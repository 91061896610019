import * as types from './actionTypes';
export default {
  saveBookmark(payload) {
    return {
      type: types.SAVE_BOOKMARK_STARTED,
      payload: payload
    };
  },
  fetchBookmark(id) {
    return {
      type: types.FETCH_ONE_BOOKMARK_STARTED,
      payload: {
        id: id
      }
    };
  },
  fetchOneBookmarkComplete(bookmark) {
    return {
      type: types.FETCH_ONE_BOOKMARK_COMPLETED,
      payload:{
        bookmark
      }
    };
  },
  fetchBookmarks(shouldDeleteDeliveredShipments) {
    return {
      type: types.FETCH_BOOKMARKS_STARTED,
      payload:{
        shouldDeleteDeliveredShipments
      }
    };
  },
  fetchBookmarksFailed(payload) {
    return {
      type: types.FETCH_BOOKMARKS_FAILED,
      payload: payload
    };
  },
  fetchBookmarksComplete(bookmarks) {
    return {
      type: types.FETCH_BOOKMARKS_COMPLETED,
      payload:{
        bookmarks
      }
    };
  },
  deleteBookmark(id) {
    return {
      type: types.DELETE_BOOKMARK_STARTED,
      payload:{
        id: id
      }
    };
  },
  deleteBookmarkComplete() {
    return {
      type: types.DELETE_BOOKMARK_COMPLETED,
      payload:{}
    };
  }
}
