import React from 'react';

export default class CarrierRollup extends React.Component {

  render() {
    return (
      <div className="carrierRollUp">
        <div className="row" style={{minHeight: 100}}>
          <span className="col-xs-12 col-md-2 carrierRollupTitles">{this.props.carrierName} </span>
          <span className="col-xs-2 col-md-2 carrierRollupNumbers onTimePickup">On Time Pickup <span className="scoreCardCounts">{Number(this.props.picked_up.on_time_pickup_percentage*100).toFixed(1)}%</span> </span>
          <span className="col-xs-2 col-md-2 carrierRollupNumbers">On Time Delivery <span className="scoreCardCounts">{Number(this.props.delivered.on_time_delivery.percentage*100).toFixed(1)}%</span> </span>
          <span className="col-xs-2 col-md-2 carrierRollupNumbers">Depart Origin <span className="scoreCardCounts">{Number(this.props.carrier_compliance.hasDepartOrigin.percentage*100).toFixed(1)}%</span> </span>
          <span className="col-xs-2 col-md-2 carrierRollupNumbers">Arrive at Destination <span className="scoreCardCounts">{Number(this.props.carrier_compliance.hasArrivalDate.percentage*100).toFixed(1)}%</span> </span>
          <span className="col-xs-2 col-md-2 carrierRollupNumbers">Contains ETA <span className="scoreCardCounts">{Number(this.props.carrier_compliance.hasETA.percentage*100).toFixed(1)}%</span> </span>
          <span className="hidden-md-up col-xs-2"></span>
        </div>
      </div>
    );
  }
}
