import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import ListViewShipmentCard from '../list-view-shipment-card/ListViewShipmentCard';
import CondensedCard from '../list-view-shipment-card/CondensedCard';
import { Row, Col, Overlay } from 'react-bootstrap';
import actions from '../../containers/shipments/actions';
import baseModalActions from '../../containers/base-modal/actions';
import exportActions from '../../containers/export/actions';
import PaginationGroup from '../../components/pagination-group';
import LoadingIcon from '../loading-icon';
import { Popover } from 'react-bootstrap';
import ExportSelectionModal from '../export/ExportSelectionModal';
import { shipmentSortByOptions, defaultShipmentSortBy } from './ShipmentSortByOptions';
import filterGenerator from '../../utils/helperMethods/filterGenerator';
import { JOB_MODES, CAPITALIZED_JOB_MODES } from '../../utils/constants';
import AnnouncementContainer from './AnnouncementContainer';

const cap_job = CAPITALIZED_JOB_MODES;

export class ShipmentList extends React.Component {
  constructor(props) {
    super(props);
    this.sortOptionsPopoverRef = React.createRef();

    this.state = {
      shipmentViewMode: this.props.shipmentViewMode || 'cardView',
      sortOptions: shipmentSortByOptions,
      showSortPanel: false,
      selectAll: true,
      selectedExportFields: []
    };
  };

  componentDidMount() {
    if (this.props.jobMode === JOB_MODES.shipments) {
      this.props.getExportSelections();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobMode !== this.props.jobMode && this.props.jobMode === JOB_MODES.shipments) {
      this.props.getExportSelections();
    }
    if (prevProps.exportSelections !== this.props.exportSelections) {
      //Select All by default
      let exportSelections = this.props.exportSelections;
      if (exportSelections.length) {
        exportSelections.forEach(x => x.isChecked = true);
      }
      this.setState({ selectAll: true, selectedExportFields: exportSelections });
    }
  };

  componentWillUnmount() {
    this.props.resetProps();
  }

  selectView = (selectedView) => {
    this.setState({ shipmentViewMode: selectedView });
    this.props.toggleShipmentViewMode(selectedView);
  };

  download = () => {
    // Flatten the generated filters list
    const generatedFilters = filterGenerator(this.props.dynamicFilters);

    // Map the incidents to the model expected by the API
    const incidents = _.map(this.props.incidents, i => ({
      name: i.additionalDetails.name,
      id: i._id
    }));

    const payload = {
      exportType: "SHIPMENTS_EXPORT",
      searchText: this.props.configuration.get('searchText') || '',
      mapBox: this.props.configuration.get('box'),
      filterConfiguration: generatedFilters.filterConfiguration,
      filters: this.props.configuration.get('activeFilters'),
      dateFilter: this.props.configuration.get('dateFilter'),
      facilityFilters: this.props.configuration.get('activeFacilityFilters'),
      incidents: incidents,
      selectedExportFields: this.state.selectedExportFields.filter(x => x.isChecked)
    };

    this.props.exportData(payload);
    this.props.closeModal();
  };

  handleExportSelectionChange = (key) => {
    let arr = [...this.state.selectedExportFields];
    arr[key].isChecked = !arr[key].isChecked;

    this.setState(Object.assign(this.state, { selectedExportFields: arr, selectAll: false }));
    this.props.showModal(this.exportModal());
  }

  handleExportSelectAllChange = () => {
    let selectAll = !this.state.selectAll;
    let arr = [...this.state.selectedExportFields];
    arr.forEach(x => x.isChecked = selectAll);

    this.setState(Object.assign(this.state, { selectAll: selectAll, selectedExportFields: arr }));
    this.props.showModal(this.exportModal());
  };

  exportModal = () => {
    return {
      title: "Choose Fields to Export",
      showModal: true,
      showCloseButton: true,
      showActionButton: true,
      actionButtonText: "Export",
      closeButtonText: "Cancel",
      actionButtonClass: "btn btn-primary",
      closeButtonClass: "btn btn-light",
      handleAction: this.download,
      handleCloseAction: this.props.closeModal,
      body: (
        <ExportSelectionModal
          selectAll={this.state.selectAll}
          selectedExportFields={this.state.selectedExportFields}
          handleExportSelectionChange={this.handleExportSelectionChange}
          handleExportSelectAllChange={this.handleExportSelectAllChange}
        />
      )
    };
  };

  handleSortShipmentsByChange(selection) {
    //if selection is different than we already had selected
    if (selection.label !== this.props.sort?.label) {
      this.setState({
        showSortPanel: false
      });
      this.props.changeSort(selection);
    }
  }

  presentSortOptions = (hide = false) => {
    const showPanel = !hide && !this.state.showSortPanel;
    this.setState({
      showSortPanel: showPanel
    });
  };

  fetchNextPage(pageNumber) {
    this.props.changeCurrentPage(this.props.jobMode, pageNumber);
  }

  renderSortOptions() {
    const sort = !!this.props.sort?.label ? this.props.sort : defaultShipmentSortBy;

    return (
      <>
        <button type="button" className={"btn btn-sm btn-outline btn-light btn-sort-options-select"}
          onClick={() => { return this.presentSortOptions() }} id="sortOptionSelect" title={sort.label ?? ''} >
          <span ref={this.sortOptionsPopoverRef}><svg className="icon"><use href="#sort" /></svg>{sort.label != null ? `Sort : ${sort.label}` : 'Sort'}</span>
        </button>
        <Overlay target={this.sortOptionsPopoverRef.current} placement={'bottom'} show={this.state.showSortPanel} containerPadding={50} rootClose={true} onHide={() => { return this.presentSortOptions(true) }}>
          <Popover id="sort-options-popover" style={{ 'maxWidth': 'initial' }}>
            {
              this.state.sortOptions.map(element => (
                <div className="sort-order-option" id={element.label} key={element.label}>
                  <span>
                    <label className="sort-order-option-radio-label">
                      <input type="radio" className="sort-order-option-radio-select" onChange={() => this.handleSortShipmentsByChange(element)} checked={sort?.label === element.label} value={element.value} />
                      {element.label}
                    </label>
                  </span>
                </div>)
              )
            }
          </Popover>
        </Overlay>
      </>
    )
  }

  renderCards() {
    return this.props.shipments.map((shipment) => {
      return (
        this.state.shipmentViewMode === 'cardView' ?
          <ListViewShipmentCard
            key={shipment._id}
            {...shipment._source}
            showModal={this.props.showModal}
            jobMode={this.props.jobMode}
            history={this.props.history}
            permissions={this.props.userProfile.permissions}
            tenantId={this.props.userProfile.tenantId}
          /> :
          <CondensedCard
            key={shipment._id}
            {...shipment._source}
            showModal={this.props.showModal}
            jobMode={this.props.jobMode}
            history={this.props.history}
            permissions={this.props.userProfile.permissions}
          />
      );
    });
  };

  render() {
    const jobMode = this.props.jobMode;
    let shipmentCountText = `No ${cap_job[jobMode]} to display.`;
    if (this.props.total > 0 && this.props.total <= this.props.limit) {
      shipmentCountText = `Showing ${this.props.total} of ${this.props.total} ${cap_job[jobMode]}`
    } else if (this.props.total > this.props.limit + this.props.offset) {
      shipmentCountText = `Showing ${this.props.offset + 1} to ${this.props.offset + this.props.limit} of ${this.props.total} ${cap_job[jobMode]}`
    } else if (this.props.total > this.props.offset) {
      shipmentCountText = `Showing ${this.props.offset + 1} to ${this.props.total} of ${this.props.total} ${cap_job[jobMode]}`
    }
    if (this.props.shipments && this.props.shipments.length > 0) {
      return (
        <div className="details-container" data-test="shipmentList">
          <div className="container-fluid flex-one">
            {this.props.loading ?
              <div className={this.props.boundByMap ? "mapCentered" : "centered"} style={{ zIndex: 1001 }}>
                <LoadingIcon></LoadingIcon>
              </div>
              :
              <div className="row shipment-list-information">
                <div id="shipmentCount" className="col-xs-12 col-sm-5">
                  {shipmentCountText}
                </div>
                <div className="col-xs-12 col-sm-7">
                  <div className="list-controls">
                    <div className="btn-group">
                      {
                        // Show the sort options dropdown / popover
                        this.renderSortOptions(this.state.showSortPanel)
                      }
                      <button type="button" className={this.state.shipmentViewMode === 'cardView' ?
                        "btn btn-sm btn-outline btn-light active" : "btn btn-sm btn-outline btn-light"}
                        onClick={() => { return this.selectView('cardView') }} id="cardview">
                        <svg className="icon space-right"><use href="#card" /></svg> Card
                      </button>
                      <button type="button" className={this.state.shipmentViewMode === "listView" ?
                        "btn btn-sm btn-outline btn-light active" : "btn btn-sm btn-outline btn-light"}
                        onClick={() => { return this.selectView('listView') }} id="listview">
                        <svg className="icon space-right"><use href="#list" /></svg> List
                      </button>
                      {this.props.userProfile.permissions
                        && this.props.userProfile.permissions.includes('Exporting')
                        && jobMode != JOB_MODES.orders
                        &&
                        <button type="button" className="btn btn-sm btn-outline btn-light"
                          onClick={() => { return this.props.showModal(this.exportModal()) }} id="exportbutton"><svg className="icon"><use href="#download" /></svg> Export CSV
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
            {!this.props.loading &&
              <Row>
                <Col md={12}>
                  {this.renderCards()}
                  <PaginationGroup
                    className="pagination"
                    totalPages={Math.ceil(this.props.total / this.props.limit)}
                    currentPageNumber={parseInt(this.props.currentPage)}
                    offset={this.props.offset}
                    limit={this.props.limit}
                    total={this.props.total}
                    fetchNextPage={page => this.props.changeCurrentPage(page)}
                    label={cap_job[jobMode]}
                  />
                </Col>
              </Row>
            }
          </div>
        </div>
      );
    } else {
      let noShipText = `No ${cap_job[jobMode]} to display`;
      return (
        <div className="details-container" data-test="shipmentList">
          <div className="container-fluid flex-one">
            <div className="row shipment-list-information">
              <div className="col-xs-12 col-sm-6">
                {this.props.loading ?
                  <div className={this.props.boundByMap ? "mapCentered" : "centered"} style={{ zIndex: 1001 }}><LoadingIcon></LoadingIcon></div> :
                  <div data-test="NoShipments">{noShipText}</div>
                }
              </div>
            </div>
          </div>
        </div>
      );
    };
  };
};

export const mapStateToProps = (state) => {
  return {
    configuration: state.shipments.queryConfiguration,
    offset: state.shipments.queryConfiguration.get('offset'),
    total: state.shipments.data.get('total'),
    sort: state.shipments.queryConfiguration.get('sort'),
    currentPage: state.shipments.queryConfiguration.get('currentPage'),
    jobMode: state.shipments.data.get('jobMode'),
    incidents: state.incidents.get('incidents'),
    dynamicFilters: state.filters.get('dynamicFilters'),
    shipments: state.shipments.data.get('shipments'),
    limit: state.shipments.data.get('limit'),
    shipmentViewMode: state.shipments.data.get('shipmentViewMode'),
    loading: state.shipments.data.get('loading'),
    defaultFilters: state.filters.get('defaultFilters'),
    userProfile: state.users.get('userProfile'),
    exportSelections: state.export.get('exportSelections'),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showModal: (modalConfig) => {
      dispatch(baseModalActions.openModal(modalConfig));
    },
    closeModal: () => {
      dispatch(baseModalActions.closeModal());
    },
    toggleShipmentViewMode: (mode) => {
      dispatch(actions.toggleShipmentViewMode(mode));
    },
    exportData: (shipments) => {
      dispatch(exportActions.exportData(shipments));
    },
    getExportSelections: () => {
      dispatch(exportActions.fetchExportSelections());
    },
    changeSort: (sort) => {
      dispatch(actions.changeSort(sort, ownProps.location, ownProps.history));
    },
    changeCurrentPage: (currentPage) => {
      dispatch(actions.changeCurrentPage(currentPage, ownProps.location, ownProps.history));
    },
    resetProps: () => {
      dispatch(exportActions.resetProps());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShipmentList);
