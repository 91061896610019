import React from 'react';
import { connect } from 'react-redux';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import landingPageActions from '../../containers/landing/actions';
import LoadingIcon from '../../components/loading-icon';

const defaultVisualConfig = {
    layoutType: models.LayoutType.Custom,
    customLayout: {
        displayOption: models.DisplayOption.FitToPage
    },
    panes: {
        pageNavigation: {
            visible: false
        },
        filters: {
            visible: false,
            expanded: false
        }
    },
    background: models.BackgroundType.Transparent,
};

export class PowerBIContainer extends React.Component {
    state = {
        className: 'col-lg-12'
    }

    componentDidMount() {
        this.props.fetchPowerBiItems();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.powerBiItems !== this.props.powerBiItems) {
            if (this.props.powerBiItems && this.props.powerBiItems.length > 1) {
                this.setState({ className: "col-lg-6" });
            }
        }
    }

    render() {
        return (
            <div className="row">
                {
                    this.props.powerBiLoading ? <div className="flex-center" data-test="powerBiLoadingIcon"><LoadingIcon /></div> :
                        this.props.powerBiItems && this.props.powerBiItems.length > 0 ?
                            this.props.powerBiItems.map((report, index) => {
                                return (
                                    <div className={this.state.className} key={index} data-test="powerBiComponent" >
                                        <PowerBIEmbed
                                            embedConfig={{
                                                id: report.id,
                                                type: report.type,
                                                embedUrl: report.embedUrl,
                                                accessToken: report.accessToken,
                                                tokenType: report.tokenType,
                                                settings: report.visualConfiguration || defaultVisualConfig
                                            }}
                                            cssClassName={'embed-container'}
                                        />
                                    </div>
                                )
                            }) : <div className="no-powerbi-items-message" data-test="noPowerBiItemsMessage">There are no reports or dashboards. </div>
                }
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        powerBiItems: state.supplyChainHealth.get('powerBiItems'),
        powerBiLoading: state.supplyChainHealth.get('powerBiLoading')
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchPowerBiItems: (tenantId) => {
            dispatch(landingPageActions.fetchPowerBiItems(tenantId));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PowerBIContainer);