import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import PropTypes from 'prop-types';
import LoadingIcon from '../../components/loading-icon';

export default class LineGraph extends React.Component {

  drawLines = () => {
    let lines = [];
    this.props.series.forEach((val, idx) => {
      lines.push(
        <Line type='monotone' strokeWidth={this.props.strokeWidthMapping[val.name]}  dataKey={this.props.dataKey} data={val.data} name={val.name} key={val.name} stroke={this.props.colorMapping[val.name]} dot={false} />
      );
    });
    return lines;
  }

  render() {
    return (
      <div className='line-chart card' data-test="LineGraph" style={{ width: this.props.width, height: this.props.height }}>
        { this.props.loading ?
          <div style={{margin: "0 auto", paddingTop: `${this.props.height / 4}px`}}><LoadingIcon/></div> :
          <React.Fragment>
            <h3 className="graph-header flex-center">{this.props.header}</h3>
            <ResponsiveContainer id={`${this.props.header.toLowerCase()}-chart-container`}>
              <LineChart margin={{ top: 20, right: 10, left: 10, bottom: 20 }} id={`${this.props.header.toLowerCase()}-chart`}>
                <XAxis
                  dataKey={this.props.xAxisDataKey}
                  type={this.props.xAxisType}
                  allowDuplicatedCategory={false}
                  label={{ value: this.props.xAxisLabel, position: 'bottom' }}
                  interval={'preserveStartEnd'}
                />
                <YAxis
                  dataKey={this.props.dataKey}
                  label={{ value: this.props.yAxisLabel, angle: -90, position: 'insideLeft', offset: 10 }}
                  domain={this.props.yAxisDomain}
                />
                <Tooltip formatter={(value) => `${value}%`}/>
                {
                  this.drawLines()
                }
                <Legend
                  onClick={this.increment}
                  align={"right"}
                  verticalAlign={"middle"}
                  layout={"vertical"}
                  wrapperStyle={{ right: "0px", maxWidth: "20%" }} />
              </LineChart>
            </ResponsiveContainer>
          </React.Fragment>
        }
      </div>
    );
  }
}

LineGraph.propTypes = {
  dataKey: PropTypes.string,
  header: PropTypes.string,
  height: PropTypes.any,
  loading: PropTypes.bool,
  series: PropTypes.array,
  width: PropTypes.any,
  xAxisDataKey: PropTypes.string,
  xAxisLabel: PropTypes.string,
  yAxisDomain: PropTypes.array,
  xAxisType: PropTypes.string,
  yAxisLabel: PropTypes.string
};

LineGraph.defaultProps = {
  loading: false,
  series: [],
  yAxisDomain: [0, 'auto']
}
