import { put, call, all } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import * as _ from 'lodash';
import actions from './actions';
import debounce from '../../utils/helperMethods/reduxHelpers';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchOrderSummaryStart({ payload }) {
  try {
    const orderSummary = yield call(api.shipments.fetchExternalTrackingData, payload);
    //HODGWIL TODO: Change how data is mapped here?
    yield put(actions.fetchOrderSummaryComplete(orderSummary.data));
  }
  catch (e) {
    yield all([
      put(actions.fetchOrderSummaryFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Order Summary Fetch Error',
        message: 'Could not fetch order summary. Please try again.',
        error: e
      }))
    ])
  }
}

export function* watchFetchOrderSummaryStart() {
  yield debounce(100, types.FETCH_ORDER_SUMMARY_STARTED, fetchOrderSummaryStart);
}
