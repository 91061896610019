import React from 'react';
import {
  AreaChart,
  Area
} from 'recharts';
import PropTypes from 'prop-types';
import './extras/style.scss';

export default class ProgressBar extends React.Component {
   data = [
    {name: 'Start', uv: 100, pv: 100, amt: 100},
    {name: 'End', uv: 100, pv: 100, amt: 100}
  ];

  render() {
    return (
      <span data-test="ProgressBar" id={`${this.props.id}-container`}>
        <span className="progress-bar-outline" style={{width: this.props.maxWidth}} id={`${this.props.id}-outline`}>
          <AreaChart id={this.props.id} data={this.data} width={this.props.percentage > 0 ? (this.props.percentage/100) * this.props.maxWidth : 0.00001} height={this.props.height} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
            <Area type='linear' isAnimationActive={this.props.isAnimationActive}  animationEasing='ease-in-out' dataKey='uv' stroke='#7EA122' fill='#7EA122' />
          </AreaChart>
        </span>
      {this.props.showLabel && <span className="scorecardPercentages">{this.props.percentage}%</span>}
      </span>
    );
  }
}

ProgressBar.defaultProps = {
  percentage: 0,
  showLabel: false,
  maxWidth: 130,
  height: 20,
  animationBegin: 0,
  isAnimationActive: false,
  id: 'progress-bar'
};

ProgressBar.propTypes = {
  percentage: PropTypes.any,
  showLabel: PropTypes.bool,
  maxWidth: PropTypes.number,
  height: PropTypes.number,
  isAnimationActive: PropTypes.bool,
  id: PropTypes.string
};
