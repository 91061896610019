import React, { Component } from 'react';
import { connect } from 'react-redux';
import './extras/styles.scss';
import Icon from '../../components/icon';
import { getDate, getDateTime, getTime, getTimeUtc } from '../../utils/helperMethods/dateTimeMethods';
import orderTrackingSummaryActions from '../../containers/order-tracking-summary/actions';
import trackingSummaryActions from '../../containers/tracking-summary/actions';
import externalTrackingActions from '../../containers/tracking/actions';
import queryString from 'query-string';
import { isExternalTrackingType, groupSortedTrackingLogsByDate, getTrackingDetailLevel, shipmentNumberClientIdSplit } from '../../utils/helperMethods/commonMethods';
import { NUOLV_ENABLED_TENANTS, ExternalTrackingDetailPage } from '../../utils/constants';

export const trackingRange = {
  allTracking: 'allTracking',
  recentTracking: 'recentTracking'
};

const defaultTrackingRange = trackingRange.allTracking;

export class TrackingHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedQueryString: queryString.parse(this.props.location.search),
      selectedTrackingRange: defaultTrackingRange,
      lastSortedColumn: 'EventDate',
      isAscending: false,
      eventDateSortIcon: 'sort-arrow-descending-active',
      updatedOnSortIcon: 'sort'
    }
  }

  componentDidMount() {
    if (this.props.pageName == ExternalTrackingDetailPage && this.props.trackingLogs && this.props.trackingLogs.size == 0) {
      this.props.getExternalTrackerLogs(window.location.hash.split('/')[2]);
    } else if (this.props.pageName != ExternalTrackingDetailPage && (isExternalTrackingType(this.state.parsedQueryString.trackingType)
      || NUOLV_ENABLED_TENANTS.includes(this.props.tenantId)
      || this.state.parsedQueryString.trackingType === 'Load'
      || this.props.loadNumbers)) {
      const shipmentId = decodeURIComponent(this.props.location.pathname.split('/')[2]);
      this.props.getTrackingLogs(this.state.parsedQueryString.loadNumber, this.state.parsedQueryString.orderNumber, this.state.parsedQueryString.trackingType, null, shipmentId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.parsedQueryString.trackingType === 'Order' && JSON.stringify(this.props.loadNumbers) !== JSON.stringify(prevProps.loadNumbers)) {
      const shipmentId = decodeURIComponent(this.props.location.pathname.split('/')[2]);
      this.props.getTrackingLogs(this.state.parsedQueryString.loadNumber, this.state.parsedQueryString.orderNumber, this.state.parsedQueryString.trackingType, this.props.loadNumbers, shipmentId);
    }
  }

  hasContainerNumberColumn = () => {
    for (const log of this.props.trackingLogs) {
      if (log.containerNumber) {
        return true;
      }
    }
    return false;
  }

  hasTrackingNumberColumn = () => {
    for (const log of this.props.trackingLogs) {
      if (log.trackingNumber) {
        return true;
      }
    }
    return false;
  }
  renderTrackingLogActivity = (log, trackingType) => {

    if (trackingType === 'NonNav') {
      let activitySummary = log.notes ? log.notes : '';

      if (log.containerNumber) {
        activitySummary = activitySummary.concat(`${activitySummary ? ' - ' : ''}Container: ${log.containerNumber}`);
      }

      if (log.items && log.items.length) {
        activitySummary = activitySummary.concat(`${activitySummary ? ' - ' : ''}Item: ${log.items.map(x => x.itemId).join(', ')}`)
      }

      return activitySummary;
    } else {
      return log.notes;
    }
  };

  filterRecentTrackingLogs = (groupedLogs, trackingType) => {

    let filteredLogs = [];
    let indexedLogs = [];

    let keys = ["ediCode", "containerNumber"];
    const locationKeys = ["City", "State", "Country"];

    if (trackingType === 'NonNav') {
      keys.push("itemId");
    } else {
      keys.push("checkCallTypeCode");
    }

    //group all the milestones by the pipe separated list of keys defined
    groupedLogs.forEach(log => {
      const key = keys.map(k => log[k]).join('|').concat(locationKeys.map(k => log.location[k]).join('|'));
      indexedLogs[key] = indexedLogs[key] || [];
      indexedLogs[key].push(log);
    });

    //for every milestone group, sort on eventDateTime desc
    Object.keys(indexedLogs).forEach(key => {
      indexedLogs[key].sort(function (a, b) {
        const aDate = new Date(a.eventDateTime);
        const bDate = new Date(b.eventDateTime);

        const eventDateTimeResult = bDate - aDate;
        if (eventDateTimeResult !== 0) {
          return eventDateTimeResult;
        }

        const updatedOnDateA = new Date(a.updatedOn);
        const updatedOnDateB = new Date(b.updatedOn);

        return updatedOnDateB - updatedOnDateA;
      });
    });

    //push the most recent entry for each of the groups
    Object.keys(indexedLogs).forEach(key => { filteredLogs.push(indexedLogs[key][0]) });

    //sort results by the most recent first
    return filteredLogs.sort((a, b) => { return new Date(b.eventDateTime) - new Date(a.eventDateTime) });
  }

  renderTrackingHistory = (tenantId, logs, trackingType, selectedTrackingRange) => {

    const determineTrackingNumber = log => {
      if (log.shipmentDisplayIdentifier) {
        return log.shipmentDisplayIdentifier;
      } else if (trackingType === 'NonNav' && log.shipmentNumber) {
        return shipmentNumberClientIdSplit(log.shipmentNumber, tenantId);
      } else {
        return log.loadNumber;
      }
    };   
    

    if (logs && logs.length > 0) {

      if (selectedTrackingRange === trackingRange.recentTracking) {
        logs = this.filterRecentTrackingLogs(logs, trackingType);
      }

      let groupedLogs = groupSortedTrackingLogsByDate(logs);

      const hasTrackingNumberCol = this.hasTrackingNumberColumn();
      const hasContainerNumberCol = this.hasContainerNumberColumn();

      return groupedLogs.map((log, i) => {
        var publicActivity = this.renderTrackingLogActivity(log, trackingType);              
        if (trackingType !== 'NonNav' && !publicActivity && !log.locationFormatted ) {
          return null;
        }else {
          return (
              <tr key={i}>
                {log.isDateGroupLabel ? <td id={`date${i}`} className="date-group-label-row">{log.eventDateTime? getDate(log.eventDateTime) : getDate(log.eventDateTimeUtc)}</td> : <td></td>}
                <td>{ log.eventDateTime? getTime(log.eventDateTime) : getTimeUtc(log.eventDateTimeUtc, true)}</td>
                <td id={`tracking-log-${i}-trackingNumber`} hidden={trackingType === 'Order' || isExternalTrackingType(trackingType) ? false : true}>{determineTrackingNumber(log)}</td>
                {hasTrackingNumberCol && <td id={`tracking-log-${i}-trackingNumber1`}>{log.trackingNumber || '\u2014'}</td>}
                {hasContainerNumberCol && <td id={`tracking-log-${i}-containerNumber`}>{log.containerNumber || '\u2014'}</td>}
                <td id={`tracking-log-${i}-activity`}>{publicActivity || '\u2014'}</td>
                <td id={`tracking-log-${i}-location`}>{log.locationFormatted || '\u2014'}</td>
                { tenantId != 404 && (<td id={`date${i}`} className="date-group-label-row trackinglogupditem">{log.updatedOn? getDateTime(log.updatedOn) : '\u2014'}</td>)}
              </tr>
          );
      }
      });
    }
  }

  hasDateUtcButNotLocal = (events) => {   
    return Array.isArray(events) && events.some((callLog) => {
      return !callLog.eventDateTime && callLog.eventDateTimeUtc;
    });
  };

  selectTrackingRange = (selectedTrackingRange) => {
    this.setState({
      selectedTrackingRange: selectedTrackingRange
    });
  };

  sortColumns = (trackingLogs, column) => {
    const isAscending = this.state.lastSortedColumn === column ? !this.state.isAscending : true;
    this.props.processTrackingLogs(trackingLogs, { column, isAscending });

    const updatedOnSortIcon = this.getSortIconName(column === 'UpdatedOn', isAscending);
    const eventDateSortIcon = this.getSortIconName(column === 'EventDate', isAscending);

    this.setState({
      lastSortedColumn: column,
      isAscending,
      updatedOnSortIcon,
      eventDateSortIcon
    });
  };

  getSortIconName = (isActiveSortColumn, isAscending) => {
    if (!isActiveSortColumn) {
      return 'sort';
    }

    return isAscending ? 'sort-arrow-ascending-active' : 'sort-arrow-descending-active';
  }

  render() {
    return (
      <div className={this.props.isTrackingHistoryActive === "active" ? "tab-pane fade active in" : "tab-pane fade"} id="tracking-history">
        <div className="row">
          <div className="col-xs-12">
            <div className="container-headers">
              <button
                id="buttonAllTracking"
                className={`btn btn-sm ${this.state.selectedTrackingRange === trackingRange.allTracking ? "btn-primary btn-semi-bold" : "btn-outline"}`}
                onClick={() => { return this.selectTrackingRange(trackingRange.allTracking); }}>
                All Tracking
              </button>
              <button
                id="buttonRecentTracking"
                className={`btn btn-sm ${this.state.selectedTrackingRange === trackingRange.recentTracking ? "btn-primary btn-semi-bold" : "btn-outline"}`}
                onClick={() => { return this.selectTrackingRange(trackingRange.recentTracking); }}>
                Recent Tracking
              </button>
            </div>
          </div>
        </div>
        <table className="table table-striped tracking-history-table">
          <thead>
            <tr>
              <th className="sortLogs" onClick={() => { return this.sortColumns(this.props.trackingLogs, 'EventDate') }}>Event Date<Icon type={this.state.eventDateSortIcon} /></th>
              <th>Time</th>
              <th hidden={this.state.parsedQueryString.trackingType === 'Order' || isExternalTrackingType(this.state.parsedQueryString.trackingType) ? false : true}> Shipment #</th>
              {this.hasTrackingNumberColumn() && <th>Tracking #</th>}
              {this.hasContainerNumberColumn() && <th>Container #</th>}
              <th>Activity</th>
              <th>Location</th>
              { this.props.tenantId != 404 && (<th className="sortLogs" onClick={() => { return this.sortColumns(this.props.trackingLogs, 'UpdatedOn') }}>Updated On<Icon type={this.state.updatedOnSortIcon} /></th>)}
            </tr>
          </thead>
          <tbody>
            {this.renderTrackingHistory(this.props.tenantId, this.props.trackingLogs, this.state.parsedQueryString.trackingType, this.state.selectedTrackingRange)}
          </tbody>
        </table>
      </div>
    );
  }
};

export const mapStateToProps = (state, ownProps) => {
  const detailLevel = getTrackingDetailLevel(ownProps.location.pathname);
  return {
    trackingLogs: state[detailLevel].get('trackingLogs'),
    loadNumbers: state[detailLevel].get('loadNumbers'),
    tenantId: state.users.get('userProfile') ? state.users.get('userProfile').tenantId : null
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  const detailLevel = getTrackingDetailLevel(ownProps.location.pathname);

  return {
    getExternalTrackerLogs: (trackingNumber) => {
      return dispatch(externalTrackingActions.fetchTrackingMilestones(trackingNumber));
    },
    getTrackingLogs: (loadNumber, orderNumber, trackingType, loadNumbers, shipmentId) => {
      if (detailLevel === 'orderTrackingSummary') {
        return dispatch(orderTrackingSummaryActions.fetchTrackingLogs(loadNumber, orderNumber, trackingType, loadNumbers, shipmentId));
      } else {
        return dispatch(trackingSummaryActions.fetchTrackingLogs(loadNumber, orderNumber, trackingType, loadNumbers, shipmentId));
      }
    },
    processTrackingLogs: (trackingLogs, sortingOptions) => {
      if (detailLevel === 'externalTracker') {
        return dispatch(externalTrackingActions.trackingLogsSort(trackingLogs, sortingOptions));
      } else if (detailLevel === 'orderTrackingSummary') {
        return dispatch(orderTrackingSummaryActions.processTrackingLogs(trackingLogs, sortingOptions));
      } else {
        return dispatch(trackingSummaryActions.processTrackingLogs(trackingLogs, sortingOptions));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingHistory);
