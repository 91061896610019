import { createStore as createReduxStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import authActions from './app/containers/auth/actions';

import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

let createdStore = null;

export function getCreatedStore() { 
  return createdStore; 
}

export const createStore = async (config, authClient, ajaxClient) => {

  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      ajaxClient,
      authClient,
      ...config
    }
  });

  const sagaMiddleware = createSagaMiddleware();

  const rootEpic = combineEpics(
    // put all your area and common epics here
  );

  const middlewares = process.env.NODE_ENV === 'production'
    ? [epicMiddleware, sagaMiddleware]
    : [epicMiddleware, createLogger(), sagaMiddleware];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createReduxStore(
    rootReducer(),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  epicMiddleware.run(rootEpic);
  sagaMiddleware.run(rootSaga);

  authClient.onRenewed(() => {
    store.dispatch(authActions.updateTokens());
  });
  store.dispatch(authActions.loadAuthClient(authClient, config.productUrl));

  createdStore = store;
  return store;
};


