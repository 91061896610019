import React from 'react';
import CovidNewsContainer from '../../containers/landing/CovidNewsContainer';
import ExceptionsContainer from '../../containers/landing/ExceptionsContainer';
import HistoricalHealthContainer from '../../containers/landing/HistoricalHealthContainer';
import InTransitHealthContainer from '../../containers/landing/InTransitHealthContainer';
import IncidentsAndNewsContainer from '../../containers/landing/IncidentsAndNewsContainer';
import TrendingShipmentsContainer from '../../containers/landing/TrendingShipmentsContainer';
import PowerBIContainer from '../../containers/landing/PowerBIContainer';
import { popover } from '../../utils/helperMethods/commonComponents';
import { OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import LoadingIcon from '../../components/loading-icon';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';
import landingPageActions from '../../containers/landing/actions';
import { navigateToViewPath } from '../../utils/helperMethods/commonMethods';
import { JOB_MODES, ORDERS_PATH } from '../../utils/constants';
import shipmentActions from '../../containers/shipments/actions';

export class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showElements: {} }
  };

  componentDidMount() {
    if (this.props.userRole === "ExternalUser") {
      this.props.setJobMode(JOB_MODES.orders);
      navigateToViewPath(ORDERS_PATH, this.props.history);
    } else {
      this.props.setJobMode(JOB_MODES.shipments);
      this.props.fetchLandingPageConfig(this.props.userProfile.tenantId);
      trackPageInfoEvent(this.props.adobeTrack, "Landing Page", ["Landing Page"]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userRole !== prevProps.userRole) {
      if (this.props.userRole === "ExternalUser") {
        navigateToViewPath(ORDERS_PATH, this.props.history);
      }
    }
  }

  pageIsLoading() {
    return this.props.userProfileLoading || this.props.userRole === "ExternalUser" || this.props.userRole === null || this.props.landingPageLoading || this.props.landingPageConfigLoading;
  }

  render() {
    return (
      <div className="page flex-column landing-page" data-test="landingPage">
        <div className="container-fluid" data-test="landingPageContainer">
          {this.pageIsLoading() ?
            <div className="centered"><LoadingIcon></LoadingIcon></div>
            :
            this.mainLandingBody()
          }
        </div>
      </div>
    )
  }

  mainLandingBody() {
    return <React.Fragment>
      <CovidNewsContainer />
      <div data-test="newsAndDisruptions" style={{ display: this.showOrHide('newsAndDisruptions', false) }} >
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-lg-12">
            <h1>News and Disruptions &nbsp;
              <OverlayTrigger placement="bottom" overlay={popover("News and Disruptions", "This section shows a summary of disruptions that could affect your shipments, as well as any important news events.", "incidentsPopover")}>
                <svg className="icon faded overlay-font" data-test="incidentsPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
              </OverlayTrigger></h1>
          </div>
        </div>
        <IncidentsAndNewsContainer history={this.props.history} showNews={this.showOrHide('news', false)} showDisruptions={this.showOrHide('disruptions', false)} location={this.props.location} />
        <hr style={{ display: this.showOrHide('newsAndDisruptions', false) }} />
      </div>
      {
         (this.props.userProfile && this.props.userProfile.permissions && this.props.userProfile.permissions.includes("PowerBI")) &&
        <div id="PowerBIContainer" style={{ display: this.showOrHide('powerBi', false) }}>
          <div className="row">
            <div className="col-lg-6">
              <h1>Supply Chain Dashboard
              &nbsp;
                <OverlayTrigger placement="bottom" overlay={popover("Power BI", "Power BI reports and dashboards.", "powerBiPopover")}>
                  <svg className="icon faded overlay-font" data-test="powerBiPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                </OverlayTrigger>
              </h1>
            </div>
          </div>
          <PowerBIContainer />
          <hr style={{ display: this.showOrHide('powerBi', false) }} />
        </div>
      }
      <div id="HealthContainer" style=
        {{
          display: this.showOrHide('supplyChainHealth', false)
        }}>
        <div className="row">
          <div className="col-lg-6">
            <h1>Supply Chain Health
            &nbsp;
      <OverlayTrigger placement="bottom" overlay={popover("Supply Chain Health", "The purpose of Supply Chain Health is to show the overall health of your shipments over a specific period of time. The figures below show the number of shipments per status by mode over the specified time period.", "supplyChainHealthPopover")}>
                <svg className="icon faded overlay-font" data-test="supplyChainHealthPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
              </OverlayTrigger>
            </h1>
          </div>
        </div>
        <HistoricalHealthContainer data-test="historicalHealthContainer"
          showGround={this.showOrHide('ground', false)}
          showAir={this.showOrHide('air', false)}
          showOcean={this.showOrHide('ocean', false)}
          showParcel={this.showOrHide('parcel', false)}
          style={{
            display: this.showOrHide('supplyChainHealth', false)
          }} />
        <hr style={{ display: this.showOrHide('supplyChainHealth', false) }} />
      </div>
      <div id="globalHealth" style={{ display: this.showOrHide('globalHealth', false) }}>
        <div className="row">
          <div className="col-lg-6 landingPageContainer">
            <h1>Global Health
            &nbsp;
      <OverlayTrigger placement="bottom" overlay={popover("Global Health", "The purpose of Global Health is to show the overall health of your shipments at the present time by aggregating data over all In Transit shipments.", "inTransitHealthPopover")}>
                <svg className="icon faded overlay-font" data-test="inTransitHealthPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
              </OverlayTrigger>
            </h1>
          </div>
        </div>
        <InTransitHealthContainer />
        <hr style={{ display: this.showOrHide('globalHealth', false) }} />
      </div>
      <div id="trendingShipments" style={{ display: this.showOrHide('trendingShipments', false) }}>
        <div className="row">
          <div className="col-lg-12">
            <h1>Trending Shipments &nbsp;
            <OverlayTrigger placement="top" overlay={popover("Trending Shipments", "This section shows the most critical current shipments based on a combination of factors including expected delivery date, risk status, shipment value, and shipment volume.", "trendingPopover")}>
                <svg className="icon faded overlay-font" data-test="trendingPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
              </OverlayTrigger>
            </h1>
          </div>
        </div>
        <TrendingShipmentsContainer history={this.props.history} />
      </div>
      <div id="ExceptionContainer" style={{ display: this.showOrHide('exceptions', false) }} >
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <h1>Exceptions  &nbsp;
      <OverlayTrigger placement="top" overlay={popover("Exceptions", "This section breaks up current shipments and sorts them by event codes if there is one associated to them.", "excetptionsPopover")}>
                <svg className="icon faded overlay-font" data-test="exceptionsPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
              </OverlayTrigger></h1>
            <ExceptionsContainer history={this.props.history} location={this.props.location} />
          </div>
        </div>
        <hr />
      </div>
    </React.Fragment>;
  }

  showOrHide(name) {
    if (this.props.landingPageConfig) {
      return this.props.landingPageConfig[name] ? "none" : "block";
    } else {
      return "none";
    }
  }
}

export const mapStateToProps = (state) => {
  const userProfile = state.users.get('userProfile');
  const userRole = userProfile ? userProfile.role : null;
  return {
    userProfile,
    userRole,
    userProfileLoading: state.users.get('userProfileLoading'),
    landingPageConfig: state.supplyChainHealth.get('landingPageConfig'),
    landingPageConfigLoading: state.supplyChainHealth.get('landingPageConfigLoading'),
  };
}

export const mapDispatchToProps = dispatch => {
  return {
    fetchLandingPageConfig: (tenantId) => {
      dispatch(landingPageActions.fetchLandingPageConfig(tenantId));
    },
    setJobMode: (jobMode) => {
      dispatch(shipmentActions.setJobMode(jobMode))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);