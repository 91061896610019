import { put, call, takeEvery, all, select, take, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import * as _ from 'lodash';

import api from '../../apis';
import * as types from './actionTypes';
import * as filterTypes from '../filters/actionTypes';
import * as filterSetsTypes from '../users/filter-sets/actionTypes';
import actions from './actions';
import incidentActions from '../incidents/actions';
import filtersActions from '../filters/actions';
import debounce from '../../utils/helperMethods/reduxHelpers';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';
import filterGenerator from '../../utils/helperMethods/filterGenerator';
import { formShipmentPayloadAgainstConfig } from '../../utils/helperMethods/shipmentPayloadGenerators';
import { navigateToViewPath } from '../../utils/helperMethods/commonMethods';
import { parseFacilityFilter, getOffset } from '../../utils/helperMethods/shipmentMethods';
import { JOB_MODES, ORDERS_PATH, SHIPMENTS_PATH, CAPITALIZED_JOB_MODES, CAPITALIZED_SINGULAR_JOB_MODES, SINGULAR_JOB_MODES } from '../../utils/constants';
import { initialState } from "./queryConfiguration/reducer";
import { shipmentSortByOptions, defaultShipmentSortBy } from '../../components/shipments/ShipmentSortByOptions';
import moment from 'moment';

const cap_job = CAPITALIZED_JOB_MODES;
const sing_cap_job = CAPITALIZED_SINGULAR_JOB_MODES;
const sing = SINGULAR_JOB_MODES;

export function* watchCurrentPageChange() {
  yield takeEvery(types.CURRENT_PAGE_CHANGED, function*({payload}) {
    yield put(actions.updateQueryParams(payload.location, payload.history));
  });
}

export function* watchMapChange() {
  yield debounce(100, types.MAP_DETAILS_CHANGED, function*({payload}) {
    yield put(actions.updateQueryParams(payload));
  });
}

export function* watchConfigurationChanges() {
  yield takeEvery([types.CURRENT_PAGE_CHANGED, types.SEARCH_TEXT_CHANGED,  
    types.CHANGED_SHIPMENTS_FILTERS_UPDATE_CONFIGURATION, types.CHANGED_SHIPMENTS_FACILITY_FILTERS_UPDATE_CONFIGURATION, types.CHANGED_SHIPMENTS_DATE_FILTER_UPDATE_CONFIGURATION,
    types.CLEARED_SHIPMENTS_FILTERS, types.SORT_CHANGED, types.MAP_CLUSTERING_REMOVED, types.FILTER_SET_LOADED], 
    function*({payload}) {
    yield put(actions.updateQueryParams(payload.location, payload.history, payload.shouldSkipFetchingShipments));
  });
}

export function* watchNavigateButtons() {
  yield takeEvery([types.NAVIGATE_TO_SHIPMENT_FROM_INCIDENT_CARD, types.NAVIGATE_TO_SHIPMENT_FROM_EXCEPTION_CARD ], function*({payload}) {
    yield put(actions.updateQueryParams(payload.location, payload.history, true));
  });
}

export function* watchShipmentsQueryConfigurationChange() {
  yield takeEvery(types.UPDATED_QUERY_PARAMS, shipmentsQueryConfigurationChange);
}

export function* watchShipmentFiltersChange() {
  yield takeEvery(types.CHANGED_SHIPMENTS_FILTERS, getFiltersAfterChange);
}

export function* watchShipmentFacilityFiltersChange() {
  yield takeEvery(types.CHANGED_SHIPMENTS_FACILITY_FILTERS, getFacilityFiltersAfterChange);
}

export function* watchShipmentDateFilterChange() {
  yield takeEvery(types.CHANGED_SHIPMENTS_DATE_FILTER, getConfigurationUpdateAfterDateFilterChange);
}

export function* watchOrdersQueryConfigurationChange() {
  yield takeEvery(types.UPDATED_QUERY_PARAMS, shipmentsQueryConfigurationChange);
}

export function* watchClusterSelect() {
  yield takeEvery(types.CLUSTER_SELECTED, onClusterSelect);
}

export function* onClusterSelect({payload}) {
  try {
    const configuration = yield select(x => x.shipments.queryConfiguration);

    if (configuration.get('displayMode') === 'mapOnly') {
      // change view from map to list when view shipments is clicked on a cluster popup
      yield put(actions.toggleShipmentDisplayMode("listOnly", payload.location, payload.history));
      return;
    }

    yield put(actions.updateQueryParams(payload.location, payload.history));
  }
  catch(e) {
    yield* handleSearchShipmentsError(e);
  }
}

export function* watchToggleShipmentsDisplayMode() {
  yield takeEvery(types.TOGGLE_SHIPMENT_DISPLAY_MODE, onShipmentDisplayModeToggled);
}

export function* onShipmentDisplayModeToggled({payload}) {
  try {
    // todo think about it selector for display mode?
    const configuration = yield select(x => x.shipments.queryConfiguration);
    // splitview applies a new bounding box, so we need to reset the page they are starting on
    // splitView -> listOnly unapplies the bounding box, so we need to reset the page they are starting on
    if (payload.display === 'splitView' || (payload.display === 'listOnly' && configuration.get('displayMode') === 'splitView')) {
        yield put(actions.changeCurrentPage(1, payload.location, payload.history, true));
    } else {
      // we need to wait for map to apply box for querying shipments first
      const shouldSkipFetchingShipments = payload.display !== 'listOnly';
      yield put(actions.updateQueryParams(payload.location, payload.history, shouldSkipFetchingShipments));
    }
  } 
  catch (e) {
    yield* handleSearchShipmentsError(e);
  }
}

export function* watchNewShipmentsQueryConfigurationUpdated() {
  yield takeEvery(types.NEW_QUERY_PARAMS_UPDATE_CONFIGURATION, function*({payload}) {
      if (!payload.shouldSkipFetchingShipments) {
        yield put(actions.searchShipments());
      }
  });
}

export function* watchNewShipmentsQueryConfiguration() {
  yield takeEvery(types.NEW_QUERY_PARAMS, getNewConfigurationAfterQueryParamsReset);
}

export function* getNewConfigurationAfterQueryParamsReset({payload}) {
  try {
    const filterConfiguration = yield select(x => x.filters);

    let dynamicFilters = filterConfiguration.get('dynamicFilters');
    const loadingFilters = !!filterConfiguration.get('loading');
    if (loadingFilters) {
      const filters = yield take(filterTypes.FETCH_TENANT_DYNAMIC_FILTERS_COMPLETED);
      dynamicFilters = filters.payload;
    }
    const generated = yield call(filterGenerator, dynamicFilters);

    const initialStateObject = initialState().toObject();
    const queryStringParams = yield call(queryString.parse, payload.url.search);
    const currentPage = parseInt(queryStringParams.currentPage ?? initialStateObject.currentPage);
    const offset = queryStringParams.offset ?? getOffset(currentPage);
    let sort = !queryStringParams.sort ? defaultShipmentSortBy : shipmentSortByOptions.find(x => x.label === queryStringParams.sort);
    const displayMode = queryStringParams.displayMode ?? initialStateObject.displayMode;
    const incidentName = queryStringParams.incidentName ?? initialStateObject.incidentName;
    const incidentId = queryStringParams.incidentId ?? initialStateObject.incidentId;
    const searchText = queryStringParams.searchText;
    const multiSearchType = queryStringParams.multiSearchType;
    let { box, zoom, facilityFilters, dateFilter, clusterSelected, liveTracked } = {...initialStateObject};
      
    if (!!queryStringParams.clusterSelected) {
      box = { 
        bottom_right: { lat: parseFloat(queryStringParams.bottom), lon: parseFloat(queryStringParams.right) },
        top_left: { lat: parseFloat(queryStringParams.top), lon: parseFloat(queryStringParams.left) }
      };
      zoom = queryStringParams.zoom;
      clusterSelected = true;
    }
    if (queryStringParams.facilityFilters) {
      const facilityFiltersFromUrl = Array.isArray(queryStringParams.facilityFilters) ? queryStringParams.facilityFilters : [queryStringParams.facilityFilters];
      facilityFilters = yield call(facilityFiltersFromUrl.map.bind(facilityFiltersFromUrl), parseFacilityFilter);
    }
    if (!!queryStringParams.startDate || !!queryStringParams.endDate || !!queryStringParams.startField || queryStringParams.dateFilter) {
      dateFilter = {
        field: !queryStringParams.endField ? [queryStringParams.startField] : [queryStringParams.startField, queryStringParams.endField],
        startDate: moment(queryStringParams.startDate).format("YYYY-MM-DD"),
        endDate: moment(queryStringParams.endDate).format("YYYY-MM-DD"),
      }
      sort = {field: queryStringParams.sort, order: queryStringParams.sortOrder};
    }

    const isGoingToMapDisplayingView = queryStringParams.displayMode === 'mapOnly' || queryStringParams.displayMode === 'splitView';

    let activeFilters = _.intersectionBy(Object.entries(queryStringParams), Object.entries(generated.filterConfiguration), pair => pair[0]);
    activeFilters = activeFilters.map(x => Array.isArray(x[1]) ? [x[0], x[1]] : [x[0], [x[1]]]);
    activeFilters = _.fromPairs(activeFilters);

    const filterSets = yield select(x => x.filterSets);
    let userFilterSets = filterSets.get('filterSets');
    if (filterSets.get('filterSetsLoadingState') === 'loading') {
      const sets = yield take(filterSetsTypes.FETCH_FILTERSETS_COMPLETED);
      userFilterSets = sets.payload.filterSets;
    }

    const defaultFilterSet = _.find(userFilterSets, x => x.isDefault);
    if (!!defaultFilterSet) {
      const defaultFilters = JSON.parse(defaultFilterSet.query);

      for (const defaultFilterKey of Object.keys(defaultFilters.filters)) {
        if (generated.filterConfiguration.hasOwnProperty(defaultFilterKey)) {
          activeFilters[defaultFilterKey] = _.union(activeFilters[defaultFilterKey], defaultFilters.filters[defaultFilterKey]);
        }
      }

      facilityFilters = _.unionWith(defaultFilters.facilityFilters, facilityFilters, _.isEqual);

      if (!!dateFilter) {
        dateFilter = defaultFilters.dateFilter;
      }
    }

    const shouldSkipFetching = isGoingToMapDisplayingView || (!!defaultFilterSet && loadingFilters);
    const newConfiguration = {
      zoom, box, activeFilters, facilityFilters, dateFilter, clusterSelected, currentPage, displayMode, sort, offset, searchText, multiSearchType, incidentName, incidentId, liveTracked
    };
    yield put(actions.newQueryStringUpdateConfiguration(newConfiguration, payload.url, payload.history, shouldSkipFetching));
  }
  catch (e) {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `${cap_job[jobMode]} Search Configuration Update Error`,
      message: `Could not set up the search configuration for ${jobMode}. Please try again.`,
      error: e
    }));
  }
}

export function* searchShipmentsStart() {
  try {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    const configuration = yield select(x => x.shipments.queryConfiguration);
    const filterConfiguration = yield select(x => x.filters);

    let dynamicFilters = filterConfiguration.get('dynamicFilters');
    if (!!filterConfiguration.get('loading')) {
      const filters = yield take(filterTypes.FETCH_TENANT_DYNAMIC_FILTERS_COMPLETED);
      dynamicFilters = filters.payload;
    }
    
    const { payload } = yield call(formShipmentPayloadAgainstConfig, dynamicFilters, configuration.toObject());

    if (!configuration.get('incidentName')) {
      const incidents = yield call(api.incidents.fetchIncidents);
      const affected = _.filter(incidents.data, i => i.affectedArea && i.affectedArea.features && i.affectedArea.features.length > 0);
      const filteredIncidents = _.map(affected, i => ({
        name: i.additionalDetails.name,
        id: i._id
      }));
      yield put(incidentActions.fetchIncidentsComplete(incidents.data));
      payload.incidents = filteredIncidents;
    }

    const shipments = yield call(api.shipments.searchShipments, payload, jobMode);

    let successfulResponse = true;
    for (let i = 0; i < shipments.result.data.responses?.length && successfulResponse; i++) {
      if (shipments.result.data.responses[i].error) {
        successfulResponse = false;
      }
    }
    if (successfulResponse) {
      const response = {
        shipments: shipments.result.data.responses,
      };
      yield put(actions.searchShipmentsComplete(response));
    } else {
      const jobMode = yield select(r => r.shipments.data.get('jobMode'));
      let errMsg = `Unable to complete search for ${jobMode}: `;
      errMsg += (payload.multiSearchType) ? payload.multiSearchType + ',' : '';
      errMsg += `Search text: ${payload.searchText}`;
      yield all([
        put(actions.searchShipmentsFailed(errMsg)),
        put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
          title: 'Search Failure',
          message: `Could not perform your search. Please check your search filters for errors and try again.`,
          error: errMsg
        }))
      ]);
    }
  }
  catch (e) {
    yield* handleSearchShipmentsError(e);
  }
}

export function* watchSearchShipmentsStart() {
  yield takeLatest(types.SEARCH_SHIPMENTS_STARTED, searchShipmentsStart);
}

export function* fetchListShipmentsStart({ payload }) {
  try {
    const jobMode = yield select(x => x.shipments.data.get('jobMode'));
    const shipments = yield call(api.shipments.searchShipments, payload, jobMode);
    yield put(actions.fetchListShipmentsComplete(shipments.result.data.responses));
  }
  catch (e) {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    yield all([
      put(actions.fetchListShipmentsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: `${cap_job[jobMode]} List Search Error`,
        message: `Could not search for your ${jobMode}. Please try again.`,
        error: e
      }))
    ])
  }
}

export function* watchFetchListShipmentsStart() {
  yield debounce(100, types.FETCH_LIST_SHIPMENTS_STARTED, fetchListShipmentsStart);
}

export function* fetchAffectedShipments() {
  try {
    const jobMode = yield select(x => x.shipments.data.get('jobMode'));
    const incidents = yield call(api.incidents.fetchIncidents);
    yield put(incidentActions.fetchIncidentsComplete(incidents.data));
    const dynamicFilters = yield call(api.metadata.fetchDynamicFilters);
    yield put(filtersActions.fetchTenantDynamicFiltersComplete(dynamicFilters.data.mappings));
    const payload = [];
    const weatherIncidents = [];
    const req = filterGenerator(dynamicFilters.data.mappings);
    req.zoom = null;
    req.limit = 20;
    req.offset = 0;
    incidents.data.map(x => {
      if (x.type && x.type.toLowerCase() === 'weather') {
        weatherIncidents.push(x);
      }
    });

    weatherIncidents.forEach(x => {
      const incidentReq = JSON.parse(JSON.stringify(req));

      if (x.additionalDetails && x.additionalDetails.name && x._id) {
        incidentReq.filters.incidents = [x.additionalDetails.name];
        incidentReq.incidents = [{
          id: x._id,
          name: x.additionalDetails.name
        }]
        payload.push(incidentReq);
      }
    });
    const filteredResult = [];

    const responses = yield payload.map(x =>
      call(api.shipments.searchShipments, x, jobMode));

    responses.forEach(x => {
      if (x.result.data.responses[2].hits.total !== 0) {
        filteredResult.push({
          totalShipments: x.result.data.responses[2].hits.total,
          late: x.result.data.responses[3].aggregations.status.status.buckets.find(x => x.key === "Late") ? x.result.data.responses[3].aggregations.status.status.buckets.find(x => x.key === "Late") :
            { key: 'Late', doc_count: 0 },
          atRisk: x.result.data.responses[3].aggregations.status.status.buckets.find(x => x.key === "At Risk") ? x.result.data.responses[3].aggregations.status.status.buckets.find(x => x.key === "At Risk") :
            { key: 'At Risk', doc_count: 0 },
          incidentInfo: x.payload.incidents[0]
        });
      }
    });
    yield put(actions.fetchAffectedShipmentsComplete(filteredResult));
  }
  catch (e) {
    yield all([
      put(actions.fetchAffectedShipmentsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Incidents Retrieve Error',
        message: `Failed to retrieve incidents. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchAffectedShipments() {
  yield takeEvery(types.FETCH_AFFECTED_SHIPMENTS_STARTED, fetchAffectedShipments);
}

export function* fetchExceptionsStart() {
  try {
    const jobMode = yield select(x => x.shipments.data.get('jobMode'));
    const result = yield call(api.shipments.fetchExceptions, jobMode);
    yield put(actions.fetchExceptionsComplete(result.data));
  }
  catch (e) {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    yield all([
      put(actions.fetchExceptionsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: `${cap_job[jobMode]} Exception Error`,
        message: `Failed to retrieve ${sing[jobMode]} exceptions. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchExceptionsStart() {
  yield takeEvery(types.FETCH_EXCEPTIONS_STARTED, fetchExceptionsStart);
}

export function* shipmentsQueryConfigurationChange({payload}) {
  try {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    const configurationState = yield select(x => x.shipments.queryConfiguration);
    const configuration = configurationState.toObject();
    
    if (!payload.shouldSkipFetchingShipments) { 
      yield put(actions.searchShipments());
    }

    const modifiedUrlParams = {};
    modifiedUrlParams.currentPage = configuration.currentPage;
    modifiedUrlParams.sort = configuration.sort.label;
    modifiedUrlParams.displayMode = configuration.displayMode;

    if (!!configuration.incidentName || !!configuration.incidentId) {
      modifiedUrlParams.incidentName = configuration.incidentName;
      modifiedUrlParams.incidentId = configuration.incidentId;
    }

    if (!!configuration.searchText) { 
      modifiedUrlParams.searchText = configuration.searchText; 
    }

    if (!!configuration.multiSearchType) {
      modifiedUrlParams.multiSearchType = configuration.multiSearchType;
    }

    if (!!configuration.dateFilter) {
      modifiedUrlParams.dateFilter = true; // is this needed?
      modifiedUrlParams.startDate = configuration.dateFilter.startDate;
      modifiedUrlParams.endDate = configuration.dateFilter.endDate;
      modifiedUrlParams.startField = configuration.dateFilter.field[0];
      if (configuration.dateFilter.field.length > 1) { modifiedUrlParams.endField = configuration.dateFilter.field[1]; }
    }

    if (!!configuration.clusterSelected) {
      modifiedUrlParams.bottom = configuration.box.bottom_right.lat;
      modifiedUrlParams.right = configuration.box.bottom_right.lon;
      modifiedUrlParams.top = configuration.box.top_left.lat;
      modifiedUrlParams.left = configuration.box.top_left.lon;
      modifiedUrlParams.zoom = configuration.zoom;
      modifiedUrlParams.clusterSelected = true;
    }
    
    for (var [key, value] of Object.entries(configuration.activeFilters)) {
      modifiedUrlParams[key] = value;
    }

    const activeFacilityFilters = configuration.activeFacilityFilters;
    if (_.isArray(activeFacilityFilters)) {
      modifiedUrlParams.facilityFilters = activeFacilityFilters.map(x => `${x.facilityName}:${x.facilityCode}:${x.facilityIdentifiers}:${x.shipmentStatus}`);
    }

    if (jobMode === JOB_MODES.shipments) {
      yield call(navigateToViewPath, SHIPMENTS_PATH, payload.history, modifiedUrlParams);
    } else if (jobMode === JOB_MODES.orders) {
      yield call(navigateToViewPath, ORDERS_PATH, payload.history, modifiedUrlParams);
    }
  } catch (e) {
    yield* handleSearchShipmentsError(e);
  }
}

export function* getFiltersAfterChange({payload}) {
  try {
    const queryConfiguration = yield select(x => x.shipments.queryConfiguration);
    const filters = queryConfiguration.get('activeFilters') ?? {};
    const filterEntry = filters[payload.filterTag];

    if (!filterEntry) {
      filters[payload.filterTag] = [payload.filter];
    } else if (filterEntry.includes(payload.filter)) {
      _.pull(filterEntry, payload.filter);
    } else {
      filterEntry.push(payload.filter);
    }

    yield put(actions.filtersChangedUpdateConfiguration(filters, payload.location, payload.history))
  }
  catch (e) {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `${cap_job[jobMode]} Filters Configuration Update Error`,
      message: `Could not set up the facility filters configuration for ${jobMode}. Please try again.`,
      error: e
    }));
  }
} 

export function* getFacilityFiltersAfterChange({payload}) {
  try {
    const queryConfiguration = yield select(x => x.shipments.queryConfiguration);
    const activeFacilityFilters = queryConfiguration.get('activeFacilityFilters') ?? [];
    const failityFiltersCount = activeFacilityFilters.length;
    if (failityFiltersCount === 0) {
      activeFacilityFilters.push(payload.filter);
    } else {
      _.remove(activeFacilityFilters, oldFilter => oldFilter.facilityName === payload.filter.facilityName && oldFilter.parsedFacilityStatus === payload.filter.parsedFacilityStatus);
      
      // we removed no filter - we are adding, not removing
      if (failityFiltersCount === activeFacilityFilters.length) {
          activeFacilityFilters.push(payload.filter);
      }
    };    
    yield put(actions.facilityFiltersChangedUpdateConfiguration(activeFacilityFilters, payload.location, payload.history, payload.displayMode))
  }
  catch (e) {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `${cap_job[jobMode]} Filters Configuration Update Error`,
      message: `Could not set up the facility filters configuration for ${jobMode}. Please try again.`,
      error: e
    }));
  }
}

export function* getConfigurationUpdateAfterDateFilterChange({payload}) {
  try {
    const isRemovingDateFilter = _.isString(payload.filter);
    let filter, dateFilterSort;
  
    if (isRemovingDateFilter) {
      filter = null;
      dateFilterSort = defaultShipmentSortBy;
    }
    else {
      filter = payload.filter;
      dateFilterSort = {
        field: payload.filter.field[0],
        order: 'desc'
      };
    }
    yield put(actions.dateFilterChangedUpdateConfiguration(filter, dateFilterSort, payload.location, payload.history));
  }
  catch (e) {
    const jobMode = yield select(r => r.shipments.data.get('jobMode'));
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `${cap_job[jobMode]} Filters Configuration Update Error`,
      message: `Could not set up the date filters configuration for ${jobMode}. Please try again.`,
      error: e
    }));
  }
}

function* handleSearchShipmentsError(e) {
  const jobMode = yield select(r => r.shipments.data.get('jobMode'));
  yield all([
    put(actions.searchShipmentsFailed()),
    put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Search Error',
      message: `${cap_job[jobMode]} could not be retrieved. Please try again.`,
      error: e
    }))
  ]);
}