import {put, call, takeEvery} from 'redux-saga/effects';
import api from '../../apis';
import * as actionTypes from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchFacilitiesStart(){
  try{
    const facilities = yield call(api.metadata.fetchFacilities);
    yield put(actions.fetchFacilitiesComplete(facilities.data));
  }
  catch(e){
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(e));
  }
}

export function* watchGetFacilitiesStart(){
  yield takeEvery(actionTypes.FETCH_FACILITIES_STARTED, fetchFacilitiesStart);
}
