import React from 'react';
import { v4 as uuidv4 } from "uuid";
import { Panel, PanelGroup } from "react-bootstrap";
import { toKebabCase } from '../../../utils/helperMethods/commonMethods';

export default class ListFilters extends React.Component {

  state = {
    activePanel: '',
    searchText: ''
  };

  handleFilterToggle = (activePanel) => {
    this.setState(Object.assign(this.state, { activePanel: this.state.activePanel === activePanel ? false : activePanel }));
  };

  handleFilterSearchChange = (e) => {
    this.setState({
      searchText: e.target.value
    });
  };

  getElementsCount = (filteringBucket, filterTag) => {
    const nestedCount = filteringBucket[filterTag];
    const elementsCountSource = nestedCount ?? filteringBucket;

    return elementsCountSource.doc_count;
  };

  containsSearchText = (searchText, target) => {
    let normalizedTarget = target.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    let normalizedSearchText = searchText.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    return normalizedTarget.includes(normalizedSearchText) || target.includes(searchText);
  };

  render() {
    return (
      <ul className="list-group" >
        {this.props.listFilters && this.props.listFilters.data && this.props.listFilters.data.buckets &&
          <PanelGroup id="accordion-controlled-example">
            <Panel defaultExpanded={(this.props.listFilters.label === this.state.activePanel) || (this.props.listFilters.label  === this.props.activeGroup)}>
              <Panel.Heading>
                <Panel.Title toggle style={{ 'fontSize': '14px' }} id={`list-${toKebabCase(this.props.listFilters.label)}`} onClick={() => this.handleFilterToggle(this.props.listFilters.label)}>
                  {this.props.listFilters.label}
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                {
                  (this.props.listFilters.data.buckets.length >= 10) ?
                  <div className="form-group">
                    <input id="searchListFilter" className="form-control" type="search" autoComplete="off"
                      value={this.state.searchText}
                      onChange={(e) => {this.handleFilterSearchChange(e)}}
                      defaultValue={this.state.searchText ? this.state.searchText : ''}
                      placeholder="Search"
                    />
                  </div> : null
                }
                {(this.props.listFilters.data.buckets.length > 0) ?
                  this.props.listFilters.data.buckets.map((filters, i) => {
                    if(this.state.searchText === '' || this.containsSearchText(this.state.searchText, filters.key)) {
                      return (
                        <li key={uuidv4()} className="list-item" >
                          <label>
                            <input type="checkbox" id={`list-${filters.container_number ? `container-number-${i}` : toKebabCase(filters.key)}-filter`} 
                            checked={this.props.listFilters.active.includes(filters.key)} onChange={() => { this.props.handleFiltersChange(this.props.listFilters, filters.key) }} />
                            <span title={filters.key}><p className="overflow">{filters.key}</p></span>
                            <span>({this.getElementsCount(filters, this.props.listFilters.tag)})</span>
                          </label>
                        </li>
                      )
                    }
                  }) : <p className="center"> No Options </p>}
              </Panel.Body>
            </Panel>
          </PanelGroup>}
      </ul >
    );
  }
};

