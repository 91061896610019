import React from 'react';
import { v4 as uuidv4 } from "uuid";
import { getDateTime } from '../../utils/helperMethods/dateTimeMethods';

export default function InventoryTable(props) {

  const snapshotDate = (props.inventory.searchResults && props.inventory.searchResults[0]) ? props.inventory.searchResults[0].snapshot_date : 'N/A';

  const fetchInventoryDetails = (item) => {
    props.history.push(`/inventory/${item.identifier}`);
  }

  const renderItems = (items) => {
    if (items && items.length > 0) {
      return items.map((item, i) => {
        return (
          <tr key={uuidv4()}>
            <td id={`sku-${i}-value`}>{item.identifier || '\u2014'}</td>
            <td>{item.line_of_business || '\u2014'}</td>
            <td>{item.product_name || '\u2014'}</td>
            <td className="text-right" id={`qty-${i}-value`}>{item.value || '\u2014'}</td>
            <td className="text-right"> <button onClick={() => { fetchInventoryDetails(item) }} id="fetchinventorydetails" className="btn btn-outline btn-sm">Details</button></td>
          </tr>
        );
      });
    }
  }
  if (props.inventory.searchResults && props.inventory.searchResults.length > 0) {
    return (
      <div id="inventorytable" className="flex-column inventory">
        <div className="row">
          <div className="space-top">
            <div className="col-sm-6">
              {props.inventory.total > (props.state.offset + 50) ? <p>Showing {(props.state.offset + 1)} to {(props.state.offset + 50)} of {props.inventory.total} (Total: {props.inventory.total_skus}) </p> : null}
              {(props.state.offset + 50) >= props.inventory.total ? <p>Showing {props.state.offset + 1} to {props.inventory.total} of {props.inventory.total} (Total: {props.inventory.total_skus}) </p> : null}
            </div>
            <div className="col-sm-6">
              <span className="italic float-right">
                <p>Last Update: {getDateTime(snapshotDate)} </p>
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <table className="table">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Line Of Business</th>
                  <th>Product Name</th>
                  <th className="text-right">QTY</th>
                  <th className="text-right"></th>
                </tr>
              </thead>
              <tbody>
                {renderItems(props.inventory.searchResults)}
              </tbody>
            </table>
          </div>
        </div>
      </div >
    );
  } else {
    return (
      <div>
        No Results Found
    </div>)
  }
}
