import { Popover, Tooltip } from 'react-bootstrap';
import React from 'react';

const popover = (title, text, id) => {
  return (
    <Popover id={id} title={title}>
      {text}
    </Popover>
  );
};

const tooltip = (text, id) => {
  return (
    <Tooltip id={id}>
      {text}
    </Tooltip>
  );
};

export {
  popover,
  tooltip
};
