import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  socketClient: null
});

function authReducer(state=initialState, action={}) {
  switch(action.type) {
    case types.CONFIGURE_SOCKET_STARTED:
      return state;
    case types.CONFIGURE_SOCKET_COMPLETED:
      return state
        .set('socketClient', action.payload.socketClient);
    case types.CONFIGURE_SOCKET_FAILED:
      return state
        .set('socketClient', null);
    default:
      return state;
  }
}

export default authReducer;
