import React from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from "uuid";
import BaseFilters from '../filter-menu/BaseFilters';
import ListFilters from '../filter-menu/ListFilters';
import DateFilter from '../filter-menu/DateFilter';
import FacilityFilter from '../filter-menu/FacilityFilter';

export default function FilterMenu(props) {
  let defaultsFilters = [];
  let bottomFilters = [];
  let defaults = ["status", "current_location_status"];
  let view = props.jobMode

  if (props.filterAggregations && props.filterAggregations.size !== 0 && props.dynamicFilters && props.dynamicFilters.filterConfiguration) {
    for (const [key, filter] of Object.entries(props.dynamicFilters.filterConfiguration)) {
      filter.tag = key;
      filter.data = props.filterAggregations[key] ? props.filterAggregations[key][key] : [];
      filter.active = props.activeFilters[key] || [];
      let show = !filter.view || filter.view.length === 0 || filter.view.includes(view);
      if (defaults.includes(key) && show) {
        defaultsFilters.push(filter);
      } else if (show) {
        bottomFilters.push(filter);
      }
    }
  } else if (props.dynamicFilters && props.dynamicFilters.filterConfiguration) {
    for (const [key, filter] of Object.entries(props.dynamicFilters.filterConfiguration)) {
      let show = !filter.view || filter.view.length === 0 || filter.view.includes(view);
      if (defaults.includes(key) && show) {
        defaultsFilters.push(filter);
      } else if (show) {
        bottomFilters.push(filter);
      }
    }
  };

  if (props.facilityLocations) {
    props.facilityLocations.forEach(location => {
      location.atFacility = false;
      location.arrivingFacility = false;
      location.departingFacility = false;

      if (props.activeFacilityFilters && props.activeFacilityFilters.length > 0) {
        props.activeFacilityFilters.forEach(filter => {
          if (location.code === filter.facilityCode) {
            location[filter.shipmentStatus] = true;
          };
        });
      };
    });
  };

  if (bottomFilters.length > 0) {
    bottomFilters.forEach(filter => {
      if (filter.data && filter.data[filter.tag] && filter.data[filter.tag].filter_values) {
        filter.data.buckets = filter.data[filter.tag].filter_values.buckets;
      };
      if (filter.data && !filter.data.buckets) {
        Object.keys(filter.data).forEach(key => {
          if (typeof (filter.data[key]) !== 'number') {
            filter.data = filter.data[key];
          };
        });
      };
      if (filter.label === 'Disruptions' && filter.data && filter.data.buckets && !Array.isArray(filter.data.buckets)) {
        let buckets = [];
        for (let [key, value] of Object.entries(filter.data.buckets)) {
          buckets.push({ key: key, doc_count: value.doc_count });
        };
        filter.data.buckets = buckets;
      };
      return filter
    });
  };

  return (
    <div className="filter-item-container space-top col-xs-12">
      {defaultsFilters.map((filter, i) => {
        return (<BaseFilters key={uuidv4()} baseFilters={filter} handleFiltersChange={props.handleFiltersChange} />)
      })}
      {!props.filterLoading && <FacilityFilter location={props.location} history={props.history} handleFacilityFilterChange={props.handleFacilityFilterChange} facilityLocations={props.facilityLocations} />}
      {!props.filterLoading && <DateFilter location={props.location} history={props.history} />}
      {bottomFilters.map((filter, i) => {
        return (<ListFilters key={uuidv4()} listFilters={filter} handleFiltersChange={props.handleFiltersChange} activeGroup={props.activeGroup} handleAccordionSelect={props.handleAccordionSelect} />)
      })}
    </div>
  );
};
