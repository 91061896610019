import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';
import { getUserProfile } from '../containers/auth/authHelper';

export default {
  async fetchAlerts() {
    const config = await getConfiguration();
    const endpoint = config.alertsApiEndpoint;
    const userProfile = await getUserProfile();
    const params = {
      userId: userProfile.user_id,
      size: 10
    };
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get('alerts', { params });
			return result;
    } catch (err) {
      throw `Error getting alerts ${err}`;
    }
  }
};
