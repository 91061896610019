import React from 'react';

export default function ErrorToast(params) {  
  return (
    <div id="errortoast" className="toast-notification static error">
      <div className="toast-header">
        <strong>{params.title}</strong>
      </div>
      <div className="toast-body">
        <p>{params.message}</p>
      </div>
    </div>
  );
}
