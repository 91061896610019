export default function (io, config) {
  const client = (roomId, nameSpace, token) => {
    const connection = config.notificationsApiEndpoint + nameSpace;

    const options = {
      query: {
        token: `Bearer ${token}`
      },
      path: config.notificationsApiSocketPath,
      transports: ['websocket']
    };
    const socket = io(connection, options);

    socket.on('connect', () => {
      socket.emit('join', roomId);
    });
    // retry with xhr polling if we can't establish a websocket connection for some reason
    socket.on('reconnect_attempt', () => {
      socket.io.opts.transports = ['polling', 'websocket'];
    });

    function registerHandler(eventList) {
      for (let i = 0; i < eventList.length; i++) {
        socket.on(eventList[i].eventName, eventList[i].eventHandler);
      }
    }

    function dispatchEvent(eventName, payload, roomId) {
      socket.emit(eventName, {
        ...payload,
        roomId
      });
    }

    function unregisterHandler(eventList) {
      for (let i = 0; i < eventList.length; i++) {
        socket.off(eventList[i].eventName);
      }
    }

    function leave() {
      socket.emit('leave', roomId);
    }

    function disconnect() {
      socket.close();
    }

    function typing(roomId) {
      socket.emit('typing', {
        roomId
      });
    }

    function stoppedTyping(roomId) {
      socket.emit('stoppedTyping', {
        roomId
      });
    }

    function updateToken(token) {
      socket.disconnect();
      socket.query.token = `Bearer ${token}`;
      socket.connect();
    }

    return {
      registerHandler,
      unregisterHandler,
      leave,
      dispatchEvent,
      typing,
      stoppedTyping,
      disconnect,
      updateToken
    };
  };
  return client;
}
