import React from 'react';
import logo from '../../containers/chr-side-nav/extras/navisphere-vision-w.svg';
import Button from '../../components/button/Button';
import Icon from '../icon/Icon';


class ExternalHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  login= ()=>{
    window.location.replace(window.location.origin);
  }

  isUserLoggedIn = () => {
    return window.localStorage.getItem('userProfile') ? true : false; 
  }

  render() {
    return (
      <div className="header-container" >
        <div className="external-header">
          <nav>
            <div className="flex align-middle">
            <div className="col-xs-2 col-sm-2 col-md-6">
                <a className="hidden-xs hidden-sm" href="#" title="Navisphere Vision Home Page" aria-label="Navisphere Vision Home Page">
                  <img src={logo}/>
                </a>
                <a className="hidden-md hidden-lg" href="#" title="Navisphere Vision Home Page" aria-label="Navisphere Vision Home Page">
                  <Icon className='icon large logo' type='navisphere'/>
                </a>
              </div>
              <div className="col-xs-10 col-sm-10 col-md-6">
                {this.props.showLoginBtn && !this.isUserLoggedIn() && <Button id={'login-button'} text="Login" onClick={this.login} style={{ float: 'right', marginRight: 15, backgroundColor: '#d78138' }} />}
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default ExternalHeader;
