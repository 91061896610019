import React from 'react';
import { connect } from 'react-redux';
import CHRSideNav from '../../containers/chr-side-nav';
import BaseModal from '../../containers/base-modal';
import Header from '../../components/header';
import Footer from '../../components/footer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authActions from '../../containers/auth/actions';

export class Layout extends React.Component {
  componentDidMount() {
    if (this.props.updateTokens) {
      this.props.updateTokens();
    }
  }

  render() {
    let page = (!this.props.accessToken) ? (<div data-test="no-access-token">No access token</div>) :
    (
      <div className='site-container'>
        <Header 
          expanded={this.props.expanded} 
          closeAll={this.closeAll} 
          history={this.props.history} 
          location={this.props.location}
          adobeTrack={this.props.adobeTrack}
          userRole={this.props.userRole}/>
        <div className='site-content'>
          <CHRSideNav {...this.props}>
            <div className="page">
              {this.props.children}
            </div>
            <Footer isFixed={true} />
          <BaseModal/>
          </CHRSideNav>
          <ToastContainer />
        </div>
      </div>
    );
    return page;
  }
}

export const mapStateToProps = (state) => {
  const userProfile = state.users.get('userProfile');
  const userRole = userProfile ? userProfile.role : null
  return {
    accessToken: state.auth.get('accessToken'),
    userRole
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    updateTokens: () => {
      dispatch(authActions.updateTokens());
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
