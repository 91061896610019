import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { CreateIncidentForm, Incidents } from '../../components/disruptions';
import LoadingIcon from '../../components/loading-icon';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';
import baseModalActions from '../base-modal/actions';
import incidentActions from '../incidents/actions';
import mapActions from '../map/actions';

export class Disruptions extends React.Component {
  state = {
    createIncident: false,
    editIncident: false,
    startDate: moment().toDate(),
    endDate: moment().add(1, 'day').toDate(),
    disruptionName: '',
    disruptionDescription: '',
    incidentId: ''
  };

  turnDrawingModeOff = () => {
    this.props.setMapContent('incidents', true);
    this.props.setMapContent('traffic', true);
    this.props.setMapContent('weather', true);
    this.props.setMapContent('editIncidents', false);
  }

  turnDrawingModeOn = () => {
    this.props.setMapContent('incidents', false);
    this.props.setMapContent('traffic', false);
    this.props.setMapContent('weather', false);
    this.props.setMapContent('editIncidents', true);
  }

  componentDidMount() {
    this.props.stopDrawing();
    trackPageInfoEvent(this.props.adobeTrack, "Disruptions Page", ["Disruptions Page"]);
  };

  componentWillUnmount() {
    this.props.stopDrawing();
    this.turnDrawingModeOff();
  };

  viewIncident = (incident) => {
    this.props.zoomToIncident(incident);
  };

  deleteIncidentModal = (incident) => {
    const confirmDeleteModalConfig = {
      showModal: true,
      showCloseButton: true,
      showActionButton: true,
      actionButtonText: "Delete",
      actionButtonClass: "btn btn-danger",
      closeButtonText: "Cancel",
      closeButtonClass: "btn btn-light",
      handleAction: () => { this.deleteIncident(incident); },
      title: "Delete Incident?",
      body: "Are you sure you want to delete this incident?"
    };
    this.props.openModal(confirmDeleteModalConfig);
  };

  deleteIncident = (incident) => {
    this.props.deleteIncident(incident._id);
    this.props.closeModal();
  };

  editIncident = (incident) => {
    this.setState({createIncident: true, editIncident: true, incidentId: incident._id, disruptionName: incident.additionalDetails.name, disruptionDescription: incident.additionalDetails.description, startDate: moment(incident.incidentDate).toDate(), endDate: moment(incident.expirationDate).toDate()});
    this.props.startDrawing();
    this.turnDrawingModeOn();

    if(incident.affectedArea.features.length > 0) {
      this.props.saveDrawnIncidentShape(incident);
      this.props.zoomToIncident(incident);
    }
  }; 

  createIncident = () => {
    this.setState({createIncident: true, editIncident: false});
    this.props.startDrawing();
    this.props.clearDrawnIncident();
    this.props.setMapContent('incidents', false);
    this.props.setMapContent('traffic', false);
    this.props.setMapContent('weather', false);
    this.props.setMapContent('editIncidents', false);
  };

  handleSubmit = () => {
    let incident = {
      additionalDetails: {
        description: this.state.disruptionDescription,
        name: this.state.disruptionName
      },
      tags: [],
      source: 'manual',
      incidentDate: this.state.startDate,
      expirationDate: this.state.endDate,
      type: this.props.currentDrawnIncident ? 'weather' : 'news',
      affectedArea: {
        type: 'FeatureCollection',
        features: []
      }
    };

    //Incident has a shape associated with it
    if (this.props.currentDrawnIncident) {
      const incidentGeometry = this.props.currentDrawnIncident;

      //We know the user is editing an existing incident and has not changed the shape at all
      if (this.props.currentDrawnIncident.additionalDetails) {
        incident.affectedArea = incidentGeometry.affectedArea;

        //The user has changed the shape for this incident so we must mutate it
      } else {
        var updatedIncidentGeometry = {
          coordinates: [
            []
          ]
        };
        //Bing maps has a weird thing where it duplicates coordinates in a row sometimes. Elastic doesn't like this much...
        for (var i = 0; i < incidentGeometry.coordinates[0].length - 1; i++) {
          if (!((incidentGeometry.coordinates[0][i][0] === incidentGeometry.coordinates[0][i + 1][0]) &&
              (incidentGeometry.coordinates[0][i][1] === incidentGeometry.coordinates[0][i + 1][1]))) {
            updatedIncidentGeometry.coordinates[0].push(incidentGeometry.coordinates[0][i]);
          }
        }
        updatedIncidentGeometry.coordinates[0].push(incidentGeometry.coordinates[0][incidentGeometry.coordinates[0].length - 1]);
        updatedIncidentGeometry.type = incidentGeometry.type;
        updatedIncidentGeometry.properties = {
          description: this.state.disruptionDescription,
          name: this.state.disruptionName
        };
        incident.affectedArea.features.push({
          type: 'Feature',
          geometry: updatedIncidentGeometry,
          properties: {
            description: this.state.disruptionDescription,
            name: this.state.disruptionName
          }
        });
      }
    }

    if (this.state.editIncident) {
      incident.id = this.state.incidentId;
      // this is needed for news events that don't has shapes attached to them.
      if (incident.affectedArea.features && incident.affectedArea.features[0]) {
      incident.affectedArea.features[0].properties.description = this.state.disruptionDescription; 
      incident.affectedArea.features[0].properties.name = this.state.disruptionName; 
      incident.affectedArea.features[0].geometry.properties.description = this.state.disruptionDescription; 
      incident.affectedArea.features[0].geometry.properties.name = this.state.disruptionName;
      }
      this.props.updateIncident(incident)
    } else {
      this.props.createIncident(incident)
    }
    this.setState({
      createIncident: false,
      disruptionName: '',
      disruptionDescription: '',
      startDate: moment().toDate(),
      endDate: moment().add(1, 'day').toDate()
    });
    this.props.stopDrawing();
    this.props.clearDrawnIncident();
    this.props.fetchIncidents();
    this.turnDrawingModeOff();
  };

  cancelCreation = () => {
    this.setState({createIncident: false, editIncident: false, incidentId: '', disruptionName: '', disruptionDescription: '', startDate: moment().toDate(), endDate: moment().add(1, 'day').toDate()});
    this.props.stopDrawing();
    this.props.clearDrawnIncident();
    this.turnDrawingModeOff();
  };

  onDateChange = (date, changedDateType) => {
    if (changedDateType === 'endDate' && moment(date).isBefore(this.state.startDate)) {
      this.setState({[changedDateType]: date, ['startDate']: date });
    } else if (changedDateType === 'startDate' && moment(date).isAfter(this.state.endDate)) {
      this.setState({[changedDateType]: date, ['endDate']: date });
    } else {
      this.setState({[changedDateType]: date});
    }
  };

  updateName = (e) => {
    this.setState({disruptionName: e.target.value});
  };

  updateDescription = (e) => {
    this.setState({disruptionDescription: e.target.value});
  };

  clearDrawnIncident = () => {
    this.props.clearDrawnIncident();
    this.props.setMapContent('editIncidents', false);
  }

  renderIncidents() {
    return this.props.incidents.map(incident => {
      return <Incidents key={incident._id} incident={incident} name={incident.additionalDetails.name} description={incident.additionalDetails.description} source={incident.source} viewIncident={this.viewIncident} deleteIncident={this.deleteIncidentModal} editIncident={this.editIncident} permissions={this.props.userProfile.permissions}></Incidents> })
  };

  renderCreateIncidentForm() {
    return <CreateIncidentForm currentDrawnIncident={this.props.currentDrawnIncident} handleSubmit={this.handleSubmit} cancelCreation={this.cancelCreation} clearDrawnIncident={this.clearDrawnIncident} startDate={this.state.startDate} endDate={this.state.endDate} onDateChange={this.onDateChange} name={this.state.disruptionName} description={this.state.disruptionDescription} updateName={this.updateName} updateDescription={this.updateDescription}></CreateIncidentForm>
  };

  render() {
    return (
      <div>
        <div className="disruptions-header border-bottom">
          <h2>Global Disruptions</h2>
          { !this.state.createIncident && 
            this.props.userProfile.permissions &&
            this.props.userProfile.permissions.includes('CreateIncidents') &&
              <button id="createIncidentsBtn" onClick={this.createIncident} className="btn btn-primary btn-sm">Create Incident</button> }
        </div>
        { this.state.createIncident ?
          this.renderCreateIncidentForm() : this.props.loading ? <div className="centered" style={{zIndex: 1001}}><LoadingIcon></LoadingIcon></div> : this.renderIncidents()
        }
      </div>
    )
  };
}

export const mapStateToProps = (state) => {
  return {
    incidents: state.incidents.get('incidents'),
    currentDrawnIncident: state.incidents.get('currentDrawnIncident'),
    loading: state.incidents.get('loading'),
    userProfile: state.users.get('userProfile')
  };
}

export const mapDispatchToProps = (dispatch) => {
  return {
    zoomToIncident: (incident) => {
      return dispatch(mapActions.zoomToIncident(incident));
    },
    deleteIncident: (incidentId) => {
      return dispatch(incidentActions.deleteIncident(incidentId));
    },
    createIncident: (incident) => {
      return dispatch(incidentActions.createIncident(incident));
    },
    updateIncident: (incident) => {
      return dispatch(incidentActions.updateIncident(incident));
    },
    setMapContent: (key, val) => {
      return dispatch(mapActions.selectDisruptionsMapContent(key, val));
    },
    startDrawing: () => {
      return dispatch(mapActions.shouldDrawOnMap(true));
    },
    stopDrawing: () => {
      return dispatch(mapActions.shouldDrawOnMap(false));
    },
    clearDrawnIncident: () => {
      return dispatch(incidentActions.clearDrawnIncident());
    },
    saveDrawnIncidentShape: (incident) => {
      return dispatch(incidentActions.saveDrawnIncidentShape(incident));
    },
    fetchIncidents: () => {
      return dispatch(incidentActions.fetchIncidents());
    },
    openModal: (modalConfig) => {
      return dispatch(baseModalActions.openModal(modalConfig));
    },
    closeModal: () => {
      return dispatch(baseModalActions.closeModal());
    }
  };
};

export const DisruptionsContainer = connect(mapStateToProps, mapDispatchToProps)(Disruptions);
