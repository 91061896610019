import * as types from './actionTypes';

export default {
  fetchScorecard(payload) {
    return {
      type: types.FETCH_SCORECARD_STARTED,
      payload: payload
    };
  },
  fetchScorecardFailed(payload) {
    return {
      type: types.FETCH_SCORECARD_FAILED,
      payload: payload
    };
  },
  fetchScorecardComplete(payload) {
    return {
      type: types.FETCH_SCORECARD_COMPLETED,
      payload: payload
    };
  },
  fetchScorecardCarriers(searchText) {
    return {
      type: types.FETCH_SCORECARD_CARRIERS_STARTED,
      payload: searchText
    };
  },
  fetchScorecardCarriersFailed(payload) {
    return {
      type: types.FETCH_SCORECARD_CARRIERS_FAILED,
      payload: payload
    };
  },
  fetchScorecardCarriersComplete(payload) {
    return {
      type: types.FETCH_SCORECARD_CARRIERS_COMPLETED,
      payload: payload
    };
  }
};
