import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import { formRouteData } from '../tracking-summary/sagas'
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';
import * as _ from 'lodash';
import { filterTrackingLogs, sortTrackingLogs } from '../../utils/helperMethods/commonMethods'

export function* fetchTrackingLogsStart({ payload }) {
  try {
    let trackingLogs = yield call(api.shipments.getTrackingLogs, payload.loadNumber, payload.orderNumber, payload.trackingType, payload.loadNumbers, 'orders', payload.shipmentId);
    yield put(actions.processTrackingLogs(trackingLogs, { column: 'EventDate', isAscending: false }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Fetch Order Tracking Log Error',
      message: `Order Tracking Log could not be retrieved. Please try again.`,
      error: e
    }));
  }
};

export function* watchFetchTrackingLogsStart() {
  yield takeEvery(types.FETCH_ORDER_TRACKING_LOGS_STARTED, fetchTrackingLogsStart);
};

export function* processTrackingLogs({ payload }) {
  let trackingLogs = payload.trackingLogs;

  if (payload.sortingOptions) {
    trackingLogs = sortTrackingLogs(trackingLogs, payload.sortingOptions);
  }

  var filteredItems = filterTrackingLogs(trackingLogs);

  yield put(actions.fetchTrackingLogsComplete(filteredItems));
};

export function* watchProcessTrackingLogs() {
  yield takeEvery(types.FETCH_ORDER_TRACKING_LOGS_PROCESS, processTrackingLogs);
};

export function* fetchTrackingSummaryStart({ payload }) {
  try {
    const trackingSummary = yield call(api.shipments.getTrackingSummary, payload.trackingNumber, payload.trackingType, payload.orderNumber, 'orders', payload.showMultiShipmentOrder);

    let order = trackingSummary.order ?? trackingSummary.loads[0];

    yield put(actions.fetchShipmentComplete({ ...order, trackingNumber: payload.trackingNumber }, payload.orderNumber, trackingSummary.loadNumbers));

    if (trackingSummary.loads && trackingSummary.loads.length) {
      const payload = yield* formRouteData(trackingSummary, order, false);
      yield put(actions.storeRouteData(payload));
    }

    yield put(actions.fetchTrackingSummaryComplete(trackingSummary));
  } catch (e) {
    yield all([
      put(actions.fetchTrackingSummaryFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Order Tracking Summary Error',
        message: `Part or all of the tracking summary could not be retrieved. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchTrackingSummaryStart() {
  yield takeEvery(types.FETCH_ORDER_TRACKING_SUMMARY_STARTED, fetchTrackingSummaryStart);
}
