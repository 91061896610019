// 
// HELPER METHODS FOR SYNGENTA EXTERNAL_SHIPMENT_WAREHOUSE PAGE
//
import moment from 'moment';
import progressImageProgramado from '../../styles/assets/shipment_progress_bar/desktop/0_programado.svg';
import progressImageEnTransito from '../../styles/assets/shipment_progress_bar/desktop/1_entransito.svg';
import progressImageEntregado from '../../styles/assets/shipment_progress_bar/desktop/2_entregado.svg';

import { getDateTimeDayMonthYearFormat, getDate } from '../../utils/helperMethods/dateTimeMethods';


// Only lowercase status value
const progressFromStatus = {
    'booked': {
      value: 0,
      description: 'Programado'
    },
    'in transit': {
      value: 1,
      description: 'En Tránsito'
    },
    'loading': {
      value: 1,
      description: 'En Tránsito'
    },
    'unloading': {
      value: 1,
      description: 'En Tránsito'
    },
    'delivered': {
      value: 2,
      description: 'Entregado'
    }
  }

const getProgressImageLink = statusValue => {
    // Booked:Programado
    if(statusValue === 0) {
        return progressImageProgramado;
    }
    // In Transit:En Transito
    if(statusValue === 1) {
        return progressImageEnTransito;
    }
    // Delivered
    if(statusValue === 2) {
        return progressImageEntregado;
    }
}

// Get progress Image and Text from status
const getProgressFromStatus = (status) => {
    if(status && typeof status === 'string') {
        let progress = progressFromStatus[status.toLowerCase()];
        return {
            statusValue: progress.value,
            text: progress.description,
            image: getProgressImageLink(progress.value)
        }
    }
}

const getShipmentStatus = (load_status, warehouseStop) => {
  if(warehouseStop.stopCompleteDateTime) 
    return 'Delivered';
  else 
    return load_status;
};

const getWarehouseStopDate = (load_status, warehouseStop) => {
  if (load_status?.toLowerCase() === 'booked' && warehouseStop.closeDateTime)
    return getDate(warehouseStop.closeDateTime, 'DD/MM/YYYY');
  else if(warehouseStop.stopCompleteDateTime)
    return getDateTimeDayMonthYearFormat(warehouseStop.stopCompleteDateTime);
  else if(warehouseStop.calculated_ETA)
    return `${getEtaDate(warehouseStop.calculated_ETA)} ${getLocationEtaMessage(warehouseStop.calculated_ETA)}`;
}

const getOrders = (orders) => {
  if(!orders) return [];

  let result = [];
  for (let order of orders) {
    // Only pending deliveries
    if(order.status && order.status.toLowerCase() === 'delivered') 
      continue;
    
    let pendingOrder = {};

    if(order.primary_identifiers && order.primary_identifiers.length > 0) {
      let deliveryNumber = order.primary_identifiers[0].value;
      if(deliveryNumber.charAt(0).toLowerCase() === 'l') {
        pendingOrder.deliveryNumber = deliveryNumber.substring(1);
      }
    }
    
    if(pendingOrder.deliveryNumber && order.secondary_identifiers[0])
      pendingOrder.purchaseOrderNumber = order.secondary_identifiers[0].value;

    if (order.status?.toLowerCase() === 'booked' && order.stops && order.stops.length)
    {
      const destinationStop = order.stops[order.stops.length - 1];
      pendingOrder.estimatedDeliveryDate = `${getDate(destinationStop.closeDateTime, 'DD/MM/YYYY')}`;
    }
    else if(order.calculated_ETA)
      pendingOrder.estimatedDeliveryDate = `${getEtaDate(order.calculated_ETA)} ${getLocationEtaMessage(order.calculated_ETA)}`; 
 
    if(order.external_order_shipment_warehouse_path)
      pendingOrder.external_order_shipment_warehouse_path = order.external_order_shipment_warehouse_path;     

    result.push(pendingOrder);
  }
  return result;
}

const addOrdersIdentifiers = (orders) => {
  if(!orders) return [];

  for (let order of orders) {
    if(order.primary_identifiers && order.primary_identifiers.length > 0) {
      let deliveryNumber = order.primary_identifiers[0].value;
      if(deliveryNumber.charAt(0).toLowerCase() === 'l') {
        order.deliveryNumber = deliveryNumber.substring(1);
      }
    }
    
    if(order.deliveryNumber && order.secondary_identifiers[0]) {
      order.purchaseOrderNumber = order.secondary_identifiers[0].value;
    }
  }

  return orders;
}

const getEtaDate = (etaDate) => {
  if (!etaDate) { return null; }

  let dateTime = moment(new Date(etaDate), true);
  const eveningShiftEnd = 19;

  if (dateTime.hours() >= eveningShiftEnd)
  {
    dateTime = dateTime.add(1, 'days');
  }

  return dateTime.format('DD/MM/YYYY');
};

const getLocationEtaMessage = (calculatedEta) => {
  if (!calculatedEta) { return null; }
 
  const timeFormat = 'HH:mm:ss';
  const nineteenHundredUTC = moment('19:00:00', timeFormat, true);
  const thirteenHundredUTC = moment('13:00:00', timeFormat, true);
  const etaDate = new Date(calculatedEta);
  const eta = moment({hours:etaDate.getHours(), minutes:etaDate.getMinutes(), seconds:etaDate.getSeconds()}, true)

  if(eta.isBetween(nineteenHundredUTC.clone().add(-1, 'day'), thirteenHundredUTC)){
    return "Turno mañana (09:00 AM a 13:00 PM)"
  } else if (eta.isBetween(thirteenHundredUTC, nineteenHundredUTC, null, '[)')){
    return "Turno tarde (13:00 PM a 19:00 PM)"
  } else if (eta.isBetween(nineteenHundredUTC, thirteenHundredUTC.clone().add(1, 'day'), null, '[)')){
    return "Turno mañana (09:00 AM a 13:00 PM)"
  }
}

export {
    getProgressFromStatus,
    getOrders,
    getShipmentStatus,
    getWarehouseStopDate,
    addOrdersIdentifiers,
    getEtaDate,
    getLocationEtaMessage
}


