import * as types from './actionTypes';
export default {
  fetchShipmentsHealth(mode, range) {
    return {
      type: types.FETCH_HEALTH_STARTED,
      payload: {
        mode: mode,
        range: range
      }
    };
  },
  fetchInTransitHealthCompleted(payload) {
    return {
      type: types.FETCH_HEALTH_IN_TRANSIT_COMPLETED,
      payload: payload
    };
  },
  fetchOceanHealthCompleted(payload) {
    return {
      type: types.FETCH_HEALTH_OCEAN_COMPLETED,
      payload: payload
    };
  },
  fetchParcelHealthCompleted(payload) {
    return {
      type: types.FETCH_HEALTH_PARCEL_COMPLETED,
      payload: payload
    };
  },
  fetchAirHealthCompleted(payload) {
    return {
      type: types.FETCH_HEALTH_AIR_COMPLETED,
      payload: payload
    };
  },
  fetchGroundHealthCompleted(payload) {
    return {
      type: types.FETCH_HEALTH_GROUND_COMPLETED,
      payload: payload
    };
  },
  fetchInTransitHealthFailed(payload) {
    return {
      type: types.FETCH_HEALTH_IN_TRANSIT_FAILED,
      payload: payload
    };
  },
  fetchOceanHealthFailed(payload) {
    return {
      type: types.FETCH_HEALTH_OCEAN_FAILED,
      payload: payload
    };
  },
  fetchParcelHealthFailed(payload) {
    return {
      type: types.FETCH_HEALTH_PARCEL_FAILED,
      payload: payload
    };
  },
  fetchAirHealthFailed(payload) {
    return {
      type: types.FETCH_HEALTH_AIR_FAILED,
      payload: payload
    };
  },
  fetchGroundHealthFailed(payload) {
    return {
      type: types.FETCH_HEALTH_GROUND_FAILED,
      payload: payload
    };
  },
  toggleCovidActive() {
    return {
      type: types.TOGGLE_COVID_ACTIVE
    }
  },

  //#region fetchTrendingShipments
  fetchTrendingShipments() {
    return {
      type: types.FETCH_TRENDING_SHIPMENTS_STARTED,
      payload: {}
    };
  },
  fetchTrendingShipmentsFailed(payload) {
    return {
      type: types.FETCH_TRENDING_SHIPMENTS_FAILED,
      payload: payload
    };
  },
  fetchTrendingShipmentsCompleted(payload) {
    return {
      type: types.FETCH_TRENDING_SHIPMENTS_COMPLETED,
      payload: payload
    };
  },
  //#endregion

  //#region fetchLandingPageConfig
  fetchLandingPageConfig(payload) {
    return {
      type: types.FETCH_LANDING_PAGE_CONFIG_STARTED,
      payload: payload
    };
  },
  fetchLandingPageConfigCompleted(payload) {
    return {
      type: types.FETCH_LANDING_PAGE_CONFIG_COMPLETED,
      payload: payload
    };
  },
  //#endregion
  
  fetchPowerBiItems() {
    return {
      type: types.FETCH_POWER_BI_ITEMS_STARTED
    };
  },
  fetchPowerBiItemsCompleted(payload) {
    return {
      type: types.FETCH_POWER_BI_ITEMS_COMPLETED,
      payload: payload
    };
  },
  fetchPowerBiItemsFailed() {
    return {
      type: types.FETCH_POWER_BI_ITEMS_FAILED
    };
  },
}
