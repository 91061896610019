import { put, call, all } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import * as _ from 'lodash';
import actions from './actions';
import debounce from '../../utils/helperMethods/reduxHelpers';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchShipmentOrdersWarehouseSummaryStart({ payload }) { //FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_START
  try {
    const result = yield call(api.shipments.fetchExternalShipmentOrdersWarehouseSummary, payload);
    yield put(actions.fetchShipmentOrdersWarehouseSummaryComplete(result.data));
  }
  catch (e) {
    yield all([
      put(actions.fetchShipmentOrdersWarehouseSummaryFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'External Shipment Warehouse Page Data Fetch Error',
        message: 'Could not fetch External Shipment Warehouse Page Data . Please try again.',
        error: e
      }))
    ])
  }
}

export function* watchFetchShipmentOrdersWarehouseSummaryStart() {
  yield debounce(100, types.FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_STARTED, fetchShipmentOrdersWarehouseSummaryStart);
}
