/***********
HOC that wraps a component in the chr-components-labs AuthenticationWrapper,
which ensures that the current session is oAuth approved and steps the user
through oAuth if not.
***********/

import React from 'react';
import AuthenticationWrapper from './AuthenticationWrapper';
import { connect } from 'react-redux';
import userActions from '../users/actions'
import authActions from './actions';

import { selectAuthClient } from './selectors';

export default function AuthRequired(ProtectedComponent) {
  class PC extends React.Component {
    render() {
      return (
        <AuthenticationWrapper 
          authClient={this.props.authClient} 
          rbacOptions={ProtectedComponent._RBAC} 
          userRole={this.props.userRole}
          userProfile={this.props.userProfile}
          userProfileNeedsToBeInitialized={this.props.userProfileNeedsToBeInitialized}
          getUserProfile={this.props.getUserProfile}
          authed={this.props.authed}
          setAuthed={this.props.setAuthed}
          trackLogin={this.props.trackLogin}
          trackLoginComplete={this.props.trackLoginComplete}
          childProps={this.props}
        >
          <ProtectedComponent
            {...this.props}
          />
        </AuthenticationWrapper>
      );
    }
  }

  const mapStateToProps = (state) => {
    const userProfile = state.users.get('userProfile');
    const userRole = userProfile ? userProfile.role : null;
    return {
      authClient: selectAuthClient(state),
      authed: state.auth.get('authed'),
      trackLogin: state.auth.get('trackLogin'),
      userProfileNeedsToBeInitialized: state.users.get('userProfileNeedsToBeInitialized'),
      userProfile,
      userRole
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    getUserProfile: (id) => {
      dispatch(userActions.fetchUserProfile(id));
    },
    setAuthed: (authed, wasAuthed) => {
      dispatch(authActions.setAuthed(authed, wasAuthed));
    },
    trackLoginComplete: () => {
      dispatch(authActions.trackLogin(false))
    }
  });

  return connect(mapStateToProps, mapDispatchToProps)(PC);
};
