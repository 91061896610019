import React from 'react';

class RemoveFiltersConfirmationModal extends React.Component {
  render() {
    return (
      <div data-test="removeFiltersModal">
        <p>Are you sure you'd like to remove the following saved filters from your profile?</p>
        <br />
        <p>{this.props.set.label}: [{this.props.savedFilters.join(' , ')}]</p>
      </div>
    );
  };
};

export default RemoveFiltersConfirmationModal;
