import React from 'react';
import { connect } from 'react-redux';
import usersActions from '../users/actions';
import Icon from '../../components/icon/Icon';
import EventNotification from '../../components/notifications/EventNotification';
import baseModalActions from '../base-modal/actions';
import metadataActions from '../metadata/actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';
import PropTypes from 'prop-types';
import LoadingIcon from '../../components/loading-icon';

export class EventNotificationsContainer extends React.Component {


  componentDidMount() {
    if (this.props.userProfile.oktaId) {
      this.props.fetchFlows();
      this.props.fetchRegions();
      if(this.props.isShipmentTracker)
      {
        this.props.addShipmentRuleCard(this.props.shipmentNumber);
      } else {
        this.props.fetchAlertRules(this.props.userProfile.oktaId, false);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userProfile && this.props.userProfile.oktaId && (this.props.userProfile.oktaId !== prevProps.userProfile.oktaId)) {
      this.props.fetchAlertRules(this.props.userProfile.oktaId);
      this.props.fetchFlows();
      this.props.fetchRegions();
    }
  }

  createOrUpdateAlertRule = (rule) => {
    let err = false;
    if (this.props.userProfile && this.props.userProfile.oktaId) {
      const streamlinedRule = {
        name: rule.name,
        conditions: rule.conditions,
        deliveryMethods: rule.deliveryMethods,
        eventType: rule.eventType,
        enabled: rule.enabled
      };
      for (const [idx, condition] of streamlinedRule.conditions.all.entries()) {
        if (!(condition.field && condition.operator && condition.value)) {
          this.props.triggerGlobalErrorAlert({
            title: 'Error!',
            message: `You must complete all fields for your IF/AND selections`
          });
          err = true;
        }
      };
      if (streamlinedRule.name === null || streamlinedRule.name.length < 1) {
        this.props.triggerGlobalErrorAlert({
          title: 'Error!',
          message: `You must have a name for this notification.`
        });
        err = true;
      };
      if (streamlinedRule.eventType === null || streamlinedRule.eventType.length < 1) {
        this.props.triggerGlobalErrorAlert({
          title: 'Error!',
          message: `You must select when you would like to receive this notification.`
        });
        err = true;
      };
      if (streamlinedRule.deliveryMethods === null || streamlinedRule.deliveryMethods.length < 1) {
        this.props.triggerGlobalErrorAlert({
          title: 'Error!',
          message: `You must select what format of delivery you would like to receive.`
        });
        err = true;
      };
      if (err) {
        return false;
      } else {
        if (rule._id.split('-').length === 1) {
          streamlinedRule.tenantId = this.props.userProfile.tenantId;
          this.props.updateAlertRule(this.props.userProfile.oktaId, rule._id, streamlinedRule);
          return true;
        } else {
          if (this.props.isShipmentTracker) {
            this.props.createAlertRule(this.props.userProfile.oktaId, this.props.userProfile.tenantId, streamlinedRule, this.props.isShipmentTracker, this.props.shipmentNumber);
            this.props.closeModal();
          } else {
            this.props.createAlertRule(this.props.userProfile.oktaId, this.props.userProfile.tenantId, streamlinedRule, false);
          }
          return true;
        }
      }
    }
    return false;
  }

  confirmDelete = (userId, alertRuleId) => {
    if (alertRuleId.split('-').length === 1) {
      const confirmDeleteModalConfig = {
        title: 'Delete rule?',
        body: "Are you sure you want to delete this rule?",
        showModal: true,
        showCloseButton: true,
        showActionButton: true,
        actionButtonText: "Delete",
        actionButtonClass: "btn btn-danger",
        closeButtonText: "Cancel",
        closeButtonClass: "btn btn-light",
        size: "xsmall",
        handleAction: () => { this.deleteAlertRule(userId, alertRuleId); }
      };
      this.props.openModal(confirmDeleteModalConfig);
    } else {
      this.deleteAlertRule(userId, alertRuleId);
    }
  }

  deleteAlertRule = (userId, alertRuleId) => {
    this.props.deleteAlertRule(userId, alertRuleId);
    this.props.closeModal();
  }

  addNewRuleCard = () => {
    this.props.createRuleCard();
  }

  copyAlertRule = (alertRule) => {
    this.props.copyRuleCard(alertRule);
  }

  listAlertRules = () => {
    let alertRules = [];

    if(this.props.alertRules) {
      const rules = this.props.isShipmentTracker ? this.props.alertRules.filter(x => x.new).entries() :  this.props.alertRules.entries();
      for(const [idx, rule] of rules) {
        alertRules.push(
          <EventNotification
            index={idx}
            rule={rule}
            key={rule._id}
            createOrUpdateAlertRule={this.createOrUpdateAlertRule}
            deleteAlertRule={() => this.confirmDelete(this.props.userProfile.oktaId, rule._id)}
            copyAlertRule={() => this.copyAlertRule(rule)}
            mobilePhoneIsVerified={this.props.userProfile.mobilePhoneIsVerified}
            flows={
              this.props.flows.filter((f, i) => {
                return this.props.flows.indexOf(f) === i
              })
            }
            regions={this.props.regions ? this.props.regions.buckets.map(x => x.key) : []}
            isShipmentTracker={this.props.isShipmentTracker}
          />
        );
      }
    }
    return alertRules;
  }

  render() {
    return (
      <div className="page container-fluid flex-one">
        <div className="row">
          <div className="col-md-3">
          {!this.props.isShipmentTracker &&<h1>Event Notifications</h1>}
          </div>
        </div>
        <div className="row">
          {this.props.alertRulesLoading ?
            <div className="centered"><LoadingIcon></LoadingIcon></div> :

            <div className="col-md-3" style={{paddingBottom: "10px"}}>
             {!this.props.isShipmentTracker && <button onClick={this.addNewRuleCard} className="btn btn-success" id={'add-event-notification-button'}>
                <Icon className="icon white" type="add"/>
                Add New
              </button>}
            </div>
          }
        </div>
        {
          this.listAlertRules()
        }
      </div>
    );
  }
}

EventNotificationsContainer.propTypes = {
  alertRules: PropTypes.array,
  closeModal: PropTypes.func,
  createAlertRule: PropTypes.func,
  createRuleCard: PropTypes.func,
  copyRuleCard: PropTypes.func,
  deleteAlertRule: PropTypes.func,
  fetchAlertRules: PropTypes.func,
  openModal: PropTypes.func,
  triggerGlobalErrorAlert: PropTypes.func,
  updateAlertRule: PropTypes.func,
  userProfile: PropTypes.object
}

EventNotificationsContainer.defaultProps = {
  alertRules: [],
  closeModal: () => { },
  createAlertRule: () => { },
  createRuleCard: () => { },
  addShipmentRuleCard: () => {},
  copyRuleCard: (rule) => { },
  deleteAlertRule: () => { },
  fetchAlertRules: () => { },
  openModal: () => { },
  triggerGlobalErrorAlert: () => { },
  updateAlertRule: () => { },
  userProfile: {}
}

export const mapStateToProps = (state) => {
  return {
    userProfile: state.users.get('userProfile'),
    alertRules: state.users.get('alertRules'),
    flows: state.tenantMetadata.get('flows').map(f => { return f.name }),
    regions: state.tenantMetadata.get('regions').regions,
    alertRulesLoading: state.users.get('alertRulesLoading')
  }
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchAlertRules: (oktaId) => {
      dispatch(usersActions.fetchAlertRules(oktaId));
    },
    createAlertRule: (oktaId, tenantId, alertRule, isShipmentTracker, shipmentNumber) => {
      dispatch(usersActions.createAlertRule(oktaId, tenantId, alertRule, isShipmentTracker, shipmentNumber));
    },
    updateAlertRule: (oktaId, alertRuleId, alertRule) => {
      dispatch(usersActions.updateAlertRule(oktaId, alertRuleId, alertRule));
    },
    createRuleCard: () => {
      dispatch(usersActions.createRuleCard());
    },
    copyRuleCard: (alertRule) => {
      dispatch(usersActions.copyRuleCard(alertRule));
    },
    deleteAlertRule: (oktaId, alertRuleId) => {
      dispatch(usersActions.deleteAlertRule(oktaId, alertRuleId));
    },
    openModal: (modalConfig) => {
      dispatch(baseModalActions.openModal(modalConfig));
    },
    closeModal: () => {
      dispatch(baseModalActions.closeModal());
    },
    triggerGlobalErrorAlert: (payload) => {
      dispatch(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(payload));
    },
    fetchFlows: () => {
      dispatch(metadataActions.fetchTenantFlows());
    },
    fetchRegions: () => {
      dispatch(metadataActions.fetchTenantRegion());
    }, 
    addShipmentRuleCard: (shipmentNumber) => {
      dispatch(usersActions.addShipmentRuleCard(shipmentNumber));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventNotificationsContainer);
