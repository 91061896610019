import React from 'react';

function render(props){
  let components = [];
  components.push(<div id='headergrouplistkey' key='headergrouplistkey' className="conversation group list-item header">{props.users.length?props.users.length:0} total users</div>)
  if(props.users){
    for(let i = 0; i < props.users.length;i++){
      components.push(
      <div id={'grouplistkey' + i} key={i+'grouplistkey'} className="conversation group list-item">
      <img
        src={props.users[i].gravatarUrl}
        className="Profile-img"
        alt="user icon"
        height={40}
        width={40}/>
        {' ' + props.users[i].displayName}
        </div>);
    }
  }
  return components;
}
export default function GroupList(props){
  return (
    <div>
      {render(props)}
    </div>
  );
}
