import { put, call, all, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import * as _ from 'lodash';
import actions from './actions';
import debounce from '../../utils/helperMethods/reduxHelpers';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';
import { formRouteData } from '../tracking-summary/sagas'
import { filterTrackingLogs, isOrionOrder, sortTrackingLogs } from '../../utils/helperMethods/commonMethods'

export function* fetchTrackingShipmentStart({ payload }) {
  try {
    const response = yield call(api.shipments.fetchTrackingShipment, payload);
    let shipment;

    if (response.loads) {
      const routeData = yield formRouteData(response, response.loads[0], false);
      yield put(actions.storeRouteData(routeData));

      if (isOrionOrder(response.loads[0])) {
        shipment = response.loads.find(l => {
          return (l.vision_tracking_number == response.vision_tracking_number);
        });
      } else {
        shipment = response.loads[0];
      }
    }

    yield put(actions.fetchTrackingShipmentComplete(response.loads, shipment, response.loadNumbers));
  }
  catch (e) {
    yield all([
      put(actions.fetchTrackingShipmentFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Failure!',
        message: 'Could not fetch shipment. Please try again.',
        error: e
      }))
    ]);
  }
}

export function* watchFetchTrackingShipmentStart() {
  yield debounce(100, types.FETCH_TRACKING_SHIPMENT_STARTED, fetchTrackingShipmentStart);
}

export function* fetchTrackingMilestonesStart({ payload }) {
  try {
    const response = yield call(api.shipments.fetchTrackingMilestones, payload);
    yield put(actions.fetchTrackingMilestonesComplete(response));
  }
  catch (e) {
    yield all([
      put(actions.fetchTrackingMilestonesFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Failure!',
        message: 'Could not fetch tracking milestones. Please try again.',
        error: e
      }))
    ]);
  }
}

export function* watchFetchTrackingMilestonesStart() {
  yield debounce(100, types.FETCH_TRACKING_MILESTONES_STARTED, fetchTrackingMilestonesStart);
}

export function* processTrackingLogs({ payload }) {
  let trackingLogs = payload.trackingLogs;

  if (payload.sortingOptions) {
    trackingLogs = sortTrackingLogs(trackingLogs, payload.sortingOptions);
  }

  var filteredTrackingLogs = filterTrackingLogs(trackingLogs);

  yield put(actions.fetchTrackingMilestonesComplete(filteredTrackingLogs));
};

export function* watchProcessTrackingLogs() {
  yield takeEvery(types.TRACKING_MILESTONES_SORT, processTrackingLogs);
};
