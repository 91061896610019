import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { getDate, getTime, getTimeUtc, isTimeUtc } from '../../utils/helperMethods/dateTimeMethods';
import { tooltip, popover } from '../../utils/helperMethods/commonComponents';
import {UtcPopOver} from '../../components/popovers/UtcPopover';
import { showContainers, showParcels, totalPackageCount } from '../../utils/helperMethods/containerUtils';
import { JOB_MODES, DETAILS_REQUESTED_DATE_HIDDEN_TENANTS, HIDE_ORDER_NUMBER, DISPLAY_FORWARDER_TENANTS, CLIENT_ID_EXIST_TENANTS } from '../../utils/constants';
import * as shipmentMethods from '../../utils/helperMethods/shipmentMethods';
import * as commonMethods from '../../utils/helperMethods/commonMethods';
import PiecesAndWeight from '../pieces-and-weight';

const isOceanMode = (shipment) => shipment.mode === 'Ocean';

const isGfs = origination => {
  return origination === 3;
};

const getOriginActualHeader = shipment => {
  if (isGfs(shipment.origination)) {
    return 'Actual';
  } else {
    return 'Complete';
  }
};

const getOriginActual = shipment => {
  if (isGfs(shipment.origination)) {
    return shipment.origin_arrived;
  } else {
    return shipment.pickup_date;
  }
};

const getDestinationComplete = shipment => {
  if (isGfs(shipment.origination)) {
    if (shipment.stops) {
      let drop = shipment.stops.find(stop => stop.stop_role === 2);
      if (drop !== undefined) {
        return drop.actual_depart_datetime;
      }
    }
  } else {
    return shipment.delivery_date;
  }
  return null;
};

const getEta = shipment => {
  if (isGfs(shipment.origination)) {
    return shipment.destination_ETA;
  } else {
    return shipment.calculated_ETA;
  }
};

const getTrackerButton = (permissions, jobMode, props) => {
  if (!permissions || !permissions.length) {
    return <></>;
  }

  if ((jobMode === JOB_MODES.orders && permissions.includes('ViewOrderTracker')) ||
    (jobMode === JOB_MODES.shipments && permissions.includes('ViewShipmentTracker'))) {
    let trackId = null;
    if (jobMode === JOB_MODES.shipments) {
      trackId = `track-${commonMethods.determineShipmentNumber(props)}-shipment-button`;
    } else if (jobMode === JOB_MODES.orders) {
      trackId = `track-${props.id}-order-button`;
    }
    return (
      <a
        id={trackId}
        onClick={() => shipmentMethods.trackShipment(props, jobMode)}
        className="btn btn-primary btn-sm tracker-link">
        View Details
      </a>
    )
  } else {
    return <></>;
  }
}

const timeDisplayWrapper = (time, name, id, isUtc) => (
  <div className="read-only date-time">
    <span className="read-only-label">{name} <UtcPopOver isUtc ={isUtc}/></span>
    <span id={`${id}date`} className="read-only-value">
      {time ? getDate(time) : '\xa0'}
    </span>
    <span id={`${id}time`} className="read-only-time">
      {time ? getTimeUtc(time, isUtc) : '-'}
    </span>
  </div>
);

const getOriginTimes = (shipment) => {
  const completeDate = getOriginActual(shipment);
  const requested = timeDisplayWrapper(shipment.origin_requested, 'Requested', 'originrequested', false);
  const scheduled = timeDisplayWrapper(shipment.origin_Sched_date_close, 'Scheduled', 'originscheddateclose', false);
  const complete = timeDisplayWrapper(completeDate, getOriginActualHeader(shipment), 'pickup');

  return (
    <div className='origin-times'>
      {!DETAILS_REQUESTED_DATE_HIDDEN_TENANTS.includes(shipment.tenantId) && requested}
      {scheduled}
      {complete}
    </div>
  );
};

const getDestinationTimes = shipment => {
  const requested = timeDisplayWrapper(shipment.destination_requested, 'Requested', 'destinationrequested', false);
  const scheduled = timeDisplayWrapper(shipment.destination_Sched_date_close, 'Scheduled', 'destinationscheddateclose', false);

  const completedTime = getDestinationComplete(shipment);

  if (!!completedTime || !!shipment.destination_arrived) {

    const completed = timeDisplayWrapper(completedTime, 'Complete', 'delivery');
    const arrived = timeDisplayWrapper(shipment.destination_arrived, 'Arrived', 'destinationarrived');

    return (
      <div className="destination-times">
        {!DETAILS_REQUESTED_DATE_HIDDEN_TENANTS.includes(shipment.tenantId) && requested}
        {scheduled}
        {arrived}
        {completed}
      </div>
    );
  }

  const finalEtaDate = getEta(shipment);
  const showEtaDate = !!finalEtaDate;

  const finalEta = showEtaDate && (
    <div className="read-only">
      <span className="read-only-label">
        Final ETA&nbsp;
        <OverlayTrigger placement="top" overlay={popover('Final ETA', 'Estimated arrival time at the destination based on the most recent location update and historical delivery trends.', 'etapopover')}>
          <svg className="icon small faded">
            <use href="#help" style={{ pointerEvents: 'none' }} />
          </svg>
        </OverlayTrigger>
        {shipment.show_ETA_source && !isGfs(shipment.origination) &&
          <OverlayTrigger placement="top" overlay={popover('Source: Data Science', 'Estimated time of arrival is determined using Navisphere Vision\'s data science pipeline.', 'dspopover')}>
            <svg className="icon small faded data-science-icon">
              <use href="#data-science" style={{ pointerEvents: 'none' }} />
            </svg>
          </OverlayTrigger>
        }
      </span>
      <span id="etadate" className="read-only-value">
        {finalEtaDate ? getDate(finalEtaDate) : '\xa0'}
      </span>
      <span id="etatime" className="read-only-time">
        {finalEtaDate ? getTime(finalEtaDate) : '-'}
      </span>
    </div>
  );

  return (
    <div className="destination-times">
      {!DETAILS_REQUESTED_DATE_HIDDEN_TENANTS.includes(shipment.tenantId) && requested}
      {scheduled}
      {showEtaDate ? finalEta : <></>}
    </div>
  );
};

const RegionInformation = (props) => {
  let tenantLabel = '/Carrier';
  let tenantDisplay = props.carrier_name;

  if (DISPLAY_FORWARDER_TENANTS.includes(props.tenantId)) {
    tenantLabel = props.forwarder != null ? '/Forwarder' : null;
    tenantDisplay = props.forwarder;
  }

  return (
    <div className="read-only">
      <span id="regionmodecarrierlabel" className="read-only-label">Region/Mode{tenantLabel}</span>
      <span id="regionmodecarriertext" className="read-only-value">
        {
          [props.region, (props.mode_display || props.mode), tenantDisplay].filter(Boolean).join("/")
        }
      </span>
    </div>
  );
}

export default function ListViewShipmentCard(props) {
  let secondaryIdentifiers = commonMethods.getUniqueIdentifiers(props.secondary_identifiers);
  let containsRestrictedMaterial = commonMethods.containsRestrictedMaterial(props.items, props.reference_numbers);
  return (
    <div id={`shipment-${commonMethods.determineShipmentNumber(props)}-card`} className={shipmentMethods.styles(props).outerCardClassNames}>
      <div className="card-container">
        <div className="identifiers-container">
          {!shipmentMethods.isHidden(props.primary_identifiers) && (
            <div className="reference-number read-only">
              <span id="primaryidlabel" className="read-only-label">
                {shipmentMethods.getIdentifierLabel(props.primary_identifiers)}
              </span>
              <span id="primaryids" title={shipmentMethods.getIdentifierValues(props.primary_identifiers, shipmentMethods.identifierLimit)} className="read-only-value">
                {shipmentMethods.getIdentifierValues(props.primary_identifiers, shipmentMethods.identifierLimit)}
              </span>
              {props.primary_identifiers && props.primary_identifiers.length > shipmentMethods.identifierLimit && (
                <a id="primaryidsellipses" title="Show More" className="btn-icon" onClick={() => props.showModal(shipmentMethods.createModalConfig(props.primary_identifiers))}>
                  <svg className="icon">
                    <use href="#ellipses-horizontal" />
                  </svg>
                </a>
              )}
            </div>
          )}
          {!shipmentMethods.isHidden(props.secondary_identifiers) && (
            <div className="reference-number read-only">
              <span id="secondaryidlabel" className="read-only-label">
                {shipmentMethods.getIdentifierLabel(props.secondary_identifiers)}
              </span>
              <span id="secondaryids" title={shipmentMethods.getIdentifierValues(secondaryIdentifiers, shipmentMethods.identifierLimit)} className="read-only-value">
                {shipmentMethods.getIdentifierValues(secondaryIdentifiers, shipmentMethods.identifierLimit)}
              </span>
              {secondaryIdentifiers && secondaryIdentifiers.length > shipmentMethods.identifierLimit && (
                <a id="secondaryidsellipses" title="Show More" className="btn-icon" onClick={() => props.showModal(shipmentMethods.createModalConfig(secondaryIdentifiers))}>
                  <svg className="icon">
                    <use href="#ellipses-horizontal" />
                  </svg>
                </a>
              )}
            </div>
          )}
          {!shipmentMethods.isHidden(props.tertiary_identifiers) && !HIDE_ORDER_NUMBER.includes(props.tenantId) && (
            <div className="reference-number read-only">
              <span id="tertiaryidlabel" className="read-only-label">
                {shipmentMethods.getIdentifierLabel(props.tertiary_identifiers)}
              </span>
              <span id="tertiaryids" title={shipmentMethods.getIdentifierValues(props.tertiary_identifiers, shipmentMethods.identifierLimit)} className="read-only-value">
                {shipmentMethods.getIdentifierValues(props.tertiary_identifiers, shipmentMethods.identifierLimit)}
              </span>
              {props.tertiary_identifiers && props.tertiary_identifiers.length > shipmentMethods.identifierLimit && (
                <a id="tertiaryidsellipses" title="Show More" className="btn-icon" onClick={() => props.showModal(shipmentMethods.createModalConfig(props.tertiary_identifiers))}>
                  <svg className="icon">
                    <use href="#ellipses-horizontal" />
                  </svg>
                </a>
              )}
            </div>
          )}
          {!CLIENT_ID_EXIST_TENANTS.includes(props.tenantId) ? (
            <div className="reference-number read-only">
              <span className="read-only-label">Shipment Number</span>
              <span id="loadnumber" className="read-only-value">
                {commonMethods.determineShipmentNumber(props)}
              </span>              
            </div>
          ) : (<div className="reference-number read-only">
            <span className="read-only-label">ASN</span>
            <span id="asn" className="read-only-value">
              {commonMethods.getAsnValues(props.handling_units)}
            </span>
          </div>)}
          <PiecesAndWeight shipment={props} />

          {(props.is_haz_mat || props.live_tracked || props.high_value || props.iot_tracked || containsRestrictedMaterial || props.expedite) && (
            <div className="read-only icon-container">
              {props.is_haz_mat && (
                <OverlayTrigger placement="top" overlay={tooltip('Hazardous Material', 'hazmattooltip')}>
                  <svg id="hazmaticon" className="icon space-right" data-toggle="tooltip" data-placement="top" data-original-title="Hazardous Materials">
                    <use href="#hazmat" />
                  </svg>
                </OverlayTrigger>
              )}
              {props.live_tracked && (
                <OverlayTrigger placement="top" overlay={tooltip('GPS Tracked', 'gpstrackedtooltip')}>
                  <svg id="livetrackedicon" className="icon space-right" data-toggle="tooltip" data-placement="top" data-original-title="GPS Tracked">
                    <use href="#gps" />
                  </svg>
                </OverlayTrigger>
              )}
              {props.iot_tracked && (
                <OverlayTrigger
                  placement="top"
                  overlay={tooltip('IoT Tracked', 'iottrackedtooltip')}
                >
                  <svg
                    id="iottrackedicon"
                    className="icon space-right"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="IoT Tracked"
                  >
                    <use href="#iot" />
                  </svg>
                </OverlayTrigger>
              )}
              {props.high_value && (
                <OverlayTrigger placement="top" overlay={tooltip('High Value', 'highvaluetooltip')}>
                  <svg id="highvalueicon" className="icon space-right" data-toggle="tooltip" data-placement="top" data-original-title="High Value">
                    <use href="#value" />
                  </svg>
                </OverlayTrigger>
              )}
              {containsRestrictedMaterial && (
                <OverlayTrigger placement="top" overlay={tooltip('Restricted Material', 'restrictedmaterialtooltip')}>
                  <svg id="restrictedmaterialicon" className="icon space-right" data-toggle="tooltip" data-placement="top" data-original-title="Restricted Material">
                    <use href="#restricted-material" />
                  </svg>
                </OverlayTrigger>
              )}
              {props.expedite && (
                <OverlayTrigger placement="top" overlay={tooltip('Expedited', 'restrictedmaterialtooltip')}>
                  <svg id="expediteicon" className="icon space-right" data-toggle="tooltip" data-placement="top" data-original-title="Expedited">
                    <use href="#expedite" />
                  </svg>
                </OverlayTrigger>
              )}
            </div>
          )}
        </div>
        <div className="flex card-content-container">
          <div className="tracker-button-container">
            {getTrackerButton(props.permissions, props.jobMode, props)}
          </div>
          <div className={props.displayMode === 'splitView' ? "split-origin-destination-status" : "origin-destination-status"}>
            <div className={props.displayMode === 'splitView' ? "row flex split-origin-destination" : "row flex split-origin-destination"}>
              <div className="col-lg-5 col-sm-6 col-xs-12">
                <div className="origin-content">
                  <h4 className="uppercase bottom-border">Origin</h4>
                  <div className='origin-name'>
                    {props.order_origin_code && props.origin_code && props.order_origin_code !== props.origin_code ? (
                      <div className="read-only">
                        <span id="orderoriginname" className="pick-drop-name-text">
                          {props.order_origin_name}
                        </span>
                        <hr className="invisible-break" />
                        <span id="orderorigincitystatecountry" className="pick-drop-loc-text">
                          {`${props.order_origin_city}, ${props.order_origin_state}, ${props.order_origin_country}`}
                        </span>
                      </div>
                    ) : (
                      <div className="read-only">
                        <span id="originname" className="pick-drop-name-text">
                          {(props.origin_name || props.origin_facility_name)}
                        </span>
                        <hr className="invisible-break" />
                        <span id="origincitystatecountry" className="pick-drop-loc-text">
                          {props.origin_city}, {props.origin_state}, {props.origin_country}
                        </span>
                      </div>
                    )}
                  </div>
                  {getOriginTimes(props)}
                </div>
              </div>
              <div className="col-lg-7 col-sm-6 col-xs-12">
                <div className="destination-content">
                  <h4 className="uppercase  bottom-border">Destination</h4>
                  <div className='destination-name'>
                    {props.order_destination_code && props.destination_code && props.order_destination_code !== props.destination_code ? (
                      <div className="read-only">
                        <span id="orderdestinationname" className="pick-drop-name-text">
                          {props.order_destination_name}
                        </span>
                        <hr className="invisible-break" />
                        <span id="orderdestinationcitystatecountry" className="pick-drop-loc-text">
                          {props.order_destination_city}, {props.order_destination_state}, {props.order_destination_country}
                        </span>
                      </div>
                    ) : (
                      <div className="read-only">
                        <span id="destinationname" className="pick-drop-name-text">
                          {(props.destination_name || props.destination_facility_name)}
                        </span>
                        <hr className="invisible-break" />
                        <span id="destinationcitystatecountry" className="pick-drop-loc-text">
                          {props.destination_city}, {props.destination_state}, {props.destination_country}
                        </span>
                      </div>
                    )}
                  </div>
                  {getDestinationTimes(props)}
                </div>
              </div>
            </div>
            <div className={props.displayMode === 'splitView' ? "split-status-container" : "status-container"}>
              <h4 className="uppercase">Status</h4>
              <div className={props.displayMode === 'splitView' ? "split-status-container-fields" : "status-container-fields"}>
                <div className="read-only">
                  <span className="read-only-label">Risk Level</span>
                  <span id="risklevelstatus" className="read-only-value">
                    {props.status ? props.status : '--'}
                  </span>
                </div>
                <div className="read-only">
                  <span className="read-only-label">Movement</span>
                  <span id="currentlocationstatus" className="read-only-value">
                    {props.current_location_status}{isGfs(props.origination) && props.detail_status && ` - ${props.detail_status}`}
                  </span>
                </div>

                <RegionInformation {...props} />

                {props.exception_controllability && (
                  <div className="read-only">
                    <span className="read-only-label">Reason</span>
                    <span id="reasoncodetext" className="read-only-value">
                      {props.exception_controllability} {props.exception_description}
                    </span>
                  </div>
                )}
                {getVesselLabel(props)}
                {showContainers(props) && (
                  <div className="read-only" >
                    <span className="read-only-label">Containers</span>
                    <span id="regionmodecontainerCount" className="read-only-value">
                      {(props.container_information && props.container_information.length > 0)
                        ? (props.container_information && props.container_information.length > 0
                          ? new Set(props.container_information.map(container_information => container_information.container_number)).size
                          : '\u2014')
                        : (props.handling_units && props.handling_units.length > 0
                          ? new Set(props.handling_units.map(handling_units => handling_units.container_number)).size
                          : '\u2014')
                      }
                    </span>
                  </div>
                )}
                {showParcels(props) && (
                  <div className="read-only" >
                    <span className="read-only-label">Total Packages</span>
                    <span id="totalpackagecount" className="read-only-value" data-test="totalPackageCount">
                      {totalPackageCount(props)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getVesselLabel(shipment) {
  if (!isOceanMode(shipment)) {
    return <></>;
  }

  let label;
  let value;
  if (shipment.vessel_name) {
    label = 'Vessel Name';
    value = shipment.vessel_name;
  } else if (shipment.vessel_imo_number) {
    label = 'Vessel IMO';
    value = shipment.vessel_imo_number;
  } else if (shipment.vessel_name_historical) {
    label = 'Vessel Name (Disembarked)';
    value = shipment.vessel_name_historical;
  } else if (shipment.vessel_imo_number_historical) {
    label = 'Vessel IMO (Disembarked)';
    value = shipment.vessel_imo_number_historical;
  } else {
    label = 'Unknown Vessel'
    value = '\u2014'
  }

  return (
    <div className="read-only" >
      <span className="read-only-label">{label}</span>
      <span id="regionmodecarriertext" className="read-only-value">
        {value}
      </span>
    </div>
  )
}
