const filterGenerator = (mappings) => {

  var payload = {
    filterConfiguration: {},
    filters: {},
    incidents: [],
    facilityFilters: []
  }

  if (mappings && mappings.length > 0) {
    for (const category of mappings) {
      for (const section of category.sections) {
          payload.filterConfiguration[section.elasticSearchFieldName] = {
            label: section.label,
            searchable: section.searchable,
            path: section.path,
            view: section.view
          }
          payload.filters[section.elasticSearchFieldName] = [];
      }
    }
  }

  return payload;
}

export default filterGenerator;
