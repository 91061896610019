import React from 'react';
import actions from '../../containers/shipments/actions'
import { connect } from 'react-redux';
import { CLOUDY_VISION_TENANTS } from '../../utils/constants';

export class AnnouncementContainer extends React.Component {
  render() {
    return (
      this.props.active && this.props.userProfile && CLOUDY_VISION_TENANTS.includes(this.props.userProfile.tenantId) &&
      <div className="row outage-alert">
        <div className="col-lg-12 outage">
          <h4><i className="glyphicon glyphicon-alert"></i>  <span class="covid-warn">Service Update:</span><i className="glyphicon glyphicon-remove covid-dismiss" onClick={() => this.props.toggleActive()} /></h4>
          <div>Currently, we're experiencing a temporary interruption in our shipment services. Our team is actively working to resolve the issue and restore normal operations. Please anticipate potential delays or unavailability in shipment details during this process. Your patience and understanding are sincerely appreciated. Thank you.</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  active: state.shipments.data.get('announcementActive')
});

const mapDispatchToProps = (dispatch) => ({
  toggleActive: () => { dispatch(actions.toggleOutageAnnouncementActive()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementContainer);
