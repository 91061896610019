import React from 'react';

export class ReferencesContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  renderReferences = (references, referenceLevel) => {
    if (Array.isArray(references) && references.length > 0) {

      if(referenceLevel) {        
        references = references.filter((x) => {
          return referenceLevel.includes(x.level);          
        });        
      }

      const sortedReferences = references.sort((objA, objB) => {
        if (objA.description > objB.description) {
          return 1;
        }
        if (objA.description < objB.description) {
          return -1;
        }
        return 0;
      });

      return (        
        <table data-testid="shipmentReferencesTable" className="table table-striped table-condensed">
          <thead>
            <tr>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {              
              sortedReferences.map((reference) => {
                return (
                  <tr key={reference.value}>
                    <td className="overflow">{reference.description}</td>
                    <td>{reference.value}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      );
    } else {
      return (<div>No references were found.</div>);
    }
  }

  render() {
    return (
      <div className="col-lg-12">
        <div className="card card-scroll card-md">
          {
            this.renderReferences(this.props.references, this.props.referenceLevel)
          }
        </div>
      </div>
    );
  }
}


export default (ReferencesContainer);

