  export const FETCH_INCIDENTS_STARTED = 'incidents/FETCH_INCIDENTS_STARTED';
  export const FETCH_INCIDENTS_FAILED = 'incidents/FETCH_INCIDENTS_FAILED';
  export const FETCH_INCIDENTS_COMPLETED = 'incidents/FETCH_INCIDENTS_COMPLETED';
  export const DELETE_INCIDENT_STARTED = 'incidents/DELETE_INCIDENT_STARTED';
  export const DELETE_INCIDENT_COMPLETED = 'incidents/DELETE_INCIDENT_COMPLETED';
  export const SAVE_DRAWN_INCIDENT_SHAPE = 'incidents/SAVE_DRAWN_INCIDENT_SHAPE';
  export const CLEAR_DRAWN_INCIDENT = 'incidents/CLEAR_DRAWN_INCIDENT';
  export const CREATE_INCIDENT_STARTED = 'incidents/CREATE_INCIDENT_STARTED';
  export const CREATE_INCIDENT_COMPLETE = 'incidents/CREATE_INCIDENT_COMPLETE';
  export const UPDATE_INCIDENT_STARTED = 'incidents/UPDATE_INCIDENT_STARTED';
  export const UPDATE_INCIDENT_COMPLETE = 'incidents/UPDATE_INCIDENT_COMPLETE';