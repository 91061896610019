import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';
import { getUserProfile } from '../containers/auth/authHelper';

export default {
  async fetchFacilities(tenantId) {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const userProfile = await getUserProfile();
      const request = await clientFactory(endpoint);
      const result = await request.get(`tenants/${tenantId || userProfile.tenantId}/facilities`);
			return result;
    } catch (err) {
      throw `Facilities ${err}`;
    }
  },
  async fetchFlows(tenantId) {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const userProfile = await getUserProfile();
      const request = await clientFactory(endpoint);
      const result = await request.get(`tenants/${tenantId || userProfile.tenantId}/flows`);
			return result;
    } catch (err) {
      throw `Flows ${err}`;
    }
  },
  async fetchChannels(tenantId) {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`tenants/${tenantId}/channels`);
			return result;
    } catch (err) {
      throw `Channels ${err}`;
    }
  },
  async fetchAccounts(tenantId) {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`tenants/${tenantId}/accounts`);
			return result;
    } catch (err) {
      throw `Accounts ${err}`;
    }
  },
  async fetchExternalAccounts(tenantId) {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`tenants/${tenantId}/externalAccountCodes`);
      if (result.data && result.data.length) {
        result.data = result.data.map(x => {
          return {
            code: x.externalAccountCodes && x.externalAccountCodes.externalAccountCode,
            name: x.externalAccountCodes && x.externalAccountCodes.externalCustomerName
        }});
      }
      
			return result;
    } catch (err) {
      throw `External Account Codes ${err}`;
    }
  },
  async fetchTenants() {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get('tenants');
			return result;
    } catch (err) {
      throw `Tenants ${err}`;
    }
  },
  async fetchDynamicFilters() {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    const userProfile = await getUserProfile();
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`tenants/${userProfile.tenantId}/dynamicFilters`);
			return result;
    } catch (err) {
      throw `Dynamic Filters ${err}`;
    }
  },
  async fetchExportSelections() {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    const userProfile = await getUserProfile();
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`tenants/${userProfile.tenantId}/exportSelections`);
			return result;
    } catch (err) {
      throw ` Export Selections${err}`;
    }
  },
  async fetchAccessFilters(tenantId) {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      return await request.get(`tenants/${tenantId}/accessFilters`);
    } catch (err) {
      throw `Access Filters ${err}`;
    }
  },
  async fetchLandingPageConfig(tenantId) {
    const config = await getConfiguration();
    const endpoint = config.metadataApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      return await request.get(`tenants/${tenantId}/landingPageConfig`);
    } catch (err) {
      throw `Landing Page Config load ${err}`;
    }
  }
};
