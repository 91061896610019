import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import usersApi from '../../apis/users';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';
import { connect } from 'react-redux';
import emoji from "@jukben/emoji-search";

export class CommentsAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      search: ''
    };
  }
  state = {
    value: '',
    search: ''
  };
  defaultPayload = {
    currentPage: 1,
    limit: 100,
    offset: 0,
    searchText: ""
  };
  defaultStyle = {
    control: {
      backgroundColor: '#fff',
      borderTop: '1px solid #c4c4c4',
      position: 'relative'
    },
    highlighter: {
      overflow: 'hidden',
      top: '-5px'
    },
    input: {
      padding: '10px 40px 5px 5px',
      border: '2px inset'
    },
    '&multiLine': {
      control: {
        border: '1px solid silver',
        fontSize: '14pt'
      },
      highlighter: {
        padding: '15px 40px 5px 5px'
      },
      input: {
        background: 'rgba(0, 0, 0, 0) none repeat scroll 0 0',
        border: 'medium none',
        color: '#4c4c4c',
        fontSize: 15,
        minHeight: 40,
        width: '100%',
        outline: 'none'
      }
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        overflowY: 'auto',
        position: 'relative',
        minWidth: '200px',
        maxHeight: '185px'
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5'
        }
      }
    }
  }
  renderEmojiSuggestion = (suggestion, search, highlightedDisplay, index, focused) => {
    return (
      <div>{highlightedDisplay}</div>
    );
  }
  renderUserSuggestion = (suggestion, search, highlightedDisplay, index, focused) => {
    return (
      <div className={`user ${focused ? 'focused' : ''}`}>
        {highlightedDisplay} | {suggestion.email}
      </div>
    );
  }
  mapUsers = (search, callback) => {
    //it's gross to call the API directly, but we need to have data returned before we leave this function.
    // wrapping it in redux is more gross, so let's compromise.
    if (!search) return;
    let payload = {
      ...this.defaultPayload,
      limit: 10,
      searchText: search.replace('.', ' ')
    };
    let usersApiPromise = new Promise (async (resolve, reject) => {
      try {
        let resp = await usersApi.fetchUsers(payload);
        resolve(resp.users);
      } catch (e) {
        reject(e);
      }
    });
    usersApiPromise
      .then(users => users ? 
        users.map(user => {
          const userName = user.user_metadata.name ? user.user_metadata.name : [user.given_name, user.family_name].join('.');
          const email = user.email;
          return {
            display: userName,
            email,
            id: [user.user_id, user.picture].join('thisIsTaylorsCustomDelimiter')
          }
        })
        : []
      ).catch(e => 
        {
          this.props.triggerGlobalErrorAlert({
            title: 'Error!',
            message: `Could not retrieve user mention list: ${e}`
          });
          return [];
        }
      )
      .then(callback)

  }
  mapEmoji = (search) => {
    return emoji(search).map(emoji => {
      return {
        display: emoji.char,
        id: emoji.name
      }
    }).slice(0, 10);
  }
  displayTransform = (id, display, type) => {
    if (type === 'user') {
      return `@${display}`
    } else if (type === 'emoji') {
      return `${display}`
    }
  }
  handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.addComment();
    };
  }
  render() {
    return (
      <MentionsInput
        value={this.props.commentValue}
        onChange={this.props.handleCommentChange}
        style={this.defaultStyle}
        markup={"@[__display__](__type__:__id__)"}
        displayTransform={this.displayTransform}
        className="conversations-input"
        placeholder={"Start typing to send a new comment. Type @ to mention people directly."}
        onKeyDown={this.handleOnKeyDown}
        maxLength = "500"
      >
        <Mention
          type="user"
          trigger="@"
          data={this.mapUsers}
          renderSuggestion={this.renderUserSuggestion}
          style={{ backgroundColor: '#d0d3d8' }}
          onAdd={this.props.mentionAdded}
          appendSpaceOnAdd={true}
        />
        <Mention
          type="emoji"
          trigger={":"}
          data={this.mapEmoji}
          renderSuggestion={this.renderEmojiSuggestion}
        />
      </MentionsInput>
    );
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    triggerGlobalErrorAlert: (payload) => {
      dispatch(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(payload))
    }
  };
};

export default connect(null, mapDispatchToProps)(CommentsAutoSuggest);
