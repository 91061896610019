import { getConfiguration } from '@chr/common-web-ui-configuration';
import { NUOLV_ENABLED_TENANTS } from '../utils/constants';
import clientFactory from './clientFactory';
import { getUserProfile } from '../containers/auth/authHelper';
import shipmentMethods from './shipments';

export default {
  async fetchUser(id) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      let resp = await request.get(`users/${id}`);
      return resp.data;
    } catch (err) {
      throw `Error getting user: ${err}`;
    }
  },
  async fetchUsers(payload) {
    const config = await getConfiguration();
    // get current user from local storage
    const user = await getUserProfile();

    let tenantPayload = `?auth0Connection=${user.oktaId}&limit=${payload.limit || 10}&offset=${payload.offset || 0}`;
    if (user.role && user.role !== 'SuperAdmin') {
      tenantPayload += `&tenantId=${user.tenantId}`;
    }

    if (payload.searchText) {
      tenantPayload += `&searchText=${payload.searchText}`;
    }

    try {
      const request = await clientFactory(config.usersApiEndpoint);
      const result = await request.get(`users${tenantPayload}`, payload);
      return result.data;
    } catch (err) {
      throw `Error fetching users: ${err}`;
    }
  },
  async saveBookmark(payload) {
    const config = await getConfiguration();
    const userProfile = await getUserProfile();
    try {
      const request = await clientFactory(config.usersApiEndpoint);
      const result = await request.post(`users/${userProfile.email}/bookmarks`, payload);
      return result;
    } catch (err) {
      throw `Error bookmarking shipment: ${err}`;
    }
  },
  async deleteBookmark(id) {
    const config = await getConfiguration();
    const userProfile = await getUserProfile();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.delete(`users/${userProfile.email}/bookmarks/${id}`);
      return result;
    } catch (err) {
      throw `Error deleting bookmark: ${err}`;
    }
  },
  async fetchBookmark(id) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const userProfile = await getUserProfile();
      const request = await clientFactory(endpoint);
      const result = await request.get(`bookmarks?email=${userProfile.email}&shipmentId=${id}`);
      return result;
    } catch (err) {
      throw `Error fetching bookmark: ${err}`;
    }
  },
  async fetchBookmarks() {
    try {
      const config = await getConfiguration();
      const userProfile = await getUserProfile();
      const request = await clientFactory(config.usersApiEndpoint);
      const result = await request.get(`bookmarks?email=${userProfile.email}`);
      const bookmarks = result.data;
      const payload = { shipments: [] };
      const finalResult = [];
      if (bookmarks.length > 0) {
        bookmarks.forEach((bookmark) => {
          if (bookmark.metadata.shipmentId) {
            payload.shipments.push(bookmark.metadata.shipmentId);
          } else if (bookmark.metadata.loadNumber) {
            payload.shipments.push(bookmark.metadata.loadNumber);
          }
        });
        const detailLevel = NUOLV_ENABLED_TENANTS.includes(userProfile.tenantId) ? 'shipments' : null
        const shipments = await shipmentMethods.searchMultiShipments(payload, detailLevel);
        shipments.data.docs.forEach((shipment) => {
          bookmarks.forEach((bookmark) => {
            if (bookmark.metadata.shipmentId === shipment._id && shipment.found) {
              bookmark.shipment = shipment._source;
              finalResult.push(bookmark);
            }
          });
        });
      }
      return finalResult;
    } catch (err) {
      throw `Error fetching bookmarks: ${err}`;
    }
  },
  async saveFilterSet(filterSet) {
    const userProfile = await getUserProfile();
    try {
      const config = await getConfiguration();
      const request = await clientFactory(config.usersApiEndpoint);
      const result = await request.post(`users/${userProfile.email}/queries`, filterSet);
      return result;
    } catch (err) {
      throw `Error saving filter set: ${err}`;
    }
  },
  async deleteFilterSet(id) {
    const userProfile = await getUserProfile();
    try {
      const config = await getConfiguration();
      const request = await clientFactory(config.usersApiEndpoint);
      const result = await request.delete(`/users/${userProfile.email}/queries/${id}`);
      return result;
    } catch (err) {
      throw `Error deleting filter set: ${err}`;
    }
  },
  async updateFilterSet(id, filterSet) {
    const userProfile = await getUserProfile();
    try {
      const config = await getConfiguration();
      const request = await clientFactory(config.usersApiEndpoint);
      const result = await request.put(`/users/${userProfile.email}/queries/${id}`, filterSet);
      return result;
    } catch (err) {
      throw `Error updating filter set: ${err}`;
    }
  },
  async fetchFilterSets() {
    try {
      const config = await getConfiguration();
      const userProfile = await getUserProfile();
      const request = await clientFactory(config.usersApiEndpoint);
      const result = await request.get(`users/${userProfile.email}/queries`);
      return result.data;
    } catch (err) {
      throw `Error fetching filter sets: ${err}`;
    }
  },
  async fetchPermissions(role) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;

    try {
      const request = await clientFactory(endpoint);

      let route = 'permissions'
      if (role) {
        route += `?role=${role}`
      }
      return await request.get(route);
    } catch (err) {
      throw `Error getting permissions: ${err}`;
    }
  },
  async searchForUser(searchText, tenantId) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      let query = `searchText=${searchText}`;

      if (tenantId) {
        query = query.concat(`&tenantId=${tenantId}`);
      }
      const result = await request.get(`users?${query}`);
      return result;
    } catch (err) {
      throw `Error searching for user: ${err}`;
    }
  },
  async createUser(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post('users', payload);
      return result;
    } catch (err) {
      throw `Error creating user: ${err}`;
    }
  },
  async updateUser(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.put(`users/${payload.oktaId}`, payload);
      return result;
    } catch (err) {
      throw `Error updating user: ${err}`;
    }
  },
  async updateUserPhoneNumber(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.put(`users/${payload.oktaId}/phoneNumber`, payload.body);
      return result;
    } catch (err) {
      throw `Error updating user profile: ${err}`;
    }
  },
  async deleteUser(userId) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.delete(`users/${userId}`);
      return result;
    } catch (err) {
      throw `Error deleting user: ${err}`;
    }
  },
  async sendSmsVerificationCode(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post(`users/${payload.oktaId}/phone/request`, payload.body);
      return result;
    } catch (err) {
      throw `Error requesting verification code: ${err}`;
    }
  },
  async validateSmsVerificationCode(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post(`users/${payload.oktaId}/phone/check`, payload.body);
      return result;
    } catch (err) {
      throw `Error validating verification code: ${err}`;
    }
  },
  async fetchAlertRules(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`users/_all/alertRules?userId=${payload.userId}`);
      return result;
    } catch (err) {
      throw `Error getting alert rules: ${err}`;
    }
  },
  async createAlertRule(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post(`users/${payload.userId}/alertRules`, payload);
      return result;
    } catch (err) {
      throw `Error creating alert rule: ${err}`;
    }
  },
  async updateAlertRule(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const cleanedPayload = { ...payload };
      delete cleanedPayload.alertRuleId;
      const result = await request.put(`users/${payload.userId}/alertRules/${payload.alertRuleId}`, cleanedPayload);
      return result;
    } catch (err) {
      throw `Error updating alert rule: ${err}`;
    }
  },
  async deleteAlertRule(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.delete(`users/${payload.userId}/alertRules/${payload.alertRuleId}`);
      return result;
    } catch (err) {
      throw `Error deleting alert rule: ${err}`;
    }
  },
  async changePassword(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post('users/changepassword', payload);
      return result;
    } catch (err) {
      throw `Error saving password: ${err}`;
    }
  },
  async resetPassword(payload) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post('users/passwordreset', payload);
      return result;
    } catch (err) {
      throw `Error sending password reset email: ${err}`;
    }
  },
  async existingUser(email) {
    const config = await getConfiguration();
    const endpoint = config.usersApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`user/${email}/exists`);
      return result;
    } catch (err) {
      throw `Error checking for existing user: ${err}`;
    }
  }
};
