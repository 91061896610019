import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
    load: {},
    orders: [],
    warehouseStop: {},
    loaded: false
});

function shipmentWarehouseSummaryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_STARTED:
      return state
        .set('loaded', false);
    case types.FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_FAILED:
      return state 
        .set('loaded', true);
    // We could set a "loading" variable to true here if we want to display a loading page.
    case types.FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_COMPLETED:
      console.log('action.payload: ', action.payload)
      return state
        .set('load', action.payload.loads[0])
        .set('orders', action.payload.orders)
        .set('warehouseStop', action.payload.warehouseStop)
        .set('loaded', true);
    default:
      return state;
  }
}

export default shipmentWarehouseSummaryReducer;
