import * as actionTypes from './actionTypes';
import actions from './actions';
import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import { selectAuthClient, selectAccessToken, selectIdToken } from './selectors';
import socketActions from '../socket/actions';
import { getConfiguration } from '@chr/common-web-ui-configuration';
import { addTokenToLocalStorage } from '../auth/authHelper';
import { SESSION_STORAGE_KEYS } from '../../utils/constants';
//safari does not support native browser BroadcastChannel, package is necessary
import { BroadcastChannel } from 'broadcast-channel';
import FastMutex from 'fast-mutex';

let _tabCheckChannel = new BroadcastChannel('tabcheck');
let _tabSentChannel = new BroadcastChannel('tabsent');

export default function(mockWindowObject) {
  const windowObject = mockWindowObject || window;
  _tabCheckChannel.onmessage = function (m) {
    if (m) {
      if (m === 'CHECK') {
        let timestamp = windowObject.sessionStorage.getItem(SESSION_STORAGE_KEYS.trkSent);
        if (timestamp != null) {
          _tabSentChannel.postMessage(timestamp);
        }
      } else if (m.startsWith('STORE')) {
        let timestamp = Number.parseInt(m.split(' ')[1]);
        if (timestamp && !Number.isNaN(timestamp)) {
          windowObject.sessionStorage.setItem(SESSION_STORAGE_KEYS.trkSent, timestamp);
        }
      }
    }
  }

  let _mutex = new FastMutex({
    clientId: `${(new Date()).valueOf()}${Math.random()}`,
    xPrefix: "_MUTEX_LOCK_X_",
    yPrefix: "_MUTEX_LOCK_Y_",
    localStorage: windowObject.localStorage,
    timeout: 500
  });

  function* watchUpdateTokensStart() {
    yield takeEvery(actionTypes.UPDATE_TOKENS_STARTED, updateTokensStart);
  }

  function* updateTokensStart() {
    try {
      const [authClient, oldAccessToken, oldIdToken, config] = yield all([
        select(selectAuthClient),
        select(selectAccessToken),
        select(selectIdToken),
        call(getConfiguration)
      ]);
  
      const { accessToken, identityToken } = yield call(authClient.ensureAuthed);
  
      if (accessToken && accessToken != oldAccessToken) {
        yield put(socketActions.configureSocket(config, accessToken));
        addTokenToLocalStorage(accessToken, config, 'access_token');
      }
  
      if (identityToken && identityToken != oldIdToken) {
        addTokenToLocalStorage(identityToken, config, 'id_token');
      }
  
      yield put(actions.updateTokensCompleted(accessToken, identityToken));
    } catch (e) {
      yield put(actions.updateTokensFailed());
    }
  }

  function* watchSetAuthedToStartTracking() {
    yield takeEvery(actionTypes.SET_AUTHED, trackLogin);
  }

  function checkForTabs() {
    return new Promise((res) => {
      let timestamps = [];
      _tabSentChannel.onmessage = function (m) {
        let timestamp = Number.parseInt(m);
        if (timestamp && !Number.isNaN(timestamp)) {
          timestamps.push(timestamp);
        }
      }
      _tabCheckChannel.postMessage('CHECK');
      setTimeout(function () {
        _tabSentChannel.onmessage = null;
        res(timestamps.sort((a, b) => b - a));
      }, 300); //wait for 300ms for any tab responses (testing has shown that they are near instant)
    })
  }

  function* trackLogin(payload) {
    if (payload.payload.authed && !payload.payload.wasAuthed) {
      try {
        //use localStorage based mutex to ensure that only one tab is checking for other tabs at a time
        yield _mutex.lock('tabcheck');
        const now = (new Date()).valueOf();
        const trackAlreadySent = windowObject.sessionStorage.getItem(SESSION_STORAGE_KEYS.trkSent)
        const trackSentAtTimestamp = Number.parseInt(trackAlreadySent);
        if (trackAlreadySent == null || Number.isNaN(trackSentAtTimestamp) || trackSentAtTimestamp + (1000*60*60*24) < now) {
          const sent = yield call(checkForTabs);
          if (!sent || !sent[0] || sent[0] + (1000*60*60*24) < now) {
            yield put(actions.trackLogin(true));
            //tracked set in session storage to ensure that a duplicate track is not sent on refresh, but will be sent on tab close
            windowObject.sessionStorage.setItem(SESSION_STORAGE_KEYS.trkSent, now);
            //tell other tabs to store that a tracking ping has already been sent
            _tabCheckChannel.postMessage(`STORE ${now}`);
          } else {
            //another tab in our browser has already started tracking, so we do not need to send another
            windowObject.sessionStorage.setItem(SESSION_STORAGE_KEYS.trkSent, sent);
          }
        }
      } catch (e) {
        //this sort of error should be invisible to a user, does not affect site functionality
        console.error(e);
      } finally {
        _mutex.release('tabcheck');
      }
    }
  }

  return {
    watchUpdateTokensStart,
    updateTokensStart,
    watchSetAuthedToStartTracking,
    checkForTabs,
    trackLogin
  }
}
