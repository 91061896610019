import alerts from './alerts';
import conversations from './conversations';
import exporting from './exporting';
import incidents from './incidents';
import inventory from './inventory';
import iot from './iot';
import metadata from './metadata';
import routing from './routing';
import shipments from './shipments';
import users from './users';
import powerBi from './powerBi';

export default {
  alerts,
  conversations,
  exporting,
  incidents,
  inventory,
  iot,
  metadata,
  routing,
  shipments,
  users,
  powerBi
};
