import React from 'react';
import { CarrierPerformance, CarrierCompliance } from '../../components/scorecard';
import './carrier.scss';

export default class CarrierBody extends React.Component {

  render() {
    return (
      <div >
        <div className='performance-and-compliance-container'>
        <div>
          <CarrierPerformance isAnimationActive={this.props.isAnimationActive} in_transit={this.props.in_transit} delivered={this.props.delivered} picked_up={this.props.picked_up} carrier_compliance={this.props.carrier_compliance}> </CarrierPerformance> 
        </div>
        <div>
          <CarrierCompliance isAnimationActive={this.props.isAnimationActive} in_transit={this.props.in_transit} delivered={this.props.delivered} picked_up={this.props.picked_up} carrier_compliance={this.props.carrier_compliance}> </CarrierCompliance>
        </div>
      </div>
      </div>
    );
  }
}