export const FETCH_TRACKING_SUMMARY_STARTED = 'tracking-summary/FETCH_TRACKING_SUMMARY_STARTED';
export const FETCH_TRACKING_SUMMARY_FAILED = 'tracking-summary/FETCH_TRACKING_SUMMARY_FAILED';
export const FETCH_TRACKING_SUMMARY_COMPLETED = 'tracking-summary/FETCH_TRACKING_SUMMARY_COMPLETED';

export const PROCESS_ROUTEDATA_COMPLETED = 'tracking-summary/PROCESS_ROUTEDATA_COMPLETED';

export const FETCH_SHIPMENT_STARTED = 'tracking-summary/FETCH_SHIPMENT_STARTED';
export const FETCH_SHIPMENT_COMPLETED = 'tracking-summary/FETCH_SHIPMENT_COMPLETED';

export const FETCH_TRACKING_LOGS_STARTED = 'tracking-summary/FETCH_TRACKING_LOGS_STARTED';
export const FETCH_TRACKING_LOGS_COMPLETED = 'tracking-summary/FETCH_TRACKING_LOGS_COMPLETED';
export const FETCH_TRACKING_LOGS_PROCESS = 'tracking-summary/FETCH_TRACKING_LOGS_PROCESS';

export const RESET_PROPS = 'tracking-summary/RESET_PROPS';