import * as types from './actionTypes';

export default {
  setJobMode(jobMode) {
    return {
      type: types.SET_JOB_MODE,
      payload: jobMode
    }
  },
  changeMapDetails(zoom, box, location, history) {
    return {
      type: types.MAP_DETAILS_CHANGED,
      payload: {zoom, box, location, history}
    }
  },
  changeSort(sort, location, history) {
    return {
      type: types.SORT_CHANGED,
      payload: {sort, location, history}
    }
  },
  selectCluster(box, zoom, location, history) {
    return {
      type: types.CLUSTER_SELECTED,
      payload: {box, zoom, location, history}
    }
  },
  changeCurrentPage(currentPage, location, history, shouldSkipFetchingShipments = false) {
    return {
      type: types.CURRENT_PAGE_CHANGED,
      payload: {currentPage, location, history, shouldSkipFetchingShipments}
    }
  },
  searchShipments() {
    return {
      type: types.SEARCH_SHIPMENTS_STARTED,
      payload: {},
    };
  },
  changeSearchInput(newSearchInput, multiSearchType, location, history, displayMode = null) {
    return {
      type: types.SEARCH_TEXT_CHANGED,
      payload: {
        newSearchInput, multiSearchType, displayMode, location, history
      },
    };
  },
  removeMapClustering(location, history) {
    return {
      type: types.MAP_CLUSTERING_REMOVED,
      payload: {
        location, history
      },
    };
  },
  newQueryString(url, history) {
    return {
      type: types.NEW_QUERY_PARAMS,
      payload: { url, history }
    }
  },
  newQueryStringUpdateConfiguration(newConfiguration, location, history, shouldSkipFetchingShipments = false) {
    return {
      type: types.NEW_QUERY_PARAMS_UPDATE_CONFIGURATION,
      payload: { newConfiguration, location, history, shouldSkipFetchingShipments }
    }
  },
  searchShipmentsFailed(payload) {
    return {
      type: types.SEARCH_SHIPMENTS_FAILED,
      payload: payload
    }
  },
  searchShipmentsComplete(payload) {
    return {
      type: types.SEARCH_SHIPMENTS_COMPLETED,
      payload: payload
    };
  },
  fetchListShipments(payload) {
    return {
      type: types.FETCH_LIST_SHIPMENTS_STARTED,
      payload: payload
    };
  },
  fetchListShipmentsFailed(payload) {
    return {
      type: types.FETCH_LIST_SHIPMENTS_FAILED,
      payload: payload
    };
  },
  fetchListShipmentsComplete(payload) {
    return {
      type: types.FETCH_LIST_SHIPMENTS_COMPLETED,
      payload: {
        shipments: payload
      }
    };
  },
  toggleShipmentDisplayMode(mode, location, history) {
    return {
      type: types.TOGGLE_SHIPMENT_DISPLAY_MODE,
      payload: {
        display: mode,
        location, 
        history
      }
    };
  },
  toggleShipmentViewMode(mode) {
    return {
      type: types.TOGGLE_SHIPMENT_VIEW_MODE,
      payload: {
        shipmentViewMode: mode,
      }
    };
  },
  navigateToShipmentFromExceptionCard(exceptionDescription, location, history) {
    return {
      type: types.NAVIGATE_TO_SHIPMENT_FROM_EXCEPTION_CARD,
      payload: {
        exceptionDescription, location, history
      }
    };
  },
  navigateToShipmentFromIncidentCard(incidentName, incidentId, status, location, history) {
    return {
      type: types.NAVIGATE_TO_SHIPMENT_FROM_INCIDENT_CARD,
      payload: {
        incidentName, incidentId, status, location, history
      }
    };
  },
  filtersChanged(filterTag, filter, location, history) {
    return {
      type: types.CHANGED_SHIPMENTS_FILTERS,
      payload: {
        filterTag,
        filter,
        location,
        history
      }
    };
  },
  facilityFiltersChanged(filter, location, history, displayMode = null) {
    return {
      type: types.CHANGED_SHIPMENTS_FACILITY_FILTERS,
      payload: {
        filter,
        location,
        history,
        displayMode
      }
    };
  },
  facilityFiltersChangedUpdateConfiguration(activeFacilityFilters, location, history, displayMode = null) {
    return {
      type: types.CHANGED_SHIPMENTS_FACILITY_FILTERS_UPDATE_CONFIGURATION,
      payload: {
        activeFacilityFilters,
        location,
        history,
        displayMode
      }
    };
  },
  filtersChangedUpdateConfiguration(filters, location, history) {
    return {
      type: types.CHANGED_SHIPMENTS_FILTERS_UPDATE_CONFIGURATION,
      payload: {
        filters,
        location,
        history
      }
    };
  },
  dateFilterChanged(filter, location, history) {
    return {
      type: types.CHANGED_SHIPMENTS_DATE_FILTER,
      payload: {
        filter,
        location,
        history
      }
    };
  },
  dateFilterChangedUpdateConfiguration(filter, sort, location, history) {
    return {
      type: types.CHANGED_SHIPMENTS_DATE_FILTER_UPDATE_CONFIGURATION,
      payload: {
        filter,
        sort,
        location,
        history
      }
    };
  },
  filtersCleared(location, history) {
    return {
      type: types.CLEARED_SHIPMENTS_FILTERS,
      payload: {
        location,
        history
      }
    };
  },
  updateQueryParams(location, history, shouldSkipFetchingShipments = false) {
    return {
      type: types.UPDATED_QUERY_PARAMS,
      payload: {
        location,
        history,
        shouldSkipFetchingShipments
      }
    };
  },
  fetchAffectedShipments(payload) {
    return {
      type: types.FETCH_AFFECTED_SHIPMENTS_STARTED,
      payload: payload
    };
  },
  fetchAffectedShipmentsFailed(payload) {
    return {
      type: types.FETCH_AFFECTED_SHIPMENTS_FAILED,
      payload: payload
    };
  },
  fetchAffectedShipmentsComplete(payload) {
    return {
      type: types.FETCH_AFFECTED_SHIPMENTS_COMPLETED,
      payload: payload
    };
  },
  fetchExceptions() {
    return {
      type: types.FETCH_EXCEPTIONS_STARTED
    };
  },
  fetchExceptionsFailed(payload) {
    return {
      type: types.FETCH_EXCEPTIONS_FAILED,
      payload: payload
    };
  },
  fetchExceptionsComplete(payload) {
    return {
      type: types.FETCH_EXCEPTIONS_COMPLETED,
      payload: payload
    };
  },
  applyLoadedFilterSet(filters, facilityFilters, dateFilter, location, history) {
    return {
      type: types.FILTER_SET_LOADED,
      payload: {
        filters,
        facilityFilters,
        dateFilter,
        location,
        history
      }
    }
  },
  toggleOutageAnnouncementActive() {
    return {
      type: types.TOGGLE_OUTAGE_ANNOUNCEMENT_ACTIVE
    }
  }
};
