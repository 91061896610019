import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  inventory: [],
  unrestricted: '',
  facilities: [],
  loading: true,
  detailsLoading: true
});

function inventoryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_INVENTORY_STARTED:
      return state
        .set('loading', true);
    case types.FETCH_INVENTORY_FAILED:
      return state
        .set('loading', false);
    case types.FETCH_INVENTORY_COMPLETED:
      return state
        .set('inventory', action.payload)
        .set('loading', false);
    case types.FETCH_INVENTORY_DETAILS_STARTED:
      return state
        .set('detailsLoading', true);
    case types.FETCH_INVENTORY_DETAILS_FAILED:
      return state
        .set('detailsLoading', false);
    case types.FETCH_INVENTORY_DETAILS_COMPLETED:
      return state
        .set('unrestricted', action.payload.unrestricted)
        .set('facilities', action.payload.facilities)
        .set('detailsLoading', false);
    default:
      return state;
  }
}

export default inventoryReducer;
