import { v4 as uuidv4 } from "uuid";
import * as types from './actionTypes';

export default {
  // FETCH SINGLE USER
  fetchUserProfile(id) {
    return {
      type: types.FETCH_USER_PROFILE_STARTED,
      payload: {
        id: id
      }
    };
  },
  fetchUserProfileComplete(userProfile) {
    return {
      type: types.FETCH_USER_PROFILE_COMPLETED,
      payload: {
        userProfile: userProfile
      }
    };
  },
  // FETCH MANY USERS
  fetchUsers(payload) {
    return {
      type: types.FETCH_USERS_STARTED,
      payload
    };
  },
  fetchUsersFailed(payload) {
    return {
      type: types.FETCH_USERS_FAILED,
      payload
    };
  },
  fetchUsersComplete(response) {
    return {
      type: types.FETCH_USERS_COMPLETED,
      payload: {
        users: response.users,
        tenantId: response.tenantId,
        isDeleted: response.isDeleted
      }
    };
  },

  fetchPermissions(role) {
    return {
      type: types.FETCH_PERMISSIONS_STARTED,
      payload: role
    };
  },
  fetchPermissionsComplete(metadata) {
    return {
      type: types.FETCH_PERMISSIONS_COMPLETED,
      payload: metadata
    };
  },

  existingUser(email) {
    return {
      type: types.EXISTING_USER_STARTED,
      payload: email
    };
  },
  existingUserComplete(existingUser) {
    return {
      type: types.EXISTING_USER_COMPLETED,
      payload: existingUser
    };
  },

  searchForTypeaheadUsers(searchText, tenantId) {
    return {
      type: types.SEARCH_FOR_USERS_TYPEAHEAD_STARTED,
      payload: {
        searchText,
        tenantId
      }
    };
  },
  searchForTypeaheadUsersComplete(typeaheadUsers) {
    return {
      type: types.SEARCH_FOR_USERS_TYPEAHEAD_COMPLETED,
      payload: typeaheadUsers
    };
  },

  createUser(user) {
    return {
      type: types.CREATE_USER_STARTED,
      payload: user
    };
  },
  createUserComplete() {
    return {
      type: types.CREATE_USER_COMPLETED,
      payload: {}
    };
  },
  updateUser(user) {
    return {
      type: types.UPDATE_USER_STARTED,
      payload: user
    };
  },
  updateUserComplete() {
    return {
      type: types.UPDATE_USER_COMPLETED,
      payload: {}
    };
  },
  updateUserPhoneNumber(oktaId, mobilePhoneNumber, mobilePhoneCountryCode, removePhoneVerification) {
    return {
      type: types.UPDATE_USER_PHONE_NUMBER_STARTED,
      payload: {
        oktaId,
        body: {
          mobilePhoneNumber,
          mobilePhoneCountryCode,
          removePhoneVerification
        }
      }
    };
  },
  fetchUser(id) {
    return {
      type: types.FETCH_USER_STARTED,
      payload: {
        id: id
      }
    };
  },
  fetchUserFailed(payload) {
    return {
      type: types.FETCH_USER_FAILED,
      payload: payload
    };
  },
  fetchUserComplete(user) {
    return {
      type: types.FETCH_USER_COMPLETED,
      payload: {
        user: user
      }
    };
  },
  fetchUserToCopy(id) {
    return {
      type: types.FETCH_USER_TO_COPY_STARTED,
      payload: {
        id: id
      }
    };
  },
  fetchUserToCopyComplete(userToCopy) {
    return {
      type: types.FETCH_USER_TO_COPY_COMPLETED,
      payload: {
        userToCopy: userToCopy
      }
    };
  },
  clearCopyToUser() {
    return {
      type: types.CLEAR_USER_TO_COPY_COMPLETED,
      payload: {
        usersToCopy: undefined
      }
    };
  },
  deleteUser(userId) {
    return {
      type: types.DELETE_USER_STARTED,
      payload: userId
    };
  },
  deleteUserComplete(user) {
    return {
      type: types.DELETE_USER_COMPLETED,
      payload: {
        isDeleted: user.isDeleted
      }
    };
  },
  sendSmsVerificationCode(oktaId, mobilePhoneNumber, mobilePhoneCountryCode) {
    return {
      type: types.SEND_SMS_VERIFICATION_CODE,
      payload: {
        oktaId,
        body: {
          mobilePhoneNumber,
          mobilePhoneCountryCode
        }
      }
    };
  },
  validateSmsVerificationCode(oktaId, mobilePhoneNumber, mobilePhoneCountryCode, verificationCode) {
    return {
      type: types.VALIDATE_SMS_VERIFICATION_CODE,
      payload: {
        oktaId,
        body: {
          mobilePhoneNumber,
          mobilePhoneCountryCode,
          verificationCode
        }
      }
    };
  },
  fetchAlertRules(userId, shipmentNumber, isShipmentTracker) {
    return {
      type: types.FETCH_ALERT_RULES_STARTED,
      payload: {
        userId, 
        shipmentNumber, 
        isShipmentTracker
      }
    };
  },
  fetchAlertRulesFailed(payload) {
    return {
      type: types.FETCH_ALERT_RULES_FAILED,
      payload: payload
    };
  },
  fetchAlertRulesComplete(alertRules, subscribedShipment) {
    return {
      type: types.FETCH_ALERT_RULES_COMPLETED,
      payload: {
        alertRules, 
        subscribedShipment
      }
    };
  },
  createAlertRule(userId, tenantId, alertRule, isShipmentTracker, shipmentNumber) {
    return {
      type: types.CREATE_ALERT_RULE_STARTED,
      payload: {
        rule: {
        userId,
        tenantId,
        ...alertRule
        },
        isShipmentTracker, 
        shipmentNumber
      }
    };
  },
  deleteAlertRule(userId, alertId) {
    return {
      type: types.DELETE_ALERT_RULE_STARTED,
      payload: {
        userId,
        alertId
      }
    }
  },
  deleteAlertRuleComplete(alertId) {
    return {
      type: types.DELETE_ALERT_RULE_COMPLETED,
      payload: {
        alertId
      }
    }
  },
  copyRuleCard(alertRule) {
    return {
      type: types.CREATE_RULE_CARD,
      payload: {
        rule: {
          ...alertRule,
          name: '',
          _id: uuidv4(),
          new: true
        }
      }
    }
  },
  createRuleCard() {
    return {
      type: types.CREATE_RULE_CARD,
      payload: {
        rule: {
          conditions: {
            all: [{}]
          },
          deliveryMethods: [],
          enabled: true,
          eventType: "",
          name: "",
          _id: uuidv4(),
          new: true
        }
      }
    }
  },
  updateAlertRule(userId, alertRuleId, alertRule) {
    return {
      type: types.UPDATE_ALERT_RULE_STARTED,
      payload: {
        userId,
        alertRuleId,
        ...alertRule
      }
    };
  },
  deleteAlertRule(userId, alertRuleId) {
    return {
      type: types.DELETE_ALERT_RULE_STARTED,
      payload: {
        userId,
        alertRuleId
      }
    };
  },
  changePassword(payload) {
    return {
      type: types.CHANGE_PASSWORD_STARTED,
      payload
    };
  },
  changePasswordComplete() {
    return {
      type: types.CHANGE_PASSWORD_COMPLETED,
      payload: {}
    };
  }, 
  addShipmentRuleCard(shipmentNumber) {
    return {
      type: types.CREATE_SHIPMENT_RULE_CARD,
      payload: {
        rule: {
          conditions: {
            all: [
              {
                field: "load_number", 
                operator: "equal", 
                value: shipmentNumber
              }]
          },
          deliveryMethods: [],
          enabled: true,
          eventType: "",
          name: "",
          _id: uuidv4(),
          new: true
        }
      }
    }
  },  
  resetSubscribedProp() {
    return {
      type: types.RESET_SUBSCRIBED_PROP
    };
  }
};
