import React from 'react';
import ReactDOM from 'react-dom';
import { getConfiguration } from '@chr/common-web-ui-configuration';
import { AnalyticsProvider } from '@chr/react-analytics';
import { ChrLdProvider } from '@chr/common-launchdarkly';
import { AjaxClient } from '@chr/common-web-ui-ajax-client';
import {
  HashRouter as Router, Switch, Route, Redirect
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider } from 'react-redux';
import { createStore } from './createStore';
import { AuthRequired } from './app/containers/auth';
import { ShipmentsPage } from './app/pages/shipments';
import SavedItems from './app/pages/saved-items';
import { LandingPage } from './app/pages/landing';
import IotDashboard from './app/pages/iot-dashboard';
import { ShipmentTrackerPage } from './app/pages/shipment-tracker';
import { InventoryPage, InventoryDetailsPage } from './app/pages/inventory';
import { ManageUsersPage, UserPage } from './app/pages/admin/manage-users';
import { NotificationPreferencesPage } from './app/pages/notifications';
import { DisruptionsPage } from './app/pages/disruptions';
import { DownloadsPage } from './app/pages/downloads';
import { ScorecardPage } from './app/pages/scorecard';
import { OrdersPage } from './app/pages/orders';
import { EXTERNAL_TRACKER_PATH, ORDERS_PATH, SHIPMENTS_PATH, ORDER_TRACKER_PATH, TRACKING_DETAIL_PATH, EXTERNAL_SHIPMENT_WAREHOUSE_PATH } from './app/utils/constants';
import ExternalTrackingPage from './app/pages/external-tracker';
import ExternalShipmentWarehouse from './app/pages/external-shipment-warehouse';
import CreatePassword from './app/pages/admin/manage-users/CreatePassword';
import { TrackingDetailPage } from './app/pages/tracking';
import { authClientInstance } from './app/containers/auth/authHelper';
import OktaAccessDenied from './app/components/okta-access-denied';

import './index.scss';
import ConfigurationContext from './app/components/config/ConfigurationContext';
import { OrderTrackerPage } from './app/pages/order-tracker';
import { getUserTenant } from './app/utils/helperMethods/userMethods';

const getHashParameters = () => {
  const searchParams = new URLSearchParams('?' + window.location.hash.slice(1));
  const accessToken = searchParams.get('access_token');
  const idToken = searchParams.get('id_token');
  return { accessToken, idToken };
};

const createAuthClient = async (config) => {
  const { accessToken, idToken } = getHashParameters();

  const authClient = await authClientInstance(config, accessToken, idToken);

  if (accessToken || idToken) {
    await authClient.ensureAuthed();
  }

  return authClient;
}

const handleAccessDenied = (config) => {
  if (window.location.hash) {
    const searchParams = new URLSearchParams('?' + window.location.hash.slice(1));
    const error = searchParams.get('error');
    return error && error === 'access_denied';
  }
  return false;
}

export const bootstrap = (async () => {
  const config = await getConfiguration();
  const isAccessDenied = handleAccessDenied(config);
  const authClient = await createAuthClient(config);
  const user = await getUserTenant();
  const launchDarklyConfig = {
    user,
    envKey: config.launchDarklyClientId,
  };

  if (isAccessDenied) {
    ReactDOM.render(
      <OktaAccessDenied authClient={authClient} />,
      document.getElementById('site-container')
    );
  } else {

    const ajaxClient = AjaxClient.createDefault(authClient);
    const store = await createStore(config, authClient, ajaxClient);

    ReactDOM.render(
      <ConfigurationContext.Provider value={config}>
        <AnalyticsProvider scriptSrc={config.visionAdobeAnalyticsPath}>
            <Provider store={store}>
              <Router>
                <LastLocationProvider>
                  <ChrLdProvider {...launchDarklyConfig} >
                    <Switch>
                      <Route path={EXTERNAL_TRACKER_PATH} component={ExternalTrackingPage} />
                      <Route path={EXTERNAL_SHIPMENT_WAREHOUSE_PATH} component={ExternalShipmentWarehouse} />
                      <Route exact path={TRACKING_DETAIL_PATH} component={TrackingDetailPage} />
                      <Route exact path="/" component={AuthRequired(LandingPage)} />
                      <Route path="/access_token*" component={AuthRequired(LandingPage)} />
                      <Route path="/shipment-tracker/:shipmentId" component={AuthRequired(ShipmentTrackerPage)} />
                      <Route path={`${ORDER_TRACKER_PATH}/:orderId`} component={AuthRequired(OrderTrackerPage)} />
                      <Route exact path={ORDERS_PATH} component={AuthRequired(OrdersPage)} />
                      <Route exact path={SHIPMENTS_PATH} component={AuthRequired(ShipmentsPage)} />
                      <Route path="/saved-items" component={AuthRequired(SavedItems)} />
                      <Route exact path="/manage-users" component={AuthRequired(ManageUsersPage)} />
                      <Route exact path="/manage-users/:userId" component={AuthRequired(UserPage)} />
                      <Route exact path="/inventory" component={AuthRequired(InventoryPage)} />
                      <Route exact path="/inventory/:identifier" component={AuthRequired(InventoryDetailsPage)} />
                      <Route exact path="/notification-preferences" component={AuthRequired(NotificationPreferencesPage)} />
                      <Route exact path="/disruptions" component={AuthRequired(DisruptionsPage)} />
                      <Route exact path="/carrier-scorecard" component={AuthRequired(ScorecardPage)} />
                      <Route exact path="/changepassword" component={AuthRequired(CreatePassword)} />
                      <Route exact path="/downloads" component={AuthRequired(DownloadsPage)} />
                      <Route exact path="/iot-dashboard" component={AuthRequired(IotDashboard)} />
                      <Redirect to="/" />
                    </Switch>
                  </ChrLdProvider>
                </LastLocationProvider>
              </Router>
            </Provider>
        </AnalyticsProvider>
      </ConfigurationContext.Provider>,
      document.getElementById('site-container')
    );
  }
})();
