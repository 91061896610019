import * as types from './actionTypes';

export default {
  openModal(modalConfiguration) {
    return {
      type: types.OPEN_MODAL,
      payload: modalConfiguration
    };
  },
  closeModal() {
    return {
      type: types.CLOSE_MODAL,
      payload: {}
    };
  }
};
