import React from 'react';
import { PIECES_AND_WEIGHT_TENANTS } from '../../utils/constants';
import { lbsToKgs } from '../../utils/helperMethods/commonMethods';

export default function PiecesAndWeight({ shipment }) {
  if (!PIECES_AND_WEIGHT_TENANTS.includes(shipment.tenantId)) {
    return null;
  }

  //Aditional validation for display the component
  if(!shipment.total_pieces_count && !displayChargeableWeight(shipment)){
    return null;
  }

  return (
    <div className="read-only">
      <span id={`${shipment.id.replace(/\|/g, '_')}-pieces-weight-label`} className="read-only-label">
        {buildDisplayLabel(shipment)}
      </span>
      <span id={`${shipment.id.replace(/\|/g, '_')}-pieces-weight-information`} title={buildDisplayInformation(shipment)} className="read-only-value">
        {buildDisplayInformation(shipment) || '\u2014'}
      </span>
    </div>
  );
};

function buildDisplayLabel(shipment) {  
  if (!displayChargeableWeight(shipment)) {
    return 'Pieces';
  }

  return 'Pieces / Chargeable Weight';
}

function buildDisplayInformation(shipment) {
  if (!displayChargeableWeight(shipment)) {
    return shipment.total_pieces_count;
  }
  let tenantId = shipment.tenantId;
  let chargeableWeight;
  if (shipment.total_chargeable_weight && shipment.shipment_weight_unit_of_measure) {
    chargeableWeight = tenantId == 404 ? 
      lbsToKgs(shipment.total_chargeable_weight, shipment.shipment_weight_unit_of_measure) 
      : parseFloat(shipment.total_chargeable_weight)?.toFixed(2);
  }

  return `${shipment.total_pieces_count} / ${chargeableWeight} ${tenantId == 404 ? 'kgs' : shipment.shipment_weight_unit_of_measure}`;
}

function displayChargeableWeight(shipment) {  
  var result = shipment.id && shipment.id.split('|')[1] !== 'Orders' && shipment.shipment_weight_unit_of_measure 
  && shipment.shipment_weight_unit_of_measure !== 'Unknown'
  && shipment.total_chargeable_weight
  && parseFloat(shipment.total_chargeable_weight) > 0
  //881219- Flex - Chargeable Weight should not display for Ocean FCL Shipments
  if(result && shipment.mode === 'Ocean' && shipment.secondary_identifiers 
  && shipment.secondary_identifiers.filter(x => x.display_label === 'Service Level')[0]?.value === 'FCL'){    
    result = false;
  }

  return result;
}