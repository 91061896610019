import {put, call, takeEvery} from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchPortsStart(){
  try{
    const ports = yield call(api.shipments.fetchPorts);
    yield put(actions.fetchPortsComplete(ports.data.ports));
  }
  catch(e){
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(e));
  }
}

export function* watchGetPortsStart(){
  yield takeEvery(types.FETCH_PORTS_STARTED, fetchPortsStart);
}
