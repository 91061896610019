import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as utils from './utils';
import shipmentActions from '../shipments/actions';
import incidentActions from '../incidents/actions';
import iotActions from '../iot/actions';
import portsActions from '../ports/actions';
import facilitiesActions from '../facilities/actions';
import mapActions from './actions';
import * as mapConstants from './constants';
import { withRouter } from 'react-router';
import { withLastLocation } from 'react-router-last-location';
import { addStopTextToStops, getTrackingDetailLevel } from '../../utils/helperMethods/commonMethods';
import queryString from 'query-string';
import { ExternalTrackingDetailPage } from '../../utils/constants';

var map = {},
  scriptURL = "https://www.bing.com/api/maps/mapcontrol??branch=release&callback=bingmapsCallback",
  pendingProps = [];

export default class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.debouncedHandleMapChange = _.debounce((props, map) => this.handleMapChange(props, map), 400);
    if (document.querySelector('script[src="' + scriptURL + '"]') === null) {
      this.loadScript(scriptURL);
      window.bingmapsCallback = function () {
        this.afterDependencyLoad(pendingProps);
      }.bind(this);
    }
  }

  componentDidMount() {
    if (window.Microsoft === null || window.Microsoft === undefined) {
      pendingProps.push(this.props);
    } else {
      this.bingMap(this.props, window.Microsoft);
    }
  }

  afterDependencyLoad(pendingProps) {
    try {
      pendingProps.map((props) => this.bingMap(props, window.Microsoft));
    } catch (exception) {
      console.error(exception);
    }
  }

  componentWillUnmount() {
    try {
      if (this.props.mapLoaded) {
        this.props.mapLoaded(false);
      }

      let mapReference = this.props.id ? ('#' + this.props.id) : '.react-bingmaps';
      if (map[mapReference])
        map[mapReference] = undefined;
      pendingProps = [];
    } catch (exception) {
      console.error(exception);
    }
  }

  componentWillReceiveProps(nextProps) {
    let mapReference = nextProps.id ? ('#' + nextProps.id) : '.react-bingmaps';
    //Check to update map type
    if (this.props.mapType !== nextProps.mapType) {
      utils.setMapType(nextProps.mapType, map[mapReference], window.Microsoft);
    }
    //Check to show weather layer
    if (this.props.showWeather !== nextProps.showWeather) {
      utils.setWeatherLayerOptions(mapConstants.WEATHER_LAYER, {
        visible: nextProps.showWeather
      }, map[mapReference], window.Microsoft, nextProps.appConfig);
    }
    //Check to show facilities layer
    if (this.props.showFacilities !== nextProps.showFacilities) {
      utils.setNamedLayerVisible(mapConstants.FACILITIES_LAYER, nextProps.showFacilities, map[mapReference], window.Microsoft);
    }
    //Check to show ports layer
    if (this.props.showPorts !== nextProps.showPorts) {
      utils.setNamedLayerVisible(mapConstants.PORTS_LAYER, nextProps.showPorts, map[mapReference], window.Microsoft);
    }
    //Check to show incidents layer
    if (this.props.showIncidents !== nextProps.showIncidents) {
      utils.setNamedLayerVisible(mapConstants.INCIDENTS_LAYER, nextProps.showIncidents, map[mapReference], window.Microsoft);
    }
    if (this.props.showEditIncidents !== nextProps.showEditIncidents) {
      utils.setNamedLayerVisible(mapConstants.INCIDENTS_EDIT_LAYER, nextProps.showEditIncidents, map[mapReference], window.Microsoft);
    }
    //Check to show region and pin layers
    if (this.props.clustering !== nextProps.clustering) {
      utils.setNamedLayerVisible(mapConstants.REGION_LAYER, true, map[mapReference], window.Microsoft);
      utils.setNamedLayerVisible(mapConstants.PIN_LAYER, true, map[mapReference], window.Microsoft);
      utils.setNamedLayerVisible(mapConstants.AIR_LAYER, true, map[mapReference], window.Microsoft);
      utils.setNamedLayerVisible(mapConstants.TRUCK_LAYER, true, map[mapReference], window.Microsoft);
      utils.setNamedLayerVisible(mapConstants.VESSEL_LAYER, true, map[mapReference], window.Microsoft);
      utils.setNamedLayerVisible(mapConstants.RAIL_LAYER, true, map[mapReference], window.Microsoft);
    }
    //Check to show traffic layer
    if (this.props.showTraffic !== nextProps.showTraffic) {
      if (this.trafficManager) {
        utils.setTrafficLayerOptions(this.trafficManager, nextProps.showTraffic);
      } else {
        window.Microsoft.Maps.loadModule('Microsoft.Maps.Traffic', () => {
          this.trafficManager = utils.addTrafficLayer(this.trafficManager, map[mapReference], window.Microsoft);
        });
      }
    }
    //Check for updated clusters
    if (this.props.shipmentSearch || nextProps.shipmentSearch) {
      if (this.props.clusters !== nextProps.clusters) {
        let pins;
        let pinLayer = utils.getLayer(mapConstants.PIN_LAYER, map[mapReference]);
        let regionLayer = utils.getLayer(mapConstants.REGION_LAYER, map[mapReference]);
        if (regionLayer) {
          regionLayer.clear();
        }
        pinLayer && pinLayer.clear();
        if (nextProps.clusters.location_hash) {
          let items = nextProps.clusters.location_hash.buckets;
          items.forEach( itemBucket => {
            if (itemBucket.doc_count === 1) {
              pins = utils.createSingle(itemBucket.top.hits.hits, window.Microsoft);
              if (pinLayer && pins) {
                pinLayer.add(pins);
                if (pins.map) {
                  pins.forEach( pin =>
                    window.Microsoft.Maps.Events.addHandler(pin, 'click', e =>
                      utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps))
                  );
                }
              }
            } else {
              pins = utils.createCluster(itemBucket, false, window.Microsoft);
              if (regionLayer && pins) {
                regionLayer.add(pins);
                pins.forEach(function (pin) {
                  window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
                });
              }
            }
          });
        } else if (nextProps.clusters.locations) {
          pinLayer && pinLayer.clear();
          pins = utils.createCluster(nextProps.clusters.locations.buckets, true, window.Microsoft);
          if (regionLayer && pins) {
            regionLayer.add(pins);
            pins.forEach(function (pin) {
              window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
            });
          }
        }
      }
      //Check for updated live tracked pins
      if (this.props.liveTrackedPins !== nextProps.liveTrackedPins && nextProps.liveTrackedPins != null) {
        if (nextProps.liveTrackedPins.truckload_transport && nextProps.liveTrackedPins.truckload_transport.truckload) {
          let truckLayer = utils.getLayer(mapConstants.TRUCK_LAYER, map[mapReference]);
          if (truckLayer) {
            truckLayer.clear();
          }
          nextProps.liveTrackedPins.truckload_transport.truckload.buckets.forEach(function (itemBucket) {
            let docHitCount = itemBucket.doc_count; 
            let pin = utils.createSingle(itemBucket.top_item.hits.hits, window.Microsoft, docHitCount);
            if (truckLayer && pin) {
              truckLayer.add(pin);
              pin.forEach(function (pin) {
                window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
              });
            }
          });
        }
        if (nextProps.liveTrackedPins.vessel && this.props.liveTrackedPins.vessel !== nextProps.liveTrackedPins.vessel) {
          let vesselLayer = utils.getLayer(mapConstants.VESSEL_LAYER, map[mapReference]);
          vesselLayer.clear();
          nextProps.liveTrackedPins.vessel.buckets.forEach(function (itemBucket) {
            let docHitCount = itemBucket.doc_count;
            let pin = utils.createSingle(itemBucket.top_item.hits.hits, window.Microsoft, docHitCount);
            if (vesselLayer && pin) {
              vesselLayer.add(pin);
              pin.forEach(function (pin) {
                window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
              });
            }
          });
        }
        if (nextProps.liveTrackedPins.flight && this.props.liveTrackedPins.flight !== nextProps.liveTrackedPins.flight) {
          let airLayer = utils.getLayer(mapConstants.AIR_LAYER, map[mapReference]);
          airLayer.clear();
          nextProps.liveTrackedPins.flight.buckets.map(function (itemBucket) {
            let docHitCount = itemBucket.doc_count;
            let pin = utils.createSingle(itemBucket.top_item.hits.hits, window.Microsoft, docHitCount);
            if (airLayer && pin) {
              airLayer.add(pin);
              pin.forEach(function (pin) {
                window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
              });
            }
          });
        }
        if (nextProps.liveTrackedPins.rail && this.props.liveTrackedPins.rail !== nextProps.liveTrackedPins.rail) {
          let railLayer = utils.getLayer(mapConstants.RAIL_LAYER, map[mapReference]);
          railLayer.clear();
          nextProps.liveTrackedPins.rail.intermodal.buckets.map(function (itemBucket) {
            let docHitCount = itemBucket.doc_count;
            let pin = utils.createSingle(itemBucket.top_item.hits.hits, window.Microsoft, docHitCount);
            if (railLayer && pin) {
              railLayer.add(pin);
              pin.forEach(function (pin) {
                window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
              });
            }
          });
        }
      }
    }
    //Check for updated facility pins
    if (this.props.facilities || nextProps.facilities) {
      if (this.props.facilityLocations !== nextProps.facilityLocations) {
        var facilitiesLayer = utils.getLayer(mapConstants.FACILITIES_LAYER, map[mapReference]);
        if (facilitiesLayer) {
          facilitiesLayer.clear();

          const facilityPins = utils.createFacilityPins(nextProps.facilityLocations, window.Microsoft);

          if (facilitiesLayer && facilityPins) {
            facilitiesLayer.add(facilityPins);
            facilityPins.forEach(function (pin) {
              window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
            });
          }
        }
      }
    }
    //Check for updated incidents data
    if (this.props.incidents || nextProps.incidents) {
      if (this.props.incidentData !== nextProps.incidentData) {
        window.Microsoft.Maps.loadModule('Microsoft.Maps.GeoJson', () => {
          var incidentsLayer = utils.getLayer(mapConstants.INCIDENTS_LAYER, map[mapReference]);
          if (incidentsLayer) {
            incidentsLayer.clear();

            var shapesToDraw = [];
            _.each(nextProps.incidentData, function (newIncidentCandidate) {
              if (newIncidentCandidate.affectedArea) {
                shapesToDraw = shapesToDraw.concat(utils.getShapesToDraw(newIncidentCandidate.affectedArea, newIncidentCandidate._id, window.Microsoft));
              }
            });
            _.each(shapesToDraw, function (shape) {
              incidentsLayer.add(shape);
              window.Microsoft.Maps.Events.addHandler(shape, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
            });
          }
        });
      }
    }
    //Check for updated ports data
    if (this.props.ports || nextProps.ports) {
      if (this.props.portsData !== nextProps.portsData) {
        var portsLayer = utils.getLayer(mapConstants.PORTS_LAYER, map[mapReference]);
        if (portsLayer) {
          portsLayer.clear();
          var staticPorts = nextProps.portsData.map(function (port) {
            return utils.portsBuilder(port._source, window.Microsoft);
          });
          if (portsLayer && staticPorts) {
            portsLayer.add(staticPorts);
            staticPorts.forEach(function (pin) {
              window.Microsoft.Maps.Events.addHandler(pin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
            });
          }
        }
      }
    }
    //Check for currentLocationPin on the tracker page
    if (this.props.currentLocationPin || nextProps.currentLocationPin) {
      if (nextProps.currentLocationPin && this.props.currentLocationPin !== nextProps.currentLocationPin || (this.props.currentLocationPin && this.props.currentLocationPin.size !==0 && this.props.isMapLoaded !== nextProps.isMapLoaded)) {
        var current_location_pin_layer = utils.getLayer(mapConstants.CURRENT_LOCATION_PIN_LAYER, map[mapReference]);
        if (current_location_pin_layer) {
          current_location_pin_layer.clear();
          current_location_pin_layer.add(utils.getCurrentLocationPin(nextProps.currentLocationPin, window.Microsoft));
        }
      }
    }

    if (this.props.expectedPosition || nextProps.expectedPosition) {
      if (nextProps.expectedPosition && this.props.expectedPosition !== nextProps.expectedPosition || (this.props.expectedPosition && this.props.expectedPosition.size !==0 && this.props.isMapLoaded !== nextProps.isMapLoaded)) {
        var expected_position_pin_layer = utils.getLayer(mapConstants.EXPECTED_POSITION_PIN_LAYER, map[mapReference]);
        if (expected_position_pin_layer) {
          expected_position_pin_layer.clear();
          var expectedPositionPin = utils.getExpectedPositionPin(nextProps.expectedPosition, window.Microsoft);
          if (expectedPositionPin && expected_position_pin_layer) {
            expected_position_pin_layer.add(expectedPositionPin);
            window.Microsoft.Maps.Events.addHandler(expectedPositionPin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
          }
        }
      }
    }
    //Check for updated routing segment data
    if (this.props.routingSegments || nextProps.routingSegments) {
      if (this.props.routingSegments !== nextProps.routingSegments || (this.props.routingSegments && this.props.routingSegments.size !==0 && this.props.isMapLoaded !== nextProps.isMapLoaded)) {
        var routingLayer = utils.getLayer(mapConstants.ROUTING_LAYER, map[mapReference]);
        if (routingLayer) {
          routingLayer.clear();
          nextProps.routingSegments.forEach(async segment => {
            var options = {
              strokeColor: segment.color,
              strokeThickness: 8
            };
            if (segment.lineType === 'dashed') {
              options.strokeDashArray = [1, 2];
              options.strokeThickness = 5;
            }
            if ((this.props.useIotMap && segment.lineType === 'dashed') || !this.props.useIotMap) {
              routingLayer.add(utils.createPolyLine(segment.locations, options, window.Microsoft));
            }
          });
        }
      }
    }

    if (this.props.iotSensorLocations || nextProps.iotSensorLocations) {
      if (this.props.iotSensorLocations !== nextProps.iotSensorLocations || (this.props.iotSensorLocations && this.props.iotSensorLocations.locations && this.props.iotSensorLocations.locations.length !==0 && this.props.isMapLoaded !== nextProps.isMapLoaded)) {
        var iotRoutingLayer = utils.getLayer(mapConstants.IOT_ROUTING_LAYER, map[mapReference]);
        if (iotRoutingLayer) {
          iotRoutingLayer.clear();

          var options = {
            strokeColor: nextProps.iotSensorLocations.color,
            strokeThickness: 8
          };

          if (this.props.useIotMap) {
            const accuracyFilteredLocations = nextProps.iotSensorLocations.locations.filter((location) => {
              return location.accuracy <= 80000;
            });

            iotRoutingLayer.add(utils.createPolyLine(accuracyFilteredLocations, options, window.Microsoft));
          }
        }
      }
    }

    if (this.props.useIotMap || nextProps.useIotMap) {
      if (this.props.useIotMap !== nextProps.useIotMap && map[mapReference]) {
        var northWest = map[mapReference].getBounds().getNorthwest();
        var southEast = map[mapReference].getBounds().getSoutheast();
        const visibleAreaBounds = [
          {
            latitude: northWest.latitude,
            longitude: northWest.longitude
          },
          {
            latitude: southEast.latitude,
            longitude: southEast.longitude
          }
        ];
      }
    }

    if (this.props.iotDetailsPins || nextProps.iotDetailsPins) {
      if (this.props.iotDetailsPins !== nextProps.iotDetailsPins) {
        var iot_details_pin_layer = utils.getLayer(mapConstants.IOT_DETAILS_PIN_LAYER, map[mapReference]);

        if (iot_details_pin_layer && nextProps.iotDetailsPins.length) {
          const groupedIotMetrics = nextProps.iotDetailsPins;

          groupedIotMetrics.forEach((pin, idx) => {
            if (pin.accuracy && pin.accuracy > 80000) {
              return;
            }

            const pinIsCurrentLocation = idx === (groupedIotMetrics.length - 1);
            if (pinIsCurrentLocation) {
              pin.isCurrentLocation = true;
            }

            if (pin.isAnomaly) {
              const anomalyPin = utils.getIotAnomalyPin(pin, window.Microsoft);
              iot_details_pin_layer.add(anomalyPin);
              window.Microsoft.Maps.Events.addHandler(anomalyPin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
            } else {
              const detailsPin = utils.getIotDetailsPin(pin, window.Microsoft);
              if (pinIsCurrentLocation) {
                iot_details_pin_layer.add(detailsPin, 0);
              } else {
                iot_details_pin_layer.add(detailsPin);
              }
              window.Microsoft.Maps.Events.addHandler(detailsPin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
            }
          });
        }
      }
    }

    //Check for Pick and Drop pins to add
    if (this.props.pickDropPins || nextProps.pickDropPins) {
      if (this.props.pickDropPins !== nextProps.pickDropPins || (this.props.pickDropPins && this.props.isMapLoaded !== nextProps.isMapLoaded)) {
        var location_pins_layer = utils.getLayer(mapConstants.LOCATION_PINS_LAYER, map[mapReference]);
        if (location_pins_layer) {
          location_pins_layer.clear();

          var lats = nextProps.pickDropPins.map(function (stop) {
            return stop.coordinate.Lat;
          });

          var lons = nextProps.pickDropPins.map(function (stop) {
            return stop.coordinate.Lon;
          });

          var maxLat = _.max(lats);
          var maxLon = _.max(lons);
          var minLat = _.min(lats);
          var minLon = _.min(lons);
          var width = Math.abs((minLon - maxLon));
          var height = Math.abs((minLat - maxLat));

          let modifiedStops = addStopTextToStops(nextProps.pickDropPins);
          let pickDropPins = modifiedStops.map(stop => {
            let createdPin = utils.getTrackerPins(stop, window.Microsoft);
            window.Microsoft.Maps.Events.addHandler(createdPin, 'click', (e) => utils.pinClicked(e, window.Microsoft, map[mapReference], nextProps));
            return createdPin;
          });

          pickDropPins.forEach(pin => {
            location_pins_layer.add(pin);
          });

          if (this.props.center) {
            utils.setMapBounds(new window.Microsoft.Maps.LocationRect(new window.Microsoft.Maps.Location(this.props.center.lat, this.props.center.lon), width, height), map[mapReference]);
          }
        }
      }
    }
    //Check for actions that move map to the incident view
    if (this.props.zoomToIncident || nextProps.zoomToIncident) {
      if (this.props.zoomToIncident !== nextProps.zoomToIncident) {
        var shapes = [];
        nextProps.zoomToIncident.affectedArea.features.forEach(feature => {
          shapes.push(utils.getShapeFromGeoJson(feature, null, window.Microsoft))
        });

        const flattenedShapes = _.flatten(shapes);

        utils.setMapBounds(new window.Microsoft.Maps.LocationRect.fromShapes(flattenedShapes), map[mapReference]);
      }
    }
    //Check for starting to draw or stopping drawing on map
    if (this.props.shouldDrawOnMap || nextProps.shouldDrawOnMap) {
      if (this.props.shouldDrawOnMap !== nextProps.shouldDrawOnMap) {
        if (nextProps.shouldDrawOnMap && nextProps.currentDrawnIncident === undefined) {
          window.Microsoft.Maps.loadModule('Microsoft.Maps.DrawingTools', async () => {
            this.tools = await utils.addDrawingTools(this.tools, map[mapReference], window.Microsoft);
            await utils.drawPolygon(this.currentShape, this.tools, map[mapReference], window.Microsoft, this.drawnShapeCallback);
          });
        } else {
          //clean up bing maps drawing tools
          if (this.tools) {
            this.tools.dispose();
            delete this.tools;
          }
        }
      }
    }
    //Remove draw shape from map, coming from a different container
    if (this.props.currentDrawnIncident || nextProps.currentDrawnIncident) {
      if (this.props.currentDrawnIncident !== nextProps.currentDrawnIncident) {
        //the user wants to clear out the shape
        if (nextProps.currentDrawnIncident === undefined && nextProps.shouldDrawOnMap) {
          //You have to remove the drawing tools
          if (this.tools) {
            this.tools.dispose();
            delete this.tools;
          }
          //Then re-add drawing tools, this is weird but can't find a better way to clear shapes
          window.Microsoft.Maps.loadModule('Microsoft.Maps.DrawingTools', async () => {
            this.tools = await utils.addDrawingTools(this.tools, map[mapReference], window.Microsoft);
            await utils.drawPolygon(this.currentShape, this.tools, map[mapReference], window.Microsoft, this.drawnShapeCallback);
          });
          //we are trying to edit an existing incident
        } else if (nextProps.currentDrawnIncident && nextProps.currentDrawnIncident.affectedArea) {
          window.Microsoft.Maps.loadModule('Microsoft.Maps.GeoJson', () => {
            var incidentsEditLayer = utils.getLayer(mapConstants.INCIDENTS_EDIT_LAYER, map[mapReference]);
            if (incidentsEditLayer) {
              incidentsEditLayer.clear();

              var shapesToDraw = [];
              shapesToDraw = shapesToDraw.concat(utils.getShapesToDraw(nextProps.currentDrawnIncident.affectedArea, nextProps.currentDrawnIncident._id, window.Microsoft));
              _.each(shapesToDraw, function (shape) {
                incidentsEditLayer.add(shape);
              });
            }
          });
        }
      }
    }

    if(!this.props.useIotMap && !nextProps.useIotMap) {
      const iotRoutingLayer = utils.getLayer(mapConstants.IOT_ROUTING_LAYER, map[mapReference]);

      if (iotRoutingLayer) {
        iotRoutingLayer.clear();
      }
    }

  }

  drawnShapeCallback = (geoJsonShape) => {
    this.props.saveDrawnIncidentShape(geoJsonShape);
  }

  loadScript(url) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  handleMapChange(props, map) {
    if (props && map && props.location?.search && queryString.parse(props.location.search).displayMode !== 'listOnly') {
      const currentZoom = map.getZoom();

      // todo
      if (props.clustering) {
        if (!!props.configuration.clusterSelected && !!props.configuration.box) {
          //we currently lock the user into the selected view and zoom if a cluster is selected.
          const hashRect = window.Microsoft.Maps.LocationRect.fromCorners(
            new window.Microsoft.Maps.Location(props.configuration.box.bottom_right.lat, props.configuration.box.top_left.lon),
            new window.Microsoft.Maps.Location(props.configuration.box.top_left.lat, props.configuration.box.bottom_right.lon)
          );

          map.setView({
            center: hashRect.center,
            zoom: currentZoom
          });
        }

        var northWest = map.getBounds().getNorthwest();
        var southEast = map.getBounds().getSoutheast();
        const currentBox = {
          top_left: {
            lat: northWest.latitude,
            lon: northWest.longitude
          },
          bottom_right: {
            lat: southEast.latitude,
            lon: southEast.longitude
          }};

        if ((currentZoom && currentZoom !== props.zoom) || (currentBox && JSON.stringify(currentBox) !== JSON.stringify(props.box))) {
          props.mapDetailsChange(currentZoom, currentBox);
        }
      }
      if (props.useIotMap) {
        var northWest = map.getBounds().getNorthwest();
        var southEast = map.getBounds().getSoutheast();
        const visibleAreaBounds = [
          {
            latitude: northWest.latitude,
            longitude: northWest.longitude
          },
          {
            latitude: southEast.latitude,
            longitude: southEast.longitude
          }
        ];
      }
    }
  }

  bingMap(props, Microsoft) {
    const {
      bingmapKey,
      defaultZoom,
      mapType,
      zoom
    } = props;
    if (bingmapKey && Microsoft) {
      let mapReference = props.id ? ('#' + props.id) : '.react-bingmaps';
      if (!map[mapReference]) {
        map[mapReference] = new Microsoft.Maps.Map(mapReference, {
          enableClickableLogo: false,
          enableInertia: false,
          credentials: bingmapKey,
          allowHidingLabelsOfRoad: true,
          showLocateMeButton: false,
          showMapTypeSelector: false,
          showZoomButtons: true,
          maxZoom: 15,
          minZoom: 3,
          zoom: defaultZoom || this.props.zoom,
          showTrafficButton: false,
          mapTypeId: Microsoft.Maps.MapTypeId[mapType]
        });
      }
      if (this.props.pageName !== ExternalTrackingDetailPage) {
        this.props.fetchData(this.props.userRole);
      }

      if (this.props.showWeather) {
        utils.setWeatherLayerOptions(mapConstants.WEATHER_LAYER, { visible: this.props.showWeather }, map[mapReference], Microsoft, this.props.appConfig);
      }
      if (this.props.showTraffic) {
        Microsoft.Maps.loadModule('Microsoft.Maps.Traffic', () => {
          this.trafficManager = utils.addTrafficLayer(this.trafficManager, map[mapReference], Microsoft);
        });
      }
      if (this.props.clustering) {
        utils.setNamedLayerVisible(mapConstants.REGION_LAYER, true, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.PIN_LAYER, true, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.AIR_LAYER, true, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.TRUCK_LAYER, true, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.VESSEL_LAYER, true, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.RAIL_LAYER, true, map[mapReference], Microsoft);
      }
      if (this.props.showTrackerData) {
        utils.setNamedLayerVisible(mapConstants.LOCATION_PINS_LAYER, this.props.showTrackerData, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.CURRENT_LOCATION_PIN_LAYER, this.props.showTrackerData, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.EXPECTED_POSITION_PIN_LAYER, this.props.showTrackerData, map[mapReference], Microsoft);
        utils.setNamedLayerVisible(mapConstants.ROUTING_LAYER, this.props.showTrackerData, map[mapReference], Microsoft);
        this.props.mapLoaded(true);
      }
      if (this.props.showFacilities) {
        utils.setNamedLayerVisible(mapConstants.FACILITIES_LAYER, this.props.showFacilities, map[mapReference], Microsoft);
      }
      if (this.props.showIotLocations) {
        utils.setNamedLayerVisible(mapConstants.IOT_ROUTING_LAYER, this.props.showIotLocations, map[mapReference], Microsoft);
      }
      if (this.props.showIotDetails) {
        utils.setNamedLayerVisible(mapConstants.IOT_DETAILS_PIN_LAYER, this.props.showIotDetails, map[mapReference], Microsoft);
      }
      if (this.props.showPorts) {
        utils.setNamedLayerVisible(mapConstants.PORTS_LAYER, this.props.showPorts, map[mapReference], Microsoft);
      }
      if (this.props.showIncidents) {
        utils.setNamedLayerVisible(mapConstants.INCIDENTS_LAYER, this.props.showIncidents, map[mapReference], Microsoft);
      }

      Microsoft.Maps.Events.addHandler(map[mapReference], 'viewchangeend', () => this.debouncedHandleMapChange(this.props, map[mapReference]));

      //keep the same center when moving from one map view to another
      if (props.box && props.box.size !== 0 && props.zoom && (!this.props.lastLocation || this.props.lastLocation && !this.props.lastLocation.pathname.includes('shipment-tracker'))) {
        var hashRect = window.Microsoft.Maps.LocationRect.fromCorners(
          new window.Microsoft.Maps.Location(props.box.bottom_right.lat, props.box.top_left.lon),
          new window.Microsoft.Maps.Location(props.box.top_left.lat, props.box.bottom_right.lon)
        );

        map[mapReference].setView({
          center: hashRect.center,
          zoom: zoom
        });
      }
    }
  }

  makeCallback(callback, data) {
    data ? callback(data) : callback();
  }

  //The render below contains a special banner for the incident creation screen
  render() {
    return (
      <div id={this.props.id} className='react-bingmaps' style={{ height: "100%", position: "relative" }}>
        {this.props.shouldDrawOnMap && <div style={{ position: "absolute", zIndex: 1000, fontSize: '30px', color: 'white', marginLeft: '1%', marginRight: '10%', maringTop: '1rem', paddingTop: '2rem' }}>Click once to start creating a shape, double click to stop.</div>}
        {this.props.loading && <div style={{ position: "absolute", zIndex: 1000, fontSize: '10px', color: 'white', margin: '1rem' }}>Loading...</div>}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  const shipmentsConfiguration = state.shipments.data;
  const configuration = state.shipments.queryConfiguration;
  const userProfile = state.users.get('userProfile');
  const userRole = userProfile ? userProfile.role : null;
  return {
    userProfile,
    userRole,
    jobMode: shipmentsConfiguration.get('jobMode'),
    dynamicFilters: state.filters.get('dynamicFilters'),
    loading: shipmentsConfiguration.get('loading'),
    incidentData: state.incidents.get('incidents'),
    facilityLocations: state.facilityLocations.get('facilities'),
    showWeather: state.map.get('mapContent').get('weather'),
    showTraffic: state.map.get('mapContent').get('traffic'),
    showPorts: state.map.get('mapContent').get('ports'),
    showIncidents: state.map.get('mapContent').get('incidents'),
    showFacilities: state.map.get('mapContent').get('facilities'),
    clustering: state.map.get('clustering'),
    clusters: shipmentsConfiguration.get('clustering'),
    mapType: state.map.get('mapType'),
    defaultZoom: state.map.get('mapContent').get('zoom'),
    shipmentSearch: state.map.get('shipmentSearch'),
    facilities: state.map.get('facilities'),
    incidents: state.map.get('incidents'),
    ports: state.map.get('ports'),
    portsData: state.portsData.get('ports'),
    shipmentSearchData: shipmentsConfiguration.get('shipments'),
    liveTrackedPins: shipmentsConfiguration.get('liveTrackedPins'),
    box: configuration.get('box'),
    zoom: configuration.get('zoom'),
    sort: configuration.get('sort'),
    displayMode: configuration.get('displayMode'),
    configuration: configuration.toObject()
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchData: (userRole) => {
      dispatch(incidentActions.fetchIncidents());
      if (userRole && userRole !== 'ExternalUser') {
        dispatch(portsActions.fetchPorts());
        dispatch(facilitiesActions.fetchFacilities());
      }
    },
    mapDetailsChange: (zoom, box) => dispatch(shipmentActions.changeMapDetails(zoom, box, ownProps.location, ownProps.history)),
    facilityFiltersChanged: (filter) => {
      return dispatch(shipmentActions.facilityFiltersChanged(filter, ownProps.location, ownProps.history, 'listOnly'));
    },
  };
};

export const MainMapContainer = withRouter(withLastLocation(connect(mapStateToProps, mapDispatchToProps)(MapContainer)));

export const mapTrackerStateToProps = (state, ownProps) => {
  const detailLevel = getTrackingDetailLevel(ownProps.location.pathname);
  return {
    mapType: state.shipmentMap.get('mapType'),
    showIncidents: state.shipmentMap.get('mapContent').get('incidents'),
    showTraffic: state.shipmentMap.get('mapContent').get('traffic'),
    showTrackerData: state.shipmentMap.get('mapContent').get('trackerData'),
    showIotDetails: state.shipmentMap.get('mapContent').get('iotDetails'),
    showIotLocations: state.shipmentMap.get('mapContent').get('iotLocations'),
    shipmentSearch: state.shipmentMap.get('shipmentSearch'),
    facilities: state.shipmentMap.get('facilities'),
    incidents: state.shipmentMap.get('incidents'),
    ports: state.shipmentMap.get('ports'),
    incidentData: state.incidents.get('incidents'),
    routingSegments: state[detailLevel].get('routingSegments'),
    currentLocationPin: state[detailLevel].get('currentLocation'),
    expectedPosition: state[detailLevel].get('expectedPosition'),
    pickDropPins: state[detailLevel].get('pickDropPins'),
    isMapLoaded: state.shipmentMap.get('mapLoaded'),
    useIotMap: state.iotSummary.get('useIotMap'),
    iotDetailsPins: state.iotSummary.get('iotMapLogs'),
    iotSensorLocations: state.iotSummary.get('iotSensorLocations')
  };
};

export const mapTrackerDispatchToProps = dispatch => {
  return {
    fetchData: () => {
      dispatch(incidentActions.fetchIncidents());
    },
    saveDrawnIncidentShape: (incident) => {
      dispatch(incidentActions.saveDrawnIncidentShape(incident))
    },
    mapLoaded: (isLoaded) => {
      dispatch(mapActions.mapLoaded(isLoaded))
    },
    fetchIotSensorLocationData: (payload) => {
      dispatch(iotActions.fetchIotSensorLocations(payload));
    },
    fetchIotMapLogs: (payload) => {
      dispatch(iotActions.fetchIotMapLogs(payload));
    }
  };
};

export const ShipmentTrackerMap = connect(mapTrackerStateToProps, mapTrackerDispatchToProps)(MapContainer);

export const mapDisruptionsStateToProps = state => {
  return {
    showWeather: state.disruptionsMap.get('mapContent').get('weather'),
    mapType: state.disruptionsMap.get('mapType'),
    showIncidents: state.disruptionsMap.get('mapContent').get('incidents'),
    showEditIncidents: state.disruptionsMap.get('mapContent').get('editIncidents'),
    showTraffic: state.disruptionsMap.get('mapContent').get('traffic'),
    shouldDrawOnMap: state.disruptionsMap.get('shouldDrawOnMap'),
    incidents: state.disruptionsMap.get('incidents'),
    currentDrawnIncident: state.incidents.get('currentDrawnIncident'),
    incidentData: state.incidents.get('incidents'),
    zoomToIncident: state.disruptionsMap.get('zoomToIncident')
  };
};

export const DisruptionsMap = connect(mapDisruptionsStateToProps, mapTrackerDispatchToProps)(MapContainer);
