import React from 'react';
import * as commonMethods from '../../utils/helperMethods/commonMethods';
import { ORDER_TRACKER_PATH, DEFAULT_SHIPMENT_PAYLOAD_LIMIT, JOB_MODES } from '../constants';

const isHidden = (identifiers) => {
  let hidden = true;

  if (identifiers) {
    hidden = !identifiers.some(element => element && element.value);
  }

  return hidden;
};

const getIdentifierValues = (identifiers, limit) => {
  let result = [];
  if (identifiers && identifiers.filter) {
    result = identifiers.filter(function (val) {
      return val.value;
    }).map(function (val) {
      return val.value;
    });
  }
  if (limit && limit > 0) {
    result = result.slice(0, limit);
  } else {
    result = result.slice(0);
  }
  return result.join(", ");
};

const showETA = (date) => {
  return Date.parse(date) > 0;
};

const getIdentifierLabel = (identifiers) => {
  return identifiers && identifiers.length > 0 && identifiers[0].display_label ? identifiers[0].display_label : '';
};

const identifierLimit = 3;

const trackShipment = (selectedShipment, jobMode, stopOrderNumber) => {
  const pathName = selectedShipment.history.location ? selectedShipment.history.location.pathname : null;
  const trackingType = commonMethods.determineTrackingType(selectedShipment);
  const trackingNumber = commonMethods.determineTrackingNumber(selectedShipment, jobMode);
  const orderNumber = stopOrderNumber || commonMethods.determineOrderNumber(selectedShipment, jobMode);
  let baseUrl = jobMode === JOB_MODES.orders ? `${ORDER_TRACKER_PATH}/` : `/shipment-tracker/`;
  let route = baseUrl + `${encodeURIComponent(encodeURIComponent(getOrderId(selectedShipment.id, stopOrderNumber, jobMode, pathName)))}` + `?loadNumber=${selectedShipment.load_number}` +
    `&orderNumber=${orderNumber}` + `&trackingType=${trackingType}` + `&trackingNumber=${trackingNumber}`;
  if (selectedShipment.shipment_number) {
    route = route.concat(`&shipmentNumber=${selectedShipment.shipment_number}`)
  }
  selectedShipment.history.push(route);
};

const createModalConfig = (ids) => {
  return {
    title: 'Reference Numbers',
    showActionButton: false,
    body: (<div>{ids && ids.map(x => { return (<div>{x.value}<br /></div>); })}</div>)
  };
};

const createDynamicModalConfig = (title, values) => {
  return {
    title: title,
    showActionButton: false,
    body: (<div>{values && values.map(x => { return (<div>{x}<br /></div>); })}</div>)
  };
};

const styles = (props) => {
  return { outerCardClassNames: 'card shipment-card ' + commonMethods.getStatusClass(props.status) }
};

const parseShipmentStatus = (status) => {
  let label;
  let lowerCaseStatus = status.toLowerCase();
  switch (lowerCaseStatus) {
    case 'atfacility':
      label = 'At Facility';
      break;
    case 'arrivingfacility':
      label = 'Inbound';
      break;
    case 'departingfacility':
      label = 'Outbound';
      break;
    default:
      label = status;
      break;
  }
  return label;
};

const parseFacilityFilter = urlPart => {
  var str = urlPart.split(":");
  return {
    facilityName: str[0],
    facilityCode: str[1],
    facilityIdentifiers: str[2],
    shipmentStatus: str[3],
    parsedFacilityStatus: parseShipmentStatus(str[3])
  };
}

const getOffset = currentPage => DEFAULT_SHIPMENT_PAYLOAD_LIMIT * (currentPage - 1);

//apply orderNumber to id when switching from shipment to order tracker
const getOrderId = (id, stopOrderNumber, jobMode, pathName) => {

  let idDelimiter = '|';
  if (stopOrderNumber && !id.includes(stopOrderNumber)) {
    id = id.concat(idDelimiter, stopOrderNumber);
  }

  //Remove orderNumber to id when switching from order to shipment tracker
  if (jobMode === 'shipments' && pathName && pathName.includes('order-tracker')){
      let splitId = id?.split(idDelimiter);      

      if (splitId?.length === 4) {
        id = splitId.slice(0, -1).join(idDelimiter);
      }
  }
  return id;
};

export {
  getIdentifierValues,
  showETA,
  getIdentifierLabel,
  identifierLimit,
  trackShipment,
  createModalConfig,
  styles,
  parseShipmentStatus,
  parseFacilityFilter,
  getOffset,
  isHidden,
  getOrderId,
  createDynamicModalConfig
};
