import React from 'react';
import { getTimeLocal, getLongDateLocal } from '../../utils/helperMethods/dateTimeMethods';


const parseMessageForMentions = (message) => {
  if(!message) return message;
  var offset = 0;
  var messageToReturn = [];
  var currentMessage = '';

  for (var i = 0; i < message.length; i++) {
    if (message[i] === '@' && message[i + 1] === '[') {
      //We know we have a user tag or an emoji at this point
      if (currentMessage.length > 0) {
        messageToReturn.push(<div key={i+'currentMessage'} style={{display:'inline'}}>{currentMessage}</div>);
        currentMessage = '';
      }
      var specialTag = ''; //This is what we are looking for, either the user or the emoji tag
      for (var j = i + 2; j < message.length; j++) {
        if (message[j] === ']') {
          i = message.indexOf(')', offset); //jump the index up
          offset = i + 1;
          const isEmoji = message.substring(j,i).includes('emoji');
          const messageToShow = isEmoji ? specialTag : ('@' + specialTag);
          messageToReturn.push(<div key={i + 'useremoji' + j}style={{display:'inline', color: '#f47b20'}}>{messageToShow}</div>);
          break;
        } else {
          specialTag += message[j];
        }
      }
    } else {
      currentMessage += message[i];
    }
  }
  if (currentMessage.length > 0) {
    messageToReturn.push(<div id={`comment-body`} key='currentMessageKey' style={{display:'inline'}}>{currentMessage}</div>);
  }
  return <div>{messageToReturn}</div>;
}
const renderComment = (props)=>{
  return (
    <div className="col-xs-10 col-sm-11">
      {
        props.showNameAndImage &&
        <div className="row">
          <div id='commentDisplayName' className="col-xs-12">
            <strong>{props.displayName}</strong>
          </div>
        </div>
      }
      <div className="row">
          <div id='commentCreatedDateTime' className="col-xs-12">
            <strong>{getTimeLocal(props.createdDateTime)}</strong>
          </div>
        </div>
      <div className="row">
        <div id={props.id || 'commentMessage'} className="col-xs-12">
          {parseMessageForMentions(props.message)}
        </div>
      </div>
    </div>
  )
};

const renderImage = (props)=>{
    return (
      <div className="col-xs-2 col-sm-1">
        <img id='commentGravatar' src={props.gravatarUrl} className="Profile-img float-right" alt="user comment icon" height={40} width={40}/>
      </div>
    );
}
const renderEmpty = ()=>{
  return (
    <div className="col-xs-2 col-sm-1">
    </div>
  );
}

const renderDateTag = (props)=>{
  return (
    <div id='commentDateTag' className="row"><hr className="conversation hr-text" data-content={ getLongDateLocal(props.createdDateTime) }></hr></div>
  );
};

const Comment = (props)=>{
  return (
    <div className="row">
        <div className="col-sm-12">
        {
          props.showDateTag && renderDateTag(props)
        }
        <div className="row">
        {
          props.showNameAndImage && renderImage(props)
        }
        {
          !props.showNameAndImage && renderEmpty()
        }
        {
          renderComment(props)
        }
        </div>
      </div>
    </div>
  );
}

export default Comment;
