import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchTenantMetadataStart({ payload }) {
  try {
    const tenantMetadata = yield all({
      regions: call(api.shipments.fetchRegions),
      flows: call(api.metadata.fetchFlows, payload.tenantId),
      channels: call(api.metadata.fetchChannels, payload.tenantId),
      facilities: call(api.metadata.fetchFacilities, payload.tenantId),
      accounts: call(api.metadata.fetchAccounts, payload.tenantId),
      externalAccounts: call(api.metadata.fetchExternalAccounts, payload.tenantId),
      tenants: call(api.metadata.fetchTenants),
      accessFilters: call(api.metadata.fetchAccessFilters, payload.tenantId)
    });
    yield put(actions.fetchTenantMetadataComplete(tenantMetadata))

  }
  catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(e));
  }
}

export function* watchGetTenantMetadataStart() {
  yield takeEvery(types.FETCH_TENANT_METADATA_STARTED, fetchTenantMetadataStart);
}

export function* fetchTenantFlowsStart() {
  try {
    const flows = yield call(api.metadata.fetchFlows);
    yield put(actions.fetchTenantFlowsComplete(flows))
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Flows could not be retrieved. Please try again.`
    }));
  }
}

export function* watchGetTenantFlowsStart() {
  yield takeEvery(types.FETCH_TENANT_FLOWS_STARTED, fetchTenantFlowsStart);
}

export function* fetchTenantRegionsStart() {
  try {
    const regions = yield call(api.shipments.fetchRegions);
    yield put(actions.fetchTenantRegionComplete(regions))
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Regions could not be retrieved. Please try again.`
    }));
  }
}

export function* watchGetTenantRegionsStart() {
  yield takeEvery(types.FETCH_TENANT_REGION_STARTED, fetchTenantRegionsStart);
}
