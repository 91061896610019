import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import actions from './actions';
import LineGraph from '../../components/line-graph';
import { statusColorMapping, strokeWidthMapping } from '../../utils/helperMethods/commonMethods';

const defaultDateRange = "7Days";

export class HistoricalHealthContainer extends React.Component {
  state = {
    selectedRange: defaultDateRange
  };

  componentDidMount() {
    this.props.fetchShipmentsHealth('Ocean', defaultDateRange);
    this.props.fetchShipmentsHealth('Parcel', defaultDateRange);
    this.props.fetchShipmentsHealth('Air', defaultDateRange);
    this.props.fetchShipmentsHealth('Ground', defaultDateRange);
  }

   getSeries = (buckets, totalsPerDay) => {
     let ret = [];
     for(const bucket of buckets) {
       ret.push({
         delivery_date: bucket.key_as_string,
         value: bucket.doc_count === 0 ? bucket.doc_count : Math.round((bucket.doc_count / totalsPerDay[bucket.key_as_string]) * 100)
       })
     }
     return ret;
   }

  getTotalPerDay = (healthData) => {
    let totalsPerDay = {};
    if(healthData.total_early) {
      healthData.total_early.early.buckets.forEach((val, index) => {
        totalsPerDay[val.key_as_string] = val.doc_count + healthData.total_late.Late.buckets[index].doc_count + healthData.total_onTime.onTime.buckets[index].doc_count;
      });
    }
    return totalsPerDay;
  }

  mapHealthData = (data) => {
    if(data && data.total_early && data.total_onTime && data.total_late) {
      const totals = this.getTotalPerDay(data);
      return [{
        name: 'Early',
        data: this.getSeries(data.total_early.early.buckets, totals)
      }, {
        name: 'On Time',
        data: this.getSeries(data.total_onTime.onTime.buckets, totals)
      }, {
        name: 'Late',
        data: this.getSeries(data.total_late.Late.buckets, totals)
      }]
    }
  }

  selectView = (selectedRange) => {
    if (this.state.selectedRange !== selectedRange) {
      this.props.fetchShipmentsHealth('Ocean', selectedRange);
      this.props.fetchShipmentsHealth('Parcel', selectedRange);
      this.props.fetchShipmentsHealth('Air', selectedRange);
      this.props.fetchShipmentsHealth('Ground', selectedRange);
      this.setState({
        selectedRange: selectedRange
      });
    }
  }

  render() {
    return(
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="lineGraphDateRange">
              <button
                className={this.state.selectedRange === defaultDateRange ? "btn btn-md btn-primary" : "btn btn-md"}
                onClick={() => { return this.selectView(defaultDateRange); }} data-test="7Days">Last 7 Days</button>
              <button
                className={this.state.selectedRange === '30Days' ? "btn btn-md btn-primary" : "btn btn-md"}
                onClick={() => { return this.selectView('30Days'); }} data-test="30Days">Last 30 Days</button>
              <button
                className={this.state.selectedRange === '90Days' ? "btn btn-md btn-primary" : "btn btn-md"}
                onClick={() => { return this.selectView('90Days'); }} data-test="90Days">Last 90 Days</button>
              <button
                className={this.state.selectedRange === '180Days' ? "btn btn-md btn-primary" : "btn btn-md"}
                onClick={() => { return this.selectView('180Days'); }} data-test="180Days">Last 180 Days</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className='healthOvertime'>
              <div className="row">
                <div className="col-lg-6 groundLineGraph" data-test="groundGraph" style={{display:this.props.showGround}}>
                  <LineGraph
                    header={"Ground"}
                    series={this.mapHealthData(this.props.groundHealthData)}
                    xAxisDataKey={"delivery_date"}
                    xAxisType={"category"}
                    xAxisLabel={"Delivery Date"}
                    yAxisDomain={[0, 100]}
                    yAxisLabel={"% of Shipments"}
                    height={300}
                    width={"100%"}
                    dataKey={"value"}
                    colorMapping={statusColorMapping}
                    strokeWidthMapping={strokeWidthMapping}
                    loading={this.props.groundHealthLoading}
                  />
                </div>
                <div className="col-lg-6 parcelLineGraph" data-test="parcelGraph" style={{display:this.props.showParcel}}>
                  <LineGraph
                    header={"Parcel"}
                    series={this.mapHealthData(this.props.parcelHealthData)}
                    xAxisDataKey={"delivery_date"}
                    xAxisType={"category"}
                    xAxisLabel={"Delivery Date"}
                    yAxisDomain={[0, 100]}
                    yAxisLabel={"% of Shipments"}
                    height={300}
                    width={"100%"}
                    dataKey={"value"}
                    colorMapping={statusColorMapping}
                    strokeWidthMapping={strokeWidthMapping}
                    loading={this.props.parcelHealthLoading}
                  />
                </div>
                <div className="col-lg-6 oceanLineGraph" data-test="oceanGraph" style={{display:this.props.showOcean}}>
                  <LineGraph
                    header={"Ocean"}
                    series={this.mapHealthData(this.props.oceanHealthData)}
                    xAxisDataKey={"delivery_date"}
                    xAxisType={"category"}
                    xAxisLabel={"Delivery Date"}
                    yAxisDomain={[0, 100]}
                    yAxisLabel={"% of Shipments"}
                    height={300}
                    width={"100%"}
                    dataKey={"value"}
                    colorMapping={statusColorMapping}
                    strokeWidthMapping={strokeWidthMapping}
                    loading={this.props.oceanHealthLoading}
                  />
                </div>
                <div className="col-lg-6 airLineGraph" data-test="airGraph" style={{display:this.props.showAir}}>
                  <LineGraph
                    header={"Air"}
                    series={this.mapHealthData(this.props.airHealthData)}
                    xAxisDataKey={"delivery_date"}
                    xAxisType={"category"}
                    xAxisLabel={"Delivery Date"}
                    yAxisDomain={[0, 100]}
                    yAxisLabel={"% of Shipments"}
                    height={300}
                    width={"100%"}
                    dataKey={"value"}
                    colorMapping={statusColorMapping}
                    strokeWidthMapping={strokeWidthMapping}
                    loading={this.props.airHealthLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

HistoricalHealthContainer.propTypes = {
  airHealthData: PropTypes.object,
  airHealthLoading: PropTypes.bool,
  groundHealthData: PropTypes.object,
  groundHealthLoading: PropTypes.bool,
  oceanHealthData: PropTypes.object,
  oceanHealthLoading: PropTypes.bool,
  parcelHealthData: PropTypes.object,
  parcelHealthLoading: PropTypes.bool
};

HistoricalHealthContainer.defaultProps = {
  airHealthData: [],
  airHealthLoading: true,
  groundHealthData: [],
  groundHealthLoading: true,
  oceanHealthData: [],
  oceanHealthLoading: true,
  parcelHealthData: [],
  parcelHealthLoading: true
}

export const mapStateToProps = (state) => {
  return {
    airHealthData: state.supplyChainHealth.get('air'),
    airHealthLoading: state.supplyChainHealth.get('airLoading'),
    groundHealthData: state.supplyChainHealth.get('ground'),
    groundHealthLoading: state.supplyChainHealth.get('groundLoading'),
    oceanHealthData: state.supplyChainHealth.get('ocean'),
    oceanHealthLoading: state.supplyChainHealth.get('oceanLoading'),
    parcelHealthData: state.supplyChainHealth.get('parcel'),
    parcelHealthLoading: state.supplyChainHealth.get('parcelLoading')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchShipmentsHealth: (mode, range) => {
      return dispatch(actions.fetchShipmentsHealth(mode, range));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalHealthContainer);
