import * as types from './actionTypes';

export default {
  exportData(payload) {
    return {
      type: types.EXPORT_SHIPMENTS_STARTED,
      payload: payload
    };
  },
  exportDataCompleted(payload) {
    return {
      type: types.EXPORT_SHIPMENTS_COMPLETED,
      payload: payload
    };
  },
  fetchExportSelections() {
    return {
      type: types.FETCH_EXPORT_SELECTIONS_STARTED,
      payload: {}
    }
  },
  fetchExportSelectionsComplete(exportSelections) {
    return {
      type: types.FETCH_EXPORT_SELECTIONS_COMPLETED,
      payload: exportSelections
    }
  },
  fetchDefaultExportSelections() {
    return {
      type: types.FETCH_DEFAULT_EXPORT_SELECTIONS
    }
  },
  resetProps() {
    return {
      type: types.RESET_PROPS
    };
  }
};
