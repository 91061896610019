import React from 'react';
import { connect } from 'react-redux';

import { OverlayTrigger } from 'react-bootstrap';
import { tooltip } from '../../utils/helperMethods/commonComponents';
import baseModalActions from '../base-modal/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';


import {WhoIsLive, GroupList} from '../../components/conversation';

export class Group extends React.Component{
  modalConfig = (users, title)=>{
    return {
      showModal: true,
      showCloseButton: true,
      showActionButton: false,
      closeButtonClass: "btn btn-light",
      title:title,
      body: <GroupList users={users}/>
    };
  }

  convertMapToArray = (inputMap)=>{
    let result = [];
    const iterator = Object.entries(inputMap);
    if(iterator.length > 0  && iterator[0].length > 1 && typeof iterator[0][1] !== 'number'){
      for(let i = 0; i < iterator.length;i++){
        result.push(iterator[i][1]);
      }
    }
    return result;
  }

  render(){
    return (
      <div className="row">
        <div id='whoislivecontainer' className="col-xs-10 col-sm-11" onClick={()=>{this.props.showModal(this.modalConfig(this.convertMapToArray(this.props.activeUsers),'Users Currently Viewing Conversation'))}}>
              <WhoIsLive users={this.convertMapToArray(this.props.activeUsers)}/>
        </div>
        <div id='groupcontainerwithoverlay' className="col-xs-2 col-sm-1" onClick={()=>{this.props.showModal(this.modalConfig(this.props.group, 'Group Members'))}}>
          <OverlayTrigger placement="top" overlay={tooltip((this.props.group.length ? this.props.group.length : 0) + ' total group members', "userstotal")}>
              <FontAwesomeIcon icon={ faUsers } size='2x'/>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    group: state.conversation.get('group'),
    activeUsers: state.conversation.get('activeUsers')
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (modalConfig) => {
      return dispatch(baseModalActions.openModal(modalConfig));
    }
  };
};
export const GroupContainer = connect(mapStateToProps, mapDispatchToProps)(Group);
