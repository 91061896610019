import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  authClient: {},
  productUrl: '',
  accessToken: null,
  idToken: null,
  authed: false,
  trackLogin: false
});

function authReducer(state=initialState, action={}) {
  switch(action.type) {
    case types.LOAD_AUTH_CLIENT:
      return state
        .set('authClient', action.payload.authClient)
        .set('productUrl', action.payload.productUrl);
    case types.UPDATE_TOKENS_STARTED:
      return state;
    case types.UPDATE_TOKENS_COMPLETED:
      return state
        .set('accessToken', action.payload.accessToken)
        .set('idToken', action.payload.idToken);
    case types.UPDATE_TOKENS_FAILED:
      return state
        .set('accessToken', null)
        .set('idToken', null);
    case types.SET_AUTHED:
      return state
        .set('authed', action.payload.authed)
    case types.TRACK_LOGIN:
      return state
        .set('trackLogin', action.payload)
    default:
      return state;
  }
}

export default authReducer;
