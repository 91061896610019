import React from 'react';

export default function ErrorMessage(props){
  return (
    <div className="row">
      <div id='commenterrormsg' className="col-xs-12">
        {
          props.text !== '' && <div className="conversation error-message">{props.text}</div>
        }
      </div>
    </div>
  );
}
