import React from 'react';
import ShipmentTracker from './ShipmentTracker';
import { LayoutHOC } from '../../components/layout';
import {ShipmentTrackerPageRBAC} from './rbac';
import { JOB_MODES } from '../../utils/constants';

const ShipmentTrackerWrapped = props => <ShipmentTracker jobMode={JOB_MODES.shipments} {...props}/>
const ShipmentTrackerPage = LayoutHOC(ShipmentTrackerWrapped);
ShipmentTrackerPage._RBAC = ShipmentTrackerPageRBAC;

export { ShipmentTracker, ShipmentTrackerPage };
