import React from 'react';
import Icon from '../icon'

class ExpectedPositionPopover extends React.Component {
  render() {
    return (
      <div className="map-popover bubble">
        <a id="closeInfobox" > <Icon className="icon close" type="close" /></a>
        <div className="map-popover-details" style={{ paddingTop: '5px' }}>
          <div className="row">
            <div className="col-sm-12">
              <div className="read-only-value" data-test="expectedPositionInfo">This represents the projected Location of the shipment based on Vision Data Science calculations</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpectedPositionPopover;
