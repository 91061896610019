import React from 'react';
import { connect } from 'react-redux';
import ShipmentsTable from '../../components/shipments-table/ShipmentsTable';
import bookmarkActions from '../../containers/users/bookmarks/actions';
import baseModalActions from '../../containers/base-modal/actions';
import LoadingIcon from '../../components/loading-icon';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';

export class SavedItems extends React.Component {
  componentDidMount() {
    this.props.getBookmarkedShipments();
    trackPageInfoEvent(this.props.adobeTrack, "Saved Items Page", ["Saved Items Page"]);
  };

  removeFlaggedModal = (bookmark) => {
    return {
      title: 'Remove Saved Item?',
      showModal: true,
      showCloseButton: true,
      showActionButton: true,
      actionButtonText: "Remove Shipment",
      closeButtonText: "Cancel",
      actionButtonClass: "btn btn-primary",
      closeButtonClass: "btn btn-light",
      handleCloseAction: () => { this.props.closeModal() },
      handleAction: () => {
        this.props.deleteBookmark(bookmark._id);
        this.props.closeModal();
      },
      body: (
        <div>
          <p>Are you sure you want to remove this shipment from your saved items?</p>
        </div>
      )
    };
  };

  removeFlaggedShipment = (bookmark) => {
    this.props.showModal(this.removeFlaggedModal(bookmark));
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12" style={{ marginBottom: '10rem', paddingTop: '2rem' }}>
              <h1>Saved Items</h1>
              {!this.props.loading ? <ShipmentsTable bookmarks={this.props.bookmarks} history={this.props.history} removeFlaggedShipment={this.removeFlaggedShipment} permissions={this.props.userProfile.permissions} /> :
                <div className="centered"><LoadingIcon /></div>}
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export const mapStateToProps = (state) => {
  return {
    bookmarks: state.bookmarks.get('bookmarks'),
    loading: state.bookmarks.get('loading'),
    userProfile: state.users.get('userProfile')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getBookmarkedShipments: () => {
      return dispatch(bookmarkActions.fetchBookmarks(true));
    },
    deleteBookmark: (id) => {
      return dispatch(bookmarkActions.deleteBookmark(id));
    },
    showModal: (modalConfig) => {
      return dispatch(baseModalActions.openModal(modalConfig));
    },
    closeModal: () => {
      return dispatch(baseModalActions.closeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedItems);
