import React, { Component } from 'react';
import { connect } from 'react-redux';
import externalShipmentWarehouseActions from '../../containers/externalShipmentWarehouse/actions';
import { getProgressFromStatus, getOrders, getShipmentStatus, getWarehouseStopDate, addOrdersIdentifiers } from './externalShipmentWarehouseHelper';
import LoadingIcon from '../../components/loading-icon';
import { EXTERNAL_SHIPMENT_WAREHOUSE_PATH } from '../../utils/constants';
import closeModal from '../../containers/base-modal/actions';
import baseModalActions from '../../containers/base-modal/actions';
import './extras/styles.scss';
import { getDateTimeDayMonthYearFormat } from '../../utils/helperMethods/dateTimeMethods';
import Icon from '../../components/icon';

//cant use props.match.params because a base64 encoded string might contain '/' which cuts the react router parse short
//EXTERNAL_TRACKER_PATH must remain consistent referenced here and in the index.js > router setup for this to work
const getBase64EncodedReqFromPathname = pathname =>  pathname.slice(EXTERNAL_SHIPMENT_WAREHOUSE_PATH.length);

export class ExternalShipmentWarehouse extends React.Component{
    componentDidMount() {
        if (this.props.location && this.props.location.pathname) {
            this.props.fetchShipmentOrdersWarehouseSummary(getBase64EncodedReqFromPathname(this.props.location.pathname));
        } else {
            this.props.fetchShipmentOrdersWarehouseSummary(null);
        }
    }

    renderOrderProgressBox = (loadNumber, status, warehouseStopDate , orders) => {
        const pendingOrders = getOrders(orders);

        if (!status) status = 'Booked'; // Shouldn't happen - booked as default
        // Progress bar image and text
        let progressStatus = getProgressFromStatus(status);

        return (
            <div className="header" id="nav-estado-del-envio">
              <div className="split-group-container">
                  <div className="split-group left">
                      <div className='title_container'>
                        <h1>
                            Estado del Envío  &nbsp;
                        </h1>
                        <div className='shipment_status_bubble_container'>
                          <span className='shipment_status_bubble'>{progressStatus.text}</span>
                        </div>

                      </div>
                      <span>Envío # {loadNumber}</span>
                      
                  </div>
                  <div>
                    {this.getWarehouseStopDateElement(warehouseStopDate)}
                  </div>
              </div>
              <div className='progress_bar_container'>
                <div className='image_container'>
                  <img src={progressStatus.image} alt="Progress"></img>
                </div>
                <div class="flex-group">
                    <div>
                      <h5 className='progress_bar_label'>PROGRAMADO</h5>
                    </div>
                    <div>
                      <h5 className='progress_bar_label'>EN TRÁNSITO</h5>
                    </div>
                    <div>
                      <h5 className='progress_bar_label'>ENTREGADO</h5>
                    </div>
                </div>
              </div>
                {this.getPendingDeliveriesSection(pendingOrders)}
            </div>
        );
    }

    // Pending Deliveries Section
    getPendingDeliveriesSection = (orders) => {
      if(orders && orders.length > 0)
        return (
          <div className='header_bottom_container' data-test='pending-orders-section'>
            <div className='line'></div>
            <div className='header_bottom'>
              <span>¿Desea ver el estado de otros envíos en curso?</span>
              <button className='pending_deliveries_button' text="Ver Entregas Pendientes" 
                onClick={() => { return this.props.showModal(this.getOrdersModal(orders)) }}>Ver Entregas Pendientes</button>
            </div>
          </div>
        );
    }

    getOrdersModal = (orders) => {
      return {
        title: 'Ver Entregas Pendientes',
        showActionButton: false,
        showCloseButton: false,
        handleCloseAction: this.props.closeModal,
        size: "large",
        body: (
            <div>
              <p>Presione el número de entrega deseado para ver los detalles.</p>
              <div className='pending_orders_table_container'> 
                { <table className='pending_orders_table'>
                  <tr>
                    <th>Entrega</th>
                    <th>Pedido</th>
                    <th>Fecha de entrega estimada</th>
                  </tr>
                  {orders.map((order) =>{
                    return <tr>
                      <td><a onClick={() => window.open(`#/${order.external_order_shipment_warehouse_path}`)}>{order.deliveryNumber}</a></td>
                      <td><a onClick={() => window.open(`#/${order.external_order_shipment_warehouse_path}`)}>{order.purchaseOrderNumber}</a></td>
                      <td className='large'>{order.estimatedDeliveryDate}</td>
                    </tr>
                  })}
                </table> }
              </div>
             </div>
        )
      };
    };

    getWarehouseStopDateElement = warehouseStopDate => { 
      if(warehouseStopDate) return (
        <div className="split-group right" data-test="warehouse-stop-date-test">
          <span className='warehouse_stop_datetime_label'>{warehouseStopDate}</span>
          <span className='entrega_label'>Fecha de entrega</span>
        </div>  
      )
    }

    renderEventCodes = (events, warehouseStop) => {
      if(events && events.length > 0) {
        const eventCodes = ["REPROGRAMA", "CANCELAR", "RECHAZO TO", "RECHAZO PA"];
        events = events.filter((event) => event.warehouseCode && event.warehouseCode.toUpperCase() === warehouseStop.locationId?.toUpperCase()
          && eventCodes.indexOf(event.eventCode?.toUpperCase()) > -1);
        if(events.length > 0) {
          let sortedByDateEvents = events.sort((x, y) => new Date(y.eventDateTime) - new Date(x.eventDateTime));
          return (
                <div className="events-content" data-test="events-test">
                  <div className="header-with-line">
                    <h2>
                      Eventos
                    </h2>
                    <hr/>
                  </div>
                  <table className="table table-striped event-details-table">
                    <thead>
                      <tr>
                        <th>Evento</th>
                        <th>Descripción</th>
                        <th>Último actualización</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedByDateEvents.map(event => {
                        return (
                          <tr>
                            <td>{event.eventCode}</td>
                            <td>{event.notes}</td>
                            <td>{getDateTimeDayMonthYearFormat(event.eventDateTime)}</td>  
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
          );
        }
      }
    } 

    // List of Orders Section
    sortStopsBySequenceNumbeAsc = (stops) => {
      if(!stops) {
        return stops;
      }
      return stops.sort(function (a, b) {
        return a.SequenceNumber - b.SequenceNumber;
      });
    };

    renderOrderOrigin = (order, driver) => {
      if(order.stops && order.stops.length) {
        const origin = order.stops[0];
        return (
          <div>
            <div class="stopLabel">ORIGEN</div>
            <div class="stopFacility">{origin.secondaryLocationId} {origin.name}</div>
            <div class="stopFacility">{origin.city}, {origin.state}</div>
            <div class="stopCarrierInfo">Conductor: {driver.driver_first_name} {driver.driver_last_name}</div>
            <div class="stopCarrierInfo">Documento: {driver.code}</div>
            <div class="stopCarrierInfo">Patentes: {order.trailer_number}</div>
          </div>
        );
      }

      return null;
    }

    renderOrderDestination = (order) => {
      if(order.stops && order.stops.length) {
        const destinationStop = order.stops[order.stops.length - 1];
        return (
          <div>
            <div class="stopLabel">Destinatario: {destinationStop.secondaryLocationId}</div>
            <div class="stopFacility">{destinationStop.name}</div>
            <div class="stopCarrierInfo">{destinationStop.address}</div>
            <div class="stopCarrierInfo">{destinationStop.postal_code} {destinationStop.city}</div>
            <div class="stopCarrierInfo">{destinationStop.state} {destinationStop.country}</div>
          </div>
        );
      }
    }

    renderListOfOrders = (orders, driverObject) => {
      if(orders && orders.length > 0) {
        let driver = {...driverObject};
          driver.driver_last_name = driverObject.driver_last_name?.split("; ")[0];
          driver.code = driverObject.driver_last_name?.split("; ")[1];
          return (
            <div className="list-of-orders-content">
              <div className="header-with-line">
                <h2 id="nav-entregas">
                  Entregas en este envío
                </h2>
                <hr/>
              </div>
              <div data-test="list-of-orders-test">
                {orders.map(order => {
                  order.stops = this.sortStopsBySequenceNumbeAsc(order.stops);
                  const orderItems = order.items;
                  return (
                    <div className="order" id={`nav-order-${order.deliveryNumber}`}>
                      <div className="split-group-container">
                        <div className='order-information-title-container'>
                          <h2>Entrega: {order.deliveryNumber} | Pedido: {order.purchaseOrderNumber} </h2>
                        </div>
                        <div className="test">
                          <div className="split-group-1">
                            {this.renderOrderOrigin(order, driver)}
                          </div>
                          <div className="arrow"><Icon type="arrow-right" /></div>
                          <div className="split-group-2">
                            {this.renderOrderDestination(order)}
                          </div>
                        </div>
                        <table className="table table-striped event-details-table">
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Descripción</th>
                              <th>Cantidad</th>
                              <th>Unidad</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                            orderItems?.map(orderItem => {
                              return (
                                <tr>
                                  <td>{orderItem.sku}</td>
                                  <td>{orderItem.product_name}</td>
                                  <td>{orderItem.units}</td>
                                  <td>{orderItem.packaging_unit_of_measure}</td> 
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )})}
              </div>
            </div>
          );
      }  
    }

    // Navigation Section
    renderNavigationMenu = (orders) => {
      return (
        <div className="navigation-menu">
          <div className='navigation-top-label'>NAVEGAR A:</div>
          <div className='navigation-label'><button onClick={() => document.getElementById("nav-estado-del-envio").scrollIntoView()}>ESTADO DEL ENVÍO</button></div>
          <div className='navigation-label'><button onClick={() => document.getElementById("nav-eventos").scrollIntoView()}>EVENTOS</button></div>
          <div className='navigation-label'><button onClick={() => document.getElementById("nav-entregas").scrollIntoView()}>ENTREGAS</button></div>
          { orders && orders.length > 0 ? orders.map((order) => {
            return (<div className='navigation-order'><button onClick={() => document.getElementById(`nav-order-${order.deliveryNumber}`).scrollIntoView()}>{order.deliveryNumber}</button></div>);}) 
            : null }
        </div>
      )
    }

    render() {
        const { loadNumber, status, warehouseStopDate, orders, warehouseStop, loaded, events, driver } = this.props;
        const ordersForLoad = addOrdersIdentifiers(orders.filter(x => x.loadNumber === loadNumber));
        
        if (!loaded) {
          return (
            <div className="flex-center">
              <LoadingIcon />
            </div>
          );
        }
    
        return (
          <div className="page flex-column" data-test="externalShipmentWarehouse">
            <div className='page_container'>
              <div className="main_container">
                <div>
                  {this.renderOrderProgressBox(loadNumber, status, warehouseStopDate , orders)}
                </div>
                <div id="nav-eventos">
                  {this.renderEventCodes(events, warehouseStop)}
                </div>
                <div>
                  {this.renderListOfOrders(ordersForLoad, driver)}
                </div>
              </div>
              <div className="side-menu-container">
                <div>
                  {this.renderNavigationMenu(ordersForLoad)}
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export const mapStateToProps = state => {
    let load = state.shipmentWarehouseSummary.get('load') ?? {};
    let orders = state.shipmentWarehouseSummary.get('orders') ?? [];
    let warehouseStop = state.shipmentWarehouseSummary.get('warehouseStop') ?? {};

    let res = {
      loadNumber: load.load_number,
      status: getShipmentStatus(load.status, warehouseStop),
      warehouseStopDate: getWarehouseStopDate(load.status, warehouseStop),
      orders: orders,
      warehouseStop: warehouseStop,
      loaded: state.shipmentWarehouseSummary.get('loaded'),
      events: load.events,
      driver: load.driver && load.driver.length ? load.driver[0] : {}
    };
    return res;
  };
  
export const mapDispatchToProps = dispatch => {
    return {
      showModal: modalConfig => {
        return dispatch(baseModalActions.openModal(modalConfig));
      },
      closeModal: () => {
        return dispatch(closeModal.closeModal());
      },
      fetchShipmentOrdersWarehouseSummary: (encodedReq) => {

        let orderSummary = externalShipmentWarehouseActions.fetchShipmentOrdersWarehouseSummary(encodedReq);
        dispatch(orderSummary);
      }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalShipmentWarehouse);
