import React from 'react'
import PropTypes from 'prop-types';

import { getDate } from '../../utils/helperMethods/dateTimeMethods';

export default function NewsCard(props) {
  return (
    <div className="card card-outline news-card" data-test="newsCard">
      <div className="card-header">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h3>{props.news.additionalDetails.name}</h3>
          </div>
          <div className="col-lg-4 col-sm-12">
            <span className="label float-right">{getDate(props.news.incidentDate)}</span>
          </div>
        </div>
      </div>
      <p>
        {props.news.additionalDetails.description && props.news.additionalDetails.link ?
          <React.Fragment>
            {props.news.additionalDetails.description}
            <a data-test="descriptionLink" href={props.news.additionalDetails.link} target="_blank">{props.news.additionalDetails.link}</a>
          </React.Fragment> :
          props.news.additionalDetails.description}
      </p>
    </div>
  );
}

NewsCard.propTypes = {
  news: PropTypes.object
};

NewsCard.defaultProps = {
  news: []
};
