/*********
HOC that injects the user object, computed from the oAuth identity token,
into the 2nd class component
**********/

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import userActions from '../users/actions';
import { selectAuthClient, selectProductUrl, selectUser, selectUserProfile, selectUserProfileNeedsToBeInitialized } from './selectors';
import { trackUserLogout } from '../../utils/helperMethods/adobeAnalyticsHelper';
import authActions from './actions';

export default function AuthContainer(NeedfulComponent) {
  class NC extends React.Component {
    componentDidMount() {
      this.props.updateTokens();
    }

    login = () => {
      this.props.authClient.login();
    }

    logout = (adobeTrack) => {
      if (adobeTrack && this.props.user && this.props.user.sub) {
        trackUserLogout(adobeTrack, this.props.user.sub, this.props.user.name, this.props.userProfile.tenantId, this.props.userProfile.email);
      }
      window.localStorage.removeItem('userProfile');
      window.localStorage.removeItem(this.props.productUrl + '-access_token');
      window.localStorage.removeItem(this.props.productUrl + '-id_token');
      if (this.props.authClient) {
        const target = window.location.origin;
        this.props.authClient.logout({"post_logout_redirect_uri": target});
      }
    }

    render() {
      return (
        <NeedfulComponent user={this.props.user} logout={this.logout} login={this.login} {...this.props}/>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(NC);
}

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: (id) => {
      dispatch(userActions.fetchUserProfile(id));
    },
    updateTokens: () => {
      dispatch(authActions.updateTokens());
    }
  };
};

const mapStateToProps = () => createStructuredSelector(
  {
    user: selectUser(),
    authClient: selectAuthClient,
    productUrl: selectProductUrl,
    userProfile: selectUserProfile,
    userProfileNeedsToBeInitialized: selectUserProfileNeedsToBeInitialized
  }
);
