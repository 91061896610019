import React from 'react';
import Icon from '../icon';

class PortPopover extends React.Component {
  render() {
    return (
      <div className="map-popover bubble">
        <a id="closeInfobox" > <Icon className="icon close" type="close" /></a>
        <div className="map-popover-header">
            <div className="read-only-label">{this.props.port.name}</div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <div className="read-only-label">Average Dwell Time</div>
            <div className="read-only-value">{this.props.port.dwell_time_avg}</div>
          </div>
          <div className="col-sm-12">
            <div className="read-only-label">Current Dwell Time</div>
            <div className="read-only-value">{this.props.port.dwell_time_week}</div>
          </div>
        </div>

      </div>
    );
  }
}

export default PortPopover;
