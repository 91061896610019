import * as types from './actionTypes';

export default {
  fetchTrackingSummary(trackingNumber, trackingType, loadNumber, shipmentId) {
    return {
      type: types.FETCH_TRACKING_SUMMARY_STARTED,
      payload: {
        trackingNumber,
        trackingType,
        loadNumber,
        shipmentId
      }
    };
  },
  fetchTrackingSummaryFailed(payload) {
    return {
      type: types.FETCH_TRACKING_SUMMARY_FAILED,
      payload: payload
    };
  },
  fetchTrackingSummaryComplete(payload) {
    return {
      type: types.FETCH_TRACKING_SUMMARY_COMPLETED,
      payload: {
        loads: payload.loads
      }
    };
  },
  storeRouteData(input) {
    return {
      type: types.PROCESS_ROUTEDATA_COMPLETED,
      payload: {
        routingSegments: input.routingSegments,
        pickDropPins: input.pickDropPins,
        currentLocation: input.currentLocation,
        expectedPosition: input.expectedPosition
      }
    };
  },
  fetchShipment(loadNumber, trackingType) {
    return {
      type: types.FETCH_SHIPMENT_STARTED,
      payload: {
        loadNumber,
        trackingType
      }
    };
  },
  fetchShipmentComplete(shipments, orderNumber, loadNumbers) {
    return {
      type: types.FETCH_SHIPMENT_COMPLETED,
      payload: {
        fetchedShipment: shipments,
        orderNumber: orderNumber,
        loadNumbers: loadNumbers
      }
    };
  },
  fetchTrackingLogs(loadNumber, orderNumber, trackingType, loadNumbers, shipmentId) {
    return {
      type: types.FETCH_TRACKING_LOGS_STARTED,
      payload: {
        loadNumber,
        orderNumber,
        trackingType,
        loadNumbers,
        shipmentId
      }
    };
  },
  fetchTrackingLogsComplete(trackingLogs) {
    return {
      type: types.FETCH_TRACKING_LOGS_COMPLETED,
      payload: {
        trackingLogs: trackingLogs
      }
    };
  },
  processTrackingLogs(trackingLogs, sortingOptions) {
    return {
      type: types.FETCH_TRACKING_LOGS_PROCESS,
      payload: {
        trackingLogs,
        sortingOptions
      }
    };
  },
  resetProps() {
    return {
      type: types.RESET_PROPS
    };
  }
}
