import {all, put, call, takeEvery} from 'redux-saga/effects';
import api from '../../apis';
import * as actionTypes from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchIncidentsStart() {
  try {
    const incidents = yield call(api.incidents.fetchIncidents);
    yield put(actions.fetchIncidentsComplete(incidents.data));
  } catch (e) {
    yield all([
      put(actions.fetchIncidentsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Fetch Incidents Error',
        message: 'Could not retrieve incidents.  Please try again.',
        error: e
      }))
    ]);
  }
}

export function* watchGetIncidentsStart() {
  yield takeEvery(actionTypes.FETCH_INCIDENTS_STARTED, fetchIncidentsStart);
}

export function* deleteIncidentStart(incidentId) {
  try {
    yield call(api.incidents.deleteIncident, incidentId);
    const incidents = yield call(api.incidents.fetchIncidents);
    yield put(actions.fetchIncidentsComplete(incidents.data));
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'This incident has been deleted.'
    }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Incident was not deleted. Please try again.`
    }));
  }
}

export function* watchDeleteIncidentStart() {
  yield takeEvery(actionTypes.DELETE_INCIDENT_STARTED, deleteIncidentStart);
}

export function* createIncidentStart(action) {
  try {
    yield call(api.incidents.createIncident, action.payload);
    yield put(actions.createIncidentComplete());
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'This incident has been created.'
    }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Incident has not been saved. Please try again.`
    }));
  }
}

export function* watchCreateIncidentStart() {
  yield takeEvery(actionTypes.CREATE_INCIDENT_STARTED, createIncidentStart);
}

export function* updateIncidentStart(action) {
  try {
    yield call(api.incidents.updateIncident, action.payload);
    yield put(actions.fetchIncidents());
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'This incident has been updated.'
    }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Incident has not been saved. Please try again.`
    }));
  }
}

export function* watchUpdateIncidentStart() {
  yield takeEvery(actionTypes.UPDATE_INCIDENT_STARTED, updateIncidentStart);
}

