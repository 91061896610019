import React from 'react';
import Footer from '../../components/footer';
import { ScorecardContainer } from '../../containers/scorecard/ScorecardContainer';

export default function Scorecard(props) {
  return (
    <div>
      <div className="container-fluid" data-test="scorecardPage">
        <div className="row space-top">
          <div style={{marginBottom: '5rem'}} className="col-xs-12">
            <h1 className="mainScoreCardHeader">Carrier Scorecard</h1>
            <ScorecardContainer {...props}/>
          </div>
        </div>
      </div>
    </div>
  );
}
