import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  averagesLoading: false,
  limit: '20',
  iotAverages: [],
  iotMapLogs: [],
  iotDetailRecords: {
    total: 0,
    results: []
  },
  iotSensorLocations: {
    locations: []
  },
  activeIotShipments: {
    shipments: [],
    total: 0
  },
  dashboardPageAfterKeys: [
    '0'
  ],
  logsLoading: false,
  iotDetailsLoading: false,
  sensorLocationsLoading: false,
  activeIotShipmentsLoading: false,
  useIotMap: false
});

export default function fetchIotDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_IOT_DETAIL_RECORDS_STARTED:
      return state
        .set('iotDetailsLoading', true);
    case types.FETCH_IOT_DETAIL_RECORDS_COMPLETED:
      return state
        .set('iotDetailsLoading', false)
        .set('iotDetailRecords', action.payload);
    case types.FETCH_IOT_DETAIL_RECORDS_FAILED:
      return state
        .set('iotDetailsLoading', false);
    case types.FETCH_IOT_MAP_LOGS_COMPLETED:
      return state
        .set('logsLoading', false)
        .set('iotMapLogs', action.payload);
    case types.FETCH_IOT_MAP_LOGS_FAILED:
      return state
        .set('logsLoading', false);
    case types.FETCH_IOT_MAP_LOGS_STARTED:
      return state
        .set('logsLoading', true);
    case types.FETCH_IOT_AVERAGES_COMPLETED:
      return state
        .set('averagesLoading', false)
        .set('iotAverages', action.payload);
    case types.FETCH_IOT_AVERAGES_FAILED:
      return state
        .set('averagesLoading', false);
    case types.FETCH_IOT_AVERAGES_STARTED:
      return state
        .set('averagesLoading', true);
    case types.UPDATE_USE_IOT_MAP:
      return state
        .set('useIotMap', action.payload);
    case types.FETCH_IOT_SENSOR_LOCATIONS_FAILED:
      return state
        .set('sensorLocationsLoading', false);
    case types.FETCH_IOT_SENSOR_LOCATIONS_STARTED:
      return state
        .set('sensorLocationsLoading', true);
    case types.FETCH_IOT_SENSOR_LOCATIONS_COMPLETED:
      return state
        .set('sensorLocationsLoading', false)
        .set('iotSensorLocations',  action.payload);
    case types.FETCH_ACTIVE_IOT_SHIPMENTS_STARTED:
        return state
          .set('activeIotShipmentsLoading', true);
    case types.FETCH_ACTIVE_IOT_SHIPMENTS_COMPLETED: {
      const nextPageNumber = action.payload.pageNumber;
      let updatedDashboardPageKeys = state.get('dashboardPageAfterKeys')
      updatedDashboardPageKeys = updatedDashboardPageKeys.set(nextPageNumber, action.payload.afterKey);
      return state
        .set('activeIotShipmentsLoading', false)
        .set('activeIotShipments', action.payload.activeShipments)
        .set('dashboardPageAfterKeys', updatedDashboardPageKeys);
    }
    case types.FETCH_ACTIVE_IOT_SHIPMENTS_FAILED:
        return state
          .set('activeIotShipmentsLoading', false);
    default:
      return state;
  }
};
