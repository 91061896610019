import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { popover } from '../../utils/helperMethods/commonComponents';

export class GenericPopOver extends React.Component {
    render() {
        return (
            <OverlayTrigger placement="top" overlay={popover(`${this.props.title}`, `${this.props.details}.`, 'genericpopover')}>
                <svg className="icon small faded">
                    <use href="#help" style={{ pointerEvents: 'none' }} />
                </svg>
            </OverlayTrigger>
        );
    }
}

export default GenericPopOver;