import _ from 'lodash';
import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';
import { factoryWithoutToken } from './clientFactory';
import { isExternalTrackingType } from '../utils/helperMethods/commonMethods';
import queryString from 'query-string';
import { getUserProfile } from '../containers/auth/authHelper';
import { NUOLV_ENABLED_TENANTS, IGNORE_RENTENTION } from '../utils/constants';

// This is a util function - let's find a different place for this
const formatLocations = (results) => {
  _.each(results, (logEntry) => {
    let locationFormatted = logEntry.location && logEntry.location.City && logEntry.location.City.trim() ? `${logEntry.location.City.trim()}, ` : '';
    locationFormatted += logEntry.location && logEntry.location.State && logEntry.location.State.trim() ? `${logEntry.location.State.trim()} ` : '';
    locationFormatted += logEntry.location && logEntry.location.Country && logEntry.location.Country.trim() ? `${logEntry.location.Country.trim()}` : '';
    logEntry.locationFormatted = locationFormatted?.trim() || null;
  });
  return results;
};

/**
 * Helper function to append parameters to shipments-api requests to route
 * requests to the correct elastic indices.
 * detailLevel routing:
 *  - 'orders' -> luminosity_vision_order
 *  - 'shipments' -> luminosity_vision_shipment
 *  - else -> luminosity_shipment_{tenantId}
 * 
 * external users are by convention forbidden to send a detailLevel other
 * than 'orders'.
 * 
 * @param {string} url shipments-api endpoint
 * @param {string} detailLevel ['orders' | other] detailLevel 'shipments' will only be specified in requests for nuolv tenants
 * @param {object} payload optional parameter, which if present assumes the detailLevel is specified in payload
 * @returns {string} potentially modified url with query parameters
 */
export async function addDetailLevelToReq(url, detailLevel, payload) {
  const profile = await getUserProfile();
  const nuolvEnabled = NUOLV_ENABLED_TENANTS.includes(profile.tenantId);

  if (payload != null) {
    //assume POST/PUT
    if (detailLevel === 'orders') {
      payload.detailLevel = detailLevel;
      return url;
    } else if (nuolvEnabled) {
      payload.detailLevel = 'shipments';
      return url;
    }
  } else {
    //assume GET/DEL and append query param
    if (detailLevel === 'orders') {
      if (url.includes('?')) {
        return url += '&detailLevel=orders';
      } else {
        return url += '?detailLevel=orders';
      }
    } else if (nuolvEnabled) {
      if (url.includes('?')) {
        return url += '&detailLevel=shipments';
      } else {
        return url += '?detailLevel=shipments';
      }
    }
  }

  return url;
}

export default {
  async searchShipments(payload, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;

    try {
      const request = await clientFactory(endpoint);
      const path = await addDetailLevelToReq('shipments/search', detailLevel, payload)
      const result = await request.post(path, payload);
      return {
        result,
        payload
      };
    } catch (err) {
      throw `Error retrieving shipments: ${err}`;
    }
  },
  async searchMultiShipments(payload, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;

    try {
      const request = await clientFactory(endpoint);
      const path = await addDetailLevelToReq('shipments/search/multi', detailLevel, payload);
      const result = await request.post(path, payload);
      return result;
    } catch (err) {
      throw `Error retrieving shipments: ${err}`;
    }
  },
  async fetchShipment(loadNumber, trackingType, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;

    try {
      const request = await clientFactory(endpoint);
      if (isExternalTrackingType(trackingType)) {
        const path = await addDetailLevelToReq(`shipments/${loadNumber}/nonnav?trackingType=${trackingType.toLowerCase()}`, detailLevel);
        const result = await request.get(path);
        return result;
      }
      const path = await addDetailLevelToReq(`shipments/${loadNumber}`, detailLevel);
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error retrieving Shipment: ${err}`;
    }
  },
  async getTrackingLogs(loadNumber, orderNumber, trackingType, loadNumbers, detailLevel, shipmentId) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    const profile = await getUserProfile();
    const nuolvEnabled = NUOLV_ENABLED_TENANTS.includes(profile.tenantId);

    const params = {};

    if (detailLevel === 'orders') {
      params.orderIds = orderNumber;
    }

    if (shipmentId && detailLevel !== 'orders') {
      params.shipmentKeys = shipmentId;
    }

    try {
      let result;

      if (detailLevel === 'orders') {
        params.detailLevel = 'orders';
      } else if (nuolvEnabled) {
        params.detailLevel = 'shipments';
      }
      const request = await clientFactory(endpoint);
      const response = await request.get(`tracking`, {
        params
      });
      result = response.data;

      if (trackingType === 'Order' && loadNumbers && loadNumbers.length > 1) {
        result = result.filter((x) => loadNumbers.includes(x.loadNumber));
      }

      const sortByEventDate = result.sort((objA, objB) => new Date(objB.eventDateTime) - new Date(objA.eventDateTime));
      return formatLocations(sortByEventDate);
    } catch (err) {
      throw `Error retrieving tracking logs: ${err}`;
    }
  },
  async fetchPorts() {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get('ports');
      return result;
    } catch (err) {
      throw `Error retrieving ports: ${err}`;
    }
  },
  async getTrackingSummary(trackingNumber, trackingType, orderNumber, detailLevel, showMultiShipmentOrder, shipmentId) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    const profile = await getUserProfile();
    const nuolvEnabled = NUOLV_ENABLED_TENANTS.includes(profile.tenantId);
    const ignoreRentention = IGNORE_RENTENTION.includes(profile.tenantId);
    
    try {
      const request = await clientFactory(endpoint);
      let orderNumberAdd = '';
      if (orderNumber) {
        orderNumberAdd = `/${orderNumber}`;
      }
      if (detailLevel !== 'orders' && !nuolvEnabled) {
        //NOTE - This is for TREX orders which are a collection of loads - not
        //to be confused with the subdivison of loads/shipments we call orders...
        if (trackingType === 'Order') {
          const path = await addDetailLevelToReq(`orders/${trackingNumber}${orderNumberAdd}?shipmentId=${shipmentId}`, detailLevel);
          const result = await request.get(path);
          return result.data;
        }

        if (trackingType === 'Load' || trackingType === 'Package') {
          const path = await addDetailLevelToReq(`shipments/${trackingNumber}${orderNumberAdd}?shipmentId=${shipmentId}`, detailLevel)
          const results = await request.get(path);
          return { loads: results.data ? [results.data] : [] };
        }

        if (isExternalTrackingType(trackingType)) {
          const path = await addDetailLevelToReq(`shipments/${trackingNumber}${orderNumberAdd}/nonnav?trackingType=${trackingType.toLowerCase()}&shipmentId=${shipmentId}`, detailLevel)  
          const result = await request.get(path);
          return result.data;
        }

        throw 'Invalid trackingType';
      } else if (orderNumber && showMultiShipmentOrder) {
        const result = await request.get(`shipments/orderNumber/${orderNumber}?skipRetention=${ignoreRentention}`);
        return result.data;
      } else {
        //nuolvnavparcel is a custom trackingType created for nuolv tenants with small parcel, to search elastic using the proper identifier
        trackingType = trackingType === 'nuolvnavparcel' ? trackingType : isExternalTrackingType(trackingType) ? trackingType.toLowerCase() : 'nuolvnav';
        //all nuolv requests call the "nonnav" endpoint to grab results from elastic for both true nonnav and nav shipments
        const path = await addDetailLevelToReq(`shipments/${trackingNumber}${orderNumberAdd}/nonnav?trackingType=${trackingType}&skipRetention=${ignoreRentention}&shipmentId=${shipmentId}`, detailLevel)

        const result = await request.get(path);
        return result.data;
      }
    } catch (err) {
      throw `Error retrieving tracking summary: ${err}`;
    }
  },
  async fetchShipmentsHealth(payload, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const path = await addDetailLevelToReq(`shipments/health?mode=${payload.mode}&range=${payload.range}`, detailLevel)
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error getting shipments health: ${err}`;
    }
  },
  async fetchTrendingShipments(detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const path = await addDetailLevelToReq('shipments/trending', detailLevel);
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error getting trending shipments: ${err}`;
    }
  },
  async getFacilityShipments(selectedFacility, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      let url = `facilities/${selectedFacility.facilityCode}/shipments`;

      if (selectedFacility.identifiers && selectedFacility.identifiers.length > 0) {
        url = url.concat(`?identifiers=${selectedFacility.identifiers}`);
      }

      const path = await addDetailLevelToReq(url, detailLevel);

      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error getting shipments for facility: ${err}`;
    }
  },
  async fetchCarrierScorecard(payload, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const queryParams = queryString.stringify(payload, { skipNull: true })
      const request = await clientFactory(endpoint);
      const path = await addDetailLevelToReq(`carriers/scorecard?${queryParams}`, detailLevel)
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error getting scorecard: ${err}`;
    }
  },
  async fetchCarriersAutocomplete(searchText, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const path = await addDetailLevelToReq(`carriers/scorecard/autocomplete?searchText=${searchText}`, detailLevel);
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error getting scorecard: ${err}`;
    }
  },
  async fetchExceptions(detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const path = await addDetailLevelToReq('shipments/exceptions', detailLevel);
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error retrieving exceptions: ${err}`;
    }
  },
  async fetchExternalTrackingData(encodedReq, detailLevel) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = factoryWithoutToken(endpoint);
      const path = await addDetailLevelToReq(`orders/summary/${encodedReq}`, detailLevel);
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error retrieving tracking data: ${err}`;
    }
  },
  async fetchExternalShipmentOrdersWarehouseSummary(encodedReq) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = factoryWithoutToken(endpoint);
      const path = `shipment/${encodedReq}/orders/summary`;
      const result = await request.get(path);
      return result;
    } catch (err) {
      throw `Error retrieving shipment order warehouse data: ${err}`;
    }
  },
  async fetchTrackingShipment(trackingNumber) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = factoryWithoutToken(endpoint);
      const result = await request.get(`/shipments/visionTrackingNumber/${trackingNumber}`);
      return result.data;
    } catch (err) {
      throw `Error retrieving tracking shipment: ${err}`;
    }
  },
  async fetchTrackingMilestones(trackingNumber) {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = factoryWithoutToken(endpoint);
      const result = await request.get(`/shipments/visiontrackingnumber/${trackingNumber}/trackinglogs`);
      const sortByEventDate = result.data.sort((objA, objB) => new Date(objB.eventDateTime) - new Date(objA.eventDateTime));
      return formatLocations(sortByEventDate);
    } catch (err) {
      throw `Error retrieving tracking milestones: ${err}`;
    }
  },
  async fetchRegions() {
    const config = await getConfiguration();
    const endpoint = config.shipmentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`/regions`);
      return result.data;
    } catch (err) {
      throw `Error retrieving regions for tenant: ${err}`;
    }
  }
};
