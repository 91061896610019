const parseResponse = (results) => {
    if (!results || !results.length > 0) {
        return;
    }

    let parsedResponse = [];

    results.forEach((result) => {
        result.data.embeds.forEach((embed) => {
            parsedResponse.push({
                id: embed.id,
                embedUrl: embed.embedUrl,
                accessToken: result.data.embedToken.token,
                type: result.data.type.toLowerCase(),
                visualConfiguration: result.data.visualConfiguration,
                tokenType: 1
            })
        });
    });

    return parsedResponse;
}

export {
    parseResponse
}