export const FETCH_IOT_DETAIL_RECORDS_STARTED = 'iot/FETCH_IOT_DETAIL_RECORDS_STARTED';
export const FETCH_IOT_DETAIL_RECORDS_COMPLETED = 'iot/FETCH_IOT_DETAIL_RECORDS_COMPLETED';
export const FETCH_IOT_DETAIL_RECORDS_FAILED = 'iot/FETCH_IOT_DETAIL_RECORDS_FAILED';

export const FETCH_IOT_MAP_LOGS_STARTED = 'iot/FETCH_IOT_MAP_LOGS_STARTED';
export const FETCH_IOT_MAP_LOGS_COMPLETED = 'iot/FETCH_IOT_MAP_LOGS_COMPLETED';
export const FETCH_IOT_MAP_LOGS_FAILED = 'iot/FETCH_IOT_MAP_LOGS_FAILED';

export const FETCH_IOT_AVERAGES_STARTED = 'iot/FETCH_IOT_AVERAGES_STARTED';
export const FETCH_IOT_AVERAGES_COMPLETED = 'iot/FETCH_IOT_AVERAGES_COMPLETED';
export const FETCH_IOT_AVERAGES_FAILED = 'iot/FETCH_IOT_AVERAGES_FAILED';

export const FETCH_IOT_SENSOR_LOCATIONS_STARTED = 'iot/FETCH_IOT_SENSOR_LOCATIONS_STARTED';
export const FETCH_IOT_SENSOR_LOCATIONS_COMPLETED = 'iot/FETCH_IOT_SENSOR_LOCATIONS_COMPLETED';
export const FETCH_IOT_SENSOR_LOCATIONS_FAILED = 'iot/FETCH_IOT_SENSOR_LOCATIONS_FAILED';

export const FETCH_ACTIVE_IOT_SHIPMENTS_STARTED = 'iot/FETCH_ACTIVE_IOT_SHIPMENTS_STARTED';
export const FETCH_ACTIVE_IOT_SHIPMENTS_COMPLETED = 'iot/FETCH_ACTIVE_IOT_SHIPMENTS_COMPLETED';
export const FETCH_ACTIVE_IOT_SHIPMENTS_FAILED = 'iot/FETCH_ACTIVE_IOT_SHIPMENTS_FAILED';

export const UPDATE_USE_IOT_MAP = 'iot/UPDATE_USE_IOT_MAP';
