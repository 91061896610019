import React, { Component } from 'react';
import { connect } from 'react-redux';
import shipmentsOrderSummaryActions from '../../containers/shipmentsOrderSummary/actions';
import LoadingIcon from '../../components/loading-icon';
import DeliveredPng from '../../styles/assets/Delivered.png';
import InTransitPng from '../../styles/assets/InTransit.png';
import { getDateTime } from '../../utils/helperMethods/dateTimeMethods';
import { getMonthAndDate } from '../../utils/helperMethods/dateTimeMethods';
import { EXTERNAL_TRACKER_PATH } from '../../utils/constants';
import './extras/styles.scss';

function getParsedDateTime(date) {
  if (date && date != '') {
    return getDateTime(date);
  } else {
    return '';
  }
}

function getParsedMonthAndDate(date) {
  if (date && date != '') {
    return getMonthAndDate(date);
  } else {
    return '';
  }
}

//cant use props.match.params because a base64 encoded string might contain '/' which cuts the react router parse short
//EXTERNAL_TRACKER_PATH must remain consistent referenced here and in the index.js > router setup for this to work
function getBase64EncodedReqFromPathname(pathname) {
  return pathname.slice(EXTERNAL_TRACKER_PATH.length);
}

export class ExternalTracker extends React.Component {
  componentDidMount() {
    if (this.props.location && this.props.location.pathname) {
      this.props.fetchOrderSummary(getBase64EncodedReqFromPathname(this.props.location.pathname));
    } else {
      this.props.fetchOrderSummary(null);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.fetchOrderSummary(getBase64EncodedReqFromPathname(this.props.location.pathname));
    }
  }

  renderOrderDetails = (orderItems) => {
    const lineItems = [];

    orderItems.forEach((row) => {
      lineItems.push(
        <tr key={row.item_key}>
          <td>{row.purchase_order_number}</td>
          <td>{row.product_name}</td>
          <td>{row.part_number}</td>
          <td>{row.units}</td>
        </tr>
      );
    });

    return lineItems;
  }

  findOriginAndDestinationData = (orderStops) => {
    let origin;
    let originStops = orderStops.filter(stop => stop.stopType === 'P');
    if (originStops.length > 0) {
      origin = originStops[0];
    }

    let destination;
    let destinationStops = orderStops.filter(stop => stop.stopType === 'D');
    if (destinationStops.length > 0) {
      destination = destinationStops[0];
    }
    return { origin, destination };
  }

  formatStopLocation = (stop => {
    if (stop == null) {
      return 'Stop Data Not Found'
    }
    let locationFormatted = stop.city ? `${stop.city}, ` : '';
    locationFormatted += stop.state ? `${stop.state} ` : '';
    locationFormatted += stop.country ? stop.country : '';
    locationFormatted = locationFormatted || null;

    return locationFormatted;
  });

  renderTrackingDetails = (orderStops, status) => {
    let processedStopData = this.processStopStatus(orderStops);

    const lineItems = [];
    lineItems.push(
      <tr >
        {processedStopData.isDelievered
          ? <td><strong>Delivered!</strong></td>

          : <td style={{ color: "Silver" }}>Delivered!</td>
        }
        {processedStopData.isDelievered
          ? <td><strong>{processedStopData.destinationCompleteDateTime}</strong></td>

          : <td></td>
        }
        {processedStopData.isDelievered
          ? <td><strong>{processedStopData.destinationLocation}</strong></td>

          : <td></td>
        }
      </tr>);
    lineItems.push(<tr >
      {
        (() => {
          if (processedStopData.isArrivedDestination && !processedStopData.isDelievered) {
            return (<td><strong>Carrier Arrived Delivery Location</strong></td>);
          }
          else if (!processedStopData.isArrivedDestination && processedStopData.isDelievered) {
            return (<td>Carrier Arrived Delivery Location</td>);
          }
          else {
            return (<td style={{ color: "Silver" }}>Carrier Arrived Delivery Location</td>);
          }
        })()
      }
      {
        (() => {
          if (processedStopData.isArrivedDestination && !processedStopData.isDelievered) {
            return (<td><strong>{processedStopData.destinationArrivedDateTime}</strong></td>);
          }
          else if (!processedStopData.isArrivedDestination && processedStopData.isDelievered) {
            return (<td>{processedStopData.destinationArrivedDateTime}</td>);
          }
          else {
            return (<td></td>);
          }
        })()
      }
      {
        (() => {
          if (processedStopData.isArrivedDestination && !processedStopData.isDelievered) {
            return (<td><strong>{processedStopData.destinationLocation}</strong></td>);
          }
          else if (!processedStopData.isArrivedDestination && processedStopData.isDelievered) {
            return (<td>{processedStopData.destinationLocation}</td>);
          }
          else {
            return (<td></td>);
          }
        })()
      }
    </tr>);
    if (processedStopData.isInTransit) {
      lineItems.push(<tr >
        <td><strong>In Transit</strong></td>
        <td><strong>{status ? getParsedDateTime(status.statusDate) : ''}</strong></td>
        <td><strong>{status ? status.statusLocation : ''}</strong></td>
      </tr>);
    }
    lineItems.push(<tr >
      <td>Carrier Departed Pickup Location</td>
      <td>{processedStopData.originCompleteDateTime}</td>
      <td>{processedStopData.originLocation}</td>
    </tr>);
    lineItems.push(<tr >
      <td>Carrier at Pickup Location</td>
      <td>{processedStopData.originArrivedDateTime}</td>
      <td>{processedStopData.originLocation}</td>
    </tr>);
    return lineItems;
  }

  processStopStatus = (orderStops) => {
    let stopData = this.findOriginAndDestinationData(orderStops);
    let originStop = stopData.origin;

    let originLocation = this.formatStopLocation(originStop);
    let originCompleteDateTime = '', originArrivedDateTime = '';
    if (originStop) {
      originCompleteDateTime = getParsedDateTime(originStop.stopCompleteDateTime);
      originArrivedDateTime = getParsedDateTime(originStop.arrivedDateTime);
    }
    let destinationStop = stopData.destination;
    let destinationLocation = this.formatStopLocation(destinationStop);
    let destinationCompleteDateTime = '', destinationArrivedDateTime = '';
    if (destinationStop) {
      destinationCompleteDateTime = getParsedDateTime(destinationStop.stopCompleteDateTime);
      destinationArrivedDateTime = getParsedDateTime(destinationStop.arrivedDateTime);
    }
    let isDelievered = false;
    let isArrivedDestination = false;
    let isInTransit = false;
    if (!destinationStop || (destinationCompleteDateTime === '' && destinationArrivedDateTime === '')) {
      isInTransit = true;
    }
    else if (destinationCompleteDateTime === '' && destinationArrivedDateTime !== '') {
      isArrivedDestination = true;
    }
    else if (destinationCompleteDateTime !== '') {
      isDelievered = true;
    }

    return {
      isDelievered,
      isArrivedDestination,
      isInTransit,
      destinationCompleteDateTime,
      destinationArrivedDateTime,
      destinationLocation,
      originLocation,
      originCompleteDateTime,
      originArrivedDateTime
    }
  }


  renderOrderProgressBox = (orderStops, eta, status) => {
    var processedStopData = this.processStopStatus(orderStops);

    return (
      <div className="order-progress-box-group">
        <img src={processedStopData.isInTransit || processedStopData.isArrivedDestination ? InTransitPng : DeliveredPng} alt="Progress"></img>
        <div className="flex-group">
          <div className="split-group">
            <h5>Shipped</h5>
            <h6>{getParsedMonthAndDate(processedStopData.originCompleteDateTime)}</h6>
          </div>
          <div className="split-group">
            {!processedStopData.isDelievered
              ? <h5><strong>In Transit</strong></h5>
              : <h5>In Transit</h5>
            }
            <h6>{status && processedStopData.isInTransit ? getParsedMonthAndDate(status.statusDate) : ''}</h6>
          </div>
          <div className="split-group">
            {!processedStopData.isDelievered ? <h5 style={{ color: "Silver" }}>Estimated</h5> : <h5><strong>Delivered</strong></h5>}
            {!processedStopData.isDelievered
              ? <h6 style={{ color: "Silver" }}>{eta.calculated_ETA}</h6>
              : <h6>{getParsedMonthAndDate(processedStopData.destinationCompleteDateTime)}</h6>
            }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { orderNumber, status, items, eta, billOfLading, stops, loaded } = this.props;

    if (!loaded) {
      return (
        <div className="flex-center">
          <LoadingIcon />
        </div>
      );
    }

    let stopData = this.findOriginAndDestinationData(stops);
    let originStop = stopData.origin;
    let destinationStop = stopData.destination;
    let originLocation = this.formatStopLocation(originStop);
    let destinationLocation = this.formatStopLocation(destinationStop);

    return (
      <div className="page flex-column" data-test="externalOrderPage">
        <div className="col-xs-12 external-tracker" style={{ marginBottom: '10rem', paddingTop: '2rem' }}>
          <h1><strong>
            Order Number: {orderNumber} &nbsp;
              </strong>
          </h1>

          <div>
            {this.renderOrderProgressBox(stops, eta, status)}
          </div>

          <div className="external-tracker-group">
            <div className="flex-group">
              <div className="split-group">
                <h1>Ship From</h1>
                <h4>{originLocation}</h4>
              </div>
              <div className="split-group">
                <h1>Ship To</h1>
                <h4>{destinationLocation}</h4>
              </div>
            </div>
          </div>

          <div className="external-tracker-group">
            <h1>
              Tracking Details &nbsp;
            </h1>
            {(!items) ? <p>No Stop Information Available</p> : (
              <table className="table table-striped stop-details-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderTrackingDetails(stops, status)}
                </tbody>
              </table>
            )}
          </div>

          <div className="external-tracker-group">
            <h1>
              Order Details &nbsp;
            </h1>
            {(!items) ? <p>No order items avaliable.</p> : (
              <table className="table table-striped order-details-table">
                <thead>
                  <tr>
                    <th>PO Number</th>
                    <th>Item Name</th>
                    <th>Part Number</th>
                    <th>Shipped</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderOrderDetails(items)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    orderNumber: state.shipmentsOrderSummary.get('orderNumber'),
    status: state.shipmentsOrderSummary.get('status'),
    eta: state.shipmentsOrderSummary.get('eta'),
    stops: state.shipmentsOrderSummary.get('stops'),
    items: state.shipmentsOrderSummary.get('items'),
    billOfLading: state.shipmentsOrderSummary.get('billOfLading'),
    loaded: state.shipmentsOrderSummary.get('loaded')
  };
  //we have access to in the props of the component, this.props.regions, this.props.flows, etcetc
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchOrderSummary: (encodedReq) => {
      dispatch(shipmentsOrderSummaryActions.fetchOrderSummary(encodedReq));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalTracker);
