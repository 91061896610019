/**
 * @typedef {{includes: () => boolean}} RoleSet
 * @typedef {{permissions: boolean, role: boolean}} RbacResult
 */

export const PERMISSIONS = {
  ViewIncidents: "ViewIncidents",
  Collaboration: "Collaboration",
  Exporting: "Exporting",
  IotDevices: "IotDevices",
  DataCompliance: "DataCompliance",
  ViewShipmentTracker: "ViewShipmentTracker",
  CreateIncidents: "CreateIncidents",
  Inventory: "Inventory",
  CarrierPerformance: "CarrierPerformance",
  ViewOrders: "ViewOrders",
  ViewOrderTracker: "ViewOrderTracker"
};

export const USER_ROLES = {
  ExternalUser: 'ExternalUser',
  User: 'User',
  Admin: 'Admin',
  SuperAdmin: 'SuperAdmin'
};

//default role access sets.  
//note that the only method that should be used on a role set is includes().
export const ROLE_SETS = {
  //this will also work when a role is not yet defined, useful for auth pages
  noRoleRestrictions: ({ includes: () => true }),
  allRoles: [USER_ROLES.ExternalUser, USER_ROLES.User, USER_ROLES.Admin, USER_ROLES.SuperAdmin],
  usersAndAbove: [USER_ROLES.User, USER_ROLES.Admin, USER_ROLES.SuperAdmin],
  adminsAndAbove: [USER_ROLES.Admin, USER_ROLES.SuperAdmin]
};

/**
 * Helper function to determine whether RBAC requirements are fulfilled for a user and resource.
 * @param {RoleSet} acceptableRoles Array-like object that userProfileRole will call includes on
 * @param {Array} requiredPermissions Array of required permissions for render. 
 *  ^^ NOTE: only ONE permission in the list is neccessary for a user to pass because the carrier
 *     scorecard uses two alternate permissions.
 * @param {Array} userPermissions Array of permissions for a user
 * @param {String} userRole Role to check acceptableRoles against
 * 
 * @returns {RbacResult} Indicates whether rbac requirements have been fulfilled. 
 */
export function passesRbac(acceptableRoles, requiredPermissions, userPermissions, userRole) {
  if (!acceptableRoles && !requiredPermissions) {
    return {
      permissions: true,
      role: true
    }
  }

  const passes = {
    permissions: true,
    role: false
  };

  if (acceptableRoles.includes(userRole)) {
    passes.role = true;
  }

  if (requiredPermissions && requiredPermissions.length) {
    /* NOTE: only ONE permission in the list is neccessary for a user to pass because the carrier
    /* scorecard uses two alternate permissions. We can revisit this in the future if it becomes
    /* necessary to drive RBAC off of multiple permissions (i'd argue we shouldn't) */
    let permissionsPassed = false;

    requiredPermissions.forEach(permission => {
      if (userPermissions.includes(permission)) {
        permissionsPassed = true;
      }
    });

    passes.permissions = permissionsPassed;
  }

  return passes;
}
