import * as types from './actionTypes';

export default {
  triggerGlobalSuccessAlert:(payload)=>{
    return {
      type: types.TRIGGER_GLOBAL_SUCCESS_ALERT,
      payload
    }
  },
  triggerGlobalErrorAlert:(payload)=>{
    return {
      type: types.TRIGGER_GLOBAL_ERROR_ALERT,
      payload
    }
  }
}
