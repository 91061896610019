import { all, put, call, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import * as actionTypes from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchInventoryStart({ payload }) {
  try {
    const inventory = yield call(api.inventory.fetchInventory, payload);
    yield put(actions.fetchInventoryComplete(inventory.data));
  }
  catch (e) {
    yield all([
      put(actions.fetchInventoryFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Inventory Fetch Error',
        message: `Inventory data could not be retrieved.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchInventoryStart() {
  yield takeEvery(actionTypes.FETCH_INVENTORY_STARTED, fetchInventoryStart);
}

export function* fetchInventoryDetailsStart({ payload }) {
  try {
    const inventoryDetails = yield call(api.inventory.fetchInventoryDetails, payload);

    const response = {
      unrestricted: inventoryDetails.data.aggregations.Unrestricted || undefined,
      facilities: inventoryDetails.data.hits.hits || []
    };
    yield put(actions.fetchInventoryDetailsComplete(response));
  }
  catch (e) {
    yield all([
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Inventory Details Error',
        message: `Inventory data could not be retrieved. Please try again.`,
        error: e
      })),
      put(actions.fetchInventoryDetailsFailed())
    ]);
  }
}

export function* watchFetchInventoryDetailsStart() {
  yield takeEvery(actionTypes.FETCH_INVENTORY_DETAILS_STARTED, fetchInventoryDetailsStart);
}
