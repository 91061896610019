import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import actions from './actions';

export class BaseModal extends React.Component {
  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.closeModal} bsSize={this.props.size} backdrop={this.props.backdrop === false ? false : true}>
        <Modal.Header closeButton>
          <Modal.Title id='baseModalTitle'>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='baseModalBody'>
          {this.props.body}
        </Modal.Body>
        <Modal.Footer>
          {this.props.showCloseButton && <Button id='baseModalCloseButton' bsClass={this.props.closeButtonClass ? this.props.closeButtonClass : "btn-link"} onClick={this.props.handleCloseAction ? this.props.handleCloseAction : this.props.closeModal}>{this.props.closeButtonText || "Close"}</Button>}
          {this.props.showActionButton && <Button id='baseModalActionButton' bsClass={this.props.actionButtonClass ? this.props.actionButtonClass : "btn-link"} onClick={this.props.handleAction}>{this.props.actionButtonText}</Button>}
        </Modal.Footer>
      </Modal>
    );
  }
}

export const mapStateToProps = state => {
  return {
    showModal: state.baseModal.get('showModal'),
    showCloseButton: state.baseModal.get('showCloseButton'),
    showActionButton: state.baseModal.get('showActionButton'),
    actionButtonText: state.baseModal.get('actionButtonText'),
    closeButtonText: state.baseModal.get('closeButtonText'),
    actionButtonClass: state.baseModal.get('actionButtonClass'),
    closeButtonClass: state.baseModal.get('closeButtonClass'),
    handleAction: state.baseModal.get('handleAction'),
    handleCloseAction: state.baseModal.get('handleCloseAction'),
    title: state.baseModal.get('title'),
    body: state.baseModal.get('body'),
    size: state.baseModal.get('size'),
    backdrop: state.baseModal.get('backdrop')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(actions.closeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseModal);
