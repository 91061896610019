import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  facilities: []
});

function facilitiesReducer(state = initialState, action={}) {
  switch (action.type) {
    case types.FETCH_FACILITIES_COMPLETED:
      return state
        .set('facilities', action.payload.facilities);
    default:
      return state;
  }
}

export default facilitiesReducer;
