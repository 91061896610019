import { put, call, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import * as actionTypes from './actionTypes';
import actions from './actions';
import debounce from '../../utils/helperMethods/reduxHelpers';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* exportDataStart({ payload }) {
  try {
    const response = yield call(api.exporting.exportData, payload);
    yield put(actions.exportDataCompleted(response));
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Download In Progress',
      message: 'In the next few minutes, you will receive an email with a link to this data in a CSV. Large amounts of data may take a few minutes.'
    }));

  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Something Went Wrong!',
      message: `Something happened and we weren't able to generate a CSV for this information. Please try again.`
    }));
  }
}

export function* watchExportDataStart() {
  yield debounce(1000, actionTypes.EXPORT_SHIPMENTS_STARTED, exportDataStart);
}

export function* fetchExportSelectionsStart() {
  try {
    const exportSelections = yield call(api.metadata.fetchExportSelections);

    if (exportSelections?.data.length > 0) {
      yield put(actions.fetchExportSelectionsComplete(exportSelections));
    } else {
      yield put(actions.fetchDefaultExportSelections());
    }    
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Export selections could not be retrieved. Please try again.`
    }));
  }
}

export function* watchGetExportSelectionsStart() {
  yield takeEvery(actionTypes.FETCH_EXPORT_SELECTIONS_STARTED, fetchExportSelectionsStart);
}
