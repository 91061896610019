import { put, call, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import * as actionTypes from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchConversationStart({payload}) {
  try {
    const resp = yield call(api.conversations.fetchConversation, payload.conversationId);
    if(typeof resp.data === "object"){
      //was found - not a new conversation
      yield put(actions.fetchConversationComplete(resp.data));
    }
  }
  catch (err) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({title:'Error Retrieving Conversation:', message:err.message}));
  }
}

export function* watchFetchConversationStart() {
  yield takeEvery(actionTypes.FETCH_CONVERSATIONS_STARTED, fetchConversationStart);
}
