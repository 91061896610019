import React from 'react';
import Footer from '../../components/footer';
import { DisruptionsMap, DisruptionsMapControls } from '../../containers/map';
import { DisruptionsContainer } from '../../containers/disruptions/DisruptionsContainer';
import './extras/styles.scss';
import ConfigurationContext from '../../components/config/ConfigurationContext'

export default function Disruptions(props) {
  return (
    <ConfigurationContext.Consumer>
      {config => (
        <div className="disruptions-page" data-test="disruptionsPage">
          <div className="hidden-xs disruptions-menu">
            <DisruptionsContainer {...props} />
          </div>
          <div className="disruptions-map">
            <div id="myMap" className="tracker-map">
              <DisruptionsMapControls />
              <DisruptionsMap appConfig={config} bingmapKey={config.bingMapKey} zoom={3} />
            </div>
          </div>
        </div>
      )}
    </ConfigurationContext.Consumer>
  );
}
