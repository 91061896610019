import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  routingSegments: [],
  origin: {},
  destination: {},
  loads: [],
  items: [],
  currentLocation: {},
  fetchedShipment: {},
  trackingLogs: {},
  loading: true,
  expectedPosition: {}
});

export default function fetchTrackingSummaryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_ORDER_TRACKING_SUMMARY_STARTED:
      return state
        .set('loading', true);
    case types.FETCH_ORDER_TRACKING_SUMMARY_FAILED:
      return state
        .set('loading', false);
    case types.FETCH_ORDER_TRACKING_SUMMARY_COMPLETED:
      return state
        .set('loads', action.payload.loads)
        .set('loading', false);
    case types.PROCESS_ROUTEDATA_COMPLETED:
      return state
        .set('routingSegments', action.payload.routingSegments)
        .set('pickDropPins', action.payload.pickDropPins)
        .set('currentLocation', action.payload.currentLocation)
        .set('expectedPosition', action.payload.expectedPosition);
    case types.FETCH_ORDER_COMPLETED:
      return state
        .set('fetchedShipment', action.payload.fetchedShipment)
        .set('orderNumber', action.payload.orderNumber)
        .set('loadNumbers', action.payload.loadNumbers);
    case types.FETCH_ORDER_TRACKING_LOGS_COMPLETED:
      return state
        .set('trackingLogs', action.payload.trackingLogs);
    case types.RESET_PROPS:
      return initialState
    default:
      return state;
  }
};
