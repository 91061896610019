import React, { Component } from 'react';
import Icon from '../icon';
import { getDate, getTime } from '../../utils/helperMethods/dateTimeMethods';
import { showContainers } from '../../utils/helperMethods/containerUtils';
import * as commonMethods from '../../utils/helperMethods/commonMethods';
import './extras/styles.scss';
import { CAPITALIZED_JOB_MODES, CAPITALIZED_SINGULAR_JOB_MODES, JOB_MODES } from '../../utils/constants';

const showETA = date => {
  return Date.parse(date) > 0;
};

class ShipmentsPopover extends Component {
  render() {
    let shipment = this.props.shipment;
    const jobMode = this.props.jobMode;
    const jobLabel = jobMode === JOB_MODES.shipments ? 'Load' : CAPITALIZED_SINGULAR_JOB_MODES[jobMode]
    const orderOrShipmentNumber = jobMode === JOB_MODES.shipments ?
      commonMethods.determineShipmentNumber(shipment) :
      commonMethods.determineOrderNumber(shipment, jobMode)
    let popoverShipment = {};
    let lowerCaseMode = shipment.mode.toLowerCase();
    let containerNumberSource = shipment.container_information ? shipment.container_information : shipment.handling_units;
    switch (lowerCaseMode) {
      case 'ocean':
        popoverShipment.label = shipment.vessel_name ? 'Vessel Name' : 'Vessel ID';
        popoverShipment.id = shipment.vessel_imo_number;
        popoverShipment.name = shipment.vessel_name;
        popoverShipment.eta = shipment.vessel_estimated_arrival_date;
        popoverShipment.etaText = 'Next Port ETA';
        popoverShipment.containerCount = containerNumberSource && containerNumberSource.length > 0 ? new Set(containerNumberSource.map(container => container.container_number)).size : '\u2014';
        popoverShipment.nextPortName = shipment.next_port_name;
        break;
      case 'air':
        popoverShipment.label = 'Aircraft ID';
        popoverShipment.id = shipment.flight_carrier_code
          ? shipment.flight_carrier_code + shipment.flight_plane_id
          : shipment.flight_plane_id;
        popoverShipment.eta = shipment.flight_eta;
        popoverShipment.etaText = 'Next Airport ETA';
        break;
      case 'parcel':
        popoverShipment.label = `Parcel ${jobLabel} #`;
        popoverShipment.id = orderOrShipmentNumber
        break;
      case 'truck':
        popoverShipment.label = `Truck ${jobLabel} #`;
        popoverShipment.id = orderOrShipmentNumber
        break;
      case 'ltl':
        popoverShipment.label = `LTL ${jobLabel} #`;
        popoverShipment.id = orderOrShipmentNumber
        break;
      default:
        popoverShipment.label = `${jobLabel} #`;
        popoverShipment.id = orderOrShipmentNumber
        break;
    }
    return (
      <div className="map-popover bubble">
        <div className="map-popover-header">
          <div className="read-only date-time">
            <span className="read-only-label"> {popoverShipment.label}</span>
            <span className="read-only-value" data-testid="shipmentspopoverIdText">
              {popoverShipment.name || popoverShipment.id || '\u2014'}
            </span>
          </div>
          <div className="special-info">
            {this.props.shipment.live_tracked ? (
              <svg
                id="livetrackedicon"
                className="icon"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="GPS Tracked"
              >
                <use href="#gps" />
              </svg>
            ) : null}
            {this.props.shipment.high_value ? (
              <Icon className="icon" type="high-value" />
            ) : null}
            {this.props.shipment.is_haz_mat ? (
              <Icon className="icon" type="hazmat" />
            ) : null}
            {commonMethods.containsRestrictedMaterial(this.props.shipment.items, this.props.shipment.reference_numbers) ? (
              <Icon id="restrictedmaterialicon" className="icon" type="restricted-material" />
            ) : null}
            {this.props.shipment.expedite ? (
              <Icon id="expediteicon" className="icon" type="expedite" />
            ) : null}
          </div>
        </div>
        <a id="closeInfobox">
          <Icon className="icon close" type="close" />
        </a>
        <hr />
        <div className="map-popover-body">
          <div className="row location-info">
            <div className="col-xs-6">
              <div className="read-only date-time">
                <span className="read-only-label">Origin</span>
                <span className="read-only-value">
                  {this.props.shipment.origin_city},
                  {this.props.shipment.origin_state},
                  {this.props.shipment.origin_country}
                </span>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="read-only date-time">
                <span className="read-only-label">Destination</span>
                <span className="read-only-value">
                  {this.props.shipment.destination_city},
                  {this.props.shipment.destination_state},
                  {this.props.shipment.destination_country}
                </span>
              </div>
            </div>
          </div>
          <hr className="secondary" />
          <div className="row eta-info">
            <div className="col-xs-4">
              {this.props.shipment.destination_Sched_date_close ? (
                <div className="read-only date-time">
                  <span className="read-only-label">Scheduled</span>
                  <span className="read-only-value">
                    {getDate(this.props.shipment.destination_Sched_date_close)}
                  </span>
                </div>
              ) : (
                <div className="read-only date-time">
                  <span className="read-only-label">Requested</span>
                  <span className="read-only-value">
                    {getDate(this.props.shipment.destination_requested)}
                  </span>
                </div>
              )}
            </div>
            <div className="col-xs-4">
              {showETA(this.props.shipment.calculated_ETA) &&
                this.props.shipment.load_status !== 90 ? (
                <div className="read-only date-time">
                  <span className="read-only-label">
                    {popoverShipment.eta ? popoverShipment.etaText : 'ETA'}
                  </span>
                  {popoverShipment.eta ? (
                    <React.Fragment>
                      {popoverShipment.nextPortName ?
                        <span className="read-only-value">
                          {popoverShipment.nextPortName}
                        </span> : null}
                      <span className="read-only-value">
                        {getDate(popoverShipment.eta)}
                      </span>
                      <span className="read-only-value">
                        {getTime(popoverShipment.eta)}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span className="read-only-value">
                        {getDate(this.props.shipment.calculated_ETA)}
                      </span>
                      <span className="read-only-value">
                        {getTime(this.props.shipment.calculated_ETA)}
                      </span>
                      {this.props.shipment.show_ETA_source ? (
                        <span className="support-text">
                          Source: Data Science
                        </span>
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <hr />
          {showContainers(this.props.shipment) ? (
            <div className="read-only">
              <span className="read-only-label inline"> Containers</span>
              <span className="read-only-value" data-testid="shipmentspopoverContainerCountText">
                {popoverShipment.containerCount}
              </span>
            </div>
          ) : null}
        </div>
        <div className="map-popover-footer">
          <button id="viewShipmentButton" className="btn btn-primary">
            {this.props.shipment.number_of_orders > 1 ? `View ${CAPITALIZED_JOB_MODES[jobMode]}` : `View ${CAPITALIZED_SINGULAR_JOB_MODES[jobMode]}`}
          </button>
        </div>
      </div>
    );
  }
}

export default ShipmentsPopover;
