import * as types from './actionTypes';

export default {
  fetchIotDetailRecords(payload) {
    return {
      type: types.FETCH_IOT_DETAIL_RECORDS_STARTED,
      payload: payload
    }
  },
  fetchIotDetailRecordsCompleted(payload) {
    return {
      type: types.FETCH_IOT_DETAIL_RECORDS_COMPLETED,
      payload: payload
    }
  },
  fetchIotDetailRecordsFailed(payload) {
    return {
      type: types.FETCH_IOT_DETAIL_RECORDS_FAILED,
      payload: payload
    }
  },
  fetchIotMapLogs(payload) {
    return {
      type: types.FETCH_IOT_MAP_LOGS_STARTED,
      payload: payload
    };
  },
  fetchIotMapLogsCompleted(payload) {
    return {
      type: types.FETCH_IOT_MAP_LOGS_COMPLETED,
      payload: payload
    };
  },
  fetchIotMapLogsFailed(payload) {
    return {
      type: types.FETCH_IOT_MAP_LOGS_FAILED,
      payload: payload
    };
  },
  fetchIotAverages(trackingNumber) {
    return {
      type: types.FETCH_IOT_AVERAGES_STARTED,
      payload: {
        trackingNumber
      }
    };
  },
  fetchIotAveragesCompleted(payload) {
    return {
      type: types.FETCH_IOT_AVERAGES_COMPLETED,
      payload: payload
    };
  },
  fetchIotAveragesFailed(payload) {
    return {
      type: types.FETCH_IOT_AVERAGES_FAILED,
      payload: payload
    };
  },
  updateUseIotMap (payload) {
    return {
      type: types.UPDATE_USE_IOT_MAP,
      payload: payload
    };
  },
  fetchIotSensorLocations (payload) {
    return {
      type: types.FETCH_IOT_SENSOR_LOCATIONS_STARTED,
      payload: payload
    };
  },
  fetchIotSensorLocationsFailed (payload) {
    return {
      type: types.FETCH_IOT_SENSOR_LOCATIONS_FAILED,
      payload: payload
    };
  },
  fetchIotSensorLocationsCompleted (payload) {
    return {
      type: types.FETCH_IOT_SENSOR_LOCATIONS_COMPLETED,
      payload: payload
    };
  },
  fetchActiveIotShipments (payload) {
    return {
      type: types.FETCH_ACTIVE_IOT_SHIPMENTS_STARTED,
      payload: payload
    }
  },
  fetchActiveIotShipmentsCompleted (payload) {
    return {
      type: types.FETCH_ACTIVE_IOT_SHIPMENTS_COMPLETED,
      payload: payload
    }
  },
  fetchActiveIotShipmentsFailed  (payload) {
    return {
      type: types.FETCH_ACTIVE_IOT_SHIPMENTS_FAILED,
      payload: payload
    }
  }
}
