const shipmentSortByOptions = [
  { label: "Created Date (descending)", value: {field: "created_date_utc" , order:"desc" }},
  { label: "Created Date (ascending)", value: {field: "created_date_utc" , order:"asc" }},
  { label: "Destination Requested/Scheduled (descending)", value: {field: "destination_Sched_date_close_or_requested" , order:"desc" }},
  { label: "Destination Requested/Scheduled (ascending)", value: {field: "destination_Sched_date_close_or_requested" , order:"asc" }},
  { label: "Destination Requested (descending)", value: {field: "destination_requested" , order:"desc" }},
  { label: "Destination Requested (ascending)", value: {field: "destination_requested" , order:"asc" }},
  { label: "Destination Scheduled (descending)", value: {field: "destination_Sched_date_close" , order:"desc" }},
  { label: "Destination Scheduled (ascending)", value: {field: "destination_Sched_date_close" , order:"asc" }},
  { label: "Origin Requested (descending)", value: {field: "origin_requested" , order:"desc" }},
  { label: "Origin Requested (ascending)", value: {field: "origin_requested" , order:"asc" }},
  { label: "Origin Scheduled (descending)", value: {field: "origin_Sched_date_close" , order:"desc" }},
  { label: "Origin Scheduled (ascending)", value: {field: "origin_Sched_date_close" , order:"asc" }}
];

const defaultShipmentSortBy = shipmentSortByOptions[2];

export { shipmentSortByOptions, defaultShipmentSortBy }