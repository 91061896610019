import React from 'react';
import { useDispatch } from 'react-redux'
import shipmentActions from '../../containers/shipments/actions';

export default function ExceptionsCard(props) {
  const dispatch = useDispatch();

  const viewShipments = (exceptionDescription, location, history) => {
    dispatch(shipmentActions.navigateToShipmentFromExceptionCard(exceptionDescription, location, history))
  }

  const renderExceptions = (exceptions) => {
    if (exceptions && exceptions.aggregations) {
      return exceptions.aggregations.source.buckets.map(x => {
        return (
          <a key={x.key} className="btn shipment-count-callout full-width flex" data-test="exception" onClick={() => viewShipments(x.key, props.location, props.history)}>
            <h4 className="flex-one">{x.key}</h4>
            <span className="shipment-count">{x.doc_count}<svg className="icon small faded"><use href="#chevron-right" /></svg></span>
          </a>
        );
      })
    } else {
      return (
        <div data-test="noExceptionsMessage">
          There are currently no events on your shipments
        </div>)
    }
  }

  return (
    <div className="card card-outline" data-test="exceptionCard">
      <div className="card-header">
        <div className="row">
          <div className="col-lg-12">
            <div className="read-only">
              <span className="medium-value">{props.exceptions && props.exceptions.hits && props.exceptions.hits.total || 0}</span>
              <span className="read-only-label">Exception Shipments</span>
            </div>
          </div>
        </div>

      </div>
      <div className="card-content">
        {renderExceptions(props.exceptions)}
      </div>
    </div>
  )
}


