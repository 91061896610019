import React from 'react'
import Icon from '../icon';
import * as shipmentMethods from '../../utils/helperMethods/shipmentMethods'
import { OverlayTrigger } from 'react-bootstrap';
import { getDate, getTime } from '../../utils/helperMethods/dateTimeMethods';
import { tooltip, popover } from '../../utils/helperMethods/commonComponents';
import { determineShipmentNumber } from '../../utils/helperMethods/commonMethods';
import { CAPITALIZED_SINGULAR_JOB_MODES, JOB_MODES } from '../../utils/constants';

const isGfs = (origination) => {
  return origination === 3;
}

const getOriginActualHeader = shipment => {
  if (isGfs(shipment.origination)) {
    return 'Actual';
  } else {
    return 'Complete';
  }
};

const getOriginActual = (shipment) => {
  if (isGfs(shipment.origination)) {
    return shipment.origin_arrived;
  } else {
    return shipment.pickup_date;
  }
}

const getDestinationComplete = (shipment) => {
  if (isGfs(shipment.origination)) {
    if (shipment.stops) {
      let drop = shipment.stops.find(stop => stop.stop_role === 2);
      if (drop !== undefined) {
        return drop.actual_depart_datetime;
      }
    }
  }

  return shipment.delivery_date;
}

const getEta = (shipment) => {
  if (isGfs(shipment.origination)) {
    return shipment.destination_ETA;
  } else {
    return shipment.calculated_ETA;
  }
}

export default function CondensedCard(props) {
  return (
    <div className={shipmentMethods.styles(props).outerCardClassNames}>
      <div className="shipment-list-view">
        <div className="shipment-card-list">
          <div className="row">
            <div className="col-sm-2 col-xs-12">
              <div className="row">
                {!shipmentMethods.isHidden(props.primary_identifiers) && (
                  <div className="number-switch col-lg-12">
                    <div className="read-only">
                      <span className="read-only-label" id="primaryidlabel">
                        {shipmentMethods.getIdentifierLabel(props.primary_identifiers)}{' '}
                      </span>
                      <span className="read-only-value" id="primaryids">
                        {shipmentMethods.getIdentifierValues(props.primary_identifiers, shipmentMethods.identifierLimit) || '\u2014'}
                      </span>
                      {props.primary_identifiers && props.primary_identifiers.length > shipmentMethods.identifierLimit && (
                        <a id="primaryidsellipses" title="Show More" className="btn-icon" onClick={() => props.showModal(shipmentMethods.createModalConfig(props.primary_identifiers))}>
                          <svg className="icon">
                            <use href="#ellipses-horizontal" />
                          </svg>
                        </a>
                      )}
                    </div>
                  </div>
                )}
                <div className="number-switch col-lg-12">
                  <div className="read-only">
                    <span className="read-only-label" id="secondaryidlabel">
                      Shipment Number
                    </span>
                    <span className="read-only-value" id="secondaryids">
                      { determineShipmentNumber(props) || '\u2014'}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-10 col-xs-12">
              <div className="row">
                <div className="col-sm-2 col-xs-12 reduce-padding">
                  {props.order_origin_code && props.origin_code && props.order_origin_code !== props.origin_code ? (
                    <div className="read-only">
                      <span className="read-only-label">Origin</span>
                      <span className="read-only-values" id="orderoriginname">
                        {props.order_origin_name + ','}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-value" id="orderoriginlocation">
                        {props.order_origin_city}, {props.order_origin_state}, {props.order_origin_country}
                      </span>
                    </div>
                  ) : (
                    <div className="read-only">
                      <span className="read-only-label">Origin</span>
                      <span className="read-only-value" id="originname">
                        {(props.origin_name || props.origin_facility_name) + ','}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-value" id="originlocation">
                        {props.origin_city}, {props.origin_state}, {props.origin_country}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm-2 col-xs-12 reduce-padding">
                  {!props.origin_Sched_date_close && props.origin_requested ? (
                    <div className="read-only">
                      <span className="read-only-label">Requested</span>
                      <span className="read-only-value" id="originrequesteddate">
                        {getDate(props.origin_requested)}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-time" id="originrequestedtime">
                        {getTime(props.origin_requested)}{' '}
                      </span>
                    </div>
                  ) : props.origin_Sched_date_close ? (
                    <div className="read-only">
                      <span className="read-only-label">Scheduled</span>
                      <span className="read-only-value" id="originscheddate">
                        {getDate(props.origin_Sched_date_close)}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-time" id="originschedtime">
                        {getTime(props.origin_Sched_date_close)}{' '}
                      </span>
                    </div>
                  ) : (
                    <div className="read-only">
                      <span className="read-only-label">Scheduled</span>
                      <span className="read-only-value"> {'\u2014'} </span>
                    </div>
                  )}
                </div>
                <div className="col-sm-2 col-xs-12 reduce-padding">
                  {getOriginActual(props) ? (
                    <div className="read-only">
                      <span className="read-only-label">{getOriginActualHeader(props)}</span>
                      <span className="read-only-value" id="originactualdate">
                        {getDate(getOriginActual(props))}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-time" id="originactualtime">
                        {getTime(getOriginActual(props))}{' '}
                      </span>
                    </div>
                  ) : (
                    <div className="read-only">
                      <span className="read-only-label">{getOriginActualHeader(props)}</span>
                      <span className="read-only-value" id="originactualdate">
                        {' '}
                        {'\u2014'}{' '}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm-2 col-xs-12 reduce-padding">
                  {props.order_destination_code && props.destination_code && props.order_destination_code !== props.destination_code ? (
                    <div className="read-only">
                      <span className="read-only-label">Destination</span>
                      <span className="read-only-value" id="orderdestinationname">
                        {props.order_destination_name + ','}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-value" id="orderdestinationcitystate">
                        {props.order_destination_city}, {props.order_destination_state}, {props.order_destination_country}
                      </span>
                    </div>
                  ) : (
                    <div className="read-only">
                      <span className="read-only-label">Destination</span>
                      <span className="read-only-value" id="destinationname">
                        {(props.destination_name || props.destination_facility_name) + ','}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-value" id="destinationcitystate">
                        {props.destination_city}, {props.destination_state}, {props.destination_country}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm-2 col-xs-12 reduce-padding">
                  {!props.destination_Sched_date_close && props.destination_requested ? (
                    <div className="read-only">
                      <span className="read-only-label">Requested</span>
                      <span className="read-only-value" id="destinationrequesteddate">
                        {getDate(props.destination_requested)}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-time" id="destinationrequestedtime">
                        {getTime(props.destination_requested)}{' '}
                      </span>
                    </div>
                  ) : props.destination_Sched_date_close ? (
                    <div className="read-only">
                      <span className="read-only-label">Scheduled</span>
                      <span className="read-only-value" id="destinationscheddate">
                        {getDate(props.destination_Sched_date_close)}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-time" id="destinationschedtime">
                        {getTime(props.destination_Sched_date_close)}{' '}
                      </span>
                    </div>
                  ) : (
                    <div className="read-only">
                      <span className="read-only-label">Scheduled</span>
                      <span className="read-only-value" id="destinationscheddate">
                        {' '}
                        {'\u2014'}{' '}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm-2 col-xs-12 reduce-padding">
                  {props.load_status !== 90 && shipmentMethods.showETA(getEta(props)) ? (
                    <div className="read-only">
                      <span className="read-only-label">
                        ETA&nbsp;
                        <OverlayTrigger placement="top" overlay={popover('ETA', 'Estimated arrival time at the destination based on the most recent location update and historical delivery trends.', 'etapopover')}>
                          <svg className="icon small faded">
                            <use href="#help" style={{ pointerEvents: 'none' }} />
                          </svg>
                        </OverlayTrigger>
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-value" id="etadate">
                        {getDate(getEta(props))}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-time" id="etatime">
                        {getTime(getEta(props))}{' '}
                      </span>
                      {props.show_ETA_source && !isGfs(props.origination) && <span className="support-text">Source: Data Science</span>}
                    </div>
                  ) : getDestinationComplete(props) && props.load_status === 90 ? (
                    <div className="read-only">
                      <span className="read-only-label">Complete</span>
                      <span className="read-only-value" id="completiondate">
                        {getDate(getDestinationComplete(props))}
                      </span>
                      <hr className="invisible-break" />
                      <span className="read-only-time" id="completiontime">
                        {getTime(getDestinationComplete(props))}{' '}
                      </span>
                    </div>
                  ) : (
                    <div className="read-only">
                      <span className="read-only-label">ETA</span>
                      <span className="read-only-value" id="etadate">
                        {' '}
                        {'\u2014'}{' '}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {props.permissions && 
            (!props.jobMode
            || (props.jobMode === JOB_MODES.shipments && props.permissions.includes('ViewShipmentTracker'))
            || (props.jobMode === JOB_MODES.orders && props.permissions.includes('ViewOrderTracker')))  
            && (
            <div style={{ padding: '5px', position: 'absolute', bottom: '0', right: '0' }}>
              <a
                className="pull-right"
                id="trackshipment"
                onClick={() => {
                  shipmentMethods.trackShipment(props, props.jobMode);
                }}
              >
                <OverlayTrigger placement="left" overlay={tooltip(`Track ${CAPITALIZED_SINGULAR_JOB_MODES[props.jobMode]}`, 'trackShipment')}>
                  <Icon type="location-pin" />
                </OverlayTrigger>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );

}
