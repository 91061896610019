import React, { Component } from 'react';
import { PieChart, Pie, Sector, Cell, Legend , ResponsiveContainer } from 'recharts';
import LoadingIcon from '../../components/loading-icon';

export default class PieGraph extends React.Component {

  state = {
    activeIndex: 0
  };

  renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, percent, value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${(percent * 100).toFixed(2)}%`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(${value} ${this.props.type})`}
        </text>
      </g>
    );
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    return (
      <div className='pie-chart card' data-test="PieGraph" style={{ width: this.props.width, height: this.props.height }}>
      { this.props.loading ?
        <div style={{margin: "0 auto", paddingTop: `${this.props.height / 4}px`}}><LoadingIcon/></div> :
        <React.Fragment>
          <h3 className="graph-header flex-center">{this.props.header}</h3>
          <ResponsiveContainer id={`pie-chart-container`}>
            <PieChart data-test='healthChart' id={`pie-chart`}>
              <Pie
                data-test="pie"
                activeIndex={this.state.activeIndex}
                activeShape={this.renderActiveShape}
                data={this.props.data}
                innerRadius={35}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={this.onPieEnter}>
                {
                  this.props.data.map((entry, index) =>
                    <Cell key={entry.name} fill={this.props.colorMapping[entry.name]} />)
                }
              </Pie>
              <Legend align="center" iconType="diamond" height={36} wrapperStyle={{bottom: "-20px"}}/>
            </PieChart>
          </ResponsiveContainer>
        </React.Fragment>
      }
      </div>
    );
  }
}

PieGraph.defaultProps = {
  data: [],
  loading: false
}
