import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../../apis';
import * as types from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../../../containers/global-success-error-alerts/actions';

export function* saveFilterSetStart(payload) {
  try {
    yield call(api.users.saveFilterSet, payload.filterSet);
    yield put(actions.fetchFilterSets());
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: `Filter set ${payload.filterSet.label} successfully saved`
    }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `Error saving filter set: ${payload.filterSet.label}`,
      error: e
    }));
  }
}

export function* watchSaveFilterSetStart() {
  yield takeEvery(types.SAVE_FILTERSET_STARTED, saveFilterSetStart);
}

export function* deleteFilterSetStart(payload) {
  try {
    yield call(api.users.deleteFilterSet, payload.id);
    yield put(actions.fetchFilterSets());
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `Error deleting filter set: ${payload.id}`,
      error: e
    }));
  }
}

export function* watchDeleteFilterSetStart() {
  yield takeEvery(types.DELETE_FILTERSET_STARTED, deleteFilterSetStart);
}

export function* updateFilterSetStart({payload}) {
  try {
    yield call(api.users.updateFilterSet, payload.id, payload.filterSet);
    yield all([
      put(actions.fetchFilterSets()),
      put(actions.updateFilterSetComplete())
    ]);
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `Error updating filter set: ${payload.filterSet.label}`,
      error: e
    }));
  }
}

export function* watchUpdateFilterSetStart() {
  yield takeEvery(types.UPDATE_FILTERSET_STARTED, updateFilterSetStart);
}

export function* fetchFilterSetStart() {
  try {
    const filterSets = yield call(api.users.fetchFilterSets);
    yield put(actions.fetchFilterSetComplete(filterSets.docs));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: `Error fetching filter sets.`,
      error: e
    }));
  }
}

export function* watchFetchFilterSetStart() {
  yield takeEvery(types.FETCH_FILTERSETS_STARTED, fetchFilterSetStart);
}
