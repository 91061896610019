import React from 'react';
import * as dateTimeMethods from '../../utils/helperMethods/dateTimeMethods';

import logo from "./extras/chr-footer-logo.png";


export default function ExternalFooter() {
  return (
    <footer className="footer fixed-position-bottom">
      <div className="container-fluid">
        <div className="flex">
          <div className="col-xs-12 col-sm-6 margin-auto">
            <a href="https://www.chrobinson.com/en-us/"><img src={logo} /></a>
            <ul className="footer-links">
              <li>
                <p className="copyright-statement"> ©1996-{dateTimeMethods.generateYear()} C.H. Robinson Worldwide, Inc.</p>
                <p className="copyright-statement"> All Rights Reserved.</p>
              </li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-6 margin-auto">
            <div className="site-legal">
              <p>Navisphere Vision is a registered trademark of C.H. Robinson Worldwide. </p><br />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
