import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../../components/icon';
import trackingSummaryActions from '../../containers/tracking-summary/actions';
import iotActions from '../../containers/iot/actions';
import { getLongDateLocal, getTimeLocal } from '../../utils/helperMethods/dateTimeMethods';
import PaginationGroup from '../../components/pagination-group';
import { connect } from 'react-redux';
import ConfigurationContext from '../../components/config/ConfigurationContext'
import MiniMapGenerator from '../../utils/helperMethods/miniMapGenerator';
import LoadingIcon from '../../components/loading-icon';

export class IoTDetails extends Component {
  constructor (props) {
    super(props);
    this.state = {
      parsedQueryString: '',
      currentPage: 1
    }
  }

  fetchIotDetails = async (selectedPageNumber) => {
    const { fetchIotDetailRecords, limit, shipmentId } = this.props;
    this.setState({
      currentPage: selectedPageNumber
    })

    await fetchIotDetailRecords({limit, pageNumber: selectedPageNumber, trackingNumber: shipmentId});
  }

  renderDetailsMap = (event) => {
    const miniMapProps = {
      id: uuidv4(),
      lat: event.latitude,
      long: event.longitude,
      accuracy: event.accuracy,
      mapType: 'canvasLight',
      zoom: 5,
      shouldDrawOnMap: true,
      box: {
        bottom_right: {
          lat: 1, lon: 2
        },
        top_left: {
          lat: 1, lon: 2
        }
      }
    };

    const formattedLatitude = event && event.latitude ? event.latitude.toFixed(7) : '';
    const formattedLongitude = event && event.longitude ? event.longitude.toFixed(7) : '';

    const detailsMap = (
      <ConfigurationContext.Consumer>
        {config => (
          <div className='iot-details-list-item-map-container'>
            <div className='iot-details-list-item-map-info'>
              {event && event.city && event.state &&
                <p className='iot-details-list-item-map-info-location'>{event.city}, {event.state}</p>
              }
              <p className='iot-details-list-item-map-info-geolocation'>{formattedLatitude}, {formattedLongitude}</p>
            </div>
            <div className='iot-details-list-item-map'>
              { miniMapProps && miniMapProps.lat && miniMapProps.long && miniMapProps.accuracy <= 80000 &&
                <MiniMapGenerator bingmapKey={config.bingMapKey} {...miniMapProps}/>
              }
            </div>
          </div>
        )}
      </ConfigurationContext.Consumer>
    );

    return detailsMap;
  }

  renderDetailsMetrics = (metrics) => {
    const metricsList = [];
    metrics.forEach((metric, idx) => {
      const metricAnomalyClass = metric.isAnomaly ? 'iot-anomaly-list-item' : "";
      const metricAnomalyStyle = metric.isAnomaly ? { textTransform: 'uppercase' } : { textTransform: 'capitalize' } ;

      const sensorType = metric && metric.sensorType ? metric.sensorType.toLowerCase() : '';

      let currentValue = metric && metric.currentValue && metric.currentValue !== 0 ? metric.currentValue.toLocaleString('en-US') : metric.currentValue.toString();
      currentValue = currentValue || '';
      const unitOfMeasure = metric.uom;
      const valueUnitSpacer = (metric.uom == '%' || metric.uom == '°') ? '' : ' ';

      metricsList.push(<p className={metricAnomalyClass} key={idx}><span style={metricAnomalyStyle}>{sensorType}</span> {currentValue}{valueUnitSpacer}{unitOfMeasure}</p>)
    });

    return metricsList
  }

  renderIotDetails = (logs) => {
    if (logs && logs.length > 0) {

      const iotDetails = [];

      for (const log in logs) {
        const event = logs[log]
        iotDetails.push(
          <tr className="iot-details-table-row" key={log}>
            <td>
              <div>{getLongDateLocal(event.timestamp)}</div>
              <div>{getTimeLocal(event.timestamp)}</div>
            </td>
            <td>{this.renderDetailsMetrics(event.metrics)}</td>
            <td>{this.renderDetailsMap(event)}</td>
          </tr>
        );
      };

      return iotDetails;
    }
  }

  render() {
    const { getIotLogs, iotDetailRecords, iotDetailsLoading, isIotDetailsActive, limit, processTrackingLogs } = this.props;
    return (
      isIotDetailsActive &&
      <>
        { iotDetailsLoading ?
          <div style={{ margin: 'auto', width: '200px' }}><LoadingIcon/></div>
          :
          <div className={isIotDetailsActive === "active" ? "tab-pane fade active in" : "tab-pane fade"} id="iotDetails">
            <table className="table table-striped tracking-history-table iot-details-table">
              <thead>
                <tr>
                  <th className="sortLogs" onClick={() => { processTrackingLogs(getIotLogs, true); }}>Date \ Time<Icon type="sort" /></th>
                  <th>Activity</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {this.renderIotDetails(iotDetailRecords.results)}
              </tbody>
            </table>
            <PaginationGroup
              className="pagination"
              totalPages={Math.ceil(iotDetailRecords.total / limit)}
              currentPageNumber={parseInt(this.state.currentPage)}
              limit={limit}
              total={iotDetailRecords.total}
              fetchNextPage={this.fetchIotDetails.bind(this)}
              label="iot-logs"
            />
          </div>
        }
      </>
    );
  }
};

export const mapStateToProps = (state) => {
  return {
    iotDetailRecords: state.iotSummary.get('iotDetailRecords'),
    iotDetailsLoading: state.iotSummary.get('iotDetailsLoading'),
    limit: state.iotSummary.get('limit')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    processTrackingLogs: (trackingLogs, sort) => {
      return dispatch(trackingSummaryActions.processTrackingLogs(trackingLogs, sort));
    },
    fetchIotDetailRecords: ({limit, trackingNumber, pageNumber}) => {
      return dispatch(iotActions.fetchIotDetailRecords({limit, trackingNumber, pageNumber}));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IoTDetails);
