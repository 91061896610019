import React from 'react';
import Icon from '../icon'

class IncidentsPopover extends React.Component {
  render() {
    return (
      <div className="map-popover bubble">
        <a id="closeInfobox" > <Icon className="icon close" type="close" /></a>
        <div className="map-popover-header">
            <div className="read-only-label">{this.props.incident.name}</div>
        </div>
        {
            this.props.incident.description &&
            <div className="map-popover-details">
                <hr />
                <div className="row">
                    <div className="col-sm-12">
                        <div className="read-only-value">{this.props.incident.description}</div>
                    </div>
                </div>
            </div>
        }
      </div>
    );
  }
}

export default IncidentsPopover;
