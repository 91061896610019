import React from 'react';
import Icon from '../icon';
import { v4 as uuidv4 } from "uuid";
import { getDateTime } from '../../utils/helperMethods/dateTimeMethods';
import LoadingIcon from '../loading-icon'

export default function InventoryDetailsTable(props) {

  const snapshotDate = (props.facilities && props.facilities[0]) ? props.facilities[0]._source.snapshot_date : 'N/A';

  const renderItems = (items) => {
    if (items && items.length > 0) {
      return items.map(item => {
        const data = item._source;
        return (
          <tr key={uuidv4()}>
            <td id={"facility-name"}>{data.location_name || '\u2014'}</td>
            <td id={"qty-value"}>{data.onhand_quantity || '\u2014'}</td>
            <td className="text-right">{data.commited_quantity || '\u2014'}</td>
            <td className="text-right">{data.available_quantity || '\u2014'}</td>
          </tr>
        );
      });
    }
  }

  return (
    <div id="inventorydetailstable" className="flex-column inventory">
      <h1>{props.state.identifier}</h1>
      <div className="row">
        <div className="col-xs-12">
          <span className="italic float-right">
            <p>Last Update: {!props.loading && getDateTime(snapshotDate)} </p>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <table className="table">
            <thead>
              <tr>
                <th onClick={() => props.sort("location_name")} id="locationname">Facility <Icon type="sort" /></th>
                <th onClick={() => props.sort("onhand_quantity")} id="onhandquantity">Total <Icon type="sort" /></th>
                <th onClick={() => props.sort("commited_quantity")} id="commitedquantity" className="text-right">Restricted <Icon type="sort" /></th>
                <th onClick={() => props.sort("available_quantity")} id="availablequantity" className="text-right">Unrestricted <Icon type="sort" /></th>
              </tr>
            </thead>
            <tbody>
            {props.loading ? <div className="centered"><LoadingIcon></LoadingIcon></div> : renderItems(props.facilities)}
            </tbody>
          </table>
          <div className="inventory-count-label">
            <div>Total Unrestricted</div>
            {!props.loading && <div className="inventory-count">{props.unrestricted.value}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
