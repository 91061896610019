import { all, put, call, takeEvery } from 'redux-saga/effects';
import debounce from '../../utils/helperMethods/reduxHelpers';
import api from '../../apis';
import * as actionTypes from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchScorecardStart({ payload }) {
  try {
    const scorecard = yield call(api.shipments.fetchCarrierScorecard, payload);
    yield put(actions.fetchScorecardComplete(scorecard.data));
  }
  catch (e) {
    yield all([
      put(actions.fetchScorecardFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Fetch Scorecard Error',
        message: `Scorecard data could not be retrieved. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchScorecardStart() {
  yield takeEvery(actionTypes.FETCH_SCORECARD_STARTED, fetchScorecardStart);
}

export function* fetchScorecardCarriersStart({ payload }) {
  try {
    const carriers = yield call(api.shipments.fetchCarriersAutocomplete, payload);
    yield put(actions.fetchScorecardCarriersComplete(carriers.data));
  }
  catch (e) {
    yield all ([
      put(actions.fetchScorecardCarriersFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Fetch Scorecard Carriers Error',
        message: `Carriers could not be retrieved. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchScorecardCarriersStart() {
  yield debounce(500, actionTypes.FETCH_SCORECARD_CARRIERS_STARTED, fetchScorecardCarriersStart);
}