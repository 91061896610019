import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  incidents: [],
  loading: true
});

function incidentReducer(state = initialState, action={}) {
  switch (action.type) {
    case types.FETCH_INCIDENTS_STARTED:
      return state
        .set('loading', true);
    case types.FETCH_INCIDENTS_FAILED:
      return state
        .set('loading', false);
    case types.FETCH_INCIDENTS_COMPLETED:
      return state
        .set('incidents', action.payload.incidents)
        .set('loading', false);
    case types.SAVE_DRAWN_INCIDENT_SHAPE:
        return state
        .set('currentDrawnIncident', action.payload.incident);
    case types.CLEAR_DRAWN_INCIDENT:
        return state
        .set('currentDrawnIncident', undefined);
    default:
      return state;
  }
}

export default incidentReducer;
