import React from 'react';

import LayoutExternal from './LayoutExternal';
import { useAnalyticsTrack } from '@chr/react-analytics';

export default function LayoutExternalHOC(Component, showLoginBtn) {
  return function WrappedComponent(props) {
    const track = useAnalyticsTrack();

      return (
        <LayoutExternal {...props} showLoginBtn={showLoginBtn}>
          <Component {...props} adobeTrack={track}/>
        </LayoutExternal>
      );
  }
}
