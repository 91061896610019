import React from 'react';
import * as shipmentMethods from './shipmentMethods';
import { isNonNav } from '../../utils/helperMethods/commonMethods';
import { USE_CON_ORDER_NUMBER, JOB_MODES } from '../constants';

const defaultDisplayOrderNumbersLimit = 3;

const getOrderReferenceNumbers = (props) => {
  if (!props.reference_numbers) {
    return [];
  }
  return props.reference_numbers.filter((selectedReferenceNumber) => {
    return selectedReferenceNumber.level === 'Load'
    && selectedReferenceNumber.code === 'CON'
    && selectedReferenceNumber.parent_id !== null
    && selectedReferenceNumber.parent_id.length
  });
}

const getOrderNumberLink = (props, limit, stopOrderNumbers) => {
  let orderNumbers = stopOrderNumbers ? stopOrderNumbers : props.order_numbers;
  let orderReferenceNumbers = getOrderReferenceNumbers(props);
  let result = [];

  if ((!orderNumbers || !orderNumbers.length) && (!orderReferenceNumbers.length)) {
    return null;
  }

  if (props.ableToViewOrderTracker && props.jobMode !== JOB_MODES.orders && !isNonNav(props) && orderReferenceNumbers.length) {
    let orderNumberParentIdMap = orderReferenceNumbers[0].parent_id;
    if(stopOrderNumbers) {
      orderNumberParentIdMap = orderNumberParentIdMap.filter(x => stopOrderNumbers.includes(x.parent_id));
    }
    result = orderNumberParentIdMap.map((x, i) => {
        return <a onClick={() => shipmentMethods.trackShipment(props, JOB_MODES.orders, x.parent_id)} key={i}>{x.value}</a>
    }).slice(0, limit);
  }
  else {
    result = orderNumbers.map((selectedOrderNumber, i) => {
      if (props.ableToViewOrderTracker && props.jobMode !== JOB_MODES.orders) {
        return <a onClick={() => shipmentMethods.trackShipment(props, JOB_MODES.orders, selectedOrderNumber)} key={i}>{selectedOrderNumber}</a>
      } else {
        return <span key={i}>{selectedOrderNumber}</span>
      }
    }).slice(0, limit);
  }

  //return comma-separated list of Objects
  return result.slice(1).reduce((x, i) => {
    return x.concat([", ", i]);
  }, [result[0]]);
};

const getOrdersModalConfig = (props, stopOrderNumbers) => {
  let orderNumbers = stopOrderNumbers ? stopOrderNumbers : props.order_numbers;
  let orderReferenceNumbers = getOrderReferenceNumbers(props);
  
  let modalBody = <div>{orderNumbers.map((orderNumber, i) => {
    let orderNumberTitle = orderNumber;

    if (props.ableToViewOrderTracker && props.jobMode !== JOB_MODES.orders) {
      if (USE_CON_ORDER_NUMBER.includes(props.tenantId) && orderReferenceNumbers && orderReferenceNumbers.length)
      {
        let orderNumberParentIdMap = orderReferenceNumbers[0].parent_id;
        let matchedParentIds = orderNumberParentIdMap.filter(id => id.parent_id === orderNumber)
        orderNumberTitle = matchedParentIds && matchedParentIds.length ? matchedParentIds[0].value : orderNumberTitle
      }
      return <a onClick={() => {
        shipmentMethods.trackShipment(props, JOB_MODES.orders, orderNumber);
        props.closeModal();
      }} key={i}>{orderNumberTitle}<br /></a>
    }
    else {
      return <div key={i}>{orderNumberTitle}<br /></div>
    }
  })}</div>;

  return {
    title: 'Orders',
    showActionButton: false,
    body: modalBody
  };
};

export {
  defaultDisplayOrderNumbersLimit,
  getOrderReferenceNumbers,
  getOrderNumberLink,
  getOrdersModalConfig
};
