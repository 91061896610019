import React from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';

export default class LoadingIcon extends React.Component {
  render() {
    return (
      <div id={'loading-icon'}>
        <ScaleLoader
          color={this.props.color}
          height={this.props.height}
          width={this.props.width}
          margin={this.props.margin}
          data-test="LoadingIcon"
        ></ScaleLoader>
      </div>
    );
  }
}

LoadingIcon.defaultProps = {
  color: '#f47b20',
  height: 100,
  width: 10,
  margin: '3px'
};

LoadingIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.string
};
