export const FETCH_TRACKING_SHIPMENT_STARTED = 'tracking/FETCH_TRACKING_SHIPMENT_STARTED';
export const FETCH_TRACKING_SHIPMENT_FAILED = 'tracking/FETCH_TRACKING_SHIPMENT_FAILED';
export const FETCH_TRACKING_SHIPMENT_COMPLETED = 'tracking/FETCH_TRACKING_SHIPMENT_COMPLETED';

export const FETCH_TRACKING_MILESTONES_STARTED = 'tracking/FETCH_TRACKING_MILESTONES_STARTED';
export const FETCH_TRACKING_MILESTONES_COMPLETED = 'tracking/FETCH_TRACKING_MILESTONES_COMPLETED';
export const FETCH_TRACKING_MILESTONES_FAILED = 'tracking/FETCH_TRACKING_MILESTONES_FAILED';

export const PROCESS_ROUTEDATA_COMPLETED = 'tracking/PROCESS_ROUTEDATA_COMPLETED';
export const TRACKING_MILESTONES_SORT = 'tracking/TRACKING_MILESTONES_SORT';

export const RESET_PROPS = 'tracking/RESET_PROPS';