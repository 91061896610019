import React from 'react';
import SelectDropdown from '../dropdown/SelectDropdown';
import EventNotificationFilter from './EventNotificationFilter';
import Icon from '../icon/Icon';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default class EventNotification extends React.Component {
  state = {
    rule: { name: '' },
    originalRule: {},
    editing: false,
    deliveryMethodOptions: [],
    eventTypeOptions: [
      {
        label: 'Status is At Risk',
        value: 'at-risk'
      },
      {
        label: 'Status is Late',
        value: 'late'
      },
      {
        label: 'Status is Early',
        value: 'early'
      },
      {
        label: 'Exception is added',
        value: 'new-exception'
      },
      {
        label: 'ETA changes',
        value: 'eta-change'
      },
      // INFO: Tracking Summary is user facing, the field that controls it is called
      // Current Location Status.
      {
        label: 'Tracking Summary Status is Booked',
        value: 'location-status-booked'
      },
      {
        label: 'Tracking Summary Status is Loading',
        value: 'location-status-loading'
      },
      {
        label: 'Tracking Summary Status is At Origin',
        value: 'location-status-at-origin'
      },
      {
        label: 'Tracking Summary Status is In Transit',
        value: 'location-status-in-transit'
      },
      {
        label: 'Tracking Summary Status is At Destination',
        value: 'location-status-at-destination'
      },
      {
        label: 'Tracking Summary Status is Unloading',
        value: 'location-status-unloading'
      },
      {
        label: 'Location Status is Delivered',
        value: 'location-status-delivered'
      },
      {
        label: 'Temperature Anomaly is Detected',
        value: 'iot-anomaly-temperature'
      },
      {
        label: 'Light Anomaly is Detected',
        value: 'iot-anomaly-light'
      },
      {
        label: 'Shock Anomaly is Detected',
        value: 'iot-anomaly-shock'
      },
      {
        label: 'Humidity Anomaly is Detected',
        value: 'iot-anomaly-humidity'
      },
      {
        label: 'Tilt Anomaly is Detected',
        value: 'iot-anomaly-tilt'
      },
      {
        label: 'Battery Anomaly is Detected',
        value: 'iot-anomaly-battery'
      },
      {
        label: 'Pressure Anomaly is Detected',
        value: 'iot-anomaly-pressure'
      }
    ]
  };

  componentDidMount() {
    this.setState({
      rule: _.cloneDeep(this.props.rule),
      originalRule: _.cloneDeep(this.props.rule),
      editing: this.props.rule.new ? true : false
    });

    let options = [
      {
        label: 'In-App',
        value: 'in-app'
      },
      {
        label: 'Email',
        value: 'email'
      }
    ];

    if (this.props.mobilePhoneIsVerified) {
      options.push({
        label: 'Text',
        value: 'sms'
      });
    };

    this.setState({
      deliveryMethodOptions: options
    });
  };

  handleUpdateCondition = (filterIdx, updatedCondition) => {
    const newState = _.cloneDeep(this.state);
    const newconditions = _.cloneDeep(this.state.rule.conditions.all);
    newconditions[filterIdx] = updatedCondition;
    newState.rule.conditions.all = newconditions;
    this.setState(newState);
  };

  handleDeleteCondition = (filterIdx) => {
    const newState = _.cloneDeep(this.state);
    const newconditions = _.cloneDeep(this.state.rule.conditions.all);
    newconditions.splice(filterIdx, 1);
    newState.rule.conditions.all = newconditions;
    this.setState(newState);
  };

  handleAddCondition = (filterIdx) => {
    const newState = _.cloneDeep(this.state);
    const newconditions = newState.rule.conditions.all;
    newconditions.splice(filterIdx + 1, 0, {});
    newState.rule.conditions.all = newconditions;
    this.setState(newState);
  };

  handleRuleNameChange = (e) => {
    const newState = Object.assign({}, this.state);
    newState.rule.name = e.target.value;
    this.setState(newState);
  }

  handleEventTypeChange = (selectedEventTypes) => {
    const newState = _.cloneDeep(this.state);
    newState.rule.eventType = selectedEventTypes ? selectedEventTypes.map(x => x.value) : null;;
    this.setState(newState);
  };

  handleDeliveryMethodChange = (selectedDeliveryMethods) => {
    const newState = _.cloneDeep(this.state);
    newState.rule.deliveryMethods = selectedDeliveryMethods ? selectedDeliveryMethods.map(sdm => sdm.value) : null;
    this.setState(newState);
  };

  getSelectedEventTypes = () => {
    if (this.state.rule.eventType) {
      return this.state.eventTypeOptions.filter(x => this.state.rule.eventType.includes(x.value));
    }
    return [];
  };

  getSelectedDeliveryMethods = () => {
    if (this.state.rule.deliveryMethods) {
      return this.state.deliveryMethodOptions.filter(dmo => this.state.rule.deliveryMethods.includes(dmo.value));
    }
    return [];
  };

  beginEditing = () => {
    this.setState({
      editing: true
    });
  }

  cancelEditing = () => {
    if (this.state.rule.new) {
      this.props.deleteAlertRule();
    } else {
      this.setState({
        rule: _.cloneDeep(this.state.originalRule),
        editing: false
      });
    }
  }

  saveAlertRule = async () => {
    const res = await this.props.createOrUpdateAlertRule(this.state.rule);
    if (res) {
      this.setState({
        editing: false
      });
    }
  }

  deleteAlertRule = () => {
    this.props.deleteAlertRule();
  }

  copyAlertRule = () => {
    this.props.copyAlertRule(this.state.rule);
  }

  render() {
    return (
      <div className="row" style={{ margin: this.props.isShipmentTracker ? "0px" : "30px"}}>
        <div className={this.props.isShipmentTracker ? "col-xs-12 col-sm-12 col-md-12" : "col-xs-12 col-sm-12 col-md-8"}>
          <div className="card">
            <div className="row">
              <div className="form-group col-md-1">
                Name:
              </div>
              <div className="form-group col-md-8">
                <input
                  className="form-control"
                  type="text"
                  id={`notification-${this.props.index}-name-input`}
                  name="ruleNameInput"
                  disabled={!this.state.editing}
                  onChange={this.handleRuleNameChange}
                  value={this.state.rule.name} />
              </div>
            </div>
            {
              this.state.rule.conditions && this.state.rule.conditions.all &&
              this.state.rule.conditions.all.map((condition, idx) =>
                <div className="row" style={{ paddingBottom: "10px" }} key={this.props.rule._id + '-' + idx}>
                  <div className="col-md-1 col-xs-1">
                    {idx === 0 ? "IF" : "AND"}
                  </div>
                  <div className="col-md-9 col-xs-11">
                    <EventNotificationFilter
                      notificationIdx={this.props.index}
                      key={idx}
                      filterIdx={idx}
                      condition={condition}
                      handleUpdateCondition={this.handleUpdateCondition}
                      disabled={!this.state.editing}
                      flows={this.props.flows}
                      regions={this.props.regions}
                    />
                  </div>
                  {this.state.editing && idx !== (this.state.rule.conditions.all.length - 1) &&
                    <div className="flex col-md-2 col-xs-12">
                      <button onClick={() => this.handleDeleteCondition(idx)}
                        className="btn btn-danger col-xs-12"
                        id={`notification-${this.props.index}-delete-${idx}-button`}
                        style={{ width: "100%" }}>
                        <Icon className="icon white" type="delete" />
                      </button>
                    </div>
                  }
                  {this.state.editing && idx === (this.state.rule.conditions.all.length - 1) &&
                    <div className="flex col-md-2 col-xs-12">
                      <button onClick={() => this.handleDeleteCondition(idx)} className="btn btn-danger col-xs-6" id={`notification-${this.props.index}-delete-${idx}-button`} style={{ width: "100%", marginRight: '5px' }} disabled={idx === 0}>
                        <Icon className="icon white" type="delete" />
                      </button>
                      <button onClick={() => this.handleAddCondition(idx)} className="btn btn-success col-xs-6" id={`notification-${this.props.index}-add-${idx}-button`} style={{ width: "100%" }}>
                        <Icon className="icon white" type="add" />
                      </button>
                    </div>
                  }
                </div>
              )
            }
            <div className="row">
              <div className="col-md-1">
                When
            </div>
              <div className="col-md-4">
                <SelectDropdown
                  selectedValues={this.getSelectedEventTypes()}
                  handleDropdownChange={this.handleEventTypeChange}
                  options={this.state.eventTypeOptions}
                  stateName={"selectedEventTypes"}
                  isMultiSelect={true}
                  closeMenuOnSelect={false}
                  isDisabled={!this.state.editing}
                  id={`notification-${this.props.index}-event-type-select`}
                  inputId={`notification-${this.props.index}-event-type-input`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                Send
            </div>
              <div className="col-md-4">
                <div className="form-group">
                  <SelectDropdown
                    selectedValues={this.getSelectedDeliveryMethods()}
                    handleDropdownChange={this.handleDeliveryMethodChange}
                    options={this.state.deliveryMethodOptions}
                    stateName={"selectedDeliveryMethods"}
                    isMultiSelect={true}
                    closeMenuOnSelect={false}
                    isDisabled={!this.state.editing}
                    id={`notification-${this.props.index}-delivery-method-select`}
                    inputId={`notification-${this.props.index}-delivery-method-input`}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-md-offset-1">
                {this.state.editing &&
                  <button onClick={this.cancelEditing} style={{ width: "100%" }} id={`notification-${this.props.index}-cancel-button`} className="btn btn-light">
                    Cancel
              </button>
                }
                {!this.state.editing &&
                  <button onClick={this.deleteAlertRule} style={{ width: "100%" }} id={`notification-${this.props.index}-delete-button`} className={"btn btn-danger"}>
                    Delete</button>
                }
              </div>
              <div className="col-md-2">
                {this.state.editing &&
                  <button onClick={this.saveAlertRule} style={{ width: "100%" }} id={`notification-${this.props.index}-save-button`} className="btn btn-primary">
                    Save
                </button>
                }
                {!this.state.editing &&
                  <button onClick={this.beginEditing} style={{ width: "100%" }} id={`notification-${this.props.index}-edit-button`} className="btn btn-primary">
                    Edit
                </button>
                }
              </div>
              <div className="col-md-2">
                {!this.state.editing &&
                  <button onClick={this.copyAlertRule} style={{ width: "100%" }} id={`notification-${this.props.index}-copy-button`} className="btn btn-primary">
                    Copy
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EventNotification.propTypes = {
  createOrUpdateAlertRule: PropTypes.func,
  deleteAlertRule: PropTypes.func,
  copyAlertRule: PropTypes.func,
  rule: PropTypes.object,
  flows: PropTypes.array,
  regions: PropTypes.array,
  index: PropTypes.number
};

EventNotification.defaultProps = {
  createOrUpdateAlertRule: () => { },
  deleteAlertRule: () => { },
  copyAlertRule: () => { },
  rule: {
    _id: '',
    conditions: {
      all: [{}]
    },
    new: false
  },
  flows: [],
  regions: []
};
