import React from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import FilterMenu from '../../components/filters/filter-menu/FilterMenu';
import filterActions from '../../containers/filters/actions';
import filterSetActions from '../../containers/users/filter-sets/actions';
import facilitiesActions from '../facilities/actions';
import baseModalActions from '../../containers/base-modal/actions';
import shipmentActions from '../../containers/shipments/actions';
import filterGenerator from '../../utils/helperMethods/filterGenerator';
import * as shipmentMethods from '../../utils/helperMethods/shipmentMethods';
import FilterSet from '../../components/filters/filter-set/FilterSet';
import SaveFiltersModal from '../../components/filters/filter-set/SaveFiltersModal';
import RemoveFiltersConfirmationModal from '../../components/filters/filter-set/RemoveFiltersConfirmationModal';

export class Filters extends React.Component {
  state = {
    activeGroup: [],
    selectedOption: {},
    defaultSet: undefined,
    deletedOption: undefined,
    title: undefined,
  };

  componentDidMount() {
    this.props.getDynamicFilters();
    this.props.getFilterSets();
    this.props.fetchFacilities();
  };

  componentDidUpdate(prevProps) {
    if (this.props.filterSetsLoadingState !== 'loaded') {
      return;
    }

    if (prevProps.dynamicFilters !== this.props.dynamicFilters || !this.props.filterSetLoaded ) {
      //generates shipment search template to save to store. If the user has a default saved, it'll replace the generated template.
      var generatedTemplate = filterGenerator(this.props.dynamicFilters);

      if (this.props.filterSets && this.props.filterSets.length > 0) {
        let defaultFilters = this.props.filterSets.filter(set => set.isDefault);
        if (defaultFilters.length > 0) {
          let defaultFilter = defaultFilters[0];
          let mergedFilters = undefined;

          if (defaultFilter.query) {
            mergedFilters = {
              ...generatedTemplate.filters,
              ...JSON.parse(defaultFilter.query).filters
            };
          } else {
            mergedFilters = {
              ...generatedTemplate.filters
            }
          }

          Object.keys(mergedFilters).forEach(key => {
            if (generatedTemplate.filters[key] === undefined) { delete mergedFilters[key]; };
          });

          this.props.applyLoadedFilterSet(mergedFilters, defaultFilter.facilityFilters, defaultFilter.dateFilter);
          this.setState({ defaultSet: defaultFilter._id });
        }
      };

      generatedTemplate.incidents = [];
    }
  };

  handleLoadFilters = () => {
    if (!this.state.selectedOption?.query) {
      return;
    }

    let savedFilters = JSON.parse(this.state.selectedOption.query);
    let mergedFilters = {};
    let generatedTemplate = filterGenerator(this.props.dynamicFilters);

    if (!savedFilters.filterConfiguration) {
      //handle old saved filters
      mergedFilters = { ...generatedTemplate.filters, ...savedFilters.filters };

      Object.keys(mergedFilters).forEach(key => {
        if (!generatedTemplate.filters[key]) {
          delete mergedFilters[key];
        };
      });
    } else {
      mergedFilters = { ...generatedTemplate, ...savedFilters };

      Object.keys(mergedFilters.filters).forEach(key => {
        if (!generatedTemplate.filters[key]) {
          delete mergedFilters.filters[key];
        };
      });
    };

    this.props.closeModal();
    this.props.applyLoadedFilterSet(mergedFilters, savedFilters.facilityFilters, savedFilters.dateFilter);
  };

  handleFiltersChange = (data, filter) => {
    this.setState({ activeGroup: data.label });
    this.props.filtersChanged(data.tag, filter);
  };

  handleFacilityFilterChange = (filter, status) => {
    const newFilter = {
      facilityName: filter.name,
      facilityCode: filter.code,
      facilityIdentifiers: filter.identifiers,
      shipmentStatus: status,
      parsedFacilityStatus: shipmentMethods.parseShipmentStatus(status)
    };
    this.props.facilityFiltersChanged(newFilter);
  };

  handleAccordionSelect = (activeGroup) => {
    this.setState(Object.assign(this.state, { activeGroup }));
  };

  handleOptionChange = (selectedSet) => {
    (selectedSet === this.state.selectedOption) ?
      this.setState(Object.assign(this.state, { selectedOption: {} }))
      : this.setState(Object.assign(this.state, { selectedOption: selectedSet }));
    this.props.showModal(this.loadFiltersModal());
  };

  handleDeleteFilterSet = (id) => {
    this.props.deleteFilterSet(id);
    this.setState(Object.assign(this.state, { deletedOption: id }));
    this.props.showModal(this.loadFiltersModal());
  };

  handleDefaultChange = (set) => {
    const payload = {
      isDefault: !set.isDefault,
      label: set.label,
      query: set.query
    };
    this.setState({ defaultSet: (this.state.defaultSet === set._id) ? false : set._id }, () => {
      this.props.updateFilterSet(set._id, payload);
      this.props.showModal(this.loadFiltersModal());
    });
  };

  savedFilterTitle = (event) => {
    this.setState(Object.assign(this.state, { title: event.target.value }));
    this.props.showModal(this.saveFiltersModal())
  };

  saveFilters = (savedConfiguration) => {
    const selectedFilters = [];
    Object.keys(savedConfiguration.activeFilters).forEach(key => {
      savedConfiguration.activeFilters[key].length > 0 &&
        selectedFilters.push(savedConfiguration.activeFilters[key].join(' , '));
    });

    if (this.state.title && (selectedFilters.length > 0 ||
      savedConfiguration.dateFilter ||
      savedConfiguration.searchText ||
      savedConfiguration.multiSearchType ||
      savedConfiguration.activeFacilityFilters.length > 0)) {
      const payload = {
        label: this.state.title,
        query: JSON.stringify({
          filters: this.props.configuration.activeFilters,
          facilityFilters: this.props.activeFacilityFilters,
          dateFilter: this.props.dateFilter,
          box: null //ensure we don't save split view box information because we will not necessarily display it in splitview
        })
      };
      this.props.saveFilterSet(payload);
      this.props.closeModal();
    };
  };

  loadFiltersModal = () => {
    return {
      title: "Saved Filters",
      showModal: true,
      showCloseButton: true,
      showActionButton: true,
      actionButtonText: "Load Filter",
      closeButtonText: "Cancel",
      actionButtonClass: "btn btn-primary",
      closeButtonClass: "btn btn-light",
      handleAction: this.handleLoadFilters,
      handleCloseAction: this.props.closeModal,
      size: "large",
      body: (
        <FilterSet
          filterSets={this.props.filterSets}
          selectedOption={this.state.selectedOption}
          deletedOption={this.state.deletedOption}
          defaultSet={this.state.defaultSet}
          handleLoadFilters={this.handleLoadFilters}
          handleOptionChange={this.handleOptionChange}
          handleDefaultChange={this.handleDefaultChange}
          removeSavedFilterSet={this.removeSavedFilterSet} />
      )
    };
  };

  saveFiltersModal = () => {
    return {
      title: "Save Selected Filters",
      showModal: true,
      showCloseButton: true,
      showActionButton: true,
      actionButtonText: "Save Filters",
      closeButtonText: "Cancel",
      actionButtonClass: "btn btn-primary",
      closeButtonClass: "btn btn-light",
      handleCloseAction: this.props.closeModal,
      handleAction: () => { this.saveFilters(this.props.configuration); },
      body: (
        <SaveFiltersModal title={this.state.title} savedFilterTitle={this.savedFilterTitle} savedConfiguration={this.props.configuration} />
      )
    };
  };

  removeSavedFilterSet = (set, savedFilters) => {
    this.props.showModal(
      {
        title: 'Delete Saved Filters?',
        showActionButton: true,
        showCloseButton: true,
        actionButtonText: "Delete",
        actionButtonClass: "btn btn-danger",
        closeButtonText: "Cancel",
        closeButtonClass: "btn btn-light",
        handleAction: () => { this.handleDeleteFilterSet(set._id) },
        handleCloseAction: () => { this.props.showModal(this.loadFiltersModal()); },
        body: (
          <RemoveFiltersConfirmationModal set={set} savedFilters={savedFilters} />
        )
      }
    );
  };

  render() {
    if (!this.props.displayFilters) {
      return <></>;
    }

    const dynamicFilters = filterGenerator(this.props.dynamicFilters);

    return (
      <div className="filter-container">
        <div className="filter-container-body">
          <div className="btn-group">
            <button type="button" className="btn btn-outline btn-sm"
              onClick={() => this.props.showModal(this.loadFiltersModal())} id={'load-filters-button'}>Load</button>
            <button type="button" className="btn btn-outline btn-sm"
              onClick={() => this.props.showModal(this.saveFiltersModal())} id={'save-filters-button'}>Save</button>
          </div>
          <FilterMenu
            dynamicFilters={dynamicFilters}
            activeFilters={this.props.activeFilters}
            activeGroup={this.state.activeGroup}
            handleFiltersChange={this.handleFiltersChange}
            filterAggregations={this.props.filterAggregations}
            facilityLocations={this.props.facilityLocations}
            handleAccordionSelect={this.handleAccordionSelect}
            handleFacilityFilterChange={this.handleFacilityFilterChange}
            activeFacilityFilters={this.props.activeFacilityFilters}
            location={this.props.location}
            history={this.props.history}
            filterLoading={this.props.filterLoading}
            jobMode={this.props.jobMode}
          />
        </div>
      </div>
    )
  };
};

export const mapStateToProps = (state) => {
  const userProfile = state.users.get('userProfile');
  const userRole = userProfile ? userProfile.role : null;
  const queryConfiguration = state.shipments.queryConfiguration;
  const jobMode = state.shipments.data.get('jobMode');
  const activeFacilityFilters = queryConfiguration.get('activeFacilityFilters');
  const activeFilters = queryConfiguration.get('activeFilters');
  const configuration = queryConfiguration.toObject();
  const filterAggregations = state.shipments.data.get('filterAggregations');
  const dateFilter = queryConfiguration.get('dateFilter');
  const filterSetLoaded = queryConfiguration.get('filterSetLoaded');

  return {
    activeFacilityFilters,
    activeFilters,
    dateFilter,
    filterAggregations,
    configuration,
    dynamicFilters: state.filters.get('dynamicFilters'),
    filterLoading: state.filters.get('loading'),
    filterSets: state.filterSets.get('filterSets'),
    filterSetsLoadingState: state.filterSets.get('filterSetsLoadingState'),
    userProfile,
    userRole,
    facilityLocations: state.facilityLocations.get('facilities'),
    jobMode,
    filterSetLoaded
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDynamicFilters: () => {
      return dispatch(filterActions.fetchTenantDynamicFilters());
    },
    filtersChanged: (filterTag, filter) => {
      return dispatch(shipmentActions.filtersChanged(filterTag, filter, ownProps.location, ownProps.history));
    },
    facilityFiltersChanged: (filter) => {
      return dispatch(shipmentActions.facilityFiltersChanged(filter, ownProps.location, ownProps.history));
    },
    fetchFacilities: () => {
      return dispatch(facilitiesActions.fetchFacilities());
    },
    getFilterSets: () => {
      return dispatch(filterSetActions.fetchFilterSets());
    },
    saveFilterSet: (payload) => {
      return dispatch(filterSetActions.saveFilterSet(payload));
    },
    updateFilterSet: (id, filterSet) => {
      return dispatch(filterSetActions.updateFilterSet(id, filterSet));
    },
    deleteFilterSet: (id) => {
      return dispatch(filterSetActions.deleteFilterSet(id));
    },
    showModal: (modal) => {
      dispatch(baseModalActions.openModal(modal));
    },
    closeModal: () => {
      dispatch(baseModalActions.closeModal());
    },
    applyLoadedFilterSet: (filters, facilityFilters, dateFilter) => {
      dispatch(shipmentActions.applyLoadedFilterSet(filters, facilityFilters, dateFilter, ownProps.location, ownProps.history))
    }
  }
};

export const FilterContainer = connect(mapStateToProps, mapDispatchToProps)(Filters);
