import React from 'react';

export default class NotificationPreference extends React.Component {
  render() {
    return (
      <div>
        <div className="notification-type">
          <div className="row">
            <div className="col-sm-6 col-md-8">
              <h4 className="">{this.props.title}</h4>
              <p>{this.props.description} </p>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="row">
                <div className="col-xs-12 col-sm-4 toggle-container">
                  <span className="toggle">
                    <input type="checkbox" id={`${this.props.notificationType} inApp`}
                      name="set-name"
                      className="switch-input"
                      onChange={() => this.props.selectedNotification(this.props.notificationType, this.props.inApp, 'inApp')}
                      checked={this.props.inApp}
                      data-test="inApp" />
                    <label id={`${this.props.notificationType}-inApp`} htmlFor={`${this.props.notificationType} inApp`} className="switch-label"></label>
                    <span className="toggle-label visible-sm visible-xs">In-App</span>
                  </span>
                </div>
                <div className="col-xs-12 col-sm-4 toggle-container">
                  <span className="toggle">
                    <input type="checkbox"
                      id={`${this.props.notificationType} email`}
                      name="set-name"
                      className="switch-input"
                      onChange={() => this.props.selectedNotification(this.props.notificationType, this.props.email, 'email')}
                      checked={this.props.email}
                      data-test="email" />
                    <label id={`${this.props.notificationType}-email`} htmlFor={`${this.props.notificationType} email`} className="switch-label"></label>
                    <span className="toggle-label visible-sm visible-xs">Email</span>
                  </span>
                </div>
                <div className="col-xs-12 col-sm-4 toggle-container">
                  <span className="toggle">
                    <input type="checkbox"
                      id={`${this.props.notificationType} sms`}
                      className="switch-input"
                      onChange={() => this.props.selectedNotification(this.props.notificationType, this.props.sms, 'sms')}
                      checked={this.props.sms}
                      data-test="sms" />
                    <label id={`${this.props.notificationType}-sms`} htmlFor={`${this.props.notificationType} sms`} className="switch-label"></label>
                    <span className="toggle-label visible-sm visible-xs">Text</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
