import * as types from './actionTypes';

export default {
  fetchTrackingShipment(trackingNumber) {
    return {
      type: types.FETCH_TRACKING_SHIPMENT_STARTED,
      payload: trackingNumber
    };
  },
  fetchTrackingShipmentFailed() {
    return {
      type: types.FETCH_TRACKING_SHIPMENT_FAILED
    };
  },
  fetchTrackingShipmentComplete(loads, shipment, loadNumbers) {
    return {
      type: types.FETCH_TRACKING_SHIPMENT_COMPLETED,
      payload: {
        loads: loads,
        fetchedShipment: shipment,
        loadNumbers: loadNumbers,
      }
    };
  },
  fetchTrackingMilestones(trackingNumber) {
    return {
      type: types.FETCH_TRACKING_MILESTONES_STARTED,
      payload: trackingNumber
    };
  },
  fetchTrackingMilestonesFailed() {
    return {
      type: types.FETCH_TRACKING_MILESTONES_FAILED
    };
  },
  fetchTrackingMilestonesComplete(payload) {
    return {
      type: types.FETCH_TRACKING_MILESTONES_COMPLETED,
      payload: payload
    };
  },
  storeRouteData(input) {
    return {
      type: types.PROCESS_ROUTEDATA_COMPLETED,
      payload: {
        routingSegments: input.routingSegments,
        pickDropPins: input.pickDropPins,
        currentLocation: input.currentLocation,
        expectedPosition: input.expectedPosition
      }
    };
  },
  trackingLogsSort(trackingLogs, sortingOptions) {
    return {
      type: types.TRACKING_MILESTONES_SORT,
      payload: {
        trackingLogs,
        sortingOptions
      }
    };
  },
  resetProps() {
    return {
      type: types.RESET_PROPS
    };
  }
};
