import React, { Component } from 'react';
import Icon from '../icon';
import queryString from 'query-string';
import {
  getIdentifierLabel,
  getIdentifierValues,
  getOrderItems,
} from '../../utils/helperMethods/orderDetailsMethods';
import bookmarkActions from '../../containers/users/bookmarks/actions';
import closeModal from '../../containers/base-modal/actions';
import baseModalActions from '../../containers/base-modal/actions';
import usersActions from '../../containers/users/actions';
import { connect } from 'react-redux';
import './extras/style.scss';
import { isExternalTrackingType, isNonNav } from '../../utils/helperMethods/commonMethods';
import EventNotificationsContainer from '../../containers/notifications/EventNotificationsContainer';
import ReferencesContainer from '../../containers/shipments/ReferencesContainer';
import * as commonMethods from '../../utils/helperMethods/commonMethods';
import { 
  ORDER_TRACKER_PATH, ExternalTrackingDetailPage, JOB_MODES,
  SHOW_ALL_REFERENCES, DISPLAY_REFS_BY_TENANT, FLEX_HIDDEN_REFERENCES,
  REFERENCE_LEVELS, DETAILS_ORDER_NUMBER_EXCLUDED_TENANTS, HIDE_ORDER_NUMBER 
} from '../../utils/constants';
import { OverlayTrigger } from 'react-bootstrap';
import { tooltip } from '../../utils/helperMethods/commonComponents';
import OrderHeaderDetails from './OrderHeaderDetails';
import * as shipmentMethods from '../../utils/helperMethods/shipmentMethods';
import { showParcels } from '../../utils/helperMethods/containerUtils';
import { shipment } from '../../containers/map/extras/icons';

export class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedQueryString: queryString.parse(this.props.location.search),
      shipmentId: decodeURIComponent(this.props.location.pathname.split('/')[2]),
    };
  }  

  componentDidMount() {

    let shipmentNumber = this.state.parsedQueryString.shipmentNumber ? this.state.parsedQueryString.shipmentNumber : this.state.parsedQueryString.loadNumber;
    const isOrders = this.props.location.pathname && this.props.location.pathname.startsWith(ORDER_TRACKER_PATH);

    if (!isOrders && this.props.pageName != ExternalTrackingDetailPage) {
      this.props.getBookmarkedShipment(this.state.shipmentId);
      this.props.fetchAlertRules(this.props.userProfile.oktaId, (commonMethods.determineShipmentNumber(this.props.shipment)), true);
    }
  }

  componentWillUnmount() {
    this.props.resetSubscribedProp();
  }

  getOrderNumber = (shipment) => {
    let orderNumber = '\u2014';
    if (shipment.order_numbers && shipment.order_numbers.length) {
      orderNumber = shipment.order_numbers.join(", ");
    } else if (["GFS", "T7", "Order"].includes(this.state.parsedQueryString.trackingType) || commonMethods.isOrionOrder(shipment)) {
      orderNumber = shipment.customer_order_number;
    }
    return orderNumber;
  };

  getShipmentNumber = (shipment) => {
    let shipmentNumber;
    if (shipment.shipment_display_identifier) {
      shipmentNumber = shipment.shipment_display_identifier
    }
    else if (isNonNav(shipment)) {
      shipmentNumber = shipment.shipment_number;
    } else {
      shipmentNumber = shipment.load_number;
    }

    return shipmentNumber
  };

  flagShipmentModal = () => {
    return {
      title: 'Flag this shipment?',
      showActionButton: true,
      showCloseButton: true,
      actionButtonText: 'Flag Shipment',
      closeButtonText: 'Cancel',
      actionButtonClass: 'btn btn-primary',
      closeButtonClass: 'btn btn-light',
      handleAction: this.bookmarkShipment,
      handleCloseAction: this.props.closeModal,
      body: (
        <React.Fragment>
          <p>This will add this shipment to your Workbench for quick access.</p>
        </React.Fragment>
      )
    };
  };

  isFlagOptionDisabled() {
    // shipment was already either flagged or delivered
    return this.isShipmentDelivered() || this.isShipmentBookmarked();
  }

  isShipmentDelivered = () => this.props.shipment.load_status === 90;
  isShipmentBookmarked = () => this.props.bookmark.length > 0;

  bookmarkShipment = () => {
    const { loads, bookmarkShipment, closeModal } = this.props;

    let body = {
      type: 'shipment',
      uri: window.location.href,
      metadata: {
        shipmentId: this.state.shipmentId,
        loadNumber: this.state.parsedQueryString.loadNumber,
        isNonNav: isNonNav(this.props.shipment),
        shipmentNumber: this.props.shipment.shipment_number,
        shipmentDisplayIdentifier: this.props.shipment.shipment_display_identifier,
        primary_identifiers: {
          display_label:
            getIdentifierLabel(loads.combinedPrimaryIdentifiers) || '\u2014',
          value:
            getIdentifierValues(
              loads.combinedPrimaryIdentifiers,
              0,
              loads.identifierLimit
            ) || '\u2014'
        }
      }
    };

    bookmarkShipment(body);
    closeModal();
  };

  notificationModal = () => {
    var isShipmentNonNav = isNonNav(this.props.shipment);
    return {
      showActionButton: false,
      showCloseButton: false,
      backdrop: false,
      size: "lg",
      body: (
        <div className="row">
          <EventNotificationsContainer
            isShipmentTracker={true}
            shipmentNumberField={isShipmentNonNav ? "shipment_number" : "load_number"}
            shipmentDisplayIdentifier={this.props.shipment_display_identifier}
            shipmentNumber={isShipmentNonNav ? (commonMethods.determineShipmentNumber(this.props.shipment)) : String(this.props.shipment.load_number)}
          />
        </div>
      )
    };
  };


  referencesModal = () => {    
    var shipmentNumber = commonMethods.determineShipmentNumber(this.props.shipment);
    var referenceLevels = [REFERENCE_LEVELS.Load];    
    
    // Add all reference level in References section
    if(SHOW_ALL_REFERENCES.includes(this.props.tenantId)){
      referenceLevels = null;
    }

    this.props.shipment.reference_numbers = this.processFlexReferences(this.props.shipment, this.props.tenantId);    
    
    return {
      title: `References for Shipment# ${shipmentNumber}`,
      showCloseButton: true,
      closeButtonClass: 'btn btn-secondary btn btn-secondary-default',
      body: (
        <div className="row">
          <ReferencesContainer
            references={this.props.shipment.reference_numbers} referenceLevel={referenceLevels}
          />
        </div>
      )
    };
  };

  getOrderHeaderLabel = (trackingType, shipment, isShipment) => {
    let orderNumberText = '\u2014';

    if ((shipment.order_numbers && shipment.order_numbers.length)
      || trackingType === 'Order' || isExternalTrackingType(trackingType) || commonMethods.isOrionOrder(shipment)) {
      orderNumberText = isShipment ? `Shipment# ${this.getShipmentNumber(shipment) || '\u2014'}`
        : `Order# ${this.getOrderNumber(shipment) || '\u2014'}`;
    }

    return (
      <h1 className="uppercase">
        {orderNumberText}
      </h1>
    )
  }

  renderFlagShipmentButton() {
    return (
      <a
        className="btn btn-outline btn-sm space-left"
        id="flagShipment"
        data-test="flagShipment"
        onClick={() =>
          this.isFlagOptionDisabled() ? '' : this.props.showModal(this.flagShipmentModal())
        }
        disabled={this.isFlagOptionDisabled()}
      >
        <Icon
          className="icon blue space-right"
          type={this.isShipmentBookmarked() ? 'check' : 'flag'}
          disabled={this.isFlagOptionDisabled()}
        />
        Flag
      </a>
    );
  }

  renderIdentifier = (identifiers, limit) => {
    const shortLimit = 2;
    const longIdentifiers = identifiers?.slice(0, limit).map(m => m.value) ?? [];
    const shortIdentifiers = getIdentifierValues(identifiers, 0, shortLimit) || '\u2014'
    
    return getIdentifierLabel(identifiers) && (
      <div>
        <div className="read-only">
          <span className="read-only-label">
            {getIdentifierLabel(identifiers) || '\u2014'}
          </span>
          <span className="read-only-value" data-test="shortIdentifiers">
            {shortIdentifiers}
          </span>
          {identifiers && identifiers.length > shortLimit &&
            <a title={getIdentifierLabel(identifiers)} data-test="longIdentifiers" className="btn-icon" onClick={() => this.props.showModal(shipmentMethods.createDynamicModalConfig(getIdentifierLabel(identifiers), longIdentifiers))}>
              <svg className="icon">
                <use href="#ellipses-horizontal" />
              </svg>
            </a>}
        </div>
      </div>
    )
  }

  renderTrackingNumber = (loads) => {
    return loads.trackingNumber && (
      <div data-test="trackingNumber">
        <div className="read-only">
          <span className="read-only-label">
            Tracking Number
          </span>
          <span className="read-only-value">
            {loads.trackingNumber}
          </span>
        </div>
      </div>
    )
  }

  renderTrailerNumber = (shipment) => {
    return shipment.trailer_number && (
      <div data-test="trailerNumber">
        <div className="read-only">
          <span className="read-only-label">
            Trailer Number
          </span>
          <span className="read-only-value">
            {shipment.trailer_number}
          </span>
        </div>
      </div>
    )
  }

  renderParcelTrackingNumber = (shipment) => {
    const limit = 2;
    const parcelTrackingNumbers = shipment.handling_units.filter(x => x.package_tracking_number).map(x => x.package_tracking_number);
    const shortParcelTrackingNumbers = parcelTrackingNumbers.slice(0, limit).join(", ");

    return (
      <div>
        <div className="read-only">
          <span className="read-only-label">
            Parcel Tracking Numbers
          </span>
          <span id="shortParcelTrackingNumbers" className="read-only-value" data-test="shortParcelTrackingNumbers">
            {shortParcelTrackingNumbers}
          </span>
          {parcelTrackingNumbers && parcelTrackingNumbers.length > limit &&
            <a id="parcelTrackingNumbers" title="ParcelTrackingNumbers" data-test="parcelTrackingNumbers" className="btn-icon" onClick={() => this.props.showModal(shipmentMethods.createDynamicModalConfig('Parcel Tracking Numbers', parcelTrackingNumbers))}>
              <svg className="icon">
                <use href="#ellipses-horizontal" />
              </svg>
            </a>}
        </div>
      </div>
    )
  }

  renderIdentificators(loads, isShipment, shipment) {
    let showIdentifier = isShipment || !DETAILS_ORDER_NUMBER_EXCLUDED_TENANTS.includes(this.props.userProfile.tenantId);
    return <React.Fragment>
      {this.renderIdentifier(loads.combinedPrimaryIdentifiers, loads.identifierLimit)}
      {this.renderIdentifier(loads.combinedSecondaryIdentifiers, loads.identifierLimit)}
      {showIdentifier && this.renderIdentifier(loads.combinedTertiaryIdentifiers, loads.identifierLimit)}
      {commonMethods.displayTrackingNumber(loads) && this.renderTrackingNumber(loads)}
      {commonMethods.displayTrailerNumber(shipment, this.props.userProfile.tenantId) && this.renderTrailerNumber(shipment)}
    </React.Fragment>
  }

  //show ref nos. for non nav, and that are included in the list of tenants with refs set 
  showReferenceModal = (shipment, tenantId) => {
     return isNonNav(shipment) || DISPLAY_REFS_BY_TENANT.includes(tenantId);
  }

  processFlexReferences = (shipment, tenantId) => {
    //US-958573 Flex: Shipment Details: References Button Remove Reference Numbers
    if (tenantId === 404 && shipment?.reference_numbers) {

      // US-958576 Rename Trade Show to Customer Defined Attribute
      if (!shipment.reference_numbers.some(item => { return item.code === "PN" })) {
        let projectNameRef = shipment.reference_numbers.find(item => { return item.code === "TS" })
          ?? shipment.reference_numbers.find(item => { return item.code === "PO" });

        if (projectNameRef) {
          projectNameRef.code = "PN";
          projectNameRef.description = "Project Name";
        } else {
          projectNameRef = { code: "PN", description: "Project Name" }
          shipment.reference_numbers.push(projectNameRef);
        }
      }

      // US- 963867 Shipment Details References Button Master Reference Numbers
      let masterRef = shipment.reference_numbers.find(item => { return item.code === "MREF" });      
      if (masterRef) {
        let mbol = shipment.reference_numbers.find(item => { return item.code === "MBOL" });        
        if (mbol) {
          masterRef.value = mbol.value;
        } else {
          masterRef.value = shipment.reference_numbers.find(item => { return item.code === "MAWB" })?.value ?? masterRef.value;          
        }
      }

      return shipment.reference_numbers.filter(item => !FLEX_HIDDEN_REFERENCES.includes(item.code));
    } else {
      return shipment.reference_numbers;
    }
  }
    
  render() {
    const { shipment, loads, showModal, location } = this.props;
    const trackingType = this.state.parsedQueryString.trackingType;
    const jobMode = location.pathname && location.pathname.startsWith(ORDER_TRACKER_PATH) ? JOB_MODES.orders : JOB_MODES.shipments;
    const historical = !shipment.vessel_imo_number && shipment.vessel_imo_number_historical;

    if (jobMode === JOB_MODES.orders) {
      const orderHeaderLabel = this.getOrderHeaderLabel(trackingType, shipment, false);
      return <React.Fragment>
        <div className="container-fluid load-header">
          {HIDE_ORDER_NUMBER.includes(this.props.userProfile.tenantId) ? '' : orderHeaderLabel}
          <div className="order-header">
            <h1 className="order-header-title" data-test="orderHeaderTitle">{'ORDER DETAILS'}</h1>
          </div>
          <div className="border-bottom"></div>
          <div className="order-header-container">
            {this.renderIdentificators(loads, false)}
          </div>
        </div>
        {loads.loadsCount <= 1 ? '' : <OrderHeaderDetails shipment={shipment} />}
      </React.Fragment>
    } else {
      const shipmentHeader = this.getOrderHeaderLabel(trackingType, shipment, true);
      return (
        <div className="container-fluid load-header">
          {shipmentHeader}
          <div className="order-header">
            <h1>{shipment.flow || '\u2014'}</h1>

            {this.props.pageName != ExternalTrackingDetailPage && <div>
              <div className="actions">
                {this.isShipmentDelivered() ?
                  <OverlayTrigger placement="top" overlay={tooltip("You cannot flag a shipment that is already delivered.")}>
                    {this.renderFlagShipmentButton()}
                  </OverlayTrigger>
                  : this.renderFlagShipmentButton()
                }
              </div>
              <div className="actions">
                <button
                  className="btn btn-outline btn-sm space-left"
                  id="eventNotification"
                  data-test="eventNotification"
                  onClick={() => showModal(this.notificationModal())}
                  disabled={this.props.subscribedShipment}
                >
                  <Icon
                    className="icon blue space-right"
                    type={this.props.subscribedShipment ? 'check' : 'envelope'}
                    disabled={this.props.subscribedShipment}
                  />
                  Notify
                </button>
                { this.showReferenceModal(this.props.shipment, this.props.tenantId) ?
                  <button
                    className="btn btn-outline btn-sm-icon-height space-left"
                    id="references"
                    onClick={() => showModal(this.referencesModal())}
                  >
                    References
                  </button>
                  : null}
              </div>
            </div>}
          </div>
          <div className="border-bottom"></div>
          <div className="order-header-container">
            {this.renderIdentificators(loads, true, shipment)}
            {showParcels(this.props.shipment) && this.renderParcelTrackingNumber(this.props.shipment)}
            {this.props.pageName == ExternalTrackingDetailPage && this.props.shipment && this.props.shipment.vision_tracking_number && (
              <div>
                <div className="read-only">
                  <span className="read-only-label">
                    Tracking Number
                  </span>
                  <span className="read-only-value" data-test="visionTrackingNumber">
                    {this.props.shipment.vision_tracking_number}
                  </span>
                </div>
              </div>
            )}
            {
              shipment.mode === 'Ocean' && (
                <>
                  <div className="read-only">
                    <span className="read-only-label">Vessel IMO{historical ? ' (Disembarked)' : ''}</span>
                    <span className="read-only-value">
                      {shipment.vessel_imo_number || shipment.vessel_imo_number_historical || `\u2014`}
                    </span>
                  </div>
                  <div className="read-only" style={{ marginRight: '0px' }}>
                    <span className="read-only-label">Vessel Name{historical ? ' (Disembarked)' : ''}</span>
                    <span className="read-only-value">
                      {shipment.vessel_name || shipment.vessel_name_historical || `\u2014`}
                    </span>
                  </div>
                </>
              )}
          </div>
          <div className="shipment-notes">
            {loads.atLeastOneLoadContainsHazMat ? (
              <div className="note">
                <Icon className="icon space-right hazmat" type="hazmat"></Icon>
                Contains Hazardous Materials
              </div>
            ) : null}
            {loads.highValueLoad ? (
              <div className="note">
                <Icon
                  className="icon space-right highValue"
                  type="high-value"
                ></Icon>
                High Value Shipment
              </div>
            ) : null}
            {loads.liveTracked && (
              <div className="note">
                <svg id="livetrackedicon" className="icon space-right">
                  <use href="#gps" />
                </svg>
                GPS Tracked
              </div>
            )}
            {loads.atLeastOneLoadContainsRestrictedMaterial && (
              <div className="note">
                <svg id="restrictedmaterialicon" className="icon space-right">
                  <use href="#restricted-material" />
                </svg>
                Contains Restricted Materials
              </div>
            )}
            {loads.expedite && (
              <div className="note">
                <svg id="expediteicon" className="icon space-right">
                  <use href="#expedite" />
                </svg>
                Expedited
              </div>
            )}
          </div>
        </div>
      );

    }
  }
}

export const mapStateToProps = (state, ownProps) => {
  const detailLevel = commonMethods.getTrackingDetailLevel(ownProps.location.pathname);
  const tenantId = ownProps.location.pathname.includes('30|') ? '30' : '0';
  const mappedLoads = getOrderItems(state[detailLevel].get('loads'), detailLevel, tenantId);
  return {
    bookmark: state.bookmarks.get('bookmark'),
    loads: mappedLoads,
    shipment: state[detailLevel].get('fetchedShipment'),
    userProfile: state.users.get('userProfile'),
    subscribedShipment: state.users.get('subscribedShipment')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    bookmarkShipment: payload => {
      return dispatch(bookmarkActions.saveBookmark(payload));
    },
    getBookmarkedShipment: id => {
      return dispatch(bookmarkActions.fetchBookmark(id));
    },
    closeModal: () => {
      return dispatch(closeModal.closeModal());
    },
    showModal: modalConfig => {
      return dispatch(baseModalActions.openModal(modalConfig));
    },
    fetchAlertRules: (oktaId, shipmentNumber, isShipmentTracker) => {
      dispatch(usersActions.fetchAlertRules(oktaId, shipmentNumber, isShipmentTracker));
    },
    resetSubscribedProp: () => {
      dispatch(usersActions.resetSubscribedProp());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
