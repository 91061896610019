'use strict'

import * as EmailValidator from 'email-validator';
import { EAPI_V2_TENANTS, ORDERS_PATH, SHIPMENTS_PATH, ORDER_TRACKER_PATH, STOP_TYPE_MAPPING, TRACKING_DETAIL_PAGE_PATH, JOB_MODES } from '../constants';
import { formatDateWithForwardSlash } from './dateTimeMethods';
import * as queryString from 'query-string';
import * as _ from 'lodash';
import { NUOLV_ENABLED_TENANTS, CLIENT_ID_EXIST_TENANTS } from '../../utils/constants';

const externalTrackingTypes = ['GFS', 'NonNav', 'T7'];

const getShipmentItemTotal = items => {
  let total = 0;
  items.forEach(x => {
    if (x.units && typeof x.units === 'number') {
      total += x.units;
    }
  });
  return total;
};

const getShipmentWithhandlingUnitsItemTotal = handlingUnits =>
  handlingUnits.reduce(
    (total, handlingUnit) =>
      total +
      handlingUnit.commodities.reduce(
        (total, c) =>
          total + (c.units && typeof c.units === 'number' ? c.units : 0),
        0
      ),
    0
  );

const toCurrency = number => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(number);
};

const getStatusClass = riskLevel => {
  let status = {
    early: 'early',
    late: 'late',
    'at risk': 'atrisk',
    'on time': 'ontime',
    'no appointment': 'noappointment'
  };

  let riskLevelValue = riskLevel || '';

  return status[riskLevelValue.toLowerCase()] || 'atrisk';
};

const isOrionOrder = shipment => {
  return (
    shipment.origination &&
    shipment.origination === 1 &&
    shipment.customer_order_number
  );
};

const isGFS = shipment => {
  return shipment.origination && shipment.origination === 3;
};

const isBayer = shipment => {
  return (
    shipment.origination &&
    shipment.origination === 4 &&
    shipment.customer_order_number
  );
};

const isNuolvnavParcel = shipment => {
  return (NUOLV_ENABLED_TENANTS.includes(shipment.tenant_id) && shipment.mode.toLowerCase() === "parcel");
};

const isNonNav = shipment => {
  return (
    shipment.origination &&
    shipment.origination === 4 &&
    !shipment.customer_order_number
  );
};

const isOcean = shipment => {
  return shipment.mode && shipment.mode === "Ocean";
};

const determineTrackingType = shipment => {
  let trackingType;
  if (isNuolvnavParcel(shipment)) {
    trackingType = 'nuolvnavparcel'
  } else if (isOrionOrder(shipment)) {
    trackingType = 'Order';
  } else if (isGFS(shipment)) {
    trackingType = 'GFS';
  } else if (isBayer(shipment)) {
    //INFO: we don't want to expose Bayer directly as this value is displayed in URLs
    trackingType = 'T7';
  } else if (isNonNav(shipment)) {
    trackingType = 'NonNav';
  } else {
    trackingType = 'Load';
  }
  return trackingType;
};

const isExternalTrackingType = trackingType => {
  return externalTrackingTypes.includes(trackingType);
};

const determineTrackingNumber = (shipment, jobMode) => {
  let trackingNumber;
  if (jobMode === JOB_MODES.orders) {
    trackingNumber = isNonNav(shipment) ? shipment.shipment_number : shipment.load_number
  } else {
    if (isOrionOrder(shipment) || isGFS(shipment) || isBayer(shipment)) {
      trackingNumber = shipment.customer_order_number;
    } else if (isNonNav(shipment)) {
      trackingNumber = shipment.tracking_number
        ? shipment.tracking_number
        : shipment.shipment_number;
    } else {
      trackingNumber = shipment.load_number;
    }
  }
  return trackingNumber;
};

const determineShipmentNumber = shipment => {
  //INFO: checking origination is more relaibe than the IsNonNav method as that also includes GFS
  if (shipment.shipment_display_identifier) {
    return shipment.shipment_display_identifier;
  } else if (shipment.origination === 4 && shipment.shipment_number) {
    return shipment.shipment_number;
  } else {
    return shipment.load_number || shipment.loadNumber;
  }
};

const getAsnValues = handlingUnits => {
  if (handlingUnits) {    
    let uniqueAsn = [];
    let result = [];
    handlingUnits.forEach((x) => {      
      if (x.commodities) {
        let ref = [];
        x.commodities.forEach((y) => ref = y.reference_numbers);
        let isAsnExist = ref?.some((z) => z.code?.toLowerCase() === "asn");
        if (isAsnExist) {
          uniqueAsn = _.uniqBy(_.filter(ref?.filter((r) => r.code?.toLowerCase() === "asn")), "value");
        }
      }
    });
    if (uniqueAsn && uniqueAsn.filter) {
      result = uniqueAsn.filter(function (val) {
        return val.value;
      }).map(function (val) {
        return val.value;
      });
    }
    return result.join(", ") ? result.join(", ") : '—';
  }
};

const determineOrderNumber = (shipment, jobMode) => {
  if (jobMode === JOB_MODES.orders) {
    if (shipment.order_numbers && shipment.order_numbers.length === 1 && shipment.order_numbers[0] != null) {
      return shipment.order_numbers[0];
    } else {
      let splitId = shipment.id?.split('|');
      let tenantIdPrependedId = splitId && !Number.isNaN(Number.parseInt(splitId[0]));
      let orderNumberFromId = null;
      if (tenantIdPrependedId && splitId?.length === 4) {
        orderNumberFromId = splitId[3];
      } else if (splitId?.length === 3) {
        orderNumberFromId = splitId[2];
      }
      return orderNumberFromId;
    }
  } else {
    return shipment.customer_order_number;
  }
};

const isInputEmpty = inputValue => {
  let value = inputValue.trim();
  return value.length === 0;
};

const isInvalidEmail = inputValue => {
  return !EmailValidator.validate(inputValue);
};

const isValidSso = inputValue => {
  const ssoValidator = /^.*\..*@(chrobinson\.com|mytmc\.com|microsoft\.com)$/i;

  return ssoValidator.test(inputValue);
};

const toKebabCase = string => {
  if (string && typeof (string) == "string") {
    let x = string.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
    if (x && x.length > 0) {
      return x.map(x => x.toLowerCase()).join('-');
    }
  } else {
    return null;
  }
};

const getFirstValidInteger = (...args) => {
  for (let i = 0; i < args.length; i++) {
    let integer = Number.parseInt(args[i], 10);
    if (integer != null && !Number.isNaN(integer)) {
      return integer;
    }
  }
  return undefined;
};

const multiSearchMapping = [
  {
    elasticValue: 'customer_order_number',
    displayLabel: 'Order Number'
  },
  {
    elasticValue: 'load_number',
    displayLabel: 'Shipment Number'
  },
  {
    elasticValue: 'tracking_number',
    displayLabel: 'Tracking Number'
  },
  {
    elasticValue: 'items.purchase_order_number',
    displayLabel: 'PO Number'
  },
  {
    elasticValue: 'items.delivery_order_number',
    displayLabel: 'DO Number'
  },
  {
    elasticValue: 'items.sku',
    displayLabel: 'SKU'
  },
  {
    elasticValue: 'handling_units.container_number',
    displayLabel: 'Container Number'
  }
];

const alternateMultiSearchMapping = [
  {
    elasticValue: 'order_numbers',
    displayLabel: 'Order Number'
  },
  {
    elasticValue: 'shipment_number',
    displayLabel: 'Shipment Number'
  },
  {
    elasticValue: 'tracking_number',
    displayLabel: 'Tracking Number'
  },
  {
    elasticValue: 'items.purchase_order_number',
    displayLabel: 'PO Number'
  },
  {
    elasticValue: 'items.delivery_order_number',
    displayLabel: 'DO Number'
  },
  {
    elasticValue: 'items.sku',
    displayLabel: 'SKU'
  },
  {
    elasticValue: 'handling_units.container_number',
    displayLabel: 'Container Number'
  }
];

const mapDateFilterField = {
  calculated_ETA: 'ETA',
  destination_requested: 'RDD',
  destination_Sched_date_open: 'D_SCH',
  origin_requested: 'ROD',
  origin_Sched_date_open: 'O_SCH'
};

const statusColorMapping = {
  Early: '#2E99E0',
  'On Time': '#7EA122',
  'At Risk': '#D5B100',
  Late: '#BA4B4D'
};

const strokeWidthMapping = {
  Early: 3,
  'On Time': 2.5,
  Late: 2
};

const shipmentNumberClientIdSplit = (shipmentNumber, tenantId) => {
  if (shipmentNumber.includes('~') && CLIENT_ID_EXIST_TENANTS.includes(tenantId)) {
    return shipmentNumber.split('~')[0];
  } else { return shipmentNumber; }
}

const groupSortedTrackingLogsByDate = (trackingLogs) => {
  //tracking logs must be sorted before calling
  let logDates = [];
  let groupedLogItems = [];
  if (trackingLogs && trackingLogs.length) {
    trackingLogs.forEach((logItem) => {
      // assuming date string follows pattern of YYYY-MM-DD T HH:MM:SS
      if (logItem.eventDateTime) {
        let dateParts = logItem.eventDateTime.split('T');
        if (logDates.indexOf(dateParts[0]) < 0) {
          logDates.push(dateParts[0]);
          //mark the tracking log to show this date as a group header
          groupedLogItems.push({
            ...logItem,
            isDateGroupLabel: true,
            eventDate: dateParts[0]
          });
        } else {
          groupedLogItems.push({
            ...logItem,
            isDateGroupLabel: false,
            eventDate: dateParts[0]
          });
        }
      }else if(!logItem.eventDateTime && logItem.eventDateTimeUtc){        
        let dateParts = logItem.eventDateTimeUtc.split('T');        
        if (logDates.indexOf(dateParts[0]) < 0) {
          logDates.push(dateParts[0]);
          //mark the tracking log to show this date as a group header
          groupedLogItems.push({
            ...logItem,
            isDateGroupLabel: true,
            eventDate: dateParts[0]
          });
        } else {
          groupedLogItems.push({
            ...logItem,
            isDateGroupLabel: false,
            eventDate: dateParts[0]
          });
        }
      } 
      else {
        groupedLogItems.push({
          ...logItem,
          isDateGroupLabel: false,
          eventDate: null
        });
      }
    });
  }
  return groupedLogItems;
}

const generatePillsFromFilters = (activeFilters, facilityFilters, searchText, multiSearchType, dateFilter, clusterSelected, tenantId) => {
  const pills = [];

  if (activeFilters) {
    const keys = Object.keys(activeFilters).filter(k => activeFilters.hasOwnProperty(k))
    keys.forEach(key => {
      if (activeFilters[key] && activeFilters[key].map) {
        activeFilters[key].map((filter, i) => {
          pills.push({ filter: key, label: filter });
        });
      }
    });
  }

  if (facilityFilters && facilityFilters.length) {
    facilityFilters.forEach(ff => {
      pills.push({ filter: 'facilityFilters', label: `${ff.facilityName} : ${ff.parsedFacilityStatus}` });
    });
  }

  if (searchText) {
    const assignedMapping = EAPI_V2_TENANTS.includes(tenantId) ? alternateMultiSearchMapping : multiSearchMapping;
    const multiSearchLabel = assignedMapping.filter(x => x.elasticValue === multiSearchType);

    pills.push({
      filter: "searchText",
      label: multiSearchType ? (multiSearchLabel[0].displayLabel + ': ' + searchText) : searchText
    });
  }

  if (!!dateFilter) {
    const dateFilterLabel = mapDateFilterField[dateFilter.field[0]];
    pills.push({
      filter: "dateFilter",
      label: `${dateFilterLabel}: ${formatDateWithForwardSlash(dateFilter.startDate)} to ${formatDateWithForwardSlash(dateFilter.endDate)}`
    });
  }

  if (clusterSelected) {
    pills.push({
      filter: 'mapCluster',
      label: 'Map Cluster'
    });
  }

  return pills;
}

const navigateToViewPath = (location, history, queryParams, userRole) => {
  if (!history || !history.push) {
    return;
  }

  //default to shipments path if navigating from a path that isn't the orders view
  //however, if a user is an ExternalUser, always navigate to the orders view
  let loc = SHIPMENTS_PATH;
  if (location === ORDERS_PATH || userRole === "ExternalUser") {
    loc = ORDERS_PATH;
  }

  let queryParamString = '';
  if (queryParams) {
    queryParamString += '?' + queryString.stringify(queryParams);
  }

  if (history.location?.pathname + history.location?.search !== loc + queryParamString) {
    history.push(loc + queryParamString);
  }
}

const getShipmentDetailLevel = (location) => {
  if (location === ORDERS_PATH || (location && location.startsWith(`${ORDER_TRACKER_PATH}/`))) {
    return JOB_MODES.orders;
  } else {
    return JOB_MODES.shipments;
  }
}

const getTrackingDetailLevel = (location) => {
  if (location === ORDERS_PATH || (location && location.startsWith(`${ORDER_TRACKER_PATH}/`))) {
    return 'orderTrackingSummary';
  } else if (location && location.startsWith(`${TRACKING_DETAIL_PAGE_PATH}/`)) {
    return 'externalTracker'
  } else {
    return 'trackingSummary';
  }
}

const camelCase = (str) => {
  if (/\s/.test(str)) {
    str = str.split(/(?=[A-Z])/).join(" ");
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }
  return str.toLowerCase();
};

const filterTrackingLogs = (trackingLogs) => {
  return _.uniqWith(trackingLogs, function (obj1, obj2) {
    return obj1.notes === obj2.notes
      && obj1.loadNumber === obj2.loadNumber
      && obj1.locationFormatted === obj2.locationFormatted
      && obj1.eventDateTime === obj2.eventDateTime
      && obj1.containerNumber === obj2.containerNumber
      && obj1.trackingNumber === obj2.trackingNumber;
  });
}

const sortTrackingLogs = (trackingLogs, sortingOptions) => {
  const sortByGetter = sortingOptions.column === 'UpdatedOn'
    ? trackingLog => new Date(trackingLog.updatedOn)
    : trackingLog => new Date(trackingLog.eventDateTime);

  return sortingOptions.isAscending
    ? trackingLogs.sort((trackingLog1, trackingLog2) => sortByGetter(trackingLog1) - sortByGetter(trackingLog2))
    : trackingLogs.sort((trackingLog1, trackingLog2) => sortByGetter(trackingLog2) - sortByGetter(trackingLog1));
}

const addStopTextToStops = (stops) => {
  let stopTypeMappingKeys = Object.keys(STOP_TYPE_MAPPING);
  let stopTypeMapping = {};
  stopTypeMappingKeys.forEach(key => {
    stopTypeMapping[key] = { name: STOP_TYPE_MAPPING[key], count: 0 };
  });

  const orderedStops = stops.sort((stopA, stopB) => stopA.stopNumber - stopB.stopNumber);

  const mapped = orderedStops.map((stop) => {
    let pickDropText = stopTypeMapping[stop.stopType] && stopTypeMapping[stop.stopType].name;
    if (pickDropText != null) {
      stopTypeMapping[stop.stopType].count++;
      if (stopTypeMapping[stop.stopType].count > 1) {
        pickDropText += ` ${stopTypeMapping[stop.stopType].count}`;
      }
    } else {
      stopTypeMapping.UnmappedStops.count++;
      if (stopTypeMapping.UnmappedStops.count > 1) {
        pickDropText += ` ${stopTypeMapping.UnmappedStops.count}`;
      }
    }
    return Object.assign({}, stop, {
      stopCountText: pickDropText
    });
  });
  return mapped;
}

const getUniqueIdentifiers = identifiers => {
  if (!identifiers)
    return identifiers;

  let containedValues = {}; const result = [];
  identifiers.forEach(item => {
    if (!containedValues[item.value])
      result.push(item);
    containedValues[item.value] = true;
  });
  return result;
}

const displayTrackingNumber = (loads) => {
  if (loads.combinedPrimaryIdentifiers && loads.combinedSecondaryIdentifiers && loads.combinedTertiaryIdentifiers) {
    let combinedIdentifiers = [...loads.combinedPrimaryIdentifiers, ...loads.combinedSecondaryIdentifiers, ...loads.combinedTertiaryIdentifiers];
    return !combinedIdentifiers.some(x => x.display_label === 'Tracking Number');
  }
  return true;
};

const displayTrailerNumber = (shipment, tenantId) => {
  // only for oshkosh
  if (tenantId !== 48 || !shipment) {
    return false;
  }

  return !!shipment.trailer_number;
};

const containsRestrictedMaterial = (items, referenceNumbers) => {
  if (items) {
    return items.some(x => x.custom_item_field1 === 'Restricted');
  } else if (referenceNumbers) {
    return referenceNumbers.some(x => x.code == "IREF" && x.value?.replace(/\s/g, '') == "NC-RestrictedShipment");
  }
};

const cubicFeetToCubicMeters = (volume, unit, asString = true) => {
  let w = typeof(volume) == 'string' ? Number.parseFloat(volume) : typeof(volume) == 'number' ? volume : null;
  if (w == null || Number.isNaN(w)) {
    return null;
  }

  if (['ft3', 'cubicfeet', 'cubic feet'].includes(unit?.toLowerCase())) {
    w = w * 0.0283168;
  }
  return asString ? w?.toFixed(2): w;
}

const feetToMeters = (length, unit, asString = true) => {
  let w = typeof(length) == 'string' ? Number.parseFloat(length) : typeof(length) == 'number' ? length : null;
  if (w == null || Number.isNaN(w)) {
    return null;
  }

  if (['ft', 'feet'].includes(unit?.toLowerCase())) {
    w = w * 0.3048;
  }
  return asString ? w?.toFixed(2): w;
}

const lbsToKgs = (weight, unit, asString = true) => {
  let w = typeof(weight) == 'string' ? Number.parseFloat(weight) : typeof(weight) == 'number' ? weight : null;
  if (w == null || Number.isNaN(w)) {
    return null;
  }

  if (!Number.isNaN(w) && (['lb', 'lbs', 'pounds'].includes(unit?.toLowerCase()))) {
    w = w * 0.453592;
  }
  return asString ? w?.toFixed(2): w;
}

export {
  getShipmentItemTotal,
  getShipmentWithhandlingUnitsItemTotal,
  determineTrackingType,
  isExternalTrackingType,
  determineTrackingNumber,
  determineShipmentNumber,
  determineOrderNumber,
  getStatusClass,
  shipmentNumberClientIdSplit,
  groupSortedTrackingLogsByDate,
  toCurrency,
  isInputEmpty,
  isInvalidEmail,
  isValidSso,
  addStopTextToStops,
  multiSearchMapping,
  alternateMultiSearchMapping,
  mapDateFilterField,
  statusColorMapping,
  strokeWidthMapping,
  isGFS,
  isNonNav,
  isOcean,
  isBayer,
  toKebabCase,
  getFirstValidInteger,
  generatePillsFromFilters,
  navigateToViewPath,
  getShipmentDetailLevel,
  getTrackingDetailLevel,
  camelCase,
  filterTrackingLogs,
  sortTrackingLogs,
  isOrionOrder,
  getUniqueIdentifiers,
  displayTrackingNumber,
  displayTrailerNumber,
  containsRestrictedMaterial,
  cubicFeetToCubicMeters,
  feetToMeters,
  lbsToKgs,
  getAsnValues
};
