import * as types from './actionTypes';

export default {
  configureSocket(config, accessToken) {
    return {
      type: types.CONFIGURE_SOCKET_STARTED,
      payload: {
        config, accessToken
      }
    };
  },
  configureSocketCompleted(socketClient) {
    return {
      type: types.CONFIGURE_SOCKET_COMPLETED,
      payload: {
        socketClient
      }
    };
  },
  configureSocketFailed() {
    return {
      type: types.CONFIGURE_SOCKET_FAILED
    };
  }
};
