import React from 'react';
import { CAPITALIZED_JOB_MODES } from '../../utils/constants';
import Icon from '../icon'

class ClustersPopover extends React.Component {
  render() {
    return (
      <div className="map-popover bubble">
        <a id="closeInfobox" > <Icon className="icon close" type="close" /></a>
        <div className="map-popover-header">
            <div className="read-only-label">{this.props.count} {CAPITALIZED_JOB_MODES[this.props.jobMode]} at this Location</div>
        </div>
        <hr />
        <div className="map-popover-footer">
          <button id="viewClustersButton" className="btn btn-primary">View {CAPITALIZED_JOB_MODES[this.props.jobMode]}</button>
        </div>
      </div>
    );
  }
}

export default ClustersPopover;
