import React from 'react';
import { AuthContainer } from '../../containers/auth';
import Button from '../button';
import Dropdown from './Dropdown';
import Icon from '../icon';
import ProfileImg from '../profile-img';

export function UserDropdownComponent(props) {
  const showUser = props.user ? {} : {display: "none"};
  const anchor = (
    <div className="anchor">
    { props.userProfile &&
        <ProfileImg id={'user-profile-image'} picture={props.userProfile.picture} size="25px"/>
      }
      { !props.userProfile &&
        <Icon type="user"/>
      }
    </div>
  );

  const items = (
    <ul className={props.type === 'dropdown' ? "dropdown-menu dropdown-menu-right" : "navbar-content"}>
      {props.user &&
        <div className={props.type === 'dropdown' ? "dropdown-header" : ''}>
        <h4 className={props.type === 'dropdown' ? '' : 'user-dropdown-name-mobile'}>{props.userProfile && props.userProfile.user_metadata ? props.userProfile.user_metadata.name : props.user.name }</h4>
          <p className={props.type === 'dropdown' ? '' : 'user-dropdown-mail-mobile'}>{props.user.email}</p>
        </div>
      }
      <li role="separator" className="divider" style={showUser}></li>
      <div className="dropdown-footer">
        {props.user &&
          <Button id={'logout-button'} onClick={() => props.logout(props.adobeTrack)} text="Log out"/>
        } { !props.user &&
          <Button id={'login-button'} onClick={props.login} text="Log in"/>
        }
      </div>
    </ul>
  );
  return(
    <Dropdown items={items} anchor={anchor} type={props.type} showTray={props.showTray}/>
  );
}

export default AuthContainer(UserDropdownComponent);
