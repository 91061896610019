import React, { Component } from 'react';
import Icon from '../icon';
export class FacilityPopover extends Component {
  render() {
    return (
      <div className="map-popover bubble large">
        <div>
          <a id="closeInfobox" > <Icon className="icon close" type="close" /></a>
          {this.props.facility.facilityKey}
        </div>
        <div className="bubble-content bubble-content-row">
          <button className="info-button" id='atFacility'>
            <div className="bubble-data"> At Facility <div> {this.props.facilityShipments.atFacility.hits.total || 0} </div></div>
          </button>
          <button className="info-button" id='arrivingFacility'>
            <div className="bubble-data"> Inbound <div> {this.props.facilityShipments.arrivingFacility.hits.total || 0} </div></div>
          </button>
          <button className="info-button" id='departingFacility'>
            <div className="bubble-data"> Outbound <div> {this.props.facilityShipments.departingFacility.hits.total || 0} </div></div>
          </button>
        </div>
      </div>
    );
  }
}

export default FacilityPopover;
