import React from 'react'
import Footer from '../../../components/footer';
import UserContainer from '../../../containers/users/UserContainer';
import queryString from 'query-string';
import { useChrLdFlag } from '@chr/common-launchdarkly';

export default function User(props) {
  const parsedString = queryString.parse(props.location.search);
  const tenantId = parseInt(parsedString.tenantId);
  const isUsingCloudDataSources = useChrLdFlag(`vision_use_cloud_data_sources_tenant_${tenantId}`);

  props = {...props, isUsingCloudDataSources}

  return (
    <div data-test='userPage'>
      <UserContainer {...props} />
    </div>
  );
}
