import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AreaGraph from '../../components/area-graph';
import ScatterGraph from '../../components/scatter-graph';
import actions from '../../containers/iot/actions';
import queryString from 'query-string';
import './extras/styles.scss';

const ToolTipContent = ({ label, payload }) => {
  if (!payload.length) { return; };
  const elements = [];
  payload.forEach((data) => {
    const uom= data.name === 'shock' ? 'g' : 'mbar';
    const formattedCurrentValue = data && data.value ? data.value.toLocaleString('en-US') : '';
    elements.push(<div className="iot-shock-pressure-tooltip-values" key={data.name}><p>{data.name}</p>: {`${formattedCurrentValue} ${uom || ''}`}</div>);
  });

  return (
    <div className="iot-shock-pressure-tooltip">
      <p className="iot-shock-pressure-tooltip-label">{label}</p>
      <div>{elements}</div>
    </div>
  )
}

export class IoTAnomalyTracking extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: {
        TEMPERATURE: 'active',
        HUMIDITY: '',
        TILT: '',
        LIGHT: ''
      }
    };
    this.isActive = this.isActive.bind(this);
  }

  componentDidMount() {
    const parsedQueryString = queryString.parse(this.props.location.search);
    this.props.getIotAverages(parsedQueryString.loadNumber);
  }

  isActive(selectedTab) {
    var tabsState = {};

    _.forEach(this.state.activeTab, (value, key) => {
      if (key === selectedTab) {
        tabsState[key] = 'active';
      } else {
        tabsState[key] = '';
      }
    });

    this.setState({
      activeTab: {
        TEMPERATURE: tabsState.TEMPERATURE,
        HUMIDITY: tabsState.HUMIDITY,
        TILT: tabsState.TILT,
        LIGHT: tabsState.LIGHT
      }
    });
  }

  getActiveTab() {
    const { activeTab } = this.state;

    return Object.keys(activeTab).filter(tab =>  activeTab[tab] === 'active')[0];
  }

  mapShockAndPressureData() {
    const { sensorData } = this.props;
    if (!sensorData.length) {
      return [];
    }
    const mappedData = [];
    const shockPressureData = this.props.sensorData
      .reduce((a,b) => { return a.concat(b);  })
      .filter(data => ['SHOCK', 'PRESSURE'].indexOf(data.sensorType) > -1 )
      .sort(this._sortByFullDate);

    shockPressureData.forEach((data) => {
      data[data.sensorType.toLowerCase()] = Math.round(data.averageValue);
      const dateExistsInMappedData = mappedData.filter(shockPressureEntry => shockPressureEntry.date === data.date);
      if (dateExistsInMappedData.length) {
        const mappedDataIndex = mappedData.findIndex(x => x.date === data.date);
        mappedData[mappedDataIndex][data.sensorType.toLowerCase()] = Math.round(data.averageValue);
      } else {
        mappedData.push(data);
      }
    })

    return mappedData;
  }

  _sortByFullDate( a, b ) {
    if (new Date(a.fullDate) < new Date(b.fullDate) ){
      return -1;
    }
    if (new Date(a.fullDate) > new Date(b.fullDate) ){
      return 1;
    }
    return 0;
  }

  mapAnomalyData() {
    const { sensorData } = this.props;
    let mappedData = [];
    const activeTab = this.getActiveTab();

    if (!sensorData.length){
      return mappedData;
    }


    switch (activeTab) {
      case 'TEMPERATURE':
        mappedData = sensorData
          .reduce((a,b) => { return a.concat(b);  })
          .filter(data => data.sensorType === 'TEMPERATURE')
          .sort(this._sortByFullDate);
        break;
      case 'HUMIDITY':
        mappedData = sensorData
          .reduce((a,b) => { return a.concat(b);  })
          .filter(data => data.sensorType === 'HUMIDITY')
          .sort(this._sortByFullDate);
        break;
      case 'TILT':
        mappedData = sensorData
          .reduce((a,b) => { return a.concat(b);  })
          .filter(data => data.sensorType === 'TILT')
          .sort(this._sortByFullDate);
        break;
      case 'LIGHT':
        mappedData = sensorData
          .reduce((a,b) => { return a.concat(b);  })
          .filter(data => data.sensorType === 'LIGHT')
          .sort(this._sortByFullDate);
        break;
    }

    mappedData.forEach((data) => {
      data.averageValue = Math.round(data.averageValue);
      data.maxValue = Math.round(data.maxValue);
      data.minValue = Math.round(data.minValue);
    });

    return mappedData;
  }

  getMaxThreshold () {
    const { sensorData } = this.props;
    let data = [];

    if (!sensorData.length){
      return data;
    }

    data = this.reduceSensorDataByActiveType();
    return data.length ? data[data.length - 1].maxThreshold : 0;
  }

  getMinThreshold () {
    const { sensorData } = this.props;
    let data = [];

    if (!sensorData.length){
      return data;
    }

    data = this.reduceSensorDataByActiveType();
    return data.length ? data[data.length - 1].minThreshold : 0;
  }

  getUnitOfMeasure () {
    const { sensorData } = this.props;
    const activeTab = this.getActiveTab();
    if (sensorData.length) {
      const activeSensorType = sensorData[0].filter((metric) => { return metric.sensorType === activeTab; });
      return activeSensorType[0] ? activeSensorType[0].uom : undefined;
    }
    return '';
  }

  reduceSensorDataByActiveType () {
    const { sensorData } = this.props;
    const activeTab = this.getActiveTab();

    switch (activeTab) {
      case 'TEMPERATURE':
        return sensorData.reduce((a,b) => { return a.concat(b);  }).filter(data => data.sensorType === 'TEMPERATURE');
      case 'HUMIDITY':
        return sensorData.reduce((a,b) => { return a.concat(b);  }).filter(data => data.sensorType === 'HUMIDITY');
      case 'TILT':
        return sensorData.reduce((a,b) => { return a.concat(b);  }).filter(data => data.sensorType === 'TILT');
      case 'LIGHT':
        return sensorData.reduce((a,b) => { return a.concat(b);  }).filter(data => data.sensorType === 'LIGHT');
    }
  }

  render() {
    const { loadingSensorData } = this.props;

    return (
      <div style={{display: 'inline-block'}}>
        <div className="col-lg-12 iot-tracking-header">
          <div className="col-lg-7">
            <p>IOT Threshold</p>
          </div>
          <div className="col-lg-5">
            <p>Shock &amp; Pressure</p>
          </div>
          <div className="col-lg-12 iot-graph-tabs">
            <ul id="iotAnomalySelector" className="nav graph-tabs">
              <li className={this.state.activeTab.TEMPERATURE}><a data-toggle="tab" onClick={() => { return this.isActive('TEMPERATURE'); }}>Temp</a></li>
              <li className={this.state.activeTab.HUMIDITY}><a data-toggle="tab" onClick={() => { return this.isActive('HUMIDITY'); }}>Humidity</a></li>
              <li className={this.state.activeTab.TILT}><a data-toggle="tab" onClick={() => { return this.isActive('TILT'); }}>Tilt</a></li>
              <li className={this.state.activeTab.LIGHT}><a data-toggle="tab" onClick={() => { return this.isActive('LIGHT'); }}>Light</a></li>
            </ul>
            <ul className="col-lg-12 iot-threshold-legend">
              <li><span className="iot-shock-color"></span><p>Shock</p></li>
              <li><span className="iot-pressure-color"></span><p>Pressure</p></li>
            </ul>
          </div>
        </div>

       <div className="col-lg-7 iot-scatter-graph">
       { loadingSensorData ? <div></div> :
          <ScatterGraph
            series={this.mapAnomalyData()}
            seriesType={this.getActiveTab()}
            maxThreshold={this.getMaxThreshold()}
            minThreshold={this.getMinThreshold()}
            unitOfMeasure={this.getUnitOfMeasure()}
            height={200}
            width={"100%"}
          />
       }
        </div>
        <div className="col-lg-5 iot-line-graph">
        { loadingSensorData ? <div></div> :
          <AreaGraph
            allowDuplicatedCategory={false}
            areaStrokeColor={'#8884d8'}
            dataKey={"date"}
            height={200}
            isEmptyText={"No Shock or Pressure data available."}
            lineType={'linear'}
            setFill={val => val === 'pressure' ? '#337ab7' : '#2E99E0'}
            series={this.mapShockAndPressureData()}
            seriesDataSelector={['shock', 'pressure']}
            toolTipContent={ToolTipContent}
            width={100}
          />
        }
        </div>
      </div>
    );
  }
};

export const mapStateToProps = (state) => {
  return {
    sensorData: state.iotSummary.get('iotAverages'),
    loadingSensorData: state.iotSummary.get('averagesLoading')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getIotAverages: (trackingNumber) => {
      return dispatch(actions.fetchIotAverages(trackingNumber));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IoTAnomalyTracking);
