import { put, call, all, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchTenantDynamicFiltersStart() {
  try {
    const dynamicFilters = yield call(api.metadata.fetchDynamicFilters);
    yield put(actions.fetchTenantDynamicFiltersComplete(dynamicFilters.data.mappings));
  }
  catch (e) {
    yield all([
      put(actions.fetchTenantDynamicFiltersFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Fetch Filters Error',
        message: 'Couldn\'t fetch your filters.  Please try again.',
        error: e
      }))
    ]);
  }
}

export function* watchTenantDynamicFiltersStart() {
  yield takeEvery(types.FETCH_TENANT_DYNAMIC_FILTERS_STARTED, fetchTenantDynamicFiltersStart);
}
