import Orders from './Orders';
import { LayoutHOC } from '../../components/layout';
import {OrdersPageRBAC} from './rbac';

const OrdersPage = LayoutHOC(Orders);
OrdersPage._RBAC = OrdersPageRBAC;

export {
  Orders,
  OrdersPage
};
