import React from 'react';
import Icon from '../icon';
import { SearchField } from '../../components/search';
import { MobileToggleButton } from '../../containers/chr-side-nav';
import logo from '../../containers/chr-side-nav/extras/navisphere-vision-w.svg';
import { UserDropdown } from '../dropdown';
import {AlertsDropdownContainer as AlertsDropdown} from '../../containers/notifications/AlertsDropdownContainer';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: false,
      collaborations: false,
      search: false,
      user: false
    };
  }

  closeAll = () => {
    for(let key in this.state) {
      if(this.state[key] === true) {
        this.setState({
          [key]: false
        });
      }
    }
    document.removeEventListener("click", this.closeAll);
  }

  toggle = (tray) => {
    this.closeAll();
    this.setState({
      [tray]: !this.state[tray]
    }, document.addEventListener("click", this.closeAll));
  }

  render() {
    return (
      <div className="header-container" >
        <div className="container-fluid">
          <nav>
            <div className="row flex align-middle">
              <MobileToggleButton />
              <div className="col-xs-2 col-sm-2 col-md-6">
                <a className="hidden-xs hidden-sm" href="#" title="Navisphere Vision Home Page" aria-label="Navisphere Vision Home Page">
                  <img src={logo}/>
                </a>
                <a className="hidden-md hidden-lg" href="#" title="Navisphere Vision Home Page" aria-label="Navisphere Vision Home Page">
                  <Icon className='icon large logo' type='navisphere'/>
                </a>
              </div>
              <div className="col-xs-4 col-sm-6">
                <li id="search" className="dropdown hidden-md hidden-lg" style={{  bottom: "10px" }}>
                  <SearchField label={"Search"} placeholder={"Search Shipments"} className={"global-search"} id={"search-main"} history={this.props.history} location={this.props.location}/>
                </li>
                <li className="dropdown hidden-xs hidden-sm" style={{  bottom: "10px" }} id={'search-container'}>
                  <SearchField label={"Search"} placeholder={"Search Shipments"} className={"global-search"} id={"search-main"} history={this.props.history} location={this.props.location}/>
                </li>
              </div>
              <div className="col-xs-6 col-sm-4 col-md-4">
                <ul className={this.props.expanded ? "utility-nav hidden-xs hidden-sm" : "utility-nav"}>
                  {this.props.userRole !== 'ExternalUser' && this.props.userRole != null &&
                    <li className="utility-nav-item dropdown hidden-xs hidden-sm">
                      <AlertsDropdown type="dropdown" history={this.props.history}/>
                    </li>
                  }
                  <li className="utility-nav-item dropdown hidden-xs hidden-sm">
                    <UserDropdown type="dropdown" adobeTrack={this.props.adobeTrack}/>
                  </li>
                  {this.props.userRole !== 'ExternalUser' && this.props.userRole != null &&
                    <li id="alerts" className="utility-nav-item dropdown hidden-md hidden-lg" onClick={() => { this.toggle('alerts'); }}>
                      <AlertsDropdown type="icon" history={this.props.history}/>
                    </li>
                  }
                  <li id="user" className="utility-nav-item dropdown hidden-md hidden-lg" onClick={() => { this.toggle('user'); }}>
                    <UserDropdown type="icon" adobeTrack={this.props.adobeTrack}/>
                  </li>
                </ul>
              </div>
            </div>
            {this.props.userRole !== 'ExternalUser' && this.props.userRole != null &&
              <AlertsDropdown type="tray" showTray={this.state.alerts} history={this.props.history}/>
            }
            <UserDropdown type="tray" showTray={this.state.user}/>
          </nav>
        </div>
      </div>
    );
  }
}

export default Header;
