import clientFactory from './clientFactory';
import { getConfiguration } from '@chr/common-web-ui-configuration';
import { getUserProfile } from '../containers/auth/authHelper';

export default {
  fetchIotMapLogs: async function (payload) {
    const trackingNumber = payload.trackingNumber;
    const config = await getConfiguration();
    const userProfile = await getUserProfile();

    const endpoint = config.iotMetricsApi;
    try {
      const request = await clientFactory(endpoint);
      const requested = await request.get(`iot/${userProfile.tenantId}/${trackingNumber}/shipmentMapSensorMetrics`);
      return requested;
    } catch (err) {
      throw 'Error retrieving IOT Map logs: ' + err;
    }
  },

  fetchIotDetailRecords: async function (payload) {
    const trackingNumber = payload.trackingNumber;
    const config = await getConfiguration();
    const userProfile = await getUserProfile();

    const endpoint = config.iotMetricsApi;
    const params = {
      bucketBy: '1d',
      pageItemCount: payload.limit || '20',
      pageNumber: payload.pageNumber || '1'
    };
    try {
      const request = await clientFactory(endpoint);
      const requested = await request.get(`iot/${userProfile.tenantId}/${trackingNumber}/iotDetailRecords`,{params});
      return requested;
    } catch (err) {
      throw 'Error retrieving IOT logs: ' + err;
    }
  },

  fetchIotAverages: async function (payload) {
    const trackingNumber = payload.trackingNumber;
    const config = await getConfiguration();
    const userProfile = await getUserProfile();

    const endpoint = config.iotMetricsApi;
    const params = {
      bucketBy: '1d'
    };
    try {
      const request = await clientFactory(endpoint);
      const requested = await request.get(`iot/${userProfile.tenantId}/${trackingNumber}/averagedShipmentSensorMetricsByDay`,{params});
      return requested;
    } catch (err) {
      throw 'Error retrieving IOT averages: ' + err;
    }
  },

  fetchIotSensorLocations: async function (payload) {
    const trackingNumber = payload.trackingNumber;
    const visibleAreaBounds = payload.visibleAreaBounds;
    const config = await getConfiguration();
    const userProfile = await getUserProfile();

    const endpoint = config.iotMetricsApi;
    try {
      const request = await clientFactory(endpoint);
      const requested = await request.post(`iot/${userProfile.tenantId}/${trackingNumber}/sensorLocationData`,{visibleAreaBounds});
      return requested;
    } catch (err) {
      throw 'Error retrieving IOT sensor location data: ' + err;
    }
  },

  fetchActiveIotShipments: async function ({ limit, afterKey }) {
    const config = await getConfiguration();
    const userProfile = await getUserProfile();

    const endpoint = config.iotMetricsApi;
    const params = {
      pageItemCount: limit || '20',
      afterKey: afterKey || '0'
    };

    try {
      const request = await clientFactory(endpoint);
      const requested = await request.get(`iot/${userProfile.tenantId}/activeIotShipments`, {params});
      return requested;
    } catch (err) {
      throw 'Error retrieving Active IOT Tracked Shipments: ' + err;
    }
  }
};
