import React from 'react';
import { connect } from 'react-redux';
import AlertsDropdown from '../../components/dropdown/AlertsDropdown';
import notificationActions from '../../containers/notifications/actions';

export const mapStateToProps = (state)=>{
  return {
    hasNewAlert: state.notifications.get('hasNewAlerts')
  };
};
export const mapDispatchToProps = (dispatch)=>{
  return {
    viewedNotifications:()=>{
      dispatch(notificationActions.viewedNotifications());
    }
  }
};
export const AlertsDropdownContainer = connect(mapStateToProps, mapDispatchToProps)(AlertsDropdown);
