import * as actionTypes from './actionTypes';
import actions from './actions';
import globalErrorActions from '../global-success-error-alerts/actions';
import exportingApi from '../../apis/exporting';
import { put, call, all, takeEvery } from 'redux-saga/effects';
import FileSaver from 'file-saver';

export function* watchGetExportDownloadStart() {
  yield takeEvery(actionTypes.GET_EXPORT_DOWNLOAD_STARTED, getDownloadStart);
}

export function* getDownloadStart({payload}) {
  try {
    const data = yield call(exportingApi.downloadExport, payload.exportType, payload.fileName);
    const fileBlob = new Blob([data], {type: "text/csv;charset=utf-8"});
    FileSaver.saveAs(fileBlob,`${payload.fileName}.${payload.userEmail}.CSV`)
    yield put(actions.getExportDownloadCompleted());
  } catch (e) {
    yield all([
      put(actions.getExportDownloadFailed()),
      put(globalErrorActions.triggerGlobalErrorAlert({
        title: "Export Download Failed",
        message: "Failed to download your exported CSV.  Please try again.",
        error: e
      }))
    ]);
  }
}