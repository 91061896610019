import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ExceptionsCard from '../../components/exceptions-card/ExceptionsCard';
import LoadingIcon from '../../components/loading-icon';
import shipmentActions from '../shipments/actions';
import { JOB_MODES } from '../../utils/constants';

export class ExceptionsContainer extends React.Component {
  componentDidMount() {
    this.props.fetchExceptions();
  }

  render() {
    return (
      this.props.exceptionsLoading ? <div style={{marginLeft: "10px"}}><LoadingIcon/></div> :
      <ExceptionsCard data-test="exceptionsCard" exceptions={this.props.exceptions} history={this.props.history} location={this.props.location} userProfile={this.props.userProfile}/>
    );
  }
}

ExceptionsContainer.propTypes = {
  exceptions: PropTypes.object,
  exceptionsLoading: PropTypes.bool,
  history: PropTypes.object
};

ExceptionsContainer.defaultProps = {
  exceptions: {},
  exceptionsLoading: true,
  history: {}
};

export const mapStateToProps = (state) => {
  // NOTE: only shipments exceptions are supported at this time
  const jobMode = JOB_MODES.shipments;
  return {
    exceptions: state.shipments.data.get('exceptions'),
    exceptionsLoading: state.shipments.data.get('exceptionsLoading'),
    userProfile: state.users.get('userProfile')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchExceptions: () => {
      dispatch(shipmentActions.fetchExceptions());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionsContainer);
