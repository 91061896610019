import React, { Component } from 'react';

var map = {},
  scriptURL = "https://www.bing.com/api/maps/mapcontrol??branch=release&callback=bingmapsCallback",
  pendingProps = [];

export default class MiniMapContainer extends Component {
  constructor(props) {
    super(props);
    if (document.querySelector('script[src="' + scriptURL + '"]') === null) {
      this.loadScript(scriptURL);
      window.bingmapsCallback = function () {
        this.afterDependencyLoad(pendingProps);
      }.bind(this);
    }
  }

  componentDidMount() {
    if (window.Microsoft === null || window.Microsoft === undefined) {
      pendingProps.push(this.props);
    } else {
      this.bingMap(this.props, window.Microsoft);
    }
  }

  afterDependencyLoad(pendingProps) {
    try {
      pendingProps.map((props) => this.bingMap(props, window.Microsoft));
    } catch (exception) {
      console.error(exception);
    }
  }

  componentWillUnmount() {
    try {
      if (this.props.mapLoaded) {
        this.props.mapLoaded(false);
      }
      let mapReference = this.props.id ? ('#' + this.props.id) : '.react-bingmaps';
      if (map[mapReference])
        map[mapReference] = undefined;
      pendingProps = [];
    } catch (exception) {
      console.error(exception);
    }
  }

  loadScript(url) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  bingMap(props, Microsoft) {
    const {
      bingmapKey,
      defaultZoom,
      mapType,
      zoom
    } = props;
    if (bingmapKey && Microsoft) {
      let mapReference = props.id ? ('#' + props.id) : '.react-bingmaps';
      if (!map[mapReference]) {
        map[mapReference] = new Microsoft.Maps.Map(mapReference, {
          enableClickableLogo: false,
          disableScrollWheelZoom: true,
          enableInertia: false,
          credentials: bingmapKey,
          disablePanning: true,
          allowHidingLabelsOfRoad: true,
          showLocateMeButton: false,
          showMapTypeSelector: false,
          showZoomButtons: false,
          showScalebar: false,
          showCopyright: false,
          maxZoom: 15,
          minZoom: 3,
          zoom: defaultZoom || this.props.zoom,
          showTrafficButton: false,
          mapTypeId: Microsoft.Maps.MapTypeId[mapType]
        });
      }

      const center = new Microsoft.Maps.Location(this.props.lat, this.props.long);

      if (props.box && props.box.size !== 0 && props.zoom && (!this.props.lastLocation || this.props.lastLocation && !this.props.lastLocation.pathname.includes('shipment-tracker'))) {
        map[mapReference].setView({
          center: center,
          zoom: zoom
        });
      }

      var pin = new Microsoft.Maps.Pushpin(center, {
        iconStyle: 37
      });

      map[mapReference].entities.push(pin);
    }
  }

  render() {
    return (
      <div id={this.props.id} className='react-bingmaps' style={{ height: "100%", position: "relative" }}></div>
    );
  }
};