import React from 'react'
import NotificationCard from './NotificationCard';

 export default function CommentAlertList(props) {
    return (
      <React.Fragment>
          {
            props.mentions && props.mentions.map((m, index)=>{
              return (
              <NotificationCard history={props.history}
                key={index}
                link={m.linkUrl}
                linkText={m.alertType === 'comment' ? "View Conversation" : "View Shipments"}
                title={m.title}
                subTitle=""
                message={m.message}
                dateTime={m.timestamp}
              />);
            })
          }
      </React.Fragment>
    );
}
