import ManageUsers from './ManageUsers';
import User from './User';
import {ManageUsersPageRBAC, UserPageRBAC} from './rbac';
import { LayoutHOC } from '../../../components/layout';

const ManageUsersPage = LayoutHOC(ManageUsers);
ManageUsersPage._RBAC = ManageUsersPageRBAC;

const UserPage = LayoutHOC(User);
UserPage._RBAC = UserPageRBAC;

export { ManageUsers, ManageUsersPage, UserPage };
