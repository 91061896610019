import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  fetchedShipment: {},
  loads: [],
  loadNumbers: [],
  trackingLogs: {},
  loading: false,
});

function trackingShipmentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_TRACKING_SHIPMENT_STARTED:
      return state
        .set('loading', true)
    case types.FETCH_TRACKING_SHIPMENT_FAILED:
      return state
        .set('loading', false);
    case types.FETCH_TRACKING_SHIPMENT_COMPLETED:
      return state
        .set('loads', action.payload.loads)
        .set('fetchedShipment', action.payload.fetchedShipment)
        .set('loadNumbers', action.payload.loadNumbers)
        .set('loading', false);
    case types.FETCH_TRACKING_MILESTONES_STARTED:
      return state
        .set('loading', true);
    case types.FETCH_TRACKING_MILESTONES_FAILED:
      return state
        .set('loading', false)
        .set('trackingLogs', {});
    case types.FETCH_TRACKING_MILESTONES_COMPLETED:
      return state
        .set('trackingLogs', action.payload)
        .set('loading', false);
    case types.PROCESS_ROUTEDATA_COMPLETED:
      return state
        .set('routingSegments', action.payload.routingSegments)
        .set('pickDropPins', action.payload.pickDropPins)
        .set('currentLocation', action.payload.currentLocation)
        .set('expectedPosition', action.payload.expectedPosition);
    case types.RESET_PROPS:
      return initialState
    default:
      return state;
  }
}

export default trackingShipmentReducer;
