import React, { Component } from 'react'
import { connect } from 'react-redux';
import logo from '../../containers/chr-side-nav/extras/navisphere-vision-w.svg';
import { isInputEmpty } from '../../utils/helperMethods/commonMethods';
import userActions from '../users/actions';
import queryString from 'query-string';
import { selectAuthClient } from '../auth/selectors';
import PropTypes from 'prop-types';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';

export class CreatePasswordContainer extends Component {
  state = {
    password: '',
    confirmPassword: '',
    inputValidation: {
      passwordLengthError: false,
      passwordMatchError: false
    },
    saveSuccessful: false
  }

  componentDidMount() {
    trackPageInfoEvent(this.props.adobeTrack, "Create Password Page", ["Create Password Page"]);
  }
  
  handleInputChange = (e, field) => {
    var newState = Object.assign({}, this.state);

    const inputValue = e.target.value;

    if (field === 'password') {
      newState.password = inputValue;
      newState.inputValidation.passwordLengthError = isInputEmpty(inputValue);
    }

    if (field === 'confirmPassword') {
      newState.confirmPassword = inputValue;
      newState.inputValidation.passwordMatchError = this.state.password !== inputValue;
    }

    this.setState(Object.assign(this.state, newState));
  }

  savePassword = () => {
    const parsedString = queryString.parse(this.props.location.search);
    if (parsedString.token && this.state.password === this.state.confirmPassword && this.state.password.length > 5) {

      const request = {
        token: parsedString.token,
        password: this.state.password
      };
      this.props.changePassword(request);
    }
  }

  handleCancel = () => {
    window['localStorage'].removeItem('userProfile');
    this.logout();
  }

  logout = () => {
    window['localStorage'].removeItem('userProfile');
    window['localStorage'].removeItem(this.props.productUrl + '-access_token');
    window['localStorage'].removeItem(this.props.productUrl + '-id_token');
    const target = window.location.origin;
    this.props.authClient.logout({"post_logout_redirect_uri": target});
  }

  render() {
    return (
      <div className="container-fluid">

        <div className="header-container" >
          <div className="row flex ">
            <div className="col-md-12 homeLogo">
              <a className="hidden-xs hidden-sm" title="Navisphere Vision Home Page" aria-label="Navisphere Vision Home Page">
                <img src={logo} />
              </a>
            </div>
          </div>
        </div>

        {this.props.savePasswordSuccessful ?
          <div className="alertForm" data-test="passwordChangeLogin">
            <h3>Your Password has been successfully created.</h3>

            <div className="form-group">
              <i><h3>Please login.</h3></i>
              <button type="button" className="save-button" data-test="login" onClick={() => { this.logout() }}>
                <span className="glyphicon glyphicon-ok-sign"></span>&nbsp;Login</button>
            </div>
          </div>
          :
          <div className="row">
            <div className="col-sm-8 col-md-6 col-lg-4">
              <form className="passwordForm">
                <h3>Create your password</h3>
                <div className="form-group">
                  <label className="read-only-value">Password</label>
                  <input type="password" name="password" data-test="password" className={this.state.inputValidation.passwordLengthError ? "form-control invalid" : "form-control"} onChange={e => this.handleInputChange(e, 'password')} value={this.state.password} minLength="6" autoFocus required />
                  {this.state.inputValidation.passwordLengthError && <span className="help-block" data-test="passwordRequired">Password is required.</span>}
                </div>

                <div className="form-group">
                  <label className="read-only-value">Retype your Password</label>
                  <input type="password" name="confirmPassword" data-test="confirmPassword" className={this.state.inputValidation.passwordMatchError ? "form-control invalid" : "form-control"} onChange={e => this.handleInputChange(e, 'confirmPassword')} value={this.state.confirmPassword} required />
                  {this.state.inputValidation.passwordMatchError && <span className="help-block" data-test="passwordDoesNotMatch">Passwords do not match.</span>}
                </div>

                <div className="sign-in-button-container">
                  <button type="button" className="btn btn-primary" data-test="savePassword" onClick={() => { this.savePassword() }} >
                    <span className="glyphicon glyphicon-edit"></span>&nbsp;Save</button>
                  <button type="button" className="btn btn-light" data-test="cancelCreatePassword" onClick={() => { this.handleCancel() }} >
                    <span className="glyphicon glyphicon-backward"></span>&nbsp; Cancel</button>
                </div>
              </form>
            </div>
          </div>}
      </div>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    savePasswordSuccessful: state.users.get('savePasswordSuccessful'),
    authClient: selectAuthClient(state)
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (payload) => {
      dispatch(userActions.changePassword(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordContainer);

CreatePasswordContainer.protoType = {
  changePassword: PropTypes.func,
  savePasswordSuccessful: PropTypes.bool
};
