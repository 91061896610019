import React, { Component } from 'react';
import { getDate, getTime } from '../../utils/helperMethods/dateTimeMethods';
import {
    getRiskLevelIconName,
    getRiskLevelClassName
} from '../../utils/helperMethods/orderDetailsMethods';
import Icon from '../icon';

export class OrdersHeaderDetails extends Component {
    
    render() {
        let {shipment} = this.props;

        return <div className="order-header-details">
        <div className="order-header-details-location" id="orderOrigin" data-test="orderOrigin">
          <span className="read-only-label">{'Order Origin'}</span>
          <span className="read-only-value" id={'orderOriginName'} data-test="orderOriginName">{shipment.origin_name || shipment.origin_facility_name || '\u2014'}</span>
          <span className="read-only-value" id={'orderOriginLocation'} data-test="orderOriginLocation">{shipment.origin_city || '\u2014'}, {shipment.origin_state || '\u2014'}, {shipment.origin_country || '\u2014'}</span>
        </div>
        <div className="order-header-details-arrow"><Icon type={'arrow-right'} /></div>
        <div className="order-header-details-location" id="orderDestination" data-test="orderDestination">
          <span className="read-only-label">{'Order Destination'}</span>
          <span className="read-only-value" id={'orderDestinationName'} data-test="orderDestinationName">{shipment.destination_name || shipment.destination_facility_name || '\u2014'}</span>
          <span className="read-only-value" id={'orderDestinationLocation'} data-test="orderDestinationLocation">{shipment.destination_city || '\u2014'}, {shipment.destination_state || '\u2014'}, {shipment.destination_country || '\u2014'}</span>
        </div>
        <div></div>
        <div className="order-header-details-eta" id="orderEta" data-test="orderEta">
          <span className="read-only-label">{'Order ETA'}</span>
          <div className="order-header-details-eta-date-time">
            <span className="read-only-value" id={`shipmentEtaDate`}>{shipment.calculated_ETA ? getDate(shipment.calculated_ETA) : '\u2014'}</span>
            <span className="read-only-time" id={`shipmentEtaTime`}>{shipment.calculated_ETA ? `at ${getTime(shipment.calculated_ETA)}` : ''}</span>
          </div>
          <div className="order-header-details-eta-risk-level">
            <Icon type={getRiskLevelIconName(shipment.riskLevel)} />
            <span className={["read-only-risk-level", getRiskLevelClassName(shipment.riskLevel)].join(' ')} id={'shipmentRiskLevel'}>{shipment.riskLevel || ''}</span>
          </div>
        </div>
      </div>;
    }
}

export default OrdersHeaderDetails;