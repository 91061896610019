import {isBayer, isGFS} from '../../utils/helperMethods/commonMethods';

export const findContainers = (handlingUnits, containerInformation) => {
  let containerNumberSource;
  if (containerInformation && containerInformation.length) {
    containerNumberSource = containerInformation;
  } else if (handlingUnits && handlingUnits.length) {
    containerNumberSource = handlingUnits
  } else {
    containerNumberSource = [];
  }

  if (containerNumberSource && containerNumberSource.length > 0) {
    return containerNumberSource.filter(x =>
      x.container_number //filter out null, empty, undefined
    );
  } else {
    return [];
  }
}

export const showContainers = shipment => {
  const { handling_units, mode, container_information } = shipment;

  const containerNumberList = findContainers(handling_units, container_information);

  return (
    mode === 'Ocean'
    && handling_units != null
    && handling_units.length > 0
    && containerNumberList.length > 0
    && !isBayer(shipment)
  );
};

export const showParcels = shipment => 
  (shipment.mode && shipment.mode.toLowerCase() === 'parcel' 
    && shipment.handling_units != null && shipment.handling_units.length > 0 && shipment.handling_units.some(x => x.package_tracking_number));

export const totalPackageCount = shipment => {
  return new Set(shipment.handling_units.map(handling_units => handling_units.package_tracking_number).filter(packageTrackingNumber=> !!packageTrackingNumber)).size;
};