import * as types from './actionTypes';
export default {
  saveFilterSet(filterSet) {
    return {
      type: types.SAVE_FILTERSET_STARTED,
      filterSet
    };
  },
  fetchFilterSets() {
    return {
      type: types.FETCH_FILTERSETS_STARTED,
      payload: {}
    };
  },
  fetchFilterSetComplete(filterSets) {
    return {
      type: types.FETCH_FILTERSETS_COMPLETED,
      payload: {
        filterSets
      }
    };
  },
  deleteFilterSet(id) {
    return {
      type: types.DELETE_FILTERSET_STARTED,
        id
    };
  },
  updateFilterSet(id, filterSet) {
    return {
      type: types.UPDATE_FILTERSET_STARTED,
      payload: {
        filterSet,
        id
      }
    };
  },
  updateFilterSetComplete() {
    return {
      type: types.UPDATE_FILTERSET_COMPLETE
    }
  }
}
