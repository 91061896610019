import axios from 'axios';
import { getAccessToken } from '../containers/auth/authHelper';
import _ from 'lodash';
import * as rax from 'retry-axios';

const waitForRetryDelay = 10000;

const factory = async function (baseUrl, params = {}) {
  const token = await getAccessToken();
  const defaultOptions = {
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { ...params }
  };
  return createAxiosInstance(defaultOptions);
};

const factoryWithoutToken = function (baseUrl, params = {}) {
  const defaultOptions = {
    baseURL: baseUrl,
    params: { ...params }
  };
  return createAxiosInstance(defaultOptions);
}

const createAxiosInstance = function(options) {
  let instance = axios.create(options);

  instance.defaults.raxConfig = {
    instance: instance,
    statusCodesToRetry: [[401, 401], [404, 404], [409, 409], [429, 429], [500, 500], [502, 504]],
    retry: 1,
    onRetryAttempt: err => {
      return new Promise(resolve => {
        if (err && err.response && [429, 503].includes(err.response.status)) {
          setTimeout(resolve, waitForRetryDelay);
        }
        else {
          resolve();
        }
      });
    }
  };
  rax.attach(instance);

  return instance;
}

export { factory as default, factoryWithoutToken };
