import * as types from './actionTypes';
export default {
  fetchIncidents() {
    return {
      type: types.FETCH_INCIDENTS_STARTED,
      payload:{}
    };
  },
  fetchIncidentsFailed(payload) {
    return {
      type: types.FETCH_INCIDENTS_FAILED,
      payload: payload
    };
  },
  fetchIncidentsComplete(incidents) {
    return {
      type: types.FETCH_INCIDENTS_COMPLETED,
      payload:{
        incidents: incidents
      }
    };
  },
  deleteIncident(incidentId) {
    return {
      type: types.DELETE_INCIDENT_STARTED,
      payload: {
        incidentId: incidentId
      }
    };
  },
  saveDrawnIncidentShape(incident) {
    return {
      type: types.SAVE_DRAWN_INCIDENT_SHAPE,
      payload: {
        incident: incident
      }
    }
  },
  clearDrawnIncident() {
    return {
      type: types.CLEAR_DRAWN_INCIDENT,
      payload: {}
    }
  },
  createIncident(incident) {
    return {
      type: types.CREATE_INCIDENT_STARTED,
      payload: {
        incident
      }
    }
  },
  createIncidentComplete(incident) {
    return {
      type: types.CREATE_INCIDENT_COMPLETE,
      payload: {
        incident
      }
    }
  },
  updateIncident(incident) {
    return {
      type: types.UPDATE_INCIDENT_STARTED,
      payload: {
        incident
      }
    }
  },
  updateIncidentComplete(incident) {
    return {
      type: types.UPDATE_INCIDENT_COMPLETE,
      payload: {
        incident
      }
    }
  }
}
