import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'
import shipmentActions from '../../containers/shipments/actions';

export default function IncidentCard(props) {
  const dispatch = useDispatch();
  const viewShipments = (incidentInfo, type, history, location) => {
    const status = type !== 'viewAll' ? type : null;
    dispatch(shipmentActions.navigateToShipmentFromIncidentCard(incidentInfo.name, incidentInfo.id, status, location, history))
  }

  return (
    <div className="col-md-4 col-xs-12" style={{margin: "10px 0 10px 0"}}>
      <div className="card incident-card" data-test="incidentCard">
        <div className="card-header">
          <div className="row">
            <div className="col-xs-12">
              <h3>{props.incident.incidentInfo.name}</h3>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="incident-current">
            <h4 className="uppercase small">Current Impact</h4>
            <div className="row">
              <div className="col-lg-6">
                <div className="read-only">
                  <span className="medium-value">{props.incident.totalShipments}</span>
                  <span className="read-only-label">Total Shipments</span>
                </div>
                <button className="btn btn-sm shipment-count-callout" data-test="incidentsViewAll" onClick={() => viewShipments(props.incident.incidentInfo, 'viewAll', props.history, props.location)}>
                  View All
								</button>
              </div>
              <div className="col-lg-6">
                <button className="btn shipment-count-callout full-width flex" data-test="incidentsAtRisk" onClick={() => viewShipments(props.incident.incidentInfo, 'At Risk', props.history, props.location)}>
                  <span className="status-headline atrisk flex-one">
                    <h4 className="uppercase">At Risk</h4>
                  </span>
                  <span data-test="atRiskCount" className="shipment-count">{props.incident.atRisk ? props.incident.atRisk.doc_count : 0}</span>
                </button>
                <button className="btn shipment-count-callout full-width flex" data-test="incidentsLate" onClick={() => viewShipments(props.incident.incidentInfo, 'Late', props.history, props.location)}>
                  <span className="status-headline late flex-one">
                    <h4 className="uppercase">Late</h4>
                  </span>
                  <span data-test="lateCount" className="shipment-count">{props.incident.late ? props.incident.late.doc_count : 0}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
IncidentCard.propTypes = {
  incident: PropTypes.object
}
IncidentCard.defaultProps = {
  incident: {}
}
