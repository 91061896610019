import React from 'react'
import { connect } from 'react-redux';
import actions from './actions';
import socket from '../../apis/socket';
import {CommentList, ErrorMessage} from '../../components/conversation';
import {GroupContainer} from './GroupContainer';
import CommentsAutoSuggest from '../auto-suggest';
import Icon from '../../components/icon';
import {getUserProfile} from '../auth/authHelper';
import { selectAccessToken } from '../auth/selectors';
import { NUOLV_ENABLED_TENANTS } from '../../utils/constants';
import { getShipmentDetailLevel } from '../../utils/helperMethods/commonMethods';

const connectionDroppedMessage = 'Sorry you got disconnected somehow, we will try reconnecting you. If this message persists try refreshing the page to continue chatting!';
let typingTimeout = undefined;

const buildTypingMessage = (whosTyping) => {
  let result = ''
  if (whosTyping.size >= 1) {
    for (let [key, val] of whosTyping.entries()) {
      result += key + ', ';
    }
    result = result.substr(0, result.length - 2);
    if (whosTyping.size === 1) {
      result += ' is typing...';
    } else {
      result += ' are typing...';
    }
  }
  return result;
};


const setup = async(props)=>{
  const profile = await getUserProfile();
  const roomId = {
    roomId: props.location.pathname.split('/')[2],
    tenantId: profile.tenantId
  };
  return socket(props.appConfig)(JSON.stringify(roomId), 'conversations', props.accessToken);
};

export class Conversation extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      eventList: [
        { eventName: 'comment', eventHandler: this.props.fetchLiveConversation },
        { eventName: 'comment:error', eventHandler: this.handleConnectionError},
        { eventName: 'disconnect', eventHandler: ()=>{this.handleConnectionError(connectionDroppedMessage)}},
        { eventName: 'connect', eventHandler: this.handleReconnect},
        { eventName: 'typing', eventHandler: this.handleTyping},
        { eventName: 'stoppedTyping', eventHandler: this.handleStoppedTyping},
        { eventName: 'leftRoom', eventHandler: this.handleActiveViewerLeft},
        { eventName: 'joinedRoom', eventHandler: this.handleActiveViewerJoined},
        { eventName: 'initJoinActiveUsers', eventHandler: this.handleInitJoinActiveUsers}
      ],
      socketClient: undefined,
      newComment: {
        conversationId:props.location.pathname.split('/')[2],
        message: '',
        link: window.location.href,
        mentions: [],
        detailLevel: null
      },
      errorMessage:'',
      typingMessage:'',
      whosTyping: new Set()
    };
  }

  componentDidMount() {
    this.props.fetchConversation(this.state.newComment.conversationId);
    setup(this.props).then((client)=>{
      this.setState({socketClient: client}, ()=>{
      this.state.socketClient.registerHandler(this.state.eventList);
    });
  });
  }
  componentWillUnmount() {
    this.props.clearState();
    if(this.state.socketClient){
      this.state.socketClient.unregisterHandler(this.state.eventList);
      this.state.socketClient.leave();
      this.state.socketClient.disconnect();
    }
  }
  getDetailLevel = (props, tenantId) => {
    const isNuolvEnabled = NUOLV_ENABLED_TENANTS.includes(tenantId);

    if (isNuolvEnabled) {
      return getShipmentDetailLevel(props.location.pathname) || null;
    }

    return null;
  }  
  handleConnectionError = (error) => {
    this.setState({errorMessage:error});
  }
  handleReconnect = ()=>{
    this.setState({errorMessage:''});
  }
  handleTyping = (event)=>{
    var newState = Object.assign({}, this.state);
    newState.whosTyping.add(event.name);
    newState.typingMessage = buildTypingMessage(newState.whosTyping);
    this.setState(newState);
  }
  handleStoppedTyping = (event)=>{
    var newState = Object.assign({}, this.state);
    newState.whosTyping.delete(event.name);
    newState.typingMessage = buildTypingMessage(newState.whosTyping);
    this.setState(newState);
  }
  handleActiveViewerJoined = (event)=>{
    this.props.activeViewerJoined(event);
  }
  handleActiveViewerLeft = (event)=>{
    this.props.activeViewerLeft(event);
  }
  handleInitJoinActiveUsers = (event)=>{
    this.props.initJoinActiveUsers(event);
  }
  addComment = async () => {
    this.state.socketClient.dispatchEvent('comment', this.state.newComment, this.state.newComment.conversationId);
    var newState = Object.assign({}, this.state);
    newState.newComment.message = '';
    this.setState(newState);
  }
  handleCommentChange = async(e) => {
    var newState = Object.assign({}, this.state);

    const userProfile = await getUserProfile();
    newState.newComment.detailLevel = this.getDetailLevel(this.props, userProfile.tenantId);
    newState.newComment.message = e.target.value;
    newState.newComment.mentions.forEach((m, i) => {
      if(!newState.newComment.message.includes(m.userId)){
        newState.newComment.mentions.splice(i, 1);
      };
    });
    this.setState(newState);
    this.state.socketClient.typing(newState.newComment.conversationId);
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(()=>{this.state.socketClient.stoppedTyping(newState.newComment.conversationId);}, 2000);
  }
  addMention = (userId, displayName) => {
    var newState = Object.assign({}, this.state);
    if (newState.newComment.mentions.filter(m => m.userId === userId).length < 1) {
      const user = userId.split('thisIsTaylorsCustomDelimiter')[0];
      const picture = userId.split('thisIsTaylorsCustomDelimiter')[1];
      newState.newComment.mentions.push({
        userId: user,
        displayName: displayName,
        gravatarUrl: picture
      });
      this.setState(newState);
    }
  }

  render() {
    return (
      <div className={this.props.isLoadCommentsActive === "active" ? "tab-pane fade active in" : "tab-pane fade"} id="comments-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <ErrorMessage text={this.state.errorMessage}/>
              <GroupContainer id='gcontainer'/>
              <CommentList id='cclist'
                comments={this.props.comments}
                activeUserId={this.props.activeUserId}
              />
              <div className="row">
                <div className="col-sm-12">
                  <div className="conversation auto-suggest type_msg">
                    <CommentsAutoSuggest id='commentAutoSuggest' handleCommentChange={this.handleCommentChange} commentValue={this.state.newComment.message} mentionAdded={this.addMention} addComment={this.addComment}/>
                    <div ref={commentListElement => { this.commentListElement = commentListElement } } id='addCommentBtn' onClick={this.addComment} className="conversation auto-suggest msg_send_btn" type="submit"><Icon type="share" /></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div id='typingtext' className="col-sm-12">
                  {this.state.typingMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    comments: state.conversation.get('comments'),
    group: state.conversation.get('group'),
    activeUserId: state.users.get('userProfile').user_id,
    accessToken: selectAccessToken(state)
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchConversation: (conversationId) => {
      return dispatch(actions.fetchConversation(conversationId));
    },
    fetchLiveConversation: (conversation) => {
      return dispatch(actions.fetchConversationComplete(conversation))
    },
    activeViewerJoined: (event) => {
      return dispatch(actions.activeViewerJoined(event));
    },
    activeViewerLeft: (event) => {
      return dispatch(actions.activeViewerLeft(event));
    },
    initJoinActiveUsers: (event) => {
      return dispatch(actions.initJoinActiveUsers(event));
    },
    clearState: () => {
      return dispatch(actions.clearState());
    }
  };
};

export const ConversationContainer = connect(mapStateToProps, mapDispatchToProps)(Conversation);
