import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  flows: [],
  channels: [],
  facilities: [],
  accounts: [],
  tenants: [],
  regions: [],
  accessFilters: []
});

function metadataReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_TENANT_METADATA_COMPLETED:
      return state
        .set('regions', action.payload.regions.aggregations.regions.buckets)
        .set('flows', action.payload.flows.data)
        .set('channels', action.payload.channels.data)
        .set('facilities', action.payload.facilities.data)
        .set('accounts', action.payload.accounts.data)
        .set('externalAccounts', action.payload.externalAccounts.data)
        .set('tenants', action.payload.tenants.data)
        .set('accessFilters', action.payload.accessFilters.data);
    case types.FETCH_TENANT_FLOWS_COMPLETED:
      return state
        .set('flows', action.payload.data);
    case types.FETCH_TENANT_REGION_COMPLETED:
      return state
        .set('regions', action.payload.aggregations.regions.buckets);
    default:
      return state;
  }
}

export default metadataReducer;
