import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toKebabCase } from '../../../utils/helperMethods/commonMethods';

export default function BaseFilters(props) {
  const renderCategories = baseFilters => {
    let buckets = baseFilters?.data?.buckets;
    const isLoading = !buckets;
    buckets = buckets ?? [];
    if (isLoading || buckets.length > 0) {
      return buckets.map(filters => {
        const isChecked =
          props.baseFilters.active &&
          props.baseFilters.active.includes(filters.key);
        return (
          <li key={uuidv4()} className="list-item">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                id={`base-${toKebabCase(filters.key)}-filter`}
                onChange={() => {
                  props.handleFiltersChange(baseFilters, filters.key);
                }}
              />
              <span>
                {' '}
                <p>
                  {filters.key} ({filters.doc_count}){' '}
                </p>
              </span>
            </label>
          </li>
        );
      });
    } else {
      return (
        <li key={uuidv4()} className="list-item">
          <p id="nooptions" style={{ color: 'grey', textAlign: 'center' }}>
            {' '}
            No Options
          </p>
        </li>
      );
    }
  };

  return (
    <ul className="list-group">
      <p>{props.baseFilters.label}</p>
      {renderCategories(props.baseFilters)}
    </ul>
  );
}
