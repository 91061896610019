import React from 'react';
import { connect } from 'react-redux';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import Icon from '../../components/icon';
import { SHIPMENTS_PATH, ORDERS_PATH } from '../../utils/constants';
import { navigateToViewPath } from '../../utils/helperMethods/commonMethods';
import sideNavActions from './actions';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './extras/CHRSideNav.scss';

//imports for rbac control 
import {passesRbac} from '../../utils/rbacUtils';

import {UserPageRBAC} from '../../pages/admin/manage-users/rbac';
import {DisruptionsPageRBAC} from '../../pages/disruptions/rbac';
import {InventoryPageRBAC} from '../../pages/inventory/rbac';
import {IotDashboardRBAC} from '../../pages/iot-dashboard/rbac';
import {LandingPageRBAC} from '../../pages/landing/rbac';
import {NotificationPreferencesPageRBAC} from '../../pages/notifications/rbac';
import {OrdersPageRBAC} from '../../pages/orders/rbac';
import {SavedItemsPageRBAC} from '../../pages/saved-items/rbac';
import {ScorecardPageRBAC} from '../../pages/scorecard/rbac';
import {ShipmentsPageRBAC} from '../../pages/shipments/rbac';

export class CHRSideNav extends React.Component {
  onSelect = (selected) => {
    const { history } = this.props;

    if (selected === SHIPMENTS_PATH || selected === ORDERS_PATH) {
      let defaultParams = {
        currentPage: 1,
        newSearch: true,
        displayMode: 'listOnly'
      }
      navigateToViewPath(selected, history, defaultParams);
    }
    else {
      history.push(selected);
    }
  };

  onToggle = () => {
    const { toggleSidenavExpanded, expanded } = this.props;
    toggleSidenavExpanded(!expanded);
  };

  navItems = {
    landingPage: {
      rbac: LandingPageRBAC,
      display: (
      <NavItem eventKey="/" navitemClassName={"landing-nav-button"} key="/">
        <NavIcon>
          <Icon className="icon white" type="dashboard" data-test="landingPageIcon" id={"landing-nav-button"}/>
        </NavIcon>
        <NavText>
          Landing Page
        </NavText>
      </NavItem>
    )},
    shipmentsPage: {
      rbac: ShipmentsPageRBAC,
      display:(
      <NavItem eventKey={SHIPMENTS_PATH} key={SHIPMENTS_PATH}>
        <NavIcon>
          <Icon className="icon white" type="card" data-test="shipmentsNavIcon" id={"shipments-nav-button"}/>
        </NavIcon>
        <NavText>
          Shipments
        </NavText>
      </NavItem>
    )},
    ordersPage: {
      rbac: OrdersPageRBAC,
      display: (
      <NavItem eventKey={ORDERS_PATH} key={ORDERS_PATH}>
        <NavIcon>
          <Icon className="icon white" type="order-icon" data-test="ordersNavIcon" id={"orders-nav-button"}/>
        </NavIcon>
        <NavText>
          Orders
        </NavText>
      </NavItem>
    )},
    savedItems: {
      rbac: SavedItemsPageRBAC,
      display: (
      <NavItem eventKey="/saved-items" key="/saved-items">
        <NavIcon>
          <Icon className="icon white" type="flag" data-test="savedItemsNavIcon" id={"workbench-nav-button"}/>
        </NavIcon>
        <NavText>
          Workbench
        </NavText>
      </NavItem>
    )},
    disruptions: {
      rbac: DisruptionsPageRBAC,
      display: (
      <NavItem eventKey="/disruptions" key="/disruptions">
        <NavIcon>
          <Icon style={{ color: 'white' }} className="icon white" type="incident" data-test="incidentsNavIcon" id={"incidents-nav-button"}/>
        </NavIcon>
        <NavText>
          Disruptions
        </NavText>
      </NavItem>
    )},
    carrierScorecard: {
      rbac: ScorecardPageRBAC,
      display: (
      <NavItem eventKey="/carrier-scorecard" key="/carrier-scorecard">
        <NavIcon>
          <FontAwesomeIcon style={{ marginLeft: '1rem', color: 'white' }} className="icon white" icon={faClipboard} size="2x" data-test="scorecardNavIcon" id={"carrier-nav-button"}/>
        </NavIcon>
        <NavText>
          Carrier Scorecard
        </NavText>
      </NavItem>
    )},
    inventory: {
      rbac: InventoryPageRBAC,
      display: (
      <NavItem eventKey="/inventory?currentPage=1" key="/inventory?currentPage=1">
        <NavIcon>
          <Icon className="icon white" type="cart" id={"inventory-nav-button"}/>
        </NavIcon>
        <NavText>
          Inventory
        </NavText>
      </NavItem>
    )},
    iotPage: {
      rbac: IotDashboardRBAC,
      display: (
      <NavItem eventKey="/iot-dashboard" key="/iot-dashboard">
        <NavIcon>
          <Icon className="icon white" type="iot-device" data-test="iotDevicesDashboardIcon" id={"iot-nav-button"} />
        </NavIcon>
        <NavText>
          IoT Dashboard
        </NavText>
      </NavItem>
    )},
    notifications: {
      rbac: NotificationPreferencesPageRBAC,
      display: (
      <NavItem eventKey="/notification-preferences" navitemClassName={'notification-nav-button'} key="/notification-preferences">
        <NavIcon>
          <Icon className="icon white" type="envelope" data-test="notificationsNavIcon" />
        </NavIcon>
        <NavText>
          Notifications
        </NavText>
      </NavItem>
    )},
    manageUsers: {
      rbac: UserPageRBAC,
      display: (
      <NavItem eventKey="/manage-users" key="/manage-users">
        <NavIcon>
          <Icon className="icon white" type="user" data-test="manageUsersNavIcon" id={"users-nav-button"}/>
        </NavIcon>
        <NavText>
          Manage Users
        </NavText>
      </NavItem>
    )},
  };

  getNavItems = (role, permissions) => {
    /* please note: just because a route isn't visible on the sidenav
     * doesn't mean that a user can't navigate to a route, update their associated
     * rbac settings using one of the above RBAC references as example */

    if (!role) {
      return [];
    }

    /* external users only get the orders page, but there are enough "setup" 
     * processes that they need to be able to navigate to the landing page. while
     * we will not allow them to navigate to it in the sidepane, they still
     * are 'allowed' to navigate to it in index.js router setup. */
    if (role === "ExternalUser") {
      return [this.navItems.ordersPage.display];
    }

    let sideNavItems = [];
    Object.keys(this.navItems).forEach(key => {
      let navItem = this.navItems[key];
      let rbacResult = passesRbac(navItem.rbac.roles, navItem.rbac.permissions, permissions, role);
      if (rbacResult.permissions && rbacResult.role) {
        sideNavItems.push(navItem.display);
      }
    });

    return sideNavItems;
  }

  render() {
    const {
      children, expanded, location, userProfile
    } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <div className={expanded ? 'sideNav-container expanded' : 'sideNav-container collapsed'} id={'nav-menu'}>
          <SideNav
            style={{ width: '54px' }}
            expanded={expanded}
            onToggle={this.onToggle}
            onSelect={this.onSelect}
          >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected={location.pathname}>
              {this.getNavItems(this.props.userRole, this.props.userPermissions)}
            </SideNav.Nav>
          </SideNav>
        </div>
        <div className={expanded ? 'content pushed' : 'content'}>
          <div className={expanded ? 'overlay visible-xs visible-sm' : ''} />
          {children}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  const userProfile = state.users.get('userProfile');
  const userPermissions = (userProfile && userProfile.permissions && userProfile.permissions.length) ? 
    userProfile.permissions : [];
  const userRole = userProfile ? userProfile.role : null;
  return {
    expanded: state.sidenav.get('expanded'),
    userProfile,
    userPermissions,
    userRole
  }
};

export const mapDispatchToProps = (dispatch) => ({
  toggleSidenavExpanded: (expanded) => dispatch(sideNavActions.toggleSidenavExpanded(expanded))
});

CHRSideNav.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  toggleSidenavExpanded: PropTypes.func,
  userProfile: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    role: PropTypes.string
  })
};

CHRSideNav.defaultProps = {
  children: null,
  expanded: false,
  history: {},
  location: '',
  toggleSidenavExpanded: () => { },
  userProfile: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CHRSideNav);
