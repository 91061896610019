import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { popover } from '../../utils/helperMethods/commonComponents';

export class UtcPopOver extends React.Component {
    render() {
        if (!this.props.isUtc) return null;

        return (
            <OverlayTrigger placement="top" overlay={popover('UTC Date & Time', 'Sorry, we are unable to display the time in local time zone. Time shown is in UTC+0 format.', 'utcpopover')}>
                <svg className="icon small faded">
                    <use href="#help" style={{ pointerEvents: 'none' }} />
                </svg>
            </OverlayTrigger>
        );
    }
}

export default UtcPopOver;