import React from 'react';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTray: false
    };
    this.divRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showTray !== this.state.showTray && this.state.showTray) {
      this.divRef.current.focus();
    }
  }

  closeTray = (e) => {
    if (!e.relatedTarget || !e.relatedTarget.localName || e.relatedTarget.localName !== 'button') {
      this.setState({ showTray: false });
    }
  }

  showTray = () => {
    this.setState({ showTray: true });
  }

  render() {
    return (
      <div>

        {this.props.type === 'dropdown' &&
          <div id='dropdownAnchor' className='dropdown-toggle' onClick={this.showTray}>
            <a className={this.props.newAlert ? 'new-alert' : ''}>
              {this.props.anchor}
            </a>
            <div className="dropDownMenu" tabIndex="0" ref={this.divRef} onBlur={(e) => this.closeTray(e)}>
              {(this.state.showTray && this.props.items) && <div className='dropdown-content open'>{this.props.items}</div>}
            </div>
          </div>}

        {this.props.type === 'tray' &&
          <div className={this.props.showTray ? 'navbar-collapse collapse in' : 'navbar-collapse collapse'}>
            {this.props.items}
          </div>
        }

        {this.props.type === 'icon' &&
          <div id='dropdownTray' className='dropdown-toggle'>
            <a>
              {this.props.anchor}
            </a>
          </div>
        }
      </div>
    );
  }
}

export default Dropdown;
