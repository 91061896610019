import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import { getLongDateLocal, getTimeLocal } from '../../utils/helperMethods/dateTimeMethods';
import './extras/styles.scss';

export default class EventsPopover extends Component {

  _renderMetricsListItems = (items) => {
    if (!items) {
      return null;
    }
    const metricsList = [];
    items.forEach((item, idx) => {
      const applyAnomalyStyling = item.isAnomaly && 'iot-anomaly-list-item';
      const valueUnitSpacer = (item.uom == '%' || item.uom == '°') ? '' : ' ';
      const formattedCurrentValue = item && item.currentValue && item.currentValue !== 0 ? item.currentValue.toLocaleString('en-US') : item.currentValue.toString();
      metricsList.push(
        <Fragment key={`metricsitem-${idx}`}>
          <div className={`col-xs-6 ${applyAnomalyStyling}`}>
            <p>{item.sensorType}</p>
          </div>
          <div className={`col-xs-6 ${applyAnomalyStyling}`}>
            <p>{formattedCurrentValue}{valueUnitSpacer}{item.uom}</p>
          </div>
        </Fragment>
      )
    })
    return metricsList;
  }

  _renderMetricsSummary = (shipment) => {
    if (shipment.metricsCount <= 1) {
      return null;
    }

    let anomaliesCountLabel;

    switch (shipment.anomaliesCount) {
      case 0:
        anomaliesCountLabel = 'No Anomalies';
        break;
      case 1:
        anomaliesCountLabel = '1 Anomaly';
        break;
      default:
        anomaliesCountLabel = `${shipment.anomaliesCount} Anomalies`;
        break;
    }

    return (
      <div className="metrics-summary">
        <div>
          <span>{`${shipment.metricsCount} Measurements Collected`}</span>
        </div>
        <div>
          <span>{anomaliesCountLabel}</span>
        </div>
      </div>
    );
  };

  _renderMetricsValueHeaderTooltip = (shipment) => {
    if (shipment.metricsCount <= 1) {
      return null;
    }

    return (
      <div className="metrics-tooltip">
        <div>
          <svg className="icon small faded">
            <use href="#help" style={{ pointerEvents: 'none' }} />
          </svg>
        </div>
        <div className="metrics-tooltip-content fade in tooltip right" role="tooltip">
          <div className="tooltip-arrow"></div>
          <div className="tooltip-inner">Last measurement in this location</div>
        </div>
      </div>
    );
  }

  render() {
    const { eventLabel, iconId, iconRef, shipment } = this.props;

    return (
      <div className="map-popover bubble">
        <div className="map-popover-header">
          <div className="read-only date-time">
            <span className="read-only-label">
              <svg id={iconId} className="icon iot-event-icon" data-toggle="tooltip" data-placement="top" data-original-title={eventLabel}><use href={iconRef} /></svg>
              {eventLabel}
            </span>
          </div>
        </div>
        <a id="closeInfobox" > <Icon className="icon close" type="close" /> </a>
        <div className="map-popover-body">
          <div className="metrics-info">
            <div className="last-location-update-label">
              <span>Last Location Update</span>
            </div>
            <div className="last-location-update-date">
              <span>{getLongDateLocal(shipment.latestUpdateTimestamp)}, {getTimeLocal(shipment.latestUpdateTimestamp)}</span>
            </div>
            { this._renderMetricsSummary(shipment) }
          </div>
          <div className="row location-info iot-row-header">
            <div className="col-xs-6">
              <span className="read-only-label iot-column-header">Sensor</span>
            </div>
            <div className="col-xs-6 metrics-value-header">
               <div>
                <span className="read-only-label iot-column-header">Value</span>
               </div>
               { this._renderMetricsValueHeaderTooltip(shipment) }
            </div>
          </div>
          <hr className="iot-hr" />
          <div className="row eta-info">
            { this._renderMetricsListItems(shipment.latestMetrics) }
          </div>
        </div>
        <div className="map-popover-footer">
          <div id="viewShipmentButton"></div>
        </div>
      </div>
    );
  }
}

EventsPopover.propTypes = {
  eventLabel: PropTypes.string,
  iconId: PropTypes.string,
  iconRef: PropTypes.string,
  shipment: PropTypes.object
};

EventsPopover.defaultProps = {
  eventLabel: '',
  iconId: '',
  iconRef: '',
  shipment: {}
}
