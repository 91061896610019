import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { IncidentCardList } from '../../components/incident-card';
import { NewsCardList } from '../../components/news-card';
import shipmentActions from '../shipments/actions';
import { JOB_MODES } from '../../utils/constants';

export class IncidentsAndNewsContainer extends React.Component {

  componentDidMount() {
    this.props.fetchAffectedShipments();
  }

  render() {
    return(
      <div data-test="incidentNewsContainer">
        <div className="row" style={{display: this.props.showDisruptions}} data-test="incidentsCardList">
           <IncidentCardList
             affectedShipments={this.props.affectedShipments}
             history={this.props.history}
             location={this.props.location}
             loading={this.props.affectedShipmentsLoading}
            />
        </div>
        <hr style={{display: (this.props.showDisruptions === 'block' && this.props.showNews === 'block' ? 'block' : 'none') }}/>
        <div className="row" style={{display: this.props.showNews}} data-test="newsCardList">
          <NewsCardList
            incidents={this.props.incidents}
            loading={this.props.affectedShipmentsLoading}
          />
        </div>
      </div>
    );
  }
}

IncidentsAndNewsContainer.propTypes = {
  affectedShipments: PropTypes.array,
  affectedShipmentsLoading: PropTypes.bool,
  history: PropTypes.object,
  incidents: PropTypes.oneOfType([
    // at runtime this is an ImmutableJS List ([object Object])...
    PropTypes.object,
    // at test time, these are mocked with array literals...
    PropTypes.array
  ])
};

IncidentsAndNewsContainer.defaultProps = {
  affectedShipments: [],
  affectedShipmentsLoading: false,
  history: {},
  incidents: []
};

export const mapStateToProps = (state) => {
  // NOTE: only shipment level incidents and news supported at this time
  const jobMode = JOB_MODES.shipments;
  return {
    affectedShipments: state.shipments.data.get('affectedShipments'),
    affectedShipmentsLoading: state.shipments.data.get('affectedShipmentsLoading'),
    incidents: state.incidents.get('incidents')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchAffectedShipments: (payload) => {
      dispatch(shipmentActions.fetchAffectedShipments(payload));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsAndNewsContainer);
