import React from 'react';
import ProgressBar from '../progress-bar';

export default class CarrierPerformance extends React.Component {

  render() {
    return (
      <div className="card">
        <h3 className="scoreCardHeaders">Carrier Performance</h3>
        {this.props.in_transit &&
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3">
              <div className="scoreCardHeaders">In Transit</div>
              <span className="scoreCardCounts" id={'in-transit-shipments-count'}>{this.props.in_transit.shipments.toLocaleString()} </span> <span>Shipments</span><br></br>
              <span className="scoreCardCounts" id={'in-transit-units-count'}>{this.props.in_transit.units.toLocaleString()} </span> <span>Units</span><br></br>
              <div className="scoreCardHeaders">Risk Level</div>
              {this.props.in_transit.at_risk && <span> <span className="scoreCardCounts">{this.props.in_transit.at_risk.shipments.toLocaleString()}</span> Shipments (<span className="scoreCardCounts" id={'in-transit-at-risk-count'}>{this.props.in_transit.at_risk.units.toLocaleString()}</span> Units) At Risk or are already Late</span>}<br></br>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3">
              <div className="scoreCardHeaders">Delivered</div>
              <span className="scoreCardCounts" id={'delivered-shipments-count'}>{this.props.delivered.shipments.toLocaleString()} </span> <span>Shipments</span><br></br>
              <span className="scoreCardCounts" id={'delivered-units-count'}>{this.props.delivered.units.toLocaleString()} </span> <span>Units</span><br></br>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6">
              <div className="onTimePickUp">On Time Pickup <br/><ProgressBar id={'on-time-pickup'} isAnimationActive={this.props.isAnimationActive} percentage={(Number(this.props.picked_up.on_time_pickup_percentage) * 100).toFixed(1)} maxWidth={130} showLabel={true}></ProgressBar> </div>
              <div>On Time Delivery <br/><ProgressBar id={'on-time-delivery'} isAnimationActive={this.props.isAnimationActive} percentage={(Number(this.props.delivered.on_time_delivery.percentage) *100 ).toFixed(1)} maxWidth={130} showLabel={true}></ProgressBar> </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
