import React, { Component } from 'react';
import Icon from '../../../components/icon';
import '../extras/styles.scss';
import Details from './Details';
import { getDate, getTime } from '../../../utils/helperMethods/dateTimeMethods';
import { getStatusClass, determineShipmentNumber, isExternalTrackingType, getTrackingDetailLevel, addStopTextToStops } from '../../../utils/helperMethods/commonMethods';
import { connect } from 'react-redux';
import { JOB_MODES, DETAILS_ORDER_NUMBER_EXCLUDED_TENANTS } from '../../../utils/constants';
import * as shipmentMethods from '../../../utils/helperMethods/shipmentMethods';
import { isEqual } from 'lodash';
import baseModalActions from '../../../containers/base-modal/actions';
import * as orderNumbersUtils from '../../../utils/helperMethods/orderNumbersUtils'
export class LoadDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  expandLoadDetails = (load) => {
    if (this.state[load] === true) {
      this.setState({
        [load]: false
      });
    } else {
      this.setState({
        [load]: true
      });
    }
  }

  GetShipmentNumber = (jobMode, shipment, userProfile, trackingTypeIsExternal) => {
    const shipmentNumberText = !trackingTypeIsExternal && (!shipment.load_status || shipment.load_status < 30) ? `Shipment # ${determineShipmentNumber(shipment)} Planned Not Booked` : `Shipment # ${determineShipmentNumber(shipment)}`;
    if (shipment) {
      if (jobMode === JOB_MODES.orders) {
        if (userProfile && userProfile.role != 'ExternalUser') {
          const props = { ...shipment, history: this.props.history };
          const routeTo = "shipments";
          
          if(shipment.exclude_from_search === true && shipment.id.toLowerCase().includes("shell")) {
            return <span className="load-number" id={'shipment-number'} data-test="ShipmentTrackerShipmentNumberText">Shipment # —</span>
          }
          return <a className="load-number" id={'shipment-number'}
            data-test="OrderTrackerShipmentNumberLink"
            onClick={() => shipmentMethods.trackShipment(props, routeTo)}
            style={{ textDecoration: 'none' }}>{shipmentNumberText}</a>
        } else {
          return null;
        }
      } else {
        return <span className="load-number" id={'shipment-number'} data-test="ShipmentTrackerShipmentNumberText">{shipmentNumberText}</span>
      }
    }
  }

  renderOrderNumbers = (load, showModal, closeModal, jobMode) => {
    // When this component is loaded from the external tracking page, this permissions object will be undefined. We want to allow access in this case.
    const ableToViewOrderTracker = this.props.userProfile.permissions ? this.props.userProfile.permissions.includes('ViewOrderTracker') : true;
    const props = { ...load, history: this.props.history, tenantId: this.props.userProfile.tenantId, ableToViewOrderTracker, closeModal, jobMode }; 
    if (!load.order_numbers
      || !load.order_numbers.length
      || (jobMode === JOB_MODES.orders && DETAILS_ORDER_NUMBER_EXCLUDED_TENANTS.includes(this.props.userProfile.tenantId))) {
      return '';
    }

    return (
      <div className="load-orders read-only">
        <span id="loadOrdersLabel" className="read-only-label">
          {"Order(s):"}
        </span>
        <div className="order-numbers">
          <span id="orderNumbers" className="read-only-value">
            {orderNumbersUtils.getOrderNumberLink(props, orderNumbersUtils.defaultDisplayOrderNumbersLimit)}
          </span>
          {load.order_numbers.length > orderNumbersUtils.defaultDisplayOrderNumbersLimit && (
            <a id="orderNumbersEllipses" title="Show More" className="btn-icon" onClick={() => { showModal(orderNumbersUtils.getOrdersModalConfig(props)) }}>
              <svg className="icon">
                <use href="#ellipses-horizontal" />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  }

  renderLoads = (loads, trackingType, jobMode, showModal, closeModal) => {
    if (loads && loads.size !== 0) {
      if (loads.length === 1 && this.state[loads[0].loadNumber] === undefined) {
        this.setState({
          [loads[0].loadNumber]: true
        });
      }
      const trackingTypeIsExternal = isExternalTrackingType(trackingType);
      return loads.map((load, index) => {
        let allOrderNumbersAreSame = load.order_numbers && load.order_numbers.length && load.stops && load.stops.length && load.stops.every(x => isEqual(x.orderNumbers, load.order_numbers));
        return (
          <div key={index}>
            {load.unplanned === true ?
              <div className="card load-details">
                <span className="load-number" id={'shipment-number'}>Shipment # Unplanned</span>
                <div className="col-md-12 list-card-pair">
                  &nbsp;
                </div>
              </div>
              : ((!load.load_status || load.load_status < 30) && !trackingTypeIsExternal)
                ?
                <React.Fragment>
                  <div className="card load-details">
                    {this.GetShipmentNumber(jobMode, load, this.props.userProfile, trackingTypeIsExternal)}
                    <div className="col-sm-12 list-card-pair">
                      &nbsp;
                    </div>
                    <div className="list-card-pair">
                      <a className="load-card-open collapsed" role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="collapseTwo">
                        <Icon type="chevron-up" className="no-pad-right" onClick={() => this.expandLoadDetails(load.loadNumber)} />
                      </a>
                    </div>
                  </div>
                </React.Fragment>
                : ((load.load_status && load.load_status >= 30) || trackingTypeIsExternal) ?
                  <React.Fragment>
                    <div className={"card load-details " + getStatusClass(load.riskLevel)}>
                      {this.GetShipmentNumber(jobMode, load, this.props.userProfile, trackingTypeIsExternal)}
                      <div className="row">
                        <div className="col-lg-3 no-pad-right">
                          <div className="read-only">
                            <span className="read-only-label">Origin</span>
                            <span className="read-only-value" id={'origin-name'}>{load.origin_name || load.origin_facility_name || '—'}</span>
                            <span className="read-only-value" id={'origin-location'}>{load.origin_city || '—'}, {load.origin_state || '—'}, {load.origin_country || '—'}</span>
                          </div>
                        </div>

                        <div className="col-lg-3 no-pad-right">
                          <div className="read-only">
                            <span className="read-only-label">Destination</span>
                            <span className="read-only-value" id={'destination-name'}>{load.destination_name || load.destination_facility_name || '—'}</span>
                            <span className="read-only-value" id={'destination-location'}>{load.destination_city || '—'}, {load.destination_state || '—'}, {load.destination_country || '—'}</span>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="read-only">
                            <span className="read-only-label">Status</span>
                            <span id={'status'} className="read-only-value">{load.status || '—'}</span>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="read-only">
                            <span className="read-only-label">ETA</span>
                            {load.calculated_ETA && load.load_status !== 90 ?
                              <React.Fragment>
                                <span className="read-only-value" id={'eta-date'}> {getDate(load.calculated_ETA)}</span>
                                <span className="read-only-time" id={'eta-time'}>{getTime(load.calculated_ETA)}</span>
                                {(load.show_ETA_source && load.show_ETA_source === true) ?
                                  <div className="read-only-value" id={'datascience-eta'}>Source: Data Science</div> : ''
                                }
                              </React.Fragment>
                              : <span className="read-only-value">{'_'}</span>}
                          </div>
                        </div>
                        <a className="load-card-open collapsed" role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="collapseTwo">
                          <Icon id={'close-detail-chevron-button'} type="chevron-up" className="no-pad-right" onClick={() => this.expandLoadDetails(load.loadNumber)} />
                        </a>
                      </div>
                      {this.renderOrderNumbers(load, showModal, closeModal, jobMode)}
                    </div>
                  </React.Fragment> : ''}
            {(!load.unplanned && load.loadNumber) ?
              <Details
                collapseExpand={(this.state[load.loadNumber] !== undefined && this.state[load.loadNumber] === true) ? true : false}
                load={load}
                stops={addStopTextToStops(load.stops)}
                showModal={showModal}
                closeModal={closeModal}
                allOrderNumbersAreSame={allOrderNumbersAreSame}
                history={this.props.history}
                userProfile={this.props.userProfile} /> : ''}
          </div>
        );
      });
    }
  }

  render() {
    return (
      <div className={this.props.isLoadDetailsActive === "active" ? "tab-pane fade active in" : "tab-pane fade"} id="load-details">
        {this.renderLoads(this.props.loads, this.props.trackingType, this.props.jobMode, this.props.showModal, this.props.closeModal)}
      </div>
    );
  };
};

export const mapStateToProps = (state, ownProps) => {
  const detailLevel = getTrackingDetailLevel(ownProps.location.pathname);
  return {
    loads: state[detailLevel].get('loads')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (modalConfig) => {
      dispatch(baseModalActions.openModal(modalConfig));
    },
    closeModal: () => {
      dispatch(baseModalActions.closeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadDetails);
