import {fork, race, take} from 'redux-saga/effects';
import {delay} from 'redux-saga';

export const debounce = (ms, pattern, task, ...args) => fork(function* () {
  while (true) {
    let action = yield take(pattern);

    while (true) {
      const {
        debounced,
        _action
      } = yield race({
        debounced: delay(ms),
        _action: take(pattern)
      });
      if (debounced) {
        yield fork(task, ...args, action);
        break;
      }
      action = _action;
    }
  }
});

export default debounce;
