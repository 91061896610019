import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';

export default {
  async fetchRoute(payload) {
    const config = await getConfiguration();
    const endpoint = config.routingApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get('route', {
        params: {
          originLat: payload.stop1.coordinate.Lat,
          originLon: payload.stop1.coordinate.Lon,
          destinationLat: payload.stop2.coordinate.Lat,
          destinationLon: payload.stop2.coordinate.Lon
        }
      });
      return result;
    } catch (err) {
      throw `Error getting route data: ${err}`;
    }
  },
  async fetchExpectedPosition(shipment) {
    const config = await getConfiguration();
    const endpoint = config.routingApiEndpoint;

    const payload = {
      load_number: shipment.load_number,
      carrier_code: shipment.carrier_code || ' ',
      clat: shipment.current_location.lat,
      clon: shipment.current_location.lon,
      dlat: shipment.destination_location.lat,
      dlon: shipment.destination_location.lon,
      olat: shipment.origin_location.lat,
      olon: shipment.origin_location.lon,
      origin_code: shipment.origin_code,
      destination_code: shipment.destination_code,
      region: (shipment.current_location_continent === 'NA') ? 0 : 1,
      picktime: shipment.pickup_date,
      reqtime: shipment.destination_Sched_date_close ? shipment.destination_Sched_date_close : shipment.destination_requested,
      updatetime: shipment.last_location_update_utc,
      teamflag: shipment.team_flag
    };

    try {
      const request = await clientFactory(endpoint);
      const result = await request.post('wherematruckat', payload);
			return result;
    } catch (err) {
      throw `Error retrieving expected position: ${err}`;
    }
  }
};
