import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import iotActions from '../iot/actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';
import * as _ from 'lodash';
import { filterTrackingLogs, sortTrackingLogs } from '../../utils/helperMethods/commonMethods'

export function* fetchTrackingLogsStart({ payload }) {
  try {
    let trackingLogs = yield call(api.shipments.getTrackingLogs, payload.loadNumber, payload.orderNumber, payload.trackingType, payload.loadNumbers, null, payload.shipmentId);
    yield put(actions.processTrackingLogs(trackingLogs, { column: 'EventDate', isAscending: false }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Fetch Tracking Log Error',
      message: `Tracking Log could not be retrieved. Please try again.`,
      error: e
    }));
  }
};

export function* watchFetchTrackingLogsStart() {
  yield takeEvery(types.FETCH_TRACKING_LOGS_STARTED, fetchTrackingLogsStart);
};

export function* processTrackingLogs({ payload }) {
  let trackingLogs = payload.trackingLogs;

  if (payload.sortingOptions) {
    trackingLogs = sortTrackingLogs(trackingLogs, payload.sortingOptions);
  }

  var filteredItems = filterTrackingLogs(trackingLogs);

  yield put(actions.fetchTrackingLogsComplete(filteredItems));
};

export function* watchProcessTrackingLogs() {
  yield takeEvery(types.FETCH_TRACKING_LOGS_PROCESS, processTrackingLogs);
};

export function* fetchShipmentStart({ payload }) {
  try {
    const shipment = yield call(api.shipments.fetchShipment, payload.loadNumber, payload.trackingType);
    yield put(actions.fetchShipmentComplete({ ...shipment, trackingNumber: payload.trackingNumber }, payload.orderNumber));
  }
  catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Fetch Shipment Error',
      message: `Shipment data could not be retrieved. Please try again.`,
      error: e
    }));
  }
}

export function* watchFetchShipmentStart() {
  yield takeEvery(types.FETCH_SHIPMENT_STARTED, fetchShipmentStart);
};

export function* formRouteData(trackingSummary, shipment, fetchRoute) {
  const allPromises = [];
  let currentLocationPin;
  let expectedPosition;
  let pickDropPins = [];
  const segments = [];
  const lineSegmentsTypes = [];

  if (shipment.last_location_update_utc && shipment.mode && (/(truck)/i.test(shipment.mode.toLowerCase())) && shipment.status && (/(in transit)/i.test(shipment.status.toLowerCase()))) {
    const timeWindow = 90;
    const currentTime = new Date();
    const timeDiffInMins = (currentTime - new Date(shipment.last_location_update_utc)) / 1000 / 60;

    if (timeDiffInMins >= timeWindow && fetchRoute) {
      expectedPosition = yield call(api.routing.fetchExpectedPosition, shipment);
    };
  }
  var relyOnLoadFound = trackingSummary.loads.some(l => l.found != null);
  trackingSummary.loads.forEach(load => {
    let getActualRoute = false;
    if (relyOnLoadFound && !load.found) {
      return;
    }
    if (load.unplannedLoad === true) {
      return;
    }
    if (load.current_location) {
      currentLocationPin = load.current_location;
    }
    // for order details
    if (trackingSummary.order) {
      currentLocationPin = trackingSummary.order.current_location;
    }
    if (load.mode && (/(truck|ltl|flatbed|reefer|reefer ltl|tautliner|bulk|parcel)/i.test(load.mode.toLowerCase()))) {
      getActualRoute = true;
    }
    pickDropPins = pickDropPins.concat(load.stops);
    pickDropPins = pickDropPins.filter(x => x.isVisible !== false);
    const lastHandledStopIndex = pickDropPins.map(x => !!x.stopCompleteDateTime || !!x.arrivedDateTime).lastIndexOf(true);
    const shouldInsertCurrentPositionStop = lastHandledStopIndex > -1 && lastHandledStopIndex < pickDropPins.length - 1;

    let stopsData = pickDropPins.slice(1).map((stop, i) => ({ stop1: pickDropPins[i], stop2: stop }));
    const solidLinesSlicingIndex = lastHandledStopIndex === -1 ? 0 : lastHandledStopIndex;
    const solidLineSegments = stopsData.slice(0, solidLinesSlicingIndex).map(x => ({ data: x, lineType: 'solid' }));
    const dashedLineSegments = stopsData.slice(solidLinesSlicingIndex).map(x => ({ data: x, lineType: 'dashed' }));

    if (shouldInsertCurrentPositionStop) {
      // insert current postition stop between last completed stop and first incomplete stop
      const currentLocationStop = { coordinate: { Lat: load.current_location.lat, Lon: load.current_location.lon } };
      solidLineSegments.push({ data: { stop1: pickDropPins[lastHandledStopIndex], stop2: currentLocationStop }, lineType: 'solid' });
      dashedLineSegments.splice(0, 1, { data: { stop1: currentLocationStop, stop2: pickDropPins[lastHandledStopIndex + 1] }, lineType: 'dashed' });
    }

    let allLineSegments = [...solidLineSegments, ...dashedLineSegments].filter(x => x.isVisible !== false);

    if (getActualRoute && fetchRoute) {
      allLineSegments = allLineSegments.map(x => ({ data: api.routing.fetchRoute(x.data), lineType: x.lineType }));
    }

    allLineSegments.forEach(x => {
      lineSegmentsTypes.push(x.lineType);
      allPromises.push(x.data);
    });
  });

  const result = yield Promise.all(allPromises);
  yield lineSegmentsTypes;
  yield call((result) => {
    result.forEach((r, index) => {
      let segmentType = lineSegmentsTypes[index];
      segments.push(buildRouteSegment(r, segmentType, segmentType === 'solid' ? "#4A90E2" : null));
    });
  }, result);
  const payload = { 'routingSegments': segments, 'pickDropPins': pickDropPins, 'currentLocation': currentLocationPin };
  payload.expectedPosition = expectedPosition ? payload.expectedPosition = expectedPosition.data : null;
  return payload;
}

export function* fetchTrackingSummaryStart({ payload }) {
  try {
    const trackingSummary = yield call(api.shipments.getTrackingSummary, payload.trackingNumber, payload.trackingType, null, null, false, payload.shipmentId);
    let shipment;

    if (payload.trackingType === "Order" || payload.trackingType === "GFS" || payload.trackingType === "T7") {
      shipment = trackingSummary.loads.find(l => {
        return (l.customer_order_number == payload.trackingNumber && l.loadNumber == payload.loadNumber);
      });
    } else {
      shipment = trackingSummary.loads[0];
    }

    yield put(actions.fetchShipmentComplete({ ...shipment, trackingNumber: payload.trackingNumber }, payload.orderNumber, trackingSummary.loadNumbers));

    if (shipment.iot_tracked) {
      yield all([
        put(iotActions.fetchIotDetailRecords({ trackingNumber: shipment.loadNumber })),
        put(iotActions.fetchIotAverages(shipment.loadNumber)),
        put(iotActions.updateUseIotMap(true))
      ]);
    }

    if (trackingSummary.loads) {
      const payload = yield* formRouteData(trackingSummary, shipment, true);
      yield put(actions.storeRouteData(payload));
    }
    yield put(actions.fetchTrackingSummaryComplete(trackingSummary));
  } catch (e) {
    yield all([
      put(actions.fetchTrackingSummaryFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Tracking Summary Error',
        message: `Part or all of the tracking summary could not be retrieved. Please try again.`,
        error: e
      }))
    ]);
  }
}

function buildRouteSegment(segment, lineType, color) {
  var routeLine = {
    class: 'Polyline',
    locations: [],
    color: color || '#4A4A4A',
    lineType: lineType
  };

  if (segment.data && segment.data.length > 1) {
    segment.data.forEach(point => {
      routeLine.locations.push({
        lat: point[0],
        lon: point[1]
      });
    });
  } else {
    routeLine.locations.push({
      lat: segment.stop1.coordinate.Lat,
      lon: segment.stop1.coordinate.Lon
    });
    routeLine.locations.push({
      lat: segment.stop2.coordinate.Lat,
      lon: segment.stop2.coordinate.Lon
    });
  }
  return routeLine;
}

export function* watchFetchTrackingSummaryStart() {
  yield takeEvery(types.FETCH_TRACKING_SUMMARY_STARTED, fetchTrackingSummaryStart);
}
