import React from 'react'
import CreatePasswordContainer from '../../../containers/users/CreatePasswordContainer';
import {CreatePasswordPageRBAC} from './rbac';

const CreatePasswordPage = function CreatePasswordPage(props) {
  return (
    <div className="createPasswordPage">
      <CreatePasswordContainer {...props} />
    </div>
  );
}

CreatePasswordPage._RBAC = CreatePasswordPageRBAC;

export default CreatePasswordPage