import { all } from 'redux-saga/effects';
import * as bookmark from './app/containers/users/bookmarks/sagas';
import * as conversation from './app/containers/conversation/sagas';
import * as downloads from './app/containers/downloads/sagas';
import * as exporting from './app/containers/export/sagas';
import * as facilities from './app/containers/facilities/sagas';
import * as filters from './app/containers/filters/sagas';
import * as filterSets from './app/containers/users/filter-sets/sagas';
import * as globalAlert from './app/containers/global-success-error-alerts/sagas';
import * as incidents from './app/containers/incidents/sagas';
import * as inventory from './app/containers/inventory/sagas';
import * as iot from './app/containers/iot/sagas';
import * as metadata from './app/containers/metadata/sagas';
import * as notifications from './app/containers/notifications/sagas';
import * as orderTrackingSummary from './app/containers/order-tracking-summary/sagas';
import * as ports from './app/containers/ports/sagas';
import * as scorecard from './app/containers/scorecard/sagas';
import * as shipment from './app/containers/shipments/sagas';
import * as shipmentsHealth from './app/containers/landing/sagas';
import * as trackingSummary from './app/containers/tracking-summary/sagas';
import * as shipmentsOrderSummary from './app/containers/shipmentsOrderSummary/sagas';
import * as shipmentWarehouseSummary from './app/containers/externalShipmentWarehouse/sagas';
import * as tracking from './app/containers/tracking/sagas';

import * as users from './app/containers/users/sagas';
import * as authFactory from './app/containers/auth/sagas';
import * as socket from './app/containers/socket/sagas';

const auth = authFactory.default();

export default function* rootSaga() {
  yield all([
    auth.watchSetAuthedToStartTracking(),
    auth.watchUpdateTokensStart(),
    bookmark.watchDeleteBookmarkStart(),
    bookmark.watchFetchBookmarksStart(),
    bookmark.watchFetchOneBookmarkStart(),
    bookmark.watchSaveBookmarkStart(),
    conversation.watchFetchConversationStart(),
    downloads.watchGetExportDownloadStart(),
    exporting.watchExportDataStart(),
    exporting.watchGetExportSelectionsStart(),
    facilities.watchGetFacilitiesStart(),
    filters.watchTenantDynamicFiltersStart(),
    filterSets.watchDeleteFilterSetStart(),
    filterSets.watchFetchFilterSetStart(),
    filterSets.watchSaveFilterSetStart(),
    filterSets.watchUpdateFilterSetStart(),
    globalAlert.watchTriggerErrorToastStart(),
    globalAlert.watchTriggerSuccessToast(),
    incidents.watchCreateIncidentStart(),
    incidents.watchDeleteIncidentStart(),
    incidents.watchGetIncidentsStart(),
    incidents.watchUpdateIncidentStart(),
    inventory.watchFetchInventoryDetailsStart(),
    inventory.watchFetchInventoryStart(),
    iot.watchFetchActiveIotShipmentsStart(),
    iot.watchFetchIotAveragesStart(),
    iot.watchFetchIotDetailRecordsStart(),
    iot.watchFetchIotMapLogsStart(),
    iot.watchFetchIotSensorLocationsStart(),
    metadata.watchGetTenantFlowsStart(),
    metadata.watchGetTenantMetadataStart(),
    metadata.watchGetTenantRegionsStart(),
    notifications.watchFetchNotificationsStart(),
    orderTrackingSummary.watchFetchTrackingLogsStart(),
    orderTrackingSummary.watchFetchTrackingSummaryStart(),
    orderTrackingSummary.watchProcessTrackingLogs(),
    ports.watchGetPortsStart(),
    scorecard.watchFetchScorecardCarriersStart(),
    scorecard.watchFetchScorecardStart(),
    shipment.watchFetchAffectedShipments(),
    shipment.watchFetchExceptionsStart(),
    shipment.watchFetchListShipmentsStart(),
    shipment.watchSearchShipmentsStart(),
    shipment.watchToggleShipmentsDisplayMode(),
    shipment.watchMapChange(),
    shipment.watchConfigurationChanges(),
    shipment.watchNavigateButtons(),
    shipment.watchClusterSelect(),
    shipment.watchNewShipmentsQueryConfiguration(),
    shipment.watchNewShipmentsQueryConfigurationUpdated(),
    shipment.watchShipmentsQueryConfigurationChange(),
    shipment.watchShipmentFiltersChange(),
    shipment.watchShipmentFacilityFiltersChange(),
    shipment.watchShipmentDateFilterChange(),
    shipmentsHealth.watchFetchSupplyChainHealth(),
    shipmentsHealth.watchFetchTrendingShipments(),
    shipmentsHealth.watchLandingPageConfig(),
    shipmentsHealth.watchToggleCovidActive(),
    shipmentsHealth.watchPowerBiItemsStart(),
    shipmentsOrderSummary.watchFetchOrderSummaryStart(),
    shipmentWarehouseSummary.watchFetchShipmentOrdersWarehouseSummaryStart(),
    socket.watchConfigureSocketStart(),
    tracking.watchFetchTrackingMilestonesStart(),
    tracking.watchFetchTrackingShipmentStart(),
    tracking.watchProcessTrackingLogs(),
    trackingSummary.watchFetchShipmentStart(),
    trackingSummary.watchFetchTrackingLogsStart(),
    trackingSummary.watchFetchTrackingSummaryStart(),
    trackingSummary.watchProcessTrackingLogs(),
    users.watchChangePasswordStart(),
    users.watchCreateAlertRulesStart(),
    users.watchCreateUserStart(),
    users.watchDeleteAlertRulesStart(),
    users.watchDeleteUserStart(),
    users.watchExistingUserStart(),
    users.watchFetchAlertRulesStart(),
    users.watchFetchPermissionsStart(),
    users.watchFetchUserProfileStart(),
    users.watchFetchUsersStart(),
    users.watchFetchUserStart(),
    users.watchFetchUserToCopyStart(),
    users.watchSearchForUsersTypeaheadStart(),
    users.watchSendVerificationCodeStart(),
    users.watchUpdateAlertRulesStart(),
    users.watchUpdateUserPhoneNumberStart(),
    users.watchUpdateUserStart(),
    users.watchValidateVerificationCodeStart()
  ]);
};
