import { combineReducers } from 'redux';

import authReducer from './app/containers/auth/reducer';
import bookmarksReducer from './app/containers/users/bookmarks/reducer';
import conversationReducer from './app/containers/conversation/reducer';
import { disruptionsMapReducer, mainMapReducer, shipmentMapReducer } from './app/containers/map/reducer';
import downloadsReducer from './app/containers/downloads/reducer';
import dynamicFiltersReducer from './app/containers/filters/reducer';
import facilitiesReducer from './app/containers/facilities/reducer';
import filterSetsReducer from './app/containers/users/filter-sets/reducer';
import incidentsReducer from './app/containers/incidents/reducer';
import inventoryReducer from './app/containers/inventory/reducer';
import iotReducer from './app/containers/iot/reducer';
import metadataReducer from './app/containers/metadata/reducer';
import modalReducer from './app/containers/base-modal/reducer';
import notificationsReducer from './app/containers/notifications/reducer';
import orderTrackingSummaryReducer from './app/containers/order-tracking-summary/reducer';
import portsReducer from './app/containers/ports/reducer';
import scorecardReducer from './app/containers/scorecard/reducer';
import shipmentsReducer from './app/containers/shipments/reducer';
import sidenavReducer from './app/containers/chr-side-nav/reducer';
import supplyChainHealthReducer from './app/containers/landing/reducer';
import trackingSummaryReducer from './app/containers/tracking-summary/reducer';
import shipmentsOrderSummaryReducer from './app/containers/shipmentsOrderSummary/reducer';
import shipmentWarehouseSummaryReducer from './app/containers/externalShipmentWarehouse/reducer';
import usersReducer from './app/containers/users/reducer';
import trackingShipmentReducer from './app/containers/tracking/reducer'; 
import exportReducer from './app/containers/export/reducer'
import socketReducer from './app/containers/socket/reducer'

export default function rootReducer(injectedReducers) {
  return combineReducers({
    auth: authReducer,
    baseModal: modalReducer,
    bookmarks: bookmarksReducer,
    conversation: conversationReducer,
    disruptionsMap: disruptionsMapReducer,
    downloads: downloadsReducer,
    facilityLocations: facilitiesReducer,
    filters: dynamicFiltersReducer,
    filterSets: filterSetsReducer,
    incidents: incidentsReducer,
    inventory: inventoryReducer,
    iotSummary: iotReducer,
    map: mainMapReducer,
    notifications: notificationsReducer,
    orderTrackingSummary: orderTrackingSummaryReducer,
    portsData: portsReducer,
    scorecard: scorecardReducer,
    shipmentMap: shipmentMapReducer,
    shipments: shipmentsReducer(),
    sidenav: sidenavReducer,
    supplyChainHealth: supplyChainHealthReducer,
    tenantMetadata: metadataReducer,
    trackingSummary: trackingSummaryReducer,
    shipmentsOrderSummary: shipmentsOrderSummaryReducer,
    shipmentWarehouseSummary: shipmentWarehouseSummaryReducer,
    users: usersReducer,
    export: exportReducer,
    externalTracker: trackingShipmentReducer,
    socket: socketReducer,
    ...injectedReducers
  });
};
