import React, { Component } from 'react';
import {ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Area, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import LoadingIcon from '../loading-icon';

export default class AreaGraph extends Component {
  drawLines = () => {
    const { areaStrokeColor, lineType, setFill, seriesDataSelector } = this.props;

    let lines = [];
    seriesDataSelector.forEach((val, idx) => {
      lines.push(
        <Area key={idx} type={lineType} dataKey={val} stroke={areaStrokeColor} fill={setFill(val)}/>
      );
    });

    return lines;
  }

  render() {
    const { allowDuplicatedCategory, dataKey, height, isEmptyText, loading, series, toolTipContent, width } = this.props;

    return (
      <div className='area-chart card' data-test="AreaGraph" style={{ width: `${width}%`, height: `${height}px` }}>
        { loading ?
          <div style={{margin: "0 auto", paddingTop: `${height / 4}px`}}><LoadingIcon/></div> :
            <React.Fragment>
              {series.length ?
              <ResponsiveContainer >
                <AreaChart data={series} margin={{top: 10, right: 0, left: 0, bottom: -10}}>
                  <CartesianGrid />
                  <XAxis dataKey={dataKey} allowDuplicatedCategory={allowDuplicatedCategory}/>
                  <YAxis />
                  <Tooltip content={toolTipContent}/>
                    {this.drawLines()}
                </AreaChart>
              </ResponsiveContainer>
            : <p>{isEmptyText}</p>}
          </React.Fragment>
        }
      </div>
    );
  }
}

AreaGraph.propTypes = {
  areaStrokeColor: PropTypes.string,
  allowDuplicatedCategory: PropTypes.bool,
  dataKey: PropTypes.string,
  height: PropTypes.number,
  isEmptyText: PropTypes.string,
  lineType: PropTypes.string,
  loading: PropTypes.bool,
  series: PropTypes.array,
  seriesDataSelector: PropTypes.array,
  width: PropTypes.number
};

AreaGraph.defaultProps = {
  areaStrokeColor: '#areaStrokeColor',
  allowDuplicatedCategory: false,
  dataKey: 'date',
  height: 100,
  lineType: 'linear',
  isEmptyText: '',
  loading: false,
  series: {},
  seriesDataSelector: [],
  width: 100
}
