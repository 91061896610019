/* Map Layers */
export const AIR_LAYER = 'luminosity-ui/map/AIR_LAYER';
export const CURRENT_LOCATION_PIN_LAYER = 'luminosity-ui/map/CURRENT_LOCATION_PIN_LAYER';
export const IOT_DETAILS_PIN_LAYER = 'luminosity-ui/map/IOT_DETAILS_PIN_LAYER';
export const IOT_ROUTING_LAYER = 'luminosity-ui/map/IOT_ROUTING_LAYER';
export const EXPECTED_POSITION_PIN_LAYER = 'luminosity-ui/map/EXPECTED_POSITION_PIN_LAYER';
export const FACILITIES_LAYER = 'luminosity-ui/map/FACILITIES_LAYER';
export const INCIDENTS_LAYER = 'luminosity-ui/map/INCIDENTS_LAYER';
export const INCIDENTS_EDIT_LAYER = 'luminosity-ui/map/INCIDENTS_EDIT_LAYER';
export const LOCATION_PINS_LAYER = 'luminosity-ui/map/LOCATION_PINS_LAYER';
export const PIN_LAYER = 'luminosity-ui/map/PIN_LAYER';
export const PORTS_LAYER = 'luminosity-ui/map/PORTS_LAYER';
export const REGION_LAYER = 'luminosity-ui/map/REGION_LAYER';
export const ROUTING_LAYER = 'luminosity-ui/map/ROUTING_LAYER';
export const TRAFFIC_LAYER = 'luminosity-ui/map/TRAFFIC_LAYER';
export const TRUCK_LAYER = 'luminosity-ui/map/TRUCK_LAYER';
export const VESSEL_LAYER = 'luminosity-ui/map/VESSEL_LAYER';
export const WEATHER_LAYER = 'luminosity-ui/map/WEATHER_LAYER';
export const RAIL_LAYER = 'luminosity-ui/map/RAIL_LAYER';