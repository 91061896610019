import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const dynamicFiltersInitialState = fromJS({
  dynamicFilters: {},
  loading: true,
});

function dynamicFiltersReducer(state = dynamicFiltersInitialState, action = {}) {
  switch (action.type) {
    case types.FETCH_TENANT_DYNAMIC_FILTERS_STARTED:
      return state
        .set('dynamicFilters', {})
        .set('loading', true);
    case types.FETCH_TENANT_DYNAMIC_FILTERS_FAILED:
      return state
        .set('loading', false);
    case types.FETCH_TENANT_DYNAMIC_FILTERS_COMPLETED:
      return state
        .set('dynamicFilters', action.payload)
        .set('loading', false);
    default:
      return state;
  }
}

export default dynamicFiltersReducer;
