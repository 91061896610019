import * as types from './actionTypes';

export default {
  fetchTenantMetadata(tenantId) {
    return {
      type: types.FETCH_TENANT_METADATA_STARTED,
      payload: {
        tenantId: tenantId
      }
    };
  },
  fetchTenantMetadataComplete(metadata) {
    return {
      type: types.FETCH_TENANT_METADATA_COMPLETED,
      payload: metadata
    };
  },
  fetchTenantFlows() {
    return {
      type: types.FETCH_TENANT_FLOWS_STARTED,
      payload: {}
    };
  },
  fetchTenantFlowsComplete(flows) {
    return {
      type: types.FETCH_TENANT_FLOWS_COMPLETED,
      payload: flows
    };
  },
  fetchTenantRegion() {
    return {
      type: types.FETCH_TENANT_REGION_STARTED,
      payload: {}
    };
  },
  fetchTenantRegionComplete(regions) {
    return {
      type: types.FETCH_TENANT_REGION_COMPLETED,
      payload: regions
    };
  }
}
