import React from 'react'
import { connect } from 'react-redux';
import { OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import actions from '../scorecard/actions';
import metadataActions from '../metadata/actions';
import { CarrierBody, Carrier } from '../../components/scorecard';
import SelectDropdown from '../../components/dropdown/SelectDropdown';
import { popover } from '../../utils/helperMethods/commonComponents';
import LoadingIcon from '../../components/loading-icon';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';

const defaultDate =  { label: '30 Days', value: 30 };
const startTypingText = "Type three or more characters to search";
const availableModes = [
  {
    label: 'Parcel',
    value: 'Parcel'
  }, {
    label: 'LTL',
    value: 'LTL'
  }, {
    label: 'Air',
    value: 'Air'
  }, {
    label: 'Ocean',
    value: 'Ocean'
  }, {
    label: 'Truck',
    value: 'Truck'
  }, {
    label: 'Rail',
    value: 'Rail'
  }, {
    label: 'Intermodal',
    value: 'Intermodal'
  }, {
    label: 'Reefer',
    value: 'Reefer'
  }, {
    label: 'Drayage',
    value: 'Drayage'
  }
];

const availableDateRanges = [
  {
    label: 'Today',
    value: moment().startOf('day')
  }, {
    label: 'Week',
    value: moment().subtract(7, 'days')
  }, {
    label: '30 Days',
    value: moment().subtract(30, 'days')
  }, {
    label: '90 Days',
    value: moment().subtract(90, 'days')
  }, {
    label: '180 Days',
    value: moment().subtract(180, 'days')
  }
]

export class Scorecard extends React.Component {
  state = {
    modeSelection: [],
    flowSelection: [],
    dateSelection: [ defaultDate ],
    searchText: [],
    //These are to help build the dynamic query params for the request
    queryMode: null,
    queryFlow: null,
    querySince: null,
    queryCarrier: null,
    noOptionsLabel: startTypingText
  };

  componentDidMount() {
    this.props.fetchScorecard();
    this.props.fetchFlows();
    trackPageInfoEvent(this.props.adobeTrack, "Carrier Scorecard Page", ["Carrier Scorecard Page"]);
  };

  search = (searchText) => {
    if(searchText && searchText.length > 2) {
      this.setState({noOptionsLabel: "No carriers found"});
      this.props.fetchScorecardCarriers(searchText);
    }
    else {
      this.setState({noOptionsLabel: startTypingText})
    }
  };

  handleDateChange = (selectedDate) => {
    if(selectedDate) {
      const sinceDate = moment(selectedDate.value).format('YYYY-MM-DD[T]HH:mm:ss');
      this.setState({ dateSelection: selectedDate, querySince: sinceDate });
      this.props.fetchScorecard({ since: sinceDate, mode: this.state.queryMode, flow: this.state.queryFlow, carrier: this.state.queryCarrier });
    } else {
      this.setState({ dateSelection: [ defaultDate ], querySince: null });
      this.props.fetchScorecard({ since: null, mode: this.state.queryMode, flow: this.state.queryFlow, carrier: this.state.queryCarrier });
    }
  };

  handleFlowChange = (selectedFlows) => {
    if(selectedFlows && selectedFlows.length > 0) {
      let flows = [];
      let flowValues = [];
      selectedFlows.forEach(m => {
        flows.push(m);
        flowValues.push(m.value);
      });
      this.setState({ flowSelection: flows, queryFlow: flowValues.join('|') });
      this.props.fetchScorecard({ flow: flowValues.join('|'), mode: this.state.queryMode, carrier: this.state.queryCarrier, since: this.state.querySince });
    } else {
      this.setState({ flowSelection: [], queryFlow: null });
      this.props.fetchScorecard({ flow: null, mode: this.state.queryMode, carrier: this.state.queryCarrier, since: this.state.querySince });
    }
  };

  handleModeChange = (selectedModes) => {
    if(selectedModes && selectedModes.length > 0) {
      let modes = [];
      let modeValues = [];
      selectedModes.forEach(m => {
        modes.push(m);
        modeValues.push(m.value);
      });
      this.setState({ modeSelection: modes, queryMode: modeValues.join('|') });
      this.props.fetchScorecard({ mode: modeValues.join('|'), carrier: this.state.queryCarrier, since: this.state.querySince, flow: this.state.queryFlow });
    } else {
      this.setState({ modeSelection: [], queryMode: null });
      this.props.fetchScorecard({ mode: null, carrier: this.state.queryCarrier, since: this.state.querySince, flow: this.state.queryFlow });
    }
  };

  handlerCarrierSearch = (e) => {
    this.setState({ searchText: e, queryCarrier: e ? e.label : null });
    this.props.fetchScorecard({ carrier: e ? e.label : null, since: this.state.querySince, flow: this.state.queryFlow, mode: this.state.queryMode });
  };

  renderCarriers() {
    const modes = Object.keys(this.props.carriers);
    const allCarriers = [];
    for(let mode of modes) {
      this.props.carriers[mode].length > 0 && allCarriers.push(<h3 id={`${mode}-scorecard`} key={mode} className="scorecard-headers">{mode}</h3>);
      this.props.carriers[mode].forEach((carrier, i) => {
        allCarriers.push(<Carrier key={i + '|' + carrier.carrier_name + '|' + carrier.total.shipments} isAnimationActive={true} carrierName={carrier.carrier_name} in_transit={carrier.in_transit} delivered={carrier.delivered} picked_up={carrier.picked_up} carrier_compliance={carrier.carrier_compliance}></Carrier>)
      });
    }
    return allCarriers;
  };
  
  render() {
    return(
      <div>
        <h3 className="col-xs-12 col-lg-3 scorecard-headers">All Carriers - Summary&nbsp;
          <OverlayTrigger placement="bottom" overlay={popover("Carrier Summary", "This summary measures performance and compliance for all carriers and modes below for delivered shipments. Please note that searching by carrier will not adjust the 'All Carrier Summary' counts.", "allCarriersSummaryScorecardHelp")}>
            <svg style={{ marginBottom: '0.3rem' }} className="icon small faded" data-test="allCarriersSummaryScorecardHelpPopover"><use href="#help" style={{ 'pointerEvents': 'none' }}  /></svg>
          </OverlayTrigger>
        </h3>
        <div id="carrierFilterSection" className="col-xs-12 col-lg-9">
          <div className="col-xs-12 col-sm-3">
            <SelectDropdown
              id={'carrier-filter-select'}
              isClearable={true}
              placeholder={"Search for a carrier"}
              handleDropdownChange={this.handlerCarrierSearch}
              selectedValues={this.state.searchText}
              options={
                this.props.carriersAutocomplete.map(c => {
                  return { label: c.key, value: c.doc_count }
                })
              }
              onInputChange={e => this.search(e)}
              isLoading={this.props.autocompleteLoading}
              closeMenuOnSelect={true}
              noOptionsLabel={this.state.noOptionsLabel}
            />
          </div>
          <div className="col-xs-12 col-sm-3">
            <SelectDropdown
              id={'date-range-filter-select'}
              isClearable={true}
              isMultiSelect={false}
              closeMenuOnSelect={true}
              options={availableDateRanges}
              placeholder={"Date Range"}
              stateName={"dateRange"}
              handleDropdownChange={this.handleDateChange}
              selectedValues={this.state.dateSelection}
            />
          </div>
          <div className="col-xs-12 col-sm-3">
            <SelectDropdown
              id={'flow-filter-select'}
              isClearable={true}
              isMultiSelect={true}
              closeMenuOnSelect={false}
              options={
                this.props.flows.filter((f, i, flows) => {
                  return flows.findIndex(t => {
                    return (t.label === f.label)
                  }) === i
                })
              }
              placeholder={"Flow"}
              stateName={"flow"}
              handleDropdownChange={this.handleFlowChange}
              selectedValues={this.state.flowSelection}
            />
          </div>
          <div className="col-xs-12 col-sm-3">
            <SelectDropdown
              id={'mode-filter-select'}
              isClearable={true}
              isMultiSelect={true}
              closeMenuOnSelect={false}
              options={availableModes}
              placeholder={"Mode"}
              stateName={"mode"}
              handleDropdownChange={this.handleModeChange}
              selectedValues={this.state.modeSelection}
            />
          </div>
        </div>
        <span>
          {this.props.loading ?
          <div className="centered"><LoadingIcon></LoadingIcon></div> :
          <React.Fragment>
            {this.props.carrierSummary && <CarrierBody isAnimationActive={true} in_transit={this.props.carrierSummary.in_transit} delivered={this.props.carrierSummary.delivered} picked_up={this.props.carrierSummary.picked_up} carrier_compliance={this.props.carrierSummary.carrier_compliance}></CarrierBody>}
            <div style={{marginBottom: '3rem'}} className="col-xs-12">
              {(this.props.carriers && this.props.carriers.ocean) ? this.renderCarriers() : <h3>No carriers have been found. Please try widening filters or searching a different carrier.</h3>}
            </div>
          </React.Fragment>
          }
        </span>
      </div>
    )
  };
}

export const mapStateToProps = (state) => {
  return {
    carrierSummary: state.scorecard.get('summary'),
    carriers: state.scorecard.get('carriers'),
    flows: state.tenantMetadata.get('flows').map(f => {
      return { label: f.name, value: f.name }
    }),
    loading: state.scorecard.get('loading'),
    carriersAutocomplete: state.scorecard.get('carriersAutocomplete'),
    autocompleteLoading: state.scorecard.get('autocompleteLoading')
  };
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchScorecard: (payload) => {
      return dispatch(actions.fetchScorecard(payload));
    },
    fetchScorecardCarriers: (payload) => {
      return dispatch(actions.fetchScorecardCarriers(payload));
    },
    fetchFlows: () => {
      return dispatch(metadataActions.fetchTenantFlows());
    }
  };
};

export const ScorecardContainer = connect(mapStateToProps, mapDispatchToProps)(Scorecard);