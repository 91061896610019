import {
  fromJS
} from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  comments: [],
  group: [],
  activeUsers: {}
});

function conversationReducer(state = initialState, action={}) {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case types.FETCH_CONVERSATIONS_COMPLETED:
      return state.set('comments', action.payload.conversation.comments)
        .set('group', action.payload.conversation.group);
    case types.ACTIVE_VIEWER_JOINED:
      newState.activeUsers[action.payload.event.user_id] = action.payload.event;
      return state.set('activeUsers', newState.activeUsers);
    case types.ACTIVE_VIEWER_LEFT:
      delete newState.activeUsers[action.payload.event.user_id];
      return state.set('activeUsers', newState.activeUsers);
    case types.INIT_JOIN_ACTIVE_USERS:
      newState.activeUsers = { ...newState.activeUsers, ...action.payload.event}
      return state.set('activeUsers', newState.activeUsers);
    case types.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}

export default conversationReducer;
