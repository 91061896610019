import { put, call, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import * as actionTypes from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';
 export function* fetchNotificationsStart() {
  try {
    const resp = yield call(api.alerts.fetchAlerts);
    if(typeof resp.data === "object"){
      yield put(actions.fetchNotificationsCompleted(resp.data));
    }
  }
  catch (err) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({title:'Error Retrieving Notifications:', message:err.message}));
  }
}
 export function* watchFetchNotificationsStart() {
  yield takeEvery(actionTypes.FETCH_NOTIFICATIONS_STARTED, fetchNotificationsStart);
}
