import React from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import actions from './actions';
import InventoryDetailsTable from '../../components/inventory/InventoryDetailsTable';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';

export class InventoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier: props.match.params.identifier || '',
      sortBy: '',
      order: ''
    }
  }

  componentDidMount() {
    const location = queryString.parse(this.props.location.search);
    const payload = { sort: { identifier: this.state.identifier } };

    if (!_.isEmpty(location)) {
      var sortData = {
        sortBy: location.sortBy,
        order: location.order
      }
      this.setState(Object.assign(this.state, sortData));

      var pathName = this.props.history.location.pathname;
      this.props.history.replace(pathName + '?' + `sortBy=${this.state.sortBy}` + `&order=${this.state.order}`);

      payload.sort.field = this.state.sortBy
      payload.sort.order = this.state.order
    }

    this.props.getInventoryDetails(payload);
    trackPageInfoEvent(this.props.adobeTrack, "Inventory Details Page", ["Inventory Details Page"]);
  }

  sort = (field) => {
    var payload = {
      sort: {
        identifier: this.state.identifier,
        field: field,
        order: (this.state.order == 'asc') ? 'desc' : 'asc'
      }
    }
    this.setState(Object.assign(this.state, { sortBy: field, order: payload.sort.order }))
    this.props.getInventoryDetails(payload)

    var pathName = this.props.history.location.pathname;
    this.props.history.replace(pathName + '?' + `sortBy=${this.state.sortBy}` + `&order=${this.state.order}`)
  }

  toMainPage = () => {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="col-sm-12">
        <div className="space-top">
          <a id="tomainpage" onClick={() => { this.toMainPage() }}>INVENTORY</a>
        </div>
        <InventoryDetailsTable
          sort={this.sort}
          state={this.state}
          unrestricted={this.props.unrestricted}
          facilities={this.props.facilities}
          loading={this.props.loading}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    unrestricted: state.inventory.get('unrestricted'),
    facilities: state.inventory.get('facilities'),
    loading: state.inventory.get('detailsLoading')
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInventoryDetails: (payload) => {
      return dispatch(actions.fetchInventoryDetails(payload));
    }
  };
}

export const InventoryDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(InventoryDetails);

