import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  showModal: false,
  showCloseButton: true,
  showActionButton: true,
  handleAction:()=>{ return null; },
  actionButtonText:'Accept',
  title:'Modal Title',
  body: null,
  closeButtonClass: 'btn btn-link'
});

export default function modalReducer(state=initialState, action={}){
  switch(action.type){
    case types.CLOSE_MODAL:
      return state
        .set('showModal', false);
    case types.OPEN_MODAL:
    return state
    .set('showModal', true)
    .set('showCloseButton', action.payload.showCloseButton)
    .set('showActionButton', action.payload.showActionButton)
    .set('actionButtonText', action.payload.actionButtonText)
    .set('closeButtonText', action.payload.closeButtonText)
    .set('actionButtonClass', action.payload.actionButtonClass)
    .set('closeButtonClass', action.payload.closeButtonClass)
    .set('handleAction', action.payload.handleAction)
    .set('handleCloseAction', action.payload.handleCloseAction)
    .set('title', action.payload.title)
    .set('body', action.payload.body)
    .set('size', action.payload.size)
    .set('backdrop', action.payload.backdrop);
    default:
      return state;
  }
};
