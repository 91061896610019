import {
  fromJS
} from 'immutable';
import * as types from './actionTypes';
 export const initialState = fromJS({
  commentAlerts: [],
  shipmentAlerts: [],
  hasNewAlerts:false
});

const notificationsListSize = 10;

 function conversationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.HANDLE_INCOMING_NOTIFICATIONS:
      let newAlerts = undefined;
      const alertName = action.payload.alertType + 'Alerts';
      newAlerts = state.get(alertName).toJS();
      newAlerts.unshift(action.payload);
      if (newAlerts.length > notificationsListSize) {
        newAlerts.pop();
      }
      return state.set(alertName, fromJS(newAlerts)).set('hasNewAlerts',fromJS(true));
    case types.FETCH_NOTIFICATIONS_COMPLETED:
        return state.set('shipmentAlerts', fromJS(action.payload.shipment)).set('commentAlerts', fromJS(action.payload.comment));
    case types.VIEWED_NOTIFICATIONS:
        return state.set('hasNewAlerts',fromJS(false));
    default:
      return state;
  }
}
 export default conversationReducer;
