import React from 'react';
import { toKebabCase } from '../../utils/helperMethods/commonMethods';

const modeOptions = ['Air', 'LTL', 'Truck', 'Ocean', 'Parcel', 'Rail', 'Intermodal', 'Reefer'];

const operatorOptions = {
  lessThan: {
    operator: "lessThan",
    operatorLabel: "Less Than"
  },
  equals: {
    operator: "equal",
    operatorLabel: "Equals"
  },
  greaterThan: {
    operator: "greaterThan",
    operatorLabel: "Greater Than"
  }
};

const fieldOptions = [
  {
    field: "item.sku",
    fieldLabel: "SKU",
    operators: [operatorOptions.equals],
    type: "text"
  },
  {
    field: "item.product_name",
    fieldLabel: "Product Name",
    operators: [operatorOptions.equals],
    type: "text"
  },
  {
    field: "origin_name",
    fieldLabel: "Origin Name",
    operators: [operatorOptions.equals],
    type: "text"
  },
  //will either resolve to a shipments load number or its shipment number based on tenant
  {
    field: "load_number",
    fieldLabel: "Shipment Number",
    operators: [operatorOptions.equals],
    type: "text"
  },
  {
    field: "customer_order_number",
    fieldLabel: "Order Number",
    operators: [operatorOptions.equals],
    type: "text"
  },
  {
    field: "destination_name",
    fieldLabel: "Destination Name",
    operators: [operatorOptions.equals],
    type: "text"
  },
  {
    field: "mode",
    fieldLabel: "Mode",
    operators: [operatorOptions.equals],
    type: modeOptions
  },
  {
    field: "region",
    fieldLabel: "Region",
    operators: [operatorOptions.equals],
    type: []
  },
  {
    field: "flow",
    fieldLabel: "Flow",
    operators: [operatorOptions.equals],
    type: []
  },
  {
    field: "item.units",
    fieldLabel: "Units",
    operators: [
      operatorOptions.lessThan,
      operatorOptions.equals,
      operatorOptions.greaterThan
    ],
    type: "number"
  },
  {
    field: "total_value",
    fieldLabel: "Product Value",
    operators: [
      operatorOptions.lessThan,
      operatorOptions.equals,
      operatorOptions.greaterThan
    ],
    type: "number"
  }
];

export default class EventNotificationFilter extends React.Component {
  handleFieldChange = (e) => {
    if (e.target.value && e.target.value !== this.props.condition.field) {
      const option = fieldOptions.find(x => x.field === e.target.value);
      if (option && option.operators.length > 1) {
        this.props.handleUpdateCondition(this.props.filterIdx, {
          field: e.target.value,
          operator: option.operators[1].operator,
          value: ''
        });
      } else {
        this.props.handleUpdateCondition(this.props.filterIdx, {
          field: e.target.value,
          operator: option.operators[0].operator,
          value: ''
        });
      }
    }
  };

  handleOperatorChange = (e) => {
    this.props.handleUpdateCondition(this.props.filterIdx, {
      field: this.props.condition.field,
      operator: e.target.value,
      value: this.props.condition.value
    });
  };

  handleValueChange = (e) => {
    this.props.handleUpdateCondition(this.props.filterIdx, {
      field: this.props.condition.field,
      operator: this.props.condition.operator,
      value: e.target.value
    });
  };

  getOperatorJsx = () => {
    if(this.props.condition.field){
      const option = fieldOptions.find(x => x.field === this.props.condition.field);
      if(option && option.operators.length > 1) {
        return (
          <div className="form-group special-margin" data-test="multi">
            <select onChange={this.handleOperatorChange} className="form-control" disabled={this.props.disabled}>
              {
                option.operators.map(o =>
                  <option id={`notification-${this.props.notificationIdx}-${o.operator}`} key={o.operator} value={o.operator} selected={this.props.condition.operator === o.operator}>
                    {o.operatorLabel}
                  </option>
                )
              }
            </select>
          </div>
        );
      } else {
        return (
          <div className="form-group special-margin" data-test="single">
            <select onChange={this.handleOperatorChange} className="form-control" disabled={true}>
              <option selected={true}>{option.operators[0].operatorLabel}</option>
              <option selected={false}>Greater Than</option>
            </select>
          </div>
        );
      }
    }
    return null;
  }

  renderDropdownLists = (option) => {
    //Need to map flows and regions here because this comes in from props and isn't avaible right away above
    fieldOptions.filter(x => x.field === 'flow')[0].type = this.props.flows;
    fieldOptions.filter(x => x.field === 'region')[0].type = this.props.regions;
    const mapping = fieldOptions.filter(x => x.field === option.field);
    const allOptions = [];
    allOptions.push(<option key={'empty'}></option>)
    if(mapping.length > 0) {
      mapping[0] && mapping[0].type.map(y => {
        allOptions.push(<option value={y} id={`notification-${this.props.notificationIdx}-${toKebabCase(y)}-option`} key={y}>{y}</option>);
      });
    }
    return allOptions;
  };

  getValueJsx = () => {
    if(this.props.condition.field){
      const option = fieldOptions.find(x => x.field === this.props.condition.field);
      return (
        <div className="form-group">
          {
            typeof(option.type) === 'object' ?
              //These are the pre-populated drop downs like mode, flow and region
              <select className="form-control" id={`notification-${this.props.notificationIdx}-if-select-second-input`} value={this.props.condition.value} disabled={this.props.disabled} onChange={this.handleValueChange}>
                {this.renderDropdownLists(option)}
              </select>
            :
              //Otherwise use the simple input box
              <input
                id={`notification-${this.props.notificationIdx}-if-text-input`}
                className="form-control"
                type={option.type}
                onChange={this.handleValueChange}
                disabled={this.props.disabled}
                value={this.props.condition.value}/>
          }
        </div>
      )
    }
    return null;
  }

  render() {
    return (
      <form data-test="root">
        <div className="form-inline">
          <div className="form-group">
            <select onChange={this.handleFieldChange} className="form-control" id={`notification-${this.props.notificationIdx}-if-select-${this.props.filterIdx}-input`} name="fieldSelect" disabled={this.props.disabled}>
              <option selected={!this.props.condition.field} value=''></option>
              {fieldOptions.map(x => {
                return (
                  <option key={x.field} value={x.field} selected={x.field === this.props.condition.field} id={`notification-${this.props.notificationIdx}-${x.field}-option`} >{x.fieldLabel}</option>
                );
              })}
            </select>
          </div>
          {this.getOperatorJsx()}
          {this.getValueJsx()}
        </div>
      </form>
    );
  }
}
