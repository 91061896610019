import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

class PaginationGroup extends React.Component {
  goToFirstPage = () => {
    if (this.props.currentPageNumber !== 1) {
      this.props.fetchNextPage(1);
      this.scrollToTop();
    }
  }
  goToLastPage = () => {
    if (this.props.currentPageNumber !== this.props.totalPages) {
      this.props.fetchNextPage(this.props.totalPages);
      this.scrollToTop();
    }
  }
  goToNextPage = () => {
    if (this.props.currentPageNumber + 1 <= this.props.totalPages) {
      this.props.fetchNextPage(this.props.currentPageNumber + 1);
      this.scrollToTop();
    }
  }
  goToPreviousPage = () => {
    if (this.props.currentPageNumber - 1 > 0) {
      this.props.fetchNextPage(this.props.currentPageNumber - 1);
      this.scrollToTop();
    }
  }
  goToPage = (e) => {
    const pageNumber = parseInt(e.target.innerText);
    if (this.props.currentPageNumber !== pageNumber) {
      this.props.fetchNextPage(pageNumber);
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    const page = document.querySelector('.details-container');
    if (page) document.querySelector('.details-container').scrollTop = 0;
  }

  renderPages(hideLastPage) {
    let pages = [];
    for (let x = 1; x <= this.props.totalPages; x++) {
      if (x === this.props.currentPageNumber - 1
        || x === this.props.currentPageNumber
        || x === this.props.currentPageNumber + 1
        || (!hideLastPage && x === this.props.totalPages)) {
        pages.push(<Pagination.Item id={`page-${x}-button`} key={x} active={x === this.props.currentPageNumber} onClick={this.goToPage}>{x}</Pagination.Item>);
      } else if (x === this.props.currentPageNumber - 3
        || x === this.props.currentPageNumber + 3) {
        pages.push(<Pagination.Ellipsis key={x} id={`page-ellipsis-button`}/>);
      }
    }
    return pages;
  }
  render() {
    const isLastPage = (this.props.offset + this.props.limit >= this.props.total);
    const hideLastPage = this.props.hideLastPage || false;
    return (
      <div className={this.props.className}>
        <Pagination id={`pagination-group`}>
          <Pagination.First onClick={this.goToFirstPage} id={'first-page-button'}/>
          <Pagination.Prev onClick={this.goToPreviousPage} id={'previous-page-button'}/>
          {this.renderPages(hideLastPage)}
          <Pagination.Next onClick={this.goToNextPage} id={'next-page-button'}/>
          { !hideLastPage && <Pagination.Last onClick={this.goToLastPage} id={'last-page-button'}/> }
        </Pagination>
      </div>
    );
  }
}

PaginationGroup.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPageNumber: PropTypes.number.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  hideLastPage: PropTypes.bool
};

export default PaginationGroup;
