import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  bookmarks: [],
  bookmark: [],
  loading: true
});

function bookmarksReducer(state = initialState, action={}) {
  switch (action.type) {
    case types.FETCH_BOOKMARKS_STARTED:
    case types.DELETE_BOOKMARK_STARTED:
      return state
        .set('loading', true);
    case types.FETCH_BOOKMARKS_FAILED:
      return state
        .set('loading', false);
    case types.FETCH_BOOKMARKS_COMPLETED:
      return state
        .set('bookmarks', action.payload.bookmarks)
        .set('loading', false);
    case types.FETCH_ONE_BOOKMARK_COMPLETED:
      return state
        .set('bookmark', action.payload.bookmark.data);
    default:
      return state;
  }
}

export default bookmarksReducer;
