import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  ports: []
});

function portsReducer(state = initialState, action={}) {
  switch (action.type) {
    case types.FETCH_PORTS_COMPLETED:
      return state
        .set('ports', action.payload.ports);
    default:
      return state;
  }
}

export default portsReducer;
