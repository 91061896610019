import React from 'react'
import { InventoryDetailsContainer } from '../../containers/inventory/InventoryDetailsContainer';

export default function InventoryDetails(props) {

  return (
    <div id="inventorydetailspage">
      <div className="container-fluid">
      <InventoryDetailsContainer {...props} />
      </div>
    </div>
  );
}
