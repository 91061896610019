import React, { Component } from 'react';
import { connect } from 'react-redux';
import shipmentActions from '../../containers/shipments/actions';
import './extras/style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger } from 'react-bootstrap';
import { tooltip } from '../../utils/helperMethods/commonComponents';
import { MultiSearchDropdown } from './';
import Icon from '../../components/icon';
import { EAPI_V2_TENANTS, JOB_MODES } from '../../utils/constants'
import { alternateMultiSearchMapping, multiSearchMapping } from '../../utils/helperMethods/commonMethods';

export class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMultiSearchBox: false,
      multiSearchType: '',
      multiSearchValue: '',
      searchValue: '',
      errorMessage: undefined
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userProfile === prevProps.userProfile && (!this.props.userProfile || this.state.searchFields)) {
      return;
    }

    const [multiSearchType, searchFields] = this.props.userProfile && EAPI_V2_TENANTS.includes(this.props.userProfile.tenantId)
    ? [alternateMultiSearchMapping[0].elasticValue, alternateMultiSearchMapping]
    : [multiSearchMapping[0].elasticValue, multiSearchMapping];

    this.setState({multiSearchType: multiSearchType, searchFields: searchFields});
  }

  search = (e) => {
    if (e.which !== 13 && e.keycode !== 13) {
      return;
    }
    
    this.props.changeSearchInput(e.target.value);
  };
  clearSearchBar = () => {
    this.setState({ searchValue: '' });
  }
  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  }
  showMultiSearchBox = () => {
    this.setState({ showMultiSearchBox: !this.state.showMultiSearchBox, errorMessage: undefined });
  }
  onMultiValueChange = (e) => {
    this.setState({ multiSearchValue: e.target.value, errorMessage: undefined });
  }
  selectedMultiType = (e) => {
    this.setState({ multiSearchType: e.target.value, errorMessage: undefined });
  }
  multiSearch = () => {
    const splitSearchText = this.state.multiSearchValue.trim().replace(/ /g, '').replace(/,/g, '\n').split('\n');
    const searchText = splitSearchText.filter(x => x.length > 0).join('|');
    
    if ((this.state.multiSearchType === 'customer_order_number' || this.state.multiSearchType === 'load_number') &&
       !(splitSearchText.every(x => x.length <= 18))) {
      this.setState({ errorMessage: 'This field can only contain numbers with less than 18 digits.  Ensure that all numbers are separated by line.' });
      return;
    }

    this.setState({ errorMessage: undefined, showMultiSearchBox: !this.state.showMultiSearchBox });
    this.props.changeSearchInput(searchText, this.state.multiSearchType);
  }

  render() {
    const placeholder = this.props.jobMode === JOB_MODES.orders ? "Search Orders" : this.props.placeholder;

    return (
      <div className="global-search" id={'global-search-container'}>
        <input value={this.state.searchValue} onChange={this.handleSearchChange} id="search-main" type="search" placeholder={placeholder} onKeyPress={this.search} disabled={!this.props.userRole}></input>
        { this.state.searchValue && <span id="cleartext" className="search-remove" onClick={this.clearSearchBar}><Icon className="icon close" type="close" /></span> }
        <OverlayTrigger placement="bottom" overlay={tooltip("Advanced Search", "Search For Multiple Shipments", "searchpopover")}>
          <FontAwesomeIcon id={'advanced-search-icon'} className="hidden-xs global-search-icon" icon={ faEllipsisV } size='2x' onClick={this.showMultiSearchBox}/>
        </OverlayTrigger>
        {this.state.showMultiSearchBox ? <MultiSearchDropdown showMultiSearchBox={this.showMultiSearchBox} multiSearchType={this.state.multiSearchType} multiSearchValue={this.state.multiSearchValue} multiSearch={this.multiSearch} onSelectChange={this.selectedMultiType} onTypeChange={this.onMultiValueChange} errorMessage={this.state.errorMessage} type="dropdown" history={this.props.history} userProfile={this.props.userProfile} searchFields={this.state.searchFields}/> : null }
      </div>
    );
  }
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeSearchInput: (newSearchInput, multiSearchType) => {
      dispatch(shipmentActions.changeSearchInput(newSearchInput, multiSearchType, ownProps.location, ownProps.history));
    }
  };
};

export const mapStateToProps = (state) => {
  const userProfile = state.users.get('userProfile');
  const userRole = userProfile ? userProfile.role : null;
  const jobMode = state.shipments.data.get('jobMode');
  const configuration = state.shipments.queryConfiguration;
  const searchText = configuration.get('searchText');

  return {
    searchText,
    configuration,
    userProfile,
    userRole,
    jobMode
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
