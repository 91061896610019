import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../../components/icon';
import sideNavActions from './actions';

export class MobileToggleButton extends React.Component {
  onToggle = () => {
    const { expanded, toggleSidenavExpanded } = this.props;
    toggleSidenavExpanded(!expanded);
  };

  render() {
    const { expanded } = this.props;

    return (
      <div className={expanded ? 'mobile-toggle-container' : 'hidden-md hidden-lg'}>
        <button type="button" className={expanded ? 'sideNav-expand-toggle mobile-expanded sidenav---sidenav-toggle---1KRjR hidden-md hidden-lg' : 'sideNav-expand-toggle clear sidenav---sidenav-toggle---1KRjR'} disabled={expanded} data-test="mobileToggleButton" id={"mobile-nav-toggle"} aria-label="Toggle Menu" onClick={this.onToggle}>
          <Icon type="menu" />
        </button>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  expanded: state.sidenav.get('expanded')
});

export const mapDispatchToProps = dispatch => ({
  toggleSidenavExpanded: expanded => dispatch(sideNavActions.toggleSidenavExpanded(expanded))
});

MobileToggleButton.propTypes = {
  expanded: PropTypes.bool,
  toggleSidenavExpanded: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileToggleButton);
