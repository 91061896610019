import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import actions from './actions';
import LoadingIcon from '../../components/loading-icon';
import ShipmentsTable from '../../components/shipments-table/ShipmentsTable';

export class TrendingShipmentsContainer extends React.Component {

  componentDidMount() {
    this.props.fetchTrendingShipments()
  }

  render() {
    return(
      <div className="row">
        <div className="col-lg-12">
          {this.props.trendingLoading ? <LoadingIcon /> :
            <ShipmentsTable trendingShipments={this.props.trendingShipments} permissions={this.props.userProfile.permissions} history={this.props.history} />
          }
        </div>
      </div>
    );
  }
}

TrendingShipmentsContainer.propTypes = {
  trendingLoading: PropTypes.bool,
  trendingShipments: PropTypes.array
};

TrendingShipmentsContainer.defaultProps = {
  trendingLoading: true,
  trendingShipments: []
};

export const mapStateToProps = (state) => {
  return {
    trendingLoading: state.supplyChainHealth.get('trendingLoading'),
    trendingShipments: state.supplyChainHealth.get('trendingShipments'),
    userProfile: state.users.get('userProfile')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchTrendingShipments: () => {
      return dispatch(actions.fetchTrendingShipments());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendingShipmentsContainer);
