import * as types from './actionTypes';

export default {
  fetchPorts() {
    return {
      type: types.FETCH_PORTS_STARTED,
      payload:{}
    };
  },
  fetchPortsComplete(ports) {
    return {
      type: types.FETCH_PORTS_COMPLETED,
      payload:{
        ports: ports
      }
    };
  }
}
