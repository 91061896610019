import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../containers/users/actions';
import PaginationGroup from '../../../components/pagination-group';
import { getDateTime } from '../../../utils/helperMethods/dateTimeMethods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import LoadingIcon from '../../../components/loading-icon';
import { trackPageInfoEvent } from '../../../utils/helperMethods/adobeAnalyticsHelper';
import { toKebabCase } from '../../../utils/helperMethods/commonMethods';

export class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      limit: 20,
      offset: 0,
      currentPage: 1
    };
  }

  componentDidMount() {
    this.props.getUsers(this.state);
    trackPageInfoEvent(this.props.adobeTrack, 'Manage Users Page', [
      'Manage Users Page'
    ]);
  }

  handleChange = field => event => {
    this.setState({ [field]: event.target.value });
  };

  handleSearchSubmit = event => {
    event.preventDefault();
    this.props.getUsers(this.state);
  };

  handleCreateNewUser = () => {
    this.props.history.push(
      `/manage-users/new?tenantId=${this.props.tenantId}`
    );
  };

  handleEditUser = (user_id, tenantId) => {
    this.props.history.push(`/manage-users/${user_id}?tenantId=${tenantId}`);
  };

  fetchNewUsers = pageNumber => {
    let params = {
      currentPage: pageNumber,
      offset: (pageNumber - 1) * this.props.userList.limit,
      limit: parseInt(this.props.userList.limit),
      searchText: this.state.searchText
        };
    this.setState({ currentPage: pageNumber });
    this.props.getUsers(params);
  };

  render() {
    const { users } = this.props.userList;

    return (
      <div>
        <div
          style={{ marginBottom: '6rem' }}
          className="container-fluid manage-users-page"
          data-test="manageUsersContainer"
        >
          <div className="row">
            <div className="col-xs-3">
              <h3>Search for a User</h3>
              <div className="search-container">
                <form>
                  <input
                    name="searchText"
                    id={'search-user-input'}
                    type="text"
                    className="form-control input-sm search-input-field"
                    onChange={this.handleChange('searchText')}
                    data-test="manageUsersSearchInput"
                  />
                  <button
                    type="submit"
                    onClick={this.handleSearchSubmit}
                    className="btn search-submit-btn"
                  >
                    <FontAwesomeIcon icon={faSearch} size="1x" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-xs-9 text-right">
              <button
                className="btn btn-lg btn-success btn-add-user"
                data-test="createNewUser"
                id={'create-user-button'}
                onClick={this.handleCreateNewUser}
              >
                <FontAwesomeIcon
                  className={'btn-icon'}
                  icon={faPlus}
                  size="1x"
                />
                <span>Create User</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 user-list-container">
              <h2>Users</h2>
              {this.props.loading ? (
                <span className="centered">
                  <LoadingIcon></LoadingIcon>
                </span>
              ) : (
                <React.Fragment>
                  <table className="table table-responsive table-striped table-condensed user-list-table">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Full Name</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.map(user => {
                          return (
                            <tr key={user.email}>
                              <td>
                                <a
                                  id={`user-${toKebabCase(user.email)}-link`}
                                  data-test="userEmail"
                                  onClick={e =>
                                    this.handleEditUser(
                                      user.user_id,
                                      user.user_metadata.tenantId ||
                                        user.tenantId
                                    )
                                  }
                                >
                                  {user.email}
                                </a>
                              </td>
                              <td>
                                {user.user_metadata.given_name}{' '}
                                {user.user_metadata.family_name}
                              </td>
                              <td>{getDateTime(user.createdOn)}</td>
                              <td>{getDateTime(user.updatedOn)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <PaginationGroup
                    offset={this.props.userList.start}
                    limit={this.props.userList.limit}
                    total={this.props.userList.total}
                    totalPages={Math.ceil(
                      this.props.userList.total / this.props.userList.limit
                    )}
                    fetchNextPage={this.fetchNewUsers.bind(this)}
                    currentPageNumber={this.state.currentPage}
                    label="Users"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    userList: state.users.get('users') ? state.users.get('users') : {},
    tenantId: state.users.get('tenantId'),
    loading: state.users.get('usersLoading')
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    getUsers: payload => {
      dispatch(actions.fetchUsers(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
