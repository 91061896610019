import React from 'react';
import { getDate, getTime } from '../../utils/helperMethods/dateTimeMethods';
import { withRouter } from 'react-router'
import Icon from '../icon';
import PropTypes from 'prop-types';

export default class NotificationsCardComponent extends React.Component {
  navigate = (url) => {
    const route = url.split('#');
    if(route.length > 1){
      this.props.history.push(encodeURI(route[1]));
    }
  }
  render() {
    return (
      <div id={'comment-notification-container'} className="notification notification-comment">
        <div className="row">
          <div className="col-sm-6">
            <Icon className="icon space-right faded inline chat" type="chat" />
            <h4 id={'comment-notification-title'} className="uppercase alertsMessage">{this.props.title}</h4>
          </div>
          <div className="col-sm-6">
            <p id={'comment-notification-date-time'} className="small float-right">{getDate(this.props.dateTime)} {getTime(this.props.dateTime)}</p>
          </div>
        </div>
        <h5>{this.props.subTitle}</h5>
        <p id={'comment-notification-message-body'} className="alertsMessage">{this.props.message}</p>
        <div className="row">
          {/* <div className="col-sm-6">
            <span className="mark-read">
              <a className="btn btn-link btn-sm"><Icon className="icon orange medium" type="check" /> Mark As Read</a>
            </span>
          </div> */}
          <div className="col-sm-12">
            <a id={'comment-notification-link'} className="btn btn-outline btn-sm float-right" onClick={() => { this.navigate(this.props.link) }}>{this.props.linkText}</a>
          </div>
        </div>
      </div>
    );
  }
}

NotificationsCardComponent.protoType = {
  link: PropTypes.string,
  linkText: PropTypes.string,
  dateTime: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  message: PropTypes.string
};

export const NotificationsCard =  withRouter(NotificationsCardComponent);
