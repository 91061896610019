import React from 'react';

import './extras/style.scss';

export default function ProfileImg(props) {
  const imgStyle = {
    backgroundImage: "url("+props.picture+")",
    height: props.size || 50,
    width: props.size || 50,
    position: "relative",
    top: "2px"
  };
  const result = props.picture ? (<div className='Profile-img' style={imgStyle} id={props.id || ''}></div>) : null;
  return(
       result
  );
}
