import React from 'react';

import { OverlayTrigger } from 'react-bootstrap';
import { tooltip } from '../../utils/helperMethods/commonComponents';

const render = (props)=>{
  let components = [];

  for(let i = 0; i < 6 && i < props.users.length;i++){
    components.push(
          <OverlayTrigger key={props.users[i].user_id + 'groupcirclekey'} placement="top" overlay={tooltip(props.users[i].displayName, props.users[i].displayName)}>
            <img
              src={props.users[i].gravatarUrl}
              className="Profile-img conversation group image"
              alt="user icon"
              height={40}
              width={40}/>
            </OverlayTrigger>
            );
  }
    if(props.users.length > 6){
    components.pop();//replace last one with count circle
    components.push(<OverlayTrigger key='lastgroupcirclekey' placement="top" overlay={tooltip(props.users.length + ' total viewers', "viewerstotal")}><div id='whoislivetotalusers' className="conversation group circle image">+{props.users.length - 6}</div></OverlayTrigger>);
  }
  return components;
};
export default function WhoIsLive(props){
  return(
    <div>
      {render(props)}
    </div>
  );
};
