import React from 'react';
import BaseModal from '../../containers/base-modal';
import {ExternalHeader} from '../../components/header';
import {ExternalFooter} from '../../components/footer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class LayoutExternal extends React.Component {
  render() {
    return (
      <div className='site-container'>
        <ExternalHeader showLoginBtn={this.props.showLoginBtn} {...this.props}/>
        <div className='external-content'>
          {this.props.children}
          <ExternalFooter />
          <BaseModal/>
          <ToastContainer />
        </div>
      </div>
    );
  }
}
