import React from 'react'
import Button from '../button';
import { LayoutHOC } from '../../components/layout';
import CHRSideNav from '../../containers/chr-side-nav';
import BaseModal from '../../containers/base-modal';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '../../containers/chr-side-nav/extras/navisphere-vision-w.svg';
import Icon from '../icon';

 const OktaAccessDenied = ({authClient}) => {

    return (
    <div className="error-container">
        <div className="header-container" >
            <div className="container-fluid">
                <div className="row flex align-middle">
                    <div className="col-xs-2 col-sm-2 col-md-6">
                        <span className="hidden-xs hidden-sm" aria-label="Navisphere Vision Home Page">
                        <img src={logo}/>
                        </span>
                        <span className="hidden-md hidden-lg" aria-label="Navisphere Vision Home Page">
                            <Icon className='icon large logo' type='navisphere'/>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div className='site-content'>

            <div className="page">
                <div className="row">
                    <p className="col-xs-12">Oops... it looks like your access to Navisphere Vision was removed.</p>
                    <p className="col-xs-12">Please contact your C.H. Robinson representative for assistance.</p>
                    <p className="col-xs-12">
                        <Button onClick={() => {
                            authClient.logout({"post_logout_redirect_uri": window.location.origin});
                        }}>
                            Log Out
                        </Button>
                    </p>
                </div>
            </div>
            <Footer isFixed={true} />
        </div>
      </div>



        
    );
  }

  export default OktaAccessDenied;