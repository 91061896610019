import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  expanded: false
});

function sidenavReducer(state=initialState, action={}) {
  switch(action.type) {
    case types.TOGGLE_SIDENAV_EXPANDED:
      return state
        .set('expanded', action.payload.expanded);
    default:
      return state;
  }
}

export default sidenavReducer;
