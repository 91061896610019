import * as types from './actionTypes';
 export default {
  handleIncomingNotifications(event) {
    return {
      type: types.HANDLE_INCOMING_NOTIFICATIONS,
      payload: event
    };
  },
  fetchNotifications(){
    return {
      type: types.FETCH_NOTIFICATIONS_STARTED
    }
  },
  fetchNotificationsCompleted(alerts){
    return {
      type: types.FETCH_NOTIFICATIONS_COMPLETED,
      payload: {
        ...alerts
      }
    }
  },
  viewedNotifications(){
    return {
      type: types.VIEWED_NOTIFICATIONS
    }
  }
};
