import React from 'react';
import * as dateTimeMethods from '../../utils/helperMethods/dateTimeMethods';

import logo from "./extras/chr-footer-logo.png";


export default function Footer(params) {
  const showFooter = params.showFooter === false ? 'hidden' : '';
  return (
    <footer className={params.isFixed === true ? `footer fixed-bottom ${showFooter}` : `footer ${showFooter}`}>
      <div className="container-fluid">
            <div className="flex container-footer">
              <div className="footer-logo">
                <a href="https://www.chrobinson.com/en-us/"><img src={logo} /></a>
                <ul className="footer-links">
                  <li>
                    <a href="https://www.chrobinson.com/en-us/privacy-policy/" target="_blank">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://www.chrobinson.com/en-us/your-privacy-rights/" target="_blank">Your Privacy Rights</a>
                  </li>
                  <li>
                    <a href="https://www.chrobinson.com/en-us/terms-of-use/" target="_blank">Terms of Use</a>
                  </li>
                  <li>
                    <p className="copyright-statement"> ©1996-{dateTimeMethods.generateYear()} C.H. Robinson Worldwide, Inc.</p>
                    <p className="copyright-statement"> All Rights Reserved.</p>
                  </li>
                </ul>
              </div>
              <div>
                <div className="site-legal">
                  <p>Navisphere Vision is a registered trademark of C.H. Robinson Worldwide. </p><br />
                  <p>Vessel location data provided by Fleetmon.com. <br /> Wildfire data courtesy of the U.S. Geological Survey.</p>
                </div>
              </div>
            </div>
      </div>
    </footer>
  );
}
