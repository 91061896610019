import filterGenerator from './filterGenerator';
import { DEFAULT_SHIPMENT_PAYLOAD_LIMIT } from '../constants';
import { defaultShipmentSortBy } from '../../components/shipments/ShipmentSortByOptions';
import { getOffset } from './shipmentMethods';

/** Helper method to generate a shipment search payload against an
 * already existing configuration and combine it with supplied query
 * parameters.  Mostly here to prevent code duplication in the split
 * view and list view shipment searches. */
export function formShipmentPayloadAgainstConfig(dynamicFilters, currentConfiguration) {
  const sort = currentConfiguration.sort ?? defaultShipmentSortBy;

  const payload = filterGenerator(dynamicFilters);
  payload.sort = sort.value;
  payload.searchText = currentConfiguration.searchText;
  payload.multiSearchType = currentConfiguration.multiSearchType;

  if (currentConfiguration.dateFilter) {
    payload.dateFilter = currentConfiguration.dateFilter;
  };

  if (currentConfiguration.activeFacilityFilters) {
    payload.facilityFilters = currentConfiguration.activeFacilityFilters;
  };

  // incident affected shipment view, shipments exceptions from landing page or global search
  if (currentConfiguration.incidentId || currentConfiguration.multiSearchType || currentConfiguration.searchText) {
    if (currentConfiguration.incidentName && currentConfiguration.incidentId) {
      payload.filters.incidents = [currentConfiguration.incidentName];
      payload.incidents = [{ id: currentConfiguration.incidentId, name: currentConfiguration.incidentName }];
      if (currentConfiguration.status) {
        payload.filters.status = currentConfiguration.status.length == 2 ? currentConfiguration.status : [currentConfiguration.status];
      }
    };
  };

  if (currentConfiguration.liveTracked) {
    payload.liveTracked = currentConfiguration.liveTracked;
  }

  //retrieve active filters
  if (currentConfiguration.activeFilters) {
    Object.keys(payload.filters).forEach(key => {
      if (currentConfiguration.activeFilters[key]) {
        Array.isArray(currentConfiguration.activeFilters[key]) ? payload.filters[key] = currentConfiguration.activeFilters[key] : payload.filters[key].push(currentConfiguration.activeFilters[key]);
      };
    });
  };

  payload.currentPage = currentConfiguration.currentPage;
  payload.limit = DEFAULT_SHIPMENT_PAYLOAD_LIMIT;
  payload.offset = getOffset(currentConfiguration.currentPage);
  payload.displayMode = currentConfiguration.displayMode;
  if (currentConfiguration.displayMode === 'splitView' || currentConfiguration.displayMode === 'mapOnly' || !!currentConfiguration.clusterSelected) {
    payload.box = currentConfiguration.box;
    payload.zoom = currentConfiguration.zoom;
  };

  return { payload };
}