import moment from 'moment';

const getDate = (dateTime, format = 'MM/DD/YY') => {
  return dateTime ? moment.parseZone(dateTime).format(format) : '\u2014';
};

const getTime = (dateTime) => {
  return dateTime ? moment.parseZone(dateTime).format('h:mm A') : '\u2014';
};

const getTimeUtc = (dateTime, isUtc) => {
  return dateTime ? `${moment.parseZone(dateTime).format('h:mm A')}${isUtc? " UTC" : ""}` : '\u2014';  
};

const getDateTime = (dateTime) => {
  return moment.parseZone(dateTime).format('MM/DD/YY h:mm A');
}
const getMonthAndDate = (dateTime) => {
  return moment.parseZone(dateTime).format('MMM DD');
}
const getLongDateLocal = (dateTime) => {
  return moment(dateTime).local().format('MMMM Do, YYYY');
}
const getTimeLocal = (dateTime) => {
  return moment(dateTime).local().format('h:mm A');
}
const generateYear = () => {
  return moment().year();
}

const formatDateWithForwardSlash = (date) => {
  return moment(date).format('MM/DD/YYYY');
}

const getParsedDateTime = (date) => {
  if (date && date != '') {
    return getDateTime(date);
  } else {
    return '';
  }
}

const getParsedMonthAndDate = (date) => {
  if (date && date != '') {
    return getMonthAndDate(date);
  } else {
    return '';
  }
}

const getDateTimeDayMonthYearFormat = (date) => {
  return moment.parseZone(date).format('DD/MM/YY - h:mm A');
}

const isTimeUtc = (localTime, utcTime) => {  
  // The legacy mapper in the API sets the local date and time to UTC when none is provided.
  return (localTime && getTime(localTime) === getTime(utcTime)) || (!localTime && utcTime);  
}

export {
  getDate,
  getTime,
  getTimeUtc,
  getDateTime,
  getMonthAndDate,
  generateYear,
  getLongDateLocal,
  getTimeLocal,
  formatDateWithForwardSlash,
  getParsedDateTime,
  getParsedMonthAndDate,
  getDateTimeDayMonthYearFormat,
  isTimeUtc
};
