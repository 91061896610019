import React from 'react';
import { connect } from 'react-redux';
import Icon from '../../components/icon';
import {CommentAlertList} from '../../components/notifications';

export class NotificationsContainerComponent extends React.Component {
  state = {
    activeTab: {
      comments: 'active',
      alerts: ''
    }
  };

  isActive(selectedTab) {
    var tabsState = {};

    for (const key of Object.keys(this.state.activeTab)) {
      if (key === selectedTab) {
        tabsState[key] = 'active';
      } else {
        tabsState[key] = '';
      }
    }

    this.setState({
      activeTab: {
        comments: tabsState.comments,
        alerts: tabsState.alerts
      }
    });
  }

  render() {
    return (
      <div className="dropdown-menu notification-dropdown dropdown-menu-right" id="notificationsDropdown" >
        <div className="fluid">
          <div className="row dropdown-header">
            <div className="col-sm-6">
              <label className="notifications-label"> Notifications</label>
            </div>
            <div className="col-sm-6">
              <button id='bellIconNotificationPreferenceBtn' className="btn btn-outline btn-light btn-sm float-right" onClick={()=>{this.props.history.push('/notification-preferences')}}>
                <Icon className="icon small faded space-right" type="settings"/> Preferences</button>
            </div>
          </div>
          <div className="tabs tabs-full-width tabs-uppercase">
            <ul id="myTab" className="nav nav-tabs">
              <li id={'comments-tab-button-label'} className={this.state.activeTab.comments}><a id={'comments-tab-button'} data-toggle="tab" onClick={() => { return this.isActive('comments'); }}>Comments</a></li>
              <li id={'alerts-tab-button-label'} className={this.state.activeTab.alerts}><a id={'alerts-tab-button'} data-toggle="tab" onClick={() => { return this.isActive('alerts'); }}>Alerts</a></li>
            </ul>
            <div id="myTabContent" className="tab-content">
              {this.state.activeTab.comments && (this.props.commentAlerts.length === 0 ? <div>No Group Notifications</div> :<CommentAlertList history={this.props.history} mentions={this.props.commentAlerts}/>)}
              {this.state.activeTab.alerts && (this.props.shipmentAlerts.length === 0 ? <div>No Shipment Notifications</div> :<CommentAlertList history={this.props.history} mentions={this.props.shipmentAlerts}/>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


NotificationsContainerComponent.defaultProps = {
  commentAlerts: [],
  shipmentAlerts: []
};

export const mapStateToProps = (state)=>{
  return {
    commentAlerts: state.notifications.get('commentAlerts').toJS(),
    shipmentAlerts: state.notifications.get('shipmentAlerts').toJS()
  };
};
export const NotificationsContainer = connect(mapStateToProps, null)(NotificationsContainerComponent);
