import React, { Component } from 'react';
import { MainMapContainer, MainMapControls } from '../../containers/map';
import ConfigurationContext from '../config/ConfigurationContext';

class Map extends Component {
  render() {
    return (
      <ConfigurationContext.Consumer>
        {config => (
          <React.Fragment>
            <div className="map-split" data-test="map">
              <MainMapControls />
              <MainMapContainer appConfig={config} bingmapKey={config.bingMapKey} displayMode={this.props.displayMode} location={this.props.location}/>
            </div>
          </React.Fragment>
        )}
      </ConfigurationContext.Consumer>
    );
  }
}

export default Map;
