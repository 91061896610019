import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../icon';

class FilterTag extends React.Component {
  close = (selectedTag) => {
    this.props.closeFilterTag(selectedTag);
  }
  render() {
    return (
        <div className="filter-tag">
            <span id="filter-tag-label-id" title={this.props.label} className="filter-tag-text">{ this.props.label }</span>
            <span id="filter-tag-remove-id" className="filter-tag-remove" onClick={ () => { this.close(this.props) } }><Icon className="icon small" type="close"/></span>
        </div>
    );
  }
}

FilterTag.propTypes = {
  closeFilterTag: PropTypes.func.isRequired
}

export default FilterTag;
