import Inventory from './Inventory';
import InventoryDetails  from './InventoryDetails';
import { LayoutHOC } from '../../components/layout';
import {InventoryDetailsPageRBAC, InventoryPageRBAC} from './rbac'

const InventoryPage = LayoutHOC(Inventory);
InventoryPage._RBAC = InventoryPageRBAC;

const InventoryDetailsPage = LayoutHOC(InventoryDetails);
InventoryDetailsPage._RBAC = InventoryDetailsPageRBAC;

export { Inventory, InventoryPage, InventoryDetails, InventoryDetailsPage };
