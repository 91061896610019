import { getUserProfile } from '../../containers/auth/authHelper';

const getUserTenant = async () => {
    try {
        const userProfile = await getUserProfile();
        const tenantId = userProfile ? userProfile?.tenantId : null;
          
        if (tenantId) 
            return { key: `vision_tenant_${tenantId}` }
        else 
            throw 'Tenant is not found';
    } 
    catch (err) {
        return { anonymous: true };
    }
}

export { getUserTenant }