import React from 'react';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import { ProgressToast, ErrorToast } from '../../components/toast';

const options = { position: 'bottom-right', hideProgressBar: true }
let toastId = null;

export function* triggerSuccessToastStart({payload}) {
  yield !toast.isActive(toastId) ? (toastId = toast(<ProgressToast title={payload.title} message={payload.message}/>, options)) : null
}

export function* watchTriggerSuccessToast() {
  yield takeEvery(actionTypes.TRIGGER_GLOBAL_SUCCESS_ALERT, triggerSuccessToastStart);
}

export function* triggerErrorToastStart({payload}) {
  /* TODO: Let's log errors here!
  /* payload.error SHOULD contain the exception thrown that triggered this specific error.
  /* We could generate a GUID for this error, log it with that GUID, and then display the GUID as a message to the user.
  /* User would then send us that GUID and we can go look up exactly which error they encountered. */
  console.error(payload.error);
  yield !toast.isActive(toastId) ? (toastId = toast(<ErrorToast title={payload.title ? payload.title : 'Error'} message={payload.message}/>, options)) : null
}

export function* watchTriggerErrorToastStart() {
  yield takeEvery(actionTypes.TRIGGER_GLOBAL_ERROR_ALERT, triggerErrorToastStart);
}
