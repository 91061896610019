import React from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import actions from './actions';
import exportActions from '../export/actions';
import filterActions from '../../containers/filters/actions';
import InventoryTable from '../../components/inventory/InventoryTable';
import PaginationGroup from '../../components/pagination-group';
import Icon from '../../components/icon';
import SelectDropdown from '../../components/dropdown/SelectDropdown';
import { isArray } from 'util';
import LoadingIcon from '../../components/loading-icon';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';

export class Inventory extends React.Component {

  state = {
    currentPage: 1,
    offset: 0,
    limit: 50,
    searchText: undefined,
    showClearButton: false,
    lineOfBusinessOptions: [],
    locationNamesOptions: [],
    lineOfBusiness: [],
    locationNames: [],
    activeFilters: {
      lineOfBusiness: [],
      locationNames: []
    }
  };

  componentDidMount() {
    const currentLocation = queryString.parse(this.props.location.search);
    !currentLocation.currentPage && this.props.history.replace('/inventory' + '?' + `currentPage=1`);

    this.setState(Object.assign(this.state, {
      currentPage: parseInt(currentLocation.currentPage),
      offset: this.offset(currentLocation.currentPage),
      searchText: (currentLocation.searchText !== undefined) ? currentLocation.searchText : undefined,
      showClearButton: (currentLocation.searchText !== undefined) ? true : false

    }));

    Object.keys(currentLocation).forEach(key => {
      if (key === 'locationNames') {
        return this.onPageLoad(currentLocation, key);
      }
      else if (key === 'lineOfBusiness') {
        return this.onPageLoad(currentLocation, key);
      };
    });

    this.props.getInventory(this.state);
    trackPageInfoEvent(this.props.adobeTrack, "Inventory Page", ["Inventory Page"]);
  }

  componentDidUpdate(prevProps) {
    const prevLocation = queryString.parse(prevProps.location.search);
    const nextLocation = queryString.parse(this.props.location.search);

    if (nextLocation.currentPage !== prevLocation.currentPage || (nextLocation.searchText !== prevLocation.searchText)) {
      this.setState(Object.assign(this.state, {
        currentPage: parseInt(nextLocation.currentPage),
        offset: this.offset(nextLocation.currentPage),
        searchText: (nextLocation.searchText) ? nextLocation.searchText : undefined,
        showClearButton: (nextLocation.searchText !== undefined) ? true : false
      }));

      this.props.getInventory(this.state);
    };

    if (prevProps.inventory !== this.props.inventory) {
      let lineOfBusinessOptions = [];
      let locationNamesOptions = [];

      this.props.inventory.lineOfBusiness.buckets.map(x => { lineOfBusinessOptions.push({ label: x.key, value: x.key }); });
      this.props.inventory.locations.buckets.map(x => { locationNamesOptions.push({ label: x.key, value: x.key }); });
      this.setState(Object.assign(this.state, { lineOfBusinessOptions: lineOfBusinessOptions, locationNamesOptions: locationNamesOptions }));
    };
  };

  onPageLoad = (currentLocation, key) => {
    let filters = [];
    (!isArray(currentLocation[key])) ? filters.push(currentLocation[key]) : filters = currentLocation[key];

    this.setState(Object.assign(this.state,
      { activeFilters: { ...this.state.activeFilters, [key]: filters } },
      { [key]: filters.map(filter => { return { label: filter, value: filter } }) }
    ));
  };

  handlePageChange = (pageNumber) => {
    const location = queryString.parse(this.props.location.search);
    let urlParams = { ...location, currentPage: pageNumber };

    this.setState(Object.assign(this.state, {
      currentPage: pageNumber,
      offset: this.offset(pageNumber),
      searchText: (this.state.searchText !== undefined) ? this.state.searchText : undefined
    }));

    this.props.history.push('/inventory' + '?' + queryString.stringify(urlParams));
  };

  handleFilterChange = (selections, filter) => {
    let arr = [];
    const location = queryString.parse(this.props.location.search);

    if (selections && selections.length > 0) { selections.map(x => { arr.push(x.value); }) };

    this.setState(Object.assign(this.state,
      { activeFilters: { ...this.state.activeFilters, [filter]: arr } },
      { [filter]: selections, currentPage: 1 }
    ))

    const urlParams = { ...location, ...this.state.activeFilters, currentPage: 1 };
    this.props.history.push('/inventory' + '?' + queryString.stringify(urlParams));

    this.props.getInventory(this.state);
  };

  offset = (pageNumber) => {
    return (pageNumber - 1) * this.state.limit;
  };

  search = (e) => {
    if (e.which === 13 || e.keycode === 13) {
      e.preventDefault();
      const payload = {
        currentPage: 1,
        searchText: e.target.value || ''
      };
      if (e.target.value.length > 0) {
        this.setState(Object.assign(this.state, { searchText: payload.searchText }));
        this.props.history.push('/inventory' + '?' + `currentPage=${payload.currentPage}` + `&searchText=${payload.searchText}`);
      };
    };
  };

  clear = () => {
    this.setState(Object.assign(this.state, { currentPage: 1, searchText: undefined, showClearButton: false }));
    document.getElementById("searchform").reset();
    this.props.history.push('/inventory' + '?' + `currentPage=1`);
  };

  showClearButton = (e) => {
    this.setState(Object.assign(this.state, { showClearButton: e.target.value.length > 0 ? true : false }));
  };

  download = () => {
    const lineOfBusiness = []
    const locations = []

    if (this.state.lineOfBusiness && this.state.lineOfBusiness.length > 0) {
      this.state.lineOfBusiness.map(x => {
        lineOfBusiness.push(x.value)
      })
    }
    if (this.state.locations && this.state.locations.length > 0) {
      this.state.locations.map(x => {
        locations.push(x.value)
      })
    }

    const payload = {
      exportType: "INVENTORY_EXPORT",
      searchText: this.state.searchText || '',
      ...this.state.activeFilters
    };
    this.props.exportData(payload);
  };

  render() {
    return (
      <div className={"pagination-table-group"}>
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-6">
                <SelectDropdown
                  label="Facility "
                  isMultiSelect={true}
                  id={"Facilities-Select"}
                  options={this.state.locationNamesOptions}
                  placeholder={"Filter by Facilities..."}
                  stateName={"locationNames"}
                  handleDropdownChange={this.handleFilterChange}
                  selectedValues={this.state.locationNames}
                />
              </div>
              <div className="col-sm-6">
                <SelectDropdown
                  label="LOB "
                  isMultiSelect={true}
                  id={"LOB-Select"}
                  closeMenuOnSelect={false}
                  options={this.state.lineOfBusinessOptions}
                  placeholder={"Filter by Line Of Business..."}
                  stateName={"lineOfBusiness"}
                  handleDropdownChange={this.handleFilterChange}
                  selectedValues={this.state.lineOfBusiness}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <form id="searchform" className="inventory inventory-search float-right">
              <div className="form-group">
                <input id="searchsku" className="input-search" type="search" autoComplete="off"
                  defaultValue={this.state.searchText ? this.state.searchText : ''}
                  placeholder="Search for Product or SKU"
                  onKeyPress={(e) => { this.search(e) }}
                  onChange={this.showClearButton}
                />
                {this.state.showClearButton && <span id="cleartext" className="inventory-search-remove" onClick={() => { return this.clear() }}><Icon className="icon close" type="close" /></span>}
              </div>
            </form>
          </div>
        </div>
        {this.props.loading ? <div className="centered"><LoadingIcon></LoadingIcon></div> :
        <React.Fragment>
          { this.props.userProfile.permissions && this.props.userProfile.permissions.includes('Exporting') &&
            <div className="row">
              <div className="col-sm-12">
                <button id="exportbutton" type="button" className="btn btn-sm btn-outline btn-light float-right"
                  onClick={() => { return this.download() }} ><svg className="icon"><use href="#download" /></svg> Export CSV
                </button>
              </div>
            </div>
          }
          <InventoryTable
            inventory={this.props.inventory}
            history={this.props.history}
            state={this.state}
          />
          <PaginationGroup
            className="pagination"
            totalPages={this.props.inventory.totalPages || 1}
            currentPageNumber={parseInt(this.state.currentPage)}
            limit={this.state.limit}
            offset={this.state.offset}
            total={this.props.inventory.total}
            fetchNextPage={this.handlePageChange.bind(this)}
            label="Items"
          />
        </React.Fragment>
        }
      </div>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    inventory: state.inventory.get('inventory'),
    dynamicFilters: state.filters.get('dynamicFilters'),
    facilityLocations: state.facilityLocations.get('facilities'),
    loading: state.inventory.get('loading'),
    userProfile: state.users.get('userProfile')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getInventory: (payload) => {
      return dispatch(actions.fetchInventory(payload));
    },
    exportData: (items) => {
      dispatch(exportActions.exportData(items));
    },
    getDynamicFilters: () => {
      return dispatch(filterActions.fetchTenantDynamicFilters());
    }
  };
};

export const InventoryContainer = connect(mapStateToProps, mapDispatchToProps)(Inventory);
