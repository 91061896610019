export const FETCH_HEALTH_AIR_COMPLETED = 'health/FETCH_HEALTH_AIR_COMPLETED';
export const FETCH_HEALTH_AIR_FAILED = 'health/FETCH_HEALTH_AIR_FAILED';
export const FETCH_HEALTH_GROUND_COMPLETED = 'health/FETCH_HEALTH_GROUND_COMPLETED';
export const FETCH_HEALTH_GROUND_FAILED = 'health/FETCH_HEALTH_GROUND_FAILED';
export const FETCH_HEALTH_IN_TRANSIT_COMPLETED = 'health/FETCH_HEALTH_IN_TRANSIT_COMPLETED';
export const FETCH_HEALTH_IN_TRANSIT_FAILED = 'health/FETCH_HEALTH_IN_TRANSIT_FAILED';
export const FETCH_HEALTH_OCEAN_COMPLETED = 'health/FETCH_HEALTH_OCEAN_COMPLETED';
export const FETCH_HEALTH_OCEAN_FAILED = 'health/FETCH_HEALTH_OCEAN_FAILED';
export const FETCH_HEALTH_PARCEL_COMPLETED = 'health/FETCH_HEALTH_PARCEL_COMPLETED';
export const FETCH_HEALTH_PARCEL_FAILED = 'health/FETCH_HEALTH_PARCEL_FAILED';
export const FETCH_HEALTH_STARTED = 'health/FETCH_HEALTH_STARTED';
export const FETCH_TRENDING_SHIPMENTS_COMPLETED = 'trending/FETCH_TRENDING_SHIPMENTS_COMPLETED';
export const FETCH_TRENDING_SHIPMENTS_FAILED = 'trending/FETCH_TRENDING_SHIPMENTS_FAILED';
export const FETCH_TRENDING_SHIPMENTS_STARTED = 'trending/FETCH_TRENDING_SHIPMENTS_STARTED';
export const FETCH_LANDING_PAGE_CONFIG_COMPLETED = 'landingPageConfig/FETCH_LANDING_PAGE_CONFIG_COMPLETED';
export const FETCH_LANDING_PAGE_CONFIG_STARTED = 'landingPageConfig/FETCH_LANDING_PAGE_CONFIG_STARTED';
export const TOGGLE_COVID_ACTIVE = 'landingPageConfig/TOGGLE_COVID_ACTIVE';
export const FETCH_POWER_BI_ITEMS_STARTED = 'health/FETCH_POWER_BI_ITEMS_STARTED';
export const FETCH_POWER_BI_ITEMS_COMPLETED = 'health/FETCH_POWER_BI_ITEMS_COMPLETED';
export const FETCH_POWER_BI_ITEMS_FAILED = 'health/FETCH_POWER_BI_ITEMS_FAILED';