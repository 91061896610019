import React from 'react';
import Icon from '../../icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';

import { mapDateFilterField, toKebabCase } from '../../../utils/helperMethods/commonMethods';
import { formatDateWithForwardSlash } from '../../../utils/helperMethods/dateTimeMethods';
class FilterSet extends React.Component {

  constructor(props) {
    super(props);
  }

  filterSets = (filterSets) => {
    if (filterSets && filterSets.length > 0) {
      return filterSets.map(set => {
        const savedFilters = [];
        const savedConfiguration = JSON.parse(set.query);
        savedConfiguration.filters = savedConfiguration.filters ?? [];

        if (savedConfiguration.filters) {
          Object.keys(savedConfiguration.filters).forEach(key => {
            if (savedConfiguration.filters[key].length > 0) {
              savedFilters.push(savedConfiguration.filters[key].join(' , '))
            };
          });
        }

        if (savedConfiguration.dateFilter) {
          const dateFilterLabel = mapDateFilterField[savedConfiguration.dateFilter.field[0]];
          var range = `${dateFilterLabel}: ${formatDateWithForwardSlash(savedConfiguration.dateFilter.startDate)} to ${formatDateWithForwardSlash(savedConfiguration.dateFilter.endDate)}`;
          savedFilters.push(range);
        };

        if (savedConfiguration.facilityFilters && savedConfiguration.facilityFilters.length > 0) {
          savedConfiguration.facilityFilters.forEach(filter => {
            var facilityFilter = `${filter.facilityName}: ${filter.parsedFacilityStatus}`
            savedFilters.push(facilityFilter);
          });
        };

        const isChecked = (this.props.selectedOption._id === set._id) ? true : false;
        const isDeleted = (this.props.deletedOption === set._id) ? true : false;
        const isDefault = (this.props.defaultSet === set._id) ? true : false;

        return (
          <tr key={set._id} hidden={isDeleted}>
            <td id="label" className="col-sm-5">{set.label}</td>
            <td id="filtersets" className="col-sm-5">{savedFilters.join(' , ')}</td>
            <td className="col-sm-1" style={{ 'textAlign': 'center' }}>
              <FontAwesomeIcon id={`filter-${toKebabCase(set.label)}-default-button`} icon={isDefault ? faCheckCircle : farCheckCircle} size='1x' onClick={() => { this.props.handleDefaultChange(set) }} />
            </td>
            <td className="col-sm-1">
              <input type="checkbox" 
                     id={`filter-${toKebabCase(set.label)}-checkbox`} 
                     value={set._id} 
                     onChange={() => { this.props.handleOptionChange(set) }} 
                     checked={isChecked} 
                     style={{ 'top': '-12px'}}/>
              <Icon id={`filter-${toKebabCase(set.label)}-delete-button`} className="icon medium float-right" style={{ 'marginRight': '5px' }} type="delete" hidden={!isChecked} onClick={() => { this.props.removeSavedFilterSet(set, savedFilters) }} />
            </td>
          </tr>
        );
      });
    };
  };

  render() {
    return (
      <div className="flex-column">
        <div className="row">
          <div className="col-xs-12">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Filters</th>
                  <th style={{ 'textAlign': 'center' }}>Default</th>
                  <th>Selection</th>
                </tr>
              </thead>
              <tbody>
                {this.props.filterSets.length > 0 ? this.filterSets(this.props.filterSets) :
                  <tr><td colSpan="4">No Filters Saved</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterSet;
