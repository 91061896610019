import {PERMISSIONS, ROLE_SETS} from '../../../utils/rbacUtils';

export const UserPageRBAC = {
  permissions: [],
  roles: ROLE_SETS.adminsAndAbove
};

export const ManageUsersPageRBAC = {
  permissions: [],
  roles: ROLE_SETS.adminsAndAbove
};

export const CreatePasswordPageRBAC = {
  permissions: [],
  roles: ROLE_SETS.noRoleRestrictions
}