import React from 'react';
import { Panel, PanelGroup } from "react-bootstrap";
import { CarrierBody } from '../../components/scorecard';
import Icon from '../../components/icon';
import CarrierRollup from './CarrierRollup';

export default class Carrier extends React.Component {
  state = {
    activeKey: []
  };

  handleAccordionSelect = (activeKey) => {
    this.setState({ activeKey });
  }

  render() {
    return (
      <div>
        <PanelGroup
          accordion
          id="carrier-accordion"
          activeKey={this.state.activeKey}
          onSelect={this.handleAccordionSelect}>

          <Panel style={{minHeight: '75px'}} eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle>
                <CarrierRollup isAnimationActive={false} carrierName={this.props.carrierName} delivered={this.props.delivered} picked_up={this.props.picked_up} carrier_compliance={this.props.carrier_compliance}> </CarrierRollup>
                <Icon style={{float: 'right', marginTop: '-6.5rem', marginRight: '1rem', height: '50px', width: '50px'}} type={this.state.activeKey === '1' ? "chevron-down" : "chevron-up"} />
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              { this.state.activeKey === '1' &&
                <CarrierBody isAnimationActive={this.props.isAnimationActive} in_transit={this.props.in_transit} delivered={this.props.delivered} picked_up={this.props.picked_up} carrier_compliance={this.props.carrier_compliance}></CarrierBody>
              }
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </div>
    );
  }
}
