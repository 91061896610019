import React, { useState } from 'react';
import Icon from '../icon';
import { getDate, getTime } from '../../utils/helperMethods/dateTimeMethods';
import { groupSortedTrackingLogsByDate } from '../../utils/helperMethods/commonMethods'

const getTableRows = (logs, trackingType) => {
  if (logs && logs.length > 0) {
    let groupedLogs = groupSortedTrackingLogsByDate(logs);
    return groupedLogs.map((log, i) => {
      return (
        <tr key={i} data-testid={`tableLogsRowId${i + 1}`}>
          {log.isDateGroupLabel ? (
            <td className="date-group-label-row">
              {getDate(log.eventDateTime)}
            </td>
          ) : (
            <td></td>
          )}
          <td>{getTime(log.eventDateTime)}</td>
          <td
            hidden={
              trackingType === 'Order' || trackingType === 'NonNav'
                ? false
                : true
            }
          >
            {log.shipmentDisplayIdentifier || log.shipmentNumber || log.loadNumber}
          </td>
          <td>{log.notes || '\u2014'}</td>
          <td>{log.locationFormatted || '\u2014'}</td>
        </tr>
      );
    });
  }
};

const LogsTable = ({ logs, trackingType }) => {
  const [sortedLogs, setSortedLogs] = useState(logs);
  return (
    <div className="tab-pane" id="tracking-history">
      <table className="table table-striped tracking-history-table">
        <thead>
          <tr>
            <th
              className="sortLogs"
              onClick={() => setSortedLogs([...sortedLogs.reverse()])}
            >
              Date
              <Icon type="sort" />
            </th>
            <th>Time</th>
            <th
              hidden={
                trackingType === 'Order' || trackingType === 'NonNav'
                  ? false
                  : true
              }
            >
              Shipment #
            </th>
            <th>Activity</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>{getTableRows(sortedLogs, trackingType)}</tbody>
      </table>
    </div>
  );
};

export default LogsTable;
