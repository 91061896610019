import React from 'react';
import Icon from '../icon';
import { OverlayTrigger } from 'react-bootstrap';
import { tooltip } from '../../utils/helperMethods/commonComponents';
import LinesEllipsis from 'react-lines-ellipsis'

export default function Incident(props) {
  return (
    <div className="incidents-box">
      <div className="incidents-text">
        <div id="incidentViewName" style={{ fontWeight: 'bold', cursor: 'pointer' }}> {props.name} </div>
        <OverlayTrigger placement="top" overlay={tooltip(props.description, "incidentViewDescription")}>
          <LinesEllipsis id="incidentViewDescription" data-test="incidentDescription" text={props.description} maxLine='3' ellipsis='...' trimRight basedOn='letters'/>
        </OverlayTrigger>
      </div>
      <div className="incidents-icons">
        {props.incident.affectedArea && props.incident.affectedArea.features.length > 0 &&
          <OverlayTrigger placement="bottom" overlay={tooltip("View On Map", "View On Map", "mapview")}>
            <Icon id="incidentViewOnMap" type="map" onClick={() => { props.viewIncident(props.incident) }} style={{ marginRight: ".4rem" }} />
          </OverlayTrigger>
        }
        {props.permissions && props.permissions.includes('CreateIncidents') &&
          <OverlayTrigger placement="bottom" overlay={tooltip("Delete Incident", "Delete Incident", "deleteIncident")}>
            <Icon id="incidentDeleteIt" type="delete" onClick={() => { props.deleteIncident(props.incident) }} style={{ marginRight: ".4rem" }} />
          </OverlayTrigger>
        }
        {props.source === 'manual' && props.permissions && props.permissions.includes('CreateIncidents') &&
          <OverlayTrigger placement="bottom" overlay={tooltip("Edit Incident", "Edit Incident", "editIncident")}>
            <Icon id="incidentEditIt" type="edit" onClick={() => { props.editIncident(props.incident) }} style={{ marginRight: ".4rem" }} />
          </OverlayTrigger>
        }
      </div>
    </div>
  );
}
