import React, { Component } from 'react';
import Icon from '../icon';

export class StopPopover extends Component {
  render() {
    let stop = this.props.stop;
    let stopTypeText = stop.stopCountText || 'Stop';
    let complete = !!stop.stopCompleteDateTime;
    let stopName = stop.name || '—';
    let address = `${stop.city || '-'}, ${stop.state || '-'}, ${stop.country || '-'}`;

    return (
      <div className="map-popover bubble medium">
        <div>
          <a id="closeInfobox" > <Icon className="icon close" type="close" /></a>
          <span className="read-only-label">{stopTypeText} {complete ? '(Complete)' : '(Not Complete)'}</span>
          <span className="read-only-value" style={{marginTop: '5px'}}>{stopName}</span>
          <span className="read-only-value">{address}</span>
        </div>
      </div>
    );
  }
}

export default StopPopover;
