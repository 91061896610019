import * as types from './actionTypes';

export default {
  toggleSidenavExpanded(expanded) {
    return {
      type: types.TOGGLE_SIDENAV_EXPANDED,
      payload: {
        expanded: expanded
      }
    };
  }
}
