export const FETCH_ORDER_TRACKING_SUMMARY_STARTED = 'order-tracking-summary/FETCH_ORDER_TRACKING_SUMMARY_STARTED';
export const FETCH_ORDER_TRACKING_SUMMARY_FAILED = 'order-tracking-summary/FETCH_ORDER_TRACKING_SUMMARY_FAILED';
export const FETCH_ORDER_TRACKING_SUMMARY_COMPLETED = 'order-tracking-summary/FETCH_ORDER_TRACKING_SUMMARY_COMPLETED';

export const PROCESS_ROUTEDATA_COMPLETED = 'order-tracking-summary/PROCESS_ROUTEDATA_COMPLETED';

export const FETCH_ORDER_COMPLETED = 'order-tracking-summary/FETCH_ORDER_COMPLETED';

export const FETCH_ORDER_TRACKING_LOGS_STARTED = 'order-tracking-summary/FETCH_ORDER_TRACKING_LOGS_STARTED';
export const FETCH_ORDER_TRACKING_LOGS_COMPLETED = 'order-tracking-summary/FETCH_ORDER_TRACKING_LOGS_COMPLETED';
export const FETCH_ORDER_TRACKING_LOGS_PROCESS = 'order-tracking-summary/FETCH_ORDER_TRACKING_LOGS_PROCESS';

export const RESET_PROPS = 'order-tracking-summary/RESET_PROPS';