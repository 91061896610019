import Shipments from './Shipments';
import React from 'react';
import { LayoutHOC } from '../../components/layout';
import {ShipmentsPageRBAC} from './rbac'
import { JOB_MODES } from '../../utils/constants';

const wrapped = props => <Shipments jobMode={JOB_MODES.shipments} {...props}/>
const ShipmentsPage = LayoutHOC(wrapped);
ShipmentsPage._RBAC = ShipmentsPageRBAC;

export {
  Shipments,
  ShipmentsPage
};
