import { useChrLdFlags } from '@chr/common-launchdarkly';
import React, {Component} from 'react';
import { JOB_MODES } from '../../utils/constants';
import {ShipmentTracker} from '../shipment-tracker/index'

const OrderTracker = props => {
  const featureFlags = useChrLdFlags();
  const showMultiShipmentOrder = featureFlags['vision_ui_multi_shipment_order'];

  if(featureFlags['vision_ui_multi_shipment_order'] !== undefined) {
    return <ShipmentTracker jobMode={JOB_MODES.orders} showMultiShipmentOrder={showMultiShipmentOrder} {...props}/>
  } else {
    return null;
  }
}

export default OrderTracker;