import React from 'react'
import Footer from '../../components/footer';
import { InventoryContainer } from '../../containers/inventory/InventoryContainer';

export default function Inventory(props) {

  return (
    <div>
      <div id="inventorymain" className="container-fluid">
        <div className="row space-top">
          <div style={{marginBottom: '5rem'}} className="col-sm-12">
            <h1>Inventory</h1>
            <InventoryContainer {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}
