import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';
import { getUserProfile } from '../containers/auth/authHelper';

export default {
  async fetchInventory(payload) {
    const config = await getConfiguration();
    const endpoint = config.inventoryApiEndpoint;
    const userProfile = await getUserProfile();

    const requestPayload = {
      tenantId: userProfile.tenantId,
      numberPerPage: 50,
      page: payload.currentPage,
      searchText: payload.searchText,
      filters: {
        line_of_business: payload.lineOfBusiness ? payload.lineOfBusiness : [],
        location_name: payload.locationNames ? payload.locationNames : []
      }
    };
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post('inventory/search', requestPayload);
			return result;
    } catch (err) {
      throw `Error Retrieving Inventory: ${err}`;
    }
  },
  async fetchInventoryDetails(payload) {
    const config = await getConfiguration();
    const endpoint = config.inventoryApiEndpoint;
    const userProfile = await getUserProfile();

    payload.tenantId = userProfile.tenantId;

    try {
      const request = await clientFactory(endpoint);
      const result = await request.post(`inventory/${payload.sort.identifier}/details`, payload);
			return result;
    } catch (err) {
      throw `Error Retrieving Inventory Details: ${err}`;
    }
  }
};
