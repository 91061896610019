export const SEARCH_SHIPMENTS_STARTED = 'shipments/SEARCH_SHIPMENTS_STARTED';
export const SEARCH_SHIPMENTS_FAILED = 'shipments/SEARCH_SHIPMENTS_FAILED';
export const SEARCH_SHIPMENTS_COMPLETED = 'shipments/SEARCH_SHIPMENTS_COMPLETED';
export const FETCH_LIST_SHIPMENTS_STARTED = 'shipments/FETCH_LIST_SHIPMENTS_STARTED';
export const FETCH_LIST_SHIPMENTS_FAILED = 'shipments/FETCH_LIST_SHIPMENTS_FAILED';
export const FETCH_LIST_SHIPMENTS_COMPLETED = 'shipments/FETCH_LIST_SHIPMENTS_COMPLETED';
export const TOGGLE_SHIPMENT_VIEW_MODE = 'shipments/TOGGLE_SHIPMENT_VIEW_MODE';
export const NAVIGATE_TO_SHIPMENT_FROM_EXCEPTION_CARD = 'shipments/NAVIGATE_TO_SHIPMENT_FROM_EXCEPTION_CARD';
export const NAVIGATE_TO_SHIPMENT_FROM_INCIDENT_CARD = 'shipments/NAVIGATE_TO_SHIPMENT_FROM_INCIDENT_CARD';
export const CHANGED_SHIPMENTS_FILTERS = 'shipments/CHANGED_SHIPMENTS_FILTERS';
export const CHANGED_SHIPMENTS_FILTERS_UPDATE_CONFIGURATION = 'shipments/CHANGED_SHIPMENTS_FILTERS_UPDATE_CONFIGURATION';
export const CHANGED_SHIPMENTS_FACILITY_FILTERS = 'shipments/CHANGED_SHIPMENTS_FACILITY_FILTERS';
export const CHANGED_SHIPMENTS_FACILITY_FILTERS_UPDATE_CONFIGURATION = 'shipments/CHANGED_SHIPMENTS_FACILITY_FILTERS_UPDATE_CONFIGURATION';
export const CHANGED_SHIPMENTS_DATE_FILTER = 'shipments/CHANGED_SHIPMENTS_DATE_FILTER';
export const CHANGED_SHIPMENTS_DATE_FILTER_UPDATE_CONFIGURATION = 'shipments/CHANGED_SHIPMENTS_DATE_FILTER_UPDATE_CONFIGURATION';
export const CLEARED_SHIPMENTS_FILTERS = 'shipments/CLEARED_SHIPMENTS_FILTERS';
export const TOGGLE_SHIPMENT_DISPLAY_MODE = 'shipments/TOGGLE_SHIPMENT_DISPLAY_MODE';
export const FETCH_AFFECTED_SHIPMENTS_STARTED = 'shipments/FETCH_AFFECTED_SHIPMENTS_STARTED';
export const FETCH_AFFECTED_SHIPMENTS_FAILED = 'shipments/FETCH_AFFECTED_SHIPMENTS_FAILED';
export const FETCH_AFFECTED_SHIPMENTS_COMPLETED = 'shipments/FETCH_AFFECTED_SHIPMENTS_COMPLETED';
export const FETCH_EXCEPTIONS_STARTED = 'exceptions/FETCH_EXCEPTIONS_STARTED';
export const FETCH_EXCEPTIONS_FAILED = 'exceptions/FETCH_EXCEPTIONS_FAILED';
export const FETCH_EXCEPTIONS_COMPLETED = 'exceptions/FETCH_EXCEPTIONS_COMPLETED';
export const FETCH_SHIPMENT_REFERENCES_STARTED = 'shipments/FETCH_SHIPMENT_REFERENCES_STARTED';
export const FETCH_SHIPMENT_REFERENCES_FAILED = 'shipments/FETCH_SHIPMENT_REFERENCES_FAILED';
export const FETCH_SHIPMENT_REFERENCES_COMPLETED = 'shipments/FETCH_SHIPMENT_REFERENCES_COMPLETED';
export const MAP_DETAILS_CHANGED = 'shipments/MAP_DETAILS_CHANGED';
export const CURRENT_PAGE_CHANGED = 'shipments/CURRENT_PAGE_CHANGED';
export const SORT_CHANGED = 'shipments/SORT_CHANGED';
export const NEW_QUERY_PARAMS = 'shipments/NEW_QUERY_PARAMS';
export const NEW_QUERY_PARAMS_UPDATE_CONFIGURATION = 'shipments/NEW_QUERY_PARAMS_UPDATE_CONFIGURATION';
export const FILTER_SET_LOADED = 'shipments/FILTER_SET_LOADED';
export const UPDATED_QUERY_PARAMS = 'shipments/UPDATED_QUERY_PARAMS';
export const CLUSTER_SELECTED = 'shipments/CLUSTER_SELECTED';
export const SEARCH_TEXT_CHANGED = 'shipments/SEARCH_TEXT_CHANGED';
export const MAP_CLUSTERING_REMOVED = 'shipments/MAP_CLUSTERING_REMOVED';
export const SET_JOB_MODE = 'shipments/SET_JOB_MODE';
export const TOGGLE_OUTAGE_ANNOUNCEMENT_ACTIVE = 'shipments/TOGGLE_OUTAGE_ANNOUNCEMENT_ACTIVE';