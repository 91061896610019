import React from 'react';

import Layout from './Layout';
import { useAnalyticsTrack } from '@chr/react-analytics';

export default function LayoutHOC(Component) {
  return function WrappedComponent(props) {
      const track = useAnalyticsTrack();
      return (
        <Layout {...props} adobeTrack={track}>
          <Component {...props} adobeTrack={track}/>
        </Layout>
      );
  }
}
