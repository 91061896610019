import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  filterSets: [],
  filterSetsLoadingState: 'nonloaded'
});

function filterSetsReducer(state = initialState, action={}) {
  switch (action.type) {
    case types.FETCH_FILTERSETS_STARTED:
      return state
        .set('filterSetsLoadingState', 'loading');
    case types.FETCH_FILTERSETS_COMPLETED:
      return state
        .set('filterSets', action.payload.filterSets)
        .set('filterSetsLoadingState', 'loaded');
    default:
      return state;
  }
}

export default filterSetsReducer;
