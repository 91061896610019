import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger } from 'react-bootstrap';
import iotActions from '../../containers/iot/actions';
import { popover } from '../../utils/helperMethods/commonComponents';
import { trackShipment } from '../../utils/helperMethods/shipmentMethods';
import IotShipmentsTables from './iotShipmentsTable';
import LoadingIcon from '../../components/loading-icon';
import PaginationGroup from '../../components/pagination-group';

export class IotDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1
    }

    this.openInShipmentTracker = this.openInShipmentTracker.bind(this);
  }

  componentDidMount() {
    const currentPage = this.state.currentPage;
    const afterKey = this.props.dashboardPageAfterKeys.get((currentPage - 1));
    this.props.getActiveIotShipments({
      limit: this.props.limit,
      afterKey: afterKey,
      pageNumber: currentPage
    });
  };

  openInShipmentTracker = (shipment) => {
    var formatData = {
      ...shipment,
      history: this.props.history
    };

    trackShipment(formatData);
  }

  fetchActiveIotShipments = async (selectedPageNumber) => {
    const { getActiveIotShipments, limit, dashboardPageAfterKeys } = this.props;

    const afterKey = dashboardPageAfterKeys.get((selectedPageNumber - 1).toString());
    this.setState({
      currentPage: selectedPageNumber
    })

    await getActiveIotShipments({ limit, afterKey, pageNumber: selectedPageNumber });
  }

  render() {
    const { activeIotShipments, loading, userProfile, limit } = this.props;
    return (
      <div className="page flex-column iot-page" data-test="iotPage">
        <div className="col-xs-12" style={{ marginBottom: '10rem', paddingTop: '2rem' }}>
          <h1 className="iot-page-header">
              IoT Dashboard &nbsp;
          </h1>
          {loading ?
            <div className="centered"><LoadingIcon /></div>
            :
            <div>
              <IotShipmentsTables
                data-test="iotShipmentsTable"
                iotShipments={activeIotShipments.shipments}
                userPermissions={userProfile.permissions}
                openInShipmentTracker={this.openInShipmentTracker}
              />
              {activeIotShipments.total > 0 &&
                <PaginationGroup
                  data-test="activeIotShipmentsPaging"
                  className="pagination"
                  totalPages={Math.ceil(activeIotShipments.total / limit)}
                  currentPageNumber={parseInt(this.state.currentPage)}
                  limit={limit}
                  total={activeIotShipments.total}
                  fetchNextPage={this.fetchActiveIotShipments.bind(this)}
                  label="iot-active-shipments"
                  hideLastPage={true}
                />
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    getActiveIotShipments: ({ limit, afterKey, pageNumber }) => {
      return dispatch(iotActions.fetchActiveIotShipments({limit, afterKey, pageNumber }));
    }
  };
};

export const mapStateToProps = state => {
  return {
    activeIotShipments: state.iotSummary.get('activeIotShipments'),
    dashboardPageAfterKeys: state.iotSummary.get('dashboardPageAfterKeys'),
    limit: state.iotSummary.get('limit'),
    userProfile: state.users.get('userProfile'),
    loading: state.iotSummary.get('activeIotShipmentsLoading')
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IotDashboard);
