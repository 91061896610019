import * as types from './actionTypes';

export default {
  getExportDownload(userEmail, exportType, fileName) {
    return {
      type: types.GET_EXPORT_DOWNLOAD_STARTED,
      payload: {
        userEmail,
        exportType,
        fileName
      }
    };
  },
  getExportDownloadCompleted(payload) {
    return {
      type: types.GET_EXPORT_DOWNLOAD_COMPLETED,
      payload: payload
    };
  },
  getExportDownloadFailed(payload) {
    return {
      type: types.GET_EXPORT_DOWNLOAD_FAILED,
      payload: payload
    };
  }
}