export const sensorFormatter = (sensorData, userSettings) => {
  const iotTemperatureSettingIsF = getUserProfileSetting(userSettings, 'iotTemperatureToggleIsF');
  if (!userSettings || iotTemperatureSettingIsF === 'false') {
    return sensorData;
  }

  sensorData.forEach((sensorGroup) => {
    sensorGroup.metrics.forEach((sensorMetric) => {
    if (sensorMetric.sensorType === 'TEMPERATURE') {
        sensorMetric.uom = 'F';
        sensorMetric.currentValue = convertCelsiusToFahrenheit(sensorMetric.currentValue);
      }
    })
  });

  return sensorData;
}

export const averagesSensorFormatter = (sensorData, userSettings) => {
  const iotTemperatureSettingIsF = getUserProfileSetting(userSettings, 'iotTemperatureToggleIsF');
  if (!userSettings || iotTemperatureSettingIsF === 'false') {
    return sensorData;
  }

  sensorData.forEach((sensorGroup) => {
    sensorGroup.forEach((sensorMetric) => {
    if (sensorMetric.sensorType === 'TEMPERATURE') {
        sensorMetric.uom = 'F';
        sensorMetric.averageValue = convertCelsiusToFahrenheit(sensorMetric.averageValue);
        sensorMetric.maxValue = convertCelsiusToFahrenheit(sensorMetric.maxValue);
        sensorMetric.minValue = convertCelsiusToFahrenheit(sensorMetric.minValue);
      }
    })
  });

  return sensorData;
}

const getUserProfileSetting = (settings, property) => {
  if (!settings || !settings.length) {
    return null;
  }

  const userSetting = settings.find(prop => prop.propertyName === property);
  return userSetting ? userSetting.propertyValue[0] : null;
}

const _roundToTenthPlace = (value, precision) => {
  const power = Math.pow(10, precision || 0);

  return Math.round(value * power) / power;
};

const convertCelsiusToFahrenheit = (C) => {
  return _roundToTenthPlace((C * 9/5) + 32, 1);
}
