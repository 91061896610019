import React, { Component } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Icon from '../../../components/icon';
import { tooltip } from '../../../utils/helperMethods/commonComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { getDate, getTime } from '../../../utils/helperMethods/dateTimeMethods';
import '../extras/styles.scss';
import * as orderNumbersUtils from '../../../utils/helperMethods/orderNumbersUtils';
import Dropdown from '../../../components/dropdown/Dropdown';
import { isNonNav, showProbabilityOfDelay } from '../../../utils/helperMethods/commonMethods';
import ReferencesContainer from '../../../containers/shipments/ReferencesContainer';
import { DETAILS_REQUESTED_DATE_HIDDEN_TENANTS, DETAILS_CARRIER_ETA_HIDDEN_TENANTS, USE_CLOSE_DATE_TIME_TENANTS
  , DISPLAY_FORWARDER_TENANTS, DISPLAY_ADITIONAL_IDENTIFIERS_TENANTS } from '../../../utils/constants';

const CarrierOrForwarderInformation = (props) => {
  if (DISPLAY_FORWARDER_TENANTS.includes(props.tenantId) && props.forwarder == null) {
    return null;
  }

  let tenantLabel = 'Carrier';
  let tenantDisplay = props.carrierName || '—';
  if (DISPLAY_FORWARDER_TENANTS.includes(props.tenantId)) {
    tenantLabel = 'Forwarder';
    tenantDisplay = props.forwarder;
  }

  return (
    <div className="col-lg-3">
      <div className="read-only">
        <span className="read-only-label">{tenantLabel}</span>
        <span id='carrierName' className="read-only-value">{tenantDisplay}</span>
      </div>
    </div>
  );
}

const CarrierName = (props) => {
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Carrier" labelValue={props.carrierName}/>
  );  
}

const CarrierProNumber = (props) => {
  return ['Air', 'Ocean'].includes(props.mode) ?
    <></>
    :
    <AditionalIdentifier tenantId={props.tenantId} label="Carrier Pro Number" labelValue={props.carrier_pro_number}/>
}

const DGCargo = (props) => {
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="DG Cargo" labelValue=
    {props?.is_haz_mat? "Yes" : "No"}/>
  );
} 

const FreightClass = (props) => {
  let freightLabelVal = null;
  if(!!props.handling_units && props.handling_units.length > 0) {
    freightLabelVal = props.handling_units.reduce((prev, current) => {
      var prevFreightClass = prev.freight_class ? +prev.freight_class : 0;
      var currentFreightClass = current.freight_class ? +current.freight_class : 0;
      return (prevFreightClass > currentFreightClass) ? prev : current;
    }).freight_class;
  }
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Freight Class" labelValue={freightLabelVal}/>
  );  
}

const IncoTerms = (props) => {
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="IncoTerms" labelValue={props.inco_term}/>
  );  
}

const IncoLocation = (props) => {
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Inco Location" labelValue={props.inco_location}/>
  );  
}

const EquipmentType = (props) => {
  // US - 964134
  let trefValue = props.reference_numbers?.find(item => {return item.code === "TREF"})?.value ?? '—';
  const eqtValue = props.reference_numbers?.find(item => {return item.code === "EQT"})?.value ?? '';

  if(trefValue !== '—' && eqtValue !== ''){
    trefValue = `${trefValue} - ${eqtValue}`;
  }

   return(
    <AditionalIdentifier tenantId={props.tenantId} label="Equipment Type" labelValue={trefValue}/>
  );  
}

const TrailerNumber = (props) => {
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Trailer Number" labelValue={props.trailer_number}/>
  );  
}

const ServiceType = (props) => { 
  // 879355 - Service Type Unclassified should not display on Shipment Details 
  if(props.service_type && props.service_type !== "Unclassified"){
    return(
    <AditionalIdentifier tenantId={props.tenantId} label="Service Type" labelValue={props.service_type}/>
  );
  }else{
    return null;
  }    
}

const TractorNumber = (props) => {
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Tractor Number" labelValue={props.tractor_number}/>
  );  
}

const MepCode = (props) => {
  // US - 964134
  const mepCodeValue = props.reference_numbers?.find(item => {return item.code === "MEPCODE"})?.value ?? '—';   
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="MEP" labelValue={mepCodeValue}/>
  );  
}

const BondedWH = (props) => {  
  // US - 964134
  const tebValue = props.reference_numbers?.find(item => {return item.code === "TEB"})?.value ?? '—';   
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Bonded W/H" labelValue={tebValue}/>
  );  
}

const ExpressClearance = (props) => {
  // US - 964134
  const cbpValue = props.reference_numbers?.find(item => {return item.code === "CBP"})?.value ?? '—';
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Express Clearance" labelValue={cbpValue}/>
  );  
}

const Flow = (props) => {
  // US - 964250
  const flowValue = props.reference_numbers?.find(item => {return item.code === "DEPT"})?.value ?? '—';
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="Flow" labelValue={flowValue}/>
  );  
}

const MepRegion = (props) => {
  // US - 973277
  const MepRegionValue = props.reference_numbers?.find(item => {return item.code === "MEPREGION"})?.value ?? '—';
  return(
    <AditionalIdentifier tenantId={props.tenantId} label="MEP Region" labelValue={MepRegionValue}/>
  );  
}


const AditionalIdentifier = (props) => {
  if(DISPLAY_ADITIONAL_IDENTIFIERS_TENANTS.includes(props.tenantId)){    
    return(      
      <IdentifierLabel label={props.label} labelValue={props.labelValue} avoidNull={true}/>      
    );
  }
  else{
    return null;
  }
}

const IdentifierLabel = (props) => {
  if( props.avoidNull && (props.labelValue === null || props.labelValue === undefined)){
    return null;
  }
  else{
    return (      
      <div className="col-lg-3">
      <div className="read-only" data-test="additionalIdentifier">
        <span className="read-only-label">{props.label}</span>
        <span className="read-only-value">{props.labelValue}</span>
      </div>
    </div>
    );
  }
}

export class Details extends Component {

  isGfs = (origination) => {
    return origination === 3;
  }

  getGfsRequestedDate = (stop) => {
    if (stop.stopType === "P" || stop.stopType === "D") {      
      return (
        <div className="read-only">
          <span className="read-only-label">Requested</span>
          <span className="read-only-value">{stop.requestedDateTime ? getDate(stop.requestedDateTime) : '—'}</span>
          <span className="read-only-time">{stop.requestedDateTime ? getTime(stop.requestedDateTime) : ''}</span>
        </div>
      );
    } else {
      return (
        <div className="read-only">
          <span className="read-only-label">Requested</span>
          <span className="read-only-value">N/A</span>
        </div>
      );
    }
  }

  getGfsScheduledDate = (stop) => {
    if (stop.stopType === "D" || (stop.stopStyle === "N" && stop.stopType === "P")) {      
      return (
        <div className="read-only">
          <span className="read-only-label">Scheduled</span>
          <span className="read-only-value">{stop.scheduledDateTimeClose ? getDate(stop.scheduledDateTimeClose) : '—'}</span>
          <span className="read-only-time">{stop.scheduledDateTimeClose ? getTime(stop.scheduledDateTimeClose) : ''}</span>
        </div>
      );
    } else if ((stop.stopStyle === "P" && (stop.stopType === "P" || stop.stopType === "OP"))
      || (stop.stopStyle === "A" && (stop.stopType === "P" || stop.stopType === "OA"))) {      
      return (
        <div className="read-only">
          <span className="read-only-label">Scheduled</span>
          <span className="read-only-value">{stop.scheduledDateTimeClose ? getDate(stop.scheduledDateTimeClose) : '—'}</span>
          <span className="read-only-time" data-test="stopTypeP">{stop.scheduledDateTimeClose ? getTime(stop.scheduledDateTimeClose) : ''}</span>
        </div>
      );
    } else {
      return (
        <div className="read-only">
          <span className="read-only-label">Scheduled</span>
          <span className="read-only-value">N/A</span>
        </div>
      );
    }
  }

  renderGfsStops = (stops, load, showModal, closeModal, tenantId) => {
    if (stops && stops.length > 0) {
      return stops.map((stop, index) => {
        let stopDetailDiv =
          <div className="read-only">
            <span id={`stop-${index}-count`} className="read-only-label">{stop.stopCountText || '—'}</span>
            <span id={`stop-${index}-name`} className="read-only-value">{stop.name || '—'}</span>
            <span id={`stop-${index}-address`} className="read-only-value">{stop.city || '-'}, {stop.state || '-'}, {stop.country || '-'}</span>
          </div>
        
        let stopEtaDiv =
          <div className="read-only">
            <span className="read-only-label">Carrier ETA</span>
            <span className="read-only-value">{stop.eta ? getDate(stop.eta) : '—'}</span>
            <span className="read-only-time">{stop.eta ? getTime(stop.eta) : ''}</span>
          </div>
        
        let stopActualDiv =
          <div className="read-only">
            <span className="read-only-label">Actual</span>
            <span className="read-only-value">{stop.arrivedDateTime ? getDate(stop.arrivedDateTime) : '—'}</span>
            <span className="read-only-time">{stop.arrivedDateTime ? getTime(stop.arrivedDateTime) : ''}</span>
          </div>
        
        let stopCompleteDiv =
          <div className="read-only">
            <span className="read-only-label">Complete</span>
            <span className="read-only-value">{stop.stopCompleteDateTime ? getDate(stop.stopCompleteDateTime) : '—'}</span>
            <span className="read-only-time">{stop.stopCompleteDateTime ? getTime(stop.stopCompleteDateTime) : ''}</span>
          </div>

        return (
          <div key={index} className="load-stops">
            <div className="load-stop-identifier" data-test="gfsStopDetails">
              {stopDetailDiv}
            </div>
            <div className="load-stop-dates">
              <div className="load-stop-flex">
                {!DETAILS_REQUESTED_DATE_HIDDEN_TENANTS.includes(tenantId) &&
                  <div className="load-stop-date" data-test="gfsStopRequested">
                    {this.getGfsRequestedDate(stop)}
                  </div>
                }
                <div className="load-stop-date" data-test="gfsStopScheduled">
                  {this.getGfsScheduledDate(stop)}
                </div>
                {!DETAILS_CARRIER_ETA_HIDDEN_TENANTS.includes(tenantId) &&
                  <div className="load-stop-date" data-test="gfsStopEta">
                    {stopEtaDiv}
                  </div>
                }
                <div className="load-stop-date" data-test="gfsStopActual">
                  {stopActualDiv}
                </div>
                <div className="load-stop-date" data-test="gfsStopComplete">
                  {stopCompleteDiv}
                </div>
                {isNonNav(this.props.load) ? 
                  (<div>
                    {this.stopsOverlay(stop)}
                  </div>) : 
                  null
                }
              </div>
            </div>
            {!this.props.allOrderNumbersAreSame ? this.renderOrderNumbers(stop.orderNumbers, load, showModal, closeModal) : ''}
          </div>
        );
      });
    }
  }

  renderPickDrops = (stops, load, showModal, closeModal, tenantId) => {
    if (stops && stops.length > 0) {
      let stopsHaveDataScienceEta = stops.filter(s => s.calculated_ETA != null && s.calculated_ETA !== "").length > 0
      let stopsHaveCarrierEta = stops.filter(s => s.eta != null && s.eta !== "").length > 0;

      return stops.map((stop, index) => {
        let stopDetailDiv =
          <div className="read-only">
            <span className="read-only-label" id={`stop-${stop.stopCountText}-count`}>{stop.stopCountText || '—'}</span>
            <span className="read-only-value" id={`stop-${index}-name`}>{stop.name || '—'}</span>
            <span className="read-only-value" id={`stop-${index}-location`}>{stop.city || '-'}, {stop.state || '-'}, {stop.country || '-'}</span>
          </div>;
        
        let stopRequestedDiv =
          <div className="read-only">
            <span className="read-only-label">Requested</span>
            <span className="read-only-value" id={`stop-${index}-requested-date`}>{stop.requestedDateTime ? getDate(stop.requestedDateTime) : '—'}</span>
            <span className="read-only-time" id={`stop-${index}-requested-time`}>{stop.requestedDateTime ? getTime(stop.requestedDateTime) : ''}</span>
          </div>;
        
        let stopScheduledDiv =
          <div className="read-only">
            <span className="read-only-label">Scheduled</span>
            <span className="read-only-value" id={`stop-${index}-scheduled-date`}>{stop.scheduledDateTimeClose ? getDate(stop.scheduledDateTimeClose) : '—'}</span>
            <span className="read-only-time" id={`stop-${index}-scheduled-time`}>{stop.scheduledDateTimeClose ? getTime(stop.scheduledDateTimeClose) : ''}</span>
          </div>;        
        
        let stopScheduledDivWithCloseDateTime =
          <div className="read-only">
            <span className="read-only-label">Scheduled</span>
            <span className="read-only-value" id={`stop-${index}-scheduled-date`}>{stop.closeDateTime ? getDate(stop.closeDateTime) : '—'}</span>
            <span className="read-only-time" id={`stop-${index}-scheduled-time`}>{stop.closeDateTime ? getTime(stop.closeDateTime) : ''}</span>
          </div>;
        
        let stopEtaDiv =
          <div className="read-only">
            <span className="read-only-label">Carrier ETA</span>
            <span className="read-only-value" id={`stop-${index}-eta-date`}>{stop.eta ? getDate(stop.eta) : '—'}</span>
            <span className="read-only-time" id={`stop-${index}-eta-time`}>{stop.eta ? getTime(stop.eta) : ''}</span>
          </div>;

        let stopDataScienceEtaDiv =
          <div className="read-only">
            <span className="read-only-label">Data Science ETA</span>
            <span className="read-only-value" id={`stop-${index}-calculated-eta-date`}>{stop.calculated_ETA ? getDate(stop.calculated_ETA) : '—'}</span>
            <span className="read-only-time" id={`stop-${index}-calculated-eta-time`}>{stop.calculated_ETA ? getTime(stop.calculated_ETA) : ''}</span>
          </div>;
       
        let stopActualDiv =
          <div className="read-only">
            <span className="read-only-label">Actual</span>
            <span className="read-only-value" id={`stop-${index}-arrived-date`}>{stop.arrivedDateTime ? getDate(stop.arrivedDateTime) : '—'}</span>
            <span className="read-only-time" id={`stop-${index}-arrived-time`}>{stop.arrivedDateTime ? getTime(stop.arrivedDateTime) : ''}</span>
          </div>;
       
        let stopCompleteDiv =
          <div className="read-only">
            <span className="read-only-label">Complete</span>
            <span className="read-only-value" id={`stop-${index}-completed-date`}>{stop.stopCompleteDateTime ? getDate(stop.stopCompleteDateTime) : '—'}</span>
            <span className="read-only-time" id={`stop-${index}-completed-time`}>{stop.stopCompleteDateTime ? getTime(stop.stopCompleteDateTime) : ''}</span>
          </div>;
          
        return (
          <div key={index} className="load-stops">
            <div className="load-stop-identifier" data-test="stopDetails">
              {stopDetailDiv}
            </div>
            <div className="load-stop-dates">
              <div className="load-stop-flex">
                {!DETAILS_REQUESTED_DATE_HIDDEN_TENANTS.includes(tenantId) &&
                  <div className="load-stop-date" data-test="stopRequested">
                    {stopRequestedDiv}
                  </div>
                }
                <div className="load-stop-date" data-test="stopScheduled">
                  {USE_CLOSE_DATE_TIME_TENANTS.includes(tenantId) ? stopScheduledDivWithCloseDateTime : stopScheduledDiv}
                </div>
                {stopsHaveDataScienceEta && !stopsHaveCarrierEta ?
                  <></> :
                  !DETAILS_CARRIER_ETA_HIDDEN_TENANTS.includes(tenantId) && 
                  <div className="load-stop-date" data-test="stopEta">
                    {stopEtaDiv}
                  </div>
                }
                {stopsHaveDataScienceEta ?
                  <div className="load-stop-date" data-test="stopDataScienceEta">
                    {stopDataScienceEtaDiv}
                  </div> :
                  <></>
                }
                <div className="load-stop-date" data-test="stopActual">
                  {stopActualDiv}
                </div>
                <div className="load-stop-date" data-test="stopComplete">
                  {stopCompleteDiv}
                </div>
              </div>
            </div>
            {isNonNav(this.props.load) ? 
              (<div className="load-stop-options">
                {this.stopsOverlay(stop)}
              </div>) : 
              null
            }
            {!this.props.allOrderNumbersAreSame ? this.renderOrderNumbers(stop.orderNumbers, load, showModal, closeModal) : ''}
          </div>
        );
      });
    }
  }

  renderOrderNumbers = (stopOrderNumbers, load, showModal, closeModal) => {
    // When this component is loaded from the external tracking page, this permissions object will be undefined. We want to allow access in this case.
    const ableToViewOrderTracker = this.props.userProfile.permissions ? this.props.userProfile.permissions.includes('ViewOrderTracker') : true;
    let orderNumbers = stopOrderNumbers || load.order_numbers;
    const props = { ...load, history: this.props.history, tenantId: this.props.userProfile.tenantId, ableToViewOrderTracker, closeModal};

    if (!orderNumbers || !orderNumbers.length) {
      return '';
    }

    return (
      <div className="load-stop-orders read-only">
        <span id="loadStopOrdersLabel" className="read-only-label">
          {"Order(s):"}
        </span>
        <div className="order-numbers">
          <span id="orderNumbers" className="read-only-value">
            {orderNumbersUtils.getOrderNumberLink(props, orderNumbersUtils.defaultDisplayOrderNumbersLimit, orderNumbers)}
          </span>
          {orderNumbers.length > orderNumbersUtils.defaultDisplayOrderNumbersLimit && (
            <a id="orderNumbersEllipses" title="Show More" className="btn-icon" onClick={() => { showModal(orderNumbersUtils.getOrdersModalConfig(props, orderNumbers)) }}>
              <svg className="icon">
                <use href="#ellipses-horizontal" />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  }

  stopReferenceModal = (stop) => {
    return {
      title: `References for Stop`,
      showCloseButton: true,
      closeButtonClass: 'btn btn-secondary btn btn-secondary-default',
      body: (
        <div className="row">
           <ReferencesContainer
            references={stop.reference_numbers}
          />
        </div>
      )
    };
  }

  stopsOverlay = (stop) => {
    const anchor = (
      <div className="anchor" data-toggle="dropdown">
       <FontAwesomeIcon id={'stop-reference-icon'} className="global-search-icon" icon={ faEllipsisV } size='1x' />
      </div>
    );

    const items = (
        <div className="dropdown">
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-popup" style={{display: "inline"}}>
          <li className="dropdown-item">
            <a
            className="btn btn-link btn-sm"
            data-test="load-stop-options"
            onClick={() => this.props.showModal(this.stopReferenceModal(stop))}>
              <Icon
                className="icon blue"
                type="document"
              /> &nbsp;
              References
            </a>
          </li>
        </div>
        </div>
    );

    return(
      <OverlayTrigger placement="bottom" overlay={tooltip("More Options", "View More Options for the Stop")}>
        <Dropdown items={items} anchor={anchor} type="dropdown" showTray={true}/>
      </OverlayTrigger>);
  }  

  render() {
    return (
      <div className="panel">
        <div id="collapseTwo" className={this.props.collapseExpand === true ? "panel-collapse collapse show" : "panel-collapse collapse"} role="tabpanel" aria-labelledby="headingTwo">
          <div className="panel-header">
            <div className="row">
              <div className="col-lg-3">
                <div className="read-only">
                  <span className="read-only-label">Risk Level</span>
                  <span id={'risk-level'} className="read-only-value">
                    <span className="status-circle early" />

                    {this.props.load.riskLevel || "On Time"}</span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="read-only">
                  <span className="read-only-label">Reason</span>
                  <span className="read-only-value">{this.props.load.exception_description || '—'}</span>
                </div>
              </div>
              <CarrierOrForwarderInformation tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <CarrierName tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <CarrierProNumber tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <TractorNumber tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <TrailerNumber tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <DGCargo tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <EquipmentType tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <FreightClass tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <IncoTerms tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <IncoLocation tenantId={this.props.userProfile.tenantId} {...this.props.load} />              
              <ServiceType tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <MepCode tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <BondedWH tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <ExpressClearance tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <Flow tenantId={this.props.userProfile.tenantId} {...this.props.load} />
              <MepRegion tenantId={this.props.userProfile.tenantId} {...this.props.load} />
            </div>
          </div>
          <div className="panel-body">
            {this.isGfs(this.props.load.origination) ? 
            this.renderGfsStops(this.props.stops, this.props.load, this.props.showModal, this.props.closeModal, this.props.userProfile.tenantId) 
            : this.renderPickDrops(this.props.stops, this.props.load, this.props.showModal, this.props.closeModal, this.props.userProfile.tenantId)}
          </div>
        </div>
      </div>
    );
  }
};

export default Details;