const selectMap = state =>  state.map;
const selectShipmentMap = state => state.shipmentMap;
const selectDisruptionsMap = state => state.disruptionsMap;

const selectClustering = state => selectMap(state).get('clustering');
const selectPins = state => selectMap(state).get('pushPins');

const selectMapContent = state => selectMap(state).get('mapContent');
const selectMapType = state => selectMap(state).get('mapType');
const selectMapModifiers = state => selectMap(state).get('modifiers');

const selectShipmentMapContent = state => selectShipmentMap(state).get('mapContent');
const selectShipmentMapType = state => selectShipmentMap(state).get('mapType');
const selectShipmentMapModifiers = state => selectShipmentMap(state).get('modifiers');

const selectDisruptionsMapContent = state => selectDisruptionsMap(state).get('mapContent');
const selectDisruptionsMapType = state => selectDisruptionsMap(state).get('mapType');
const selectDisruptionsMapModifiers = state => selectDisruptionsMap(state).get('modifiers');

export {
  selectClustering,
  selectPins,
  selectMapContent,
  selectMapType,
  selectMapModifiers,
  selectShipmentMapContent,
  selectShipmentMapType,
  selectShipmentMapModifiers,
  selectDisruptionsMapContent,
  selectDisruptionsMapModifiers,
  selectDisruptionsMapType
};
