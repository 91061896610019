import React from 'react'
import PropTypes from 'prop-types';

import LoadingIcon from '../../components/loading-icon';
import NewsCard from './NewsCard';

export default function NewsCardList(props) {
  let news = [];
  props.incidents.forEach(x => {
    if (x.type && x.source && (x.source.toLowerCase() === 'twitter' || x.source.toLowerCase() === 'email' || x.source.toLowerCase()==='manual') && x.type.toLowerCase() === 'news') {
      var linkIndex = x.additionalDetails.description.indexOf('http');
      if (linkIndex !== -1) {
        x.additionalDetails.link = x.additionalDetails.description.substring(linkIndex);
        x.additionalDetails.description = x.additionalDetails.description.substring(0, linkIndex);
      }
      news.push(<NewsCard key={x._id} news={x}/>);
    }
  });

  return (
    props.loading ? <div style={{ marginLeft: "10px" }}><LoadingIcon/></div> :
      (news.length ?
        <div className="col-md-12" data-test='newsCards'>
          {news}
        </div> :
        <div style={{ margin: "0 0 25px 15px" }} data-test="noNews">There is no relevant news to display. </div>)
  );
}

NewsCardList.propTypes = {
  incidents: PropTypes.oneOfType([
    // at runtime this is an ImmutableJS List ([object Object])...
    PropTypes.object,
    // at test time, these are mocked with array literals...
    PropTypes.array
  ]),
  loading: PropTypes.bool
};

NewsCardList.defaultProps = {
  incidents: [],
  loading: false
};
