import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../../components/icon/Icon';
import ShipmentList from '../../components/shipments/ShipmentList';
import actions from '../../containers/shipments/actions';
import FilterContainer from '../../containers/filters';
import FilterTag from '../../components/filters/filter-tag/FilterTag';
import Map from '../../components/shipments/Map';
import { generatePillsFromFilters } from '../../utils/helperMethods/commonMethods';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';
import { CAPITALIZED_JOB_MODES, JOB_MODES, JOB_MODE_LIST } from '../../utils/constants';
import LoadingIcon from '../../components/loading-icon';

const cap_job = CAPITALIZED_JOB_MODES;

const handleModeChange = (props, newJobMode, newLocation) => {
  let jobMode = newJobMode || props.jobMode;
  let location = newLocation || props.location
  props.setJobMode(jobMode);
  let page = cap_job[jobMode] + ' Page';
  trackPageInfoEvent(props.adobeTrack, page, page);
  props.newQueryString(location);
} 

export class Shipments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyUnlisten: () => {},
      isDisplayingFilters: true,
      pills: [],
    }
  };

  componentDidMount() {
    // '/orders' -> 'orders'
    let newJobMode = this.props.location.pathname.substring(1, this.props.location.pathname.length);
    handleModeChange(this.props, newJobMode)
    this.setState({historyUnlisten: this.props.history.listen(this.onHistoryChange.bind(this))});
  };

  componentDidUpdate(oldProps) {
    if (oldProps.location.pathname !== this.props.location.pathname) {
      handleModeChange(this.props)
    }
  }

  onHistoryChange(location, action) {
    // A change to an arbitrary index in the stack, such as a back or forward navigation
    if (action === 'POP' || location.search.includes('newSearch')) {
      let shouldQuery = true;
      if (this.props.location.pathname !== location.pathname) {
        // '/orders' -> 'orders'
        let newJobMode = location.pathname.substring(1, location.pathname.length);
        if (JOB_MODE_LIST.includes(newJobMode)) {
          shouldQuery = false;
          //will be handled by componentDidMount()
        }
      }
      if (shouldQuery) {
        this.props.newQueryString(location);
      }
    }
  }

  componentWillUnmount() {
    this.state.historyUnlisten();
  }

  removePill = (pill) => {
    const tag = pill.filter;

    if (tag === "searchText") {
      this.props.clearSearchInput();
    }
    else if (tag === "dateFilter") {
      this.props.dateFilterChanged(pill.label)
    }
    else if (tag === "facilityFilters") {
      const split = pill.label.split(" : ");
      const filter = { facilityName: split[0], parsedFacilityStatus: split[1] };
      this.props.facilityFiltersChanged(filter);
    }
    else if (tag === 'mapCluster') {
      this.props.removeMapClustering();
    }
    else {
      this.props.filtersChanged(tag, pill.label);
    }
  };

  render() {
    const displayMode = this.props.displayMode;
    const jobMode = this.props.jobMode;

    if (!this.props.userProfile && this.props.userLoading) {
      return (
        <div className="page shipment-list flex" role="main">
          <LoadingIcon data-test="orders-loading"/>
        </div>
      );
    }

    if ((this.props.userRole == 'ExternalUser' && jobMode == JOB_MODES.shipments) ||
        (jobMode == JOB_MODES.orders && !this.props.userProfile?.permissions?.includes("ViewOrders"))) 
    {
      return (
        <div className="page shipment-list flex" role="main">
          You do not have permissions to view this page.
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="page shipment-list flex" role="main">
          <div className="collapse dont-collapse-sm filter-collapse" id="filtercontainerCollapse">
            <FilterContainer {...this.props} displayFilters={this.state.isDisplayingFilters} />
          </div>
          <div className="list-view">
            <div className="flex-column">
              <div className="container-fluid page-control-header">
                <div className="applied-filter-container">
                  <div className="flex-row-center">
                    <div className="filter-toggle" style={{flexGrow: 1, alignSelf: 'center'}}>
                      <button type="button" className="btn btn-outline btn-sm"
                        onClick={() => {this.setState(state => ({isDisplayingFilters: !state.isDisplayingFilters}))}}
                        id={'display-filters-button'}
                      >
                        <Icon type="filter"/>  {this.state.isDisplayingFilters ? "Hide" : "Show"}
                      </button>
                    </div>
                    <div style={{flexGrow: 11, alignSelf: 'center'}}>
                      {
                        this.props.pills &&
                        this.props.pills.map((pill, i) => {
                          return <FilterTag id="remove-pill" key={i} label={pill.label} closeFilterTag={() => this.removePill(pill)}></FilterTag>
                        })
                      }
                      {
                        this.props.pills?.length > 0 && <span><a id={'clear-all-button'} onClick={() => this.props.filtersCleared()}>Clear All</a></span>
                      }
                    </div>
                      <div style={{flexGrow: 3, alignSelf: 'center'}}>
                        <div className="map-display-control">
                          <form>
                            <span className="list-only radio radio-inline">
                              <label 
                                className={displayMode === 'listOnly' ? "btn btn-sm btn-primary" : "btn btn-sm"} 
                                id={'list-only-button'}>
                                <input 
                                  type="radio" 
                                  name="view-control" 
                                  onClick={() => { this.props.toggleDisplayMode('listOnly'); }} 
                                  id="list-only-input" 
                                  value="list-only" 
                                  data-test="listOnly" 
                                  disabled={this.props.loading}/>
                                List Only
                              </label>
                            </span>
                            <span className="split-view radio radio-inline">
                              <label 
                                className={displayMode === 'splitView' ? "btn btn-sm btn-primary" : "btn btn-sm"} 
                                id={'split-button'}>
                                <input 
                                  type="radio" 
                                  name="view-control" 
                                  onClick={() => { this.props.toggleDisplayMode('splitView'); }} 
                                  id="split-input" 
                                  value="split" 
                                  data-test="splitView" 
                                  disabled={this.props.loading}/>
                                Split View
                              </label>
                            </span>
                            <span className="map-only radio radio-inline">
                              <label 
                                className={displayMode === 'mapOnly' ? "btn btn-sm btn-primary" : "btn btn-sm"} 
                                id={'map-only-button'}>
                                <input 
                                  type="radio" 
                                  name="view-control" 
                                  onClick={() => { this.props.toggleDisplayMode('mapOnly'); }} 
                                  id="map-only-input" 
                                  value="map-only" 
                                  data-test="mapOnly" 
                                  disabled={this.props.loading}/>
                                Map Only
                              </label>
                            </span>
                          </form>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              {displayMode === 'splitView' ?
                <div className="flex split-section split-view">
                  <ShipmentList 
                    boundByMap={true} 
                    history={this.props.history} 
                    location={this.props.location} 
                    jobMode={this.props.jobMode}/>
                  <Map 
                    showFooter={false} 
                    location={this.props.location} 
                    history={this.props.history}
                    jobMode={this.props.jobMode}
                    displayMode={displayMode}/>
                </div>
                : displayMode === 'mapOnly' ?
                  <div className="flex split-section map-only">
                    <Map 
                      showFooter={false} 
                      boundByMap={false} 
                      location={this.props.location} 
                      history={this.props.history} 
                      jobMode={this.props.jobMode}
                      displayMode={displayMode}/>
                  </div>
                  :
                  <div className="flex split-section list-only">
                    <ShipmentList 
                      boundByMap={false} 
                      history={this.props.history} 
                      location={this.props.location} 
                      jobMode={this.props.jobMode}/>
                  </div>
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
};

export const mapStateToProps = (state) => {
  const userProfile = state.users.get('userProfile');
  const userLoading = state.users.get('userLoading');
  const userRole = userProfile?.role;
  const tenantId = userProfile ? userProfile.tenantId : null;
  const queryConfiguration = state.shipments.queryConfiguration;
  const activeFilters = queryConfiguration.get('activeFilters');
  const activeFacilityFilters = queryConfiguration.get('activeFacilityFilters');
  const displayMode = queryConfiguration.get('displayMode');
  
  const pills = generatePillsFromFilters(activeFilters, activeFacilityFilters, queryConfiguration.get('searchText'), queryConfiguration.get('multiSearchType'), 
  queryConfiguration.get('dateFilter'), queryConfiguration.get('clusterSelected'), tenantId);
  return {
    loading: state.shipments.data.get('loading'),
    activeFilters,
    activeFacilityFilters,
    displayMode,
    userLoading,
    userProfile,
    userRole,
    pills
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleDisplayMode: (displayMode) => {
      dispatch(actions.toggleShipmentDisplayMode(displayMode, ownProps.location, ownProps.history));
    },
    newQueryString: (url) => {
      dispatch(actions.newQueryString(url, ownProps.history));
    },
    filtersChanged: (filterTag, filter) => {
      dispatch(actions.filtersChanged(filterTag, filter, ownProps.location, ownProps.history))
    },
    facilityFiltersChanged: (filter) => {
      dispatch(actions.facilityFiltersChanged(filter, ownProps.location, ownProps.history))
    },
    dateFilterChanged: (filter) => {
      dispatch(actions.dateFilterChanged(filter, ownProps.location, ownProps.history))
    },
    filtersCleared: () => {
      dispatch(actions.filtersCleared(ownProps.location, ownProps.history))
    },
    clearSearchInput: () => {
      dispatch(actions.changeSearchInput(undefined, undefined, ownProps.location, ownProps.history));
    },
    removeMapClustering: () => {
      dispatch(actions.removeMapClustering(ownProps.location, ownProps.history));
    },
    setJobMode: (jobMode) => {
      dispatch(actions.setJobMode(jobMode))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Shipments);