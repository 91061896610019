import { capitalize } from "lodash";

export const READABLE_PACKAGING_TYPES = {
  "20": `20' Standard Dry Container`,
  "20DR": `20' Standard Dry Container`,
  "20FF": `20' Full Void Container`,
  "20FH": `20' Flat Rack Container (Over High)`,
  "20FR": `20' Flat Rack Container`,
  "20FW": `20' Flat Rack Container (Over Wide)`,
  "20HC": `20' High Cube Dry Container`,
  "20HQ": `20' High Cube Container`,
  "20NR": `20' Non Operating Reefer Container`,
  "20OT": `20' Open Top Container`,
  "20RF": `20' Reefer Container`,
  "24BIN": `24" Bin`,
  "30BIN": `30" Bin`,
  "36BIN": `36" Bin`,
  "40": `40' Standard Dry Container`,
  "40BIN": `40" Bin`,
  "40DR": `40' Standard Dry Container`,
  "40FF": `40' Full Void Container`,
  "40FH": `40' Flat Rack Container (Over High)`,
  "40FR": `40' Flat Rack Container`,
  "40FW": `40' Flat Rack Container (Over Wide)`,
  "40HC": `40' High Cube Dry Container`,
  "40HQ": `40' High Cube Container`,
  "40HR": `40' High-Cube Reefer Container`,
  "40NR": `40' Non Operating Reefer Container`,
  "40OT": `40' Open Top Container`,
  "40RF": `40' Standard Reefer Container`,
  "40RH": `40' High Cube Reefer Container`,
  "45HC": `45' High Cube Dry Container`,
  "45HR": `45' High-Cube Reefer Container`,
  "AST": `Assortment`,
  "BAG": `Bags`,
  "BAL": `Bale`,
  "BAT": `Batch`,
  "BDL": `Bundles`,
  "BIN": `Bin`,
  "BLK": `Bulk`,
  "BOT": `Bottle`,
  "BOX": `Boxes`,
  "BRL": `Barrel`,
  "BSHL": `Bushel`,
  "BXT": `Bucket`,
  "CAR": `Carpets`,
  "CAS": `Case`,
  "CG": `CARD`,
  "CLLPK": `Cellpack`,
  "COIL": `Coils`,
  "CRT": `Crates`,
  "CTN": `Cartons`,
  "CUB": `Cube`,
  "CYL": `Cylinder`,
  "DRC": `DRC`,
  "DRM": `Drums`,
  "DSP": `Display`,
  "DZ": `Dozens`,
  "EAC": `Eaches`,
  "ECO": `ECO Crate`,
  "Empty": `Empty/Unknown Packaging`,
  "EURO": `EURO Ctn`,
  "FLAT": `Flat`,
  "FT": `Feet`,
  "GAL": `Gallon`,
  "GOH": `Garment on Hanger`,
  "GYL": `Gaylords`,
  "HDW": `Hundred Weight (lbs)`,
  "HFBIN": `Half Bin`,
  "HFCTN": `Half Ctn`,
  "HVYPK": `Heavy Pack`,
  "I6408": `IFCO 6408`,
  "I6411": `IFCO 6411`,
  "I6413": `IFCO 6413`,
  "I6416": `IFCO 6416`,
  "I6419": `IFCO 6419`,
  "I6423": `IFCO 6423`,
  "I6425": `IFCO 6425`,
  "I6428": `IFCO 6428`,
  "IBC": `Intermediate Bulk Container`,
  "IFCLB": `Pounds-IFCO`,
  "IFCO": `IFCO`,
  "IPLT": `International Pallet`,
  "JAR": `Jar`,
  "KEG": `Keg`,
  "KG": `Kilogram`,
  "KIT": `Kit`,
  "L": `Liter`,
  "LBS": `Pounds`,
  "LNFT": `Linear Foot`,
  "LSE": `Loose`,
  "LUG": `Lug`,
  "M": `Meter`,
  "O6409": `ORBIS 6409`,
  "O6411": `ORBIS 6411`,
  "O6413": `ORBIS 6413`,
  "O6416": `ORBIS 6416`,
  "O6419": `ORBIS 6419`,
  "O6425": `ORBIS 6425`,
  "O6428": `ORBIS 6428`,
  "ORBIS": `ORBIS`,
  "OTHR": `Other`,
  "P4311": `PL 4311`,
  "P4317": `PL 4317`,
  "P6408": `PL 6408`,
  "P6411": `PL 6411`,
  "P6413": `PL 6413`,
  "P6416": `PL 6416`,
  "P6419": `PL 6419`,
  "P6423": `PL 6423`,
  "P6425": `PL 6425`,
  "P6428": `PL 6428`,
  "PAD": `Pad`,
  "PAL": `Pail`,
  "PCK": `Packet`,
  "PCS": `Pieces`,
  "PI": `Tubes/Pipes`,
  "PKG": `Package`,
  "PLT": `Pallet`,
  "PLT1": `Pallet with dimensions of (48"x40")`,
  "PLT2": `Pallet with dimensions of (48"x48")`,
  "PLT3": `Pallet with dimensions of (60"x48")`,
  "POG": `Proof of Gallons`,
  "PR": `Pair`,
  "R6411": `RPC 6411`,
  "R6413": `RPC 6413`,
  "R6416": `RPC 6416`,
  "RCK": `Rack`,
  "REL": `Reels`,
  "RKS": `Racks`,
  "ROL": `Roll`,
  "RORO": `Roll On, Roll Off`,
  "RPC": `RPC`,
  "SAK": `Sack`,
  "SET": `Set`,
  "SHT": `Sheet`,
  "SKD": `Skid`,
  "SKE": `Skid elevating or lift truck`,
  "Skids": `Skids`,
  "SLP": `Slip Sheet`,
  "SQF": `Square Feet`,
  "T6408": `TOSCA 6408`,
  "T6411": `TOSCA 6411`,
  "T6413": `TOSCA 6413`,
  "T6416": `TOSCA 6416`,
  "T6419": `TOSCA 6419`,
  "T6423": `TOSCA 6423`,
  "T6425": `TOSCA 6425`,
  "T6428": `TOSCA 6428`,
  "TH": `Thousand`,
  "TLD": `Intermodal Trailer/Container Load (Rail)`,
  "TNBIN": `Tension Bin`,
  "TOSCA": `TOSCA`,
  "TOT": `Totes`,
  "TRY": `Tray`,
  "TUB": `Tub`,
  "WDCRT": `Wood Crate`,
  "YD": `Yards`,
  "20DC": `20'ft Dry Container`,
  "20DV": `20'ft Dry Van`,
  "20GP": `20'ft General Purpose`,
  "40DC": `40'ft Dry Container`,
  "40DV": `40'ft Dry Van`,
  "40GP": `40'ft General Purpose`,
  "45HQ": `45'ft High Cube`,
  "40NOR": `40'ft NOR container`
};

export const STOP_TYPE_MAPPING = {
  P: "Pick",
  D: "Drop",
  X: "Cross Dock",
  DA: "Destination Airport",
  DP: "Destination Port",
  DR: "Destination Ramp",
  DT: "Destination Terminal",
  IA: "Intermediary Airport",
  IP: "Intermediary Port",
  IR: "Intermediary Ramp",
  OA: "Origin Airport",
  OP: "Origin Port",
  OR: "Origin Ramp",
  OT: "Origin Terminal",
  OF: "Origin Forwarder",
  OC: "Origin Crossdock",
  DF: "Destination Forwarder",
  DC: "Destination Crossdock",
  DY: "Dropyard",
  SP: "Supplier",
  SH: "Shipper",
  CN: "Consignee",
  O: "Origin",
  DS: "Destination",
  BK: "Broker",
  AG: "Agent",
  FC: "Factory",
  UnmappedStops: "Stop"
};

export const IGNORE_RENTENTION = [30];

export const EAPI_V2_TENANTS = [26, 30, 31, 36, 37];

export const TEST_TENANTS = [25, 26];

/** these tenants are tenants that have migrated from the old
 luminosity_shipment_{tenantId} indices to the new indices at
 luminosity-vision-shipment and luminosity-vision-order */
export const NUOLV_ENABLED_TENANTS = [26, 36, 30, 47, 49];

export const DETAILS_ORDER_NUMBER_EXCLUDED_TENANTS = [47];

export const USE_CON_ORDER_NUMBER = [47];

export const HIDE_ORDER_NUMBER = [47];

export const DETAILS_CARRIER_ETA_HIDDEN_TENANTS = [47];

export const DETAILS_REQUESTED_DATE_HIDDEN_TENANTS = [47];

export const USE_CLOSE_DATE_TIME_TENANTS = [47];

export const CLIENT_ID_EXIST_TENANTS = [30];

export const DISPLAY_FORWARDER_TENANTS = [404];

export const DISPLAY_ADITIONAL_IDENTIFIERS_TENANTS = [404];

export const DEFAULT_SHIPMENT_PAYLOAD_LIMIT = 20;

// tenant ids for search cardd pieces/weights information
export const PIECES_AND_WEIGHT_TENANTS = [404];

export const EXTERNAL_TRACKER_PATH = "/orders/summary/";

export const EXTERNAL_SHIPMENT_WAREHOUSE_PATH = "/shipment/warehouse/summary/"

export const SHIPMENTS_PATH = "/shipments";

export const ORDERS_PATH = "/orders";

export const ORDER_TRACKER_PATH = "/order-tracker";

export const TRACKING_DETAIL_PAGE_PATH = "/tracking";

export const TRACKING_DETAIL_PATH = "/tracking/:trackingNumber";

export const REFERENCE_LEVELS = {
  Load: 'Load',
  Item: 'Item',
  OriginPort: 'originPort',
  IntermediaryPort: 'intermediaryPort',
  DestinationPort: 'destinationPort',
  Job: 'Job'
};

export const ExternalTrackingDetailPage = "ExternalTrackingDetailPage";

export const LOCAL_STORAGE_KEYS = {
  covidDismissed: 'covidDismissed'
};

export const SESSION_STORAGE_KEYS = {
  trkSent: 'trkSent'
}

/**
 * @var JOB_MODES describes different divisions of customer data.  
 * Largely interchangable with the term detailLevel, except that detailLevel
 * controls whether we search the new luminosity_vision_shipment index for 
 * NUOLV tenants in the shipments-api when 'shipments' is supplied.
 */
export const JOB_MODES = {
  orders: 'orders',
  shipments: 'shipments'
}
//['orders', 'shipments', ...]
export const JOB_MODE_LIST = Object.keys(JOB_MODES).reduce((a, v) => { a.push(JOB_MODES[v]); return a }, []);
//CAP['orders'] => "Orders"
export const CAPITALIZED_JOB_MODES = JOB_MODE_LIST.reduce((a, v) => { a[v] = capitalize(v); return a }, {});
//SIN['shipments'] => "shipment"
export const SINGULAR_JOB_MODES = JOB_MODE_LIST.reduce((a, v) => { a[v] = v.substring(0, v.length - 1); return a }, {});
//CSIN['shipments'] => "Shipment"
export const CAPITALIZED_SINGULAR_JOB_MODES = JOB_MODE_LIST.reduce((a, v) => { a[v] = capitalize(v.substring(0, v.length - 1)); return a }, {});

export const SHOW_ALL_REFERENCES = [404];

export const DISPLAY_REFS_BY_TENANT = [404];

export const FLEX_HIDDEN_REFERENCES = ["CNS1","CNS6","CNS4","CNS7","SHPMD","HBL","BOL","SREF","MBOL","MAWB","TEB","CBP","DEPT"];

export const DEFAULT_EXPORT_SELECTIONS = [
  {
    "label": "Shipment Number",
    "value": "LoadNumber",
    "level": "load"
  },
  {
    "label": "Status",
    "value": "Status",
    "level": "load"
  },
  {
    "label": "Recent Update",
    "value": "LastIndexDate",
    "level": "load"
  },
  {
    "label": "Order Number",
    "value": "CustomerOrderNumber",
    "level": "load"
  },
  {
    "label": "Origin Scheduled",
    "value": "OriginSchedDateClose",
    "level": "load"
  },
  {
    "label": "Origin Requested",
    "value": "OriginRequested",
    "level": "load"
  },
  {
    "label": "Origin Actual",
    "value": "OriginCompleted",
    "level": "load"
  },
  {
    "label": "Order Origin Name",
    "value": "OrderOriginName",
    "level": "load"
  },
  {
    "label": "Order Origin City",
    "value": "OrderOriginCity",
    "level": "load"
  },
  {
    "label": "Order Origin State",
    "value": "OrderOriginState",
    "level": "load"
  },
  {
    "label": "Order Origin Country",
    "value": "OrderOriginCountry",
    "level": "load"
  },
  {
    "label": "Origin Name",
    "value": "OriginName",
    "level": "load"
  },
  {
    "label": "Origin City",
    "value": "OriginCity",
    "level": "load"
  },
  {
    "label": "Origin State",
    "value": "OriginState",
    "level": "load"
  },
  {
    "label": "Origin Country",
    "value": "OriginCountry",
    "level": "load"
  },
  {
    "label": "Destination Scheduled",
    "value": "DestinationSchedDateClose",
    "level": "load"
  },
  {
    "label": "Destination Requested",
    "value": "DestinationRequested",
    "level": "load"
  },
  {
    "label": "Carrier ETA",
    "value": "DestinationETA",
    "level": "load"
  },
  {
    "label": "Calculated ETA",
    "value": "CalculatedETA",
    "level": "load"
  },
  {
    "label": "Delivery DateTime",
    "value": "DeliveryDate",
    "level": "load"
  },
  {
    "label": "Destination Name",
    "value": "OrderDestinationName",
    "level": "load"
  },
  {
    "label": "Destination City",
    "value": "OrderDestinationCity",
    "level": "load"
  },
  {
    "label": "Destination State",
    "value": "OrderDestinationState",
    "level": "load"
  },
  {
    "label": "Destination Country",
    "value": "OrderDestinationCountry",
    "level": "load"
  },
  {
    "label": "Shipment Destination Name",
    "value": "DestinationName",
    "level": "load"
  },
  {
    "label": "Shipment Destination City",
    "value": "DestinationCity",
    "level": "load"
  },
  {
    "label": "Shipment Destination State",
    "value": "DestinationState",
    "level": "load"
  },
  {
    "label": "Shipment Destination Country",
    "value": "DestinationCountry",
    "level": "load"
  },
  {
    "label": "Movement",
    "value": "CurrentLocationStatus",
    "level": "load"
  },
  {
    "label": "Exception",
    "value": "ExceptionDescription",
    "level": "load"
  },
  {
    "label": "Exception Code",
    "value": "ExceptionControllability",
    "level": "load"
  },
  {
    "label": "Region Flow",
    "value": "Flow",
    "level": "load"
  },
  {
    "label": "Mode",
    "value": "Mode",
    "level": "load"
  },
  {
    "label": "Carrier",
    "value": "CarrierName",
    "level": "load"
  },
  {
    "label": "Channel",
    "value": "SalesChannel",
    "level": "load"
  },
  {
    "label": "Delivery Order Number",
    "value": "DeliveryOrderNumber",
    "level": "item"
  },
  {
    "label": "Customer PO Number",
    "value": "PurchaseOrderNumer",
    "level": "item"
  },
  {
    "label": "Line Of Business",
    "value": "LineOfBusiness",
    "level": "item"
  },
  {
    "label": "SKU/Part",
    "value": "SKU",
    "level": "item"
  },
  {
    "label": "Product Name",
    "value": "ProductName",
    "level": "item"
  },
  {
    "label": "Units",
    "value": "Units",
    "level": "item"
  }
]

export const CLOUDY_VISION_TENANTS = [1, 35, 404, 52];