import { all, put, call, takeEvery } from 'redux-saga/effects';
import api from '../../../apis';
import * as types from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../../global-success-error-alerts/actions';

export function* saveBookmarkStart(payload) {  
  let preferredIdentifier = payload.payload.metadata.shipmentDisplayIdentifier;
  preferredIdentifier = preferredIdentifier || (payload.payload.metadata.isNonNav ? payload.payload.metadata.shipmentNumber : payload.payload.metadata.loadNumber)
  try {
    yield call(api.users.saveBookmark, payload.payload);
    yield put(actions.fetchBookmark(payload.payload.metadata.shipmentId));
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: `Shipment ${preferredIdentifier} was successfully saved to your workbench.`
    }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Shipment ${preferredIdentifier} was not successfully saved to your workbench. Please try again.`,
      error: e
    }));
  }
}

export function* watchSaveBookmarkStart() {
  yield takeEvery(types.SAVE_BOOKMARK_STARTED, saveBookmarkStart);
}

export function* deleteBookmarkStart(payload) {
  yield call(api.users.deleteBookmark, payload.payload.id);
  yield put(actions.deleteBookmarkComplete());
  yield put(actions.fetchBookmarks());
}

export function* watchDeleteBookmarkStart() {
  yield takeEvery(types.DELETE_BOOKMARK_STARTED, deleteBookmarkStart);
}

export function* fetchOneBookmarkStart(payload) {
  let bookmarks = yield call(api.users.fetchBookmark, payload.payload.id);

  yield put(actions.fetchOneBookmarkComplete(bookmarks));
}

export function* watchFetchOneBookmarkStart() {
  yield takeEvery(types.FETCH_ONE_BOOKMARK_STARTED, fetchOneBookmarkStart);
}

export function* fetchBookmarksStart({payload}) {
  try {
    let bookmarks = yield call(api.users.fetchBookmarks);

    if (payload.shouldDeleteDeliveredShipments) {
     //delete delivered saved shipments
      const isBookmarkedShipmentDelivered = x => x.shipment.load_status === 90;
      const bookmarksToDeleteIds = bookmarks.filter(isBookmarkedShipmentDelivered).map(x => x._id);
      bookmarks = bookmarks.filter(x => !isBookmarkedShipmentDelivered(x));

     if (bookmarksToDeleteIds.length > 0) {
      yield all(bookmarksToDeleteIds.map(x => call(api.users.deleteBookmark, x)));
     }
    }

    yield put(actions.fetchBookmarksComplete(bookmarks));
  } catch (e) {
    yield all([
      put(actions.fetchBookmarksFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Bookmark Retrieval Error',
        message: `Error retrieving saved shipments.  Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchBookmarksStart() {
  yield takeEvery(types.FETCH_BOOKMARKS_STARTED, fetchBookmarksStart);
}
