import * as types from './actionTypes';

export default {
  fetchInventory(payload) {
    return {
      type: types.FETCH_INVENTORY_STARTED,
      payload: payload
    };
  },
  fetchInventoryFailed(payload) {
    return {
      type: types.FETCH_INVENTORY_FAILED,
      payload: payload
    };
  },
  fetchInventoryComplete(payload) {
    return {
      type: types.FETCH_INVENTORY_COMPLETED,
      payload: payload
    };
  },
  fetchInventoryDetails(payload) {
    return {
      type: types.FETCH_INVENTORY_DETAILS_STARTED,
      payload: payload
    };
  },
  fetchInventoryDetailsFailed(payload) {
    return {
      type: types.FETCH_INVENTORY_DETAILS_FAILED,
      payload: payload
    };
  },
  fetchInventoryDetailsComplete(payload) {
    return {
      type: types.FETCH_INVENTORY_DETAILS_COMPLETED,
      payload: payload
    };
  }

};
