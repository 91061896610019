import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';
import Icon from '../icon';
import { tooltip } from '../../utils/helperMethods/commonComponents';

const getOptions = (showSelectAll, selectAllLabel, deselectAllLabel, options, selectedValues) => {
  if (options.length > 0 && showSelectAll) {
    if (options.length !== selectedValues.length) {
      if (selectedValues.length !== 0) {
        return [{ label: selectAllLabel, value: options, isSelectAll: true }, { label: deselectAllLabel, value: [], isDeselectAll: true }, ...options];
      } else {
        return [{ label: selectAllLabel, value: options, isSelectAll: true }, ...options];
      }
    } else {
      return [{ label: deselectAllLabel, value: [], isDeselectAll: true }];
    }
  } else {
    return options;
  }
}

const handleOnChange = (currentValues, stateName, showSelectAll, handleDropdownChange) => {
  if (!showSelectAll || !currentValues) {
    handleDropdownChange(currentValues, `${stateName}`);
  } else {
    const selectAllValues = currentValues.filter(x => x.isSelectAll);
    const deselectAllValues = currentValues.filter(x => x.isDeselectAll);

    if (selectAllValues.length !== 0) {
      handleDropdownChange(selectAllValues[0].value, stateName);
    } else if (deselectAllValues.length !== 0) {
      handleDropdownChange(deselectAllValues[0].value, stateName);
    } else {
      handleDropdownChange(currentValues, `${stateName}`);
    }
  }
}

export default function SelectDropdown({
  label,
  overlayContent,
  overlayIconType,
  closeMenuOnSelect,
  isClearable,
  isDisabled,
  isLoading,
  isMultiSelect,
  handleDropdownChange,
  stateName,
  onInputChange,
  options,
  placeholder,
  selectedValues,
  inputId,
  id,
  showSelectAll,
  selectAllLabel,
  deselectAllLabel,
  noOptionsLabel
}) {
  return (
    <div className="form-group">
      <label htmlFor="overlayLabel" className="read-only-value">
        {label}
        {overlayContent && (
          <OverlayTrigger placement="top" overlay={tooltip(`${overlayContent}`, `${overlayIconType}`)}>
            <Icon type={`${overlayIconType}`} className="icon small faded" />
          </OverlayTrigger>
        )}
      </label>
      <Select
        id={id}
        inputId={inputId}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMultiSelect}
        onChange={(e) => handleOnChange(e, `${stateName}`, showSelectAll, handleDropdownChange)}
        onInputChange={(e) => onInputChange(e)}
        options={(options && selectedValues) ? getOptions(showSelectAll, selectAllLabel, deselectAllLabel, options, selectedValues) : options}
        placeholder={placeholder}
        value={selectedValues}
        noOptionsMessage={() => noOptionsLabel}
      />
    </div>
  );
}

SelectDropdown.propTypes = {
  closeMenuOnSelect: PropTypes.bool,
  handleDropdownChange: PropTypes.func,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  overlayContent: PropTypes.node,
  overlayIconType: PropTypes.node,
  placeholder: PropTypes.string,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  stateName: PropTypes.string,
  inputId: PropTypes.string,
  id: PropTypes.string,
  showSelectAll: PropTypes.bool
};

SelectDropdown.defaultProps = {
  closeMenuOnSelect: false,
  handleDropdownChange: () => { },
  isClearable: false,
  isDisabled: false,
  isLoading: false,
  isMultiSelect: false,
  label: '',
  onInputChange: () => { },
  options: [],
  overlayContent: null,
  overlayIconType: null,
  placeholder: '',
  selectedValues: [],
  stateName: '',
  showSelectAll: false,
  selectAllLabel: "Select All",
  deselectAllLabel: "Deselect All"
};
