import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from './actions';
import PieGraph from '../../components/pie-graph';
import { statusColorMapping } from '../../utils/helperMethods/commonMethods';

export class InTransitHealthContainer extends React.Component {

  componentDidMount() {
    this.props.fetchShipmentsHealth('All', 'InTransit');
  }

  mapInTransitData = (data) => {
    if(data && data.early && data.onTime && data.atRisk && data.late) {
      return [
        { name: 'Early', value: this.props.inTransitHealthData.early.doc_count },
        { name: 'On Time', value: this.props.inTransitHealthData.onTime.doc_count },
        { name: 'At Risk', value: this.props.inTransitHealthData.atRisk.doc_count },
        { name: 'Late', value: this.props.inTransitHealthData.late.doc_count }
      ];
    }
  }

  render() {
    return(
      <div className="row">
        <div className="col-lg-6">
          <div className='healthChart'>
              <PieGraph
                data={this.mapInTransitData(this.props.inTransitHealthData)}
                type="Shipments"
                colorMapping={statusColorMapping}
                loading={this.props.allLoading}
                height={380}
                width={"100%"}
                header={"In Transit Shipments By Status"}
              />
          </div>
        </div>
      </div>
    );
  }
}

InTransitHealthContainer.propTypes = {
  allLoading: PropTypes.bool,
  inTransitHealthData: PropTypes.object
};

InTransitHealthContainer.defaultProps = {
  allLoading: true,
  inTransitHealthData: []
};

export const mapStateToProps = (state) => {
  return {
    allLoading: state.supplyChainHealth.get('allLoading'),
    inTransitHealthData: state.supplyChainHealth.get('inTransit')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchShipmentsHealth: (mode, range) => {
      return dispatch(actions.fetchShipmentsHealth(mode, range));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InTransitHealthContainer);
