import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
export default class MultiSearchDropdown extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="col-xs-12">
        <div className="advanced-search row">
          <div className="col-sm-6">
            <h5>Search Multiple Shipments</h5>
            <span className="label">Value Type</span>
            <select id={'advanced-search-select'} value={this.props.multiSearchType} onChange={e => this.props.onSelectChange(e)} className="form-control space-bottom">
              {this.props.searchFields && this.props.searchFields.map(x => {
                return (
                  <option id={x.elasticValue} key={x.elasticValue} value={x.elasticValue} >{x.displayLabel}</option>
                )
              })}
            </select>
            <div className={this.props.errorMessage ? 'form-group has-error' : 'form-group'}>
              <textarea id={'advanced-search-text-input'} value={this.props.multiSearchValue} onChange={e => this.props.onTypeChange(e)} className="form-control space-bottom" rows="3" placeholder="Enter one value per line..."></textarea>
              {this.props.errorMessage && <div className="flex">
                <FontAwesomeIcon icon={faExclamationCircle} size='2x' />
                <div><span className="help-text">{this.props.errorMessage}</span></div>
              </div>}
            </div>
            <div className="btn-group">
              <button onClick={() => this.props.showMultiSearchBox()} className="btn btn-light" id="multisearchcancel" style={{ 'marginRight': '5px' }}>Cancel</button>
              <button onClick={e => this.props.multiSearch(e)} className="btn btn-primary" id="multiSearch">Search</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
