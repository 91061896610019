import React from 'react';

export default function ExportSelectionModal(props) {
  return (
    <div className="flex-column">
      <div className="row">
        <div className="col-xs-12">
          <div className="export-selection-modal">
           <p>Please select the fields that you wish to export. Once the fields are selected, you will receive an email link to the data in a CSV file. <br />
          <span>Note: Large amounts of data may take a few minutes to compile.</span></p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <ul className="export-selection-box">
            <label>
              <input type="checkbox" checked={props.selectAll} id='select-all-export' onChange={() => { props.handleExportSelectAllChange() }} />
              <span title="select-all"><p className="overflow">Select All</p></span>
            </label>
            {props.selectedExportFields.map((selection, i) => {
              return (
                <label key={i}>
                  <input type="checkbox" checked={selection.isChecked} id={`${selection.value}`} onChange={() => { props.handleExportSelectionChange(i) }} />
                  <span title={selection.label}><p className="overflow">{selection.label}</p></span>
                </label>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

