import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
    orderNumber: 0,
    status: {},
    eta: {},
    stops: [],
    items: [],
    billOfLading: {},
    loaded: false
});

function shipmentsOrderSummaryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_ORDER_SUMMARY_STARTED:
      return state
        .set('loaded', false);
    case types.FETCH_ORDER_SUMMARY_FAILED:
      return state 
        .set('loaded', true);
    // We could set a "loading" variable to true here if we want to display a loading page.
    case types.FETCH_ORDER_SUMMARY_COMPLETED:
      return state
        .set('orderNumber', action.payload.orderNumber)
        .set('status', action.payload.status)
        .set('eta', action.payload.eta)
        .set('stops', action.payload.stops)
        .set('items', action.payload.items)
        .set('billOfLading', action.payload.billOfLading)
        .set('loaded', true);
    default:
      return state;
  }
}

export default shipmentsOrderSummaryReducer;
