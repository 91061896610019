import React from 'react';
import { mapDateFilterField } from '../../../utils/helperMethods/commonMethods';
import { formatDateWithForwardSlash } from '../../../utils/helperMethods/dateTimeMethods';

class SaveFiltersModal extends React.Component {

  filterSets = (savedConfiguration) => {
    const savedFilters = [];
    Object.keys(savedConfiguration.activeFilters).forEach(key => {
      savedConfiguration.activeFilters[key].length > 0 &&
        savedFilters.push(savedConfiguration.activeFilters[key].join(' , '));
    });

    if (savedConfiguration.dateFilter) {
      const dateFilterLabel = mapDateFilterField[savedConfiguration.dateFilter.field[0]];
      var range = `${dateFilterLabel}: ${formatDateWithForwardSlash(savedConfiguration.dateFilter.startDate)} to ${formatDateWithForwardSlash(savedConfiguration.dateFilter.endDate)}`;
      savedFilters.push(range);
    };

    if (savedConfiguration.activeFacilityFilters && savedConfiguration.activeFacilityFilters.length > 0) {
      savedConfiguration.activeFacilityFilters.forEach(filter => {
        var facilityFilter = `${filter.facilityName}: ${filter.parsedFacilityStatus}`
        savedFilters.push(facilityFilter);
      });
    };

    return (
      <div>
        {savedFilters.length > 0 ? <p> Selected Filters: [{savedFilters.join(' , ')}] </p> : <p style={{ 'color': 'red', 'fontStyle': 'italic', 'fontSize': '12px' }}>Must select at least one filter</p>}
      </div>
    );
  };

  render() {
    return (
      <div>
        <p>This will save the selected filters to your profile. Add a name to make it easy to identify.</p>
        <form>
          <div className="form-group">
            <label htmlFor="flagName">Name</label>
            {!this.props.title && <p style={{ 'color': 'red', 'fontStyle': 'italic', 'fontSize': '12px' }}>Must add title</p>}
            <input id={'filter-name-input'} type="text" className="form-control" placeholder="Name of filters set" onChange={this.props.savedFilterTitle} />
          </div>
        </form>
        <div>{this.filterSets(this.props.savedConfiguration)}</div>
      </div>
    );
  };
};

export default SaveFiltersModal;
