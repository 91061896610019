import React from 'react';


export default class Toggle extends React.Component {
  render() {
    return (
      <span data-test="Toggle" className={this.props.className || "toggle"}>
        <span className="toggle-label">{this.props.toggleLabel || ''}</span>
        <input type="checkbox" id={this.props.id} name={this.props.name || null} checked={this.props.checked} onChange={this.props.onChange} className="switch-input"/>
        <label htmlFor={this.props.id} className="switch-label">{this.props.label || ''}</label>
      </span>
    );
  }
};
