import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';
import trackingSummaryActions from '../../containers/tracking-summary/actions';
import orderTrackingSummaryActions from '../../containers/order-tracking-summary/actions';
import globalSuccessErrorAlertActions from '../../containers/global-success-error-alerts/actions';
import iotActions from '../../containers/iot/actions';
import Orders from '../../components/shipment-tracker/Orders';
import TrackingHistory from './TrackingHistory';
import IotDetails from './IotDetails';
import IotAnomalyTracking from './IotAnomalyTracking';
import Commodities from './Commodities';
import LoadDetails from './load-details/Load-details';
import ConversationContainer from '../../containers/conversation';
import { ShipmentTrackerMap, ShipmentTrackerMapControls } from '../../containers/map';
import './extras/styles.scss';
import LoadingIcon from '../../components/loading-icon';
import ConfigurationContext from '../../components/config/ConfigurationContext'
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';
import { ExternalTrackingDetailPage, EAPI_V2_TENANTS, JOB_MODES, SINGULAR_JOB_MODES, CAPITALIZED_SINGULAR_JOB_MODES } from '../../utils/constants';
import { getTrackingDetailLevel } from '../../utils/helperMethods/commonMethods';
import externalTrackingDetailActions from '../../containers/tracking/actions';
import * as commonMethods from '../../utils/helperMethods/commonMethods';

const singular_job = SINGULAR_JOB_MODES;
const singular_cap = CAPITALIZED_SINGULAR_JOB_MODES;
export class ShipmentTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: {
        loadDetails: 'active',
        trackingHistory: '',
        iotDetails: '',
        commodities: '',
        comments: ''
      },
      lastRequestParams: ''
    };
    this.isActive = this.isActive.bind(this);
  }

  isActive(selectedTab) {
    var tabsState = {};

    _.forEach(this.state.activeTab, (value, key) => {
      if (key === selectedTab) {
        tabsState[key] = 'active';
      } else {
        tabsState[key] = '';
      }
    });

    this.setState({
      ...this.state,
      activeTab: {
        loadDetails: tabsState.loadDetails,
        trackingHistory: tabsState.trackingHistory,
        iotDetails: tabsState.iotDetails,
        commodities: tabsState.commodities,
        comments: tabsState.comments
      }
    });
  }

  isShipmentStale(parsedQueryString, userProfile, shipment, orderNumber, jobMode) {
    const trackingNumber = this.getTrackingNumber(parsedQueryString, userProfile);

    return shipment?.trackingNumber !== trackingNumber ||
      ((jobMode === JOB_MODES.orders && parsedQueryString.orderNumber) &&
        ((parsedQueryString.orderNumber !== orderNumber) || (shipment.order_numbers && shipment.order_numbers.length == 1 && shipment.order_numbers[0] !== parsedQueryString.orderNumber))
      )
  }


  getTrackingNumber(parsedQueryString, userProfile) {
    let trackingNumber = parsedQueryString.trackingNumber;
    if (EAPI_V2_TENANTS.includes(userProfile.tenantId) && parsedQueryString.trackingType.toLowerCase() === 'nonnav' && !!parsedQueryString.shipmentNumber) {
      trackingNumber = parsedQueryString.shipmentNumber;
    }

    return trackingNumber;
  }

  getTrackingSummaryParamString(location, userProfile) {
    const parsedQueryString = queryString.parse(location.search)
    return `${this.getTrackingNumber(parsedQueryString, userProfile)}${parsedQueryString.trackingType}${parsedQueryString.loadNumber}${parsedQueryString.orderNumber}`
  }

  trackingSummaryParamsHaveChanged(props, prevProps) {
    return this.getTrackingSummaryParamString(props.location, props.userProfile) != this.getTrackingSummaryParamString(prevProps.location, prevProps.userProfile);
  }

  componentDidUpdate(prevProps) {
    const { fetchIotMapLogs, fetchIotSensorLocationData, updateUseIotMap, location, shipment, orderNumber, userProfile, jobMode } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const shipmentId = decodeURIComponent(this.props.location.pathname.split('/')[2]);

    if (!this.props.loading && this.isShipmentStale(parsedQueryString, userProfile, shipment, orderNumber, jobMode) && this.trackingSummaryParamsHaveChanged(this.props, prevProps)) {
      const trackingNumber = this.getTrackingNumber(parsedQueryString, userProfile);
      this.props.getTrackingSummary(
        trackingNumber,
        parsedQueryString.trackingType,
        parsedQueryString.loadNumber,
        parsedQueryString.orderNumber,
        shipmentId
      );
      this.setState({...this.state, lastRequestParams: this.getTrackingSummaryParamString(location, userProfile)});
    }

    if (this.props.pageName === ExternalTrackingDetailPage || !shipment.iot_tracked) {
      updateUseIotMap(false);
    }
    else if (prevProps.shipment && prevProps.shipment.loadNumber != shipment.loadNumber) {
      if (shipment.iot_tracked) {
        const loadNumber = queryString.parse(window.location.hash.split('?').pop()).loadNumber;
        fetchIotSensorLocationData({ trackingNumber: loadNumber });
        fetchIotMapLogs({ trackingNumber: loadNumber });
        updateUseIotMap(true);
      }
    }
  }

  componentWillUnmount() {
    const { updateUseIotMap } = this.props;
    updateUseIotMap(false);
    this.props.resetProps();
  }

  componentDidMount() {
    const { adobeTrack, location, shipment, userProfile, orderNumber, jobMode } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const shipmentId = decodeURIComponent(this.props.location.pathname.split('/')[2]);

    if (this.props.pageName == ExternalTrackingDetailPage) {
      trackPageInfoEvent(adobeTrack, "External Tracking Detail", ["External Tracking Detail"]);
    } else {
      trackPageInfoEvent(adobeTrack, `${singular_cap[jobMode]} Tracker`, [`${singular_cap[jobMode]} Tracker`]);
    }

    if (this.isShipmentStale(parsedQueryString, userProfile, shipment, orderNumber, jobMode) || this.props.pageName == ExternalTrackingDetailPage) {
      const trackingNumber = this.getTrackingNumber(parsedQueryString, userProfile);
      this.props.getTrackingSummary(
        trackingNumber,
        parsedQueryString.trackingType,
        parsedQueryString.loadNumber,
        parsedQueryString.orderNumber,
        shipmentId
      );
      this.setState({...this.state, lastRequestParams: this.getTrackingSummaryParamString(location, userProfile)});
    }
}

  renderLoading() {
    return (
      <div style={{ zIndex: 1001 }} className="mapCentered">
        <LoadingIcon></LoadingIcon>
      </div>
    );
  }

  render() {
    const { location, shipment, userProfile, orderNumber, jobMode } = this.props;
    const parsedQueryString = queryString.parse(location.search);

    if (this.isShipmentStale(parsedQueryString, userProfile, shipment, orderNumber, jobMode)) {
      return this.renderLoading();
    }

    return (
      <div className="shipment-tracker">
        <div className="details-container">
          <Orders
            trackingType={parsedQueryString.trackingType}
            location={location}
            pageName={this.props.pageName}
            tenantId={this.props.userProfile.tenantId}
          />
          {jobMode === JOB_MODES.shipments && this.props.pageName != ExternalTrackingDetailPage && shipment.iot_tracked && userProfile.permissions.includes('IotDevices') &&
            <IotAnomalyTracking location={location} />
          }
          <div className="tabs tabs-full-width tabs-uppercase">
            <ul id="myTab" className="nav nav-tabs">
              <li className={this.state.activeTab.loadDetails}>
                <a
                  data-toggle="tab"
                  onClick={() => {
                    return this.isActive('loadDetails');
                  }}
                  id={'shipment-details-button'}
                >
                  {singular_job[jobMode]} Details
                </a>
              </li>
              <li className={this.state.activeTab.trackingHistory}>
                <a
                  data-toggle="tab"
                  onClick={() => {
                    return this.isActive('trackingHistory');
                  }}
                  id={'shipment-tracking-button'}
                >
                  {singular_job[jobMode]} Tracking
                </a>
              </li>
              {
                jobMode === JOB_MODES.shipments && this.props.pageName != ExternalTrackingDetailPage && userProfile && userProfile.permissions && userProfile.permissions.includes('IotDevices') && shipment.iot_tracked &&
                <li className={this.state.activeTab.iotDetails}>
                  <a
                    data-toggle="tab"
                    data-test="iotDetails"
                    onClick={() => {
                      return this.isActive('iotDetails');
                    }}
                  >
                    IoT Details
                </a>
                </li>
              }
              <li className={this.state.activeTab.commodities}>
                <a
                  data-toggle="tab"
                  onClick={() => {
                    return this.isActive('commodities');
                  }}
                  id={'item-details-button'}
                >
                  Item Details
                </a>
              </li>
              {jobMode === JOB_MODES.shipments && this.props.pageName != ExternalTrackingDetailPage &&
                <li className={this.state.activeTab.comments}>
                  <a
                    data-toggle="tab"
                    data-test="comments"
                    onClick={() => {
                      return this.isActive('comments');
                    }}
                    id={'shipment-comments-button'}
                  >
                    Comments
                  </a>
                </li>
              }
            </ul>
          </div>
          <div className="container-fluid load-content no-padding">
            <div id="myTabContent" className="tab-content">
              {this.props.loading ?
                this.renderLoading()
                : (
                  <React.Fragment>
                    <TrackingHistory
                      isTrackingHistoryActive={
                        this.state.activeTab.trackingHistory
                      }
                      location={location}
                      pageName={this.props.pageName}
                    />
                    <IotDetails isIotDetailsActive={this.state.activeTab.iotDetails} shipmentId={queryString.parse(location.search).loadNumber} />
                    <Commodities
                      isCommoditiesActive={this.state.activeTab.commodities}
                      jobMode={jobMode}
                      location={location}
                      tenantId={this.props.userProfile.tenantId}
                    />
                    <LoadDetails
                      isLoadDetailsActive={this.state.activeTab.loadDetails}
                      trackingType={parsedQueryString.trackingType}
                      jobMode={jobMode}
                      location={location}
                      history={this.props.history}
                      userProfile={this.props.userProfile}
                    />
                    {this.props.pageName !== ExternalTrackingDetailPage &&
                      <ConfigurationContext.Consumer>
                        {config => (
                          <ConversationContainer
                            appConfig={config}
                            isLoadCommentsActive={this.state.activeTab.comments}
                            location={location}
                          />
                        )}
                      </ConfigurationContext.Consumer>}
                  </React.Fragment>
                )}
            </div>
          </div>
        </div>
        <div className="map-split">
          <div id="myMap" className="tracker-map">
            <ShipmentTrackerMapControls />
            <ConfigurationContext.Consumer>
              {config => (
                <ShipmentTrackerMap
                  bingmapKey={config.bingMapKey}
                  center={shipment.current_location}
                  zoom={3}
                  location={location}
                  pageName={this.props.pageName}
                />
              )}
            </ConfigurationContext.Consumer>
          </div>
        </div>
      </div>
    );
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  const detailLevel = getTrackingDetailLevel(ownProps.location.pathname);
  return {
    resetProps: () => {
      detailLevel === 'orderTrackingSummary' ? dispatch(orderTrackingSummaryActions.resetProps()) : dispatch(trackingSummaryActions.resetProps());
    },
    fetchIotSensorLocationData: (payload) => {
      dispatch(iotActions.fetchIotSensorLocations(payload));
    },
    fetchIotMapLogs: (payload) => {
      dispatch(iotActions.fetchIotMapLogs(payload));
    },
    updateUseIotMap: (payload) => {
      dispatch(iotActions.updateUseIotMap(payload))
    },
    triggerGlobalErrorAlert: payload => {
      dispatch(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(payload));
    },
    getTrackingSummary: (trackingNumber, trackingType, loadNumber, orderNumber, shipmentId) => {
      if (commonMethods.getTrackingDetailLevel(ownProps.location.pathname) === 'externalTracker') {
        return dispatch(externalTrackingDetailActions.fetchTrackingShipment(ownProps.location.pathname.split('/')[2]));
      } else if (ownProps.jobMode === JOB_MODES.orders) {
        return dispatch(
          orderTrackingSummaryActions.fetchTrackingSummary(
            trackingNumber,
            orderNumber,
            trackingType,
            loadNumber,
            ownProps.showMultiShipmentOrder
          )
        );
      } else {
        return dispatch(
          trackingSummaryActions.fetchTrackingSummary(
            trackingNumber,
            trackingType,
            loadNumber,
            shipmentId
          )
        );
      }
    }
  };
};

export const mapStateToProps = (state, ownProps) => {
  const detailLevel = getTrackingDetailLevel(ownProps.location.pathname);
  const jobMode = detailLevel == 'orderTrackingSummary' ? JOB_MODES.orders : JOB_MODES.shipments
  return {
    shipment: state[detailLevel].get('fetchedShipment'),
    orderNumber: state[detailLevel].get('orderNumber'),
    loading: state[detailLevel].get('loading'),
    userProfile: state.users.get('userProfile'),
    jobMode
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentTracker);
