import React, { useEffect } from 'react';
import { passesRbac } from '../../utils/rbacUtils';
import { Layout } from '../../components/layout';
import LoadingIcon from '../../components/loading-icon';
import { trackUserLoginSuccess } from '../../utils/helperMethods/adobeAnalyticsHelper';
import { useAnalyticsTrack } from '@chr/react-analytics';

const notAuthedDiv = <div data-test="notAuthed"></div>;
const loadingProfileDiv = (
    <div data-test="loadingProfile" className="centered">
        <LoadingIcon/>
    </div>
);

async function ensureAuthed(props, setAuthed, currentlyAuthed) {
    try {
        await props.authClient.ensureAuthed();
        let identity = await props.authClient.getIdentity();
        if (props.userProfileNeedsToBeInitialized && identity.sub) {
            props.getUserProfile(identity.sub);
        }
        setAuthed(true, currentlyAuthed);
    } catch (ex) {
        setAuthed(false, currentlyAuthed);
    }
}

export default function AuthenticationWrapper(props) {
    const track = useAnalyticsTrack();

    useEffect(() => {
        ensureAuthed(props, props.setAuthed, props.authed)
    }, []);

    useEffect(() => {
        if (props.authed && props.trackLogin && props.userProfile && props.userProfile.oktaId) {
            trackUserLoginSuccess(track, props.userProfile.oktaId, props.userProfile.tenantId, props.userProfile.email, props.userProfile.user_metadata.name);
            props.trackLoginComplete();
        }
    }, [props.trackLogin, props.userProfile])

    if (props.userProfile == null || props.userProfile.permissions == null || props.userRole == null) {
        return loadingProfileDiv;
    }

    if (props.authed) {
        let rbacResult = passesRbac(props.rbacOptions.roles, props.rbacOptions.permissions, props.userProfile.permissions, props.userRole);
        if (rbacResult.permissions && rbacResult.role) {
            return (props.children);
        } else if (!rbacResult.permissions && rbacResult.role) {
            return (
                <Layout {...props.childProps}>
                    <div data-test="invalidPermissions" style={{margin: '20px 0px 0px 20px'}}>
                        You do not have the permissions necessary to view this page.
                    </div>
                </Layout>
            );
        } else {
            props.childProps.history.push("/");
            return notAuthedDiv;
        }
    } else {
        return notAuthedDiv;
    }
}