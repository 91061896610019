import React from 'react'
import Footer from '../../components/footer';
import NotificationPreferencesContainer from '../../containers/notifications/NotificationPreferencesContainer';

export default function NotificationPreferences(props) {
  return (
    <div data-test='notificationPreferencesPage' style={{marginBottom: '5rem'}}>
      <NotificationPreferencesContainer {...props} />
    </div>
  );
}
