import * as actionTypes from './actionTypes';
import actions from './actions';
import { eventChannel } from 'redux-saga'
import { put, takeLatest, select, take, call, spawn } from 'redux-saga/effects';
import socket from '../../apis/socket';
import * as selectors from './selectors';
import notificationActions from '../notifications/actions';

export function* watchConfigureSocketStart() {
  yield takeLatest(actionTypes.CONFIGURE_SOCKET_STARTED, configureSocketStart);
}

export function* configureSocketStart({ payload }) {
  try {
    let socketClient = yield select(selectors.selectSocketClient);
    // reconnect socket with passed accessToken
    if (socketClient) {
      yield call(socketClient.updateToken, payload.accessToken);
    }
    // socketClient does not exist, so create one
    else {
      const socketFactory = yield call(socket, payload.config);
      socketClient = yield call(socketFactory, 'default', 'notifications', payload.accessToken);

      const eventList = [
        { eventName: 'connect', eventHandler: () => { console.info('connected'); } },
        { eventName: 'error', eventHandler: (e) => { console.error('socket error', e); } },
        { eventName: 'connect_error', eventHandler: (e) => { console.error('connect error', e); } }
  
      ];
      yield call(socketClient.registerHandler, eventList);

      yield spawn(watchNotificationsChannelStart, socketClient);
    }

    yield put(actions.configureSocketCompleted(socketClient));
  } catch (e) {
    yield put(actions.configureSocketFailed());
  }
}

export function* watchNotificationsChannelStart(socketClient) {
  const notificationsChannel = yield call(createNotificationsChannel, socketClient);
  while (true) { //NOSONAR
    const payload = yield take(notificationsChannel);
    yield put(notificationActions.handleIncomingNotifications(payload))
  }
}

export function createNotificationsChannel(socketClient) {
  return eventChannel(emit => {
    const alertEventHandler = (event) => {
      emit(event);
    };
    socketClient.registerHandler([{ eventName: 'alert', eventHandler: alertEventHandler }]);
    
    return () => socketClient.unregisterHandler([{ eventName: 'alert' }]);
  });
}