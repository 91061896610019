/* Map Container */
import * as types from './actionTypes';
export default {
  /* Map Controls */

  setMapType(mapType) {
    return {
      type: types.SET_MAP_TYPE,
      payload: {
        mapType: mapType
      }
    };
  },

  setMapContent(key, val) {
    return {
      type: types.SET_MAP_CONTENT,
      payload: {
        key: key,
        val: val
      }
    };
  },

  selectDisruptionsMapType(mapType) {
    return {
      type: types.SET_DISRUPTIONS_MAP_TYPE,
      payload: {
        mapType: mapType
      }
    };
  },

  selectDisruptionsMapContent(key, val) {
    return {
      type: types.SET_DISRUPTIONS_MAP_CONTENT,
      payload: {
        key: key,
        val: val
      }
    };
  },

  zoomToIncident(incident) {
    return {
      type: types.ZOOM_TO_INCIDENT,
      payload: {
        incident: incident
      }
    };
  },

  shouldDrawOnMap(draw) {
    return {
      type: types.SHOULD_DRAW_ON_MAP,
      payload: {
        draw: draw
      }
    };
  },

  setShipmentMapContent(key, val) {
    return {
      type: types.SET_SHIPMENT_MAP_CONTENT,
      payload: {
        key: key,
        val: val
      }
    };
  }, 

  setShipmentMapType(mapType){
    return {
      type: types.SET_SHIPMENT_MAP_TYPE,
      payload: {
        mapType: mapType
      }
    };
  },

  mapLoaded(isLoaded) {
    return {
      type: types.MAP_LOADED, 
      payload: isLoaded
    };
  }
}
