import decode from 'jwt-decode';
import { createSelector } from 'reselect';
import { trackUserLogout } from '../../utils/helperMethods/adobeAnalyticsHelper';

const selectAuth = state => state.auth;
const selectAuthClient = state => selectAuth(state).get('authClient');
const selectProductUrl = state => selectAuth(state).get('productUrl');
const selectAccessToken = state => selectAuth(state).get('accessToken');
const selectIdToken = state => selectAuth(state).get('idToken');
const selectAuthed = state => selectAuth(state).get('authed');
const selectUserProfile = state => state.users.get('userProfile');
const selectUserProfileNeedsToBeInitialized = state => state.users.get('userProfileNeedsToBeInitialized');
const getAdobeTrackromProps = (state, props) => props.adobeTrack;

const selectUser = () => createSelector(selectAuthClient, selectProductUrl, selectAccessToken, selectIdToken, getAdobeTrackromProps,
  (authClient, productUrl, accessToken, idToken, adobeTrack) => {
    try {
      const decodedValue = decode(idToken);
      return decodedValue;
    } catch (e) {
      if (accessToken) {
        if (window['localStorage'].getItem('userProfile')) {
          const userProfile = JSON.parse(window['localStorage'].getItem('userProfile'));
          if (adobeTrack && userProfile.oktaId) {
            trackUserLogout(adobeTrack, userProfile.oktaId, userProfile.user_metadata.name, userProfile.tenantId, userProfile.email);
          }
          window['localStorage'].removeItem('userProfile');
        }
      }

      if (productUrl) {
        window['localStorage'].removeItem(productUrl + '-access_token');
        window['localStorage'].removeItem(productUrl + '-id_token');
      }

      const target = window.location.origin;
      authClient.logout({ "post_logout_redirect_uri": target });
    }
  });

export {
  selectAuth,
  selectAuthed,
  selectAuthClient,
  selectProductUrl,
  selectAccessToken,
  selectIdToken,
  selectUser,
  selectUserProfile,
  selectUserProfileNeedsToBeInitialized
};
