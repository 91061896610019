import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';
import { getUserProfile } from '../containers/auth/authHelper';
import { addDetailLevelToReq } from './shipments';
import { JOB_MODES } from '../utils/constants';

export default {
  async exportData(payload) {
    const config = await getConfiguration();
    const endpoint = config.exportingApiEndpoint;
    const userProfile = await getUserProfile();

    const params = {
      userEmail: userProfile.upn || userProfile.email,
      userName: userProfile.name || '',
      tenantId: userProfile.tenantId,
      exportType: payload.exportType
    };

    if (payload.exportType === 'SHIPMENTS_EXPORT') {
      params.shipmentsExportPayload = {
        mapBox: payload.mapBox,
        searchText: payload.searchText,
        filters: payload.filters || {},
        filterConfiguration: payload.filterConfiguration || {},
        incidents: payload.incidents || {},
        dateFilter: payload.dateFilter,
        facilityFilters: payload.facilityFilters || [],
        selectedExportFields: payload.selectedExportFields || []
      };
      //only shipments exports are supported for now
      await addDetailLevelToReq('exportShipments', JOB_MODES.shipments, params.shipmentsExportPayload)
    } else if (payload.exportType === 'INVENTORY_EXPORT') {
      params.inventoryExportPayload = {
        filters: {
          line_of_business: payload.lineOfBusiness,
          location_name: payload.locationNames
        },
        searchText: payload.searchText
      };
    }
    try {
      const request = await clientFactory(endpoint);
      const response = await request.post('exportShipments', params);

      return response;
    } catch (err) {
      throw `Error exporting data: ${err}`;
    }
  },
  async downloadExport(exportType, fileName) {
    const config = await getConfiguration();
    const endpoint = config.exportingApiEndpoint;

    try {
      const request = await clientFactory(endpoint);
      const response = await request.get(`DownloadExport?exportType=${exportType}&fileName=${fileName}`);

      return response.data;
    } catch (err) {
      throw `Error downloading csv: ${err}`;
    }
  }
};
