import * as types from './actionTypes';

export default {
  fetchOrderSummary(encodedReq) {
    return {
      type: types.FETCH_ORDER_SUMMARY_STARTED,
      payload: encodedReq
    };
  },
  fetchOrderSummaryFailed(payload) {
    return {
      type: types.FETCH_ORDER_SUMMARY_FAILED,
      payload: payload
    };
  },
  fetchOrderSummaryComplete(payload) {
    return {
      type: types.FETCH_ORDER_SUMMARY_COMPLETED,
      payload: payload
    };
  }
};
