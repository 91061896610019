import * as types from './actionTypes';

export default {
  fetchTrackingSummary(trackingNumber, orderNumber, trackingType, loadNumber, showMultiShipmentOrder) {
    return {
      type: types.FETCH_ORDER_TRACKING_SUMMARY_STARTED,
      payload: {
        trackingNumber,
        trackingType,
        orderNumber,
        loadNumber,
        showMultiShipmentOrder
      }
    };
  },
  fetchTrackingSummaryFailed(payload) {
    return {
      type: types.FETCH_ORDER_TRACKING_SUMMARY_FAILED,
      payload: payload
    };
  },
  fetchTrackingSummaryComplete(payload) {
    return {
      type: types.FETCH_ORDER_TRACKING_SUMMARY_COMPLETED,
      payload: {
        loads: payload.loads
      }
    };
  },
  storeRouteData(input) {
    return {
      type: types.PROCESS_ROUTEDATA_COMPLETED,
      payload: {
        routingSegments: input.routingSegments,
        pickDropPins: input.pickDropPins,
        currentLocation: input.currentLocation,
        expectedPosition: input.expectedPosition
      }
    };
  },
  fetchShipmentComplete(shipment, orderNumber, loadNumbers) {
    return {
      type: types.FETCH_ORDER_COMPLETED,
      payload: {
        fetchedShipment: shipment,
        orderNumber: orderNumber,
        loadNumbers: loadNumbers
      }
    };
  },
  fetchTrackingLogs(loadNumber, orderNumber, trackingType, loadNumbers, shipmentId) {
    return {
      type: types.FETCH_ORDER_TRACKING_LOGS_STARTED,
      payload: {
        loadNumber,
        orderNumber,
        trackingType, 
        loadNumbers,
        shipmentId
      }
    };
  },
  fetchTrackingLogsComplete(trackingLogs) {
    return {
      type: types.FETCH_ORDER_TRACKING_LOGS_COMPLETED,
      payload: {
        trackingLogs: trackingLogs
      }
    };
  },
  processTrackingLogs(trackingLogs, sortingOptions) {
    return {
      type: types.FETCH_ORDER_TRACKING_LOGS_PROCESS,
      payload: {
        trackingLogs,
        sortingOptions
      }
    };
  },
  resetProps() {
    return {
      type: types.RESET_PROPS
    };
  }
}
