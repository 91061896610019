import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';
import { getUserProfile } from '../containers/auth/authHelper';

export default {
    async fetchPowerBiItems() {
        const config = await getConfiguration();
        const endpoint = config.reportGatewayApiEndpoint;
        try {
            const userProfile = await getUserProfile();
            const request = await clientFactory(endpoint);
            const result = await request.get(`All/${userProfile.tenantId}`);
            return result;
        } catch (err) {
            throw `PowerBI Items ${err}`;
        }
    },
    async fetchPowerBiDashboardEmbed(id) {
        const config = await getConfiguration();
        const endpoint = config.reportGatewayApiEndpoint;
        try {
            const request = await clientFactory(endpoint);
            const result = await request.get(`Embed/Dashboard/${id}`);
            return result;
        } catch (err) {
            throw `PowerBI Dashboard Embed ${err}`;
        }
    },
    async fetchPowerBiReportEmbed(id) {
        const config = await getConfiguration();
        const endpoint = config.reportGatewayApiEndpoint;
        try {
            const request = await clientFactory(endpoint);
            const result = await request.get(`Embed/Report/${id}`);
            return result;
        } catch (err) {
            throw `PowerBI Report Embed ${err}`;
        }
    }
};