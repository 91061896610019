import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  summary: {},
  carriers: {},
  carriersAutocomplete: [],
  loading: true,
  autocompleteLoading: false
});

function scorecardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_SCORECARD_STARTED:
      return state
        .set('loading', true);
    case types.FETCH_SCORECARD_FAILED:
      return state
        .set('loading', false);
    case types.FETCH_SCORECARD_COMPLETED:
      return state
        .set('summary', action.payload.summary)
        .set('carriers', action.payload.carriers)
        .set('loading', false);
    case types.FETCH_SCORECARD_CARRIERS_STARTED:
      return state
        .set('autocompleteLoading', true);
    case types.FETCH_SCORECARD_CARRIERS_FAILED:
      return state
        .set('autocompleteLoading', false);
    case types.FETCH_SCORECARD_CARRIERS_COMPLETED:
      return state
        .set('carriersAutocomplete', action.payload)
        .set('autocompleteLoading', false);
    default:
      return state;
  }
}

export default scorecardReducer;
