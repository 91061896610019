import React from 'react'
import { connect } from 'react-redux';
import { Panel, PanelGroup } from "react-bootstrap";
import shipmentActions from '../../../containers/shipments/actions';
import { DatePicker } from '@chr/web-components-labs';
import moment from 'moment';

export class DateFilter extends React.Component {
  state = {
    selectedField: '',
    startDate: moment().toDate(),
    endDate: moment().add(1, 'day').toDate(),
    panelExpanded: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateFilter && !this.props.dateFilter) {
      this.setState({
        selectedField: '',
        startDate: moment().toDate(),
        endDate: moment().add(1, 'day').toDate(),
        panelExpanded: false
      });
    } else if (!prevProps.dateFilter && this.props.dateFilter) {
      let commaSeparatedDate = this.props.dateFilter.field.join(',');
      this.setState({
        selectedField: commaSeparatedDate,
        startDate: moment(this.props.dateFilter.startDate).toDate(),
        endDate: moment(this.props.dateFilter.endDate).toDate(),
        panelExpanded: true
      });
    }
  }

  handleDropdownChange = (event) => {
    this.setState({ selectedField: event.target.value });
  }

  searchByDate = () => {
    if (this.state.selectedField === '') {
      return;
    }

    const fields =  this.state.selectedField.includes(',') ? this.state.selectedField.split(',') : [this.state.selectedField];
    const dateFilter = {
      field: fields,
      startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.state.endDate).format("YYYY-MM-DD")
    };

    this.props.changeDateFilter(dateFilter);
  }

  onDateChange = (date, changedDateType) => {
    this.setState({
      [changedDateType]: date
    });
  };

  render() {
    return (
      <ul className="list-group" >
        <PanelGroup id="accordion-controlled-example">
          <Panel className="dateFilterAccordion" expanded={this.state.panelExpanded} onToggle={() => { }}>
            <Panel.Heading>
              <Panel.Title toggle style={{ 'fontSize': '14px' }}
                onClick={() => this.setState({ panelExpanded: !this.state.panelExpanded })}>
                Date Range
            </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              <div className="dateFilter">
                <div>
                  <label className="read-only-value">Date Type</label>
                  <select className="form-control space-bottom dateType" onChange={this.handleDropdownChange} value={this.state.selectedField}>
                    <option value=""></option>
                    <option value="calculated_ETA">ETA</option>
                    <option value="destination_requested">Requested Delivery Date</option>
                    <option value="destination_Sched_date_open,destination_Sched_date_close">Scheduled Delivery Date (between open and close)</option>
                    <option value="origin_requested">Origin Requested Date</option>
                    <option value="origin_Sched_date_open,origin_Sched_date_close">Origin Scheduled Date (between open and close)</option>
                  </select>
                </div>
                <div className='dateRange'>
                  <label className="read-only-value">From</label>
                  <DatePicker
                    className="form-control"
                    selectedDate={this.state.startDate}
                    onChange={(e) => this.onDateChange(e, 'startDate')} />
                  <label className="read-only-value">To</label>
                  <DatePicker
                    className="form-control"
                    selectedDate={this.state.endDate}
                    onChange={(e) => this.onDateChange(e, 'endDate')} />
                </div>
                <div className="searchByDate">
                  <button className="btn btn-primary" onClick={() => this.searchByDate()} data-test="searchByDate">Search By Date</button>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </ul>
    );
  }
}

export const mapStateToProps = (state) => {
  const userProfile = state.users.get('userProfile');
  const userRole = userProfile ? userProfile.role : null;
  const configuration = state.shipments.queryConfiguration;
  const dateFilter = configuration.get('dateFilter');
  const jobMode = state.shipments.data.get('jobMode');

  return {
    dateFilter,
    userProfile,
    userRole,
    jobMode
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {  
  return {
    changeDateFilter: filter => {
      return dispatch(shipmentActions.dateFilterChanged(filter, ownProps.location, ownProps.history));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
