import { all, put, call, takeEvery, select } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import debounce from '../../utils/helperMethods/reduxHelpers';
import globalSuccessErrorAlertActions from '../../containers/global-success-error-alerts/actions';
import { delay } from 'redux-saga'
import notificationActions from '../notifications/actions';

//fetch user profile
export function* fetchUserProfileStart(action) {
  try {
    const userProfile = yield call(api.users.fetchUser, action.payload.id);
    window['localStorage'].setItem('userProfile', JSON.stringify(userProfile));
    yield put(actions.fetchUserProfileComplete(userProfile));
    if (userProfile.role !== null && userProfile.role !== 'ExternalUser') {
      yield put(notificationActions.fetchNotifications());
    }
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(e));
  }
}
export function* watchFetchUserProfileStart() {
  yield debounce(100,
    types.FETCH_USER_PROFILE_STARTED,
    fetchUserProfileStart,
  );
}

export function* fetchUsersStart(action) {
  try {
    let users = yield call(api.users.fetchUsers, action.payload);
    const tenantId = JSON.parse(window['localStorage'].getItem('userProfile')).tenantId;
    const response = {
      users: users,
      tenantId,
      isDeleted: false
    };
    yield put(actions.fetchUsersComplete(response));
  } catch (e) {
    yield all([
      put(actions.fetchUsersFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Users Fetch Error',
        message: 'Failed to fetch users.  Please try again.',
        error: e
      }))
    ]);
  }
}

export function* watchFetchUsersStart() {
  yield takeEvery(
    types.FETCH_USERS_STARTED,
    fetchUsersStart);
}


export function* fetchPermissionsStart(action) {
  try {
    let permissions = yield call(api.users.fetchPermissions, action.payload);
    yield put(actions.fetchPermissionsComplete(permissions.data));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(e));
  }
}

export function* watchFetchPermissionsStart() {
  yield takeEvery(
    types.FETCH_PERMISSIONS_STARTED,
    fetchPermissionsStart);
}


export function* existingUserStart(action) {
  try {
    const user = yield call(api.users.existingUser, action.payload);

    yield put(actions.existingUserComplete(user.data));
  } catch (e) {

  }
}

export function* watchExistingUserStart() {
  yield takeEvery(
    types.EXISTING_USER_STARTED,
    existingUserStart
  );
}

export function* searchForUsersTypeaheadStart(action) {
  try {
    const user = yield call(api.users.searchForUser, action.payload.searchText, action.payload.tenantId);

    yield put(actions.searchForTypeaheadUsersComplete(user.data.users));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(e));
  }
}

export function* watchSearchForUsersTypeaheadStart() {
  yield takeEvery(
    types.SEARCH_FOR_USERS_TYPEAHEAD_STARTED,
    searchForUsersTypeaheadStart,
  );
}

export function* createUserStart(action) {
  try {
    const user = yield call(api.users.createUser, action.payload);

    yield put(actions.fetchUserComplete(user.data));

    yield put(actions.createUserComplete());

    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'This user has been created and a welcome email has been sent.'
    }));

  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `User has not been saved. Please try again.`
    }));
  }
}

export function* watchCreateUserStart() {
  yield takeEvery(
    types.CREATE_USER_STARTED,
    createUserStart,
  );
}

export function* updateUserStart(action) {
  try {
    yield call(api.users.updateUser, action.payload);

    yield put(actions.updateUserComplete());
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'This user has been updated.'
    }));

  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `User has not been saved. Please try again.`
    }));
  }
}

export function* watchUpdateUserStart() {
  yield takeEvery(
    types.UPDATE_USER_STARTED,
    updateUserStart,
  );
}

export function* updateUserPhoneNumberStart(action) {
  try {
    yield call(api.users.updateUserPhoneNumber, action.payload);

    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'User profile has been updated.'
    }));
    const userProfile = yield call(api.users.fetchUser, action.payload.oktaId);
    yield put(actions.fetchUserProfileComplete(userProfile));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Error while trying to update user profile.`
    }));
  }
}

export function* watchUpdateUserPhoneNumberStart() {
  yield takeEvery(
    types.UPDATE_USER_PHONE_NUMBER_STARTED,
    updateUserPhoneNumberStart,
  );
}

export function* validateVerificationCode(action) {
  try {
    yield call(api.users.validateSmsVerificationCode, action.payload);
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'Phone number has been validated.'
    }));
    const userProfile = yield call(api.users.fetchUser, action.payload.oktaId);
    yield put(actions.fetchUserProfileComplete(userProfile));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Error occured while validating verification code`
    }));
  }
}

export function* watchValidateVerificationCodeStart() {
  yield takeEvery(
    types.VALIDATE_SMS_VERIFICATION_CODE,
    validateVerificationCode,
  );
}

export function* sendVerificationCode(action) {
  try {
    yield call(api.users.sendSmsVerificationCode, action.payload);
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Error occured while sending verification code.`
    }));
  }
}

export function* watchSendVerificationCodeStart() {
  yield takeEvery(
    types.SEND_SMS_VERIFICATION_CODE,
    sendVerificationCode,
  );
}

export function* fetchUserStart(action) {
  try {
    const user = yield call(api.users.fetchUser, action.payload.id);
    yield put(actions.fetchUserComplete(user));
  } catch (e) {
    yield all([
      put(actions.fetchUserFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: "Fetch User Error",
        message: "Failed to get user.  Please try again.",
        error: e
      }))
    ]);
  }
}

export function* watchFetchUserStart() {
  yield takeEvery(
    types.FETCH_USER_STARTED,
    fetchUserStart,
  );
}

export function* fetchUserToCopyStart(action) {
  try {
    const user = yield call(api.users.fetchUser, action.payload.id);
    yield put(actions.fetchUserToCopyComplete(user));
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'User was successfully copied.'
    }));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `User was not copied. Please try again.`
    }));
  }
}

export function* watchFetchUserToCopyStart() {
  yield takeEvery(
    types.FETCH_USER_TO_COPY_STARTED,
    fetchUserToCopyStart,
  );
}


export function* deleteUserStart(action) {
  try {
    yield call(api.users.deleteUser, action.payload);

    // added a delay due to a bug in auth0 where there is a delay in search user functionality after deleting a user
    yield call(delay, 500);
    yield put(actions.deleteUserComplete({
      isDeleted: true
    }));
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'This user has been deleted.'
    }));

  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `User was not deleted. Please try again.`
    }));
  }
}

export function* watchDeleteUserStart() {
  yield takeEvery(
    types.DELETE_USER_STARTED,
    deleteUserStart,
  );
}

export function* fetchAlertRulesStart(action) {
  try {
    const alertRules = yield call(api.users.fetchAlertRules, action.payload);
    let subscribedShipment = false;
    if (alertRules && alertRules.data && action.payload && action.payload.isShipmentTracker) {
      alertRules.data.forEach(x => {
        if (x.conditions && x.conditions.all) {
          x.conditions.all.forEach(y => {
            if (y.field == "load_number" && y.value == action.payload.shipmentNumber) {
              subscribedShipment = true;
            }
          });
        }
      });
    }
    yield put(actions.fetchAlertRulesComplete(alertRules.data, subscribedShipment));
  } catch (e) {
    yield all([
      put(actions.fetchAlertRulesFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Fetch Alert Rules Error',
        message: `Error occurred while getting alert rules for user.  Please try again.`,
        error: e
      }))
    ])
  }
}

export function* watchFetchAlertRulesStart() {
  yield takeEvery(types.FETCH_ALERT_RULES_STARTED, fetchAlertRulesStart);
}

export function* createAlertRuleStart(action) {
  try {
    yield call(api.users.createAlertRule, action.payload.rule);
    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'Alert rule has been created.'
    }));
    yield put(actions.fetchAlertRules(action.payload.rule.userId, action.payload.shipmentNumber,  action.payload.isShipmentTracker));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Error occurred while creating alert rule.`
    }));
  }
}

export function* watchCreateAlertRulesStart() {
  yield takeEvery(types.CREATE_ALERT_RULE_STARTED, createAlertRuleStart);
}

export function* updateAlertRuleStart(action) {
  try {
    yield call(api.users.updateAlertRule, action.payload);

    yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
      title: 'Success!',
      message: 'Alert rule has been updated.'
    }));

    const alertRules = yield call(api.users.fetchAlertRules, action.payload);
    yield put(actions.fetchAlertRulesComplete(alertRules.data));
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Error occurred while updating alert rule.`
    }));
  }
}

export function* watchUpdateAlertRulesStart() {
  yield takeEvery(types.UPDATE_ALERT_RULE_STARTED, updateAlertRuleStart);
}

export function* deleteAlertRuleStart(action) {
  try {
    if (action.payload.userId && action.payload.alertRuleId.split('-').length === 1) {
      yield call(api.users.deleteAlertRule, action.payload);

      yield put(globalSuccessErrorAlertActions.triggerGlobalSuccessAlert({
        title: 'Success!',
        message: 'Alert rule has been deleted.'
      }));

      const alertRules = yield call(api.users.fetchAlertRules, action.payload);
      yield put(actions.fetchAlertRulesComplete(alertRules.data));
    } else {
      yield put(actions.deleteAlertRuleComplete(action.payload.alertRuleId));
    }
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Error occurred while deleting alert rule.`
    }));
  }
}

export function* watchDeleteAlertRulesStart() {
  yield takeEvery(types.DELETE_ALERT_RULE_STARTED, deleteAlertRuleStart);
}

export function* changePasswordStart(action) {
  try {
    yield call(api.users.changePassword, action.payload);
    yield put(actions.changePasswordComplete());
  } catch (e) {
    yield put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
      title: 'Failure!',
      message: `Failed to save password. Please try again.`
    }));
  }
}

export function* watchChangePasswordStart() {
  yield takeEvery(types.CHANGE_PASSWORD_STARTED, changePasswordStart);
}
