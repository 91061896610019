import React from 'react'
import NotificationPreference from '../../components/notifications/NotificationPreference';
import EventNotificationsContainer from './EventNotificationsContainer';
import { connect } from 'react-redux';
import usersActions from '../users/actions';
import PropTypes from 'prop-types';
import SmsVerificationContainer from './SmsVerificationContainer';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';

export class NotificationPreferencesContainer extends React.Component {
  state = {
    preferences: [
      {
        notificationType: 'tagged',
        title: 'Tagged in a Message',
        description: 'You will be notified when someone tags you in a message thread on an individual shipment.',
        inApp: true,
        email: true,
        sms: false
      },
      {
        notificationType: 'newMessage',
        title: 'Someone else replies after me.',
        description: 'You will be notified when someone comments on a shipment you commented on.',
        inApp: true,
        email: false,
        sms: false
      }
    ],
    userProfile: []
  };

  componentDidMount() {
    if (this.props.userProfile.size !== 0) {
      this.props.fetchUserProfile(this.props.userProfile.oktaId);
    }
    trackPageInfoEvent(this.props.adobeTrack, "Notifications Page", ["Notifications Page"]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userProfile !== this.props.userProfile && this.props.userProfile.size !== 0 && this.props.userProfile.notificationPreferences && this.props.userProfile.notificationPreferences.length > 0
      || this.state.userProfile !== this.props.userProfile) {
      this.mapNotificationPreferences();
    }
  }

  mapNotificationPreferences = () => {
    let currentState = this.state.preferences;

    if (this.props.userProfile.notificationPreferences) {
      for (let preference of this.props.userProfile.notificationPreferences) {
        if (preference.notificationType === 'tagged') {
          currentState[0].inApp = preference.inApp;
          currentState[0].email = preference.email;
          currentState[0].sms = preference.sms;
        } else {
          currentState[1].inApp = preference.inApp;
          currentState[1].email = preference.email;
          currentState[1].sms = preference.sms;
        }
      }
    }

    this.setState({
      ...this.state,
      preferences: currentState,
      userProfile: this.props.userProfile
    });
  }

  selectedNotification = (notificationType, prevState, checkbox) => {
    var preferences = [...this.state.preferences];

    var index = preferences.findIndex(preference => preference.notificationType === notificationType);

    preferences[index][checkbox] = !prevState;

    this.setState({ preferences });
  }

  saveSettings = () => {
    let userProfile = this.props.userProfile;

    let payload = {
      upn: userProfile.upn || undefined,
      oktaId: userProfile.oktaId,
      accounts: userProfile.accounts,
      channels: userProfile.channels,
      destinationFacilities: userProfile.destinationFacilities,
      email: userProfile.email,
      flows: userProfile.flows,
      originFacilities: userProfile.originFacilities,
      permissions: userProfile.permissions,
      regions: userProfile.regions,
      role: userProfile.role,
      user_metadata: {
        family_name: userProfile.user_metadata.family_name,
        given_name: userProfile.user_metadata.given_name,
        name: userProfile.user_metadata.name,
        tenantId: userProfile.user_metadata.tenantId,
        updated_by: userProfile.user_metadata.name
      },
      notificationPreferences: [],
      blocked: false
    };

    if (userProfile.settings) {
      payload.settings = userProfile.settings;
    }

    this.state.preferences.forEach(x => {
      payload.notificationPreferences.push(
        {
          notificationType: x.notificationType,
          inApp: x.inApp,
          email: x.email,
          sms: x.sms
        }
      )
    });

    this.props.updateUser(payload);
  }

  renderPreferences = (preferences) => {
    return preferences.map((preference) => {
      return (
        <div key={preference.notificationType}>
          <NotificationPreference
            title={preference.title}
            description={preference.description}
            inApp={preference.inApp}
            email={preference.email}
            sms={preference.sms}
            selectedNotification={this.selectedNotification}
            notificationType={preference.notificationType} />
        </div>
      )
    })
  }


  render() {
    return (
      <div className="page flex-column notification-settings">
        <div className="container-fluid flex-one">
          <h1>Notification Preferences</h1>
          <div className="row">

            {/* Text notification settings */}
            <div className="col-sm-12 col-md-4 col-md-push-8">
              <SmsVerificationContainer />
            </div>

            <div className="col-sm-12 col-md-8 col-md-pull-4">
              <div className="row notification-preference-header">
                <div className="col-sm-6 col-md-8">
                </div>
                <div className="col-sm-6 col-md-4 hidden-xs hidden-sm">
                  <div className="row">
                    <div className="col-sm-4 notification-preference">
                      <span className='column-label'>In-App</span>
                    </div>
                    <div className="col-sm-4 notification-preference">
                      <span className="column-label">Email</span>
                    </div>
                    <div className="col-sm-4 notification-preference">
                      <span className="column-label">Text</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="notification-group">
                <h3 className="no-margin">Messaging</h3>
                <div className="notification-type-content">

                  {this.renderPreferences(this.state.preferences)}

                </div>
              </div>

              <div className="save-actions">
                <button className="btn btn-primary" onClick={() => this.saveSettings()} data-test="saveNotificationPreferences">Save Settings</button>
              </div>

            </div>
          </div>
          <div className="row">
            <EventNotificationsContainer />
          </div>
        </div>
      </div>
    );
  }
}

NotificationPreferencesContainer.protoType = {
  userProfile: PropTypes.object
};


export const mapStateToProps = (state) => {
  return {
    userProfile: state.users.get('userProfile')
  }
};

export const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => {
      dispatch(usersActions.updateUser(user));
    },
    fetchUserProfile: (userId) => {
      dispatch(usersActions.fetchUserProfile(userId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPreferencesContainer);
