import React from 'react';
import { connect } from 'react-redux';
import metadataActions from '../metadata/actions';
import baseModalActions from '../base-modal/actions';
import usersActions from '../users/actions';
import PropTypes from 'prop-types';
import { Panel, PanelGroup } from 'react-bootstrap';
import Icon from '../../components/icon';
import SelectDropdown from '../../components/dropdown/SelectDropdown';
import { OverlayTrigger } from 'react-bootstrap';
import { tooltip } from '../../utils/helperMethods/commonComponents';
import {
  isInputEmpty,
  isInvalidEmail,
  isValidSso,
  camelCase
} from '../../utils/helperMethods/commonMethods';
import Autocomplete from 'react-autocomplete';
import LoadingIcon from '../../components/loading-icon';
import queryString from 'query-string';
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';
import { NUOLV_ENABLED_TENANTS } from '../../utils/constants';

export class UserContainer extends React.Component {
  state = {
    isUsingCloudDataSources: false,
    activeKey: [],
    selectedRole: [],
    role: { label: 'User', value: 'User' },
    selectedTenant: [],
    tenants: [],
    selectedPermissions: [],
    permissions: [],
    settings: [],
    selectedAccounts: [],
    accounts: [],
    selectedExternalAccounts: [],
    externalAccounts: [],
    selectedChannels: [],
    channels: [],
    selectedOriginFacilities: [],
    selectedDestinationFacilities: [],
    facilities: [],
    selectedRegions: [],
    regions: [],
    selectedFlows: [],
    flows: [],
    firstName: '',
    lastName: '',
    ssoPrincipalName: '',
    email: '',
    isFederated: false,
    inputValidation: {
      firstNameDirty: false,
      lastNameDirty: false,
      emailDirty: false,
      ssoPrincipalNameDirty: false,
      firstNameError: false,
      lastNameError: false,
      emailExistsError: false,
      emailInvalidError: false,
      emailRequiredError: false,
      ssoPrincipalNameError: false
    },
    userId: [],
    tenantName: '',
    userProfile: [],
    tenantId: [],
    showCopyUserInput: false,
    copyUserSearchText: '',
    typeaheadUsers: []
  };

  componentDidMount() {
    const userId = this.props.location.pathname.slice(14);
    const parsedString = queryString.parse(this.props.location.search);
    const isNewUser = userId === 'new' ? true : false;

    this.setState({
      isUsingCloudDataSources: this.props.isUsingCloudDataSources,
      tenantId: parseInt(parsedString.tenantId),
      isNewUser: isNewUser,
      userId: userId
    });

    if (isNewUser && parsedString.tenantId) {
      this.props.fetchTenantMetadata(parseInt(parsedString.tenantId));
      trackPageInfoEvent(this.props.adobeTrack, 'New User Page', [
        'New User Page'
      ]);
    } else if (!isNewUser && parsedString.tenantId) {
      this.props.fetchUser(userId);
      this.props.fetchTenantMetadata(parseInt(parsedString.tenantId));
      trackPageInfoEvent(this.props.adobeTrack, 'Edit User Page', [
        'Edit User Page'
      ]);
    }
  }

  /* TODO: Remove after we fully migrate prod to luminosity-ui
  /* - "Undoes" the scorecard mapping while trying not to disturb their current permissions */
  mapOptionsToPermissions = (options, userPermissions, isUsingCloudDataSources) => {
    let newPermissions = [];
    if (options != null) {
      options.forEach(option => {
        if (option === 'Scorecard' && !isUsingCloudDataSources) {
          //if they want it, give them both!
          newPermissions.push('CarrierPerformance');
          newPermissions.push('DataCompliance');
        } else {
          newPermissions.push(option);
        }
      });
    }
    if (userPermissions != null) {
      userPermissions.forEach(permission => {
        //don't disrupt their current user permissions with our mapping, but we will be removing these
        if (permission === 'ViewIncidents' || permission === 'Collaboration') {
          newPermissions.push(permission);
        }
      });
    }
    return newPermissions;
  };

  roleOptions = (role, tenantId) => {
    const roles = [];

    roles.push({ label: 'User', value: 'User' });

    if (NUOLV_ENABLED_TENANTS.includes(tenantId)) {
      roles.push({ label: 'ExternalUser', value: 'ExternalUser' });
    }

    roles.push({ label: 'Admin', value: 'Admin' });

    if (role === 'SuperAdmin') {
      roles.push({ label: 'SuperAdmin', value: 'SuperAdmin' })
    }

    return roles;
  };

  setAccessFilters = (userAccessFilters, accessFilters) => {
    const keys = Object.keys(userAccessFilters);
    for (const key of keys) {
      //validate against tenant's accessFilters
      const filteredArray = accessFilters.filter(section => {
        return section.label.replace(/\s+/g, '').toLowerCase() === key.toLowerCase();
      }).map(section => {
        let newSection = Object.assign({}, section);
        return userAccessFilters[key] ? userAccessFilters[key].filter(x => {
          return newSection.values.some(y =>
            y.displayValue === x.label
          )
        }) : []
      })[0] || [];

      //populate selected values
      let selectedStateKey = camelCase(`selected ${key}`);
      this.setState(Object.assign(this.state, {
        [selectedStateKey]: filteredArray ? filteredArray.map(x => {
          return { label: x.label, value: x.label };
        }) : []
      }));
    }

    //populate accessFilter dropdown options
    accessFilters.forEach(section => {
      var key = camelCase(section.label)
      this.setState({
        [key]: section.values.map(y => {
          return { label: y.displayValue, value: y.displayValue }
        })
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //if userToCopy has changed, then use that to supercede this.user.props
    let accounts;
    let externalAccounts;
    let permissions;
    let channels;
    let regions;
    let flows;
    let originFacilities;
    let destinationFacilities;

    if ((!prevProps.userToCopy && this.props.userToCopy) || (prevProps.userToCopy && this.props.userToCopy && prevProps.userToCopy.user_id !== this.props.userToCopy.user_id)) {
      accounts = this.props.userToCopy.accounts;
      externalAccounts = this.props.userToCopy.externalAccounts;
      permissions = this.props.userToCopy.permissions;
      channels = this.props.userToCopy.channels;
      regions = this.props.userToCopy.regions;
      flows = this.props.userToCopy.flows;
      originFacilities = this.props.userToCopy.originFacilities;
      destinationFacilities = this.props.userToCopy.destinationFacilities;

      let userToCopyFilteredData = {
        accounts:
          accounts && accounts.length > 0
            ? accounts.filter(
              x =>
                x !== null &&
                this.props.accounts.some(
                  y => y.code.toLowerCase() === x.toLowerCase()
                )
            )
            : [],
        externalAccounts:
          externalAccounts && externalAccounts.length > 0
            ? externalAccounts.filter(
              x =>
                x !== null &&
                this.props.externalAccounts.some(
                  y => y.code.toLowerCase() === x.toLowerCase()
                )
            )
            : [],
        flows:
          flows && flows.length > 0
            ? flows.filter(x =>
              this.props.flows.some(
                y => y.name.toLowerCase() === x.toLowerCase()
              )
            )
            : [],
        regions:
          regions && regions.length > 0
            ? regions.filter(x =>
              this.props.regions.some(
                y => y.name.toLowerCase() === x.toLowerCase()
              )
            )
            : [],
        channels:
          channels && channels.length > 0
            ? channels.filter(x =>
              this.props.channels.some(
                y => y.name.toLowerCase() === x.toLowerCase()
              )
            )
            : [],
        originFacilities:
          originFacilities && originFacilities.length > 0
            ? this.props.facilities.filter(x =>
              originFacilities.some(
                y => y.toLowerCase() === x.code.toLowerCase()
              )
            )
            : [],
        destinationFacilities:
          destinationFacilities && destinationFacilities.length > 0
            ? this.props.facilities.filter(x =>
              destinationFacilities.some(
                y => y.toLowerCase() === x.code.toLowerCase()
              )

            )
            : [],
        permissions:
          permissions && permissions.length > 0
            ? this.props.permissions.filter(x =>
              permissions.some(
                y => y === x
              )
            )
            : []
      };

      if (this.props.userToCopy.accessFilters && this.props.accessFilters) {
        this.setAccessFilters(this.props.userToCopy.accessFilters, this.props.accessFilters);
      };

      this.setState({
        selectedAccounts: userToCopyFilteredData.accounts.map(x => {
          return { label: x, value: x };
        }),
        selectedExternalAccounts: userToCopyFilteredData.externalAccounts.map(x => {
          return { label: x, value: x };
        }),
        selectedPermissions: mapPermissionsToOptions(userToCopyFilteredData.permissions, this.props.isUsingCloudDataSources).map(x => {
          return { label: x, value: x };
        }),
        selectedChannels: userToCopyFilteredData.channels.map(x => {
          return { label: x, value: x };
        }),
        selectedRegions: userToCopyFilteredData.regions.map(x => {
          return { label: x, value: x };
        }),
        selectedFlows: userToCopyFilteredData.flows.map(x => {
          return { label: x, value: x };
        }),
        selectedOriginFacilities: userToCopyFilteredData.originFacilities.map(
          x => {
            return { label: x.name, value: x.name, code: x.code };
          }
        ),
        selectedDestinationFacilities: userToCopyFilteredData.destinationFacilities.map(
          x => {
            return { label: x.name, value: x.name, code: x.code };
          }
        ),
        permissions: userToCopyFilteredData.permissions.map(x => ({ label: x, value: x }))
      });

    } else if (this.props.user) {
      accounts = this.props.user.accounts;
      externalAccounts = this.props.user.externalAccounts;
      permissions = this.props.user.permissions;
      channels = this.props.user.channels;
      regions = this.props.user.regions;
      flows = this.props.user.flows;
      originFacilities = this.props.user.originFacilities;
      destinationFacilities = this.props.user.destinationFacilities;
    }

    // create user, available roles
    if (this.state.isNewUser && this.props.userProfile && this.state.userProfile.length === 0 && this.props.userProfile.size !== 0) {
      const roles = this.roleOptions(this.props.userProfile.role, this.props.userProfile.tenantId);
      const potentialRole = roles.filter(r => r.value == this.props.userProfile.role);
      const selectedRole = potentialRole.length ? potentialRole[0] : roles[0];

      this.setState({
        roles: roles,
        selectedRole: selectedRole,
        userProfile: this.props.userProfile
      });
    }

    // set availalble tenantData and default values
    if (this.state.isNewUser && this.props.accounts && this.props.userProfile && this.props.accounts.size !== 0 && this.props.userProfile.size !== 0 && prevProps.accounts !== this.props.accounts) {
      const tenantName = this.props.tenants.find(x => x.tenantId === this.props.userProfile.tenantId).name;
      const selectedTenant =
        this.props.userProfile.role === 'SuperAdmin' &&
          this.state.selectedTenant &&
          this.state.selectedTenant.value
          ? {
            value: this.state.selectedTenant.value,
            label: this.state.selectedTenant.value,
            tenantId: this.state.selectedTenant.tenantId
          }
          : {
            value: tenantName,
            label: tenantName,
            tenantId: this.props.userProfile.tenantId
          };

      const tenantRoles = this.roleOptions(this.props.userProfile.role, selectedTenant.tenantId);
      this.setState({
        roles: tenantRoles
      });
      const facilities = this.props.facilities.map(x => {
        return { label: x.name, value: x.name, code: x.code };
      });

      //populate accessFilter dropdown options for new users
      if (this.props.accessFilters && this.props.accessFilters.length) {
        this.props.accessFilters.forEach(x => {
          let data = x.values.map(y => {
            return { label: y.displayValue, value: y.displayValue }
          });
          this.setState({ [camelCase(x.label)]: data });
        });
      }

      this.setDropdownOptions(this.props.accounts, 'code', ['accounts', 'selectedAccounts']);
      this.setDropdownOptions(this.props.externalAccounts, 'code', ['externalAccounts', 'selectedExternalAccounts']);
      this.setDropdownOptions(this.props.flows, 'name', ['flows']);
      this.setDropdownOptions(this.props.regions, 'key', ['regions']);
      this.setDropdownOptions(this.props.channels, 'name', ['channels']);
      this.setState({
        tenantName: tenantName,
        selectedTenant: selectedTenant,
        facilities: facilities
      });
    }

    if (
      !this.state.isNewUser &&
      this.props.accounts &&
      this.props.user &&
      this.props.userProfile &&
      this.props.accounts.size !== 0 &&
      this.props.userProfile.size !== 0 &&
      this.props.user.size !== 0 &&
      (prevProps.accounts !== this.props.accounts ||
        this.state.accounts.length === 0) &&
      this.props.user.oktaId === this.state.userId
    ) {
      const tenantName = this.props.tenants.find(
        x => x.tenantId === this.props.user.tenantId
      ).name;

      const facilities = this.props.facilities.map(x => {
        return { label: x.name, value: x.name, code: x.code };
      });

      // filter out deleted user info
      var filteredTenantData = {
        accounts:
          accounts && accounts.length > 0
            ? accounts.filter(
              x =>
                x !== null &&
                this.props.accounts.some(
                  y => y.code.toLowerCase() === x.toLowerCase()
                )
            )
            : [],
        externalAccounts:
          externalAccounts && externalAccounts.length > 0
            ? externalAccounts.filter(
              x =>
                x !== null &&
                this.props.externalAccounts.some(
                  y => y.code.toLowerCase() === x.toLowerCase()
                )
            )
            : [],
        flows:
          flows && flows.length > 0
            ? flows.filter(x =>
              this.props.flows.some(
                y => y.name.toLowerCase() === x.toLowerCase()
              )
            )
            : [],
        regions:
          regions && regions.length > 0
            ? regions.filter(x =>
              this.props.regions.some(
                y => y.key.toLowerCase() === x.toLowerCase()
              )
            )
            : [],
        channels:
          channels && channels.length > 0
            ? channels.filter(x =>
              this.props.channels.some(
                y => y.name.toLowerCase() === x.toLowerCase()
              )
            )
            : [],
        originFacilities:
          originFacilities && originFacilities.length > 0
            ? facilities.filter(x =>
              originFacilities.some(
                y => y.toLowerCase() === x.code.toLowerCase()
              )
            )
            : [],
        destinationFacilities:
          destinationFacilities && destinationFacilities.length > 0
            ? facilities.filter(x =>
              destinationFacilities.some(
                y => y.toLowerCase() === x.code.toLowerCase()
              )
            )
            : []
      };

      if (this.props.user.accessFilters && this.props.accessFilters) {
        this.setAccessFilters(this.props.user.accessFilters, this.props.accessFilters);
      };

      this.setDropdownOptions(filteredTenantData.accounts, null, ['selectedAccounts']);
      this.setDropdownOptions(this.props.accounts, 'code', ['accounts']);

      this.setDropdownOptions(filteredTenantData.externalAccounts, null, ['selectedExternalAccounts']);
      this.setDropdownOptions(this.props.externalAccounts, 'code', ['externalAccounts']);

      this.setDropdownOptions(filteredTenantData.flows, null, ['selectedFlows']);
      this.setDropdownOptions(this.props.flows, 'name', ['flows']);

      this.setDropdownOptions(filteredTenantData.regions, null, ['selectedRegions']);
      this.setDropdownOptions(this.props.regions, 'key', ['regions']);

      this.setDropdownOptions(filteredTenantData.channels, null, ['selectedChannels']);
      this.setDropdownOptions(this.props.channels, 'name', ['channels']);

      const roles = this.roleOptions(this.props.user.role, this.props.user.tenantId);

      this.setState({
        tenantName: tenantName,
        facilities: facilities,
        selectedDestinationFacilities: filteredTenantData.destinationFacilities,
        selectedOriginFacilities: filteredTenantData.originFacilities,
        firstName:
          this.props.user.user_metadata &&
            this.props.user.user_metadata.given_name
            ? this.props.user.user_metadata.given_name
            : '',
        lastName:
          this.props.user.user_metadata &&
            this.props.user.user_metadata.family_name
            ? this.props.user.user_metadata.family_name
            : '',
        email: this.props.user.email || '',
        ssoPrincipalName: this.props.user.upn || '',
        roles: roles,
        selectedRole: roles.filter(x => x.label === this.props.user.role)[0],
        disableDelete:
          this.props.userProfile.oktaId === this.props.user.oktaId
            ? true
            : false,
        tenantId: this.props.user.tenantId,
        selectedTenant: {
          value: tenantName,
          label: tenantName,
          tenantId: this.props.user.tenantId
        },
        settings: this.mapUserSettings(this.props.user.settings)
      });
    }
   
    if (
      this.state.isNewUser &&
      prevProps.permissions !== this.props.permissions
    ) {
      let permissionsToUse = this.props.userToCopy ? this.props.userToCopy.permissions : this.props.permissions;
      let mappedPermissions = mapPermissionsToOptions(permissionsToUse, this.props.isUsingCloudDataSources);
      this.setDropdownOptions(mappedPermissions, null, [
        'permissions',
        'selectedPermissions'
      ]);
    }

    if (
      !this.state.isNewUser &&
      this.props.permissions &&
      this.props.permissions.length !== 0 &&
      this.props.user &&
      (prevProps.permissions !== this.props.permissions ||
        prevProps.user !== this.props.user)
    ) {
      let mappedPermissions = mapPermissionsToOptions(this.props.permissions, this.props.isUsingCloudDataSources);
      let mappedUserPermissions = mapPermissionsToOptions(
        this.props.user.permissions,
        this.props.isUsingCloudDataSources
      );

      mappedUserPermissions = mappedUserPermissions.filter(up => mappedPermissions.includes(up));

      this.setDropdownOptions(mappedUserPermissions, null, [
        'selectedPermissions'
      ]);
      this.setDropdownOptions(mappedPermissions, null, ['permissions']);
    }

    if (prevProps.tenants !== this.props.tenants) {
      const tenants = this.props.tenants.map(x => {
        return { label: x.name, value: x.name, tenantId: x.tenantId };
      });

      this.setState({ tenants: tenants });
    }

    if (prevProps.existingUser !== this.props.existingUser) {
      const isExistingUser = this.props.existingUser
        ? { emailExistsError: true }
        : { emailExistsError: false };
      this.setState({
        inputValidation: {
          ...this.state.inputValidation,
          ...isExistingUser
        }
      });
    }

    if (this.state.isNewUser && this.props.user !== prevProps.user ) {
      this.props.history.push(
        `/manage-users/${this.props.user.oktaId}?tenantId=${this.state.tenantId}`
      );
    }

    if (
      this.state.isNewUser &&
      this.props.location.pathname.slice(14) !== 'new'
    ) {
      const parsedString = queryString.parse(this.props.location.search);
      

      this.setState({
        tenantId: parseInt(parsedString.tenantId),
        isNewUser: false,
        userId: this.props.location.pathname.slice(14)
      });
    }

    // added a isDeleted flag due to a bug in auth0 where there is a delay in search user functionality after deleting a user
    if (prevProps.isDeleted !== this.props.isDeleted) {
      this.props.history.push('/manage-users');
    }

    if ((prevState.selectedRole && prevState.selectedRole.value
      && this.state.selectedRole && this.state.selectedRole.value
      && prevState.selectedRole.value !== this.state.selectedRole.value)
      || (prevState.selectedRole && prevState.selectedRole.value == null
        && this.state.selectedRole && this.state.selectedRole.value
      )) {
      this.props.fetchPermissions(this.state.selectedRole.value)
    }
  }

  componentWillUnmount() {
    this.props.clearCopyToUser();
  }

  setDropdownOptions = (tenantData, key, stateKey) => {
    let currentTenatData = [];
    if (tenantData) {
      for (let i = 0; i <= tenantData.length - 1; i++) {
        if (key) {
          currentTenatData.push({
            label: tenantData[i][key],
            value: tenantData[i][key]
          });
        } else {
          currentTenatData.push({ label: tenantData[i], value: tenantData[i] });
        }
      }
    }

    if (stateKey.length === 1) {
      this.setState({ [stateKey[0]]: currentTenatData });
    } else {
      this.setState({ [stateKey[0]]: currentTenatData });
      this.setState({ [stateKey[1]]: currentTenatData });
    }
  };

  handleDropdownChange = (currentValues, stateName) => {
    this.setState({ [stateName]: currentValues ? currentValues : [] });
  };

  handleSelectUserToCopy = userToCopy => {
    this.setState({
      copyUserSearchText: userToCopy
    });
    const userDetails = this.props.typeaheadUsers.find(
      x => x.email === userToCopy
    );
    this.props.fetchUserToCopy(userDetails.user_id);
    const tenantId =
      this.props.userProfile.role === 'SuperAdmin'
        ? this.state.selectedTenant.tenantId
        : this.props.userProfile.tenantId;
    this.props.searchForTypeaheadUsers(userToCopy, tenantId);
  };

  handleTenantChange = selectedTenant => {
    this.props.fetchTenantMetadata(selectedTenant.tenantId);
    this.props.fetchPermissions();
    let newState = {
      selectedTenant: selectedTenant,
      tenantId: selectedTenant.tenantId,
      selectedAccounts: [],
      selectedExternalAccounts: [],
      selectedPermissions: [],
      selectedChannels: [],
      selectedOriginFacilities: [],
      selectedDestinationFacilities: [],
      selectedRegions: []
    }

    if (this.props.accessFilters && this.props.accessFilters.length) {
      this.props.accessFilters.forEach(x => {
        newState[camelCase(`selected ${x.label}`)] = [];
      });
    }

    this.setState(newState);
  };

  handleAccordionSelect = activeKey => {
    this.setState({ activeKey });
  };

  handleCancel = () => {
    this.props.history.push('/manage-users');
  };

  handleDelete = () => {
    this.props.deleteUser(this.state.userId);
    this.props.closeModal();
  };

  mapSelectedAccountsToTenantAccounts = (selectedAccounts, tenantAccounts) => {
    if (selectedAccounts && tenantAccounts) {
      let convertedSelectionAccounts = tenantAccounts
        .filter(accountDetail =>
          selectedAccounts.some(selectedAccount => {
            return (
              selectedAccount.value.localeCompare(
                accountDetail.value,
                undefined,
                { sensitivity: 'base' }
              ) == 0
            ); // if the comparison is zero, the strings are considered equivalent
          })
        )
        .map(account => {
          return { label: account.value, value: account.value };
        });

      return convertedSelectionAccounts;
    } // there's no accounts to be used
    else {
      return [];
    }
  };

  handleSave = isNewUser => {
    let userPermissions = this.props.user ? this.props.user.permissions : null;

    // ensure that we only push eligible accounts into the user selected accounts
    let eligibleAccounts = this.mapSelectedAccountsToTenantAccounts(
      this.state.selectedAccounts,
      this.state.accounts
    );

    let eligibleExternalAccounts = this.mapSelectedAccountsToTenantAccounts(
      this.state.selectedExternalAccounts,
      this.state.externalAccounts
    );

    this.setState({
      selectedAccounts: eligibleAccounts,
      selectedExternalAccounts: eligibleExternalAccounts
    });

    let payload = {
      accounts: eligibleAccounts.map(x => x.value),
      externalAccounts: eligibleExternalAccounts.map(x => x.value),
      channels: this.state.selectedChannels.map(x => x.value),
      destinationFacilities: this.state.selectedDestinationFacilities.map(
        x => x.code
      ),
      email: this.state.email,
      flows: this.state.selectedFlows.map(x => x.value),
      originFacilities: this.state.selectedOriginFacilities.map(x => x.code),
      permissions: this.mapOptionsToPermissions(
        this.state.selectedPermissions.map(x => x.value),
        userPermissions,
        this.props.isUsingCloudDataSources
      ),
      regions: this.state.selectedRegions.map(x => x.value),
      role: this.state.selectedRole.value,
      settings: this.state.settings,
      user_metadata: {
        family_name: this.state.lastName,
        given_name: this.state.firstName,
        name: `${this.state.firstName} ${this.state.lastName}`,
        tenantId:
          this.props.userProfile.role === 'SuperAdmin'
            ? this.state.selectedTenant.tenantId
            : this.props.userProfile.tenantId,
        updated_by: this.props.userProfile.user_metadata.name
      }
    };

    if (this.props.accessFilters && this.props.accessFilters.length) {
      let accessFiltersPayload = {};
      this.props.accessFilters.forEach(section => {
        let stateKey = camelCase(section.label);
        let selectedStateKey = camelCase(`selected ${section.label}`);

        accessFiltersPayload[stateKey] = this.state[selectedStateKey] ? this.state[selectedStateKey].map(selectedAccessFilter => {
          return { label: selectedAccessFilter.label, value: section.values.find(test => { return test.displayValue == selectedAccessFilter.value; }).fieldValues }
        }) : [];
      });

      payload.accessFilters = accessFiltersPayload;
    };

    if (this.state.ssoPrincipalName) {
      payload.upn = this.state.ssoPrincipalName;
    };

    if (isNewUser) {
      payload.connection = 'Username-Password-Authentication';
      payload.user_metadata.created_by = this.props.userProfile.user_metadata
        ? this.props.userProfile.user_metadata.given_name +
        ' ' +
        this.props.userProfile.user_metadata.family_name
        : 'VisionApp';
         this.props.createUser(payload);
    } else {
      payload.blocked = false;
      payload.oktaId = this.props.user.oktaId;
      this.props.updateUser(payload);
    }
  };

  handleInputChange = (e, field) => {
    let updateInput = [];
    let inputValidation = [];

    const inputValue = e.target.value;

    if (field === 'firstName') {
      updateInput.firstName = inputValue;
      inputValidation.firstNameDirty = true;
      inputValidation.firstNameError = isInputEmpty(inputValue);
    }

    if (field === 'lastName') {
      updateInput.lastName = inputValue;
      inputValidation.lastNameDirty = true;
      inputValidation.lastNameError = isInputEmpty(inputValue);
    }

    if (field === 'email') {
      updateInput.email = inputValue;
      inputValidation.emailDirty = true;
      inputValidation.emailExistsError = false;
      inputValidation.emailRequiredError = isInputEmpty(inputValue);
      inputValidation.emailInvalidError =
        !isInputEmpty(inputValue) && isInvalidEmail(inputValue);

      if (!isInvalidEmail(inputValue)) {
        this.props.isExistingUser(inputValue);
      }
    }

    if (field === 'sso') {
      updateInput.ssoPrincipalName = inputValue;
      inputValidation.ssoPrincipalNameDirty = true;
      inputValidation.ssoPrincipalNameError = isValidSso(inputValue);
    }

    if (field === 'copyUserCheckbox') {
      updateInput.showCopyUserInput = e.target.checked;

      if (!e.target.checked) {
        updateInput.copyUserSearchText = '';
      }
    }

    if (field === 'iotTemperatureToggleIsF') {
      const getSettings = Object.assign([], this.state.settings);
      const settingIndex = getSettings.findIndex(prop => prop.propertyName === 'iotTemperatureToggleIsF');

      if (settingIndex !== -1) {
        getSettings[settingIndex].propertyValue = String(e.target.checked);
      } else {
        getSettings.push({
          propertyName: "iotTemperatureToggleIsF",
          propertyValue: String(e.target.checked)
        })
      }
      this.setState({ settings: getSettings });
    }

    if (field === 'copyUserSearchText') {
      updateInput.copyUserSearchText = inputValue;

      if (inputValue.length >= 3) {
        const tenantId =
          this.props.userProfile.role === 'SuperAdmin'
            ? this.state.selectedTenant.tenantId
            : this.props.userProfile.tenantId;
        this.props.searchForTypeaheadUsers(inputValue, tenantId);
      }
    }

    this.setState({
      inputValidation: {
        ...this.state.inputValidation,
        ...inputValidation
      },
      ...updateInput
    });
  };

  deleteUserModal = userId => {
    return {
      title: '',
      showActionButton: true,
      showCloseButton: true,
      actionButtonText: 'Delete',
      actionButtonClass: 'btn btn-danger',
      handleAction: this.handleDelete,
      handleCloseAction: this.props.closeModal,
      closeButtonText: 'Cancel',
      body: (
        <div className="align-middle">
          {' '}
          Are you sure you want to delete this user?
        </div>
      )
    };
  };

  returnSettingsProperty = property => {
    const settingsProperty = this.state.settings.find(prop => prop.propertyName === property);
    return settingsProperty ? settingsProperty.propertyValue : false
  }

  mapUserSettings = settings => {
    const mappedSettings = [];
    if (settings && settings.length > 0) {
      settings.forEach((setting) => {
        const newSetting = {
          propertyName: setting.propertyName,
          propertyValue: setting.propertyValue[0]
        };
        mappedSettings.push(newSetting);
      });
    }
    return mappedSettings;
  }

  renderAccessFilters = () => {
    if (this.props.accessFilters && this.props.accessFilters.length) {
      return this.props.accessFilters.map((filter, i) => {
        let stateKey = camelCase(filter.label);
        let selectedStateKey = camelCase(`selected ${filter.label}`);
        return (
          <SelectDropdown
            key={i}
            label={filter.label}
            selectedValues={this.state[selectedStateKey]}
            handleDropdownChange={this.handleDropdownChange}
            options={this.state[stateKey]}
            stateName={selectedStateKey}
            isMultiSelect={true}
            closeMenuOnSelect={false}
            placeholder={`Select ${filter.label}...`}
            showSelectAll={filter.label === 'Customer Locations' ? true : false}
          />
        );
      });
    }
  }

  render() {
    let tenantOptions;
   const isUserFederated = this.props.user?.isFederated;
    if (
      this.props.userProfile &&
      this.props.userProfile.role === 'SuperAdmin'
    ) {
      tenantOptions = (
        <SelectDropdown
          label="Tenant"
          selectedValues={this.state.selectedTenant}
          handleDropdownChange={this.handleTenantChange}
          options={this.state.tenants}
          stateName={'selectedTenant'}
          isMultiSelect={false}
          closeMenuOnSelect={true}
          placeholder={'Select tenant...'}
          isDisabled={!this.state.isNewUser ? true : false}
        />
      );
    } else {
      tenantOptions = (
        <div className="form-group">
          <label className="read-only-value">Tenant</label>
          <label className="tenantName">{this.state.tenantName}</label>
        </div>
      );
    }

    let disableSaveButton = false;

    if (
      this.state.firstName.length === 0 ||
      this.state.lastName.length === 0 ||
      this.state.email.length === 0 ||
      this.state.inputValidation.emailExistsError
    ) {
      disableSaveButton = true;
    }

    return (
      <div className="container-fluid">
        <div className="row header-buffer min-height-setting">
          {this.props.loading ? (
            <div className="centered">
              <LoadingIcon></LoadingIcon>
            </div>
          ) : (
              <div className="col-md-6">
                <form className="userForm">
                  {tenantOptions}

                  <SelectDropdown
                    label="Role"
                    selectedValues={this.state.selectedRole}
                    handleDropdownChange={this.handleDropdownChange}
                    options={this.state.roles}
                    stateName={'selectedRole'}
                  />

                  <div className="form-group">
                    <label className="read-only-value">First Name *</label>
                    <input
                      type="text"
                      name="firstName"
                      data-test="firstName"
                      id={'first-name-input'}
                      className={
                        this.state.inputValidation.firstNameError
                          ? 'form-control invalid'
                          : 'form-control'
                      }
                      onChange={e => this.handleInputChange(e, 'firstName')}
                      value={this.state.firstName}
                      maxLength="50"
                      autoFocus
                      required
                    />
                    {this.state.inputValidation.firstNameError && (
                      <span className="help-block">
                        This is a required field.
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="read-only-value">Last Name *</label>
                    <input
                      type="text"
                      name="lastName"
                      data-test="lastName"
                      id={'last-name-input'}
                      onChange={e => this.handleInputChange(e, 'lastName')}
                      value={this.state.lastName}
                      required
                      maxLength="50"
                      className={
                        this.state.inputValidation.lastNameError
                          ? 'form-control invalid'
                          : 'form-control'
                      }
                    />
                    {this.state.inputValidation.lastNameError && (
                      <span className="help-block">
                        This is a required field.
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="read-only-value">Email *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      data-test="email"
                      disabled={isUserFederated && !this.state.isNewUser}
                      onChange={e => this.handleInputChange(e, 'email')}
                      value={this.state.email}
                      className={
                        this.state.inputValidation.emailRequiredError ||
                          this.state.inputValidation.emailInvalidError ||
                          this.state.inputValidation.emailExistsError
                          ? 'form-control invalid'
                          : 'form-control'
                      }
                    />
                    {this.state.inputValidation.emailRequiredError && (
                      <span className="help-block">
                        This is a required field.
                      </span>
                    )}
                    {this.state.inputValidation.emailInvalidError && (
                      <span className="help-block">
                        Please enter a valid email address.
                      </span>
                    )}
                    {this.state.inputValidation.emailExistsError && (
                      <span className="help-block">
                        Email is already in use by another user
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="read-only-value">
                      SSO User Principal Name
                    <OverlayTrigger
                        placement="top"
                        overlay={tooltip(
                          `If you have SSO (single sign-on) setup for your company, enter the user's SSO email address. This is typically the "username" you use to sign in to your company computer, email, etc., followed by your company domain name (userlogin@companydomain.com)`,
                          'help'
                        )}
                      >
                        <Icon type="help" className="icon small faded" />
                      </OverlayTrigger>
                    </label>
                    <input
                      type="text"
                      id="upn"
                      name="upn"
                      data-test="sso"
                      disabled = {(!this.state.isNewUser)}
                      onChange=
                       { e => this.handleInputChange(e, 'sso')}
                      defaultValue={this.state.ssoPrincipalName}
                      className={
                        this.state.inputValidation.ssoPrincipalNameError
                          ? 'form-control invalid'
                          : 'form-control'}                                             
                    />
                    <span>
                      Leave blank if you don't have SSO (single sign-on) setup.
                  </span>
                    {this.state.inputValidation.ssoPrincipalNameError && (
                      <span className="help-block">
                        {' '}
                      CH Robinson SSO must follow corporate short form email practices.
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <div className="form-check">
                      <label
                        className="form-check-label read-only-value"
                        htmlFor="copyUserCheckbox"
                      >
                        <input
                          style={{ 'top': '-12px' }}
                          className="form-check-input"
                          type="checkbox"
                          name="copyUserCheckbox"
                          onChange={e =>
                            this.handleInputChange(e, 'copyUserCheckbox')
                          }
                          id="copyUserCheckbox"
                          checked={this.state.showCopyUserInput}
                          data-test="copyUserCheckbox"
                        />
                      Copy Rights from Existing User
                    </label>
                    </div>
                    {this.state.showCopyUserInput && (
                      <Autocomplete
                        getItemValue={i => i.email}
                        items={this.props.typeaheadUsers || []}
                        renderItem={(item, isHighlighted) => {
                          return (
                            <div
                              style={{
                                background: isHighlighted ? 'lightgray' : 'white',
                                cursor: 'pointer'
                              }}
                              className={
                                isHighlighted
                                  ? 'dropdown-item active'
                                  : 'dropdown-item'
                              }
                              key={item.email}
                            >
                              {item.email}
                            </div>
                          );
                        }}
                        value={this.state.copyUserSearchText}
                        onChange={e =>
                          this.handleInputChange(e, 'copyUserSearchText')
                        }
                        onSelect={this.handleSelectUserToCopy}
                        inputProps={{ className: 'form-control' }}
                        wrapperStyle={{ zIndex: 10000 }}
                        wrapperProps={{ className: 'dropdown' }}
                        renderMenu={(items, value, style) => {
                          return (
                            <div
                              style={{
                                ...style,
                                zIndex: 10000,
                                borderRadius: '3px',
                                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%'
                              }}
                              children={items}
                            />
                          );
                        }}
                      />
                    )}
                  </div>

                  <div className="form-check">
                    <label
                      className="form-check-label read-only-value"
                      htmlFor="iotTemperatureToggle"
                    >
                      <input 
                        className="form-check-input"
                        style={{ 'top': '-12px' }}
                        type="checkbox"
                        name="iotTemperatureToggle"
                        onChange={e =>
                          this.handleInputChange(e, 'iotTemperatureToggleIsF')
                        }
                        id="iotTemperatureToggle"
                        checked={(this.returnSettingsProperty('iotTemperatureToggleIsF') === 'true')}
                        data-test="iotTemperatureToggle"
                      />
                    Set IoT Temperature to display in F <span style={{ 'fontSize': '12px' }}>(Default is C) </span>
                    </label>
                  </div>

                  <SelectDropdown
                    label="Permissions "
                    selectedValues={mapPermissionsToOptions(
                      this.state.selectedPermissions,
                      this.props.isUsingCloudDataSources
                    )}
                    handleDropdownChange={this.handleDropdownChange}
                    options={mapPermissionsToOptions(
                      this.state.permissions.map(x => x.label),
                      this.props.isUsingCloudDataSources
                    ).map(x => {
                      return { label: x, value: x };
                    })}
                    stateName={'selectedPermissions'}
                    isMultiSelect={true}
                    closeMenuOnSelect={false}
                    overlayContent={
                      "Permissions allow you to remove access to certain pages or functionality based on user needs. Access can be be removed by clicking the 'x' next to the page or functionality name."
                    }
                    overlayIconType={'help'}
                    placeholder={'Select permissions...'}
                  />

                  <SelectDropdown
                    label="Accounts "
                    selectedValues={this.state.selectedAccounts}
                    handleDropdownChange={this.handleDropdownChange}
                    options={this.state.accounts}
                    stateName={'selectedAccounts'}
                    isMultiSelect={true}
                    closeMenuOnSelect={false}
                    overlayContent={
                      "Accounts allow you to remove or add access to certain accounts based on user needs. Access can be removed by clicking the 'x' next to the account you'd like removed."
                    }
                    overlayIconType={'help'}
                    placeholder={'Select accounts...'}
                  />

                {
                  !this.state.externalAccounts || !this.state.externalAccounts.length ? null : <SelectDropdown
                    data-test="externalAccountsDropdown"
                    label="External accounts "
                    selectedValues={this.state.selectedExternalAccounts}
                    handleDropdownChange={this.handleDropdownChange}
                    options={this.state.externalAccounts}
                    stateName={'selectedExternalAccounts'}
                    isMultiSelect={true}
                    closeMenuOnSelect={false}
                    overlayContent={
                      "External accounts allow you to remove or add access to certain accounts based on user needs. Access can be removed by clicking the 'x' next to the account you'd like removed."
                    }
                    overlayIconType={'help'}
                    placeholder={'Select external accounts...'}
                  />
                }
                </form>

                <PanelGroup
                  accordion
                  id="accordion-controlled-example"
                  activeKey={this.state.activeKey}
                  onSelect={this.handleAccordionSelect}
                >
                  <Panel eventKey="1">
                    <Panel.Heading>
                      <Panel.Title toggle>
                        <br />
                      Access Filters
                      <Icon
                          type={
                            this.state.activeKey === '1'
                              ? 'chevron-down'
                              : 'chevron-right'
                          }
                        />
                      </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                      <p>
                        The following access filters will further restrict the
                        user from accessing certain shipments. Leave the access
                        filter blank to allow full access or select specific
                        values to limit access to only what has been selected.
                    </p>

                      <SelectDropdown
                        label="Channels"
                        selectedValues={this.state.selectedChannels}
                        handleDropdownChange={this.handleDropdownChange}
                        options={this.state.channels}
                        stateName={'selectedChannels'}
                        isMultiSelect={true}
                        closeMenuOnSelect={false}
                        placeholder={'Select channels...'}
                      />

                      <SelectDropdown
                        label="Origin Facilities"
                        selectedValues={this.state.selectedOriginFacilities}
                        handleDropdownChange={this.handleDropdownChange}
                        options={this.state.facilities}
                        stateName={'selectedOriginFacilities'}
                        isMultiSelect={true}
                        closeMenuOnSelect={false}
                        placeholder={'Select origin facilities...'}
                      />

                      <SelectDropdown
                        label="Destination Facilities"
                        selectedValues={this.state.selectedDestinationFacilities}
                        handleDropdownChange={this.handleDropdownChange}
                        options={this.state.facilities}
                        stateName={'selectedDestinationFacilities'}
                        isMultiSelect={true}
                        closeMenuOnSelect={false}
                        placeholder={'Select destination facilities...'}
                      />

                      <SelectDropdown
                        label="Regions"
                        selectedValues={this.state.selectedRegions}
                        handleDropdownChange={this.handleDropdownChange}
                        options={this.state.regions}
                        stateName={'selectedRegions'}
                        isMultiSelect={true}
                        closeMenuOnSelect={false}
                        placeholder={'Select regions...'}
                      />

                      <SelectDropdown
                        label="Flows"
                        selectedValues={this.state.selectedFlows}
                        handleDropdownChange={this.handleDropdownChange}
                        options={this.state.flows}
                        stateName={'selectedFlows'}
                        isMultiSelect={true}
                        closeMenuOnSelect={false}
                        placeholder={'Select flows...'}
                      />

                      {this.renderAccessFilters()}
                    </Panel.Body>
                  </Panel>
                </PanelGroup>

                <div className="form-group">
                  <div className="col-xs-12 text-right">
                    <button
                      type="button"
                      id={'save-user-button'}
                      className="btn btn-primary save"
                      onClick={e => this.handleSave(this.state.isNewUser)}
                      disabled={disableSaveButton}
                    >
                      <span className="glyphicon glyphicon-edit"></span>&nbsp;Save
                  </button>
                    <button
                      type="button"
                      className="btn btn-light cancel"
                      id={'cancel-button'}
                      onClick={this.handleCancel}
                    >
                      <span className="glyphicon glyphicon-backward"></span>&nbsp;
                    Cancel
                  </button>
                    {!this.state.isNewUser && (
                      <button
                        type="button"
                        id={'delete-user-button'}
                        className="btn btn-danger delete"
                        onClick={() =>
                          this.props.showModal(this.deleteUserModal())
                        }
                        disabled={this.state.disableDelete}
                      >
                        <span className="glyphicon glyphicon-remove"></span>&nbsp;
                      Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

UserContainer.protoType = {
  fetchTenantMetadata: PropTypes.func,
  fetchPermissions: PropTypes.func,
  searchForUser: PropTypes.func,
  createUser: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchUserToCopy: PropTypes.func,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  regions: PropTypes.array,
  flows: PropTypes.array,
  channels: PropTypes.array,
  facilities: PropTypes.array,
  accounts: PropTypes.array,
  externalAccounts: PropTypes.array,
  tenants: PropTypes.array,
  accessFilters: PropTypes.array,
  permissions: PropTypes.array,
  existingUser: PropTypes.bool,
  user: PropTypes.array,
  userProfile: PropTypes.array,
  isDeleted: PropTypes.array,
  typeaheadUsers: PropTypes.array,
  userToCopy: PropTypes.array
};

/* TODO: Remove this after we fully migrate prod to luminosity-ui
/* - Maps permissions from API to selectable UI options
/* - Removes the soon to be depreciated ViewIncidents and Collaboration permissions from user interaction
/* - If a user has a CarrierPerformance permisson or a DataComplience permission, we will show them Scorecard
/*    - Eventually, CarrierPerformance will be renamed to Scorecard and updated for all users */
export const mapPermissionsToOptions = (permissions, isUsingCloudDataSources) => {
  if (permissions != null) {
    let newOptions = [];
    permissions.forEach(permission => {
      if (permission !== 'ViewIncidents' && permission !== 'Collaboration') {
        if (
          permission === 'CarrierPerformance' ||
          permission === 'DataCompliance'
        ) {
          if (!isUsingCloudDataSources && newOptions.filter(opt => opt === 'Scorecard').length === 0) {
            newOptions.push('Scorecard');
          }
        } else {
          newOptions.push(permission);
        }
      }
    });
    return newOptions;
  } else {
    return [];
  }
};

// todo: update props
export const mapStateToProps = (state, props) => {
  //TODO:  Remove this mapping after we have switched over to refactor and have updated the mongodb permission list
  const oldPermissions = state.users.get('permissions');
  let permissionNameArray = [];
  let newPermissions = [];
  if (oldPermissions != null) {
    oldPermissions.forEach(permission => {
      permissionNameArray.push(permission.name);
    });
    newPermissions = mapPermissionsToOptions(permissionNameArray, props.isUsingCloudDataSources);
  }

  return {
    regions: state.tenantMetadata.get('regions'),
    flows: state.tenantMetadata.get('flows'),
    channels: state.tenantMetadata.get('channels'),
    facilities: state.tenantMetadata.get('facilities'),
    accounts: state.tenantMetadata.get('accounts'),
    externalAccounts: state.tenantMetadata.get('externalAccounts'),
    tenants: state.tenantMetadata.get('tenants'),
    accessFilters: state.tenantMetadata.get('accessFilters'),
    permissions: newPermissions,
    existingUser: state.users.get('existingUser'),
    typeaheadUsers: state.users.get('typeaheadUsers'),
    user: state.users.get('user'),
    userProfile: state.users.get('userProfile'),
    isDeleted: state.users.get('isDeleted'),
    userToCopy: state.users.get('userToCopy'),
    loading: state.users.get('userLoading')
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchAccessFilters: tenantId => {
      dispatch(metadataActions.fetchAccessFilters(tenantId));
    },
    fetchTenantMetadata: tenantId => {
      dispatch(metadataActions.fetchTenantMetadata(tenantId));
    },
    fetchPermissions: role => {
      dispatch(usersActions.fetchPermissions(role));
    },
    isExistingUser: email => {
      dispatch(usersActions.existingUser(email));
    },
    searchForTypeaheadUsers: (searchText, tenantId) => {
      dispatch(usersActions.searchForTypeaheadUsers(searchText, tenantId));
    },
    createUser: user => {
      dispatch(usersActions.createUser(user));
    },
    fetchUser: userId => {
      dispatch(usersActions.fetchUser(userId));
    },
    fetchUserToCopy: userId => {
      dispatch(usersActions.fetchUserToCopy(userId));
    },
    updateUser: user => {
      dispatch(usersActions.updateUser(user));
    },
    clearCopyToUser: () => {
      dispatch(usersActions.clearCopyToUser());
    },
    deleteUser: userId => {
      dispatch(usersActions.deleteUser(userId));
    },
    showModal: modalConfig => {
      dispatch(baseModalActions.openModal(modalConfig));
    },
    closeModal: () => {
      dispatch(baseModalActions.closeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
