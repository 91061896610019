import React from 'react';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import _ from 'lodash';
import geohash from 'ngeohash';
import { iconHTML } from '../../components/icon';
import queryString from 'query-string';
import * as apis from '../../apis/shipments';
import * as mapConstants from './constants';
import * as shipmentMethods from '../../utils/helperMethods/shipmentMethods';
import * as commonMethods from '../../utils/helperMethods/commonMethods';
import ShipmentsPopover from '../../components/popovers/ShipmentsPopover';
import PortPopover from '../../components/popovers/PortPopover';
import IncidentsPopover from '../../components/popovers/IncidentsPopover';
import FacilityPopover from '../../components/popovers/FacilityPopover';
import ClustersPopover from '../../components/popovers/ClustersPopover';
import ExpectedPositionPopover from '../../components/popovers/ExpectedPositionPopover';
import EventsPopover from '../../components/popovers/EventsPopover';
import StopPopover from '../../components/popovers/StopPopover';
import calcOffset from '../../utils/helperMethods/pinMethods';
import { getCreatedStore } from '../../../createStore';
import shipmentActions from '../../containers/shipments/actions';

import { flight, gps, intermodal, shipment, vessel, clusterCss } from './extras/icons.js';

const weatherApiKey = cfg => cfg.weatherApiKey;
const incidentFillColor = 'rgba(192,161,138,0.25)';
const incidentStrokeColor = 'rgba(244,123,32,1)';
const incidentStrokeThickness = 2;

function EmptyOverlay() { };

export function addWeatherLayer(name, map, Microsoft, options, appConfig) {
  EmptyOverlay.prototype = new Microsoft.Maps.CustomOverlay();
  EmptyOverlay.prototype.onAdd = function () {
    const container = document.createElement('div');
    this.setHtmlElement(container);
  };

  if (appConfig && appConfig.weatherApiEndpoint) {
    axios.get(`${appConfig.weatherApiEndpoint}series/productSet/PPAcore?apiKey=${weatherApiKey(appConfig)}`).then(function (response) {
      let i;
      let series = response.data.seriesInfo.radarFcst.series;
      let s = series[0];
      let m = series[0].ts;
      for (i = 0; i < series.length; i++) {
        if (series[i].ts > m) {
          s = series[i];
          m = series[i].ts;
        }
      }
      s.fts.sort();
      let tileSources = [];
      for (i = 0; i < s.fts.length; i++) {
        if (tileSources.length > 5)
          break;
        let path = `${appConfig.weatherApiEndpoint}tile/radarFcst?ts={ts}&fts={fts}&xyz={x}:{y}:{zoom}&apiKey=${weatherApiKey(appConfig)}`;
        path = path.replace('{fts}', s.fts[i]);
        path = path.replace('{ts}', s.ts);
        const tileSource = new Microsoft.Maps.TileSource({
          uriConstructor: path
        });

        tileSources.push(tileSource);
      }

      let weatherLayer = new Microsoft.Maps.AnimatedTileLayer({
        mercator: tileSources,
        frameRate: 500,
        visible: true,
        loadingScreen: new EmptyOverlay()
      });
      if (weatherLayer) {
        weatherLayer.getId = function () {
          return mapConstants.WEATHER_LAYER;
        };
      }
      map.layers.insert(weatherLayer);
    });
  }
}

export function getLayer(layerName, map) {
  if (map && map.layers) {
    return _.find(map.layers, function (layer) {
      if (layer.getId) {
        return layer.getId() === layerName;
      }
      return false;
    });
  } else {
    return null;
  }
}

export function setWeatherLayerOptions(name, options, map, Microsoft, appConfig) {
  const layer = getLayer(name, map);
  if (layer) {
    layer.setOptions(options);
  } else {
    addWeatherLayer(name, map, Microsoft, options, appConfig);
  }
}

export function setNamedLayerVisible(name, visible, map, Microsoft) {
  const layer = getLayer(name, map);
  if (layer) {
    layer.setVisible(visible);
  } else {
    addNamedLayer(name, map, Microsoft, visible);
  }
}

export function addNamedLayer(name, map, Microsoft, visible) {
  const layer = new Microsoft.Maps.Layer(name);
  if (visible) {
    layer.setVisible(visible);
  }
  map.layers.insert(layer);
  return layer;
}

export function addTrafficLayer(trafficManager, map, Microsoft) {
  if (!trafficManager) {
    trafficManager = new Microsoft.Maps.Traffic.TrafficManager(map);
    setTrafficLayerOptions(trafficManager, true);
    return trafficManager;
  }
  return trafficManager;
}

export async function addDrawingTools(tools, map, Microsoft) {
  if (!tools) {
    tools = await new Microsoft.Maps.DrawingTools(map);
    return tools;
  }
  return tools;
}

export async function drawPolygon(currentShape, tools, map, Microsoft, geoJsonShape) {
  if (!tools) {
    tools = await addDrawingTools(tools, map, Microsoft);
  }
  Microsoft.Maps.Events.addHandler(tools, 'drawingChanged', async () => {
    geoJsonShape(await getGeoJsonFromShape(currentShape, Microsoft));
  });

  if (currentShape) {
    tools.finish(shapeDrawn, map);
    currentShape = null;
  }
  tools.create(Microsoft.Maps.DrawingTools.ShapeType.polygon, function (s) {
    s.setOptions({
      fillColor: incidentFillColor,
      strokeColor: incidentStrokeColor,
      strokeThickness: incidentStrokeThickness
    });
    currentShape = s;
  });

  return currentShape;
}

function shapeDrawn(s, map) {
  map.entities.push(s);
}

export function setTrafficLayerOptions(trafficManager, visible) {
  if (visible) {
    trafficManager.show();
    trafficManager.hideLegend();
  } else {
    trafficManager.hide();
  }
}

export async function getGeoJsonFromShape(shape, Microsoft) {
  let geoJson = {};
  if (shape) {
    await Microsoft.Maps.loadModule('Microsoft.Maps.GeoJson', async function () {
      geoJson = await Microsoft.Maps.GeoJson.write(shape);
    });
  }
  return geoJson;
}

export function getShapeFromGeoJson(geoJson, options, Microsoft) {
  options = options || { polygonOptions: {}, pushpinOptions: {} };
  let defaultPolygonOptions = {
    fillColor: incidentFillColor,
    strokeColor: incidentStrokeColor,
    strokeThickness: incidentStrokeThickness
  };

  let defaultPushpinOptions = {
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><circle cx="6" cy="6" r="5.7142859"  fill="rgba(244,123,32,1)" /></svg>',
    anchor: new Microsoft.Maps.Point(6, 6)
  };

  let shapeOptions = {
    polygonOptions: _.extend(defaultPolygonOptions, options.polygonOptions),
    pushpinOptions: _.extend(defaultPushpinOptions, options.pushpinOptions)
  };

  let microsoftData = Microsoft.Maps.GeoJson.read(geoJson, shapeOptions);
  microsoftData.data = geoJson.geometry.properties || geoJson.properties;
  return microsoftData;
}

export function getShapesToDraw(affectedArea, incidentId, Microsoft) {
  let shapes = [];
  _.forEach(affectedArea.features, function (feature) {
    shapes.push(getShapeFromGeoJson(feature, {}, Microsoft));
  });
  let flattenedShapes = _.flatten(shapes);
  flattenedShapes.forEach(function (shape) {
    if (shape.metadata) {
      shape.metadata.incidentId = incidentId;
      if (shape.data.end_time && shape.data.start_time) {
        shape.metadata.end_time = shape.data.end_time;
        shape.metadata.start_time = shape.data.start_time;
        shape.metadata.path_id = shape.data.path_id;
      }
    }
  });

  return flattenedShapes;
}

export function setMapType(overlay, map, Microsoft) {
  map.setMapType(Microsoft.Maps.MapTypeId[overlay]);
}

export function setMapZoom(zoom, map) {
  if (map && zoom) {
    map.setView({
      zoom: zoom
    });
  }
}

export function setMapBounds(bounds, map) {
  if (map && bounds) {
    map.setView({
      bounds: bounds
    });
  }
}

export function setMapCenter(center, map, Microsoft) {
  if (map && center && center[0] && center[1]) {
    map.setView({
      center: new Microsoft.Maps.Location(center[0], center[1])
    });
  }
}

export function portsBuilder(port, Microsoft) {
  let icon = iconHTML({ type: 'port', style: { cursor: 'pointer' } });
  let portPin = createPushPin(port.port_location.lat, port.port_location.lon, { anchor: { x: 21, y: 21 }, icon: icon }, Microsoft);
  if (!port.dwell_time_avg || port.dwell_time_avg == '-1') {
    port.dwell_time_avg = 'N/A';
  }
  if (!port.dwell_time_week || port.dwell_time_week == '-1') {
    port.dwell_time_week = 'N/A';
  }
  portPin.metadata = {
    icon: icon,
    name: port.name,
    wCode: port.warehouse_code,
    isPort: true,
    dwell_time_avg: port.dwell_time_avg,
    dwell_time_week: port.dwell_time_week
  };
  return portPin;
}

export function createFacilityPins(facilityLocations, Microsoft) {
  const facilityPins = []

  for (let i = 0; i < facilityLocations.length; i++) {
    const facility = facilityLocations[i];

    if (facility.hideInVision) {
      continue;
    }

    const pinOptions = {
      anchor: { x: 17, y: 17 },
      icon: iconHTML({ type: facility.imagePath })
      //TODO: consoliate facility pins across tenant and use facility.type to look up icon
    };
    const facilityPin = createPushPin(facility.lat, facility.lon, pinOptions, Microsoft);
    facilityPin.metadata = {
      icon: pinOptions.icon,
      isFacility: true,
      facilityKey: facility.name,
      facilityCode: facility.code,
      type: facility.type,
      identifiers: facility.identifiers
    };
    facilityPins.push(facilityPin);
  }

  return facilityPins;
}

function getRegionLocation(shipment) {
  let location = null;
  if (shipment.current_location_continent_center) {
    location = { lat: shipment.current_location_continent_center.lat, lon: shipment.current_location_continent_center.lon };
  } else if (shipment.current_location_country_center) {
    location = { lat: shipment.current_location_country_center.lat, lon: shipment.current_location_country_center.lon };
  } else if (shipment.current_location_state_center) {
    location = { lat: shipment.current_location_state_center.lat, lon: shipment.current_location_state_center.lon };
  }
  return location;
}

function createClusterPinSvg(count, buckets) {
  let statusColors = {
    'N/A': 'rgba(222,192,31,1)',
    'At Risk': 'rgba(222,192,31,1)',
    'On Time': 'rgba(123,159,27,1)',
    'Late': 'rgba(186,68,68,1)',
    'Early': 'rgba(46,153,224, 1)',
    'No Appointment': 'rgba(166,166,166, 1)'
  };

  let outlineWidth = 12;
  let radius = getRadius(count);
  let size = 2 * radius;
  let sectors = [];
  let l = size / 2;
  let a;
  let aRad;
  let z;
  let x;
  let y;
  let X;
  let Y;
  let R = 0;
  let drawFullCircle = false;
  let fullCircleKey;
  for (let key in buckets) {
    if (buckets.hasOwnProperty(key)) {
      let percent = buckets[key].doc_count / count;
      if (percent === 1) {
        drawFullCircle = true;
        fullCircleKey = buckets[key].key;
        break;
      }
      a = 360 * percent;
      let aCalc = (a > 180) ? 360 - a : a;
      let arc;
      aRad = aCalc * Math.PI / 180;
      z = Math.sqrt(2 * l * l - (2 * l * l * Math.cos(aRad)));
      if (aCalc <= 90) {
        x = l * Math.sin(aRad);
      } else {
        x = l * Math.sin((180 - aCalc) * Math.PI / 180);
      }
      y = Math.sqrt(z * z - x * x);
      Y = y;

      if (a <= 180) {
        X = l + x;
        arc = 0;
      } else {
        X = l - x;
        arc = 1;
      }
      sectors.push({
        color: statusColors[buckets[key].key],
        arc: arc,
        L: l,
        X: X,
        Y: Y,
        R: R
      });
      R = R + a;
    }
  }
  let svg = ['<svg xmlns="http://www.w3.org/2000/svg" width="' + size + '" height="' + size + '">'];
  svg.push('<defs>');
  svg.push('<style>');
  svg.push(clusterCss);
  svg.push('</style>');
  svg.push('</defs>');
  if (drawFullCircle) {
    let circle = '<circle xmlns="http://www.w3.org/2000/svg" cx="' + radius + '" cy="' + radius + '" fill="' + statusColors[fullCircleKey] + '" r="' + radius + '"></circle>';
    svg.push(circle);
  } else {
    sectors.forEach((sector) => {
      let path = '<path xmlns="http://www.w3.org/2000/svg" fill="' + sector.color + '" d="M' + sector.L + ',' + sector.L + ' L' + sector.L + ',0 A' +
        sector.L + ',' + sector.L + ' 0 ' + sector.arc + ',1 ' + sector.X + ', ' + sector.Y + ' z' +
        '" transform="' + 'rotate(' + sector.R + ', ' + sector.L + ', ' + sector.L + ')' + '"></path>';
      svg.push(path);
    });
  }
  let donut = '<circle xmlns="http://www.w3.org/2000/svg" cx="' + radius + '" cy="' + radius + '" fill="#ffffff" r="' + (radius - outlineWidth) + '"></circle>';
  svg.push(donut);
  let verticalOffset = window.SVGGraphicsElement ? '' : 'dy="5px"';
  let text = '<text xmlns="http://www.w3.org/2000/svg"  x="50%" y="50%" text-anchor="middle" font-family="Open Sans, Tahoma, Geneva, Verdana, sans-serif" font-size="13px" alignment-baseline="central" ' + verticalOffset + '>{text}</text>';
  svg.push(text);
  svg.push('</svg>');
  return [svg.join('').replace('{text}', (count)), radius];
}

function getRadius(count) {
  let minRadius = 18;
  return Math.log(count) / Math.log(10) * 5 + minRadius;
}

export function createCluster(buckets, isRegion, Microsoft) {
  if (isRegion && Microsoft) {
    return _.compact(buckets.map(function (itemBucket) {
      let location = getRegionLocation(itemBucket.top_item.hits.hits[0]._source);
      if (location) {
        let svgInfo = createClusterPinSvg(itemBucket.doc_count, itemBucket.status.buckets);
        let svg = svgInfo[0];
        let mid = svgInfo[1];
        let pin = createPushPin(location.lat, location.lon, { icon: svg, anchor: new Microsoft.Maps.Point(mid, mid) }, Microsoft);
        pin.metadata = {
          shipment: itemBucket,
          single: false,
          count: itemBucket.doc_count,
          isFacility: false,
          hashKey: itemBucket.box.buckets[0].key,
          related: false,
          facilityKey: null
        };
        return pin;
      }
    }));
  } else {
    if (buckets && buckets.status) {
      let svgString = createClusterPinSvg(buckets.doc_count, buckets.status.buckets);
      let radius = getRadius(buckets.doc_count);
      let pin = createPushPin(buckets.lat.value, buckets.lon.value, { icon: svgString[0], anchor: new Microsoft.Maps.Point(radius, radius) }, Microsoft);
      let related = buckets.vessel.buckets.length === 1 || buckets.flight.buckets.length === 1;
      pin.metadata = {
        shipment: buckets.top.hits.hits[0]._source,
        single: false,
        hashKey: buckets.key,
        related: related,
        isFacility: false,
        count: buckets.doc_count,
        facilityKey: null
      };
      return [pin];
    }
  }
}

function toRad(deg) {
  return deg * Math.PI / 180;
}

function toDeg(rad) {
  return rad * 180 / Math.PI;
}

function getAngle(origin, destination) {
  if (origin && destination) {
    const dLon = toRad(destination.lon - origin.lon);
    let y = Math.sin(dLon) * Math.cos(toRad(destination.lat));
    let x = Math.cos(toRad(origin.lat)) * Math.sin(toRad(destination.lat)) - Math.sin(toRad(origin.lat)) * Math.cos(toRad(destination.lat)) * Math.cos(dLon);
    let brng = toDeg(Math.atan2(y, x));
    return Math.abs(((brng + 360) % 360)) - 90;
  }
  return 0;
}

function mapPinIcon(template, angle, status) {
  const colorMap = {
    "On Time": '"#7B9F18"',
    "Late": '"#BE4E4D"',
    "Early": '"#0087E0"',
    "At Risk": '"#E6C61B"',
    "N/A": '"#E6C61B"',
    "Unknown": '"#8F8F8F"'
  };
  return template.replace('{angle}', angle).replace('{color}', colorMap[status]);
}

function createSinglePinSvg(status, mode, current_location, destination_location, hasGpsTracking) {
  let angle = getAngle(current_location, destination_location);
  let pin;
  let center;
  let lowerCaseMode = (mode || '').toLowerCase();
  switch (lowerCaseMode) {
    case 'ocean':
      pin = mapPinIcon(vessel, angle, status);
      center = 20;
      break;
    case 'air':
      pin = mapPinIcon(flight, angle, status);
      center = 16.5;
      break;
    case 'parcel':
    case 'truck':
    case 'truckload':
    case 'reefer':
    case 'ltl':
      if (hasGpsTracking) {
        pin = mapPinIcon(gps, angle, status);
        center = 12.5;
      } else {
        pin = mapPinIcon(shipment, angle, status);
        center = 6;
      }
      break;
    case 'intermodal':
      if (hasGpsTracking) {
        angle += 180;//flip due to icon direction

        //orientation
        let scaling = 1;
        if (angle <= 270 || angle >= 450) {
          scaling = -1;
        }
        pin = mapPinIcon(intermodal(scaling), angle, status);
        center = 17.5;
      } else {
        pin = mapPinIcon(shipment, angle, status);
        center = 6;
      }
      break;
    default:
      pin = mapPinIcon(shipment, 0.0, status);
      center = 6;
      break;
  }
  return { svg: pin, center: center };
}

export function createSingle(buckets, Microsoft, count) {
  return buckets.map(function (itemBucket) {
    let item = itemBucket._source;
    item.number_of_orders = count;
    let svg = createSinglePinSvg(item.status, item.mode, item.current_location, item.destination_location, item.live_tracked);
    let pin = createPushPin(item.current_location.lat, item.current_location.lon, { icon: svg.svg, anchor: new Microsoft.Maps.Point(svg.center, svg.center) }, Microsoft);
    pin.metadata = { shipment: item, single: true, count: itemBucket.top_item, isFacility: false, facilityKey: null };
    return pin;
  });
}

function createPushPin(lat, lon, options, Microsoft) {
  if (Microsoft) {
    return new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(lat, lon), {
      icon: options.icon,
      anchor: new Microsoft.Maps.Point(options.anchor.x, options.anchor.y)
    });
  }
}

export function getCurrentLocationPin(coords, Microsoft) {
  let svg = iconHTML({ type: 'current_location', style: { cursor: 'pointer' } });
  return createPushPin(coords.lat, coords.lon, { icon: svg, anchor: { x: 14, y: 14 } }, Microsoft);
}

export function getExpectedPositionPin(coords, Microsoft) {
  let svg = iconHTML({ type: 'expected_position', style: { cursor: 'pointer' } });
  let pin = createPushPin(coords.lat, coords.lon, { icon: svg, anchor: { x: 14, y: 14 } }, Microsoft);
  pin.metadata = {
    isExpectedPosition: true
  }
  return pin;
}

export function getIotAnomalyPin(stop, Microsoft) {
  const svg = iconHTML({ type: 'location_pin_red', style: { cursor: 'pointer' } });
  const pin = createPushPin(stop.latitude, stop.longitude, { icon: svg, anchor: { x: 14, y: 14 } }, Microsoft);

  pin.metadata = Object.assign({}, stop, { isAnomaly: true });
  return pin;
}

export function getIotDetailsPin(stop, Microsoft) {
  const iconColor = stop.isCurrentLocation ? 'current_location' : 'location_pin_green';
  const svg = iconHTML({ type: iconColor, style: { cursor: 'pointer' } });
  const pin = createPushPin(stop.latitude, stop.longitude, { icon: svg, anchor: { x: 14, y: 14 } }, Microsoft);

  pin.metadata = Object.assign({}, stop, { isIotDetails: true });
  return pin;
}

export function getTrackerPins(stop, Microsoft) {
  let svg;
  if (stop.stopType === 'P') {
    if (stop.stopCompleteDateTime) {
      svg = iconHTML({ type: 'pick-complete', style: { cursor: 'pointer' } });
    } else {
      svg = iconHTML({ type: 'pick-incomplete', style: { cursor: 'pointer' } });
    }
  } else if (stop.stopType === 'D') {
    if (stop.stopCompleteDateTime) {
      svg = iconHTML({ type: 'drop-complete', style: { cursor: 'pointer' } });
    } else {
      svg = iconHTML({ type: 'drop-incomplete', style: { cursor: 'pointer' } });
    }
  }

  const trackerPin = createPushPin(stop.coordinate.Lat, stop.coordinate.Lon, { icon: svg, anchor: { x: 14, y: 14 } }, Microsoft);
  trackerPin.metadata = Object.assign({}, stop, { isStopPin: true });
  return trackerPin;
}

export function createPolyLine(locations, options, Microsoft) {
  return new Microsoft.Maps.Polyline(locations.map(function (loc) {
    return new Microsoft.Maps.Location(loc.lat, loc.lon);
  }), options);
}

export function pinClicked(e, Microsoft, map, props) {
  let pinType = e.target.metadata;
  let zoomLevel = map.getZoom();
  let pinHeight;
  let pinAnchor;
  try {
    pinHeight = e.primitive.image.height;
    pinAnchor = e.primitive.anchor;
  } catch (error) {
    pinHeight = 0;
    pinAnchor = { x: 0, y: 0 }
  }

  let collection = map.entities._infoboxCollection;
  if (!_.isEmpty(collection)) {
    collection[0].setMap(null);
    collection.pop();
  }

  let infobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(0, 0), {
    visible: false
  });
  infobox.setMap(map);


  Microsoft.Maps.Events.addHandler(map, 'click', () => {
    infobox.setMap(null);
  });

  if (pinType.single) {
    let searchText;
    let pinLocation = map.tryLocationToPixel(e.target.getLocation(), Microsoft.Maps.PixelReference.control);
    let selectedShipment = e.target.metadata.shipment;
    let template = ReactDOMServer.renderToString(<ShipmentsPopover shipment={selectedShipment} jobMode={props.jobMode}/>);

    infobox.setOptions({
      location: e.target.getLocation(),
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, false).then(result => {
      infobox.setOptions({
        offset: result
      });

      let viewShipmentButton = document.getElementById('viewShipmentButton');
      //directs to the list view if multiple loads are linked to the same identifier, else direct to the shipment tracker.
      viewShipmentButton.addEventListener('click', () => {
        if (selectedShipment.number_of_orders > 1) {
          let lowerCaseMode = selectedShipment.mode.toLowerCase();
          switch (lowerCaseMode) {
            case 'air':
              searchText = `${selectedShipment.flight_carrier_code}~${selectedShipment.flight_plane_id}`;
              break;
            case 'ocean':
              searchText = selectedShipment.vessel_imo_number;
              break;
            case 'intermodal':
              searchText = selectedShipment.train_number;
              break;
            default:
              searchText = selectedShipment.origination == 4 ? selectedShipment.shipment_number : selectedShipment.load_number;
              break;
          };
          
          searchText = searchText ? searchText.toString() : searchText;
          getCreatedStore().dispatch(shipmentActions.changeSearchInput(searchText, null, props.location, props.history, 'listView'));
        } else {
          trackShipment(selectedShipment, props)
        }
      });

      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is single.") 
      console.error(err);
    });

  } else if (pinType.isPort) {
    let pinLocation = map.tryLocationToPixel(e.target.getLocation(), Microsoft.Maps.PixelReference.control);
    let selectedPort = pinType;
    let template = ReactDOMServer.renderToString(<PortPopover port={selectedPort} />);

    infobox.setOptions({
      location: e.target.getLocation(),
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, false).then(result => {
      infobox.setOptions({
        offset: result
      });
      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is port.") 
      console.error(err);
    });
  } else if (pinType.isAnomaly) {
    let pinLocation = map.tryLocationToPixel(e.target.getLocation(), Microsoft.Maps.PixelReference.control);
    let shipment = e.target.metadata;
    let template = ReactDOMServer.renderToString(
      <EventsPopover
        eventLabel={'IoT Tracked'}
        iconId={'iottrackedicon'}
        iconRef={'#iot'}
        shipment={shipment}
      />
    )

    infobox.setOptions({
      location: e.target.getLocation(),
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, true).then(result => {
      infobox.setOptions({
        offset: result
      });
      let viewShipmentButton = document.getElementById('viewShipmentButton');
      viewShipmentButton.addEventListener('click', () => { clusteredShipment(e.target.metadata.hashKey, props, infobox) });

      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is Anomaly.") 
      console.error(err);
    });
  } else if (pinType.isIotDetails) {
    let pinLocation = map.tryLocationToPixel(e.target.getLocation(), Microsoft.Maps.PixelReference.control);
    let shipment = e.target.metadata;
    let template = ReactDOMServer.renderToString(
      <EventsPopover
        eventLabel={'IoT Details'}
        iconId={'iottrackedicon'}
        iconRef={'#iot'}
        shipment={shipment}
      />
    )

    infobox.setOptions({
      location: e.target.getLocation(),
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, true).then(result => {
      infobox.setOptions({
        offset: result
      });
      let viewShipmentButton = document.getElementById('viewShipmentButton');
      viewShipmentButton.addEventListener('click', () => { clusteredShipment(e.target.metadata.hashKey, props, infobox) });

      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is Iot Details.") 
      console.error(err);
    });
  } else if (pinType.incidentId) {
    let pinLocation = e.point;
    let selectedIncident = pinType;
    let template = ReactDOMServer.renderToString(<IncidentsPopover incident={selectedIncident} />);

    infobox.setOptions({
      location: e.location,
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, true).then(result => {
      infobox.setOptions({
        offset: result
      });
      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is incident.") 
      console.error(err);
    });

  } else if (pinType.isExpectedPosition) {
    let pinLocation = e.point;
    let template = ReactDOMServer.renderToString(<ExpectedPositionPopover />);

    infobox.setOptions({
      location: e.location,
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, true).then(result => {
      infobox.setOptions({
        offset: result
      });
      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is Expected Position.") 
      console.error(err);
    });

  } else if (pinType.isFacility) {
    let pinLocation = map.tryLocationToPixel(e.target.getLocation(), Microsoft.Maps.PixelReference.control);
    let selectedFacility = pinType;

    apis.default.getFacilityShipments(selectedFacility).then(response => {
      let template = ReactDOMServer.renderToString(<FacilityPopover facility={selectedFacility} facilityShipments={response.data} />);

      infobox.setOptions({
        location: e.target.getLocation(),
        visible: true,
        htmlContent: template
      });

      calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, true).then(result => {
        infobox.setOptions({
          offset: result
        });

        let currentQueryParameters = {};
        if (props.location && props.location.search) {
          currentQueryParameters = queryString.parse(props.location.search);
        }

        let statusArr = ['atFacility', 'arrivingFacility', 'departingFacility'];

        statusArr.forEach(status => {
          let facilityStatusBtn = document.getElementById(status);
          facilityStatusBtn.addEventListener('click', () => {

            const facilityFilter = {
              facilityName: selectedFacility.facilityKey,
              facilityCode: selectedFacility.facilityCode,
              facilityIdentifiers: selectedFacility.identifiers,
              shipmentStatus: status,
              parsedFacilityStatus: shipmentMethods.parseShipmentStatus(status)
            };
            props.facilityFiltersChanged(facilityFilter);
          });
        });

        addCloseEventListener(infobox);
      });
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is facility.") 
      console.error(err);
    });

  } else if (zoomLevel < 6 && e.target.metadata.hashKey) {

    let geoHash = geohash.decode_bbox(e.target.metadata.hashKey);
    let hashRect = Microsoft.Maps.LocationRect.fromCorners(
      new Microsoft.Maps.Location(geoHash[0], geoHash[1]),
      new Microsoft.Maps.Location(geoHash[2], geoHash[3]));

    map.setView({
      center: hashRect.center,
      zoom: zoomLevel + 1
    });
  } else if (pinType.isStopPin) {
    let pinLocation = e.point;
    let selectedStop = pinType;
    let template = ReactDOMServer.renderToString(<StopPopover stop={selectedStop} />);

    infobox.setOptions({
      location: e.location,
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, true).then(result => {
      infobox.setOptions({
        offset: result
      });
      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset when pin type is stop pin.") 
      console.error(err);
    });
  } else {
    let pinLocation = map.tryLocationToPixel(e.target.getLocation(), Microsoft.Maps.PixelReference.control);
    let count = e.target.metadata.count;
    let template = ReactDOMServer.renderToString(<ClustersPopover count={count} jobMode={props.jobMode}/>);

    infobox.setOptions({
      location: e.target.getLocation(),
      visible: true,
      htmlContent: template
    });

    calcOffset(Microsoft.Maps.Point, pinLocation, pinHeight, pinAnchor, true).then(result => {
      infobox.setOptions({
        offset: result
      });
      let viewClustersButton = document.getElementById('viewClustersButton');
      viewClustersButton.addEventListener('click', () => { clusteredShipment(e.target.metadata.hashKey, props, infobox) });

      addCloseEventListener(infobox);
    }).catch((err) => {
      console.log("Error while calculating offset.")
      console.error(err);
    });
  }

  collection.push(infobox);
}

const trackShipment = (selectedShipment, props) => {
  const trackingType = commonMethods.determineTrackingType(selectedShipment);
  const trackingNumber = commonMethods.determineTrackingNumber(selectedShipment);

  props.history.push('/shipment-tracker/' + `${selectedShipment.id}` + `?loadNumber=${selectedShipment.load_number}` +
    `&orderNumber=${selectedShipment.customer_order_number}` + `&trackingType=${trackingType}` + `&trackingNumber=${trackingNumber}`);
};

export function clusteredShipment(hashKey, props, infobox) {
  const geoHash = geohash.decode_bbox(hashKey);
  const currentQueryParameters = {};
  const zoom = commonMethods.getFirstValidInteger(currentQueryParameters.zoom, props.zoom);
  const box = { 
    bottom_right: { lat: geoHash[0], lon: geoHash[3] },
    top_left: { lat: geoHash[2], lon: geoHash[1] }
  };

  infobox.setMap(null);
  getCreatedStore().dispatch(shipmentActions.selectCluster(box, zoom, props.location, props.history));
}

const addCloseEventListener = (infobox) => {
  let closeInfobox = document.getElementById('closeInfobox');
  closeInfobox && closeInfobox.addEventListener('click', () => { infobox.setMap(null) });
}