import React from 'react';

export default function ProgressToast(params) {

  return (
    <div id="progresstoast" className="toast-notification static success">
      <div className="toast-header">
        <strong>{params.title}</strong>
      </div>
      <div className="toast-body">
        <p>{params.message}</p>
      </div>
    </div >
  );
}
