import { fromJS } from 'immutable';
import * as _ from 'lodash';
import * as types from '../actionTypes';
import { getOffset } from '../../../../app/utils/helperMethods/shipmentMethods';
import { defaultShipmentSortBy } from '../../../components/shipments/ShipmentSortByOptions';

export const initialState = () => fromJS({
  zoom: 5,
  currentPage: 1,
  activeFilters: {},
  activeFacilityFilters: [],
  dateFilter: null,
  box: null,
  displayMode: 'listOnly',
  sort: defaultShipmentSortBy.value,
  offset: 0,
  clusterSelected: null,
  searchText: undefined,
  multiSearchType: undefined,
  incidentName: '',
  incidentId: null,
  liveTracked: null,
  filterSetLoaded: false
});

export function shipmentsQueryConfigurationReducer(state = initialState(), action = {}) {
  switch (action.type) {
    case types.SET_JOB_MODE:
      return initialState();
    case types.MAP_DETAILS_CHANGED:
      return state
        .set('zoom', action.payload.zoom)
        .set('box', action.payload.box);
    case types.SORT_CHANGED:
      return state
        .set('sort', action.payload.sort);
    case types.SEARCH_TEXT_CHANGED:
      return state
        .set('currentPage', 1)
        .set('displayMode', action.payload.displayMode ?? state.get('displayMode'))
        .set('searchText', action.payload.newSearchInput)
        .set('multiSearchType', action.payload.multiSearchType);
    case types.CHANGED_SHIPMENTS_FILTERS_UPDATE_CONFIGURATION:
      return state
        .set('currentPage', 1)
        .set('activeFilters', action.payload.filters);
    case types.CHANGED_SHIPMENTS_FACILITY_FILTERS_UPDATE_CONFIGURATION:
      return state
        .set('currentPage', 1)
        .set('displayMode', action.payload.displayMode ?? state.get('displayMode'))
        .set('activeFacilityFilters', action.payload.activeFacilityFilters);
    case types.CHANGED_SHIPMENTS_DATE_FILTER_UPDATE_CONFIGURATION:
      return state
        .set('currentPage', 1)
        .set('dateFilter', action.payload.filter)
        .set('sort', action.payload.sort);
    case types.CLEARED_SHIPMENTS_FILTERS:
      return state
        .set('activeFilters', {})
        .set('currentPage', 1)
        .set('activeFacilityFilters', [])
        .set('multiSearchType', null)
        .set('dateFilter', null)
        .set('searchText', null)
        .set('clusterSelected', false);
    case types.TOGGLE_SHIPMENT_DISPLAY_MODE:
      return state
        .set('displayMode', action.payload.display);
    case types.NAVIGATE_TO_SHIPMENT_FROM_EXCEPTION_CARD:
      return state
        .set('displayMode', 'listOnly')
        .set('currentPage', 1)
        .set('searchText', action.payload.exceptionDescription)
        .set('activeFilters', {})
        .set('activeFacilityFilters', [])
        .set('dateFilter', null)
        .set('incidentName', '')
        .set('incidentId', '');
    case types.NAVIGATE_TO_SHIPMENT_FROM_INCIDENT_CARD:
      return state
        .set('displayMode', 'listOnly')
        .set('currentPage', 1)
        .set('incidentName', action.payload.incidentName)
        .set('incidentId', action.payload.incidentId)
        .set('searchText', '')
        .set('activeFilters', { status: action.payload.status ?? [], incidents: [action.payload.incidentName] })
        .set('activeFacilityFilters', [])
        .set('dateFilter', null);
    case types.CURRENT_PAGE_CHANGED:
      return state
        .set('currentPage', action.payload.currentPage)
        .set('offset', getOffset(action.payload.currentPage));
    case types.CLUSTER_SELECTED:
      return state
        .set('clusterSelected', true)
        .set('box', action.payload.box)
        .set('zoom', action.payload.zoom)
        .set('currentPage', 1)
        .set('offset', getOffset(1));
    case types.MAP_CLUSTERING_REMOVED:
      return state
        .set('clusterSelected', false)
        .set('box', null)
        .set('zoom', initialState().get('zoom'));
    case types.NEW_QUERY_PARAMS_UPDATE_CONFIGURATION:
      return state
        .set('searchText', action.payload.newConfiguration.searchText)
        .set('zoom', action.payload.newConfiguration.zoom)
        .set('box', action.payload.newConfiguration.box)
        .set('activeFilters', action.payload.newConfiguration.activeFilters)
        .set('activeFacilityFilters', action.payload.newConfiguration.facilityFilters)
        .set('dateFilter', action.payload.newConfiguration.dateFilter)
        .set('clustering', action.payload.newConfiguration.clustering)
        .set('currentPage', action.payload.newConfiguration.currentPage)
        .set('displayMode', action.payload.newConfiguration.displayMode)
        .set('sort', action.payload.newConfiguration.sort)
        .set('offset', action.payload.newConfiguration.offset)
        .set('clusterSelected', action.payload.newConfiguration.clusterSelected)
        .set('searchText', action.payload.newConfiguration.searchText)
        .set('multiSearchType', action.payload.newConfiguration.multiSearchType)
        .set('incidentName', action.payload.newConfiguration.incidentName)
        .set('incidentId', action.payload.newConfiguration.incidentId);
    case types.FILTER_SET_LOADED:
      return state
        .set('activeFilters', action.payload.filters ?? {})
        .set('currentPage', 1)
        .set('offset', getOffset(1))
        .set('activeFacilityFilters', action.payload.facilityFilters ?? [])
        .set('dateFilter', action.payload.dateFilter)
        .set('filterSetLoaded', true);
    default:
      return state;
  }
}