import React from 'react';
import Comment from './Comment';
import { getLongDateLocal } from '../../utils/helperMethods/dateTimeMethods';

export default function CommentList(props){
  return (
      <div className="row">
        <div id='commentslist' className="col-sm-12">
          {
            props.comments.length > 0 ? props.comments.map((comment,index)=>{
              if(index === 0){
                  return <Comment id={`comment-${index}-message`} key={'commentslist'+index} {...comment} showNameAndImage={true} showDateTag={true}/>
              }else{
                const newDay = getLongDateLocal(props.comments[index - 1].createdDateTime) !== getLongDateLocal(comment.createdDateTime);
                const showName = props.comments[index - 1].displayName !== comment.displayName;
                return <Comment id={`comment-${index}-message`} key={'commentslist'+index} {...comment} showNameAndImage={showName} showDateTag={newDay}/>
              }
            }) : <h2><strong>Start a new conversation!</strong></h2>
          }
        </div>
      </div>
  )
}
