import React from 'react';
import { DatePicker } from '@chr/web-components-labs';

export default function CreateIncidentForm(props) {
  return (
    <div className="create-incident-container">
      <form onSubmit={props.handleSubmit}>
        <label className="incidents-label">Name</label>
        <input
          id="incidentName"
          type="text"
          className="form-control"
          placeholder="Name"
          onChange={props.updateName}
          value={props.name}
          maxLength={50}
          required
        ></input>

        <label className="incidents-label space-top">Description</label>
        <input
          id="incidentDescription"
          type="text"
          className="form-control"
          placeholder="Description"
          maxLength="500"
          onChange={props.updateDescription}
          value={props.description}
          required
        ></input>

        <div className="dateRange">
          <label className="incidents-label">Incident Date</label>
          <DatePicker
            id="incidentStartDate"
            className="form-control"
            selectedDate={props.startDate}
            onChange={e => props.onDateChange(e, 'startDate')}
          />
          <label className="incidents-label">Expiration Date</label>
          <DatePicker
            id="incidentEndDate"
            className="form-control"
            selectedDate={props.endDate}
            onChange={e => props.onDateChange(e, 'endDate')}
          />
        </div>

        <div className="space-bottom">
          <h2 className="border-bottom">Affected Location</h2>
          {props.currentDrawnIncident ? (
            <button
              id="incidentRemoveButton"
              className="space-bottom btn btn-outline btn-danger"
              onClick={props.clearDrawnIncident}
            >
              Remove Incident
            </button>
          ) : (
            <div id="incidentHelpTag" className="space-bottom incidents-label">
              Click once on the map to start creating a shape associated with
              this incident.<br></br>Double click to stop drawing.
            </div>
          )}
        </div>

        <div className="space-top">
          <button
            id="incidentSaveButton"
            type="submit"
            className="col-xs-6 btn btn-primary"
          >
            Save
          </button>
          <button
            id="incidentCancelButton"
            onClick={props.cancelCreation}
            className="col-xs-6 btn btn-outline"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
