import React from 'react'
import { connect } from 'react-redux'
import actions from './actions';
import queryString from 'query-string';
import LoadingIcon from '../../components/loading-icon';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions'
import { trackPageInfoEvent } from '../../utils/helperMethods/adobeAnalyticsHelper';

export class Downloads extends React.Component {
  componentDidMount() {
    trackPageInfoEvent(this.props.adobeTrack, "Download Page", ["Download Page"]);
  }

  downloadCSV(props) {
    if (!props.userProfile || !props.userProfile.permissions || !props.userProfile.permissions.includes('Exporting')) {
      props.triggerGlobalErrorAlert({
        title: "Insufficient Permissions",
        message: "You do not have the permissions necessary to download an export. Contact your administrator if you believe this is in error."
      });
      return;
    }

    if (props.location && props.location.search) {
      const queryParams = queryString.parse(props.location.search);
      let isVerified = false;
      if (queryParams.exportType && queryParams.fileName) {
        // new way - using okta token generated email
        if(props.userProfile.upn != null) {
          if(props.userProfile.upn.localeCompare(queryParams.userEmail, undefined, { sensitivity: 'base' }) === 0 ) {
            isVerified = true;
            props.getExportDownload(props.userProfile.upn, queryParams.exportType, queryParams.fileName);
          }
        } 
        // obsolete - delete once Exporting.API verification method is deployed
        if (!isVerified && queryParams.userEmail.toLowerCase() === props.userProfile.email.toLowerCase()) {
          isVerified = true;
          props.getExportDownload(queryParams.userEmail, queryParams.exportType, queryParams.fileName);
        } 
        
        if(!isVerified) {
          props.triggerGlobalErrorAlert({
            title: "Insufficient Permissions",
            message: "User attempted to download export belonging to another user email."
          })
        }
        return;
      }
    }
    // catch invalid parameters
    props.triggerGlobalErrorAlert({
      title: "Invalid Download Specified",
      message: "Could not determine the file to download.  You seem to have reached this page in error."
    });
  }

  render() {
    return (
      <div>
        <div className="downloads-header col-lg-12">
          <h1>Export Download</h1>
          <div>
            {this.props.loading || this.props.userLoading ? 
              <div className="centered" data-test="loadingDisplay">
                <LoadingIcon/>
              </div>
            : <button id="downloadCSVButton" 
                className="btn btn-primary" 
                onClick={() => this.downloadCSV(this.props)}>
                  Download CSV
              </button>
            }
          </div>
        </div>
      </div>
    )
  };
}

export const mapStateToProps = (state) => {
  return {
    loading: state.downloads.get('loading'),
    userProfile: state.users.get('userProfile'),
    userLoading: state.users.get('userLoading')
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    getExportDownload: (userEmail, exportType, fileName) => {
      return dispatch(actions.getExportDownload(userEmail, exportType, fileName))
    },
    triggerGlobalErrorAlert: (payload) => {
      return dispatch(globalSuccessErrorAlertActions.triggerGlobalErrorAlert(payload))
    }
  }
}

export const DownloadsContainer = connect(mapStateToProps, mapDispatchToProps)(Downloads);
