import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  loading: false,
  downloadUrl: null
});

function downloadsReducer(state = initialState, action={}) {
  switch (action.type) {
    case types.GET_EXPORT_DOWNLOAD_STARTED:
      return state
        .set('loading', true);
    case types.GET_EXPORT_DOWNLOAD_FAILED:
      return state
        .set('loading', false);
    case types.GET_EXPORT_DOWNLOAD_COMPLETED:
      return state
        .set('loading', false);
    default:
      return state;
  }
}

export default downloadsReducer;