async function calcOffset(point, pinLocation, pinSize, pinAnchor, largePin) {

  var element = await document.getElementsByClassName('map-popover');

  if (element.length > 0) {
    var screenWidth = window.innerWidth;
    let absoluteGap = 10;
    var infoBoxWidth = element[0].clientWidth;
    var infoBoxHeight = element[0].clientHeight;
    var iconSize = pinSize;

    var pinXOffset = pinSize / 2;
    var pinYOffset = pinSize / 2;

    //if (largePin) {
    //  iconSize = pinSize / 2;
    //  pinYOffset = iconSize;
    //  pinXOffset += 10;
    //}

    var xOffset;
    var yOffset;

    if (pinLocation.x < (infoBoxWidth / 2 + iconSize) && pinLocation.y < (infoBoxHeight + iconSize)) {
      xOffset = 0;
      yOffset = (-infoBoxHeight - pinSize);
      element[0].classList.add('upperLeft');
    } else if (pinLocation.x < (infoBoxWidth + iconSize) && pinLocation.y >= (infoBoxHeight + iconSize)) {
      xOffset = 0;
      yOffset = iconSize;
      element[0].classList.add('lowerLeft');
    } else if ((pinLocation.x > (screenWidth - iconSize - infoBoxWidth)) && pinLocation.y < (infoBoxHeight + iconSize)) {
      yOffset = (-infoBoxHeight - iconSize);
      xOffset = (-infoBoxWidth);
      element[0].classList.add('upperRight');
    } else if ((pinLocation.x > (screenWidth - iconSize - infoBoxWidth)) && pinLocation.y >= (infoBoxHeight + iconSize)) {
      xOffset = (-infoBoxWidth);
      yOffset = iconSize;
      element[0].classList.add('lowerRight');
    } else if (pinLocation.y < (infoBoxHeight + iconSize)) {
      xOffset = (-infoBoxWidth / 2) - (pinXOffset / 4);
      yOffset = (-infoBoxHeight - pinYOffset - absoluteGap);
      element[0].classList.add('topMiddle');
    } else {
      xOffset = (-infoBoxWidth / 2) - pinXOffset / 4;
      yOffset = pinYOffset + absoluteGap;
      element[0].classList.add('bottomMiddle');
    }
    return new point(xOffset, yOffset);
  }
}

export default calcOffset;
