import React, { useState } from 'react';
import Icon from '../../components/icon';
import './extras/styles.scss';
import { toCurrency, isGFS, getTrackingDetailLevel, isOcean, cubicFeetToCubicMeters, lbsToKgs } from '../../utils/helperMethods/commonMethods';
import {
  getShipmentItemTotal,
  getShipmentWithhandlingUnitsItemTotal
} from '../../utils/helperMethods/commonMethods';
import { showContainers, showParcels } from '../../utils/helperMethods/containerUtils';
import { connect } from 'react-redux';
import Button from '../../components/button/Button';
import './commodities.scss';
import baseModalActions from '../../containers/base-modal/actions';
import LogsTable from '../../components/LogsTable';
import { JOB_MODES, READABLE_PACKAGING_TYPES } from '../../utils/constants';
import { GenericPopOver } from '../../components/popovers/GenericPopover'; 

const renderCommodities = (commodities, jobMode, tenantId, handlingUnitPackageType) => {  
  if (commodities && commodities.length > 0) {       
    return commodities.map((commodity, i) => {
      return (
        <tr key={i} data-testid={`containerCommoditiesRowId${i}`}>
          <td id={`commodity-purchase_order_number${i}`}>{commodity.purchase_order_number || '\u2014'}</td>
          <td>
            {commodity.is_haz_mat === true ? (
              <Icon id="hazmat" type="hazmat" />
            ) : (
              ''
            )}{' '}
            {commodity.name || '\u2014'}
          </td>
          <td>{commodity.category || '\u2014'}</td>
          <td id={`commodity-sku${i}`}>{(commodity.sku && commodity.sku !== 'Unclassified') ? commodity.sku : '\u2014'}</td>
          <td id={`commodity-part_number${i}`}>
            {commodity.part_number || '\u2014'}
          </td>
          {jobMode === JOB_MODES.shipments &&
            <td id={`commodity-value${i}`}>
              {commodity.value ? toCurrency(commodity.value) : '\u2014'}
            </td>
          }
          <td id={`commodity-units${i}`}>
            {commodity.units
              ? Intl.NumberFormat().format(commodity.units)
              : '\u2014'}
          </td>
          {tenantId === 404 && (
            <td id={`commodity-pkg_type-$${i}`}>
              {commodity.packaging_type || '\u2014'}
            </td>
          )}
          <td id={`commodity-volume${i}`}>
            {commodity.volume
              ? tenantId == 404 ? 
                Intl.NumberFormat().format(cubicFeetToCubicMeters(commodity.volume, commodity.volume_unit_of_measure, false)) :
                Intl.NumberFormat().format(commodity.volume)
              : '\u2014'}
          </td>
          <td id={`commodity-weight${i}`}>
            {commodity.weight
              ? tenantId == 404 ? 
                Intl.NumberFormat().format(lbsToKgs(commodity.weight, commodity.weight_unit_of_measure, false)) :
                Intl.NumberFormat().format(commodity.weight)
              : '\u2014'}
          </td>
        </tr>
      );
    });
  }
};

const renderParcels = (parcels, jobMode, tenantId, handlingUnitPackageType) => {
  if (parcels && parcels.length > 0) {    
    return parcels.map((parcel, i) => {
      return (
        <tr key={i} data-testid={`containerparcelsRowId${i}`}>
          <td id={`parcel-purchase_order_number${i}`}>{parcel.purchase_order_number || '\u2014'}</td>
          <td>
            {parcel.name || '\u2014'}
          </td>
          <td>{parcel.category || '\u2014'}</td>
          <td id={`parcel-sku${i}`}>{(parcel.sku && parcel.sku !== 'Unclassified') ? parcel.sku : '\u2014'}</td>
          <td id={`parcel-part_number${i}`}>
            {parcel.part_number || '\u2014'}
          </td>
          {jobMode === JOB_MODES.shipments &&
            <td id={`parcel-value${i}`}>
              {parcel.value ? toCurrency(parcel.value) : '\u2014'}
            </td>
          }
          <td id={`parcel-units${i}`}>
            {parcel.units
              ? Intl.NumberFormat().format(parcel.units)
              : '\u2014'}
          </td>
          {tenantId === 404 && (
            <td id={`parcel-pkg_type-$${i}`}>
              {handlingUnitPackageType || '\u2014'}
            </td>
          )}
          <td id={`parcel-volume${i}`}>
            {parcel.volume
              ? tenantId == 404 ? 
                Intl.NumberFormat().format(cubicFeetToCubicMeters(parcel.volume, parcel.volume_unit_of_measure, false)):
                Intl.NumberFormat().format(parcel.volume)
              : '\u2014'}
          </td>
          <td id={`parcel-weight${i}`}>
            {parcel.weight
              ? tenantId == 404 ? 
                Intl.NumberFormat().format(lbsToKgs(parcel.weight, parcel.weight_unit_of_measure, false)):
                Intl.NumberFormat().format(parcel.weight)
              : '\u2014'}
          </td>
        </tr>
      );
    });
  }
};

const renderItems = (items, jobMode, tenantId) => {
  if (items && items.length > 0) {     
    return items.map((item, i) => {
      return (
        <tr key={i}>
          <td>
            {item.is_haz_mat === true ? <Icon id="hazmat" type="hazmat" /> : ''}{' '}
            {item.description || '\u2014'}
          </td>
          <td>
            {item.line_of_business && item.line_of_business !== 'null'
              ? item.line_of_business
              : '\u2014'}
          </td>
          <td>{item.product_name || '\u2014'}</td>
          <td>{(item.sku && item.sku !== "Unclassified") ? item.sku : '\u2014'}</td>
          <td>{item.part_number || '\u2014'}</td>
          {jobMode === JOB_MODES.shipments && <td>{item.commodity_value ? toCurrency(item.commodity_value) : '\u2014'}</td>}
          <td>{item.units ? Intl.NumberFormat().format(item.units) : '\u2014'}</td>
          {tenantId === 404 && (
            <td>
              {item.packaging_type || '\u2014'}
            </td>
          )}
          {tenantId === 404 && (
            <td>              
              {Intl.NumberFormat().format(cubicFeetToCubicMeters(item.packaging_volume, item.packaging_volume_unit_of_measure, false)) 
              || '\u2014'}
            </td>
          )}
        </tr>
      );
    });
  }
};

export const filterContainerEvents = (events, containerNumber) =>
  events.filter((event) => event.containerNumber === containerNumber);

const grossWeight = (handlingUnits) => 
  handlingUnits?.
    flatMap(h => h.commodities || [])?.
    reduce((acc, curr) => acc + lbsToKgs(curr.weight, curr.weight_unit_of_measure, false), 0)?.
    toFixed(2)

const grossWeightUnitOfMeasure = (handlingUnits) =>
  handlingUnits?.flatMap(h => h.commodities || [])?.find(f => !!f.weight_unit_of_measure)?.weight_unit_of_measure;

const StatusFieldShowCustomRule = (tenantId, shipment) => {
  //861919 Flex Remove Status and Last Free Date Fields
  const StatusCustomTenants = [404];   
  if (!StatusCustomTenants.includes(tenantId)) {
    return true;
  }
  else
  {
    return !isOcean(shipment)
  }  
};

const LdfFieldShowCustomRule = (tenantId, shipment) => {
  //861919 Flex Remove Status and Last Free Date Fields  
  const LdfCustomTenants = [404];   
  if (!LdfCustomTenants.includes(tenantId)) {
    return true;
  }
  else
  {   
    return !isOcean(shipment)
  }  
};

const DisplayTotalChargeableWeight = (tenantId, shipment) => { 
  var displayCW = tenantId === 404 && shipment.id.split('|')[1] !== 'Orders';  
  //Flex - Chargeable Weight should not display for Ocean FCL Shipments
  if(displayCW && shipment.mode === 'Ocean' && shipment.secondary_identifiers 
  && shipment.secondary_identifiers.filter(x => x.display_label === 'Service Level')[0]?.value === 'FCL'){    
    displayCW = false;
  }
  return displayCW;    
}

const DisplayChargeableWeight = (tenantId, shipment) => { 
  var displayCW = tenantId === 404 
  //Flex - Chargeable Weight should not display for Ocean FCL Shipments
  if(displayCW && shipment.mode === 'Ocean' && shipment.secondary_identifiers 
  && shipment.secondary_identifiers.filter(x => x.display_label === 'Service Level')[0]?.value === 'FCL'){    
    displayCW = false;
  }
  return displayCW;    
}

const CalculateChargeableWeight = (props) => {  
  // Calculates the weight in kgs and compares with volumetric weight to take the higher value
  const wuom = props.commodities?.find(f => !!f.weight_unit_of_measure)?.weight_unit_of_measure;
  var totalWeight = props.commodities ? 
  lbsToKgs(props.commodities.reduce((total, current) => total += (current.weight || 0), 0), wuom)
    : 0;  
  
  if(props.isGFS){    
    const vuom = props.commodities?.find(f => !!f.volume_unit_of_measure)?.volume_unit_of_measure;
    // Calculate Volumetric weight by taking the volume in Cubic Meters X 1000
    var volumetricWeight = props.commodities ? 
      cubicFeetToCubicMeters(props.commodities.reduce((total, current) => total += (current.volume || 0), 0), vuom, false) * 1000
      : 0;  
   
    // Compare the volumetric weight to the gross weight and take the greater value to display as the chargeable weight
    if(volumetricWeight > totalWeight){
      totalWeight = volumetricWeight;    
    }
  }

  return(
    <div className="commodities-value huchargeableweight">
      {totalWeight > 0?
        parseFloat(totalWeight).toFixed(2) + ' (kgs)'
        : '\u2014'}
    </div>
  );   
}

const DisplayNullChargeableWeight = (total_chargeable_weight) => {  
  if(total_chargeable_weight){
    return null;
  }
  else {
    return (
      <GenericPopOver title ="Chargeable Weight" details = "The carrier did not provide a Chargeable Weight for this shipment"/>
    );
  }  
}

export const Commodities = ({
  shipment,
  isCommoditiesActive,
  trackingLogs,
  showModal,
  jobMode,
  tenantId
}) => {
  
  return (
    <div
      className={
        isCommoditiesActive === 'active'
          ? 'tab-pane fade active in'
          : 'tab-pane fade'
      }
      id="commodities"
    >
      <div className="commodities-container">
        <div className="row">
          <div className="col-xs-12">
            <h5>Overview</h5>
            <div className="row commodities-overview">
              <div className="col-xs-4 col-lg-3 ">
                <div className="read-only-label">
                  Number of {showContainers(shipment) ? 'Containers' : showParcels(shipment) ? 'Packages' : 'Products'}
                </div>
                <div id={'container-count'} className="commodities-value">
                  {showSummaryCount(shipment)}
                </div>
              </div>
              <div className="col-xs-4 col-lg-2">
                <div className="read-only-label">Total Units</div>
                <div className="commodities-value">
                  {showContainers(shipment) || showParcels(shipment)
                    ? Intl.NumberFormat().format(
                        getShipmentWithhandlingUnitsItemTotal(
                          shipment.handling_units
                        )
                      )
                    : shipment.items &&
                      Intl.NumberFormat().format(
                        getShipmentItemTotal(shipment.items)
                      )}
                </div>
              </div>
              {jobMode === JOB_MODES.shipments && (
                <div className="col-xs-4 col-lg-2">
                  <div className="read-only-label">Total Value</div>
                  <div className="commodities-value">
                  {shipment.total_value ? toCurrency(shipment.total_value) : '\u2014'}
                    <span></span>
                  </div>
                </div>
              )}
              { DisplayTotalChargeableWeight(tenantId, shipment) && (
                <div className="col-xs-4 col-lg-2">
                  <div className="read-only-label">Total Chargeable Weight {DisplayNullChargeableWeight(shipment.total_chargeable_weight)}</div>
                  <div className="commodities-value" data-testid="tcw-commodity-value">
                    {                      
                      shipment.total_chargeable_weight && shipment.shipment_weight_unit_of_measure && shipment.shipment_weight_unit_of_measure !== 'Unknown'
                      ? lbsToKgs(shipment.total_chargeable_weight, shipment.shipment_weight_unit_of_measure) + ' ' + '(kgs)'
                      : '\u2014'
                    }
                  </div>
                </div>
              )}
              {tenantId === 404 && (
                <div className="col-xs-4 col-lg-2">
                  <div className="read-only-label">Total Gross Weight</div>
                  <div className="commodities-value">
                    {
                      grossWeight(shipment.handling_units) && grossWeightUnitOfMeasure(shipment.handling_units)
                      ? grossWeight(shipment.handling_units) + ' ' + '(kgs)'
                      : '\u2014'
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {showContainers(shipment) ? (
              <ContainerTables
                shipment={shipment}
                showModal={showModal}
                trackingLogs={trackingLogs}
                jobMode={jobMode}
                tenantId={tenantId}
              />
            )
              :
              (showParcels(shipment)) ?
                (<ParcelTables
                  shipment={shipment}
                  jobMode={jobMode}
                  showModal={showModal}
                  trackingLogs={trackingLogs}
                  tenantId={tenantId}
                />)
                :
                (
                  <ItemsTable shipment={shipment} jobMode={jobMode} tenantId={tenantId} />
                )}
          </div>
        </div>
      </div>
    </div>
  );
}

const showSummaryCount = (shipment) => {
  if (showContainers(shipment)) {
    if (shipment.container_information && shipment.container_information.length) {
      return [
        ...new Set(
          shipment.container_information.map(
            (container_information) => container_information.container_number
          )
        )
      ].length;
    } else if (shipment.handling_units && shipment.handling_units.length) {
      return [
        ...new Set(
          shipment.handling_units.map(
            (handling_units) => handling_units.container_number
          )
        )
      ].length;
    }
  }
  else if (showParcels(shipment)) {
    return [
      ...new Set(
        shipment.handling_units.map(
          (handling_units) => handling_units.package_tracking_number
        )
      )
    ].length;
  }
  else if (shipment && shipment.items && shipment.items.length > 0) {
    return shipment.items.length;
  } else {
    return '\u2014';
  }
};

export const mapReadablePackagingType = (packagingType) =>
  READABLE_PACKAGING_TYPES[packagingType];

export const mapContainerNumberLevel = (tenantId, shipment, packageOrContainerType, containerNumber) => {  
  
  if(tenantId == 404 && isOcean(shipment)) {
    return `${packageOrContainerType} Container #: ${containerNumber}`
  }  
  else {     
    return `${mapReadablePackagingType(packageOrContainerType) || 'Container'} #: ${containerNumber || 'N/A'}`
  }  
}

export const mapContainerNumber = (tenantId, shipment, containerNumber) => {
  // 862180 - Container Type Label incorrectly displaying Pallets based on packaging type
  if(tenantId == 404 && isOcean(shipment) && !containerNumber){
    return "-"
  }
  else{
    return containerNumber
  }  
}

export const ContainerTables = ({ shipment, showModal, trackingLogs, jobMode, tenantId }) => {
  // Need to either use the container_inforamtion array if it's populated, otherwise use the handling_units array.
  let containerNumberSource;  
  if (shipment.container_information && shipment.container_information.length) {
    containerNumberSource = shipment.container_information;
  } else if (shipment.handling_units && shipment.handling_units.length) {
    containerNumberSource = shipment.handling_units;
  } else {
    containerNumberSource = [];
  }

  const areTablesVisibleInit = containerNumberSource.reduce((total, value) => {
    total[value.container_number] = true;
    return total;
  }, {});

  const [areTablesVisible, setAreTablesVisible] = useState(
    areTablesVisibleInit
  );

  let handlingUnitsJoinedWithContainerInformation = [];

  //if container_information is populated, map to a new object for the purposes of rendering this data
  if (shipment.container_information && shipment.container_information.length) {
    
    handlingUnitsJoinedWithContainerInformation = shipment.container_information.map(
      (container_information) => {
        let handlingUnitOrContainer = {
          containerNumber: container_information.container_number,
          commodities: [],
          packageOrContainerType: container_information.container_type,
          lastFreeDayforEmptyReturn: container_information.last_free_day_for_empty_return,
        };
        return handlingUnitOrContainer;
      }
    );
  }
  //if container_information is not populated, default to existing values in the handling_unit
  else {    
    handlingUnitsJoinedWithContainerInformation = shipment.handling_units.map(
      (handling_unit) => {
        let handlingUnitOrContainer = {
          containerNumber: mapContainerNumber(tenantId, shipment, handling_unit.container_number),
          commodities: [], //initially empty, will be populated in later step
          packageOrContainerType: handling_unit.packaging_type,
          weight: handling_unit.weight,
          weight_unit_of_measure: handling_unit.weight_unit_of_measure
        };
        return handlingUnitOrContainer;
      }
    );
  }
  let uniqueHandlingUnitsOrContainers = [];
  //find unique container numbers
  for (var handlingUnitOrContainer of handlingUnitsJoinedWithContainerInformation) {
    if (
      !uniqueHandlingUnitsOrContainers.find(
        (o) => o.containerNumber == handlingUnitOrContainer.containerNumber
      )
    ) {      
      uniqueHandlingUnitsOrContainers.push(handlingUnitOrContainer);
    }
  }
  //group commodities with same container number together
  for (var handlingUnitOrContainer of uniqueHandlingUnitsOrContainers) {
    
    for (var handling_unit of shipment.handling_units) {
      if (
        handlingUnitOrContainer.containerNumber ==
        handling_unit.container_number
      ) {
        
        handlingUnitOrContainer.status = handling_unit.status;
        handlingUnitOrContainer.commodities = handlingUnitOrContainer.commodities.concat(
          handling_unit.commodities
        );
        handlingUnitOrContainer.handlingUnitPackageType = handling_unit.packaging_type
        //Logic for weight when is null        
        handlingUnitOrContainer.weight = handling_unit.weight ??
          (tenantId == 404) ? 
            grossWeight([handling_unit])
            : handling_unit.commodities?.reduce((a, v) => a += (v.weight || 0) , 0);          
        handlingUnitOrContainer.weight_unit_of_measure = handling_unit.weight_unit_of_measure?? 
          handling_unit.commodities?.find(f => !!f.weight_unit_of_measure)?.weight_unit_of_measure;
      }
    }
  }

   

  return uniqueHandlingUnitsOrContainers.map((handlingUnitOrContainer, i) => {
    const {
      containerNumber,
      commodities,
      packageOrContainerType,
      status,
      lastFreeDayforEmptyReturn,
      handlingUnitPackageType,
      weight,
      weight_unit_of_measure
    } = handlingUnitOrContainer;    

    return (
      <React.Fragment key={containerNumber}>
        <div className="card" style={{ marginTop: '20px' }}>
          <div
            className="row container-headers"
          >
            <div className="col-md-4">
              <h5
                data-testid={`handlingUnitNumberLabel${i + 1}`}
                id={`container-${i}-number`}
              >{`${mapContainerNumberLevel(tenantId, shipment, packageOrContainerType, containerNumber)}`}</h5>
              <Icon
                type="chevron-up"
                data-testid={`expandContainerDetailsBtn${i + 1}`}
                style={{
                  transform: areTablesVisible[containerNumber]
                    ? null
                    : 'rotate(180deg)',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setAreTablesVisible({
                    ...areTablesVisible,
                    [containerNumber]: !areTablesVisible[containerNumber]
                  });
                }}
              />
            </div>
            <div className="col-md-4">
            { DisplayChargeableWeight(tenantId, shipment) && (
                <>
                  <div className="read-only-label">Chargeable Weight</div>
                  <CalculateChargeableWeight commodities={commodities} isGFS ={isGFS(shipment)}/>                  
                </>
              )}
              {isGFS(shipment) && StatusFieldShowCustomRule(tenantId, shipment) && <div className="lfdContainerStatus">
                <div className="read-only-label">
                  Status
                </div>
                <div className="read-only-value status">
                  {status ? status : '\u2014'}
                </div>
              </div>}
              {isGFS(shipment) && LdfFieldShowCustomRule(tenantId, shipment) && <div className="lfdDateField">
                <div className="read-only-label">
                  LFD:
                </div>
                <div className="read-only-value lfd">
                  {lastFreeDayforEmptyReturn ? lastFreeDayforEmptyReturn : '\u2014'}
                </div>
              </div>}
            </div>
            {showContainers(shipment) &&
              containerNumber != null &&
              containerNumber != '' && (
                <div className="col-md-4">
                  <Button
                    className="btn-container-tracking btn btn-primary"
                    text="Container Tracking"
                    onClick={() =>
                      showModal({
                        showModal: true,
                        body: (
                          <React.Fragment>
                            <p>Container #: {containerNumber}</p>
                            <LogsTable
                              trackingType="NonNav"
                              logs={filterContainerEvents(
                                trackingLogs,
                                containerNumber
                              )}
                            />
                          </React.Fragment>
                        ),
                        title: 'Container Tracking History',
                        size: 'large'
                      })
                    }
                  /></div>
              )}
          </div>
          {areTablesVisible[containerNumber] && (
            <table className="table table-striped">
              <thead>
                <tr id="blablah">
                  <th>PO #</th>
                  <th>Item Name</th>
                  <th>Product</th>
                  <th>SKU</th>
                  <th>Part</th>
                  {jobMode === JOB_MODES.shipments && <th>Value</th>}
                  <th>QTY</th>
                  {tenantId === 404 && <th>PKG Type</th>}
                  <th>Volume {commodities.length ? tenantId == 404 ? '(m³)': commodities[0].volume_unit_of_measure : null}</th>
                  <th>Weight {commodities.length ? tenantId == 404 ? '(kgs)': commodities[0].weight_unit_of_measure : null}</th>
                </tr>
              </thead>

              <tbody
                style={{
                  visibility: areTablesVisible[containerNumber]
                    ? null
                    : 'collapse'
                }}
              >
                {renderCommodities(commodities, jobMode, tenantId, handlingUnitPackageType)}
              </tbody>
            </table>
          )}
        </div>
      </React.Fragment>
    );
  });
};

export const ParcelTables = ({ shipment, jobMode, showModal, trackingLogs, tenantId }) => {
  const areTablesVisibleInit = shipment.handling_units.reduce((total, value) => {
    total[value.package_tracking_number] = true;
    return total;
  }, {});

  const [areTablesVisible, setAreTablesVisible] = useState(
    areTablesVisibleInit
  );

  return shipment.handling_units.map((handling_unit, i) => {

    let packageTrackingNumber = handling_unit.package_tracking_number;
    let commodities = handling_unit.commodities;

    return (
      <React.Fragment key={packageTrackingNumber}>
        <div className="card" style={{ marginTop: '20px' }}>
          <div
            className="row container-headers"
          >
            <div className="col-md-4">
              <h5
                data-testid={`handlingUnitNumberLabel${i + 1}`}
                id={`parcel-${i}-number`}
              >{`Package #: ${packageTrackingNumber || 'N/A'}`}</h5>
              <Icon
                type="chevron-up"
                data-testid={`expandContainerParcelBtn${i + 1}`}
                style={{
                  transform: areTablesVisible[packageTrackingNumber]
                    ? null
                    : 'rotate(180deg)',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setAreTablesVisible({
                    ...areTablesVisible,
                    [packageTrackingNumber]: !areTablesVisible[packageTrackingNumber]
                  });
                }}
              />
            </div>

            <div className="col-md-4">
              {tenantId === 404 && (
                <>
                  <div className="read-only-label">Chargeable Weight</div>
                  <div className="commodities-value">
                    {
                      shipment.total_chargeable_weight && shipment.shipment_weight_unit_of_measure && shipment.shipment_weight_unit_of_measure !== 'Unknown'
                      ? lbsToKgs(shipment.total_chargeable_weight, shipment.shipment_weight_unit_of_measure) + ' ' + '(kgs)'
                      : '\u2014'
                    }
                  </div>
                </>
              )}
            </div>

            <div className="col-md-4">
              <Button
                className="btn-container-tracking parcel btn btn-primary"
                data-testid="parcelTrackingBtn"
                text="Parcel Tracking"
                onClick={() =>
                  showModal({
                    showModal: true,
                    body: (
                      <React.Fragment>
                        <p>Parcel Tracking #: {packageTrackingNumber}</p>
                        <LogsTable
                          trackingType="NonNav"
                          logs={filterParcelEvents(
                            trackingLogs,
                            packageTrackingNumber
                          )}
                        />
                      </React.Fragment>
                    ),
                    title: 'Parcel Tracking History',
                    size: 'large'
                  })
                }
              /></div>
          </div>
          {areTablesVisible[packageTrackingNumber] && (
            <table className="table table-striped">
              <thead>
                <tr id="blablah">
                  <th>PO #</th>
                  <th>Item Name</th>
                  <th>Product</th>
                  <th>SKU</th>
                  <th>Part</th>
                  {jobMode === JOB_MODES.shipments && <th>Value</th>}
                  <th>QTY</th>
                  {tenantId === 404 && <th>PKG Type</th>}
                  <th>Volume {commodities.length ? tenantId == 404 ? '(m³)': commodities[0].volume_unit_of_measure : null}</th>
                  <th>Weight {commodities.length ? tenantId == 404 ? '(kgs)': commodities[0].weight_unit_of_measure : null}</th>
                </tr>
              </thead>

              <tbody
                style={{
                  visibility: areTablesVisible[packageTrackingNumber]
                    ? null
                    : 'collapse'
                }}
              >
                {renderParcels(commodities, jobMode, tenantId, handling_unit.packaging_type)}
              </tbody>
            </table>
          )}
        </div>
      </React.Fragment>
    );
  });
};

export const filterParcelEvents = (events, packageTrackingNumber) =>
  events.filter((event) => event.trackingNumber === packageTrackingNumber);

export const ItemsTable = ({ shipment, jobMode, tenantId }) => {
  return (
    <React.Fragment>
      <div
        className="container-headers"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}
      >
        <div style={{ display: 'flex' }}>
          <h5 style={{ marginBottom: '0px', marginRight: '10px' }}>
            Item Details
          </h5>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr id="aa">
            <th>Item Name</th>
            <th>Line of Business</th>
            <th>Product</th>
            <th>SKU</th>
            <th>Part</th>
            {jobMode === JOB_MODES.shipments && <th>Value</th>}
            <th>QTY</th>
            {tenantId === 404 && <th>PKG Type</th>}
            {tenantId === 404 && <th>Volume (m³)</th>}
          </tr>
        </thead>

        <tbody>{renderItems(shipment.items, jobMode, tenantId)}</tbody>
      </table>
    </React.Fragment>
  );
};

export const mapStateToProps = (state, ownProps) => {
  const detailLevel = getTrackingDetailLevel(ownProps.location.pathname);
  const jobMode = detailLevel === 'orderTrackingSummary' ? JOB_MODES.orders : JOB_MODES.shipments
  return {
    shipment: state[detailLevel].get('fetchedShipment'),
    loads: state[detailLevel].get('loads'),
    trackingLogs: state[detailLevel].get('trackingLogs'),
    jobMode
  };
};

export const mapDispatchToProps = (dispatch) => ({
  showModal: (modalConfig) => dispatch(baseModalActions.openModal(modalConfig))
});

export default connect(mapStateToProps, mapDispatchToProps)(Commodities);
