import { put, call, takeEvery, all } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import { averagesSensorFormatter, sensorFormatter } from './sensorFormatter';
import globalSuccessErrorAlertActions from '../global-success-error-alerts/actions';

export function* fetchIotDetailRecordsStart({ payload }) {
  try {
    let logsResponse = yield call(api.iot.fetchIotDetailRecords, payload);
    const userProfileSettings = JSON.parse(window['localStorage'].getItem('userProfile')).settings;
    // TODO: Verify this function
    const formattedResults = sensorFormatter(logsResponse.data.results, userProfileSettings);

    const formattedResponse = {
      total: logsResponse.data.total,
      results: formattedResults
    }

    yield put(actions.fetchIotDetailRecordsCompleted(formattedResponse));
  } catch (e) {
    yield all([
      put(actions.fetchIotDetailRecordsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: "Iot Detail Records Call Failed",
        message: `Failed to return Iot Detail Records for device: #${payload.trackingNumber}`,
        error: e
      }))
    ]);
  }
};

export function* watchFetchIotDetailRecordsStart() {
  yield takeEvery(types.FETCH_IOT_DETAIL_RECORDS_STARTED, fetchIotDetailRecordsStart);
}

export function* fetchIotMapLogsStart({ payload }) {
  try {
    let logsResponse = yield call(api.iot.fetchIotMapLogs, payload);
    const userProfileSettings = JSON.parse(window['localStorage'].getItem('userProfile')).settings;
    const formattedResponse = sensorFormatter(logsResponse.data, userProfileSettings);

    const aggregatedResponse = formattedResponse.reduce((res, item) => {
      const itemKey = `${item.latitude}|${item.longitude}`;

      const resItem = res[itemKey] || {
        accuracy: item.accuracy,
        latitude: item.latitude,
        longitude: item.longitude,
        latestUpdateTimestamp: item.timestamp,
        metricsCount: 0,
        anomaliesCount: 0,
        latestMetrics: item.metrics
      };
      
      resItem.anomaliesCount += item.metrics.filter(x => !!x.isAnomaly).length;
      resItem.metricsCount += 1;

      if (new Date(item.timestamp) > new Date(resItem.latestUpdateTimestamp)) {
        resItem.accuracy = item.accuracy;
        resItem.latestUpdateTimestamp = item.timestamp;
        resItem.latestMetrics = item.metrics;
      }

      res[itemKey] = resItem;

      return res;
    }, {});

    yield put(actions.fetchIotMapLogsCompleted(Object.values(aggregatedResponse)));
  } catch (e) {
    yield all([
      put(actions.fetchIotMapLogsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: "Iot Map Logs Call Failed",
        message: `Failed to return Iot Map Logs for device: #${payload.trackingNumber}`,
        error: e
      }))
    ]);
  }
};

export function* watchFetchIotMapLogsStart() {
  yield takeEvery(types.FETCH_IOT_MAP_LOGS_STARTED, fetchIotMapLogsStart);
}

export function* fetchIotAveragesStart({ payload }) {
  try {
    let logsResponse = yield call(api.iot.fetchIotAverages, payload);
    const userProfileSettings = JSON.parse(window['localStorage'].getItem('userProfile')).settings;
    const formattedResponse = averagesSensorFormatter(logsResponse.data, userProfileSettings);

    yield put(actions.fetchIotAveragesCompleted(formattedResponse));
  } catch (e) {
    yield all([
      put(actions.fetchIotAveragesFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: "Iot Averages Call Failed",
        message: `Failed to return device averages for device: #${payload.trackingNumber}`,
        error: e
      }))
    ]);
  }
};

export function* watchFetchIotAveragesStart() {
  yield takeEvery(types.FETCH_IOT_AVERAGES_STARTED, fetchIotAveragesStart);
}

export function* fetchIotSensorLocationsStart({ payload }) {
  try {
    let response = yield call(api.iot.fetchIotSensorLocations, payload);

    var locationsLine = {
      class: 'Polyline',
      locations: [],
      color: '#4A4A4A',
      lineType: 'solid'
    };

    if (response.data && response.data.length > 1) {
      response.data.forEach(point => {
        locationsLine.locations.push({
          accuracy: point.accuracy,
          lat: point.latitude,
          lon: point.longitude
        });
      });
    }

    yield put(actions.fetchIotSensorLocationsCompleted(locationsLine));
  } catch (e) {
    yield all([
      put(actions.fetchIotSensorLocationsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: "Iot Sensor Locations Call Failed",
        message: `Failed to return device sensor locations for shipment: #${payload.trackingNumber}`,
        error: e
      }))
    ]);
  }
};

export function* watchFetchIotSensorLocationsStart() {
  yield takeEvery(types.FETCH_IOT_SENSOR_LOCATIONS_STARTED, fetchIotSensorLocationsStart);
}

export function* fetchActiveIotShipmentsStart({payload}) {
  try {
    let response = yield call(api.iot.fetchActiveIotShipments, { limit: payload.limit, afterKey: payload.afterKey })

    let activeShipments = {
      shipments: [],
      total: 0,
    };
    let afterKey;

    if (response.data && response.data.total > 0) {
      activeShipments.shipments = response.data.shipments;
      activeShipments.total = response.data.total;
      afterKey = response.data.afterKey
    }

    yield put (actions.fetchActiveIotShipmentsCompleted({
      activeShipments,
      afterKey,
      pageNumber: payload.pageNumber
    }));
  } catch (e) {
    yield all([
      put(actions.fetchActiveIotShipmentsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: "Iot Tracked Shipments Call Failed",
        message: `Failed to return Iot Tracked Shipments`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchActiveIotShipmentsStart() {
  yield takeEvery(types.FETCH_ACTIVE_IOT_SHIPMENTS_STARTED, fetchActiveIotShipmentsStart);
}
