import { fromJS } from 'immutable';
import * as types from './actionTypes';
import { DEFAULT_EXPORT_SELECTIONS } from './../../utils/constants';

export const initialState = fromJS({
  exportSelections: []
});

function exportReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_EXPORT_SELECTIONS_COMPLETED:
      return state
        .set('exportSelections', action.payload.data[0].exportSelections);
    case types.FETCH_DEFAULT_EXPORT_SELECTIONS:
      return state
        .set('exportSelections', DEFAULT_EXPORT_SELECTIONS);
    case types.RESET_PROPS:
      return initialState;
    default:
      return state;
  }
}

export default exportReducer;
