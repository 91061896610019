import React from 'react';
import { v4 as uuidv4 } from "uuid";
import Icon from '../../../components/icon';
import { Panel, PanelGroup } from "react-bootstrap";

export default class FacilityFilter extends React.Component {

  state = {
    activeFacility: '',
    searchText: ''
  };

  handleFaciltyToggle = (facilityCode) => {
    this.setState(Object.assign(this.state, { activeFacility: this.state.activeFacility === facilityCode ? false : facilityCode }));
  };

  handleFilterSearchChange = (e) => {
    this.setState({
      searchText: e.target.value
    });
  };

  containsSearchText = (searchText, target) => {
    let normalizedTarget = target.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    let normalizedSearchText = searchText.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    return normalizedTarget.includes(normalizedSearchText) || target.includes(searchText);
  };

  render() {
    return (
      <ul className="list-group" >
        <PanelGroup id="accordion-controlled-example">
          <Panel>
            <Panel.Heading>
              <Panel.Title toggle> All Facilities </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              {
                (this.props.facilityLocations && this.props.facilityLocations.length >= 10) ?
                <div className="form-group">
                  <input id="searchFacilityFilter" className="form-control" type="search" autoComplete="off"
                    value={this.state.searchText}
                    onChange={(e) => {this.handleFilterSearchChange(e)}}
                    defaultValue={this.state.searchText ? this.state.searchText : ''}
                    placeholder="Search"
                  />
                </div> : null
              }
              {(this.props.facilityLocations && this.props.facilityLocations.length > 0) ?
                this.props.facilityLocations.map(facility => {
                  if(this.state.searchText === '' || this.containsSearchText(this.state.searchText, facility.name)) {
                    return (
                      <div key={uuidv4()}>
                        <PanelGroup id="facility-filter-panel">
                          <Panel
                            expanded={this.state.activeFacility === facility.code} onToggle={() => { }}>
                            <Panel.Heading>
                              <Panel.Title
                                toggle
                                onClick={() => this.handleFaciltyToggle(facility.code)}>
                                <span ><Icon style={{ 'float': 'right' }} type={this.state.activeFacility === facility.code ? "chevron-down" : "chevron-right"} /></span>
                                <p title={facility.name} className="overflow">{facility.name}</p>
                              </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body collapsible>
                              <div className="list-item">
                                <label>
                                  <input type="checkbox" checked={facility.atFacility} onChange={() => { this.props.handleFacilityFilterChange(facility, 'atFacility') }} />
                                  <p>At Facility</p>
                                </label>
                                <label>
                                  <input type="checkbox" checked={facility.arrivingFacility} onChange={() => { this.props.handleFacilityFilterChange(facility, 'arrivingFacility') }} />
                                  <p>Inbound</p>
                                </label>
                                <label>
                                  <input type="checkbox" checked={facility.departingFacility} onChange={() => { this.props.handleFacilityFilterChange(facility, 'departingFacility') }} />
                                  <p>Outbound</p>
                                </label>
                              </div>
                            </Panel.Body>
                          </Panel>
                        </PanelGroup>
                      </div>
                    );
                }}) : <p className="center"> No Options </p>}
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </ul>
    );
  };
};
