import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';

export default {
  async fetchConversation(conversationId) {
    const config = await getConfiguration();
    const endpoint = config.conversationsApiEndpoint;

    try {
      const request = await clientFactory(endpoint);
      const result = await request.get(`conversations/${conversationId}`);
			return result;
    } catch (err) {
      throw `Error getting conversations ${err}`;
    }
  }
};
