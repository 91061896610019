import * as types from './actionTypes';

export default {
  loadAuthClient(authClient, productUrl) {
    return {
      type: types.LOAD_AUTH_CLIENT,
      payload: {
        authClient: authClient,
        productUrl: productUrl
      }
    };
  },
  setAuthed(authed, wasAuthed) {
    return {
      type: types.SET_AUTHED,
      payload: {
        authed,
        wasAuthed
      }
    }
  },
  trackLogin(trackLogin) {
    return {
      type: types.TRACK_LOGIN,
      payload: trackLogin
    }
  },
  updateTokens() {
    return {
      type: types.UPDATE_TOKENS_STARTED
    };
  },
  updateTokensCompleted(accessToken, idToken) {
    return {
      type: types.UPDATE_TOKENS_COMPLETED,
      payload: {
        accessToken, idToken
      }
    };
  },
  updateTokensFailed() {
    return {
      type: types.UPDATE_TOKENS_FAILED
    };
  }
};
