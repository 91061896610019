import React from 'react';
import { determineShipmentNumber } from "../../utils/helperMethods/commonMethods";

export default function IotShipmentsTable(props) {

  const determineAnomaliesClass = (value) => {
    let textClass = 'iot-dashboard-anomalies-green-text';

    if (value > 0) {
      textClass = 'iot-dashboard-anomalies-red-text';
    }

    return textClass
  }

  const  renderIotItems = (props) => {
    const lineItems = [];

    props.iotShipments.forEach((currentShipment, index) => {
      lineItems.push(
        <tr data-test="iotShipment" key={currentShipment.id}>
          <td>{determineShipmentNumber(currentShipment) || '\u2014'}</td>
          <td>{currentShipment.flow}</td>
          <td>{currentShipment.origin_city}</td>
          <td>{currentShipment.origin_Sched_date_close}</td>
          <td>{currentShipment.pickup_date}</td>
          <td>{currentShipment.destination_city}</td>
          <td>{currentShipment.destination_Sched_date_close}</td>
          <td>{currentShipment.calculated_ETA}</td>
          <td>{currentShipment.calculated_ETA_source}</td>
          <td>{currentShipment.status} </td>
          <td className={determineAnomaliesClass(currentShipment.anomalies)}>{currentShipment.anomalies}</td>
          {props.userPermissions && props.userPermissions.includes('ViewShipmentTracker') &&
          <td><button onClick={() => { props.openInShipmentTracker(currentShipment)}} data-test="trackShipment"
            className="btn btn-primary btn-sm">Track Shipment</button></td>
          }
        </tr>
      );
    });

    return lineItems;
  }

  return (
    <div>
      {(!props.iotShipments || !props.iotShipments.length) ? <p data-test="noIotShipments">No IoT Tracked Devices Available</p> : (
      <table className="table table-striped tracking-history-table">
        <thead>
          <tr>
            <th colSpan="100%">
              <h2>Active Iot Shipments</h2>
            </th>
          </tr>
          <tr>
            <th>Shipment #</th>
            <th>Flow</th>
            <th>Origin Location</th>
            <th>Origin Scheduled</th>
            <th>Origin Complete</th>
            <th>Destination Location</th>
            <th>Destination Scheduled</th>
            <th>ETA</th>
            <th>ETA Source</th>
            <th>Status</th>
            <th>Anomalies</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {renderIotItems(props)}
        </tbody>
      </table>
      )}
    </div>
  );
}
