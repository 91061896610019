import * as types from './actionTypes';

export default {
  fetchTenantDynamicFilters(payload) {
    return {
      type: types.FETCH_TENANT_DYNAMIC_FILTERS_STARTED,
      payload: payload
    };
  },
  fetchTenantDynamicFiltersFailed(payload) {
    return {
      type: types.FETCH_TENANT_DYNAMIC_FILTERS_FAILED,
      payload: payload
    }
  },
  fetchTenantDynamicFiltersComplete(dynamicFilters) {
    return {
      type: types.FETCH_TENANT_DYNAMIC_FILTERS_COMPLETED,
      payload: dynamicFilters
    };
  }
}
