import * as types from './actionTypes';

export default {
  fetchConversation(conversationId) {
    return {
      type: types.FETCH_CONVERSATIONS_STARTED,
      payload:{
        conversationId: conversationId
      }
    };
  },
  fetchConversationComplete(conversation) {
    return {
      type: types.FETCH_CONVERSATIONS_COMPLETED,
      payload:{
        conversation: conversation
      }
    };
  },
  activeViewerJoined(event){
    return {
      type:types.ACTIVE_VIEWER_JOINED,
      payload:{
        event
      }
    }
  },
  activeViewerLeft(event){
    return {
      type:types.ACTIVE_VIEWER_LEFT,
      payload:{
        event
      }
    }
  },
  initJoinActiveUsers(event){
    return {
      type:types.INIT_JOIN_ACTIVE_USERS,
      payload:{
        event
      }
    }
  },
  clearState(){
    return {
      type: types.CLEAR_STATE,
      payload:{}
    }
  }
}
