  // get single user
  export const FETCH_USER_PROFILE_STARTED = 'users/FETCH_USER_PROFILE_STARTED';
  export const FETCH_USER_PROFILE_COMPLETED = 'users/FETCH_USER_PROFILE_COMPLETED';

  // get many users
  export const FETCH_USERS_STARTED = 'users/FETCH_USERS_STARTED';
  export const FETCH_USERS_FAILED = 'users/FETCH_USERS_FAILED';
  export const FETCH_USERS_COMPLETED = 'users/FETCH_USERS_COMPLETED';

  export const FETCH_PERMISSIONS_STARTED = 'users/FETCH_PERMISSIONS_STARTED';
  export const FETCH_PERMISSIONS_COMPLETED = 'users/FETCH_PERMISSIONS_COMPLETED';

  export const EXISTING_USER_STARTED = 'users/EXISTING_USER_STARTED';
  export const EXISTING_USER_COMPLETED = 'users/EXISTING_USER_COMPLETED';

  export const SEARCH_FOR_USERS_TYPEAHEAD_STARTED = 'users/SEARCH_FOR_USERS_TYPEAHEAD_STARTED';
  export const SEARCH_FOR_USERS_TYPEAHEAD_COMPLETED = 'users/SEARCH_FOR_USERS_TYPEAHEAD_COMPLETED';

  export const CREATE_USER_STARTED = 'users/CREATE_USER_STARTED';
  export const CREATE_USER_COMPLETED = 'users/CREATE_USER_COMPLETED';

  export const FETCH_USER_STARTED = 'users/FETCH_USER_STARTED';
  export const FETCH_USER_FAILED = 'users/FETCH_USER_FAILED';
  export const FETCH_USER_COMPLETED = 'users/FETCH_USER_COMPLETED';

  export const FETCH_USER_TO_COPY_STARTED = 'users/FETCH_COPY_FROM_USER_STARTED';
  export const FETCH_USER_TO_COPY_COMPLETED = 'users/FETCH_COPY_FROM_USER_COMPLETED';
  export const CLEAR_USER_TO_COPY_COMPLETED = 'users/CLEAR_COPY_FROM_USER_COMPLETED';

  export const UPDATE_USER_STARTED = 'users/UPDATE_USER_STARTED';
  export const UPDATE_USER_COMPLETED = 'users/UPDATE_USER_COMPLETED';

  export const UPDATE_USER_PHONE_NUMBER_STARTED  = 'users/UPDATE_USER_PHONE_NUMBER_STARTED';
  export const UPDATE_USER_PHONE_NUMBER_COMPLETED  = 'users/UPDATE_USER_PHONE_NUMBER_COMPLETED';

  export const DELETE_USER_STARTED = 'users/DELETE_USER_STARTED';
  export const DELETE_USER_COMPLETED = 'users/DELETE_USER_COMPLETED';

  export const SEND_SMS_VERIFICATION_CODE = 'users/SEND_SMS_VERIFICATION_CODE';
  export const VALIDATE_SMS_VERIFICATION_CODE = 'users/VALIDATE_SMS_VERIFICATION_CODE';

  export const FETCH_ALERT_RULES_STARTED = 'users/FETCH_ALERT_RULES_STARTED';
  export const FETCH_ALERT_RULES_FAILED = 'users/FETCH_ALERT_RULES_FAILED';
  export const FETCH_ALERT_RULES_COMPLETED = 'users/FETCH_ALERT_RULES_COMPLETED';

  export const CREATE_RULE_CARD = "users/CREATE_RULE_CARD";

  export const CREATE_ALERT_RULE_STARTED = 'users/CREATE_ALERT_RULE_STARTED';

  export const UPDATE_ALERT_RULE_STARTED = 'users/UPDATE_ALERT_RULE_STARTED';

  export const DELETE_ALERT_RULE_STARTED = 'users/DELETE_ALERT_RULE_STARTED';
  export const DELETE_ALERT_RULE_COMPLETED = 'users/DELETE_ALERT_RULE_COMPLETED';

  export const CHANGE_PASSWORD_STARTED = 'users/CHANGE_PASSWORD_STARTED';
  export const CHANGE_PASSWORD_COMPLETED = 'users/CHANGE_PASSWORD_COMPLETED';
 
  export const CREATE_SHIPMENT_RULE_CARD = "users/CREATE_SHIPMENT_RULE_CARD";
  export const RESET_SUBSCRIBED_PROP = 'users/RESET_SUBSCRIBED_PROP';
