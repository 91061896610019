/* Main Map Controls */
export const SET_MAP_CONTENT = 'map/SET_MAP_CONTENT';
export const SET_MAP_TYPE = 'map/SET_MAP_TYPE';

/* Shipment Map Controls */
export const SET_SHIPMENT_MAP_CONTENT = 'map/SET_SHIPMENT_MAP_CONTENT';
export const SET_SHIPMENT_MAP_TYPE = 'map/SET_SHIPMENT_MAP_TYPE';

/* Disruptios Map Controls */
export const SET_DISRUPTIONS_MAP_CONTENT = 'map/SET_DISRUPTIONS_MAP_CONTENT';
export const SET_DISRUPTIONS_MAP_TYPE = 'map/SET_DISRUPTIONS_MAP_TYPE';

/* Zoom to a view of an incident */
export const ZOOM_TO_INCIDENT = 'disruptions/ZOOM_TO_INCIDENT';

/* Turn on off bing maps drawing tools */
export const SHOULD_DRAW_ON_MAP = 'map/SHOULD_DRAW_ON_MAP';

export const MAP_LOADED = 'map/MAP_LOADED';