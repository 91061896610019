import React from 'react';
import PropTypes from 'prop-types';

import IncidentCard from './IncidentCard';
import LoadingIcon from '../../components/loading-icon';

export default function IncidentCardList(props) {
  let cards = [];
  for(const [idx, x] of props.affectedShipments.entries()) {
    cards.push(<IncidentCard key={x.incidentInfo.id} incident={x} history={props.history} location={props.location}/>);
    if(idx > 0 && (idx + 1) % 3 === 0) {
      cards.push(<div className="w-100 row"></div>);
    }
  }
  return(
    <div className="row" style={{margin: "10px 0 10px 0"}}>
    {
      props.loading ?  <div style={{marginLeft: "10px"}}><LoadingIcon/></div> :
      (
        cards.length ? cards :
        <div style={{ margin: "0 0 25px 15px" }} data-test="noIncidents">
          There are no disruptions currently impacting your supply chain.
        </div>
      )
    }
    </div>
  );
}

IncidentCardList.propTypes = {
  affectedShipments: PropTypes.array,
  loading: PropTypes.bool
};

IncidentCardList.defaultProps = {
  affectedShipments: [],
  loading: false
};
