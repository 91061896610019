import * as _ from 'lodash';
import { containsRestrictedMaterial } from '../../utils/helperMethods/commonMethods';

const getOrderItems = (loads, stateDetails, tenantId) => {
  let combinedPrimaryIdentifiers = [];
  let combinedSecondaryIdentifiers = [];
  let combinedTertiaryIdentifiers = [];
  let atLeastOneLoadContainsHazMat = false;
  let atLeastOneLoadContainsRestrictedMaterial = false;
  let highValueLoad = false;
  let liveTracked = false;
  let loadsCount = loads.length;
  let trackingNumber = null;
  let expedite = false;
  let intForwarder = false;

  const containsObj = (arry, obj) => {
    var result = _.findIndex(arry, obj);
    return result !== -1 ? true : false;
  };

  loads.forEach(load => {
    if (load) {
      if (load.is_haz_mat) {
        atLeastOneLoadContainsHazMat = true;
      }
      if (load.high_value) {
        highValueLoad = true;
      }
      if (load.live_tracked) {
        liveTracked = true;
      }
      if( tenantId =='30' && stateDetails =='orderTrackingSummary')
      {
        intForwarder = load.international_forwarder ? load.international_forwarder : false;
      }
      else
      {
        intForwarder = true;
      }
      if((intForwarder)){
        if (load.primary_identifiers && load.primary_identifiers.length > 0) {

          load.primary_identifiers.forEach(identifier => {
            if (!containsObj(combinedPrimaryIdentifiers, identifier)) {
              combinedPrimaryIdentifiers.push(identifier);
            }
          });
        }
      }  
      if (load.secondary_identifiers && load.secondary_identifiers.length > 0) {
        load.secondary_identifiers.forEach(identifier => {
          if (!containsObj(combinedSecondaryIdentifiers, identifier)) {
            combinedSecondaryIdentifiers.push(identifier);
          }
        });
      }
      if (load.tertiary_identifiers && load.tertiary_identifiers.length > 0) {
        load.tertiary_identifiers.forEach(identifier => {
          if (!containsObj(combinedTertiaryIdentifiers, identifier)) {
            combinedTertiaryIdentifiers.push(identifier);
          }
        });
      }
      if (load.tracking_number) {
        trackingNumber = load.tracking_number;
      }
      if (containsRestrictedMaterial(load.items, load.reference_numbers)) {
        atLeastOneLoadContainsRestrictedMaterial = true;
      }
      if (load.expedite) {
        expedite = true;
      }
    }
  });

  return {
    combinedPrimaryIdentifiers: combinedPrimaryIdentifiers,
    combinedSecondaryIdentifiers: combinedSecondaryIdentifiers,
    combinedTertiaryIdentifiers: combinedTertiaryIdentifiers,
    atLeastOneLoadContainsHazMat: atLeastOneLoadContainsHazMat,
    atLeastOneLoadContainsRestrictedMaterial: atLeastOneLoadContainsRestrictedMaterial,
    highValueLoad: highValueLoad,
    liveTracked: liveTracked,
    intForwarder: intForwarder,
    loadsCount: loadsCount,
    expedite: expedite,
    trackingNumber
  };
};

const getIdentifierLabel = (identifiers) => {
  return identifiers && identifiers.length > 0 && identifiers[0].display_label ? identifiers[0].display_label : "";
};

const getIdentifierValuesInner = (identifiers) => {
  var result = [];
  if (identifiers && identifiers.filter) {
    result = identifiers.filter(function (val) {
      return val.value;
    }).map(function (val) {
      return val.value;
    });
  }
  return result;
};

const hasIdentifier = (shipment, key) => {
  var fullKey = key + "_identifiers";
  return !!(shipment[fullKey] && shipment[fullKey].length > 0);
}

const getIdentifierValues = (identifiers, start, end) => {
  var result = getIdentifierValuesInner(identifiers);
  if (end && end > 0) {
    result = result.slice(start || 0, end);
  } else if (start && start > 0) {
    result = result.slice(start);
  }
  return result.join(", ");
};

const getRiskLevelIconName = (riskLevel) => {
  if (!riskLevel) return;
  switch (riskLevel.toUpperCase()) {
    case 'AT RISK': return 'order-at-risk';
    case 'EARLY': return 'order-early';
    case 'ON TIME': return 'order-on-time';
    case 'LATE': return 'order-late';
    default: return;
  }
}

const getRiskLevelClassName = (riskLevel) => {
  return riskLevel ? riskLevel.split(' ').join('-').toLowerCase() : '';
}

export { getOrderItems, getIdentifierLabel, getIdentifierValues, hasIdentifier, getRiskLevelIconName, getRiskLevelClassName };
