import React from 'react';
import Dropdown from './Dropdown';
import Icon from '../icon';
import {NotificationsContainer} from '../../containers/notifications';
import PropTypes from 'prop-types';
export default class AlertsDropdown extends React.Component {
  render() {
    return (
      <div onFocus={this.props.viewedNotifications}>
        <Dropdown
          items={this.props.type === 'dropdown' ? <NotificationsContainer history={this.props.history}/> : <div className='navbar-content'></div>}
          anchor={<Icon type="notification-new" id="alerts-icon" />}
          type={this.props.type}
          showTray={this.props.showTray}
          newAlert={this.props.hasNewAlert} />
      </div>
    );
  }
};

AlertsDropdown.protoType = {
  type: PropTypes.string,
  showTray: PropTypes.bool
};
