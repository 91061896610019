import { combineReducers } from 'redux';
import { fromJS } from 'immutable';
import queryString from 'query-string';
import { shipmentsQueryConfigurationReducer } from './queryConfiguration/reducer';
import * as types from './actionTypes';
import { DEFAULT_SHIPMENT_PAYLOAD_LIMIT } from '../../../app/utils/constants';

const JOB_MODE_LOCAL_STORAGE_KEY = 'STORED_JOB_MODE' ;

export const initialState = () => fromJS({
  jobMode: window.localStorage.getItem(JOB_MODE_LOCAL_STORAGE_KEY) ?? 'unset',
  displayMode: 'listOnly',
  affectedShipmentsLoading: false,
  filterAggregations: {},
  exceptionsLoading: false,
  loading: true,
  liveTrackedPins: {},
  oldNeedsRemovedFromApi: [],
  shipments: [],
  total: 0,
  clustering: {},
  limit: DEFAULT_SHIPMENT_PAYLOAD_LIMIT,
  announcementActive: true,
});

export function shipmentsReducer(state = initialState(), action = {}) {
  const jobMode = state.get('jobMode');
  if (action.type !== types.SET_JOB_MODE && jobMode == 'unset') {
    //jobMode must be set to act on this state
    return state;
  }
  switch (action.type) {
    case types.SET_JOB_MODE: {
      let newState = initialState();
      const newJobMode = action.payload;
      window.localStorage.setItem(JOB_MODE_LOCAL_STORAGE_KEY, newJobMode);
      return newState.set('jobMode', newJobMode);
    }
    case types.SEARCH_SHIPMENTS_STARTED:
      return state
        .set('loading', true)
    case types.SEARCH_SHIPMENTS_FAILED:
      return state
        .set('loading', false)
        .set('shipments', [])
        .set('total', 0)
        .set('error', action.payload);
    case types.SEARCH_SHIPMENTS_COMPLETED:
      return state
        .set('oldNeedsRemovedFromApi', action.payload.shipments[0].aggregations.locations.buckets)
        .set('liveTrackedPins', action.payload.shipments[1].aggregations)
        .set('shipments', action.payload.shipments[2].hits.hits)
        .set('total', action.payload.shipments[2].hits.total)
        .set('filterAggregations', action.payload.shipments[3].aggregations)
        .set('clustering', action.payload.shipments[4]?.aggregations)
        .set('loading', false)
    case types.FETCH_SHIPMENT_REFERENCES_STARTED:
      return state
        .set('shipmentReferencesLoading', true);
    case types.FETCH_SHIPMENT_REFERENCES_FAILED:
      return state
        .set('shipmentReferencesLoading', false)
        .set('shipmentReferences', [])
        .set('error', action.payload);
    case types.FETCH_SHIPMENT_REFERENCES_COMPLETED:
      return state
        .set('shipmentReferencesLoading', false)
        .set('shipmentReferences', action.shipmentReferences)
    case types.FETCH_LIST_SHIPMENTS_COMPLETED:
      return state
        .set('shipments', action.payload.shipments[2].hits.hits)
        .set('total', action.payload.shipments[2].hits.total);
    case types.TOGGLE_SHIPMENT_VIEW_MODE:
      return state
        .set('shipmentViewMode', action.payload.shipmentViewMode);
    case types.FETCH_AFFECTED_SHIPMENTS_STARTED:
      return state
        .set('affectedShipmentsLoading', true);
    case types.FETCH_AFFECTED_SHIPMENTS_FAILED:
      return state
        .set('affectedShipmentsLoading', false);
    case types.FETCH_AFFECTED_SHIPMENTS_COMPLETED:
      return state
        .set('affectedShipments', action.payload)
        .set('affectedShipmentsLoading', false);
    case types.FETCH_EXCEPTIONS_STARTED:
      return state
        .set('exceptionsLoading', true);
    case types.FETCH_EXCEPTIONS_FAILED:
      return state
        .set('exceptionsLoading', false);
    case types.FETCH_EXCEPTIONS_COMPLETED:
      return state
        .set('exceptions', action.payload)
        .set('exceptionsLoading', false);
    case types.UPDATED_QUERY_PARAMS: {
      let newState = state.set('loading', true);
      if (action.payload.location && action.payload.location.search) {
        let newParams = queryString.parse(action.payload.location.search);
        newState = newState.set('displayMode', newParams.displayMode)
      }
      return newState;
    }
    case types.NEW_QUERY_PARAMS:
      return state
        .set('loading', true)
    case types.TOGGLE_SHIPMENT_DISPLAY_MODE: {
      //split view -> any other view (or vice versa) undoes bounding box, reset data for next query
      let currentView = state.get('displayMode');
      let newState = state;
      if (currentView === 'splitView' && action.payload.display !== 'splitView'
          || action.payload.display === 'splitView' && currentView !== 'splitView') {
        newState = initialState();
      }
      return newState
        .set('displayMode', action.payload.display)
        .set('jobMode', state.get('jobMode'))
    }
    case types.TOGGLE_OUTAGE_ANNOUNCEMENT_ACTIVE:
      return state
        .set('announcementActive', !state.get('announcementActive'));
    default:
      return state;
  }
}

export default function shipmentsRootReducer() {
  return combineReducers({
    data: shipmentsReducer,
    queryConfiguration: shipmentsQueryConfigurationReducer
  });
}


