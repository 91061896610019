import { all, put, call, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import * as types from './actionTypes';
import actions from './actions';
import globalSuccessErrorAlertActions from '../../containers/global-success-error-alerts/actions';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { parseResponse } from '../../utils/helperMethods/powerBiHelper';

const defaultLandingPageHide = {
  newsAndDisruptions: false,
  news: false,
  disruptions: false,
  supplyChainHealth: false,
  ground: false,
  air: false,
  parcel: false,
  ocean: false,
  parcel: false,
  globalHealth: false,
  trendingShipments: false,
  exceptions: false,
  powerBi: false
};

export function* fetchSupplyChainHealth(action) {
  try {
    const result = yield call(api.shipments.fetchShipmentsHealth, action.payload);
    if (action.payload.mode === 'All') {
      yield put(actions.fetchInTransitHealthCompleted(result.data));
    } else if (action.payload.mode === 'Ocean') {
      yield put(actions.fetchOceanHealthCompleted(result.data));
    } else if (action.payload.mode === 'Ground') {
      yield put(actions.fetchGroundHealthCompleted(result.data));
    } else if (action.payload.mode === 'Air') {
      yield put(actions.fetchAirHealthCompleted(result.data));
    } else if (action.payload.mode === 'Parcel') {
      yield put(actions.fetchParcelHealthCompleted(result.data));
    }
  } catch (e) {
    let failedModeAction = () => { };
    if (action.payload.mode === 'All') {
      failedModeAction = actions.fetchInTransitHealthFailed;
    } else if (action.payload.mode === 'Ocean') {
      failedModeAction = actions.fetchOceanHealthFailed;
    } else if (action.payload.mode === 'Ground') {
      failedModeAction = actions.fetchGroundHealthFailed;
    } else if (action.payload.mode === 'Air') {
      failedModeAction = actions.fetchAirHealthFailed;
    } else if (action.payload.mode === 'Parcel') {
      failedModeAction = actions.fetchParcelHealthFailed;
    }
    yield all([
      put(failedModeAction()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: `${action.payload.mode} Health Error`,
        message: `Failed to retrieve supply chain health. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchSupplyChainHealth() {
  yield takeEvery(types.FETCH_HEALTH_STARTED, fetchSupplyChainHealth);
}

export function* fetchTrendingShipments() {
  try {
    const result = yield call(api.shipments.fetchTrendingShipments);
    yield put(actions.fetchTrendingShipmentsCompleted(result.data));
  } catch (e) {
    yield all([
      put(actions.fetchTrendingShipmentsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Trending Shipments Error',
        message: `Failed to retrieve trending shipments. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchFetchTrendingShipments() {
  yield takeEvery(types.FETCH_TRENDING_SHIPMENTS_STARTED, fetchTrendingShipments);
}

export function* fetchLandingPageConfig(action) {
  const result = yield call(api.metadata.fetchLandingPageConfig, action.payload);
  const mappedShowElements = mapLandingPageConfig(result.data);
  yield put(actions.fetchLandingPageConfigCompleted(mappedShowElements));
}

export function* watchLandingPageConfig() {
  yield takeEvery(types.FETCH_LANDING_PAGE_CONFIG_STARTED, fetchLandingPageConfig);
}

function mapLandingPageConfig(config) {
  let hideElements = {};
  if (config === {}) {
    hideElements = Object.assign(hideElements, defaultLandingPageHide)
  } else if (config) {
    hideElements.newsAndDisruptions = parseShowFromLandingPageConfig(config, 'newsAndDisruptions');
    hideElements.news = parseShowFromLandingPageConfig(config, 'news');
    hideElements.disruptions = parseShowFromLandingPageConfig(config, 'disruptions');
    hideElements.supplyChainHealth = parseShowFromLandingPageConfig(config, 'supplyChainHealth');
    hideElements.ground = parseShowFromLandingPageConfig(config, 'ground');
    hideElements.air = parseShowFromLandingPageConfig(config, 'air');
    hideElements.parcel = parseShowFromLandingPageConfig(config, 'parcel');
    hideElements.ocean = parseShowFromLandingPageConfig(config, 'ocean');
    hideElements.parcel = parseShowFromLandingPageConfig(config, 'parcel');
    hideElements.globalHealth = parseShowFromLandingPageConfig(config, 'globalHealth');
    hideElements.trendingShipments = parseShowFromLandingPageConfig(config, 'trendingShipments');
    hideElements.exceptions = parseShowFromLandingPageConfig(config, 'exceptions');
    hideElements.powerBi = parseShowFromLandingPageConfig(config, 'powerBi');
  }

  return hideElements;
}

export function parseShowFromLandingPageConfig(config, name, defaultResult) {
  defaultResult = defaultResult ? defaultResult : false;
  const result = drillDown(config, name, defaultResult);
  const hasDisabledFlag = result['disabled'] ? result['disabled'] : false;

  let fieldToAdd = {};
  fieldToAdd['show' + name] = hasDisabledFlag;
  return hasDisabledFlag;
}

export function drillDown(object, elementNameToFind, defaultIfNotFound) {
  if (object) {
    if (object[elementNameToFind]) { return object[elementNameToFind]; }

    for (let key in object) {
      if (typeof object[key] === 'object') {
        const value = drillDown(object[key], elementNameToFind, defaultIfNotFound);
        if (value) return value;
      }
    }
    return defaultIfNotFound;
  }
  else {
    return defaultIfNotFound;
  }
}

export function toggleCovid(mockableWindow) {
  let dismissed = mockableWindow.localStorage.getItem(LOCAL_STORAGE_KEYS.covidDismissed);
  if (dismissed) {
    mockableWindow.localStorage.removeItem(LOCAL_STORAGE_KEYS.covidDismissed)
  } else {
    mockableWindow.localStorage.setItem(LOCAL_STORAGE_KEYS.covidDismissed, 'true')
  }
}

export function* watchToggleCovidActive() {
  yield takeEvery(types.TOGGLE_COVID_ACTIVE, toggleCovid, window)
}

export function* fetchPowerBiItemsStart() {
  try {
    //retrieve all report/dashboard assigned to tenant
    let response = yield call(api.powerBi.fetchPowerBiItems);

    if (response && response.data && response.data.length > 0) {
      let parsedData = [];
      const reports = response.data.filter(x => x.type === "Report");
      const dashboards = response.data.filter(x => x.type === "Dashboard");

      //retrieve all report embeds assigned to tenant
      if (reports && reports.length > 0) {
        const reportEmbeds = yield reports.map(report => call(api.powerBi.fetchPowerBiReportEmbed, report.pbiId));
        parsedData = parsedData.concat(parseResponse(reportEmbeds));
      }

      //retrieve all dashboard embeds assigned to tenant
      if (dashboards && dashboards.length > 0) {
        const dashboardEmbeds = yield dashboards.map(dashboard => call(api.powerBi.fetchPowerBiDashboardEmbed, dashboard.pbiId));
        parsedData = parsedData.concat(parseResponse(dashboardEmbeds));
      }

      yield put(actions.fetchPowerBiItemsCompleted(parsedData));
    }
  } catch (e) {
    yield all([
      put(actions.fetchPowerBiItemsFailed()),
      put(globalSuccessErrorAlertActions.triggerGlobalErrorAlert({
        title: 'Power BI Error',
        message: `Failed to retrieve Power BI items. Please try again.`,
        error: e
      }))
    ]);
  }
}

export function* watchPowerBiItemsStart() {
  yield takeEvery(types.FETCH_POWER_BI_ITEMS_STARTED, fetchPowerBiItemsStart);
}