import {PERMISSIONS, ROLE_SETS} from '../../utils/rbacUtils'

export const InventoryPageRBAC = {
  permissions: [PERMISSIONS.Inventory],
  roles: ROLE_SETS.usersAndAbove
};

export const InventoryDetailsPageRBAC = {
  permissions: [PERMISSIONS.Inventory],
  roles: ROLE_SETS.usersAndAbove
};