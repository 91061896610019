import * as types from './actionTypes';
export default {
  fetchFacilities() {
    return {
      type: types.FETCH_FACILITIES_STARTED,
      payload:{}
    };
  },
  fetchFacilitiesComplete(facilities) {
    return {
      type: types.FETCH_FACILITIES_COMPLETED,
      payload:{
        facilities: facilities
      }
    };
  }
}
