import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  clustering: true,
  mapType: 'canvasDark',
  mapContent: {
    facilities: true,
    ports: true,
    incidents: true,
    weather: false,
    traffic: false,
    zoom: 3,
    trackerData: false
  },
  modifiers: {
    hideMapType: false,
    hidePorts: false,
    hideFacilities: false,
    hideIncidents: false,
    hideWeather: false,
    hideTraffic: false
  },
  shipmentSearch: true,
  facilities: true,
  incidents: true,
  ports: true
});

function mainMapReducer(state=initialState, action='') {
  switch(action.type) {
    case types.SET_MAP_CONTENT:
      return state
        .setIn(['mapContent', action.payload.key], action.payload.val);
    case types.SET_MAP_TYPE:
      return state
        .set('mapType', action.payload.mapType);
    default:
      return state;
  }
}

export const initialShipmentState = fromJS({
  clustering: false,
  mapType: 'canvasDark',
  mapContent: {
    facilities: false,
    iotDetails: true,
    iotLocations: true,
    ports: false,
    incidents: true,
    weather: false,
    traffic: false,
    trackerData: true
  },
  modifiers: {
    hideMapType: false,
    hidePorts: true,
    hideFacilities: true,
    hideIncidents: false,
    hideWeather: true,
    hideTraffic: false
  },
  shipmentSearch: false,
  facilities: false,
  incidents: true,
  ports: false,
  showTrackerData: true,  
  mapLoaded: false 
});

function shipmentMapReducer(state=initialShipmentState, action='') {
  switch(action.type) {
    case types.SET_SHIPMENT_MAP_CONTENT:
      return state
        .setIn(['mapContent', action.payload.key], action.payload.val);
    case types.SET_SHIPMENT_MAP_TYPE:
      return state
        .set('mapType', action.payload.mapType);
    case types.MAP_LOADED:
      return state
        .set('mapLoaded', action.payload);
    default:
      return state;
  }
}

export const initialDisruptionsState = fromJS({
  clustering: false,
  mapType: 'canvasDark',
  mapContent: {
    editIncidents: false,
    facilities: false,
    incidents: true,
    ports: false,
    shouldDrawOnMap: false,
    trackerData: false,
    traffic: true,
    weather: true,
    zoom: 3
  },
  modifiers: {
    hideMapType: false,
    hidePorts: true,
    hideFacilities: true,
    hideIncidents: false,
    hideWeather: false,
    hideTraffic: false
  },
  shipmentSearch: false,
  facilities: false,
  incidents: true,
  ports: false
});

function disruptionsMapReducer(state = initialDisruptionsState, action = '') {
  switch (action.type) {
    case types.SET_DISRUPTIONS_MAP_CONTENT:
      return state
        .setIn(['mapContent', action.payload.key], action.payload.val);
    case types.SET_DISRUPTIONS_MAP_TYPE:
      return state
        .set('mapType', action.payload.mapType);
    case types.ZOOM_TO_INCIDENT:
      return state
        .set('zoomToIncident', action.payload.incident);
    case types.SHOULD_DRAW_ON_MAP:
      return state
        .set('shouldDrawOnMap', action.payload.draw);
    default:
      return state;
  }
}

export {
  mainMapReducer,
  shipmentMapReducer,
  disruptionsMapReducer
};
