import * as types from './actionTypes';

export default {
  fetchShipmentOrdersWarehouseSummary(encodedReq) {
    return {
      type: types.FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_STARTED,
      payload: encodedReq
    };
  },
  fetchShipmentOrdersWarehouseSummaryFailed(payload) {
    return {
      type: types.FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_FAILED,
      payload: payload
    };
  },
  fetchShipmentOrdersWarehouseSummaryComplete(payload) {
    return {
      type: types.FETCH_SHIPMENT_ORDERS_WAREHOUSE_SUMMARY_COMPLETED,
      payload: payload
    };
  }
};
