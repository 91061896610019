import React from 'react';
import ProgressBar from '../progress-bar';
import { OverlayTrigger } from 'react-bootstrap';
import { popover } from '../../utils/helperMethods/commonComponents';

export default class CarrierCompliance extends React.Component {

  render() {
    return (
      <div className="card">
        <h3 className="scoreCardHeaders">Carrier Compliance</h3>
        {this.props.carrier_compliance &&
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div>Depart Origin&nbsp;
                <OverlayTrigger placement="bottom" overlay={popover("Depart Origin", "Percentage of delivered shipments that contain a depart origin carrier update.", "carrierDepartOrigin")}>
                  <svg className="icon small faded" data-test="carrierDepartOriginPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                </OverlayTrigger><br />
                <ProgressBar id={'carrier-depart-origin'} isAnimationActive={this.props.isAnimationActive} percentage={this.props.carrier_compliance.hasDepartOrigin && Number(this.props.carrier_compliance.hasDepartOrigin.percentage * 100).toFixed(1)} showLabel={true} maxWidth={130}></ProgressBar> </div>

              <div>Arrive at Destination&nbsp;
                <OverlayTrigger placement="bottom" overlay={popover("Arrive At Destination", "Percentage of delivered shipments that contain an arrival date.", "carrierAtDestination")}>
                  <svg className="icon small faded" data-test="carrierAtDestinationPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                </OverlayTrigger><br />
                <ProgressBar id={'carrier-at-destination'} isAnimationActive={this.props.isAnimationActive} percentage={this.props.carrier_compliance.hasArrivalDate && Number(this.props.carrier_compliance.hasArrivalDate.percentage * 100).toFixed(1)} showLabel={true} maxWidth={130}></ProgressBar> </div>

              <div>Contains ETA&nbsp;
                <OverlayTrigger placement="bottom" overlay={popover("Contains ETA", "Percentage of delivered shipments that contain a Destination ETA.", "carrierContainsETA")}>
                  <svg className="icon small faded" data-test="carrierContainsETAPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                </OverlayTrigger><br />
                <ProgressBar id={'carrier-contains-eta'} isAnimationActive={this.props.isAnimationActive} percentage={this.props.carrier_compliance.hasETA && Number(this.props.carrier_compliance.hasETA.percentage * 100).toFixed(1)} showLabel={true} maxWidth={130}></ProgressBar> </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-lg-4">
              <div>Avg # Updates &nbsp;
                <OverlayTrigger placement="bottom" overlay={popover("Average Number of Updates", "Average number of carrier updates per delivered shipment. Carrier updates include driver log, package tracking log, and milestone entries.", "carrierAvgNumOfUpdate")}>
                  <svg className="icon small faded" data-test="supplyChainHealthPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                </OverlayTrigger>
              </div>
              <div className="scoreCardCounts" id={'average-number-of-updates-count'}>{Number(this.props.carrier_compliance.averageNumUpdates).toFixed(1)}</div>
              <div>Avg Update Time &nbsp;
                <OverlayTrigger placement="bottom" overlay={popover("Average Update Time", "Average time (in hours) between carrier updates. Measurement occurs between the Origin Arrive Date and the Destination Complete Date.", "carrierAvgUpdateTime")}>
                  <svg className="icon small faded" data-test="supplyChainHealthPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                </OverlayTrigger>
              </div>
              <div className="scoreCardCounts" id={'average-update-time-count'}>{Number(this.props.carrier_compliance.averageUpdateTime).toFixed(1)}<span> Hours</span></div>
            </div>

            {/* For air only carriers */}
            {this.props.carrier_compliance.hasFlightNumber &&
              <div className="col-xs-12 col-sm-12 col-lg-4">
                <span>Flight # &nbsp;
                  <OverlayTrigger placement="bottom" overlay={popover("Flight Number", "Percentage of delivered air shipments containing Plane ID.", "carrierFlightNum")}>
                    <svg className="icon small faded" data-test="carrierFlightNumPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                  </OverlayTrigger>
                </span>
                <span style={{ float: 'right' }} className="scoreCardCounts" id={'has-flight-number-count'}>{Number(this.props.carrier_compliance.hasFlightNumber.percentage * 100).toFixed(1)}%</span><br></br>

                <span>Wheels Up &nbsp;
                  <OverlayTrigger placement="bottom" overlay={popover("Wheels Up", "Percentage of delivered air shipments that contain a Wheels Up carrier update.", "carrierWheelsUp")}>
                    <svg className="icon small faded" data-test="carrierWheelsUpPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                  </OverlayTrigger>
                </span>
                <span style={{ float: 'right' }} className="scoreCardCounts" id={'has-wheels-up-count'} >{Number(this.props.carrier_compliance.hasWheelsUp.percentage * 100).toFixed(1)}%</span><br></br>

                <span>Wheels Down &nbsp;
                  <OverlayTrigger placement="bottom" overlay={popover("Wheels Down", "Percentage of delivered air shipments that contain a Wheels Down carrier update.", "carrierWheelsDown")}>
                    <svg className="icon small faded" data-test="carrierWheelsDownPopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                  </OverlayTrigger>
                </span>
                <span style={{ float: 'right' }} className="scoreCardCounts" id={'has-wheels-down-count'}>{Number(this.props.carrier_compliance.hasWheelsDown.percentage * 100).toFixed(1)}%</span><br></br>

                <span>Takeoff Time &nbsp;
                  <OverlayTrigger placement="bottom" overlay={popover("Takeoff Time", "Percentage of delivered air shipments that contain a take-off time.", "carrierTakeOffTime")}>
                    <svg className="icon small faded" data-test="carrierTakeOffTimePopover"><use href="#help" style={{ 'pointerEvents': 'none' }} /></svg>
                  </OverlayTrigger>
                </span>
                <span style={{ float: 'right' }} className="scoreCardCounts" id={'has-take-off-time-count'}>{Number(this.props.carrier_compliance.takeOffTime.percentage * 100).toFixed(1)}%</span><br></br>

              </div>
            }
          </div>
        }
      </div>
    );
  }
}
