import React, { Component } from 'react';
import {ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, ZAxis, ReferenceLine, Tooltip} from 'recharts';
import PropTypes from 'prop-types';
import LoadingIcon from '../loading-icon';


export class CustomizedDot extends Component {
  render() {
    const { cx, cy, payload, high, low } = this.props;

    let isOutsideThreshold = false;

    if (payload.y >= high || payload.y <= low) {
      isOutsideThreshold = true;
    }

    return (
      <circle className="scatter-graph-custom-dot" cx={cx} cy={cy} r={3.5} stroke={isOutsideThreshold ? 'red' : 'green'} strokeWidth={2} fill="white" />
    );
  }
};

export class CustomizedToolTip extends Component {
  render() {
    const { payload } = this.props;

    const dataPoints = [];

    payload.forEach((val, idx) => {
      if (val.name !== 'Date') {
        const valueUnitSpacer = (val.unit == '%' || val.unit == '°') ? '' : ' ';
        dataPoints.push(
          <div className="scatter-graph-tool-tip">
            <p>{val.payload.date}</p>
            <p>Hi: {val.payload.maxValue}{valueUnitSpacer}{val.unit}</p>
            <p>Avg: {val.payload.averageValue}{valueUnitSpacer}{val.unit}</p>
            <p>Low: {val.payload.minValue}{valueUnitSpacer}{val.unit}</p>
          </div>
        )
      }
    })

    return dataPoints;
  }
};


export default class ScatterGraph extends Component {
  drawLines = () => {
    let lines = [];
    this.props.series.forEach((val) => {
      const data = [
        {date: val.date, y: val.maxValue, maxValue: val.maxValue, minValue: val.minValue, averageValue: val.averageValue},
        {date: val.date, y: val.averageValue, maxValue: val.maxValue, minValue: val.minValue, averageValue: val.averageValue},
        {date: val.date, y: val.minValue, maxValue: val.maxValue, minValue: val.minValue, averageValue: val.averageValue}
      ]

      lines.push(
        <Scatter key={val.date} className="scatter-graph-plot" data={data} fill='#000000' line shape={<CustomizedDot high={val.maxThreshold} low={val.minThreshold} />}/>
      );
    });

    return lines;
  }

  render() {
    const { height, loading, maxThreshold, minThreshold, series, seriesType, unitOfMeasure, width } = this.props;

    return (
      <div className='line-chart card' data-test="LineGraph" style={{ width: width, height: height }}>
        { loading ?
          <div className="line-graph-loading-icon" style={{margin: "0 auto", paddingTop: `${height / 4}px`}}><LoadingIcon/></div> :

          <React.Fragment>
            { series.length ?
              <ResponsiveContainer >
                <ScatterChart margin={{top: 30, right: 5, bottom: -10, left: 5}}>
                  <ReferenceLine y={maxThreshold} label={{ value: `${maxThreshold} ${unitOfMeasure}`, position: "insideBottomLeft", fontSize: '11px', color: "#dddddd"}} stroke={"#dddddd"} />
                  <ReferenceLine y={minThreshold} label={{ value: `${minThreshold} ${unitOfMeasure}`, position: "insideTopLeft", fontSize: '11px', color: "#dddddd"}}  stroke={"#dddddd"} />
                  <XAxis type="category" dataKey={'date'} name='Date' allowDuplicatedCategory={false} />
                  <YAxis type="number" dataKey={'y'} name='Light' unit={unitOfMeasure} hide={false} domain={[(minThreshold - (maxThreshold - minThreshold) / 2), maxThreshold]}/>
                  <Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomizedToolTip />} />
                  { this.drawLines() }
                </ScatterChart>
              </ResponsiveContainer>
            : <p>No {seriesType} data available</p> }
          </React.Fragment>
        }
      </div>
    );
  }
}

ScatterGraph.propTypes = {
  series: PropTypes.array,
  seriesType: PropTypes.string
};

ScatterGraph.defaultProps = {
  series: []
}
