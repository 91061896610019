import React from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import usersActions from '../users/actions';
import baseModalActions from '../base-modal/actions';

export class SmsVerificationContainer extends React.Component {
  state = {
    newPhoneNumber: "",
    newCountryCode: 1,
    agreedToTermsOfService: false,
    countryCodeMask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    countryCodeOptions: [
      { country: 'United States', countryCode: 1, mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
      { country: 'Mexico', countryCode: 52, mask: [/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] }
    ],
    maskPlaceholderChar: "#",
    verificationCode: ""
  };

  //#region Existing Valid Number
  handleRemovePhoneNumber = () => {
    this.props.updateUserPhoneNumber(
      this.props.userProfile.oktaId,
      this.props.userProfile.mobilePhoneNumber,
      this.props.userProfile.mobilePhoneCountryCode,
      true
    );
  };
  //#endregion

  //#region New Phone Number Modal
  handleCountryCodeChange = (e) => {
    const selectedCountryCode = this.state.countryCodeOptions.find(x => {
      return e.target.value === x.countryCode.toString();
    });
    this.setState(Object.assign(this.state, {
      newCountryCode: selectedCountryCode.countryCode,
      countryCodeMask: selectedCountryCode.mask
    }));
    this.handleAddPhoneNumberClick();
  };

  handlePhoneNumberChange = (e) => {
    this.setState(Object.assign(this.state, { newPhoneNumber: e.target.value }));
    this.handleAddPhoneNumberClick();
  };

  handleAcceptTermsToggle = () => {
    this.setState(Object.assign(this.state, { agreedToTermsOfService: !this.state.agreedToTermsOfService }));
    this.handleAddPhoneNumberClick();
  };

  getNewPhoneNumberModalBody = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="col-lg-12">
            <p>You may receive SMS updates from Navisphere Vision and can opt out at any time. Standard message rates apply.</p>
          </div>
          <div className="col-lg-12">
            <form>
              <div className="form-inline">
                <div className="form-group col-md-12">
                  <label htmlFor="countryCodeSelect">Phone Number:  </label>
                  <select onChange={this.handleCountryCodeChange} className="form-control" id="countryCodeSelect" name="countryCodeSelect">
                    {this.state.countryCodeOptions.map(x => {
                      return (
                        <option key={x.countryCode} value={x.countryCode}>{x.countryCode}</option>
                      );
                    })}
                  </select>
                  <MaskedInput type="text"
                    name="phoneNumber"
                    autoFocus
                    className="form-control"
                    placeholderChar={this.state.maskPlaceholderChar}
                    mask={this.state.countryCodeMask}
                    value={this.state.newPhoneNumber}
                    onChange={this.handlePhoneNumberChange}
                    showMask={true}/>
                </div>
                <div className="form-group col-md-12" style={{paddingTop:"10px"}}>
                  <div className="form-check">
                    <label htmlFor="acceptTermsCheckbox" className="form-check-label" style={{fontWeight:"bold"}}>
                    <input type="checkbox" className="form-check-input"
                      id="acceptTermsCheckbox"
                      name="acceptTermsCheckbox"
                      checked={this.state.agreedToTermsOfService}
                      onChange={this.handleAcceptTermsToggle}/>
                      I agree to the terms of service
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      );
  };

  shouldEnableVerifyButton = () => {
    return this.state.agreedToTermsOfService
    && this.state.newPhoneNumber
    && !this.state.newPhoneNumber.includes(this.state.maskPlaceholderChar);
  };

  handleAddPhoneNumberClick = () => {
    const phoneNumberModalConfig = {
      showModal: true,
      showCloseButton: true,
      showActionButton: this.shouldEnableVerifyButton(),
      actionButtonText: "Verify Phone #",
      actionButtonClass: "btn btn-primary",
      closeButtonText: "Cancel",
      closeButtonClass: "btn btn-secondary",
      handleAction: () => { this.handlePhoneNumberVerifyClick(); },
      title: "Enter Your Phone Number",
      body: this.getNewPhoneNumberModalBody()
    };
    this.props.openModal(phoneNumberModalConfig);
  };

  handlePhoneNumberVerifyClick = () => {
    const phoneNumberModalConfig = {
      showModal: true,
      showCloseButton: true,
      showActionButton: true,
      actionButtonText: "Submit",
      actionButtonClass: "btn btn-primary",
      closeButtonText: "Cancel",
      closeButtonClass: "btn btn-secondary",
      handleAction: () => {this.handleVerificationCodeSubmitClick(); },
      title: "Enter Code",
      body: this.getValidationCodeModalBody()
    };
    this.props.openModal(phoneNumberModalConfig);
    this.props.sendSmsVerificationCode(this.props.userProfile.oktaId, this.state.newPhoneNumber, this.state.newCountryCode);
  };
  //#endregion

  //#region Validation Code Modal
  handleVerificationCodeChange = (e) => {
    this.setState(Object.assign(this.state, { verificationCode: e.target.value }));
    const phoneNumberModalConfig = {
      showModal: true,
      showCloseButton: true,
      showActionButton: true,
      actionButtonText: "Submit",
      actionButtonClass: "btn btn-primary",
      closeButtonText: "Cancel",
      closeButtonClass: "btn btn-secondary",
      handleAction: () => {this.handleVerificationCodeSubmitClick(); },
      title: "Enter Code",
      body: this.getValidationCodeModalBody()
    };
    this.props.openModal(phoneNumberModalConfig);
  };

  getValidationCodeModalBody = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div>
            <p>Your account has been set up, but we need to make sure you're a human in control of the phone number you gave us.
              Can you please enter the verification code we just sent to your phone?</p>
          </div>
          <div className="input-group col-xs-9 col-sm-6">
            <input type="text" placeholder="Verification Code" maxLength="4" className="form-control"
              onChange={this.handleVerificationCodeChange} value={this.state.verificationCode}/>
            <div className="input-group-btn">
              <button onClick={this.handlePhoneNumberVerifyClick} className="btn btn-secondary">Resend Code</button>
            </div>
          </div>
        </div>
      </div>
      );
  };

  handleVerificationCodeSubmitClick = () => {
    this.props.validateSmsVerificationCode(
      this.props.userProfile.oktaId,
      this.state.newPhoneNumber,
      this.state.newCountryCode,
      this.state.verificationCode);
    this.props.closeModal();
  };
  //#endregion

  renderWithVerifiedNumber = () => {
    return (
      <div>+{this.props.userProfile.mobilePhoneCountryCode} {this.props.userProfile.mobilePhoneNumber}
        <br />
        <button className="btn btn-danger" onClick={() => { this.handleRemovePhoneNumber(); }}>Remove Phone Number</button>
      </div>
    );
  };

  renderWithoutVerifiedNumber = () => {
    return (
      <div>
        <button className="btn btn-primary" onClick={() => { this.handleAddPhoneNumberClick(); }}>Add Phone Number</button>
      </div>
    );
  };

  render() {
    return(
      <div>
        <div>
          <h4>Text Notification Settings</h4>
          <h5>Text Alerts are only available for individual shipments</h5>
        </div>
        <div className="card">
          <p style={{paddingBottom: "10px"}}>Phone Number</p>
          {
            this.props.userProfile.mobilePhoneIsVerified &&
            this.renderWithVerifiedNumber()
          }
          {
            !this.props.userProfile.mobilePhoneIsVerified &&
            this.renderWithoutVerifiedNumber()
          }
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    userProfile: state.users.get('userProfile')
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    sendSmsVerificationCode: (oktaId, mobilePhoneNumber, mobilePhoneCountryCode) => {
      dispatch(usersActions.sendSmsVerificationCode(oktaId, mobilePhoneNumber, mobilePhoneCountryCode));
    },
    validateSmsVerificationCode: (oktaId, mobilePhoneNumber, mobilePhoneCountryCode, verificationCode) => {
      dispatch(usersActions.validateSmsVerificationCode(oktaId, mobilePhoneNumber, mobilePhoneCountryCode, verificationCode));
    },
    updateUserPhoneNumber: (oktaId, mobilePhoneNumber, mobilePhoneCountryCode, removePhoneVerification) => {
      dispatch(usersActions.updateUserPhoneNumber(oktaId, mobilePhoneNumber, mobilePhoneCountryCode, removePhoneVerification));
    },
    openModal: (modalConfig) => {
      dispatch(baseModalActions.openModal(modalConfig));
    },
    closeModal: () => {
      dispatch(baseModalActions.closeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsVerificationContainer);
