import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/button';
import Icon from '../../components/icon';
import Toggle from '../../components/toggle';

import { selectMapContent, selectMapType, selectMapModifiers } from './selectors';
import actions from './actions';
import { selectShipmentMapContent,selectShipmentMapType, selectShipmentMapModifiers } from './selectors';
import { selectDisruptionsMapContent, selectDisruptionsMapModifiers, selectDisruptionsMapType } from './selectors';

export class MapControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  render() {
    return(
      <div className="map-controls">
        <Button className="btn btn-primary map-control-button" onClick={() => { this.setState({show: !this.state.show}); }}>
          <Icon type="map" className="icon white space-right"/>Map Controls
        </Button>
        { this.state.show &&
        <div className={"map-control-content"}>
          { !this.props.modifiers.get('hideMapType') &&
            <div className="map-type">
              <h4 className="uppercase">Map Type</h4>
              <span className="radio" >
                <input id="mapAerial" type="radio" name="mapType" onChange={()=>{ this.props.setMapType('aerial'); }} checked={this.props.mapType === 'aerial'}/>
                <label htmlFor="mapAerial">Aerial </label>
              </span>
              <span className="radio" id="canvas-dark-label">
                <input id="canvasDark" type="radio" name="mapType" onChange={()=>{ this.props.setMapType('canvasDark'); }} checked={this.props.mapType === 'canvasDark'}/>
                <label>Canvas Dark </label>
              </span>
              <span className="radio">
                <input id="mapGrayscale" type="radio" name="mapType" onChange={()=>{ this.props.setMapType('grayscale'); }} checked={this.props.mapType === 'grayscale'}/>
                <label htmlFor="mapGrayscale">Grayscale </label>
              </span>
              <span className="radio">
                <input id="mapRoad" type="radio" name="mapType" onChange={()=>{ this.props.setMapType('road'); }} checked={this.props.mapType === 'road'}/>
                <label>Road </label>
              </span>
            </div>
          }
          <div className="map-content">
            <h4 className="uppercase">Map Content</h4>
            {!this.props.modifiers.get('hideFacilities') &&
              <Toggle toggleLabel="Facility Locations"
                      id="icons-facilities"
                      name="icons-facilities"
                      checked={this.props.mapContent.get('facilities')}
                      onChange={() => { this.props.setMapContent('facilities', !this.props.mapContent.get('facilities')); }}
              />
            }{!this.props.modifiers.get('hideIncidents') &&
            <Toggle toggleLabel="Incidents"
                    id="icons-incidents"
                    name="icons-incidents"
                    checked={this.props.mapContent.get('incidents')}
                    onChange={() => { this.props.setMapContent('incidents', !this.props.mapContent.get('incidents')); }}
            />
            }{!this.props.modifiers.get('hidePorts') &&
              <Toggle toggleLabel="Port Locations"
                      id="icons-ports"
                      name="icons-ports"
                      checked={this.props.mapContent.get('ports')}
                      onChange={() => { this.props.setMapContent('ports', !this.props.mapContent.get('ports')); }}
              />
            }{!this.props.modifiers.get('hideTraffic') &&
              <Toggle toggleLabel="Traffic"
                      id="icons-traffic"
                      name="icons-traffic"
                      checked={this.props.mapContent.get('traffic')}
                      onChange={() => { this.props.setMapContent('traffic', !this.props.mapContent.get('traffic')); }}
              />
            }{!this.props.modifiers.get('hideWeather') &&
            <Toggle toggleLabel="Weather"
                    id="icons-weather"
                    name="icons-weather"
                    checked={this.props.mapContent.get('weather')}
                    onChange={() => { this.props.setMapContent('weather', !this.props.mapContent.get('weather')); }}
            />
            }
          </div>
        </div>
      }
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    mapType: selectMapType(state),
    mapContent: selectMapContent(state),
    modifiers: selectMapModifiers(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return{
    setMapType: (mapType) => { dispatch(actions.setMapType(mapType)); },
    setMapContent: (key, val) => { dispatch(actions.setMapContent(key, val)); }
  };
};

export const MainMapControls = connect(mapStateToProps, mapDispatchToProps)(MapControls);

export const mapShipmentStateToProps = state => {
  return {
    mapType: selectShipmentMapType(state),
    mapContent: selectShipmentMapContent(state),
    modifiers: selectShipmentMapModifiers(state)
  };
};

export const mapShipmentDispatchToProps = dispatch => {
  return {
    setMapType: (mapType) => { dispatch(actions.setShipmentMapType(mapType)); },
    setMapContent: (key, val) => { dispatch(actions.setShipmentMapContent(key, val)); }
  };
};

export const ShipmentTrackerMapControls = connect(mapShipmentStateToProps, mapShipmentDispatchToProps)(MapControls);

export const mapDisruptionsStateToProps = state => {
  return {
    mapType: selectDisruptionsMapType(state),
    mapContent: selectDisruptionsMapContent(state),
    modifiers: selectDisruptionsMapModifiers(state)
  };
};

export const mapDisruptionsDispatchToProps = dispatch => {
  return {
    setMapType: (mapType) => { dispatch(actions.selectDisruptionsMapType(mapType)); },
    setMapContent: (key, val) => { dispatch(actions.selectDisruptionsMapContent(key, val)); }
  };
};

export const DisruptionsMapControls = connect(mapDisruptionsStateToProps, mapDisruptionsDispatchToProps)(MapControls);
