import { getConfiguration } from '@chr/common-web-ui-configuration';
import clientFactory from './clientFactory';

export default {
  async fetchIncidents() {
    const config = await getConfiguration();
    const endpoint = config.incidentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.get('incidents');
			return result;
    } catch (err) {
      throw `Error getting incidents: ${err}`;
    }
  },
  async deleteIncident(payload) {
    const config = await getConfiguration();
    const endpoint = config.incidentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.delete(`incidents/${payload.payload.incidentId}`);
			return result;
    } catch (err) {
      throw `Error deleting incident: ${err}`;
    }
  },
  async createIncident(payload) {
    const config = await getConfiguration();
    const endpoint = config.incidentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.post('incidents', payload.incident);
			return result;
    } catch (err) {
      throw `Error creating incident: ${err}`;
    }
  },
  async updateIncident(payload) {
    const config = await getConfiguration();
    const endpoint = config.incidentsApiEndpoint;
    try {
      const request = await clientFactory(endpoint);
      const result = await request.put(`incidents/${payload.incident.id}`, payload.incident);
			return result;
    } catch (err) {
      throw `Error updating incident: ${err}`;
    }
  }
};
