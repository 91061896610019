import fetchUser from '../../apis/users';
import decode from 'jwt-decode';
import { getConfiguration } from '@chr/common-web-ui-configuration'
import { createClient } from '@chr/common-web-ui-authentication';

export async function getAccessToken(productUrl) {
  try {
    if (localAuthClient) {
      const accessToken = await localAuthClient.getAccessToken();
      if (accessToken) {
        return accessToken;
      }
    }
  }
  catch (err) {
    console.error('Error while getting access token. Error:', err);
  }

  if (!productUrl) {
    const config = await getConfiguration();
    productUrl = config.productUrl;
  }

  const authInfoStorageKey = productUrl + '-access_token';
  const localStorage = window['localStorage'];
  return localStorage.getItem(authInfoStorageKey);
}

export async function getUserProfile() {
  const config = await getConfiguration();
  const userProfile = localStorage.getItem('userProfile');
  if (userProfile) {
    return JSON.parse(userProfile);
  } else {
    let idToken;

    try {
      if (localAuthClient) {
        idToken = await localAuthClient.getIdToken();
      }
    }
    catch (err) {
      console.error('Error while getting identity token. Error:', err);
    }

    if (!idToken) {
      idToken = localStorage.getItem(config.productUrl + '-id_token');
    }

    if (!idToken) {
      throw new Error('User profile is not found.');
    }
    let decodedIdToken = await decode(idToken);
    const result = fetchUser.fetchUser(decodedIdToken.sub);
    return result;
  }
}

let localAuthClient = null;
export async function authClientInstance(config, accessToken, idToken) {
  if (!localAuthClient) {
    let { accessTokenObject, idTokenObject } = await handleTokens(config, accessToken, idToken);
    localAuthClient = createClient(config, {}, accessTokenObject, idTokenObject);
  }
  return localAuthClient;
}

const handleTokens = async (config, accessToken, idToken) => {
  addTokenToLocalStorage(accessToken, config, 'access_token');
  addTokenToLocalStorage(idToken, config, 'id_token');

  let accessTokenObject = await convertToken(accessToken, 'accessToken');
  let idTokenObject = accessTokenObject ? await convertToken(idToken, 'idToken', accessTokenObject.scopes) : null;

  return { accessTokenObject, idTokenObject };
}

const convertToken = async (token, key, scopes) => {
  try {
    let decodedToken = await decode(token);
    let tokenObject = {
      scopes: scopes || decodedToken.scp,
      expiresAt: decodedToken.exp
    };
    tokenObject[key] = token;
    return tokenObject;
  }
  catch (err) {
    return null;
  }
}

export const addTokenToLocalStorage = (token, config, key) => {
  if (token) {
    window.localStorage.setItem(
      `${config.productUrl}-${key}`,
      token
    );
  }
}