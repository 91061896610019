import { fromJS } from 'immutable';
import * as types from './actionTypes';

export const initialState = fromJS({
  alertRules: [],
  userProfile: {},
  users: [],
  usersLoading: false,
  userLoading: false,
  savePasswordSuccessful: false,
  alertRulesLoading: false,
  userProfileLoading: true,
  userProfileNeedsToBeInitialized: true
});

export default function usersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_USER_PROFILE_STARTED:
      return state
        .set('userProfileNeedsToBeInitialized', false);
    case types.FETCH_USER_PROFILE_COMPLETED:
      return state
        .set('userProfile', action.payload.userProfile)
        .set('userProfileLoading', false);
    case types.FETCH_USERS_STARTED:
      return state
        .set('usersLoading', true);
    case types.FETCH_USERS_FAILED:
      return state
        .set('usersLoading', false);
    case types.FETCH_USERS_COMPLETED:
      return state
        .set('users', action.payload.users)
        .set('tenantId', action.payload.tenantId)
        .set('isDeleted', action.payload.isDeleted)
        .set('usersLoading', false);
    case types.FETCH_PERMISSIONS_COMPLETED:
      return state
        .set('permissions', action.payload);
    case types.EXISTING_USER_COMPLETED:
      return state
        .set('existingUser', action.payload);
    case types.SEARCH_FOR_USERS_TYPEAHEAD_COMPLETED:
      return state
        .set('typeaheadUsers', action.payload);
    case types.FETCH_USER_STARTED:
      return state
        .set('userLoading', true);
    case types.FETCH_USER_FAILED:
      return state
        .set('userLoading', false);
    case types.FETCH_USER_COMPLETED:
      return state
        .set('user', action.payload.user)
        .set('userLoading', false);
    case types.FETCH_USER_TO_COPY_COMPLETED:
      return state
        .set('userToCopy', action.payload.userToCopy);
    case types.CLEAR_USER_TO_COPY_COMPLETED:
      return state
        .set('userToCopy', action.payload.usersToCopy);
    case types.DELETE_USER_COMPLETED:
      return state
        .set('isDeleted', action.payload.isDeleted);
    case types.FETCH_ALERT_RULES_STARTED:
      return state
        .set('alertRulesLoading', true);
    case types.FETCH_ALERT_RULES_FAILED:
      return state
        .set('alertRulesLoading', false);
    case types.FETCH_ALERT_RULES_COMPLETED:
      return state
        .set('alertRulesLoading', false)
        .set('alertRules', action.payload.alertRules)
        .set('subscribedShipment', action.payload.subscribedShipment);
    case types.CREATE_RULE_CARD:
      const newRules = [action.payload.rule, ...state.get('alertRules')];
      return state
        .set('alertRules', newRules);
    case types.DELETE_ALERT_RULE_COMPLETED:
      return state
        .set('alertRules', state.get('alertRules').filter(x => x._id !== action.payload.alertId));
    case types.CHANGE_PASSWORD_COMPLETED:
      return state
        .set('savePasswordSuccessful', true);
    case types.CREATE_SHIPMENT_RULE_CARD:
      const combinedRules = [action.payload.rule, ...state.get('alertRules').filter(x => !x.new)];
      return state
        .set('alertRules', combinedRules);
    case types.RESET_SUBSCRIBED_PROP:
      return state
        .set('subscribedShipment', false);
    default:
      return state;
  }
}
