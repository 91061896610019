import React from 'react';
import Icon from '../icon';
import { getDateTime } from '../../utils/helperMethods/dateTimeMethods';
import { trackShipment, getIdentifierValues } from '../../utils/helperMethods/shipmentMethods';
import { determineShipmentNumber } from "../../utils/helperMethods/commonMethods";

export default function ShipmentsTable(props) {
  const openInShipmentTracker = (shipment, props) => {
    var formatData = {
      ...shipment,
      history: props.history
    };

    trackShipment(formatData);
  }

  const renderShipments = (props) => {
    let tableData = [];

    if (props.trendingShipments && props.trendingShipments.length > 0) {
      tableData = props.trendingShipments;
    } else if (props.bookmarks && props.bookmarks.length > 0) {
      tableData = props.bookmarks;
    };

    return tableData.map((data) => {
      const currentShipment = data._source ? data._source : data.shipment;

      return (
        <tr key={currentShipment.id} data-test="shipmentsTable">
          <td>{determineShipmentNumber(currentShipment) || '\u2014'}</td>
          <td>{getIdentifierValues(currentShipment.primary_identifiers, 3) || '\u2014'}</td>
          <td>{currentShipment.origin_city && currentShipment.origin_state ? currentShipment.origin_city + ', ' + currentShipment.origin_state : '\u2014'}</td>
          <td>{currentShipment.origin_Sched_date_close ? getDateTime(currentShipment.origin_Sched_date_close) : '\u2014'}</td>
          <td>{currentShipment.pickup_date ? getDateTime(currentShipment.pickup_date) : '\u2014'}</td>
          <td>{currentShipment.destination_city && currentShipment.destination_state ? currentShipment.destination_city + ', ' + currentShipment.destination_state : '\u2014'}</td>
          <td>{currentShipment.destination_Sched_date_close ? getDateTime(currentShipment.destination_Sched_date_close) : '\u2014'}</td>
          <td>{currentShipment.calculated_ETA ? getDateTime(currentShipment.calculated_ETA) : '\u2014'}</td>
          <td>{currentShipment.calculated_ETA_source ? currentShipment.calculated_ETA_source : '\u2014'}</td>
          <td>{currentShipment.status || '\u2014'}</td>
          {props.permissions && props.permissions.includes('ViewShipmentTracker') &&
            <td><button onClick={() => { openInShipmentTracker(currentShipment, props) }} data-test="trackShipment" id={`track-${determineShipmentNumber(currentShipment)}-shipment-button`}
            className="btn btn-primary btn-sm">Track Shipment</button></td>
          }
          {props.bookmarks && <td><a id={`remove-bookmark-${determineShipmentNumber(currentShipment)}-button`} onClick={() => { props.removeFlaggedShipment(data) }}> <Icon id={`remove-bookmark-${determineShipmentNumber(currentShipment)}-icon`} type="delete" /> </a></td>}
        </tr>
      );
    });
  };
  return (
    <div className="flex-column" >
      <div className="row">
        <div className="col-xs-12">
          <table className="table">
            <thead>
              <tr>
                <th data-test="loadNumberHeader">Shipment Number</th>
                <th>Primary Identifier</th>
                <th>Origin Location</th>
                <th>Origin Scheduled</th>
                <th>Origin Complete</th>
                <th>Destination Location</th>
                <th>Destination Scheduled</th>
                <th>ETA</th>
                <th>ETA source</th>
                <th>Status</th>
                {props.permissions && props.permissions.includes('ViewShipmentTracker') && <th></th>}
                {props.bookmarks && <th></th>}
              </tr>
            </thead>
            <tbody>
              {(props.trendingShipments && props.trendingShipments.length > 0) || (props.bookmarks && props.bookmarks.length > 0) ? renderShipments(props) :
                <tr>
                  <td colSpan="9" data-test="noShipmentResult">No Results Found</td>
                </tr>} 
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}